'use strict';

import Numbers from "../../../../../utilities/number.utilities";
import {getActivationPace} from "../../../../../shared/services/migrated/activation.service";

var ActivationPace = function (ParticipationService, $q) {
    'ngInject'
    return {
        getActivationPace: function (_params, callback) {
            var defered = $q.defer();
            var promise = defered.promise;

            var params = {
                from: _params.from,
                to: _params.to,
                extras: {}
            };

            if (_params.hierarchy) {
                params.hierarchyId = _params.hierarchy.id;
            }

            if (_params.groups) {
                params.groupId = _params.groups.join(',');
            }

            params.filters = _params.filters;

            getActivationPace(params, function (err, data) {
                if (err)
                    callback(err);
                else {
                    var result = {
                        activation: [],
                        invitation: [],
                        today: [],
                        max: 0
                    };

                    if (data && data.results) {
                        data.results.forEach(function (item) {

                            result.activation.push({
                                x: new Date(item.date).getTime(),
                                y: item.activatedAcumulated,
                                percent: Numbers.roundNumber(item.activatedAcumulated * 100 / item.invitedAcummulated)
                            });
                            result.invitation.push({
                                x:  new Date(item.date).getTime(),
                                y: item.invitedAcummulated
                            });
                            result.today.push({
                                x:  new Date(item.date).getTime(),
                                y: item.activated
                            });
                        });
                    }

                    callback(null, result);
                }
            });
        }
    };
};

const ActivationPaceService = angular.module('happyForceApp')
.service('ActivationPaceService', ActivationPace);

export default ActivationPaceService;
