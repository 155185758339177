import {getService} from '../../../migration_utils/react-in-angular';
import {useEffect, useMemo, useState} from 'react';


function useCompanyNames() {

    const CompanySvrc = getService('CompanySvrc');
    const $translate = getService('$translate');

    const [companyName, setCompanyName] = useState<string | undefined>();
    const [industryName, setIndustryName] = useState<string | undefined>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        setLoading(true);
        CompanySvrc.getUserCompany(function (err:any, company: any) {
            if (err) {
                // TODO: Handle error
                setError(err);
                setCompanyName($translate.instant('COMPANY'));
            } else {
                setCompanyName(company.name);
                CompanySvrc.getCompanyIndustryName(function (err:any, name: any) {
                    if (err) {
                        // TODO: Handle error
                        setError(err);
                        setIndustryName('INDUSTRY');
                    } else {
                        setIndustryName(name);
                    }
                    setLoading(false);

                });
            }
        });
    },[]);

    const memoizedCompanyName = useMemo(() => companyName, [companyName]);
    const memoizedIndustryName = useMemo(() => industryName, [industryName]);


    return {
        companyName: memoizedCompanyName,
        industryName: memoizedIndustryName,
        companyInfoLoading: loading,
        error
    };

}

export default useCompanyNames;
