'use strict';

function CommentMainCtrl(Comments) {
    'ngInject';
    var _this = this;

    _this.commentVisible = function () {

        if (!_this.running) {
            _this.running = setTimeout(function () {
                if (_this.comment.hasBeenSeen == false) {
                    if (!_this.replyOf) {
                        Comments.markAsSeen(_this.comment.id, function (err, result) {
                            // Mark the comment as seen on the server
                            _this.comment.hasBeenSeen = result.hasBeenSeen;
                            delete _this.running;
                        });
                        
                    }
                }
            }, 3000);
        }
    };
}


//Directive used to set metisMenu and minimalize button
const hfCommentMain = angular.module('happyForceApp')
    .directive('hfCommentMain', function () {
        return {
            scope: {
                comment: '<',
                hideType: '<',
                replyOf: '<',
                feedbackTypeText: '<',
                commentUpdatedCallback: '='
            },
            transclude: {
                postHeaderZone: '?postHeaderZone',
                preCommentZone: '?preCommentZone',
                postCommentZone: '?postCommentZone',
                reactionsZone: '?reactionsZone'
            },
            template: require('./comment-main.html'),
            controller: CommentMainCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            replace: true
        };
    });

export default hfCommentMain;
