'use strict';

import Style from "../../../../../utilities/style.utils";
import Dates from "../../../../../utilities/date.utilities";
import Numbers from "../../../../../utilities/number.utilities";

function participationEvolutionChartCtrl($scope) {
    'ngInject';

    var _this = this;


    function renderChart(data, min, max, grouping) {

        Highcharts.dateFormats = {
            q: function (timestamp) {
                var date = new Date(timestamp),
                    quarter = (Math.floor(date.getUTCMonth() / 3) + 1);
                return quarter;
            }
        };

        var formatedData = [];
        data.forEach(function (entry) {
            formatedData.push(
                [
                    Dates.removeTimeZone(entry.date).getTime(),
                    entry.activated ?  Numbers.calculatePercent(entry.participants, entry.activated) : null
                ]
            );
        });

        formatedData = _.sortBy(formatedData, function (o) { return o[0]; });

        var dateTimeLabelFormat = { // don't display the dummy year
            day: '%e. %b',
            month: '%e. %b',
            year: '%e. %b'
        };

        if (grouping === 'MONTH') {
            dateTimeLabelFormat.day = '%b \'%y';
            dateTimeLabelFormat.month = '%b \'%y';
            dateTimeLabelFormat.year = '%b \'%y';
        } else if (grouping === 'QUARTER') {
            dateTimeLabelFormat.day = 'Q%q\'%y';
            dateTimeLabelFormat.month = 'Q%q\'%y';
            dateTimeLabelFormat.year = 'Q%q\'%y';

        }

        var tooltipFormat;

        if (grouping == 'DAY' || grouping == 'WEEK') {
            tooltipFormat = '%e. %b %Y';
        }  else {
            tooltipFormat = '%b %Y';
        }



        var zones = [];

        if (grouping === 'DAY') {
            zones = [{
                value: 0,
                color: Style.noData
            },
                {
                    value: 30,
                    color: Style.bad
                },
                {
                    value: 50,
                    color: Style.warning
                }, {
                    color: Style.excellent
                }];

        } else if (grouping === 'WEEK') {
            zones = [{
                value: 0,
                color: Style.noData
            },
                {
                    value: 20,
                    color: Style.bad
                },
                {
                    value: 40,
                    color: Style.warning
                }, {
                    color: Style.excellent
                }];
        } else {
            zones = [{
                value: 0,
                color: Style.noData
            },
                {
                    value: 30,
                    color: Style.bad
                },
                {
                    value: 50,
                    color: Style.warning
                }, {
                    color: Style.excellent
                }];
        }


        return {
            options: {
                chart: {
                    type: 'spline',
                    height: 190,
                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    fontFamily: 'HelveticaNeue-Light'
                },
                title: null,
                plotOptions: {
                    series: {
                        lineWidth: 2,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: dateTimeLabelFormat,
                    startOnTick: false,
                    title: {
                        enabled: false
                    }

                },
                yAxis: {
                    tickColor: '#b5bbc3',
                    title: {
                        enabled: false
                    },
                    gridLineWidth: 1,
                    gridLineDashStyle: 'Dot',
                    labels: {
                        style: {
                            color: '#b5bbc3'
                        }
                    },
                    min: min,
                    max: max,
                    tickInterval: max / 5
                },

                tooltip: {
                    enabled: true,
                    headerFormat: '<b>{point.x:' + tooltipFormat + '}:</b><br>',
                    pointFormat: '{point.y:.1f}%'
                }
            },

            series: [{
                showInLegend: false,
                data: formatedData,
                zones: zones,
                connectNulls: true

            }]
        };
    };

    function parseData() {
        _this.participationChartConfig =  renderChart(_this.data, 0, 100, _this.grouping);

    }
    this.$onInit = function () {

        ['data', 'grouping'].forEach(function (key) {
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) return;
                parseData();
            });
        });

        parseData();
    };



}

const participationEvolutionChart = angular.module('happyForceApp')
    .directive('participationEvolutionChart', function () {
        return {
            scope: {
                data: '=',
                grouping: '='
            },
            template: require('./participation-evolution-chart.html'),
            controller: participationEvolutionChartCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });

export default participationEvolutionChart;
