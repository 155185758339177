import { useEffect, useState } from 'react';
import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, ListItemText, Box, Typography } from '@mui/material';
import { getHierarchyAll } from '../../../services/migrated/hierarchy.service';
import { getService } from 'reactInAngular';

interface HierarchyNestedMenuItemProps {
    filters?: any;
    setFilters?: (filters: any) => void;
    open?: boolean;
    mode?: string;
    title?: string;
    nonRemovable: boolean;
}

const HierarchyNestedMenuItem = ({ filters, setFilters, open, mode, title, nonRemovable }: HierarchyNestedMenuItemProps) => {
    const [hierarchies, setHierarchies] = useState([]);
    const [selectedHierarchies, setSelectedHierarchies] = useState([]);
    const [selectedHierarchy, setSelectedHierarchy] = useState();
    const ErrorSvrc = getService('ErrorSvrc');

    useEffect(() => {
        getHierarchyAll(function (err, orgdata) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setHierarchies(orgdata);
            }
        });
    }, []);

    //Used to load selected hierarchies from filters
    useEffect(() => {
        if (mode === 'multi') {
            const initialSelectedHierarchies = [];
            if (filters && filters.length > 0) {
                filters.forEach((filter) => {
                    initialSelectedHierarchies.push(filter);
                });
                setSelectedHierarchies(initialSelectedHierarchies);
            }
        } else if (mode === 'single') {
            if (filters && filters.length > 0) {
                setSelectedHierarchy(filters[0]);
            }
        }
    }, [filters]);

    //handle with remove/add
    const handleFilter = (item) => {
        item.option = title;
        item.nonRemovable = nonRemovable;

        if (mode === 'multi') {
            if (filters && filters.findIndex(e => e.id === item.id) > -1) {
                setFilters(filters => filters.filter(filterItem => filterItem.id !== item.id));
            } else {
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else if (mode === 'single') { //DONE
            //Check if exists one of the type
            if (filters.findIndex(e => e.type === item.type) > -1) {
                //If nonRemovable, check if do nothing or replace
                if (nonRemovable) {
                    //If id is the same
                    if (filters && filters.findIndex(e => e.id === item.id) > -1) { //Do nothing
                        return;
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                } else {
                    if (filters && filters.findIndex(e => e.id === item.id) > -1) { //Same id, remove it
                        setFilters(filters => filters.filter(filterItem => filterItem.type !== item.type));
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                }
            } else { //No same type, add it
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else {
            setFilters(optionFilters => [...optionFilters, item]);
        }
    }

    const handleCheckboxChange = (item) => {
        handleFilter(item);
        //If item exists, remove it. If not, add it
        setSelectedHierarchies((prevSelected) => {
            const itemExists = prevSelected.some(e => e.id === item.id);

            if (itemExists) {
                return prevSelected.filter(e => e.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const handleRadioChange = (item) => {
        handleFilter(item);

        if (selectedHierarchy && nonRemovable) {
            return;
        } else {
            if(selectedHierarchy === item){
                setSelectedHierarchy(null);
            } else {
                setSelectedHierarchy(item);
            }
        }
    };

    const renderHierarchies = (items) => {
        if (!items) {
            return null;
        }

        if (mode === 'multi') {
            return items.map((item, index) => {
                const isChecked = selectedHierarchies.findIndex(e => e.id === item.id) > -1;

                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => {
                                handleCheckboxChange(item)
                            }
                            }
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isChecked}
                                        inputProps={{ 'aria-labelledby': index }} />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderHierarchies(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem key={index} onClick={() => handleCheckboxChange(item)} sx={{ paddingLeft: 1 }}>
                            <Checkbox
                                checked={isChecked}
                                inputProps={{ 'aria-labelledby': index }} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return items.map((item, index) => {
                const isSelected = selectedHierarchy ? (selectedHierarchy as { id: string }).id === item.id : false;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleRadioChange(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Radio
                                        checked={isSelected}
                                    />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderHierarchies(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => handleRadioChange(item)}
                            sx={{ paddingLeft: 1 }}>
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            //TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderHierarchies(hierarchies)}
        </NestedMenuItem>
    );
};

export default HierarchyNestedMenuItem;
