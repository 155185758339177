'use strict';

var HFBannerCtrl = function ($scope) {
    const _this = this;

    this.$onInit = function () {
        if (!_this.type) {
            _this.type = 'primary';
        }

        ['type'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (!n) {
                    _this.type = 'primary';
                }
            });
        });
    };
};

const hfBanner = angular.module('happyForceApp')
    .directive('hfBanner', function () {
        return {
            template: require('./hf-banner.html'),
            scope: {
                type: '<'
            },
            controller: HFBannerCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });
export default hfBanner;
