

function FeedbackInitiativesDropdownCtrl(ErrorSvrc, FeedbackContainersService, $translate, $state, toaster) {
    'ngInject';

    const _this = this;

    _this.editInitiative = function () {
        $state.go('feedback.initiativeEditor', { initiativeId: _this.initiative.id });
    };

    _this.deleteInitiative = function () {

        var deletionText = '';

        if (_this.initiative.status === 'ONGOING') {
            deletionText = $translate.instant('INITIATIVE_DELETE_ONGOING_DESCRIPTION');
        } else if (_this.initiative.numComments > 0) {
            deletionText = $translate.instant('INITIATIVE_DELETE_WITH_COMMENTS_DESCRIPTION');
        } else {
            deletionText = $translate.instant('INITIATIVE_DELETE_STANDARD_DESCRIPTION');
        }

        swal({
            type: 'warning',
            title: $translate.instant('INITIATIVE_DELETE_TITLE'),
            text: deletionText,
            allowEscapeKey: true,
            buttons: {
                cancel: true,
                confirm: { text: $translate.instant('CONFIRM'), className: 'swal-button--delete' }
            },
            confirmButtonColor: '#DD6B55',
            closeOnConfirm: true
        }).then(function (result) {
            if (result) {
                FeedbackContainersService.delete(_this.initiative.id, function (err, result) {
                    if (err)
                    {ErrorSvrc.showErrorModal(err);}
                    else {
                        toaster.pop('success', null, ($translate.instant('INITIATIVE_DELETED')));
                        if (_this.reload)
                        {_this.reload();}
                    }
                });
            }
        });
    };

    function internalArchive(archive) {
        const _initiative = angular.copy(_this.initiative);
        _initiative.archived = archive;
        FeedbackContainersService.update(_initiative.id, _initiative, function (err, result) {
            if (err)
            {ErrorSvrc.showErrorModal(err);}
            else {
                _this.initiative = result;
                if (_this.initiative.archived)
                {toaster.pop('success', null, ($translate.instant('INITIATIVE_STATUS_ARCHIVED')));}
                else
                {toaster.pop('success', null, ($translate.instant('INITIATIVE_STATUS_UNARCHIVED')));}

                if (_this.reload)
                {_this.reload();}

            }
        });
    }

    _this.archiveInitiative = function (archive) {

        if (archive && _this.initiative.status === 'ONGOING') {
            swal({
                type: 'warning',
                title: $translate.instant('INITIATIVE_ARCHIVE_TITLE'),
                text: $translate.instant('INITIATIVE_ARCHIVE_ACTIVE_DESCRIPTION'),
                allowEscapeKey: true,
                buttons: {
                    cancel: true,
                    confirm: { text: $translate.instant('CONFIRM'), className: 'swal-button--delete' }
                },
                confirmButtonColor: '#DD6B55',
                closeOnConfirm: true
            }).then(function (result) {
                if (result) {
                    internalArchive(archive);
                }
            });
        } else {
            internalArchive(archive);
        }
    };

    _this.goToDetail = function () {
        if (_this.initiative.draft) {
            $state.go('feedback.initiativeEditor', { initiativeId: _this.initiative.id });
        } else {
            $state.go('feedback.initiativeDetail', { initiativeId: _this.initiative.id });
        }
    };
}


const hfFeedbackInitiativeDropdown = angular.module('happyForceApp')
    .directive('hfFeedbackInitiativeDropdown', function () {
        return {
            template: require('./feedback-initiative-dropdown.html'),
            controller: FeedbackInitiativesDropdownCtrl,
            scope: {
                initiative: '=',
                reload: '='
            },
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackInitiativeDropdown;
