'use strict';

import template from './hf-content-editor.html';
import Trix from 'trix';

//jscs:disable maximumLineLength
var videoSites = [
    {
        url: /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/i,
        html: function (url) {
            function getVideoId(url) {
                var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                return (url.match(p)) ? RegExp.$1 : false;
            };

            return '<iframe style="width: 100%" src="https://www.youtube.com/embed/' + getVideoId(url) + '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
        }
    },
    {
        url: /^https:\/\/vimeo.com\/(\d+)/,
        html: function (url, reg) {
            var matched = url.match(reg);
            var videoId = (matched && matched[1]) || null;

            return '<iframe src="https://player.vimeo.com/video/' + videoId + '" style="width: 100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
        }
    },
    {
        url: /^https:\/\/www\.instagram\.com\/p\/(\w+)\//,
        html: function (url, reg) {
            var matched = url.match(reg);
            var videoId = (matched && matched[1]) || null;

            return '<iframe src="https://www.instagram.com/p/' + videoId + '/embed/captioned" style="width: 100%" frameborder="0" scrolling="no" allowtransparency="true"></iframe>';
        }
    }

];


var HFContentEditorCtrl = function ($scope, $translate, Attachments, AuthSrvc) {
    'ngInject'
    var _this = this;
    _this.editHTML = false;

    _this.isADVISOR = AuthSrvc.isADVISOR();


    var $editor;

    _this.trixInitialize = function (e, editor) {
        window.$editor = $editor = editor;

        Trix.config.attachments.preview.caption.name = null;
        Trix.config.attachments.preview.caption.size = null;
        Trix.config.attachments.file.caption.name = null;

        var buttonHTML = '<button id="youtubeToTrix" type="button" class="trix-button trix-button--icon ';
        buttonHTML += 'trix-button--icon-youtube" data-trix-attribute="href" data-trix-action="link" ';
        buttonHTML += 'data-trix-key="k" title="Youtube" tabindex="-1">Link</button>';
        $('.trix-button-group--file-tools').append(buttonHTML);
    };

    _this.trixAttachmentAdd = function (e) {
        var attachment;
        attachment = e.attachment;
        if (attachment.file) {
            if (!attachment.file.type.startsWith('image')) {
                _this.uploadFiles([attachment.file]);
                $editor.composition.removeAttachment(attachment);
                return;
            } else {
                _this.sendImage(attachment);
            }
        }
    };


    _this.sendImage = function (image) {
        _this.uploading = true;
        var data = new FormData();
        data.append('file', image.file);
        Attachments.upload(image.file, function (err, result) {
            _this.uploading = false;
            if (err) {
                var messageError = $translate.instant(
                    'UPLOAD_FILE_ERROR_DESC',
                    { name: image.file.name }
                );
                swal(
                    $translate.instant(
                        (err && err.errorMessage) || 'UPLOAD_FILE_ERROR'
                    ),
                    messageError,
                    'error'
                );
            } else {
                if (result && result.filePath) {
                    if (_this.onMediaAdded) {
                        _this.onMediaAdded(result);
                    }

                    return image.setAttributes({
                        url: result.filePath,
                        href: result.filePath,
                        caption: null
                    });
                }
            }
        }, function (progress, total) {
            console.log('progress', progress, total);

        });
    };

    // for multiple files:
    _this.uploadFiles = function (files) {
        if (files && files.length) {
            _this.uploading = true;
            for (var i = 0; i < files.length; i++) {
                Attachments.upload(files[i], function (err, $file) {
                    _this.uploading = false;
                    if (!err && $file !== -1) {
                        if (_this.onAttachmentAdded) {
                            return _this.onAttachmentAdded($file);
                        };
                    } else if (err) {
                        var messageError = $translate.instant(
                            'UPLOAD_FILE_ERROR_DESC',
                            { name: $file.name }
                        );
                        swal(
                            $translate.instant(
                                (err && err.errorMessage) || 'UPLOAD_FILE_ERROR'
                            ),
                            messageError,
                            'error'
                        );
                    }


                });
            }
        }
    };


    // Video injection on Trix Control
    var eventActive = false;

    function videoToTrix(event) {
        var $buttons = jQuery('.trix-button.trix-button--dialog');

        function renderVideo(e) {
            if (jQuery(e.target).data('trix-method') === 'setAttribute') {
                var chunk = jQuery('.trix-input.trix-input--dialog').val();
                videoSites.forEach(function (site) {
                    if (site.url.test(chunk)) {
                        var html = site.html(chunk, site.url);
                        if (html) {
                            var attachment = new Trix.Attachment({ content: html });
                            window.$editor.isInserting = true;
                            window.$editor.insertAttachment(attachment);
                            window.$editor.insertHTML('<br />');
                        }
                    }
                });
            }
            $buttons.off('click', renderVideo);
            eventActive = false;
        }

        if (!eventActive) {
            $buttons.one('click', renderVideo);
        }
        eventActive = true;
    };





    this.$onInit = function () {
        _this.currentContent = _this.content;

        ['currentContent'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n != o) {
                    if (_this.onContentUpdated) {
                        _this.onContentUpdated(n);
                    }
                };
            });
        });

        jQuery(document).on('click', '#youtubeToTrix', videoToTrix);

    };
};

const hfContentEditor = angular.module('happyForceApp')
    .directive('hfContentEditor', function () {
        return {
            scope: {
                content: '<',
                onContentUpdated: '<',
                onMediaAdded: '<',
                onAttachmentAdded: '<'
            },
            template: template,
            controller: HFContentEditorCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });
export default hfContentEditor;
