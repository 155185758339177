'use strict';
import SurveySendReminderModal from "../survey_reminder_send/survey_reminder_send.controller";
import SurveyDeleteModal from '../survey_delete/survey_delete.controller';


function SurveyActionMenuCtrl($translate, SurveyScheduleModal, SurveySendReminderModal, AuthSrvc, Surveys, ErrorSvrc, $uibModal, $state, toaster, SurveyDeleteModal) {
    const _this = this;

    _this.hasAdminRoles = AuthSrvc.isADVISOR() || AuthSrvc.isADMIN();

    /* We need to do this to make the swal views to work since they are not in the same scope */
    function reloadByStatus (status) {
        _this.reloadStatus(status);
    }

    function reloadSurvey (surveyId) {
        _this.reloadSurvey(surveyId);
    }

    _this.editSurvey = function () {
        $state.go('surveys.edit', { surveyId: _this.survey.id });
    };

    _this.sendReminder = function () {

        SurveySendReminderModal.showModal(_this.survey, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                swal($translate.instant('SURVEY_REMINDER_SENT'), '', 'success');
            }
        });
    };

    _this.pauseOrArchive = function (pause) {
        var swalParams = {
            icon: 'warning',
            closeOnEsc: false,
            buttons: {
                cancel: $translate.instant('CANCEL'),
                confirm: {
                    text: (pause ? $translate.instant('PAUSE') : $translate.instant('ARCHIVE')),
                    value: 'confirm'
                }
            },
            closeModal: true
        };

        if (pause) {
            swalParams.title = $translate.instant('SURVEY_PAUSE');
            swalParams.text = $translate.instant('SURVEY_PAUSE_DESCRIPTION');
        } else {
            swalParams.title = $translate.instant('SURVEY_ARCHIVE');
            swalParams.text = $translate.instant('SURVEY_ARCHIVE_DESCRIPTION');
        }

        swal(swalParams)
            .then(function (value) {
                switch (value) {
                    case 'confirm':
                        var plan = angular.copy(_this.survey.schedulePlan);

                        if (pause) {
                            plan.paused = true;
                        } else {
                            plan.archived = true;
                        }

                        Surveys.setPlan(_this.survey.id, plan, function (err, result) {
                            if (err) {
                                ErrorSvrc.showErrorModal(err);
                            } else {
                                //_this.survey = result;
                                toaster.pop('success', null, (pause ? $translate.instant('SURVEY_PAUSED') : $translate.instant('SURVEY_ARCHIVED')));

                                if (pause) {
                                    reloadByStatus('PAUSED');
                                } else {
                                    reloadByStatus('ARCHIVED');
                                }

                            };
                        });
                        break;
                    default:
                }
            });
    };



    _this.swapMandatoryStatus = function () {

        if (_this.survey.schedulePlan.mandatory) {

            var plan = angular.copy(_this.survey.schedulePlan);
            plan.mandatory = false;
            Surveys.setPlan(_this.survey.id, plan, function (err, result) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    toaster.pop({
                        type: 'success',
                        title: $translate.instant('SURVEY_MANDATORY_DISABLED'),
                        showCloseButton: true,
                        timeout: 5000
                    });
                    reloadSurvey(_this.survey.id);
                };
            });
        } else {
            var swalParams = {
                icon: 'warning',
                closeOnEsc: false,
                buttons: {
                    cancel: $translate.instant('CANCEL'),
                    confirm: {
                        text: $translate.instant('CONFIRM'),
                        value: 'confirm'
                    }
                },
                closeModal: true,
                title: $translate.instant('SURVEY_MANDATORY_ENABLE'),
                text: $translate.instant('SURVEY_MANDATORY_ENABLE_DESCRIPTION')

            };

            swal(swalParams)
                .then(function (value) {
                    switch (value) {
                        case 'confirm':
                            var plan = angular.copy(_this.survey.schedulePlan);
                            plan.mandatory = true;
                            Surveys.setPlan(_this.survey.id, plan, function (err, result) {
                                if (err) {
                                    ErrorSvrc.showErrorModal(err);
                                } else {
                                    //_this.survey = result;
                                    toaster.pop('success', null, $translate.instant('SURVEY_MANDATORY_ENABLED'));
                                    reloadSurvey(_this.survey.id);
                                };
                            });
                            break;
                        default:
                    }
                });
        }
    };



    _this.reactivate = function () {
        var swalParams = {
            icon: 'warning',
            closeOnEsc: false,
            buttons: {
                cancel: $translate.instant('CANCEL'),
                confirm: {
                    text: $translate.instant('REACTIVATE'),
                    value: 'confirm'
                }
            },
            closeModal: true
        };

        swalParams.title = $translate.instant('SURVEY_REACTIVATE');
        swalParams.text = $translate.instant('SURVEY_REACTIVATE_DESCRIPTION');

        swal(swalParams)
            .then(function (value) {
                switch (value) {
                    case 'confirm':
                        var plan = angular.copy(_this.survey.schedulePlan);
                        plan.paused = false;
                        Surveys.setPlan(_this.survey.id, plan, function (err, result) {
                            if (err) {
                                ErrorSvrc.showErrorModal(err);
                            } else {
                                toaster.pop('success', null, $translate.instant('SURVEY_REACTIVATED_DESCRIPTION'));
                                reloadByStatus();
                            };
                        });
                        break;
                    default:
                }
            });
    };

    _this.relaunch = function () {
        var swalParams = {
            icon: 'info',
            closeOnEsc: false,
            buttons: {
                cancel: $translate.instant('CANCEL'),
                confirm: {
                    text: $translate.instant('SURVEY_RELAUNCH_CONFIRM'),
                    value: 'confirm'
                }
            },
            closeModal: true
        };

        swalParams.title = $translate.instant('SURVEY_REALAUNCH');
        swalParams.text = $translate.instant('SURVEY_REALAUNCH_DESCRIPTION');

        swal(swalParams)
            .then(function (value) {
                switch (value) {
                    case 'confirm':
                        _this.editSchedule(true);
                        break;
                    default:
                }
            });
    };

    _this.editSchedule = function (relaunch) {

        const schedulePlan = relaunch ? null : _this.survey.schedulePlan;

        SurveyScheduleModal.showModal(_this.survey, schedulePlan, relaunch, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop('success', null, $translate.instant('SURVEY_SCHEDULE_PLAN_SET'));
                reloadByStatus();
            }
        });
    };

    _this.cloneSurvey = function () {
        if (_this.survey.result && _this.survey.status != 'NOW') {
            var swalParams = {
                icon: 'info',
                closeOnEsc: false,
                buttons: {
                    cancel: $translate.instant('CANCEL'),
                    confirm: {
                        text: $translate.instant('SURVEY_CLONE_CONFIRM'),
                        value: 'confirm'
                    }
                },
                closeModal: true
            };

            swalParams.title = $translate.instant('SURVEY_CLONE');
            swalParams.text = $translate.instant('SURVEY_CLONE_CONFIRM_DESCRIPTION');

            swal(swalParams)
                .then(function (value) {
                    switch (value) {
                        case 'confirm':
                            $state.go('surveys.edit', { surveyId: _this.survey.id, surveyClone: 'CLONE' });
                            break;
                        default:
                    }
                });
        } else {
            $state.go('surveys.edit', { surveyId: _this.survey.id, surveyClone: 'CLONE' });
        }
    };

    _this.surveyDelete = function () {
        SurveyDeleteModal.showDeleteModal(_this.survey, function () {
            reloadByStatus();
        });
    };
};



const hfSurveyActionMenu = angular.module('happyForceApp').directive('hfSurveyActionMenu', function () {
    return {
        scope: {
            survey: '<',
            reloadSurvey: '<',
            reloadStatus: '<'
        },
        restrict: 'E',
        template: require('./survey_action_menu.html'),
        controller: SurveyActionMenuCtrl,
        controllerAs: '$surveyactionmenu',
        bindToController: true
    };
});

export default hfSurveyActionMenu;
