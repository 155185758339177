

import AnalyzeBoxChart from './components/analyze_box_chart.component';
import Dates from '../../../../utilities/date.utilities';
import async from 'async';
import ColorUtilities from '../../../../utilities/color.utilities';
import { getENPSStats } from '../../../../shared/services/migrated/enps.service';

function EngagementAnalyzeBoxCtrl($scope, ScoresV2Srvc, Scores, HIService, ErrorSvrc, $translate, Characteristics, Groups, LoadingService, SegmentsFilterService, Settings, SegmentationNamesService, Languages) {
    'ngInject';
    var _this = this;

    _this.comparisonGroups = [];

    _this.results = [];

    _this.scoreAndFactorNames = {};

    _this.from;

    // Initialize the default filters
    _this.indicatorsFilters = {};

    // Initialize the default tags
    _this.selectedIndicatorsTags = [];

    _this.updateIndicatorsFilters = function (newSelectedFilters) {
        _this.indicatorsFilters = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    // Build a primary list of filters
    _this.primaryFilterOptions = {
        title: $translate.instant('HI_ENPS'),
        nonRemovable: false,
        options: [
            {
                type: 'primary',
                id: 'HI',
                title: $translate.instant('HI'),
                selectable: true,
                exclusive: false
            },
            {
                type: 'primary',
                id: 'ENPS',
                title: $translate.instant('ENPS'),
                selectable: true,
                exclusive: false
            }
        ]
    };

    _this.optionFiltersConfig = {
        filters: [_this.primaryFilterOptions]
    };

    // Build a list based on the scores

    _this.scoreFilterOptions = [];

    Scores.list({}, function (err, results) {
        if (!err) {
            results.forEach(function (score) {


                const scoreName = Languages.getTranslationFromUserLanguage(score.name);

                const scoreEntry = {
                    type: 'score',
                    id: 'SCORE_' + score.id,
                    title: scoreName,
                    selectable: true,
                    exclusive: false,
                    children: []
                };

                // Store the score name
                _this.scoreAndFactorNames[score.id] = scoreName;

                // Now, let's iterate over the factors, and add them as well as childs
                score.factors.forEach(function (factor) {

                    const factorName = Languages.getTranslationFromUserLanguage(factor.name);

                    const factorEntry = {
                        type: 'score',
                        id: 'FACTOR_' + score.id + '_' + factor.id,
                        title: factorName,
                        selectable: true,
                        exclusive: false
                    };

                    _this.scoreAndFactorNames[factor.id] = factorName;

                    scoreEntry.children.push(factorEntry);
                });

                _this.scoreFilterOptions.push(scoreEntry);
            });
        }
        else { console.error('Error retrieving score names', err); }
        if (_this.scoreFilterOptions.length > 0) {
            _this.scoreFilterOptionsAdapted = {
                title: $translate.instant('SCORES_SECTION_TITLE'),
                nonRemovable: false,
                options: _this.scoreFilterOptions
            };
            
            _this.optionFiltersConfig.filters.push(_this.scoreFilterOptionsAdapted);
        }
    });

    function buildParamsFromGroup(comparisonGroup) {

        var params = {
            from: _this.fromDate,
            to: _this.toDate,
            hasFilters: false
        };

        if (comparisonGroup.filters) {
            if (comparisonGroup.filters['hierarchyIds'] && comparisonGroup.filters['hierarchyIds'].length > 0) {
                if (_.some(comparisonGroup.filters['hierarchyIds'], function (h) { return h != null; })) { params.hierarchyId = comparisonGroup.filters['hierarchyIds']; }
                params.hasFilters = true;
            }
            if (comparisonGroup.filters['segments'] && comparisonGroup.filters['segments'].length > 0) {
                params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(comparisonGroup.filters['segments']);
                params.hasFilters = true;

            }
            if (comparisonGroup.filters['groups'] && comparisonGroup.filters['groups'].length > 0) {
                params.groupId = comparisonGroup.filters['groups'];
                params.hasFilters = true;
            }
        }

        return params;
    }


    function buildNameFromGroup(comparisonGroup, callback) {

        if (comparisonGroup.filters) {
            SegmentationNamesService
                .getNames(
                    comparisonGroup.filters['hierarchyIds'],
                    comparisonGroup.filters['segments'],
                    comparisonGroup.filters['groups'],
                    function (err, names) {
                        if (err) {
                            console.error('Error retrieving segment names', err);
                            callback(err);
                        } else {

                            // Let's compile the final name
                            var areaNames = [];
                            var segmentNames = [];
                            var groupNames = [];

                            var finalNames = [];
                            names.forEach(function (name) {
                                if (name.type == 'hierarchy') { areaNames.push(name.name); }
                                else if (name.type == 'segment') { segmentNames.push(name.name); }
                                else if (name.type == 'group') { groupNames.push(name.name); }
                            });

                            if (areaNames.length > 0) { finalNames.push($translate.instant('EMPLOYEE_AREA') + ' ' + areaNames.join(', ')); }

                            if (segmentNames.length > 0) { finalNames.push($translate.instant('EMPLOYEE_PROFILE') + ' ' + segmentNames.join(', ')); }

                            if (groupNames.length > 0) { finalNames.push($translate.instant('EMPLOYEE_GROUPS') + ' ' + groupNames.join(', ')); }

                            callback(null, finalNames.join('; '));
                        }
                    });
        } else {
            CompanySvrc.getUserCompany(function (err, company) {
                if (!err) {
                    callback(null, company.name);
                } else {
                    callback(err);
                }
            });
        }
    }



    function retrieveData() {

        const groupNameStreams = [];

        var groupNames;

        var addedNames = [];

        // First compute the name of each group and check for duplicates
        _this.comparisonGroups.forEach(function (comparisonGroup) {
            groupNameStreams.push(function (next) {
                buildNameFromGroup(comparisonGroup, function (err, name) {
                    if (!err) {
                        if (name) {
                            comparisonGroup.duplicate = (addedNames.indexOf(name) != -1);
                            addedNames.push(name);
                        } else {
                            comparisonGroup.duplicate = false;
                        }

                        next(null, {
                            id: comparisonGroup.id,
                            name: name
                        });
                    } else {
                        next(err);
                    }
                });
            });
        });

        // Fire the streams
        async.parallel(groupNameStreams, function (err, results) {
            // For each result, set the name
            results.forEach(function (result) {
                groupNames = groupNames || {};
                groupNames[result.id] = result.name;
            });

            // Now, let's retrieve the data for each group
            const streams = [];

            // Build the streams for each key
            _this.comparisonGroups.forEach(function (comparisonGroup) {

                // For each group, we need to build the streams
                const requestParams = buildParamsFromGroup(comparisonGroup);

                // Verify that the group has params:
                if (requestParams.hasFilters) {
                    Object.keys(_this.indicatorsFilters).forEach(function (key) {
                        if (key == 'primary' && _this.indicatorsFilters[key].length) {
                            if (_this.indicatorsFilters[key].indexOf('HI') > -1) {
                                // Build streams for the HI
                                streams.push(function (next) {
                                    HIService.stats.get(requestParams, function (err, result) {
                                        // TODO: Parse the stats
                                        var parsedResult = {
                                            id: 'HI',
                                            groupName: groupNames[comparisonGroup.id],
                                            metricName: 'HI',
                                            group: comparisonGroup.id,
                                            color: comparisonGroup.color,
                                            results: [],
                                            hasData: (result.quality != 'NO_DATA'),
                                            hasEnoughActiveEmployees: true
                                        };
                                        if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                                            parsedResult.hasEnoughActiveEmployees = false;
                                            next(null, parsedResult);
                                        } else if (err) {
                                            next(err);
                                        } else {
                                            Object.keys(result.periodResults.votes).forEach(function (voteKey) {
                                                parsedResult.results.push(
                                                    {
                                                        date: result.periodResults.votes[voteKey].date,
                                                        value: result.periodResults.votes[voteKey].indexRating
                                                    }
                                                );
                                            });
                                            next(null, parsedResult);
                                        }
                                    });
                                });
                            }
                            if (_this.indicatorsFilters[key].indexOf('ENPS') > -1) {
                                // Buid streams for the ENPS
                                streams.push(function (next) {
                                    getENPSStats(requestParams, function (err, result) {
                                        var parsedResult = {
                                            id: 'ENPS',
                                            metricName: 'eNPS',
                                            groupName: groupNames[comparisonGroup.id],
                                            group: comparisonGroup.id,
                                            color: comparisonGroup.color,
                                            results: [],
                                            hasData: (_.some(result.results, function (enpsResult) { return enpsResult.quality != 'NO_DATA'; })),
                                            hasEnoughActiveEmployees: true
                                        };
                                        if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                                            parsedResult.hasEnoughActiveEmployees = false;
                                            next(null, parsedResult);
                                        } else if (err) {
                                            next(err);
                                        } else {
                                            result.results.forEach(function (enpsResult) {
                                                parsedResult.results.push(
                                                    {
                                                        date: Dates.fromAPIFormat(enpsResult.from, Settings.apiDateFormat),
                                                        value: enpsResult.result

                                                    }
                                                );
                                            });

                                            // TODO: Parse the stats
                                            next(null, parsedResult);
                                        }
                                    });
                                });
                            }
                        } else if (key == 'score' && _this.indicatorsFilters[key].length) {
                            _this.indicatorsFilters[key].forEach(function (scoreOrFactor) {
                                if (scoreOrFactor.includes('SCORE_') || scoreOrFactor.includes('FACTOR_')) {
                                    const scoreId = scoreOrFactor.split('_')[1];
                                    var factorId = null;

                                    var scoreOrFactorName;

                                    // This is a factor request
                                    if (scoreOrFactor.includes('FACTOR_')) {
                                        factorId = scoreOrFactor.split('_')[2];
                                        scoreOrFactorName = _this.scoreAndFactorNames[factorId];
                                    } else {
                                        scoreOrFactorName = _this.scoreAndFactorNames[scoreId];
                                    }

                                    // Buid streams for the score
                                    streams.push(function (next) {
                                        ScoresV2Srvc.stats.get(scoreId, requestParams, function (err, result) {
                                            var parsedResult = {
                                                id: scoreOrFactor,
                                                groupName: groupNames[comparisonGroup.id],
                                                metricName: scoreOrFactorName,
                                                group: comparisonGroup.id,
                                                color: comparisonGroup.color,
                                                results: [],
                                                hasEnoughActiveEmployees: true,
                                                hasData: (_.some(result.results, function (scoreResult) { return scoreResult.quality != 'NO_DATA'; }))
                                            };
                                            if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                                                parsedResult.hasEnoughActiveEmployees = false;
                                                next(null, parsedResult);
                                            } else if (err) {
                                                next(err);
                                            } else {
                                                result.results.forEach(function (scoreResult) {
                                                    if (factorId == null) {
                                                        parsedResult.results.push(
                                                            {
                                                                date: Dates.fromAPIFormat(scoreResult.from, Settings.apiDateFormat),
                                                                value: scoreResult.result

                                                            }
                                                        );
                                                    } else {

                                                        var factorResult = _.find(scoreResult.factors, function (factor) { return factor.id == factorId; });

                                                        parsedResult.results.push(
                                                            {
                                                                date: Dates.fromAPIFormat(scoreResult.from, Settings.apiDateFormat),
                                                                value: factorResult.result

                                                            }
                                                        );
                                                    }
                                                });

                                                // TODO: Parse the stats
                                                next(null, parsedResult);
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    });
                }
            });


            // Fire the streams
            async.parallel(streams, function (err, results) {
                _this.results = results;

                // Now, mark those without data
                var groupsWithDataData = {};

                _this.results.forEach(function (result) {
                    if (result.hasData) {
                        groupsWithDataData[result.group] = true;
                    }
                });

                _this.comparisonGroups.forEach(function (comparisonGroup) {
                    if (!groupsWithDataData[comparisonGroup.id]) {
                        comparisonGroup.hasData = false;
                    } else {
                        comparisonGroup.hasData = true;
                    }
                });
            });

        });

    }

    _this.updateComparisonGroupFilters = function (groupId, newFilters) {
        this.comparisonGroups = this.comparisonGroups.map(group =>
            group.id === groupId ? { ...group, filters: newFilters } : group
        );
    }

    _this.addComparisonGroup = function () {
        const comparisonId = 'COMPARISON_' + _this.comparisonGroups.length;
        const color = ColorUtilities.palettes.chartsNamed[_this.comparisonGroups.length % ColorUtilities.palettes.chartsNamed.length];
        const colorName = ColorUtilities.names[_this.comparisonGroups.length % ColorUtilities.names.length];
        let filters = {};
        let selectedTags = [];

        function updateFilters(newSelectedFilters) {
            _this.updateComparisonGroupFilters(comparisonId, newSelectedFilters);
            $scope.$apply();
        };

        // Prepare the filters
        let hierarchyFiltersConfig = {
            id: 'HIERARCHY',
            mode: 'multi',
            title: $translate.instant('AREA'),
            nonRemovable: false
        }

        let groupFiltersConfig = {
            id: 'GROUP',
            mode: 'single',
            title: $translate.instant('GROUPS'),
            nonRemovable: false
        }

        let segmentFiltersConfig = {
            id: 'SEGMENT',
            mode: 'multi',
            title: $translate.instant('SEGMENTED'),
            nonRemovable: false
        }

        _this.comparisonGroups.push({
            id: comparisonId,
            color: color,
            tagColor: colorName,
            filters: filters,
            selectedTags: selectedTags,
            hierarchyFiltersConfig: hierarchyFiltersConfig,
            groupFiltersConfig: groupFiltersConfig,
            segmentFiltersConfig: segmentFiltersConfig,
            updateFilters: updateFilters,
            duplicated: false
        });
    };


    _this.groupHasFilters = function (comparisonGroup) {
        const hasFilters = comparisonGroup.filters;
        const hastFiltersWithData = _.some(Object.keys(comparisonGroup.filters), function (key) {
            const filterData = comparisonGroup.filters[key];
            return filterData && filterData.length > 0;

        });

        return hasFilters && hastFiltersWithData;
    };

    _this.hasIndicators = function () {
        if (Object.keys(_this.indicatorsFilters).length == 0) {
            return false;
        } else {
            return _.some(Object.keys(_this.indicatorsFilters), function (key) {
                return _this.indicatorsFilters[key].length > 0;
            });
        }
    };

    _this.hasComparisonOrFilters = function () {

        var hasComparison = false;
        var hasIndicators = _this.hasIndicators();
        if (_this.comparisonGroups.length == 0) {
            hasComparison = false;
        } else {
            hasComparison = _.some(_this.comparisonGroups, _this.groupHasFilters);
        }
        return hasComparison && hasIndicators;

    };

    _this.removeComparison = function (comparisonGroupId) {
        _this.comparisonGroups = _.filter(_this.comparisonGroups, function (comparisonGroup) {
            return comparisonGroup.id != comparisonGroupId;
        });
    };

    _this.$onInit = function () {
        ['comparisonGroups', 'indicatorsFilters', 'to'].forEach(function (prop) {
            $scope.$watch(angular.bind(_this, function () {
                return _this[prop];
            }), function (n, o) {
                _this.fromDate = Dates.firstDayOfMonth(moment(_this.to).subtract(12, 'month').toDate());
                _this.toDate = Dates.lastDayOfMonth(_this.to);

                if (!_.isEqual(n, o)) {


                    retrieveData();
                }
            }, true);
        });

    };

}

const hfEngagementAnalyzeBox = angular.module('happyForceApp').directive('hfEngagementAnalyzeBox', function () {
    return {
        scope: {
            to: '<',
            dateGrouping: '<'
        },
        restrict: 'E',
        template: require('./analyze_box.html'),
        controller: EngagementAnalyzeBoxCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfEngagementAnalyzeBox;
