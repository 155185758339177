import {
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Switch, Typography
} from '@mui/material';
import {Person} from '../../../types';
import React, { useEffect } from 'react';
import moment from 'moment/moment';
import {getService} from '../../../../../../../../migration_utils/react-in-angular';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {APISettings} from '../../../../../../../shared/services/migrated/axiosWrapper';

interface UserDeletedFieldsProps {
    person: Person;
    onRowChange: (updatedPerson: Person) => void;
    onValidationChange: (isValid: boolean) => void;
}


const UserDeletedFields = ({person, onRowChange, onValidationChange}: UserDeletedFieldsProps) => {

    const $translate = getService('$translate')

    const [leaveReason, setLeaveReason] = React.useState(person.turnoverType ? person.turnoverType : null);
    const [leaveDate, setLeaveDate] = React.useState(person.turnoverDate ? moment(person.turnoverDate) : null);
    const [leaveReasonEnabled, setLeaveReasonEnabled] = React.useState(true);


    useEffect(() => {
        if (leaveReasonEnabled) {
            person.turnoverType = leaveReason ? leaveReason : null;

            if (leaveDate?.isValid()) {
                const finalLeaveDate = leaveDate ? leaveDate.format(APISettings.dateFormat) : null;
                person.turnoverDate = finalLeaveDate;
                onValidationChange(true);
            } else {
                onValidationChange(false);
            }

        } else {
            delete person.turnoverType;
            delete person.turnoverDate;
            onValidationChange(true);
        }
        onRowChange(person);

    }, [leaveReason, leaveDate, leaveReasonEnabled]);



    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Grid container sx={(theme) => ({marginTop: theme.spacing(2)})}>
                <Grid item xs={6}>

                    <DatePicker
                        disabled={!leaveReasonEnabled}
                        label={$translate.instant('EMPLOYEE_SET_LEAVEDATE')}
                        value={leaveDate}
                        slotProps={{ textField: { variant: 'standard', } }}
                        onChange={(newValue) => setLeaveDate(newValue)}
                    />

                </Grid>

                <Grid item xs={6}>


                    <FormControl  size="small" variant={"standard"} fullWidth={true}>

                        <InputLabel
                            htmlFor="userLeaveReason">{$translate.instant('EMPLOYEE_SET_LEAVE_REASON')}</InputLabel>

                        <Select
                            disabled={!leaveReasonEnabled}
                            fullWidth
                            id="userLeaveReason"
                            value={leaveReason}
                            onChange={(e) => {
                                setLeaveReason(e.target.value as string);
                            }}
                            aria-describedby="userLeaveReason-text">
                            <MenuItem value={'VOLUNTARY'}>
                                {$translate.instant('TURNOVER_TYPE_VOLUNTARY')}
                            </MenuItem>
                            <MenuItem value={'INVOLUNTARY'}>
                                {$translate.instant('TURNOVER_TYPE_INVOLUNTARY')}
                            </MenuItem>
                            <MenuItem value={'OTHER'}>
                                {$translate.instant('TURNOVER_TYPE_OTHER')}
                            </MenuItem>
                        </Select>

                        <FormHelperText id="userRole-text">
                            {leaveReason === 'VOLUNTARY' && $translate.instant('EMPLOYEE_LEAVE_VOLUNTARY_DESCRIPTION')}
                            {leaveReason === 'INVOLUNTARY' && $translate.instant('EMPLOYEE_LEAVE_INVOLUNTARY_DESCRIPTION')}
                            {leaveReason === 'OTHER' && $translate.instant('EMPLOYEE_LEAVE_OTHER_DESCRIPTION')}

                        </FormHelperText>

                    </FormControl>
                </Grid>

                <Grid item xs={12}>

                    <FormGroup>
                        <FormControlLabel
                            onChange={(e) => {setLeaveReasonEnabled(e.target.checked)}}
                            control={<Switch
                                checked={leaveReasonEnabled} />}
                            label={<Typography variant={'caption'} fontWeight={'bold'}>
                                {$translate.instant('EMPLOYEE_LEAVE_INFO_ENABLED')}
                            </Typography> } />
                    </FormGroup>

                </Grid>
            </Grid>
        </Box>
    );

}

export default UserDeletedFields;
