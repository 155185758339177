'use strict';


import Dates from "../../../../../utilities/date.utilities";
import Numbers from "../../../../../utilities/number.utilities";


function participationVotesDistributionCtrl($scope, $translate, ParticipationService, ErrorSvrc) {
    'ngInject'
    var _this = this;


    function loadResults() {

        var params = {};

        // Get the data for the current hierarchy & filter
        params.filters = _this.filters;
        params.groupId = _this.groups;

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        var from =  Dates.firstDayOfMonth(_this.from);
        var to = Dates.lastDayOfMonth(_this.to);


        ParticipationService.getDistribution(from, to, params,  function (err, stats) {
            $scope.loadingAnalytics = false;
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    _this.hasEnoughActiveEmployees = false;
                } else {
                    console.log('ERROR getting Stats', err);
                }
            } else {
                _this.total = stats.total;
                _this.mean = Numbers.roundNumber(stats.mean, 1);
                _this.percentile50 = Numbers.roundNumber(stats.percentile50, 1);
                _this.percentile70 = Numbers.roundNumber(stats.percentile70, 1);
                _this.percentile90 = Numbers.roundNumber(stats.percentile90, 1);

                _this.min = stats.min;
                _this.max = stats.max;
            }
        });
    }

    _this.$onInit = function () {
        ['from', 'to', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o || !n)  {
                    return;
                }
                loadResults();
            }, true);
        });

        loadResults();
    };





}


const participationVotesDistribution =angular.module('happyForceApp')
    .directive('participationVotesDistribution', function () {
        return {
            scope: {
                from: '=',
                to: '=',
                level: '=',
                filters: '=',
                groups: '='
            },
            template: require('./participation-vote-distribution.html'),
            controller: participationVotesDistributionCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });

export default participationVotesDistribution;
