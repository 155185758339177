'use strict';

import Dates from "../../../utilities/date.utilities";
import {doGet, APISettings, doPost} from './axiosWrapper'; // Assuming axiosWrapper is a utility file for Axios requests

const configurationBaseUrl = '/company/enps/configuration';
const statsBaseUrl = '/company/stats/enps';
const feedbackBaseUrl = '/company/feedback/enps';



export const getENPSConfiguration = (callback) => {
    doGet(configurationBaseUrl, {}, callback);
};

export const saveENPSConfiguration = (configuration, callback) => {
    doPost(configurationBaseUrl, configuration, callback);
};


export const getENPSStats = (params, callback) => {
    const from = params.from ? Dates.toAPIFormat(params.from, APISettings.apiDateFormat) : undefined;
    const to = params.to ? Dates.toAPIFormat(params.to, APISettings.apiDateFormat) : undefined;
    doGet(statsBaseUrl, { ...params, from, to }, callback);
};

export const getENPSGrouping = (groupBy, params, callback) => {
    const reqParams = { ...params };
    if (reqParams.from) {
        reqParams.from = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    }
    if (reqParams.to) {
        reqParams.to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    }
    const url = `${statsBaseUrl}/grouping/${groupBy}`;
    doGet(url, reqParams, callback);
};

export const getENPSClassification = (params, callback) => {
    const _params = { ...params };
    if (_params.to) {
        _params.to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    }
    const url = `${statsBaseUrl}/classification`;
    doGet(url, _params, callback);
};

export const getENPSIndividualStats = (from, to, params, callback) => {
    const url = `${statsBaseUrl}/individual/${Dates.toAPIFormat(from, APISettings.apiDateFormat)}/${Dates.toAPIFormat(to, APISettings.apiDateFormat)}`;
    doGet(url, params, callback);
};

export const getENPSPersonalStats = (to, personalToken, callback) => {
    const formattedTo = Dates.toAPIFormat(to, APISettings.apiDateFormat);
    const url = `${statsBaseUrl}/personal/${formattedTo}/${personalToken}`;
    doGet(url, {}, callback);
};

export const getENPSFeedback = (params, callback) => {
    const _params = { ...params };
    if (_params.from) {
        _params.from = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    }
    if (_params.to) {
        _params.to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    }
    doGet(feedbackBaseUrl, _params, callback);
};
