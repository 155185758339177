



import Numbers from '../../utilities/number.utilities';

import template from './manage.users.html';

import Style from '../../utilities/style.utils';
import UserListing from 'root/app/components/users/components/user_listing';



function ManageUsersCtrl($scope,
    $stateParams,
    ErrorSvrc,
    People,
    Reports,
    AuthSrvc,
    UserSendReminderModal,
    $translate,
    toaster,
    FileSaver) {
    'ngInject';
    var _this = this;

    // Initialize the default filters
    _this.selectedFilters = {
        deletedStatus: [false],
        activatedStatus: [true, false],
        employeeType: ['EMPLOYEE','ADMIN', 'MANAGER'],
        mailStatus: ['ANY']
    };

    _this.selectedTags = [{
        'type': 'OPTION',
        'selected': [
            { 'type': 'employeeType',
                'id': 'EMPLOYEE',
                'title': $translate.instant('USER_TYPE'),
                'selectable': true,
                'exclusive': false,
                'name': $translate.instant('EMPLOYEE'),
                'option': $translate.instant('USER_ROLE'), 'nonRemovable': false },
            { 'type': 'employeeType',
                'id': 'ADMIN',
                'title': $translate.instant('USER_TYPE'),
                'selectable': true,
                'exclusive': false,
                'name': $translate.instant('ADMIN'),
                'option': $translate.instant('USER_ROLE'), 'nonRemovable': false },
            { 'type': 'employeeType',
                'id': 'MANAGER',
                'title': $translate.instant('USER_TYPE'),
                'selectable': true,
                'exclusive': false,
                'name': $translate.instant('MANAGER'),
                'option': $translate.instant('USER_ROLE'), 'nonRemovable': false },

            { 'type': 'deletedStatus',
                'id': false,
                'title': $translate.instant('EMPLOYEE_STATUS'),
                'selectable': true,
                'exclusive': true,
                'name': $translate.instant('INVITED'),
                'option': $translate.instant('STATUS'), 'nonRemovable': true },

            { 'type': 'activatedStatus',
                'id': true,
                'title': $translate.instant('EMPLOYEE_ACTIVATION_STATUS'),
                'selectable': true,
                'exclusive': false,
                'name': $translate.instant('EMPLOYEE_ACTIVATED'),
                'option': $translate.instant('ACTIVATION'), 'nonRemovable': false },
            { 'type': 'activatedStatus',
                'id': false,
                'title': $translate.instant('EMPLOYEE_ACTIVATION_STATUS'),
                'selectable': true,
                'exclusive': false,
                'name': $translate.instant('EMPLOYEE_NOT_ACTIVATED'),
                'option': $translate.instant('ACTIVATION'), 'nonRemovable': false },

            { 'type': 'mailStatus',
                'id': 'ANY',
                'title': $translate.instant('EMPLOYEE_MAIL_STATUS'),
                'selectable': true,
                'exclusive': true,
                'name': $translate.instant('EMPLOYEE_MAIL_STATUS_ANY'),
                'option': $translate.instant('EMAIL_STATUS'), 'nonRemovable': true }
        ]
    }];


    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        show: true,
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        show: true,
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        show: true,
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false
    }

    _this.deletedStatusFilterOptions = {
        title: $translate.instant('EMPLOYEE_STATUS'),
        nonRemovable: true,
        options: [
            {
                type: 'deletedStatus',
                id: false,
                title: $translate.instant('INVITED'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'deletedStatus',
                id: true,
                title: $translate.instant('DELETED'),
                selectable: true,
                exclusive: true
            }
        ]
    };

    _this.activationStatusFilterOptions = {
        title: $translate.instant('EMPLOYEE_ACTIVATION_STATUS'),
        nonRemovable: true,
        options: [
            {
                type: 'activatedStatus',
                id: true,
                title: $translate.instant('EMPLOYEE_ACTIVATED'),
                selectable: true,
                exclusive: false
            },
            {
                type: 'activatedStatus',
                id: false,
                title: $translate.instant('EMPLOYEE_NOT_ACTIVATED'),
                selectable: true,
                exclusive: false
            }
        ]
    };


    _this.roleFilterOptions = {
        title: $translate.instant('USER_TYPE'),
        nonRemovable: false,
        options: [
            {
                type: 'employeeType',
                id: 'EMPLOYEE',
                title: $translate.instant('EMPLOYEE'),
                selectable: true,
                exclusive: false
            },
            {
                type: 'employeeType',
                id: 'ADMIN',
                title: $translate.instant('ADMIN'),
                selectable: true,
                exclusive: false
            },
            {
                type: 'employeeType',
                id: 'MANAGER',
                title: $translate.instant('MANAGER'),
                selectable: true,
                exclusive: false
            }
        ]
    };

    if (AuthSrvc.isADVISOR) {
        _this.roleFilterOptions.options.push({
            type: 'employeeType',
            id: 'ADVISOR',
            title: $translate.instant('ADVISOR'),
            selectable: true,
            exclusive: false
        });
    }

    _this.mailStatusOptions = {
        title: $translate.instant('EMPLOYEE_MAIL_STATUS'),
        nonRemovable: true,
        options: [
            {
                type: 'mailStatus',
                id: 'ANY',
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_ANY'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'mailStatus',
                id: 'CORRECT',
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_CORRECT'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'mailStatus',
                id: 'FAILING',
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_FAILING'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'mailStatus',
                id: 'UNSUBSCRIBED',
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_UNSUB'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'mailStatus',
                id: 'UNKNOWN',
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_UNKNOWN'),
                selectable: true,
                exclusive: true
            }
        ]
    };

    _this.profileStatusFilterOptions = {
        title: $translate.instant('EMPLOYEE_CONFIG_STATUS'),
        nonRemovable: false,
        options: [
            {
                type: 'profileStatus',
                id: 'NO_AREA',
                title: $translate.instant('EMPLOYEE_WITHOUT_AREA'),
                selectable: true,
                exclusive: false
            },
            {
                type: 'profileStatus',
                id: 'INCOMPLETE_PROFILE',
                title: $translate.instant('EMPLOYEE_WITHOUT_FULL_PROFILE'),
                selectable: true,
                exclusive: false
            }
        ]
    };

    if (AuthSrvc.isADVISOR) {
        _this.profileStatusFilterOptions.options.push({
            type: 'profileStatus',
            id: 'BLOCKED',
            title: $translate.instant('BLOCKED_EMPLOYEES'),
            selectable: true,
            exclusive: false
        });
    }

    _this.optionFiltersConfig = {
        show: true,
        filters: [_this.deletedStatusFilterOptions,
            _this.activationStatusFilterOptions,
            _this.roleFilterOptions,
            _this.mailStatusOptions,
            _this.profileStatusFilterOptions]
    };

    _this.dateFiltersConfig = {
        show: false,
        filters: []
    };


    _this.color = function (value) {
        if (value === 0) {
            return Style.noData;
        } else if (value <= 25) {
            return Style.bad;
        } else if (value <= 50) {
            return Style.warning;
        } else if (value <= 75) {
            return Style.good;
        } else {
            return Style.excellent;
        }
    };

    _this.valuation = function (value) {
        if (value === 0) {
            return $translate.instant('EMPLOYEE_VALUATION_NO_DATA');
        } else if (value <= 25) {
            return $translate.instant('EMPLOYEE_VALUATION_BAD');
        } else if (value <= 50) {
            return $translate.instant('EMPLOYEE_VALUATION_WARNING');
        } else if (value <= 75) {
            return $translate.instant('EMPLOYEE_VALUATION_GOOD');
        } else {
            return $translate.instant('EMPLOYEE_VALUATION_EXCELENT');
        }
    };

    _this.searchText = $stateParams && $stateParams.email || null;

    _this.statuses = [
        {
            name: $translate.instant('INVITED'),
            value: 'invited'

        },
        {
            name: $translate.instant('DELETED'),
            value: 'deleted'
        }
    ];

    _this.header = {
        title: $translate.instant('EMPLOYEES_SECTION_TITLE'),
        subtitle: $translate.instant('EMPLOYEES_SECTION_DESCRIPTION')
    };


    // This is a trick to force the update of the component on the react side
    _this.reactFilters = JSON.stringify({
        searchText: _this.searchText,
        selectedFilters: _this.selectedFilters
    });


    _this.sendReminder = function (type) {
        UserSendReminderModal.showSendReminderModal(type, null, null, null, function (err) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            }
        });
    };

    _this.loadUserstats = function () {

        People.stats.get(function (err, results) {
            if (err) {
                ErrorSvrc.showErrorModal(err);

            } else {


                _this.invitedCount = results.invited;

                _this.activatedCount = results.activated;
                _this.activatedPercent = Numbers.roundNumber(Numbers.calculatePercent(results.activated, results.invited), 1);
                _this.activatedPercentValuation = _this.valuation(_this.activatedPercent);
                _this.activatedPercentColor = _this.color(_this.activatedPercent);

                _this.sleepingCount = results.sleeping;
                _this.sleepingPercent = Numbers.roundNumber(Numbers.calculatePercent(results.sleeping, results.activated), 1);
                _this.sleepingPercentValuation = _this.valuation(100 - _this.sleepingPercent);
                _this.sleepingPercentColor = _this.color(100 - _this.sleepingPercent);


            }
        });
    };


    _this.inviteUser = function () {
        _this.isInviting = true;
        $scope.$apply();
    };

    _this.handleInviteCancel = function () {
        _this.isInviting = false;
        $scope.$apply();
    }


    _this.exportEmployeesToExcel = function () {
        _this.exportingExcelReport = true;
        People.getTemplate('UPDATE', function (err, template) {
            _this.exportingExcelReport = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                var blob = new Blob([template], {});
                FileSaver.saveFile(blob, 'users.xlsx');
            }

        });
    };

    _this.exportAdvisorsEmployeesToExcel = function () {
        _this.exportingExcelReport = true;
        Reports.users(function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                swal('Fichero en camino!', '', 'success');
            }
        });
    };






    this.$onInit = function () {


        $scope.$watch(angular.bind($scope, function () {
            return _this.selectedFilters;
        }), function (newVal, oldVal) {
            if (!newVal || newVal === oldVal) { return; }

            // This is a trick to force the update of the component on the react side
            _this.reactFilters = JSON.stringify(
                {
                    searchText: _this.searchText,
                    selectedFilters: newVal
                }
            )
        }, true);


        $scope.$watch(angular.bind($scope, function () {
            return _this.searchText;
        }), function (newVal, oldVal) {

            if (!newVal || newVal === oldVal) { return; }

            // This is a trick to force the update of the component on the react side
            _this.reactFilters = JSON.stringify(
                {
                    searchText: newVal,
                    selectedFilters: _this.selectedFilters
                }
            )
        }, true);


    };

    _this.loadUserstats();

}


const ManageUsersView = function ManageUsersRoute($stateProvider) {
    'ngInject';
    $stateProvider.state('manage.users', {
        url: '/users/manage',
        controller: ManageUsersCtrl,
        controllerAs: '$ctrl',
        template: template,
        data: { pageTitle: 'MANAGE_USERS', roles: ['ADMIN', 'ADVISOR'] }
    });
}
export default ManageUsersView;
