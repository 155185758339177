'use strict'


import template from './welcome-user.modal.html';

function WelcomeUserCtrl($rootScope, $translate, $scope, $uibModalInstance, $location, AuthSrvc, CompanySvrc, ErrorSvrc) {
    'ngInject';

    $scope.user = AuthSrvc.user();
    $scope.email = $scope.user.email;

    $scope.username = $scope.user.name;

    var process = ['name', 'password'];

    $scope.passwordsMatch = true;
    $scope.passwordsMinLength = true;

    $scope.usernameError = false;

    $scope.stage = process[0];
    $scope.next = function (next) {
        if (typeof next == 'undefined') {
            var index = process.indexOf($scope.stage) + 1;
            $scope.stage = process[index];
        } else {
            var index = process.indexOf(next);
            if (index) {
                $scope.stage = process[index];
            }
        }
    };

    $scope.is = function (value) {
        return $scope.stage == value;
    };

    $scope.isUsernameValid = function () {
        if ($scope.username && $scope.username.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    $scope.resetError = function () {
        $scope.usernameError = false;
    };

    $scope.validateUsername = function () {
        if ($scope.isUsernameValid()) {
            $scope.usernameError = false;
            $scope.next();
        } else {
            $scope.usernameError = true;
        }
    };

    $scope.validatePassword = function () {
        if ($scope.password == $scope.repitPassword) {
            $scope.passwordsMatch = true;
            if ($scope.password.length > 5) {
                $scope.updateUser();
                $scope.passwordsMinLength = true;
            } else {
                $scope.passwordsMinLength = false;
            }
        } else {
            $scope.passwordsMatch = false;
            if ($scope.password.length > 5) {
                $scope.passwordsMinLength = true;
            } else {
                $scope.passwordsMinLength = true;
            }
        }
    };

    $scope.updateUser = function () {
        AuthSrvc.updateUser({ name: $scope.username, password: $scope.password }, function success(updatedUser) {
            $uibModalInstance.close();
            swal($translate.instant('PROFILE_UPDATED'), '', 'success');
        }, function error(response) {

            ErrorSvrc.showErrorModal(response);
        });
    };
}


const WelcomeUserModalComponent = angular.module('happyForceApp')
    .factory('WelcomeUserModalComponent',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showRegisterModal = function() {
                $uibModal.open({
                    template: template,
                    controller: WelcomeUserCtrl,
                    windowClass: 'activate-modal-window',
                    backdrop: 'static',
                    keyboard: false
                });
            };

            return methods;

        }
        );

export default WelcomeUserModalComponent;
