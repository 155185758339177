'use strict';
import ActivationPaceService from "./activation-pace-chart.service";


function ActivationPaceCtrl(ActivationPaceService, $translate, $scope, Settings) {
    'ngInject'
    var _this = this;

    function getActivationPaceConfig(dateFormat, levelName, $translate) {

        return {
            events: {
                load: function () {
                    $(window).trigger('resize');
                }
            },
            options: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: null
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    min: 0,
                    maxPadding: 0,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            color: '#3ebfde'
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    outside: true,
                    formatter: function () {
                        var s = '<span class="tooltip-header small-style">' + moment(this.x).format(dateFormat) + '</span>';
                        if (levelName) {
                            s += '<p class="tooltip-hierarchy medium-style">' + levelName + '</p>';
                        }

                        $.each(this.points, function () {
                            s += '<p class="tooltip-content medium-style"><i class="fa fa-circle" style="color:' + this.series.color + '"></i>';
                            s += '<span class="tooltip-value">' + this.point.y + '</span>';
                            s += '<span class="tooltip-name">' + this.series.name + '</span></p>';
                            if (this.point.percent && (!levelName)) {
                                s += '<p class="tooltip-percent small-style">';
                                s += '(' + this.point.percent + '% ' + $translate.instant('ACTIVATION') + ')</p>';
                            }
                        });

                        return s;
                    },

                    shared: true
                },
                plotOptions: {
                    column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                    },
                    series: {
                        states: {
                            hover: {
                                brightness: -0.3 // darken
                            }
                        }
                    }
                }
            },
            size: {
                height: 200
            },
            series: [],
            credits: {
                enabled: false
            }
        };

    };

    this.$onInit = function () {

        // Leyenda
        _this.leyendItems = [];
        _this.leyendItems.push({ color: '#3ebfde', label: $translate.instant('ACTIVED_USERS') });

        function loadData() {
            _this.loading = true;
            if (_this.level && _this.level.id) {
                if (_this.leyendItems.length == 2) {
                    _this.leyendItems.splice(1, 1);
                }
            } else {
                if (_this.leyendItems.length == 1) {
                    _this.leyendItems.push({ color: 'rgba(0, 0, 0, 0.08)', label: $translate.instant('INVITED_USERS') });
                }
            }

            var params = {
                from: _this.dates.from,
                to: _this.dates.to,
                hierarchy: _this.level,
                filters: _this.filters,
                groups: _this.groups
            };

            _this.fromDate = moment(params.from);
            _this.toDate = moment(params.to);


            ActivationPaceService.getActivationPace(params, function (err, data) {
                _this.loading = false;
                if (err) {
                    swal(err, '', 'error');
                    return;
                }

                var dateFormat = $translate.instant('DATE_FORMAT');
                if (dateFormat === 'DATE_FORMAT')
                    dateFormat = Settings.defaultDateFormat;

                var levelName;
                if (_this.level && _this.level.id) {
                    levelName = _this.level.name;
                }

                _this.hiRhythmConfig = getActivationPaceConfig(dateFormat, levelName, $translate);

                if (!_this.level || !_this.level.id) {
                    _this.hiRhythmConfig.series.push({
                        name: $translate.instant('INVITED_USERS'),
                        data: data.invitation,
                        color: 'rgba(0, 0, 0, 0.08)',
                        showInLegend: false
                    });
                }

                _this.hiRhythmConfig.series.push({
                    name: $translate.instant('ACTIVED_USERS'),
                    data: data.activation,
                    color: '#3ebfde',
                    showInLegend: false
                });
                _this.hiRhythmConfig.series.push({
                    name: $translate.instant('ACTIVED_USERS_TODAY'),
                    data: data.today,
                    color: '#3ebfde',
                    showInLegend: false,

                    dataLabels: {
                        enabled: true,

                        useHTML: true,
                        color: '#FFFFFF',
                        formatter: function () {
                            return '' + (this.y > 0 ? this.y : '');
                        },

                        rotation: 0
                    }
                });

            });

        };

        loadData();
        var lastExecution;
        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                loadData();
            }, true);
        });

        // Grafica
        _this.hasEnoughActiveEmployees = true;
    };

}


const hfActivationPace = angular.module('happyForceApp')
    .directive('hfActivationPace', function () {
        return {
            scope: {
                dates: '=',
                level: '=',
                filters: '=',
                groups: '='
            },
            template: require('./activation-pace-chart.html'),
            controller: ActivationPaceCtrl,
            controllerAs: 'rac',
            bindToController: true
        };
    });

export default hfActivationPace;
