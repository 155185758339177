import {InsightInt} from '../types';
import * as React from 'react';
import {useEffect} from 'react';
import {Box, Button, ButtonGroup, Grid, Typography} from '@mui/material';
import {getService} from 'reactInAngular';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import ScoreEvolutionChart from './components/ScoreEvolutionChart';
import SelectedScoreNumber from './components/SelectedScoreNumber';
import ScoreAIBox from './components/ScoreAIBox';
import Dates from '../../../../utilities/date.utilities';
import {APISettings} from '../../../../shared/services/migrated/axiosWrapper';
import {SegmentFilter} from '../utils/filteringUtils';
import useScoreNames from '../../../../shared/hooks/useScoreNames';
import {ImageIcon} from '../../../../shared/new-components/hf-styled-components/ImageIcon';
import ScoreQuestionList from './components/ScoreQuestionList';

interface ScoreDrawerDetailProps {
    scoreId: string;
    to: Date;
    dateGrouping: string;
    selectedInsight: InsightInt | null;
    segmentationFilter: SegmentFilter | null;
}

const ScoreDrawerDetail = ({scoreId, to, dateGrouping, segmentationFilter, selectedInsight}: ScoreDrawerDetailProps) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const ErrorSvrc = getService('ErrorSvrc');

    const [scoreData, setScoreData] = React.useState<any | null>(null);
    const [selectedResult, setSelectedResult] = React.useState<any | null>(null);
    const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(null);

    const { scoreName, scoreIconPath, scoreNamesLoading } = useScoreNames(scoreId);

    const [displayBlock, setDisplayBlock] = React.useState<'REPORT' | 'QUESTIONS'>('REPORT');

    const prepareParams = () => {
        const params = {
            to: to,
            dateGrouping: dateGrouping,
            hierarchyId: segmentationFilter?.hierarchyId,
            groupId: segmentationFilter.groupId,
            filters: segmentationFilter.characteristic ? [segmentationFilter.characteristic] : [],
        };
        return params;
    };

    useEffect(() => {
        setLoading(true);



        if (!scoreNamesLoading) {
            ScoresV2Srvc.stats.get(scoreId, prepareParams(), (error, response) => {
                if (error) {
                    ErrorSvrc.showErrorModal(error);
                } else {
                    setScoreData(response);
                }
                setLoading(false);
            })
        }


    }, [scoreId, to, dateGrouping, selectedInsight, scoreNamesLoading]);


    useEffect(() => {
        if (selectedResult) {
            setSelectedToDate(Dates.fromAPIFormat(selectedResult.to, APISettings.apiDateFormat),);
        }
    }, [selectedResult]);


    return (
        <Box width={'40vw'}>
            <Box sx={(theme) => ({
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: theme.spacing(2),
            })}>
                <ImageIcon sx={(theme) => ({
                    marginRight: theme.spacing(1),
                })} size={'medium'} src={scoreIconPath} alt={scoreName}/>
                <h2>{scoreName}</h2>
            </Box>

            <HappyLoading loading={loading}>

                <Typography
                    color={'textSecondary'}
                    variant={'caption'}>Detalles del segmento (nombre area, grupo, etc).</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <SelectedScoreNumber
                            scoreId={scoreId}
                            selectedResult={selectedResult}/>
                    </Grid>
                    <Grid item xs={8}>
                        <ScoreEvolutionChart
                            scoreId={scoreId}
                            dateGrouping={dateGrouping}
                            scoreData={scoreData}
                            segmentationFilter={segmentationFilter}
                            onSelectResult={setSelectedResult}
                        />
                    </Grid>
                </Grid>

                <Box sx={(theme) => ({
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: theme.spacing(2),
                })}>
                    <ButtonGroup
                        variant="text">
                        <Button onClick={() => {setDisplayBlock('REPORT')}}>Summary</Button>
                        <Button  onClick={() => {setDisplayBlock('QUESTIONS')}}>Questions</Button>
                    </ButtonGroup>
                </Box>

                {displayBlock === 'REPORT' && (
                    <Box>
                        <ScoreAIBox
                            scoreId={scoreId}
                            segmentationFilter={segmentationFilter}
                            dateGrouping={dateGrouping}
                            to={selectedToDate}/>
                    </Box>
                )}

                {displayBlock === 'QUESTIONS' && (
                    <Box>
                        <ScoreQuestionList
                            scoreId={scoreId}
                            selectedResult={selectedResult}
                            scoreData={scoreData}
                            segmentationFilter={segmentationFilter}
                            dateGrouping={dateGrouping}
                            to={selectedToDate}/>
                    </Box>
                )}



            </HappyLoading>


        </Box>
    );
};

export default ScoreDrawerDetail;
