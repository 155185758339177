import {Paper, styled} from '@mui/material';

const WhiteBgPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(2),
    textAlign: 'center',
    border: 'border: 1px solid #e2e7e9',
    backgroundColor: '#fff',

}));

export { WhiteBgPaper}
