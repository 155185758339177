'use strict';

var SurveyLanguageItemCtrl = function ($scope) {
    'ngInject'
    var _this = this;

    _this.valid = true;

    function validateItem() {
        if (_this.selectedLanguage == null || (_this.item && _this.item.langValues && _this.item.langValues[_this.selectedLanguage])) {
            _this.valid =  true;
        } else {
            _this.valid =  false;
        }
    };

    _this.$onInit = function () {

        // Monitor language changes of the question
        ['item', 'selectedLanguage'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n != o) {
                    validateItem();
                };

            });
        });
    };
};


const hfSurveyLanguageItem = angular.module('happyForceApp')
    .directive('hfSurveyLanguageItem', function () {
        return {
            scope: {
                item: '=',
                selectedLanguage: '='
            },
            template: require('./survey_language_item.html'),
            controller: SurveyLanguageItemCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSurveyLanguageItem;
