import {Drawer, styled} from '@mui/material';
import {paperClasses} from '@mui/material/Paper';


const PaddedDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
        padding: theme.spacing(2),
    }
}));

export { PaddedDrawer}
