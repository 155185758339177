'use strict';

function HIFollowupFeedbackItemCtrl($scope, ClassificationService, $translate, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.commentUpdated = function (err, updatedComment) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
        }
        else {
            _this.comment = updatedComment;
        }
    };

    this.$onInit = function () {
        _this.label = $translate.instant('ATTITUDE_' + _this.comment.type);
        _this.color = ClassificationService.colors[_this.comment.type];
    };
}

const hfHiFollowupFeedbackItem = angular.module('happyForceApp').directive('hfHiFollowupFeedbackItem', function () {
    return {
        scope: {
            comment: '=',
            showConversationLink: '<?',
            size: '<'
        },
        restrict: 'E',
        template: require('./hi_followup_feedback_item.html'),
        controller: HIFollowupFeedbackItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfHiFollowupFeedbackItem;
