'use strict';

const SilosService = function (Restangular) {
    'ngInject'

    var Hierarchy = Restangular.one('company').one('hierarchy');

    var methods = {};

    methods.getAll = function (callback, error) {
        Hierarchy.one('silos').getList().then(callback, error);
    };

    methods.add = function (silo, callback) {
        Hierarchy.customPOST(silo, 'silos').then(function (data) {
            return callback(null, data);
        }, function (err) {

            callback(err);
        });
    };

    methods.update = function (id, silo, callback) {
        Hierarchy.one('silos').one(id).customPUT(silo).then(function (data) {
            return callback(null, data);
        }, function (err) {

            callback(err);
        });
    };

    methods.remove = function (id, callback) {
        Hierarchy.one('silos').one(id).remove().then(function (data) {
            return callback(null, data);
        }, function (err) {

            callback(err);
        });
    };

    return methods;

};

export default SilosService;
