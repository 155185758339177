


import Dates from '../../../utilities/date.utilities';
import {APISettings, doGet} from './axiosWrapper';


const baseUrl = '/company/stats/activation';

export function getActivationPace (params, callback)  {
    const from = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    const to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    const url = `${baseUrl}/from/${from}/to/${to}`;
    doGet(url, params, callback);
}

export function getActivationResume(params, callback) {
    const from = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    const to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    const url = `${baseUrl}/resume/from/${from}/to/${to}`;
    doGet(url, params, callback);
}


export function getActivation (params, callback) {
    const to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    const url = `${baseUrl}/to/${to}`;
    doGet(url, params, callback);
}

export function getActivationHeatmap (groupBy, params, callback) {
    const from = params.from ? Dates.toAPIFormat(params.from, APISettings.apiDateFormat) : undefined;
    const to = params.to ? Dates.toAPIFormat(params.to, APISettings.apiDateFormat) : undefined;
    const url = `${baseUrl}/grouping/${groupBy}`;
    const queryParams = { ...params, from, to };
    doGet(url, queryParams, callback);
}
