'use strict';

function ConversationRow($scope) {
    'ngInject';
    var _this = this;

}

const hfConversationRow = angular.module('happyForceApp').directive('hfConversationRow', function () {
    return {
        scope: {
            conversation: '='
        },
        restrict: 'E',
        template: require('./conversation-row.html'),
        controller: ConversationRow,
        controllerAs: '$ctrl',
        bindToController: true
    };
}).controller('ConversationRow', ConversationRow);

export default hfConversationRow;
