'use strict'

import Dates from "../../../../../utilities/date.utilities";

function FeedbackInitiativesListingCtrl($scope, Filters, HIService, Stats, $stateParams, Settings, ErrorSvrc, FeedbackStatusService, FeedbackContainersService, $translate) {
    'ngInject';

    var _this = this;

    _this.currentInitiativesPage = 1;
    _this.totalInitiatives = 0;
    _this.dateFormat = $translate.instant('DATE_FORMAT');
    _this.searchText = null;
    _this.showCTA = false;


    function prepareParams() {

        var params = {};


        if (_this.filters['from']) {
            params.from = _this.filters['from'];
        }
        if (_this.filters['to']) {
            params.to = _this.filters['to'];
        }

        if (_this.filters.groups && _this.filters.groups.length)
            params.groups = _this.filters.groups;

        if (_this.filters.hierarchyIds && _this.filters.hierarchyIds.length) {
            params.hierarchyId = _this.selectedFilters.hierarchyIds.join(',');
        }

        if (_this.filters.segments && _this.filters.segments.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.filters.segments);
        }

        if (_this.searchText != null) {
            params.containerText = _this.containerText;
        }

        // Set the pagination to the params
        params.fromContainer = (_this.currentInitiativesPage - 1) * 8;
        params.toContainer = 8;

        return params;
    };


    // Main method loading calling to the API for load the ideas
    _this.loadInitiatives = function () {
        var params = prepareParams();
        _this.loadingData = true;
        FeedbackContainersService.list('SUGGESTION', params, function (err, results) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.initiatives = [];
                _this.initiatives = results.results;
                _this.totalInitiatives = results.total;
                _this.showCTA = results.noContent;
            }
            _this.loadingData = false;
        });
    };


    this.$onInit = function () {
        var pageArgs = ['filters', 'searchText', 'currentInitiativesPage'];
        pageArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (newVal === oldValue) return;
                _this.loadInitiatives();
            }, true);
        });
        _this.loadInitiatives();
    };



}


const hfFeedbackInitiativesListing = angular.module('happyForceApp')
    .directive('hfFeedbackInitiativesListing', function () {
        return {
            template: require('./feedback-initiatives-listing.html'),
            controller: FeedbackInitiativesListingCtrl,
            scope: {
                filters: '<'
            },
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackInitiativesListing;
