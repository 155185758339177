'use strict';

import hfFeedbackAmountSettings from '../components/feedback_amount_settings/feedback_amount_settings.controller';
import hfFeedbackTransparencySettings from '../components/feedback_transparency_settings/feedback_transparency_settings.controller';

function CongratulationFeedbackConfigCtrl($scope, Analytics) {
    'ngInject'

    var _this = this;

    ['enabled', 'employeeSignatureMode', 'managerSignatureMode', 'privacyMode', 'sharedAmmountComments', 'maxCommentsPerDay'].forEach(function (key) {
        // Listeners
        $scope.$watch(
            angular.bind(_this, function () {
                return $scope.feedbackConfiguration.feedbackConfiguration['CONGRATULATION'][key];
            }),
            function (n, o) {

                if (n === o ) {
                    return;
                }

                $scope.saveFeedbackSettings();
            }
        );
    });


    Analytics.trackEvent('settings_congratulation_feedback_config');
}

const hfCongratulationFeedbackConfig = angular.module('happyForceApp')
    .directive('hfCongratulationFeedbackConfig', function () {
        return {
            template: require('./congratulation_feedback_config.html'),
            controller: CongratulationFeedbackConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfCongratulationFeedbackConfig;
