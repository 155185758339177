function AnnouncementsAddSectionModalCtrl(ErrorSvrc, $modalInstance, AnnouncementsSectionsConfiguration, currentSectionsConfig) {

    var _this = this;


    // Check if we should show any component custom component

    _this.availableSectionTypes = [];

    // Look for all the types that have been already added
    AnnouncementsSectionsConfiguration.sectionTypes.forEach(function (s) {
        var foundIndex =   _.findIndex(currentSectionsConfig, { id: s.id });
        if (foundIndex == -1) {
            // This type is not already added
            _this.availableSectionTypes.push(s);
        }
    });


    _this.addSection = function (sectionType) {
        AnnouncementsSectionsConfiguration.getDefaultConfiguration(sectionType.id, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $modalInstance.close(result);
            }

        });

    };


    _this.exit = function () {
        $modalInstance.dismiss();
    };

    _this.$onInit = function () {
        console.log('Me inicio');
    };

}




const AnnouncementsAddSectionModal = angular.module('happyForceApp')
    .factory('AnnouncementsAddSectionModal',
        function ($uibModal) {
            var methods = {};
            methods.showModal = function (currentSectionsConfig, callback) {
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./announcements_add_section_modal.html'),
                    controller: AnnouncementsAddSectionModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        currentSectionsConfig: function () {
                            return currentSectionsConfig;
                        }
                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );

export default AnnouncementsAddSectionModal;