import { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, Box, ListItemText, Typography } from '@mui/material';

interface SegmentNestedMenuItemProps {
    filters?: any;
    setFilters?: (filters: any) => void;
    open?: boolean;
    mode?: string;
    title?: string;
    nonRemovable: boolean;
}

const SegmentNestedMenuItem = ({ filters, setFilters, open, mode, title, nonRemovable }: SegmentNestedMenuItemProps) => {
    const Characteristics = getService('Characteristics');
    const Languages = getService('Languages');
    const [characteristics, setCharacteristics] = useState([]);
    const [selectedCharacteristics, setSelectedCharacteristics] = useState([]);
    const [selectedCharacteristic, setSelectedCharacteristic] = useState();
    const ErrorSvrc = getService('ErrorSvrc');

    useEffect(() => {
        Characteristics.getAll(function (err, characteristicsData) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setCharacteristics(characteristicsData);
            }
        });
    }, []);

    //Used to load selected characteristics from filters
    useEffect(() => {
        if (mode === 'multi') {
            const initialSelectedCharacteristics = [];
            if (filters && filters.length > 0) {
                filters.forEach((filter) => {
                    initialSelectedCharacteristics.push(filter);
                });
                setSelectedCharacteristics(initialSelectedCharacteristics);
            }
        } else if (mode === 'single') {
            if (filters && filters.length > 0) {
                setSelectedCharacteristic(filters[0]);
            }
        }
    }, [filters]);

    //handle with remove/add
    const handleFilter = (characteristic, item) => {
        //Filter name
        item.option = title;
        item.characteristicId = characteristic.id
        item.nonRemovable = nonRemovable
        if (characteristic.questionType === 'LIST') {
            item.name = Languages.getTranslationFromUserLanguage(characteristic.description) +
                ' (' + Languages.getTranslationFromUserLanguage(item.value) + ')';
        } else if (characteristic.questionType === 'DATE') {
            item.name = Languages.getTranslationFromUserLanguage(characteristic.description) +
                ' (' + Languages.getTranslationFromUserLanguage(item.name) + ')';
        }

        if (mode === 'multi') {
            if (filters && filters.findIndex(e => e.id === item.id) > -1) {
                setFilters(filters => filters.filter(filterItem => filterItem.id !== item.id));
            } else {
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else if (mode === 'single') { //DONE
            //Check if exists one of the type
            if (filters.findIndex(e => e.type === item.type) > -1) {
                //If nonRemovable, check if do nothing or replace
                if (nonRemovable) {
                    //If id is the same
                    if (filters && filters.findIndex(e => e.id === item.id) > -1) { //Do nothing
                        return;
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                } else {
                    if (filters && filters.findIndex(e => e.id === item.id) > -1) { //Same id, remove it
                        setFilters(filters => filters.filter(filterItem => filterItem.type !== item.type));
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                }
            } else { //No same type, add it
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else {
            setFilters(optionFilters => [...optionFilters, item]);
        }
    }

    const handleCheckboxChange = (characteristic, item) => {
        handleFilter(characteristic, item);
        setSelectedCharacteristics((prevSelected) => {
            const itemExists = prevSelected.some(e => e.id === item.id);

            if (itemExists) {
                return prevSelected.filter(e => e.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const handleRadioChange = (characteristic, item) => {
        handleFilter(characteristic, item);
        setSelectedCharacteristic(item);

        if (selectedCharacteristic && nonRemovable) {
            return;
        } else {
            if(selectedCharacteristic === item){
                setSelectedCharacteristic(null);
            } else {
                setSelectedCharacteristic(item);
            }
        }
    };

    const renderCharacteristics = (characteristic) => {
        if (mode === 'multi') {
            if (characteristic.questionType === 'LIST' && characteristic.values) {
                return characteristic.values.map((item, index) => {
                    const isChecked = selectedCharacteristics.findIndex(e => e.id === item.id) > -1;
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleCheckboxChange(characteristic, item)}>
                            <Checkbox
                                checked={isChecked} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {Languages.getTranslationFromUserLanguage(item.value)}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                });
            }
            if (characteristic.questionType === 'DATE' && characteristic.ranges) {
                return characteristic.ranges.map((item, index) => {
                    const isChecked = selectedCharacteristics.findIndex(e => e.id === item.id) > -1;
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleCheckboxChange(characteristic, item)}>
                            <Checkbox
                                checked={isChecked} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {Languages.getTranslationFromUserLanguage(item.name)}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                });
            }
        } else if (mode === 'single') {
            if (characteristic.questionType === 'LIST' && characteristic.values) {
                return characteristic.values.map((item, index) => {
                    const isSelected = selectedCharacteristic ? (selectedCharacteristic as { id: string }).id === item.id : false;
                    <MenuItem
                        sx={{ paddingLeft: 1 }}
                        key={index}
                        onClick={() => handleRadioChange(characteristic, item)}>
                        <Radio
                            checked={isSelected}
                        />
                        <ListItemText primary={
                            <Typography style={{ fontSize: '14px' }}>
                                {Languages.getTranslationFromUserLanguage(item.value)}
                            </Typography>
                        } />
                    </MenuItem>
                });
            }
            if (characteristic.questionType === 'DATE' && characteristic.ranges) {
                return characteristic.ranges.map((item, index) => {
                    const isSelected = selectedCharacteristic ? (selectedCharacteristic as { id: string }).id === item.id : false;
                    <MenuItem
                        sx={{ paddingLeft: 1 }}
                        key={index}
                        onClick={() => handleRadioChange(characteristic, item)}>
                        <Radio
                            checked={isSelected}
                        />
                        <ListItemText primary={
                            <Typography style={{ fontSize: '14px' }}>
                                {Languages.getTranslationFromUserLanguage(item.name)}
                            </Typography>
                        } />
                    </MenuItem>
                });
            }
        } else {
            //TODO: grouped
        }
    }

    const renderCharacteristicsDescriptions = (items) => {
        if (!items) {
            return null;
        }

        return items.map((item, index) => {
            return (
                <NestedMenuItem
                    key={index}
                    sx={{ paddingLeft: 2 }}
                    renderLabel={() =>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {Languages.getTranslationFromUserLanguage(item.description)}
                                </Typography>
                            } />
                        </Box>
                    }
                    parentMenuOpen={open}
                    button={true}
                >
                    {renderCharacteristics(item)}
                </NestedMenuItem>
            );
        });
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderCharacteristicsDescriptions(characteristics)}
        </NestedMenuItem>
    );
};

export default SegmentNestedMenuItem;