const PreviewService =  function (Restangular) {
    'ngInject';
    var Preview = Restangular.one('company').one('preview');
    var methods = {};
    var stats = {};


    methods.sendInvitationPreview = function (content, callback) {
        Preview.one('sendInvitationSample').customPOST({ content: content }).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    return methods;

};

export default PreviewService;

