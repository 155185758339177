'use strict';

import {getHierarchyName} from "../../../../../shared/services/migrated/hierarchy.service";

function ConversationEntry($scope, Comments, People, AuthSrvc, $translate) {
    'ngInject';
    var _this = this;
    _this.maxLength = 100;


    function loadConversationData() {
        // Based on the conversation type, we will have to format one label or the other

        if (_this.conversation.type === 'COMMENT' && _this.conversation.commentId) {
            // Retrieve the employee from the comment:
            Comments.getOne(_this.conversation.commentId, function (err, comment) {
                if (err) {
                    console.error(err);
                } else if (comment) {

                    // Based on the comment, we can
                    _this.badgeImage = comment.feedbackTypeImage;

                    // Determine which label we will use based on the anonymity of the conversation
                    var feedbackTypeTranslation = $translate.instant('FEEDBACK_TYPE_' + comment.feedbackType);
                    if (_this.conversation.notAnonymous) {


                        // If the conversaton is not owned by the user, get the profile of the owner
                        People.getProfile(_this.conversation.employeeId, function (err, user) {
                            if (err) {
                                _this.ownerName =  $translate.instant('UNKNOWN_USER_NAME');
                            } else {
                                _this.sourceLabel = $translate.instant('CONVERSATION_COMMENT_SOURCE_NON_ANNONYMOUS', {
                                    name: user.name,
                                    type: feedbackTypeTranslation
                                });
                                _this.avatarName = user.name;
                                _this.displayName = true;

                            }
                        });
                    } else {
                        _this.sourceLabel = $translate.instant('CONVERSATION_COMMENT_SOURCE_ANNONYMOUS', {
                            areaName: comment.hierarchyName,
                            type: feedbackTypeTranslation
                        });
                        _this.primaryImage = 'images/annonymous_badge.png';
                        _this.displayName = false;
                    }
                }
            });

        } else if (_this.conversation.type === 'ENGAGEMENT_STATUS') {

            getHierarchyName(_this.conversation.hierarchyId, function (err, name) {
                if (err) {
                    name = '?';
                }
                _this.sourceLabel = $translate.instant('CONVERSATION_COMMENT_SOURCE_ENGAGEMENT_STATUS', {
                    areaName: name
                });
                _this.primaryImage = 'images/annonymous_badge.png';
                _this.badgeImage = 'images/engagement_conversation.svg';
                _this.displayName = false;
            });
        }

        // If the conversaton is not owned by the user, get the profile of the owner
        People.getProfile(_this.conversation.assignedTo, function (err, user) {
            if (err) {
                _this.ownerName =  $translate.instant('UNKNOWN_USER_NAME');
            } else {
                _this.ownerName = user.name;
                _this.ownerImage = user.profileImagePath;

            }
        });

    }

    this.$onInit = function () {
        loadConversationData();
    };

    _this.onPress = function () {
        if (_this.onSelect) {
            _this.onSelect(_this.conversation.id);
        }
    };

}

const hfConversationEntry = angular.module('happyForceApp').directive('hfConversationEntry', function () {
    return {
        scope: {
            conversation: '<',
            onSelect: '<'
        },
        restrict: 'E',
        template: require('./feedback-conversation-entry.controller.html'),
        controller: ConversationEntry,
        controllerAs: '$ctrl',
        bindToController: true
    };
}).controller('ConversationEntry', ConversationEntry);

export default hfConversationEntry;
