'use strict';

function SettingsWidgetIntegrationCtrl($scope, Languages, Analytics) {
    'ngInject'

    var _this = this;

    Analytics.trackEvent('settings_widget_integration');

    _this.bannerId = generateBannerId(_this.hierarchyId);

    _this.languages = Languages.appLanguages.filter(function (lang) {
        return lang.id.indexOf('-') === -1;
    });

    _this.languages.push({ id: 'pt', label: Languages.appLanguages[5].label });

    function generateBannerId(hierarchyId) {
        return 'hf-' + $scope.companyDetails.id + (hierarchyId ? '.' + hierarchyId : '');
    }

    ['hierarchyId', 'language'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind(_this, function () {
            return _this[key];
        }), function (n, o) {
            if (n == o) return;
            
            // remove previous
            var oldElement = document.getElementById(_this.bannerId);
            var newElement = document.createElement('div');
            _this.bannerId = newElement.id = generateBannerId(_this.hierarchyId);
            oldElement.parentNode.replaceChild(newElement, oldElement);
            
            showWidget();
        });
    });

    function showWidget() {
        var params = {
            token: $scope.companyDetails.id
        };

        if (_this.hierarchyId) {
            params.hierarchy = _this.hierarchyId;
        }

        if (_this.language) {
            params.lang = _this.language.id;
        }

        hfWidget(_this.bannerId, params);
    }

    setTimeout(showWidget, 500);
}

const hfWidgetIntegrationSettings = angular.module('happyForceApp')
    .directive('hfWidgetIntegrationSettings', function () {
        return {
            template: require('./settings_widget_integration.html'),
            controller: SettingsWidgetIntegrationCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfWidgetIntegrationSettings;