import React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';

const CommentShowRepliesAction = ({ comment, commentUpdatedCallback }) => {
    const CommentRepliesModal = getService('CommentRepliesModal');
    const $translate = getService('$translate');

    const showReplies = (event) => {
        event.stopPropagation();
        CommentRepliesModal.showModal(comment, commentUpdatedCallback);
    };

    return (
        <span>
            {!comment.privated  ? (
                <a onClick={showReplies}
                    style={{display:'flex'}}>
                    <i className="fa fa-commenting-o"></i>
                    <span className="m-l-xs">{comment.replies ? comment.replies.length : 0}</span>
                </a>
            ) : (
                <Tooltip title={$translate.instant('COMMENT_IS_PRIVATED_REPLY_IN_THREAD_ACTION_HINT')} placement="top">
                    <span className={'warm-grey'} style={{display:'flex'}}>
                        <i className="fa fa-commenting-o"></i>
                        <span className="m-l-xs">{comment.replies ? comment.replies.length : 0}</span>
                    </span>
                </Tooltip>
            )}
        </span>
    );
};

CommentShowRepliesAction.propTypes = {
    comment: PropTypes.object.isRequired,
    commentUpdatedCallback: PropTypes.func
};

angularize(CommentShowRepliesAction, 'hfCommentShowRepliesAction', angular.module('happyForceApp'), {
    comment: '<',
    commentUpdatedCallback: '='
});

export default CommentShowRepliesAction;
