'use strict';

//import Scope from './../../../../../../utilities/scope.utilities';

function CharacteristicsDeleteCtrl($scope, Characteristics, AuthSrvc, ErrorSvrc, $modalInstance, selectedCharacteristic, $translate) {

    //Scope.initialize($scope, ['executing', 'errored', 'isDeleteCharacteristic', 'isEditCharacteristic', 'isAddCharacteristic'], false);

    $scope.selectedCharacteristic = selectedCharacteristic;

    $scope.errorMsg = '';


    $scope.ok = function () {
        $scope.executing = true;
        $modalInstance.close($scope.selectedCharacteristic);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

}

angular.module('happyForceApp')
.controller('CharacteristicsDeleteCtrl', CharacteristicsDeleteCtrl);
