'use strict';

import {getHierarchies} from "../../../services/migrated/hierarchy.service";

var AreasSelectorCtrl = function (AuthSrvc, Filters, $scope, $translate, $modalInstance, selected, title) {
    'ngInject';
    var _this = this;

    _this.user = AuthSrvc.user();
    this.lastUpdate = new Date();

    _this.titleText = null;

    if (title) {
        _this.titleText = $translate.instant(title);
    } else {
        _this.titleText = $translate.instant('FILTER_BY_SEGMENTATION');
    }

    _this.openFilter = function () {
        _this.openedFilter = true;
        _this.selectedDepartmentBackup = _this.selectedDepartment;
        _this.selectedDepartmentsBackup = _this.selectedDepartments.slice(0);
    };

    var all = $translate.instant('ALL');


    _this.selectedDepartments = [];

    _this.isSelected = function (name) {
        if (_this.selectedDepartments[_this.selectedDepartments.length - 1].name == name) {
            return true;
        } else {
            return false;
        }
    };

    _this.setSelectedDepartment = function (selected) {
        if (_this.isInSelected(selected)) return;
        _this.selectedDepartment = selected;
        if (_this.selectedDepartments.length == 0 && _this.user.role != 'MANAGER') {
            _this.selectedDepartments.push({ name: all, id: null });
        }

        _this.selectedDepartments.push(selected);
        _this.hierarchies = selected.children;

        _this.hierarchy = selected;
        setHierarchy(selected);
    };

    _this.setSuperiorDepartment = function (selected) {
        _this.selectedDepartment = selected;
        var index = _this.selectedDepartments.indexOf(selected);
        _this.selectedDepartments = _this.selectedDepartments.slice(0, index + 1);

        if (selected.name == all) {
            getHierarchies(function (err, hierarchies) {
                _this.hierarchies = hierarchies;
            });
        } else {
            _this.hierarchies = selected.children;
        }
    };

    _this.resetSelection = function () {
        _this.openedFilter = false;
        _this.selectedDepartments = _this.selectedDepartmentsBackup;
        _this.selectedDepartment = _this.selectedDepartmentBackup;
        getHierarchies(function (err, hierarchies) {
            _this.hierarchies = hierarchies;
        });

    };

    function setHierarchy(hierarchy) {
        _this.hierarchy = {
            id: hierarchy.id,
            name: hierarchy.name
        };
    }

    _this.filter = function () {
        _this.openedFilter = false;
        setHierarchy(_this.selectedDepartment);
    };

    _this.applyFilters = function () {
        $modalInstance.close(_this.hierarchy);
    };

    _this.cancelFilter = function () {
        $modalInstance.close([]);
    };

    _this.isInSelected = function (id) {
        if (!selected || !selected.length) return false;
        return (selected.filter(function (item) {
            return item.id === id;
        }).length > 0);
    };


    _this.selectedDepartment = { name: all, id: null };
    _this.selectedDepartments = [{ name: all }];


    if ($scope.useFilter && Filters.level()) {
        _this.setSelectedDepartment(Filters.level());
        _this.setSuperiorDepartment(Filters.level());
    }

    this.$onInit = function () {
        getHierarchies(function (err, hierarchies) {
            _this.hierarchies = hierarchies;
        });
    };

};

angular.module('happyForceApp').controller('AreasSelectorCtrl', AreasSelectorCtrl);

const hfAreasSelector = angular.module('happyForceApp').directive('hfAreasSelector', function () {
    return {
        scope: {
            selected: '=',
            useFilter: '=',
            title: '=?'
        },
        template: require('./areas-selector.html'),
        controller: 'AreasSelectorCtrl',
        controllerAs: '$ctrl',
        transclude: true,
        bindToController: true
    };
});


export default hfAreasSelector;
