'use strict';
import hfSurveyBlock from "./components/survey_block/survey_block.controller";
import hfSurveyLanguageItem from "./components/survey_language_item/survey_language_item.controller";
import SurveyQuestionEditCtrl from "./components/survey_question_edit/survey_question_edit.controller";
import SurveyBlockEditCtrl from "./components/survey_block_edit/survey_block_edit.controller";
import SurveySettingsEditCtrl from "./components/survey_settings_edit/survey_settings_edit.controller";
import SurveyScheduleModal from "../components/survey_schedule_edit/survey_schedule_edit.controller";



function SurveysEditCtrl($scope, Languages, Settings, ErrorSvrc, $translate, $stateParams, Surveys, $uibModal, toaster, $state, AuthSrvc, SurveyScheduleModal) {
    'ngInject'
    var _this = this;


    var today = moment().toDate();
    var nextWeek = moment(today).add('days', 7).toDate();
    _this.hasAdminRoles = AuthSrvc.isADVISOR() || AuthSrvc.isADMIN();


    _this.isEdit = false;
    _this.editingQuestion = false;
    _this.editingBlock = null;
    _this.editingBlockQuestionIndex = null;
    _this.originalQuestion = null;
    _this.maxLength = 256;
    _this.surveyStep = 0;

    _this.availableLanguages = Languages.list;
    _this.surveyLanguages = [];
    _this.languageSelected = null;
    _this.templatedModified = false;

    _this.selectLanguage = function (language) {
        _this.selectedLanguage = language;
    };

    _this.addLanguage = function (language) {

        // Add language to survey
        Languages.setLangValue(_this.survey.title, null, language);
        Languages.setLangValue(_this.survey.description, null, language);
        if (_this.survey.blocks) {
            _this.survey.blocks.forEach(function (block) {
                Languages.setLangValue(block.title, null, language);
                Languages.setLangValue(block.description, null, language);
                if (block.questions) {
                    block.questions.forEach(function (question) {
                        Languages.setLangValue(question.title, null, language);
                        if (question.values) {
                            question.values.forEach(function (value) {
                                Languages.setLangValue(value.label, null, language);
                            });
                        };
                        if (question.firstValue) {
                            Languages.setLangValue(question.firstValue.label, null, language);
                        };
                        if (question.lastValue) {
                            Languages.setLangValue(question.lastValue.label, null, language);
                        }
                    });
                }
            });
        }

        _this.selectLanguage(language);

        processLanguages();


        //  _this.editSettingsSurvey();
    };

    _this.removeLanguage = function () {

        var languageName = Languages.getNameByCode(_this.selectedLanguage);
        swal({
            type: 'warning',
            title: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_TITLE', { language: languageName }),
            text: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_DESCRIPTION', { language: languageName }),
            allowEscapeKey: true,
            buttons: {
                cancel: {
                    visible: true,
                    text: $translate.instant('QUESTION_DELETE_LANGUAGE_CANCEL', { language: languageName }),
                    value: false
                },
                confirm: {
                    text: $translate.instant('QUESTION_DELETE_LANGUAGE_CONFIRM', { language: languageName }),
                    value: true,
                    className: 'swal-button--delete'
                }
            },
            confirmButtonColor: '#DD6B55',
            closeOnConfirm: true
        }).then(function (result) {
            if (result) {

                Languages.removeLangValue(_this.survey.title, _this.selectedLanguage);
                Languages.removeLangValue(_this.survey.description, _this.selectedLanguage);
                if (_this.survey.blocks) {
                    _this.survey.blocks.forEach(function (block) {
                        Languages.removeLangValue(block.title, _this.selectedLanguage);
                        Languages.removeLangValue(block.description, _this.selectedLanguage);
                        if (block.questions) {
                            block.questions.forEach(function (question) {
                                Languages.removeLangValue(question.title, _this.selectedLanguage);
                                if (question.values) {
                                    question.values.forEach(function (value) {
                                        Languages.removeLangValue(value.label, _this.selectedLanguage);
                                    });
                                };
                                if (question.firstValue) {
                                    Languages.removeLangValue(question.firstValue.label, _this.selectedLanguage);
                                };
                                if (question.lastValue) {
                                    Languages.removeLangValue(question.lastValue.label, _this.selectedLanguage);
                                }
                            });
                        }
                    });
                }

                _this.selectLanguage(null);

                processLanguages();

            }
        });
    };




    _this.editSettingsSurvey = function () {
        $uibModal.open({
            backdrop: 'static',
            template: require('./components/survey_settings_edit/survey_settings_edit.html'),
            controller: 'SurveySettingsEditCtrl',
            controllerAs: '$ctrl',
            resolve: {
                survey: _this.survey,
                selectedLanguage: function () { return _this.selectedLanguage; },
                isEdit: true
            }
        }).result.then(function (editedSurvey) {
            _this.templatedModified = true;
            _this.survey.title = editedSurvey.title;
            _this.survey.description = editedSurvey.description;
            _this.survey.activationRule = editedSurvey.activationRule;
            _this.survey.segmentation = editedSurvey.segmentation;

        }, function (res) {
            console.log('Modal ' + res);
        });
    };



    _this.editBlock = function ($index, block) {
        $uibModal.open({
            backdrop: 'static',
            template: require('./components/survey_block_edit/survey_block_edit.html'),
            controller: 'SurveyBlockEditCtrl',
            controllerAs: '$ctrl',
            resolve: {
                block: block,
                selectedLanguage: function () { return _this.selectedLanguage; },
                isEdit: block ? true : false
            }
        }).result.then(function (editedBlock) {
            _this.templatedModified = true;
            if (editedBlock && !editedBlock.questions)
                editedBlock.questions = [];

            if (editedBlock) {
                if ($index >= 0) {
                    _this.survey.blocks[$index] = editedBlock;
                } else {
                    _this.survey.blocks[_this.survey.blocks.length] = editedBlock;
                }
            } else {
                _this.survey.blocks.splice($index, 1);
            }
        }, function (res) {
            console.log('Modal ' + res);
        });
    };

    var validateSurvey = function () {
        // validate survey:
        delete _this.surveyError;
        if (!_this.survey.title.defaultValue || _this.survey.title.defaultValue.length === 0) {
            _this.surveyError = $translate.instant('SURVEY_DESIGN_NO_TITLE');
        } else if (!_this.survey.blocks || _this.survey.blocks.length === 0) {
            _this.surveyError = $translate.instant('SURVEY_DESIGN_ERROR_NO_BLOCKS');
        } else {
            _this.survey.blocks.forEach(function (block) {
                if (!block.questions || block.questions.length === 0)
                    _this.surveyError = $translate.instant('SURVEY_DESIGN_ERROR_EMPTY_BLOCK');
            });
        }
        return (typeof _this.surveyError === 'undefined');

    };

    function processResult(result, schedule) {
        var message = '';
        _this.survey = result;
        _this.templatedModified = false;

        if (_this.isEdit) {
            message = $translate.instant('SURVEY_TEMPLATE_UPDATED');
        } else {
            message = $translate.instant('SURVEY_TEMPLATE_CREATED');
        }


        // After creating the survey, we always switch to edit mode
        _this.isEdit = true;

        if (schedule) {


            if (!_this.hasTranslations()) {
                swal($translate.instant('SURVEY_TRANSLATIONS_MISSED'), '', 'error');
            } else {
                toaster.pop('success', null, message);
                _this.scheduleSurvey(_this.survey);
            }

        } else {
            swal(message, '', 'success');
            $state.go('surveys.list');
        }
    };


    _this.publish = function (schedule) {
        if (!validateSurvey()) return;

        if (_this.templateMode) {
            _this.templateEditDoneCallback(_this.survey);
        } else if (_this.isEdit) {
            Surveys.update(_this.survey.id, _this.survey, function (err, result) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    processResult(result, schedule);
                };
            });
        } else {
            Surveys.create(_this.survey, function (err, result) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    processResult(result, schedule);
                };
            });
        }

    };

    function processLanguages() {
        if (_this.survey && _this.survey.title && _this.survey.title.langValues) {
            _this.surveyLanguages = Object.keys(_this.survey.title.langValues);
        } else {
            _this.surveyLanguages = [];
        }
    };


    $scope.$on('$stateChangeStart', function (event, next, current) {
        if (_this.templatedModified && !confirm($translate.instant('SURVEY_CHANGES_WILL_BE_LOST'))) {
            event.preventDefault();
        }
    });

    _this.upBlock = function (blockIndex) {
        var block = _this.survey.blocks.splice(blockIndex, 1)[0];
        var newIndex = blockIndex > 0 ? blockIndex - 1 : 0;
        _this.survey.blocks.splice(newIndex, 0, block);
    };

    _this.downBlock = function (blockIndex) {
        var block = _this.survey.blocks.splice(blockIndex, 1)[0];
        var newIndex = blockIndex < _this.survey.blocks.length ? blockIndex + 1 : _this.survey.blocks.length;
        _this.survey.blocks.splice(newIndex, 0, block);
    };

    _this.upQuestion = function (blockIndex, questionIndex) {
        var block = _this.survey.blocks[blockIndex];
        var question = block.questions.splice(questionIndex, 1)[0];

        var newIndex = questionIndex > 0 ? questionIndex - 1 : 0;

        if (questionIndex === 0 && blockIndex !== 0) {
            block = _this.survey.blocks[blockIndex - 1];
            newIndex = block.questions.length;
        }

        block.questions.splice(newIndex, 0, question);
    };

    _this.downQuestion = function (blockIndex, questionIndex) {
        var block = _this.survey.blocks[blockIndex];
        var question = block.questions.splice(questionIndex, 1)[0];

        var newIndex = questionIndex < block.questions.length ? questionIndex + 1 : block.questions.length;

        if (questionIndex === block.questions.length && _this.survey.blocks.length > blockIndex + 1) {
            block = _this.survey.blocks[blockIndex + 1];
            newIndex = 0;
        }

        block.questions.splice(newIndex, 0, question);
    };

    _this.onBlockUpdated = function (block) {
        _this.templatedModified = true;

    };


    _this.hasTranslations = function () {
        var status = true;
        _this.surveyLanguages.forEach(function (lang) {
            if (status && !_this.validateLanguage(lang)) {
                status = false;
            }
        });
        return status;
    };

    _this.validateLanguage = function (code) {
        var response = [];

        response.push(_this.survey.title.langValues && !!_this.survey.title.langValues[code] || false);
        response.push(_this.survey.description.langValues && !!_this.survey.description.langValues[code] || false);
        _this.survey.blocks.forEach(function (block) {
            response.push(block.title.langValues && !!block.title.langValues[code] || false);
            block.questions && block.questions.forEach(function (question) {
                response.push(question.title.langValues && !!question.title.langValues[code] || false);
                question.values && question.values.forEach(function (value) {
                    response.push(value.label.langValues && !!value.label.langValues[code] || false);
                });
            });
        });

        return response.indexOf(false) === -1;
    };


    _this.scheduleSurvey = function (survey) {
        SurveyScheduleModal.showModal(survey, survey.schedulePlan, false, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                swal($translate.instant('SURVEY_SCHEDULE_PLAN_SET'), '', 'success');
            }
        });
    };



    _this.arrow = {
        action: function () {
            $state.go('surveys.list');
        }
    };


    this.$onInit = function () {

        if (_this.templateMode) {
            _this.survey = _this.template;
            processLanguages();
        } else if ($stateParams.surveyClone && $stateParams.surveyClone == 'CLONE') {
            _this.isEdit = false;
            _this.templatedModified = true;
            Surveys.get($stateParams.surveyId, function (err, survey) {
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    _this.survey = angular.copy(survey);
                    delete _this.survey.id;
                    delete _this.survey.schedulePlan;

                    processLanguages();
                }
            });
        } else if ($stateParams.surveyClone && $stateParams.surveyClone == 'TEMPLATE') {
            _this.isEdit = false;
            _this.templatedModified = true;
            Surveys.getTemplate($stateParams.surveyId, function (err, survey) {
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    _this.survey = angular.copy(survey);
                    _this.survey.templateId = _this.survey.id;
                    delete _this.survey.id;
                    delete _this.survey.schedulePlan;
                    processLanguages();
                }
            });

        } else if ($stateParams.surveyId) {
            Surveys.get($stateParams.surveyId, function (err, survey) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    _this.survey = survey;
                    _this.isEdit = true;
                    processLanguages();
                }
            });
        } else {
            _this.isEdit = false;
            _this.survey = {
                title: {
                    defaultValue: ''
                },
                description: {
                    defaultValue: ''
                },
                blocks: []
            };
            processLanguages();
            _this.editSettingsSurvey();
        }



        $(document).ready(function () {
            setTimeout(function () {
                $('.languageselector').select2();
            }, 1000);
        });
    };

}



import template from './surveys_edit.html';



const SurveysEditView = function($stateProvider) {
    'ngInject';
    $stateProvider.state('surveys.edit', {
        url: '/edit/:surveyId/:surveyClone',
        params: {
            surveyId: { squash: true, value: null },
            surveyClone: { squash: true, value: null }
        },
        controller: SurveysEditCtrl,
        controllerAs: '$ctrl',
        scope: {
            template: '=',
            templateMode: '=',
            templateEditDoneCallback: '='
        },
        template: template,
        data: { pageTitle: 'Surveys Edit', roles: ['ADMIN', 'ADVISOR'] }
    });
}


// TODO: Since we need the survey editor in the advisor tools, we need to define it as directive too in order to
//  to use it on the angular side of the Advisor Tools:

const hfSurveysEditDirective = angular.module('happyForceApp')
    .directive('hfSurveysEdit', function () {
        return {
            scope: {
                template: '=',
                templateMode: '=',
                templateEditDoneCallback: '='
            },
            template: template,
            controller: SurveysEditCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });


export default SurveysEditView;
