'use strict';

import Scope from "../../../../utilities/scope.utilities";
import FeedbackCharts from "../../feedback.charts";
import async from 'async';

function ReceivedFeedbackStatsCtrl($scope, HIService, Comments, ErrorSvrc, Filters, $translate, Analytics) {
    'ngInject';

    Scope.initialize($scope, ['bubbleChart', 'evolutionComparsionChart', 'participationChart'], {
        title: $translate.instant('LOADING_CHART')
    });


    this.$onInit = function () {

        var _this = this;
        _this.hasEnoughActiveEmployees = true;
        _this.loadCommentsStats = true;
        _this.totalComments = 0;
        _this.loading = true;

        _this.filters = null;

        _this.loadData = function () {
            _this.loading = true;
            var params = prepareParams();
            var commentStream = [];
            _this.totalComments = 0;

            commentStream.push(function (next) {
                _this.loadCommentsStats = true;
                HIService.get(function (err, hiQuestion) {
                    if (err) {
                        next(err);
                    } else {
                        Comments.stats.get(params, function (err, commentStats) {
                            _this.loading = false;
                            if (err) {
                                return next(err);
                            }

                            _this.loadCommentsStats = false;
                            _this.commentStats = commentStats;
                            _this.commentStatsChartLegend = [];

                            var values = {};
                            hiQuestion.values.forEach(function (value) {
                                values[value.id] = value.color;
                            });

                            _this.concepts = commentStats.concepts;

                            _this.concepts.forEach(function (concept) {
                                concept.color = values[concept.averageRating];
                            });

                            var feedbackTypeCounts = [];

                            _(['CONGRATULATION', 'CRITICISM', 'INFORMATION', 'SUGGESTION', 'RECOGNITION', 'OTHER']).each(function (type, i) {
                                var color = Comments.getFeedbackTypeColor(type);
                                feedbackTypeCounts.push({
                                    type: type,
                                    count: commentStats.feedbackTypeCount[type],
                                    color: color
                                });

                                if (type != 'OTHER' || (type === 'OTHER' && commentStats.feedbackTypeCount[type] > 0)) {
                                    _this.commentStatsChartLegend.push({
                                        label: $translate.instant('FEEDBACK_TYPE_RESULT_' + type) + ' (' + commentStats.feedbackTypeCount[type] + ')',
                                        color: color
                                    });
                                }

                                _this.totalComments = _this.totalComments + commentStats.feedbackTypeCount[type];

                            });

                            _this.commentStatsChartCfg = FeedbackCharts.commentsStatsChart(feedbackTypeCounts, $translate);

                            next();

                        });

                    }

                });

            });

            async.parallel(commentStream, function (err, data) {
                _this.loadCommentsStats = false;
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                }
            });

        };

        function prepareParams() {
            var params = {};

            params.from = _this.dates.from;
            params.to = _this.dates.to;
            params.groupId = _this.groups;

            if (_this.level) {
                params.hierarchyId = _this.level;
            }

            params.filters = _this.filters;

            return params;
        };

        _this.loadData();

        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                // Now compute the stats
                _this.loadData();
            }, true);
        });

        Analytics.trackEvent('feedback');
    };

}



const hfReceivedFeedbackStats = angular.module('happyForceApp').directive('hfReceivedFeedbackStats', function () {
    return {
        scope: {
            dates: '=',
            filters: '=',
            groups: '=',
            level: '='
        },
        restrict: 'E',
        template: require('./received-feedback-stats.html'),
        controller: ReceivedFeedbackStatsCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfReceivedFeedbackStats;
