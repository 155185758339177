'use strict';

const DateRangeCtrl = function ($scope, $timeout, AuthSrvc, Filters, $translate, Settings) {
    'ngInject';
    var _this = this;

    this.$onInit = function () {

        _this.datesRange = {
            startDate: _this.dates && _this.dates.from,
            endDate: _this.dates && _this.dates.to
        };

        $scope.$watch(angular.bind($scope, function () {
            return _this.dates;
        }), function (newVal, oldVal) {

            if (!newVal || newVal === oldVal) return;
            _this.datesRange = {
                startDate: _this.dates && _this.dates.from,
                endDate: _this.dates && _this.dates.to
            };
        }, true);

        var onChangeDatePicker = function () {
            _this.dates = {
                from: _this.datesRange.startDate.toDate(),
                to: _this.datesRange.endDate.toDate()
            };
        };

        var dateFormat = $translate.instant('DATE_FORMAT');
        if (dateFormat === 'DATE_FORMAT')
            dateFormat = Settings.defaultDateFormat;

        // Datepicker options
        this.dateRangeOptions = {
            eventHandlers: {
                'apply.daterangepicker': onChangeDatePicker
            },
            opens: 'left',
            showDropdowns: true,
            locale: {
                format: dateFormat,
                applyLabel: $translate.instant('CALENDAR.APPLY'),
                cancelLabel: $translate.instant('CALENDAR.CANCEL'),
                fromLabel: $translate.instant('CALENDAR.FROM'),
                toLabel: $translate.instant('CALENDAR.TO'),
                customRangeLabel: $translate.instant('CALENDAR.CUSTOM'),
                daysOfWeek: [
                    $translate.instant('CALENDAR.DAYWEEK.SU'),
                    $translate.instant('CALENDAR.DAYWEEK.MO'),
                    $translate.instant('CALENDAR.DAYWEEK.TU'),
                    $translate.instant('CALENDAR.DAYWEEK.WE'),
                    $translate.instant('CALENDAR.DAYWEEK.TH'),
                    $translate.instant('CALENDAR.DAYWEEK.FR'),
                    $translate.instant('CALENDAR.DAYWEEK.SA')
                ],
                monthNames: [
                    $translate.instant('CALENDAR.MONTH.JANUARY'),
                    $translate.instant('CALENDAR.MONTH.FEBRUARY'),
                    $translate.instant('CALENDAR.MONTH.MARCH'),
                    $translate.instant('CALENDAR.MONTH.APRIL'),
                    $translate.instant('CALENDAR.MONTH.MAY'),
                    $translate.instant('CALENDAR.MONTH.JUNE'),
                    $translate.instant('CALENDAR.MONTH.JULY'),
                    $translate.instant('CALENDAR.MONTH.AUGUST'),
                    $translate.instant('CALENDAR.MONTH.SEPTEMBER'),
                    $translate.instant('CALENDAR.MONTH.OCTOBER'),
                    $translate.instant('CALENDAR.MONTH.NOVEMBER'),
                    $translate.instant('CALENDAR.MONTH.DECEMBER')
                ],
                firstDay: parseInt($translate.instant('CALENDAR.FIRSTDAY'))
            },
            alwaysShowCalendars: true,
            linkedCalendars: false,
            maxDate: moment().add(1, 'days'),
            ranges: {}
        };

        // Periods labels
        this.dateRangeOptions.ranges[$translate.instant('CALENDAR.TODAY')] = [moment(), moment()];
        this.dateRangeOptions.ranges[$translate.instant('CALENDAR.YESTERDAY')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        this.dateRangeOptions.ranges[$translate.instant('CALENDAR.LAST7DAYS')] = [moment().subtract(6, 'days'), moment()];
        this.dateRangeOptions.ranges[$translate.instant('CALENDAR.LAST30DAYS')] = [moment().subtract(29, 'days'), moment()];
        this.dateRangeOptions.ranges[$translate.instant('CALENDAR.THISMONTH')] = [moment().startOf('month'), moment().endOf('month')];

        // jscs:disable maximumLineLength
        this.dateRangeOptions.ranges[$translate.instant('CALENDAR.LASTMONTH')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        // jscs:enable maximumLineLength

        // Prev Period
        this.prevPeriod = function () {
            var momentEndDate = moment(_this.datesRange.endDate);
            var momentStartDate = moment(_this.datesRange.startDate);

            var daysBetween = momentEndDate.diff(momentStartDate, 'd');
            _this.datesRange = {
                startDate: momentStartDate.subtract(daysBetween, 'd'),
                endDate: momentEndDate.subtract(daysBetween, 'd')
            };

            onChangeDatePicker();
        };

        // Next Period
        this.nextPeriod = function () {
            var momentEndDate = moment(_this.datesRange.endDate);
            var momentStartDate = moment(_this.datesRange.startDate);

            var daysBetween = momentEndDate.diff(momentStartDate, 'd');
            _this.datesRange = {
                startDate: momentStartDate.add(daysBetween, 'd'),
                endDate: momentEndDate.add(daysBetween, 'd')
            };
            onChangeDatePicker();
        };
    };
};

const DateRangeComponent = angular.module('happyForceApp')
  .directive('hfDateRange', function () {
    return {
        scope: {
            dates: '='
        },
        template: require('./daterange.html'),
        controller: DateRangeCtrl,
        controllerAs: 'dr',
        transclude: true,
        bindToController: true
    };
});

export default DateRangeComponent;
