import async from "async";
import {getHierarchyName} from "./migrated/hierarchy.service";

const SegmentationNamesService = function (Characteristics, Groups, Languages, $translate) {
    'ngInject';

    var methods = {};

    /* Given a list of segments, returns and array of segment names */
    methods.getNames = function (hierarchyIds, segmentationPairs, groupIds, callback) {
        var streams = [];

        if (hierarchyIds) {
            hierarchyIds.forEach(function (hierarchyId) {
                streams.push(function (next) {
                    if (hierarchyId) {
                        getHierarchyName(hierarchyId, function (err, name) {
                            if (err) {
                                next(err);
                            } else {
                                next(null, {
                                    type: 'hierarchy',
                                    name: name
                                });
                            }
                        });
                    } else {
                        next(null, {
                            type: 'hierarchy',
                            name: $translate.instant('ALL_AREAS')
                        });

                    }

                });
            });
        }


        if (segmentationPairs) {
            segmentationPairs.forEach(function (segmentFilter) {
                streams.push(function (next) {
                    var segment = Characteristics.getCharacteristicById(segmentFilter.id);
                    if (!segment)
                        next(null, '?');
                    else {
                        // Get the segment name
                        const segmentName = segment.description;

                        // Now the value names
                        var segmentValueNames = [];
                        segmentFilter.values.forEach(function (valueId) {
                            var valueName = Languages.getTranslationFromUserLanguage
                            (Characteristics.getCharacteristicValueName(segmentFilter.id, valueId));

                            segmentValueNames.push(valueName);
                        });

                        // Finally, compose a segment with value names:
                        var segmentNameWithValues = segmentName + ' (' + segmentValueNames.join(', ') + ')';

                        next(null, {
                            type: 'segment',
                            name: segmentNameWithValues
                        });
                    }
                });
            });
        }
        if (groupIds) {
            groupIds.forEach(function (groupId) {
                streams.push(function (next) {
                    Groups.getGroup(groupId, function (err, group) {
                        if (err) {
                            next(err, '?');
                        } else {
                            next(null, {
                                type: 'group',
                                name: group.name
                            });
                        }
                    });
                });
            });
        }


        // First retrieve the groupNameStreams; once is done, retrieve the streams
        async.parallel(streams, function (err, results) {
            callback(err, results);
        });
    };

    return methods;

};

export default SegmentationNamesService;

