import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getService, angularize } from 'reactInAngular';

const MeasuringNowBox = ({ to, score }) => {
    const [days, setDays] = useState(null);

    const $translate = getService('$translate');

    useEffect(() => {
        const currentDate = new Date();
        const lastDayOfMonth = new Date(to.getFullYear(), to.getMonth() + 1, 0);
        setDays(lastDayOfMonth.getDate() - currentDate.getDate());
    }, [to]);

    return (
        <section className="measuring-now-box">
            <div className="row">
                <div className="col-xs-12 measuring-now-box-row">
                    <span className="measuring-now-box-column m-r-md">
                        <img className="img-sm" src="/images/info_icon.svg" alt="Info" />
                    </span>
                    <span className="measuring-now-box-column">
                        <strong className="big-style">{$translate.instant('ENGAGEMENT_MEASURING_NOW')}</strong>
                        <div className="small-style m-t-xs">
                            {$translate.instant('ENGAGEMENT_MEASURING_NOW_REMAINING_DAYS', { days: days })}
                        </div>
                        <div className="medium-style m-t-xs" dangerouslySetInnerHTML={{ __html: $translate.instant('ENGAGEMENT_MEASURING_NOW_GO_TO_PREVIOUS') }}></div>
                    </span>
                </div>
            </div>
        </section>
    );
};

MeasuringNowBox.propTypes = {
    to: PropTypes.instanceOf(Date).isRequired,
    score: PropTypes.number
};

angularize(MeasuringNowBox, 'hfEngagementMeasuringNowBox', angular.module('happyForceApp'), {
    to: '=',
    score: '='
});

export default MeasuringNowBox;
