import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {SegmentFilter} from '../../utils/filteringUtils';
import useScoreNames from '../../../../../shared/hooks/useScoreNames';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {ValuationTypography} from '../../../../../shared/new-components/hf-styled-components/ValuationTypography';
import VariationArrow from './VariationArrow';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Style from '../../../../../utilities/style.utils';
import Charts from '../../../../../utilities/charts.utilities';


interface ScoreQuestionListProps {
    scoreId: string;
    to: Date;
    dateGrouping: string;
    segmentationFilter: SegmentFilter | null;
    scoreData: any | null;
    selectedResult: any | null;
}


interface FacorChartConfigData {
    [factorId: string]: object
}


const ScoreQuestionList = (({scoreId, scoreData, selectedResult}:ScoreQuestionListProps) => {

    const {  factorNames, questionNames, scoreNamesLoading } = useScoreNames(scoreId);

    const [factorChartData, setFactorChartData] = useState<FacorChartConfigData | undefined>();



    const generateChartConfig = () => {


        if (!selectedResult || scoreNamesLoading) {
            setFactorChartData(undefined);
        } else {
            const newFactorChartData: FacorChartConfigData = {};

            let max = 0;
            let min = 0;


            selectedResult?.factors?.forEach((factor: any) => {


                const categories =[];

                const negativeData = [];
                const neutralNegativeData = [];
                const neutralPositiveData = [];
                const positiveData = [];



                factor.questions.forEach((question: any) => {
                    categories.push({
                        name: questionNames[question.id]
                    });

                    let negative = 0;
                    let neutralNegative = 0;
                    let neutralPositive = 0;
                    let positive = 0;

                    // Let's add the distribution of the votes
                    Object.keys(question.distribution).forEach((key) => {
                        const keyNumber = parseInt(key);
                        if (keyNumber <=3) {
                            negative = negative + (-1 * question.distribution[key]);
                        } else if (keyNumber <= 5) {
                            neutralNegative = neutralNegative + (-1 * question.distribution[key]);
                        } else if (keyNumber <= 8) {
                            neutralPositive = neutralPositive + question.distribution[key];
                        } else {
                            positive = positive + question.distribution[key];
                        }
                    });

                    if ((negative + neutralNegative) < min) {
                        min = (negative + neutralNegative);
                    }

                    if ((neutralPositive + positive) > max) {
                        max = (neutralPositive + positive);
                    }

                    negativeData.push(negative);
                    neutralNegativeData.push(neutralNegative);
                    neutralPositiveData.push(neutralPositive);
                    positiveData.push(positive);
                });

                const fontConfig =  Charts.getFontConfig('ultra');



                const chartConfig = {
                    chart: {
                        ...fontConfig,
                        type: 'bar',
                        fontFamily: fontConfig.fontFamily
                    },

                    xAxis: [{
                        categories: categories,
                        reversed: false,
                        tickWidth:0,
                        labels: {
                            step: 1,
                            useHTML: true,
                            allowOverlap: true,
                            style: {
                                wordBreak: 'break-word',
                                textOverflow: 'allow',
                                width: 150
                            }
                        },
                    }],
                    yAxis: {
                        min: min,
                        max: max,
                        title: {
                            text: 'Votes'
                        },
                        plotLines: [{
                            color: 'black', // Color of the neutral range line
                            width: 1,
                            value: 0, // Central point
                            dashStyle: 'Dash',
                            zIndex: 5
                        }]
                    },

                    plotOptions: {
                        series: {
                            stacking: 'normal',
                            borderRadius: '2',
                            groupPadding:0,
                            pointWidth:20,
                            pointPadding: 0
                        }
                    },
                    title: {
                        text: ''
                    },



                    series: [{
                        name: 'Negative',
                        data: negativeData,
                        color: Style.bad
                    },
                    {
                        id: 'neutral',
                        name: 'Neutral Negative',
                        data: neutralNegativeData,
                        color: Style.warning
                    },

                    {
                        name: 'Positive',
                        data: positiveData,
                        color: Style.good
                    },
                    {
                        name: 'Neutral Positive',
                        data: neutralPositiveData,
                        color: Style.neutral

                    }]
                };


                newFactorChartData[factor.id] = chartConfig;
            });

            console.log(min, max);

            // Let's set the min and max values for the y-axis
            Object.keys(newFactorChartData).forEach((key) => {
                newFactorChartData[key].yAxis.min = min;
                newFactorChartData[key].yAxis.max = max;
            });

            setFactorChartData(newFactorChartData);
        }
    }

    useEffect(() => {
        if (selectedResult && !scoreNamesLoading)
        {generateChartConfig();}
    },[selectedResult, scoreNamesLoading]);


    const renderFactor = (factor: any) => {
        if (!factorChartData || !factorChartData[factor.id]) {
            return null;
        }
        return (
            <Grid item key={factor.id} sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <Typography variant="h6">
                    {factorNames[factor.id]}
                </Typography>

                <Box sx={(theme) => ({marginTop: theme.spacing(1)})}>
                    <HighchartsReact highcharts={Highcharts} options={factorChartData[factor.id]} />
                </Box>

            </Grid>
        );
    }

    if (!scoreData || scoreNamesLoading) {
        return null;
    }

    return (
        <Grid container spacing={2} height={'100%'} overflow={'scroll'}>
            {selectedResult?.factors?.map((factor: any) => {
                return renderFactor(factor)
            })}
        </Grid>
    )

})

export default ScoreQuestionList;
