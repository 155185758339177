'use strict';

import Numbers from "../../../utilities/number.utilities";
import Dates from "../../../utilities/date.utilities";




import template from './detail.html';
import {getHierarchyName} from "../../../shared/services/migrated/hierarchy.service";
import ScorePositioningBox from './components/positioning_box/score_positioning_box.component';
import ScoreEngagementDetailOverview from './components/overview/score_overview.component';
import ScoreDetailFactorsList from './components/factors_list/factors_list';
import {dateQueryParamToDate} from '../../../utilities/navigation_state.utilities';
import ScoreReportModal
    from 'root/app/components/engagement/score_detail/components/score_report_modal/scoreReportModal';

function EngagementDetailCtrl($scope,$rootScope, Filters, $stateParams, HIService, Scores, ScoresV2Srvc, $translate, ErrorSvrc, $state, Settings, $intercom, Reports, toaster, Analytics) {
    'ngInject';
    var _this = this;

    _this.grouping = 'MONTH';


    _this.dateFormat = Settings.defaultMonthDateFormat;

    _this.to = $stateParams.to ? dateQueryParamToDate($stateParams.to) : Filters.endDate();



    _this.from = moment(_this.to).subtract(12, 'M').toDate();
    _this.scoreId = $stateParams.scoreId;
    _this.arrow = {
        action: function () {
            $state.go($rootScope.previousState, $rootScope.previousStateParams)
        }
    };

    _this.loading = true;
    _this.stats = {};
    _this.results = [];
    _this.factors = [];
    var factorsTemp = {};

    _this.numDecimals = 1;

    _this.hasEnoughActiveEmployees = true;
    _this.detailView = 1;


    _this.level = Filters.level() || null;
    _this.filters = Filters.segments() || null;
    _this.groups = Filters.groups() || null;

    if ($stateParams.hierarchyId) {
        _this.level = {
            id: $stateParams.hierarchyId
        };

        // TODO: We have to do this, because the hierarchies filter doesn't display the name properly...
        getHierarchyName(
            $stateParams.hierarchyId,
            function (err, hierarchyName) {
                if (!err) {
                    _this.level.name = hierarchyName;
                }
            }
        );
    }

    if ($stateParams.filters) {
        _this.filters = $stateParams.filters;
    }

    if ($stateParams.groupId) {
        _this.groups = [$stateParams.groupId];
    }

    _this.prevMonth = function () {
        _this.to = moment(_this.to).subtract(1, 'month').toDate();
        _this.from = moment(_this.to).subtract(12, 'M').toDate();
    };

    _this.nextMonth = function () {
        var nextDate = moment(_this.to).add(1, 'month').toDate();
        if (nextDate.getTime() <= Date.now()) {
            _this.to = nextDate;
        }
        _this.from = moment(_this.to).subtract(12, 'M').toDate();
    };

    _this.textForBenchmark = function (value, benchmark, key) {
        var difference = value - benchmark;
        var formattedDiff = Numbers.roundNumber(difference, _this.numDecimals);

        var keys = { benchmark: benchmark, difference: Math.abs(formattedDiff) };
        if (difference == 0) {
            return $translate.instant(key + '_NEUTRAL', keys);
        } else if (difference > 0) {
            return $translate.instant(key + '_POSITIVE', keys);
        } else {
            return $translate.instant(key + '_NEGATIVE', keys);
        }
    };

    _this.processStats = function (error, response) {
        if (error) {
            if (ErrorSvrc.isNotEnoughActiveEmployees(error)) {
                _this.hasEnoughActiveEmployees = false;
                _this.stats = {};
                _this.results = [];
                _this.factors = [];
            } else {
                Rollbar.error('Error getting engagement detail stats', error);
            }
            _this.loading = false;
            return;
        }

        _this.status = response.status;
        _this.filtered = response.filtered;
        _this.hasEnoughActiveEmployees = true;
        if (!response.results || response.results.length === 0) {
            _this.loading = false;
            return;
        }

        var numResults = response.results.length;
        var current = response.results[numResults - 1];
        var value = current.result;
        var variation = current.variation;

        _this.stats = {
            quality: current.quality,
            valuation: current.valuation,
            value: Numbers.roundNumber(value, _this.numDecimals),
            variation: Numbers.roundNumber(variation, 1),
            measuringNow: current.measuringNow,
            measureDate: current.from,
            companyBenchmark: current.companyBenchmark,
            industryBenchmark: current.industryBenchmark,
            globalBenchmark: current.globalBenchmark,
            ranking: current.ranking
        };

        if (numResults > 1) {
            var previousValue = response.results[numResults - 2].result;
            _this.stats.previousValue = Numbers.roundNumber(previousValue, _this.numDecimals);
        }

        _this.results = response.results;
        _this.measuringNow = current.measuringNow;
        _this.hiImpact = current.hiImpact;
        _this.enpsImpact = current.enpsImpact;

        _this.loading = false;
    };

    _this.prepareParams = function () {
        var to = Dates.lastDayOfMonth(_this.to);
        var from = Dates.firstDayOfMonth(_this.from);
        var params = {
            from: from,
            to: to,
            scoreId: _this.scoreId,
            filters: _this.filters,
            groupId: _this.groups,
            grouping: _this.grouping
        };

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        return params;
    };

    _this.loadResults = function () {
        _this.loading = true;
        var params = _this.prepareParams();
        ScoresV2Srvc.stats.get(_this.scoreId, params, _this.processStats);
    };

    _this.loadScoreInfo = function () {
        Scores.get(_this.scoreId, function (error, score) {
            if (error) {
                $scope.loadingScore = false;
                ErrorSvrc.showErrorModal(error);
                return;
            }

            _this.score = score;
            var monthsToSubtract = Math.min(_this.score.numberMeasurements * _this.score.periodMonths, 12);
            _this.from = moment(moment(_this.to).subtract(monthsToSubtract, 'months').utc().format('YYYY-MM-DD HH:mm:ss')).toDate();

            _this.score.translation = {
                score: score.name,
                score_frequency: $translate.instant('SCORE_FREQUENCY_' + _this.score.schedule)
            };

            if (typeof $scope.viewEvolution === 'undefined') {
                $scope.viewEvolution = score.type != 'ENPS';
            }

            _this.scoreType = score.type;

            _this.itemsPerPage = Math.min(score.numberMeasurements, 12);
            score.factors.forEach(function (factor) {
                factorsTemp[factor.id] = factor;
            });

            _this.loadResults();
        });
    };

    _this.navigateToDetail = function (route, params) {
        $state.go(route, params);
    };



    _this.onShowExportModal = function () {
        var params = _this.prepareParams();
        _this.reportTo = params.to;
        _this.reportFrom = params.from;
        _this.showExportModal = true;
    };

    _this.onExportModalClose = (reportParams) => {
        _this.showExportModal = false;
        if (reportParams) {
            _this.exportingExcelReport = true;
            var params = _this.prepareParams();

            delete params.from;
            delete params.to;

            if (reportParams.reportType === 'GROUPING') {
                params.groupingType = reportParams.groupingType;
            }

            Reports.score(_this.scoreId, reportParams.from, reportParams.to, params, function (err) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                    _this.exportingExcelReport = false;
                    Analytics.trackEvent('score_detail', 'excel_report', 'false');
                } else {
                    _this.exportingExcelReport = false;
                    toaster.pop('success', null, ($translate.instant('ENGAGEMENT_EXPORT_SENT')));
                    Analytics.trackEvent('score_detail', 'excel_report', 'success');
                }
            });
        }

        $scope.$apply();




    };



    _this.onInsightDetailClick = function (scoreId, hierarchyId, filters) {

        if (hierarchyId) {
            _this.level = {
                id: hierarchyId
            };
            // TODO: We have to do this, because the hierarchies filter doesn't display the name properly...
            getHierarchyName(
                $stateParams.hierarchyId,
                function (err, hierarchyName) {
                    if (!err) {
                        _this.level.name = hierarchyName;
                    }
                }
            );
        }
        _this.filters = filters;

    }

    this.$onInit = function () {



        var loadData = function () {
            _this.loadScoreInfo();

            _this.hasFilters = !!_this.filters || !!(_this.groups && _this.groups.length > 0) || !!(_this.level && _this.level.id);
        };

        loadData();

        ['to', 'level', 'filters', 'groups', 'grouping'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                loadData();
            }, true);
        });

        $intercom.trackEvent('dashboard_visited_engagement_detail');
    };
};



function ScoreDetailView($stateProvider) {
    'ngInject';
    $stateProvider.state('engagement.scoreDetail', {
        url: '/detail/score/:scoreId?hierarchyId&filters&groupId&to',
        controller: EngagementDetailCtrl,
        controllerAs: '$ctrl',

        template: template,
        data: { pageTitle: 'ENGAGEMENT_DETAIL', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
}

export default ScoreDetailView;
