'use strict';

import HiQuestionEditModal from './components/hi_question_edit/hi_question_edit.controller';

function HiQuestionConfigCtrl($rootScope, HIService, HiQuestionsEditModal, Restangular, Analytics, $translate, $scope, $location, AuthSrvc, CompanySvrc, ErrorSvrc, $stateParams, $state, $uibModal, Languages) {
    'ngInject'

    var _this = this;
    $scope.Auth = AuthSrvc;

    $scope.selectedLanguage = null;

    $scope.questionLanguages = [];

    HIService.get(function (err, question) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
            $scope.loading = false;
            $scope.isError = true;
            return;
        }

        $scope.question = question;
        $scope.loading = false;

    });

    $scope.selectLanguage = function (language) {
        $scope.selectedLanguage = language;
    };

    $scope.addLanguage = function (language) {
        $scope.selectLanguage(language);
        $scope.editQuestion(language);
    };

    $scope.removeLanguage = function (language) {

        var languageName = Languages.getNameByCode(language);
        swal({
            type: 'warning',
            title: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_TITLE', { language: languageName }),
            text: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_DESCRIPTION', { language: languageName }),
            allowEscapeKey: true,
            buttons: {
                cancel: {
                    visible: true,
                    text: $translate.instant('QUESTION_DELETE_LANGUAGE_CANCEL', { language: languageName }),
                    value: false
                },
                confirm: {
                    text: $translate.instant('QUESTION_DELETE_LANGUAGE_CONFIRM', { language: languageName }),
                    value: true,
                    className: 'swal-button--delete'
                }
            },
            confirmButtonColor: '#DD6B55',
            closeOnConfirm: true
        }).then(function (result) {
            if (result) {
                const updatedQuestion = angular.copy($scope.question);

                // Remove the language
                Languages.removeLangValue(updatedQuestion.title, language);

                updatedQuestion.values.forEach(function (value) {
                    Languages.removeLangValue(value.label, language);
                });

                updateQuestion(updatedQuestion, function () {
                    $scope.questionLanguages = Object.keys(updatedQuestion.title.langValues);
                });
            }
        });
    };

    $scope.editQuestion = function ()
     {
        HiQuestionsEditModal.showModal(angular.copy($scope.question), $scope.selectedLanguage, function (updatedQuestion) {
            if(updatedQuestion){
                $scope.executing = true;
                HIService.update(updatedQuestion, function (err, question) {
                    if (err) {
                        Analytics.trackEvent('settings_company_hi', 'update', 'fail');
                        ErrorSvrc.showErrorModal(err);
                    } else {
                        Analytics.trackEvent('settings_company_hi', 'update', 'success');
                        $scope.question = question;
                        if (callback)
                            callback();
                        swal($translate.instant('QUESTION_UPDATED'), '', 'success');
                    }
        
                    $scope.executing = false;
                });
            }
        });
    };

    // Monitor language changes of the question
    ['question'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind($scope, function () {
            return $scope[key];
        }), function (n, o) {
            if (n != o) {
                if (n.title.langValues) {
                    $scope.questionLanguages = Object.keys(n.title.langValues);
                }
            };

        });
    });

    ['selectedLanguage'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind($scope, function () {
            return $scope[key];
        }), function (n, o) {
            console.log('El selected es ' + _this.selectedLanguage);

        });
    });

}

const hfHiQuestionConfig = angular.module('happyForceApp')
    .directive('hiQuestionConfig', function () {
        return {
            template: require('./hi_question_config.html'),
            controller: HiQuestionConfigCtrl,
            controllerAs: 'qcc',
            bindToController: true
        };
    });

export default hfHiQuestionConfig;