'use strict';

function TagsCtrl(Comments, HIService, ErrorSvrc, $log, $scope) {
    'ngInject';
    var _this = this;

    function handleErr(err) {
        if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
            _this.hasEnoughActiveEmployees = false;
        } else {
            ErrorSvrc.showErrorModal(err);

        }
    }


    function loadData() {

        var dateParams = {
            from: _this.dates.from,
            to: _this.dates.to,
            hierarchyId: _this.level && _this.level.id,
            filters: _this.filters,
            groupId: _this.groups
        };

        HIService.get(function (err, hiQuestion) {
            if (err) {
                handleErr(err);
            } else {
                var values = {};
                hiQuestion.values.forEach(function (value) {
                    values[value.id] = value.color;
                });

                Comments.stats.get(dateParams, function (err, commentStats) {
                    if (err) {
                        handleErr(err);
                    } else {
                        // Process each concept, and asign the color
                        _this.concepts = commentStats.concepts;

                        _this.concepts.forEach(function (concept) {
                            concept.color = values[concept.averageRating];
                        });

                        _this.hasEnoughActiveEmployees = true;
                    }

                });

                _this.hasEnoughActiveEmployees = true;
            }

        });

    };


    this.$onInit = function () {

        _this.concepts = [];


        loadData();

        var lastExecution;
        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                loadData();
            }, true);
        });
    };
}


const CommentTagsComponent = angular.module('happyForceApp')
    .directive('hfTagsBox', function () {
        return {
            scope: {
                dates: '=',
                level: '=',
                filters: '=',
                groups: '='
            },
            template: require('./tags.html'),
            controller: TagsCtrl,
            controllerAs: 'tg',
            bindToController: true
        };
    });

export default CommentTagsComponent;
