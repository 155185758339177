'use strict';

import {getENPSConfiguration, saveENPSConfiguration} from "../../../../shared/services/migrated/enps.service";

function SettingsENPSCtrl($scope, $translate, ErrorSvrc, Analytics, toaster) {

    var _this = this;

    _this.saving = false;


    Analytics.trackEvent('settings_enps');


    _this.$onInit = function () {
        getENPSConfiguration(function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.enpsConfiguration = result;
                $scope.$apply();
            }
        });
    };

    _this.saveENPSSettings = function () {
        _this.saving = true;
        saveENPSConfiguration(_this.enpsConfiguration, function (err, result) {

            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop('success', $translate.instant('SETTINGS_ENPS_CONFIG_SAVED'));
                _this.enpsConfiguration = result;
            }
            _this.saving = false;
            $scope.$apply();
        });
    };
}

const hfSettingsEnps = angular.module('happyForceApp')
    .directive('hfSettingsEnps', function () {
        return {
            template: require('./enps_config.html'),
            controller: SettingsENPSCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSettingsEnps;
