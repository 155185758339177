'use strict';

var OptionsTaggingFilterCtrl = function ($rootScope, $compile, $scope, $window) {
    'ngInject';

    var _this = this;

    _this.optionsFilters = [];
    _this.optionNames = {};
    _this.optionTypeLabelOverrides = {};

    function removeFilter(id) {
        // First, remove the element from the list
        if (_this.mode == 'multi') {
            _this.optionsFilters = _.without(_this.optionsFilters, id);
        } else {
            _this.optionsFilters = [];
        }
        _this.notifyFilterChanges();
    }

    function renderTags() {

        // Based on the mode, we need to render the tags differently
        if (_this.mode == 'multi') {
            // Multi mode:
            if (_this.optionsFilters.length > 0) {
                // Look for the already inserted elements
                // Let's retrieve all the hierarchy names; we expect only one on multi and single modes.
                _this.optionsFilters.forEach(function (optionId) {
                    // Only add the names already not added:
                    if (!_this.tagFilterMenu.hasTag(optionId)) {

                        const typeLabel = _this.optionTypeLabelOverrides[optionId] ? _this.optionTypeLabelOverrides[optionId] : _this.typeLabel;
                        _this.tagFilterMenu.insertFilterTag(optionId, _this.type, _this.optionNames[optionId],
                            typeLabel,  _this.nonRemovable, removeFilter);
                    }
                });

            }
        } else if (_this.mode == 'single') {
            // Single mode:
            if (_this.optionsFilters.length > 0) {
                const optionId = _this.optionsFilters[0];
                const typeLabel = _this.optionTypeLabelOverrides[optionId] ? _this.optionTypeLabelOverrides[optionId] : _this.typeLabel;
                _this.tagFilterMenu.insertFilterTag('dummy', _this.type, _this.optionNames[optionId], typeLabel,
                    _this.nonRemovable, removeFilter);
            }
        } else {
            // Grouped mode:
            if (_this.optionsFilters.length > 0) {
                var streams = [];
                _this.optionsFilters.forEach(function (optionId) {
                    // Only add the names already not added:
                    streams.push(function (next) {
                        next(null, {
                            id: optionId,
                            name: _this.optionNames[optionId]
                        });
                    });
                });

                async.parallel(streams, function (err, options) {
                    if (err) {
                        console.log(err);
                    }

                    // First map all options by their type
                    var optionsByType = [];
                    options.forEach(function (option) {
                        const typeLabel = _this.optionTypeLabelOverrides[option.id] ? _this.optionTypeLabelOverrides[option.id] : _this.typeLabel;

                        // Find the options containing this label:
                        var optionsByTypeLabel = _.find(optionsByType, function (o) {
                            return o.label == typeLabel;
                        });

                        // If is not found, let's create it:
                        if (!optionsByTypeLabel) {
                            optionsByTypeLabel = {
                                label: typeLabel,
                                names: []
                            };
                            optionsByType.push(optionsByTypeLabel);
                        }
                        optionsByTypeLabel.names.push(option.name);
                    });

                    optionsByType.forEach(function (optionsByTypeLabel) {
                        const name = optionsByTypeLabel.names.join(', ');
                        const key = optionsByTypeLabel.label + '_dummy';
                        _this.tagFilterMenu.insertFilterTag(key, _this.type, name, optionsByTypeLabel.label,  _this.nonRemovable, removeFilter);
                    });
                });
            }
        }
    }

    _this.selectOption = function (option, siblings, $event) {

        // If the option is already added, this is a removal
        if (_this.optionsFilters.indexOf(option.id) >= 0) {
            _this.tagFilterMenu.removeTag(option.id, _this.type);
            _this.optionsFilters = _.without(_this.optionsFilters, option.id);
        } else {

            // In single mode, we clean the list of selected hierarchies
            if (_this.mode == 'single') {
                _this.optionsFilters = [];
            }

            // If this option is exclusive, we have to remove all the siblings
            if (option.exclusive && siblings && _this.mode != 'single') {
                siblings.forEach(function (sibling) {
                    if (sibling.id != option.id) {
                        _this.optionsFilters = _.without(_this.optionsFilters, sibling.id);
                        removeFilter(sibling.id);
                        _this.tagFilterMenu.removeTag(sibling.id, _this.type);
                    }
                });
            }

            // Also if remove all options that are exclusive but are not this one
            if (siblings && _this.mode != 'single') {
                siblings.forEach(function (sibling) {
                    if (sibling.exclusive && sibling.id != option.id) {
                        _this.optionsFilters = _.without(_this.optionsFilters, sibling.id);
                        removeFilter(sibling.id);
                        _this.tagFilterMenu.removeTag(sibling.id, _this.type);
                    }
                });
            }

            _this.optionsFilters.push(option.id);
        }

        // Render tags
        renderTags();

        // Notify filter changes:
        _this.notifyFilterChanges();

        if ($event) {
            $event.stopPropagation();
        }

    };

    _this.notifyFilterChanges = function () {

        var filtersNotifyMsg = {
            type: _this.type,
            filters: _this.optionsFilters
        };

        $rootScope.$broadcast('filterChange-' + _this.tagFilterMenu.filterId, filtersNotifyMsg);
    };

    _this.prevent = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };

    function buildOptionNameList(options) {
        if (options && options.length > 0) {
            options.forEach(function (option) {
                _this.optionNames[option.id] = option.title;
                if (option.children) {
                    buildOptionNameList(option.children);
                }

                if (option.overrideTypeLabel) {
                    _this.optionTypeLabelOverrides[option.id] = option.overrideTypeLabel;
                }
            });
        }
    }



    // Pra all hierarchies
    _this.$onInit = function () {
        _this.isEllipsis = $window.innerWidth < 1800;

        $scope.$watch(angular.bind(_this, function () {
            return _this['options'];
        }), function (n, o) {

            // If there is a change on the settings, clean up the filters
            if (_this.optionsFilters.length > 0) {
                _this.optionsFilters.forEach(function (optionId) {
                    _this.tagFilterMenu.removeTag(optionId, _this.type);
                });
                _this.optionNames = {};
            }

            buildOptionNameList(_this.options);

            // If there are filters, render them
            if (_this.tagFilterMenu.filters && _this.tagFilterMenu.filters[_this.type]) {
                _this.optionsFilters = _this.tagFilterMenu.filters[_this.type];
                renderTags();
            }

        }, true);





    };
};

/* This kind of filter has a different format than the others.
 * It is a list of options, each one with a title and a value. Also these options can be be seletable or not and have children
 * Here is a sample.
 *  _this.options =  [
        {
            id: '1',
            title: 'Title 1',
            selectable: false,
            exclusive: true,
            children: [
                {
                    id: '1-1',
                    title: 'Title 1.1',
                    selectable: true,
                    exclusive: true,
                    children: [
                        {
                            id: '1-1-1',
                            title: 'Title 1-1-1',
                            selectable: true,
                            exclusive: false
                            overrideTypeLabel: 'Override the component default label, with this label',
                        },
                        {
                            id: '1-1-2',
                            title: 'Title 1.1.2',
                            selectable: true,
                            exclusive: false,
                            overrideTypeLabel: 'Override the component default label, with this label',
                        }
                    ]
                },
                {
                    id: '1-2',
                    title: 'Title 1.2',
                    selectable: true,
                    exclusive: true,
                    children: [
                        {
                            id: '1-2-1',
                            title: 'Title 1.2.1',
                            selectable: true,
                            exclusive: false
                        },
                        {
                            id: '1-2-2',
                            title: 'Title 1.2.2',
                            selectable: true,
                            exclusive: false
                        }
                    ]
                }
            ]
        }
    ];
 *
 * */


const hfOptionsTaggingFilter = angular.module('happyForceApp').directive('hfOptionsTaggingFilter', function () {
    return {
        restrict: 'E',
        scope: {
            mode: '<',
            label: '@',
            typeLabel: '@',
            type: '@',
            nonRemovable: '<',
            options: '='
        },
        require: {
            tagFilterMenu: '^hfTagFilterMenu'
        },
        template: function (elem, attrs) {
            // If doesn't have a title, we assume that we should display the items in a flat mode
            if (attrs.label)
                return require('./options-tagging-filter.html');
            else
                return require('./options-flat-tagging-filter.html');
        },
        controllerAs: '$ctrl',
        bindToController: true,
        transclude: false,
        replace: true,
        controller: OptionsTaggingFilterCtrl
    };
});

export default hfOptionsTaggingFilter;
