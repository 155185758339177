import React, { FC, useEffect } from 'react';
import {IHeatmapColumn} from './heatmap-types';
import Tooltip from '@mui/material/Tooltip';
import {Box, Typography} from '@mui/material';
import {ImageIcon} from '../hf-styled-components/ImageIcon';
import Style from '../../../utilities/style.utils';

interface HeatmapColumnProps {
    position: number;
    column: IHeatmapColumn;
    onSwapColumnDeployed?: (id: string, isDeployed: boolean) => void;
}

const HeatmapColumn: FC<HeatmapColumnProps & React.HTMLAttributes<HTMLElement>> = ({ position, column, onSwapColumnDeployed }) => {
    useEffect(() => {
        if (column.name.length > 24) {
            column.name = column.name.trim().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 24) + '...';
        }
    }, [column]);

    return (
        column.visible && (
            <Tooltip title={column.name} placement="top">
                <Box sx={(theme) => ({
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: `1px solid ${Style.Neutral200}`,
                    backgroundColor: Style.white,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    justifyContent: 'space-between',
                    padding: theme.spacing(1),
                    marginLeft: (position === 0 ? 0 : '4px'),
                })} onClick={() => onSwapColumnDeployed && onSwapColumnDeployed(column.id, !column.isDeployed)}>
                    <Box sx={{display:'flex', justifyContent: 'space-between'}}>
                        {column.icon && <ImageIcon size="small" src={column.icon} alt="icon" />}
                        {column.hasChildren && (
                            <span className="mini-style warm-grey">
                                <i className={`fa ${column.isDeployed ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                            </span>
                        )}
                    </Box>
                    <Typography  sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%' /* Ajusta según sea necesario */
                    }}
                    variant="caption" className="small-style warm-grey text">
                        {column.name}
                    </Typography>
                </Box>

            </Tooltip>
        )
    );
};

export default HeatmapColumn;

