
const FeedbackContainersService = function (Restangular, Settings) {
    'ngInject';
    // This is a hack; we are calling to the same endpoint that for normal comments
    var FeedbackContainers = Restangular.one('company').one('feedback').one('containers');

    var methods = {};

    methods.list = function (type, params, callback) {

        var finalParams = angular.copy(params);

        finalParams.feedbackType = type;

        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);
        if (params.activeOn)
            finalParams.activeOn = moment(params.activeOn).format(Settings.apiDateFormat);

        if (params && params.hierarchyId && params.hierarchyId.id) {
            finalParams.hierarchyId = params.hierarchyId.id;
        }

        FeedbackContainers.get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.save = function (container, callback) {
        FeedbackContainers.customPOST(container).then(function (status) {
            callback(null, status);
        }, function (err) {
            Rollbar.error('Error creating Feedback container', err);
            callback(err);
        });
    };

    methods.update = function (id, container, callback) {
        FeedbackContainers.customPUT(container, id).then(function (status) {
            callback(null, status);
        }, function (err) {
            Rollbar.error('Error updating Feedback container', err);
            callback(err);
        });
    };

    methods.get = function (id, callback) {
        FeedbackContainers.one(id).get({}).then(function (data) {
            callback(null, data);
        }, function (err) {
            callback(err);
        });
    };

    methods.delete = function (id, callback) {
        FeedbackContainers.one(id).remove().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    return methods;

};

export default FeedbackContainersService;
