import Style from '../../../../utilities/style.utils';


type ScoreZone = {
    value?: number;
    color: string;
}

const scoreZones:ScoreZone[] = [
    { value: 0, color: Style.noData },
    { value: 5, color: Style.bad },
    { value: 7, color: Style.warning },
    { color: Style.good }
];

const enpsZones:ScoreZone[] = [
    { value: -10, color: Style.bad },
    { value: 20, color: Style.neutral },
    { value: 60, color: Style.warning },
    { color: Style.good }
];

const hiZones:ScoreZone[] = [
    { value: 33, color: Style.bad },
    { value: 66, color: Style.warning },
    { color: Style.good }
]


export {scoreZones, enpsZones, hiZones};
