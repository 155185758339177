

enum BulkActionType {
    INVITE = 'INVITE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}


interface PersonCharacteristic {
    questionType: 'DATE' | 'LIST';
    characteristicId: string;
    value?: string;
    date?: string;
}


interface Person  {
    id: string | undefined;
    name: string | undefined;
    email: string;
    activated: boolean | undefined;
    mailStatus: 'SENT' | 'DELIVERED' | 'OPEN' | 'DEFERRAL' | 'HARDBOUNCE' | 'SOFTBOUNCE' | 'SPAM' | 'UNSUB' | 'REJECTED' | 'DELAYED' | 'OTHER' | undefined;
    profileImagePath: string | undefined;
    role: string;
    managedHierarchyIds: string[] | undefined;
    hierarchyId: string | undefined;
    groups: string[] | undefined;
    characteristics: PersonCharacteristic[] | undefined;
    appActivationToken: string | undefined;
    addedBy: string | undefined;
    addedOn: string | undefined;
    sourceType: 'REGISTRATION' | 'USER' | 'SELF' | 'API' | 'API_INTERNAL' | undefined;
    deleted: boolean;
    deletedOn: string;
    language: string | undefined;
    turnoverType: string | undefined;
    turnoverDate: string | undefined;
    blocked: boolean | undefined;
    debug: boolean | undefined;
}

enum RecordStatusErrorType {
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
    ORIGINAL_USER_NOT_FOUND = 'ORIGINAL_USER_NOT_FOUND',
    CHARACTERISTICS_FAILURES = 'CHARACTERISTICS_NOT_FOUND',
    HIERARCHY_FAILURES = 'HIERARCHY_NOT_FOUND',
    MANAGER_REQUIRES_HIERARCHY = 'MANAGER_REQUIRES_HIERARCHY',
    GROUPS_FAILURES = 'GROUPS_NOT_FOUND',
    ROLE_FAILURES = 'ROLE_NOT_FOUND',
    ROLE_REQUIRES_AREA = 'ROLE_REQUIRES_AREA',
    EMAIL_FAILURES = 'EMAIL_NOT_FOUND',
    TURNOVER_FAILURES = 'TURNOVER_NOT_VALID',
}

interface BulkActionPerson extends Omit<Person, 'id' | 'addedBy' | 'addedOn' | 'mailStatus' | 'activated' | 'profileImagePath' | 'appActivationToken' | 'sourceType' | 'deleted' | 'deletedOn'> {
    originalPerson?: Person;
    recordFailures: RecordStatusErrorType[];
    processingFailures: string[];
}

interface CellProps {
    person: Person | BulkActionPerson;
    bulkActionType?: BulkActionType | undefined;
}


export { Person, BulkActionPerson, CellProps, BulkActionType, RecordStatusErrorType, PersonCharacteristic};
