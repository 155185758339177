'use strict';

import firebase from "firebase";
import template from './advisors_template.html';

function AdvisorsTemplatesCtrl($scope, AuthSrvc, Languages) {
    'ngInject'
    var _this = this;

    _this.hideDetail = function () {
        _this.showDetail = false;
        _this.category = null;
        _this.language = null;
    };

    _this.addLanguage = function () {
        _this.createCategory(_this.language.id);
    };

    _this.selectCategory = function (key, value, lang) {
        _this.selectedCategory = key;
        _this.selectedLanguage = lang;
        _this.category = value;
        _this.language = lang;
    };

    _this.createCategory = function (lang) {
        var categoryName = prompt('¿Que nombre le ponesmos a la categoría?');

        if (!categoryName || categoryName.trim().length === 0) return;
        var newPostRef = _this.reference.child(lang);
        var obj = {};
        obj[categoryName] = 1;
        newPostRef.update(obj);
    };

    _this.createTemplate = function () {
        _this.showDetail = true;
        _this.template = {
            title: '',
            message: '',
            attachments: [],
            isEdit: false
        };
    };

    _this.editTemplate = function (key, value) {
        _this.showDetail = true;
        value.isEdit = true;
        _this.template = value;
        _this.selectedTemplate = key;
    };

    _this.removeTemplate = function (key) {
        var swalParams = {
            //icon: 'warning',
            closeOnEsc: false,
            buttons: {
                cancel: 'Cancelar',
                confirm: {
                    text: 'Borrar',
                    value: 'confirm'
                }
            },
            closeModal: true
        };

        swalParams.title = '¿Borramos plantilla?';
        swalParams.text = '¿Estás seguro? Esto no se puede deshacer';

        swal(swalParams)
            .then(function (value) {
                switch (value) {
                    case 'confirm':
                        _this.reference.child(_this.selectedLanguage.id).child(_this.selectedCategory).child(key).remove();
                        _this.hideDetail();
                }
            });
    };

    _this.updateOnFirebase = function () {
        var newPostRef = _this.reference.child(_this.selectedLanguage.id).child(_this.selectedCategory).child(_this.selectedTemplate);
        newPostRef.set(_this.template);

        _this.hideDetail();
    };

    _this.saveOnFirebase = function () {
        var newPostRef = _this.reference.child(_this.selectedLanguage.id).child(_this.selectedCategory).push();

        if (_this.template && !_this.template.attachments) {
            delete _this.template.attachments;
        }

        newPostRef.set(_this.template);
        _this.hideDetail();
    };

    _this.onSaveCallback = function (announcement) {
        delete announcement.sectionId;
        delete announcement.expirationDate;
        delete announcement.effectiveDate;
        delete announcement.categoryIds;

        if (_this.template.isEdit) {
            _this.updateOnFirebase();
        } else {
            _this.saveOnFirebase();
        }

    };

    // Firebase announcements templates
    function loadFromFirebase() {
        _this.reference.on('value', function (snapshot) {
            _this.templates = snapshot.toJSON();
        }, function (errorObject) {

            console.log('The read failed: ' + errorObject.code);
        });
    }


    this.$onInit = function () {

        if (!AuthSrvc.isADVISOR) {
            return;
        };

        _this.reference = firebase.database().ref().child('templates').child('announcements');


        _this.template = null;

        _this.languages = Languages.appLanguages;

        // Pediente de meter algo
        _this.header = {
            title: 'Announcement Templates',
            subtitle: 'Plantillas de anuncios'
        };

        _this.advisor = AuthSrvc.isADVISOR;



        _this.category = null;
        _this.language = null;





        /*
        var updates = {};
        updates['/announcements/' + $scope.actual.lang + '/' + id] = announcement;
        firebase.database().ref().update(updates);
        */



        ['showDetail'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) return;

                loadFromFirebase();
            });
        });

        loadFromFirebase();

        _this.showDetail = false;

    };
}




function AdvisorsTemplateView($stateProvider) {
    'ngInject'
    $stateProvider.state('advisors.templates', {
        url: '/advisors/advisors_template',
        controller: AdvisorsTemplatesCtrl,
        controllerAs: '$ctrl',
        bindToController: true,
        template: template,
        data: { pageTitle: 'Advisors', roles: ['ADVISOR'] }
    });
}

export default AdvisorsTemplateView;
