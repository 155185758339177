'use strict';

function HRISPersonioCtrl($scope, ErrorSvrc, HRSIService, $uibModalInstance) {
    'ngInject'

    $scope.executing = false;

    //TODO: Why whe have to do this?
    $scope.HRSIService = HRSIService;
    $scope.ErrorSvrc = ErrorSvrc;

    $scope.cancel = function () {
        $uibModalInstance.dismiss('close');
    };

    $scope.ok = function () {
        $scope.executing = true;
        delete $scope.errorMsg;
        var params = {
            clientId: $scope.clientId,
            secret: $scope.secret,
            type: 'PERSONIO'
        };
        $scope.HRSIService.connect(params, function (err, result) {
            $scope.executing = false;
            if (err) {
                $scope.errorMsg = ErrorSvrc.getErrorMessage(err);
            } else {
                $uibModalInstance.close();
            }
        });
    };

}

const HRISPersonioModal = angular.module('happyForceApp')
    .factory('HRISPersonioModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showModal = function (callback) {
                $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    template: require('./hris_personio.html'),
                    controller: HRISPersonioCtrl
                }).result.then(function () {
                    callback();
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;
        }
    );

export default HRISPersonioModal;