'use strict';

const SegmentsFilterCtrl = function (Characteristics, SegmentsFilterService, Filters) {
  'ngInject';
    var _this = this;

    this.$onInit = function () {

        _this.filters_applied = _this.filters ? _this.filters.split(',').length : 0;

        function getBySegment(id) {
            return characteristicsData[id] || null;
        }

        Characteristics.getAll(function (err, data) {
            if (err) {
                console.log('Error: ' + err);
            } else {
                // Prepare data
                if (data) {
                    data.forEach(function (c) {
                        var id = c.id;
                        if (c.questionType === 'LIST') {
                            c.values.forEach(function (v) {
                                v.uniqueId = id + ':' + v.id;
                            });
                        } else if (c.questionType === 'DATE') {
                            c.ranges.forEach(function (v) {
                                v.uniqueId = id + ':' + v.id;
                            });
                        } else if (c.questionType === 'BOOLEAN') {
                            c.uniqueIdYes = id + ':' + c.yesValue;
                            c.uniqueIdNo = id + ':' + c.noValue;
                        }
                    });
                }

                _this.segmentedQuestions = data;

                if (_this.filters) {
                    _this.checked = {};
                    _this.filters.split(',').forEach(function (key) {
                        _this.checked[key] = true;
                    });
                }
            }


        });

        _this.uncheck = function (thisValue, respectiveValue) {
            if (_this.checked[thisValue] == true && _this.checked[respectiveValue] == true) {
                delete _this.checked[respectiveValue];
            }

            if (!_this.checked[thisValue]) {
                delete _this.checked[thisValue];
            }

            _this.filters_applied = Object.keys(_this.checked).length;
        };

        _this.applyFilters = function () {
            var cleaned = [];

            // Get only true values
            if (_this.checked)
                Object.keys(_this.checked).forEach(function (key) {
                    if (_this.checked[key])
                        cleaned.push(key);

                });

            _this.filters = cleaned.length ? cleaned.join(',') : null;
            _this.filters_applied = cleaned.length;
            Filters.save({ segments: _this.filters });
            _this.isopen = false;
        };
    };
};

const SegmentsFilterComponent = angular.module('happyForceApp')
    .directive('hfSegmentsFilter', function () {
    return {
        scope: {
            filters: '=',
            additionalStyles: '@'
        },
        template: require('./segments-filter.html'),
        controller: SegmentsFilterCtrl,
        controllerAs: 'sfc',
        transclude: true,
        bindToController: true
    };
});


export default SegmentsFilterComponent;
