import {Box, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {ValuationTypography} from '../../../../../shared/new-components/hf-styled-components/ValuationTypography';
import {ScoreFactorName} from '../../../../../shared/new-components/hf-score-name/hfScoreName';
import useScoreNames from '../../../../../shared/hooks/useScoreNames';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import VariationArrow from './VariationArrow';

interface SelectedScoreNumberProps {
    scoreId: string;
    selectedResult: any;
}

const SelectedScoreNumber = ({ scoreId, selectedResult }: SelectedScoreNumberProps) => {

    const {  factorNames, scoreNamesLoading } = useScoreNames(scoreId);

    if (scoreNamesLoading) {
        return null;
    }



    return (
        <Box display={'flex'} flexDirection={'column'} height={'100%'}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                minHeight: '25%',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <ValuationTypography variant="bigNumber" valuation={selectedResult?.valuation}>
                        {selectedResult?.result?.toFixed(2)}
                    </ValuationTypography>
                    <Typography variant="h6" color={'textSecondary'}>pts</Typography>
                </Box>
            </Box>
            <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
            }}>
                {selectedResult?.factors?.map((factor: any) => {
                    return (
                        <Box
                            key={factor.id}
                            sx={{display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'baseline'}
                        }
                        >
                            <Typography variant="h6">
                                {factorNames?.[factor.id]}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    alignItem: 'center',

                                }}
                            >

                                <ValuationTypography variant="subtitle2" valuation={factor.valuation}>
                                    {factor.result?.toFixed(2)}
                                </ValuationTypography>
                                <VariationArrow fontSize={'large'} variation={factor.variation} />

                            </Box>


                        </Box>
                    )
                })}
            </Box>


        </Box>
    );
}


export default SelectedScoreNumber;
