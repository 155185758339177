import Style from "../../../utilities/style.utils";
import Numbers from "../../../utilities/number.utilities";

var SurveyChartRender = SurveyChartRender || {};


SurveyChartRender.indexEvaluation = function (questionIndex) {

    var result = {};

    if (questionIndex) {
        result.indexLabel = Numbers.roundNumber(questionIndex, 1);
        if (result.indexLabel  >= 6) {
            result.color = Style.excellent;
        } else if (result.indexLabel  >= 3) {
            result.color = Style.warning;
        } else if (result.indexLabel  > 0) {
            result.color = Style.bad;
        } else {
            result.color = Style.noData;
        }
    } else {
        result.indexLabel = '-';
        result.color = Style.noData;
    }

    return result;

};

export default SurveyChartRender;


