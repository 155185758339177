/**
 * Import the polyfills and vendor files
 */
import './polyfills';
import './vendor';

/**
 * Import the global styles
 */
import './index.scss';


/*
 * Import the old style app

import './app/index.less';
import './app/vendor.less';
import './app/less/style.less';
 */

/**
 * Temporary Import angular
 * see: https://github.com/Microsoft/TypeScript/issues/10178
 */
import * as angular from 'angular';

/**
 *  Import module to be bootstrapped
 */
// @ts-ignore
import { moduleName as appModule } from './app/app';

/**
 * Bootstrap the application using the imported moduleName
 */
const bootstrapModuleName = angular.module('application.bootstrap', [
  appModule
]).name;


if (module.hot) {
  module.hot.accept();
}
