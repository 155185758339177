'use strict';

import template from './company-create.html';
import {getHierarchyName} from "../../shared/services/migrated/hierarchy.service";
import async from 'async';

const CompanyCreateCtrl = function($scope, $translate, CompanySvrc, ErrorSvrc, Settings, $uibModalStack, Languages) {
    'ngInject'
    var _this = this;

    _this.executing = false;
    _this.uploadProgress = 0;
    _this.uploading = false;
    _this.company = {};

    _this.setupParams = {
        industryType: 'ACCOMMODATION_AND_FOOT_SERVICES',
        feedbackLevelModel: 'ANONYMOUS_OPEN',
        defaultLanguage: 'en',
        timezone: 'GMT',
    }

    $scope.industryTypes = CompanySvrc.industryTypesList;

    $scope.languages = Languages.appLanguages;

    _this.wizardTitle = $translate.instant('CREATE_COMPANY');
    _this.wizardSubtitle = $translate.instant('CREATE_COMPANY_SUBTITLE');

    _this.dateFormat = $translate.instant('DATE_FORMAT');
    if (_this.dateFormat === 'DATE_FORMAT')
        _this.dateFormat = Settings.defaultDateFormat;

    function findStep(classes) {
        if (!classes) return;
        var findStep = classes.match(/hf-step-(\d)/);
        var actual = 1;
        if (findStep && findStep[1]) {
            actual = parseInt(findStep[1]);
        }

        return actual;
    }

    function validateStep() {
        var actualStep = findStep(angular.element('hf-company-create .inmodal').attr('class'));
        var step = '.hf-step-' + actualStep;

        angular.element(step + ' .error').removeClass('error');
        angular.forEach(angular.element(step + ' [required]'), function (item) {
            if (item.value === '') {
                var $item = angular.element(item);

                if ($item.parent().attr('class') === 'special-input-input') {
                    $item = $item.parent();
                }

                $item.addClass('error');
            }
        });

        return (angular.element(step + ' .error').length === 0 && angular.element(step + ' .collission_error').length === 0);

    }

    _this.wizardOptions = {
        nextButton: $translate.instant('NEXT'),
        prevButton: $translate.instant('PREV'),
        confirm: {
            text: $translate.instant('CREATE'),
            action: function () {
                createCompany();
            }
        },
        return: {
            text: $translate.instant('RETURN'),
            className: 'hf-secondary',
            action: function () {
                _this.goToSelector();
            }
        },
        validate: validateStep,
        exit: function () {
            _this.exit();
        }
    };

    var blocked = false;

    function createCompany() {
        if (blocked) return;
        blocked = true;

        var finalCompany = angular.copy(_this.company);

        console.log('Creamos empresa!!', finalCompany);

        CompanySvrc.createCompany(function (err, company) {
            if (err) {
                return swal('Error creating company', err, 'error');
            }

            console.log('COMPANY', company);
            var stream = [];

            stream.push(function (next) {
                CompanySvrc.changeCompany(company.id, function (data) {
                    next();
                });
            });

            // Save config
            stream.push(function (next) {
                finalCompany.id = company.id;
                CompanySvrc.saveCompany(finalCompany, function (err, data) {
                    if (err)
                        console.error('Error saving company');
                    else {
                        console.log('Companía actualizada', data);
                        next();
                    }
                });

            });

            // Update logo
            stream.push(function (next) {
                if (!_this.picFile) return next();
                console.log('Actualizamos la imagen de la empresa');
                CompanySvrc.setCompanyImage(_this.picFile, function (err, updatedCompany) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        console.error('Error uploading file', err);
                        next(err);
                    } else {
                        swal($translate.instant('COMPANY_LOGO_UPDATED'), '', 'success');
                        next();
                    }
                });
            });


            // Set the company settings
            stream.push(function (next) {
                CompanySvrc.setup(_this.setupParams, function (err, data) {
                    if (err)
                        console.error('Error saving company settings');
                    else {
                        console.log('Company settings saved', data);
                        next();
                    }
                });
            });

            async.waterfall(stream, function (err) {
                blocked = false;
                if (err) {
                    return swal('Error creating company!', err, 'err');
                }

                swal('Created!', '', 'success');
                _this.exit();
            });
        });
    }

    _this.getHierchyName = function (hierarchyId) {
        if (!hierarchyId) return;
        var hierarchyNames = hierarchyId.map(function (hierarchy) {
            return getHierarchyName(hierarchy);
        });

        return hierarchyNames;
    };

    _this.onTimeZoneChange = function (timeZone) {
        _this.setupParams.timezone = timeZone;
    };

    _this.exit = function () {
        $uibModalStack.dismissAll();
    };

    var setCompanyImage = function (file) {
        _this.uploading = true;
    };

    _this.loadFile = function () {
        angular.element('#logoFile').click();
    };

};


const CompanyCreateModal = angular.module('happyForceApp')
    .factory('CompanyCreateModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};


            methods.showModal = function (callback) {
                $uibModal.open({
                    backdrop: 'static',
                    template: require('./company-create.html'),
                    controller: CompanyCreateCtrl,
                    controllerAs: '$ctrl',
                    size: 'createNewCompany defaults',
                }).result.then(function (results) {
                    if (results) {
                        callback();
                    }
                });
            };

            return methods;

        }
    );

export default CompanyCreateModal;


