'use strict';

function ConversationsState($stateProvider) {
    'ngInject';
    $stateProvider.state('feedback.conversations', {
        url: '/conversations',
        template: '<hf-conversations-section></hf-conversations-section>',
        data: { pageTitle: 'CONVERSATIONS_SECTION_TITLE', roles: ['ADMIN', 'MANAGER', 'ADVISOR'] }
    }).state('feedback.conversations.detail', {
        url: '/:commentId',
        template: '<hf-conversations-section></hf-conversations-section>',
        data: { pageTitle: 'CONVERSATIONS_SECTION_TITLE', roles: ['ADMIN', 'MANAGER', 'ADVISOR'] }

    });
}

const ConversationsStateConfig = angular.module('happyForceApp').config(ConversationsState);

export default ConversationsStateConfig;
