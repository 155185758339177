import angular from 'angular';



function DeleteCompanyController(selectedCompany, $modalInstance) {
    'ngInject';

    var _this = this;
    _this.selectedCompany = selectedCompany;
    _this.secretCompanyDeletePhrase = '';

    _this.ok = function () {
        $modalInstance.close(_this.secretCompanyDeletePhrase);

    }

    _this.cancel = function () {
        $modalInstance.dismiss('cancel');
    }
}



const DeleteCompanyModal = angular.module('happyForceApp')
    .factory('DeleteCompanyModal',
        function ($uibModal) {
            var methods = {};

            methods.showModal = function (company, callback) {

                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'lg',
                    template: require('./deleteCompany.html'),
                    controller: DeleteCompanyController,
                    controllerAs: '$ctrl',
                    resolve: {
                        selectedCompany: function () {
                            return company;
                        }

                    }
                }).result.then(function (secretCompanyDeletePhrase) {
                    if (callback) {
                        callback(secretCompanyDeletePhrase);
                    }
                }, function () {

                });

            };

            return methods;
        }
    );


export default DeleteCompanyModal;

