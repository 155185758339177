'use strict';

function FeedbackAmountConfigCtrl(Analytics) {
    'ngInject'

    var _this = this;

    Analytics.trackEvent('settings_ideas_feedback_config');
}

const hfFeedbackAmountSettings = angular.module('happyForceApp')
    .directive('hfFeedbackAmountSettings', function () {
        return {
            scope: {
                type: '=',
                feedbackConfiguration: '<'
            },
            template: require('./feedback_amount_settings.html'),
            controller: FeedbackAmountConfigCtrl,
            controllerAs: '$feedbackAmountSettings',
            bindToController: true
        };
    });

export default hfFeedbackAmountSettings;