'use strict';

import template from './enps_feedback_item.html';

function EnpsFeedbackItemCtrl(ErrorSvrc) {
    'ngInject';

    var _this = this;

    _this.commentUpdated = function (err, updatedComment) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
        }
        else {
            _this.comment = updatedComment;
        }
    };


    var setTagStyleAndLabelFromValue = function (value) {
        if (value >= 9) {
            _this.tagStyle = 'success';
            _this.tagLabel = 'ENPS_PROMOTER';
        } else if (value >= 7) {
            _this.tagStyle = 'warning';
            _this.tagLabel = 'ENPS_PASSIVE';
        } else {
            _this.tagStyle = 'danger';
            _this.tagLabel = 'ENPS_DETRACTOR';
        }
    };



    this.$onInit = function () {

        // Conversation link modal, will be only displayed based on setting value
        if (typeof _this.showConversationLink != 'undefined') {
            _this.displayConversationLink = _this.showConversationLink;
        } else {
            _this.displayConversationLink = true;
        }

        _this.score = _this.comment.votes[0].score;
        _this.voteColor = setTagStyleAndLabelFromValue(_this.score);
    };

}

const hfEnpsFeedbackItem =  angular.module('happyForceApp').directive('hfEnpsFeedbackItem', function () {
    return {
        scope: {
            comment: '=',
            showConversationLink: '<?',
            size: '<'
        },
        restrict: 'E',
        template: template,
        controller: EnpsFeedbackItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfEnpsFeedbackItem;

