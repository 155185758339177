import {APISettings} from '../shared/services/migrated/axiosWrapper';
import Dates from './date.utilities';



// Utility function to convert a date object to a query parameter
const dateToQueryParam = (date) => {
    return Dates.toAPIFormat(date, APISettings.apiDateFormat);
}

const dateQueryParamToDate = (queryParam) => {
    return Dates.fromAPIFormat(queryParam, APISettings.apiDateFormat);
}

export {dateToQueryParam, dateQueryParamToDate};
