// NOTE: This has been migrated to React on hfSegmentationName.tsx
const CharacteristicNameFilter = function (Characteristics) {
    'ngInject';
    return function (characteristicId) {
        if (characteristicId) {
            var characteristic = Characteristics.getCharacteristicById(characteristicId);
            if (characteristic)
                return characteristic.description;
            else
                return '?';
        } else
            return '';

    };
};

export default CharacteristicNameFilter;
