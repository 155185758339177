'use strict';

import hfFeedbackAmountSettings from '../components/feedback_amount_settings/feedback_amount_settings.controller';
import hfFeedbackTransparencySettings from '../components/feedback_transparency_settings/feedback_transparency_settings.controller';

function InformationFeedbackConfigCtrl($scope, Analytics) {
    'ngInject'

    var _this = this;

    ['enabled', 'employeeSignatureMode', 'managerSignatureMode', 'privacyMode', 'sharedAmmountComments', 'maxCommentsPerDay'].forEach(function (key) {
        // Listeners
        $scope.$watch(
            angular.bind(_this, function () {
                return $scope.feedbackConfiguration.feedbackConfiguration['INFORMATION'][key];
            }),
            function (n, o) {
                if (n === o) {
                    return;
                }
                $scope.saveFeedbackSettings();
            }
        );
    });


    Analytics.trackEvent('settings_ideas_feedback_config');
}

const hfInformationFeedbackConfig = angular.module('happyForceApp')
    .directive('hfInformationFeedbackConfig', function () {
        return {
            template: require('./information_feedback_config.html'),
            controller: InformationFeedbackConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfInformationFeedbackConfig;
