'use strict';

const hfAlertBanner = angular.module('happyForceApp')
    .directive('hfAlertBanner', function () {
        return {
            template: require('./alert_banner.html'),
            transclude: true,
            link: function (scope, element, attr) {
                scope.message = attr.message;
                scope.submessage = attr.submessage;
            }
        };
    });

export default hfAlertBanner;
