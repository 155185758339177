import {Box, Tooltip, Typography, TypographyProps} from '@mui/material';
import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import {useTheme} from '@mui/material/styles';

interface HFInfoIconWithTooltipProps extends TypographyProps {
    tooltipContent?: React.ReactNode;
    tooltipTitle?: string;
    icon?: 'INFO' | 'WARNING' | 'ERROR';
    iconOverride?: React.ReactNode;
}

/* A shared component that displays an info icon with a tooltip to haved unified styles everywhere */


const HFInfoIconWithTooltip = ({ tooltipContent, tooltipTitle, icon='INFO',iconOverride } : HFInfoIconWithTooltipProps) => {


    const theme = useTheme();

    const iconProps = {
        width: theme.typography.caption.fontSize,
        height: theme.typography.caption.fontSize,
        position: 'relative', top: '-3px', left: '3px'
    }

    const getColor = () => {
        switch (icon) {
            case 'WARNING':
                return 'warning';
            case 'ERROR':
                return 'error';
            case 'INFO':
            default:
                return 'primary';
        }
    }

    const color = getColor();

    return (
        <Tooltip title={tooltipContent ? tooltipContent : tooltipTitle}>
            <Box sx={{display: 'flex', position: 'relative', top: '-3px', left: '3px'}}>
                {(!iconOverride && icon === 'INFO') && <InfoOutlinedIcon sx={iconProps}  color={color} />}
                {(!iconOverride && icon === 'WARNING') && <WarningAmberOutlinedIcon sx={iconProps} color={color} />}
                {(!iconOverride && icon === 'ERROR') && <ReportOutlinedIcon sx={iconProps} color={color} />}
                {iconOverride && React.cloneElement(iconOverride as React.ReactElement, { sx: iconProps, color })}

            </Box>

        </Tooltip>
    );
}

export default HFInfoIconWithTooltip;
