import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';


const getBenchmarkDescription = (result, companyBenchmark, $translate) => {
    if (result && companyBenchmark) {
        const benchmark = result - companyBenchmark;
        const benchmarkAbs = benchmark.toFixed(2);

        if (benchmark > 0) {
            return $translate.instant('SCORE_BENCHMARK_COM_UP', { benchmark: benchmarkAbs });
        } else if (benchmark < 0) {
            return $translate.instant('SCORE_BENCHMARK_COM_DOWN', { benchmark: benchmarkAbs });

        } else {
            return $translate.instant('SCORE_BENCHMARK_COM_EQUAL', { benchmark: benchmarkAbs });
        }
    }
};

const getVariationDescription = (variation, $translate) => {
    if (variation) {
        const variationAbs = variation.toFixed(2);
        if (variation > 0) {
            return $translate.instant('SCORE_VARIATION_UP', { variation: variationAbs });
        } else if (variation < 0) {
            return $translate.instant('SCORE_VARIATION_DOWN', { variation: variationAbs });
        }   else {
            return $translate.instant('SCORE_VARIATION_EQUAL', { variation: variationAbs });
        }
    }

};


const getQualityDescription = (quality, $translate) => {
    switch (quality) {
        case 'LOW':
            return $translate.instant('SCORE_LOW_QUALITY_DESCRIPTION');
        case 'NO_DATA':
            return $translate.instant('SCORE_NODATA_QUALITY_DESCRIPTION');
        default:
            return $translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION');
    }
};

const renderPopover = (hasData, cellData, value, $translate) => {

    return (
        <Tooltip title={
            <>
                {hasData && (
                    <>
                        <Typography variant={'caption'}  dangerouslySetInnerHTML={{__html:getQualityDescription(cellData?.quality, $translate)}}></Typography>
                        <Typography variant={'caption'}  dangerouslySetInnerHTML={{__html:getVariationDescription(cellData?.variation, $translate)}}></Typography>
                        <Typography variant={'caption'}  dangerouslySetInnerHTML={{__html:getBenchmarkDescription(value, cellData?.companyBenchmark, $translate)}}></Typography>
                    </>
                )}

                {!hasData && (
                    <Typography variant={'caption'} >{$translate.instant('SCORE_NODATA_LONG_DESCRIPTION')}</Typography>
                )}
            </>
        } placement="bottom">
            <span className="mini-style">
                <i className="fa fa-info-circle text-white"></i>
            </span>

        </Tooltip>
    )
}


export {getBenchmarkDescription, getVariationDescription, getQualityDescription, renderPopover};
