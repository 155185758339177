'use strict';
import async from 'async';

function Recognition($scope, $stateParams, SegmentsFilterService, HIService, Comments, ManagersActivity, ErrorSvrc, CompanySvrc, Filters, $translate, $state, RecognitionService, Languages) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {
        _this.hasEnoughActiveEmployees = true;
        _this.loadRecognitionStats = true;
        _this.groups = Filters.groups() || [];

        _this.filters = null;
        _this.recognitionComments = [];
        _this.recognitionLegend = [];
        _this.loading = true;

        _this.loadData = function () {
            _this.loading = true;

            _this.loadRecognitionStats = true;
            var params = prepareParams();
            var recognitionStream = [];

            _this.recognitionComments = [];
            _this.recognitionStats = [];

            recognitionStream.push(function (next) {
                var commentParams = angular.copy(params);
                commentParams.feedbackTypes = 'RECOGNITION';
                commentParams.fromComment = 0;
                commentParams.toComment = 5;

                Comments.get(commentParams, function (employeeComments) {
                    _this.recognitionComments = employeeComments.comments;
                    _this.recognitionTotal = employeeComments.total;
                    next(null);
                }, function (err) {

                    next(err);
                });
            });

            recognitionStream.push(function (next) {
                var recognitionParams = angular.copy(params);
                RecognitionService.stats.get(recognitionParams, function (err, stats) {
                    if (err) {
                        next(err);
                    } else {
                        _this.recognitionStats = stats.results;
                        next(null);
                    }
                });
            });

            // TODO:
            async.parallel(recognitionStream, function (err) {
                _this.loadRecognitionStats = false;
                _this.loading = false;
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                }
            });


        };

        function prepareParams() {
            var params = {};

            params.from = _this.dates.from;
            params.to = _this.dates.to;
            params.groupId = _this.groups;

            if (_this.level) {
                params.hierarchyId = _this.level;
            }

            params.filters = _this.filters;

            return params;
        };

        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                // Now compute the stats
                _this.loadData();
            }, true);
        });
        _this.loadData();
    };
}

const hfRecognition = angular.module('happyForceApp').directive('hfRecognition', function () {
    return {
        scope: {
            dates: '=',
            filters: '=',
            groups: '=',
            level: '='
        },
        restrict: 'E',
        template: require('./recognition.html'),
        controller: Recognition,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfRecognition;
