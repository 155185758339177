import {Box, Skeleton, Typography} from '@mui/material';
import UserAreaBreadcrumbs from '../shared/userAreaBreadcrumbs';
import React, {useEffect} from 'react';
import {BulkActionPerson, BulkActionType, CellProps, Person} from '../../types';
import {getService} from 'reactInAngular';
import HFInfoIconWithTooltip from '../../../../../../shared/new-components/hf-info-icon-with-tooltip';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import useHierarchyName from '../../../../../../shared/hooks/useHierarchyName';


const AreaCell = ({person, bulkActionType}: CellProps) => {

    const $translate = getService('$translate');
    const [originalPerson, setOriginalPerson] = React.useState<any>();
    const originalHierarchyName = useHierarchyName(originalPerson?.hierarchyId);


    useEffect(() => {
        setOriginalPerson((person as BulkActionPerson).originalPerson);
    }, [person]);

    if (!person.id && bulkActionType === undefined) {
        return (
            <Skeleton animation={false} variant="rounded" width={'80%'} height={20} />
        )
    }

    const renderOriginalPersonAreaTooltip = () => {
        if (originalPerson
            && bulkActionType === BulkActionType.UPDATE
            && originalPerson.hierarchyId !== person.hierarchyId
        ) {


            const originalName = originalPerson.hierarchyId ? originalHierarchyName.hierarchyName: $translate.instant('NO_AREA');


            return (
                <HFInfoIconWithTooltip
                    tooltipContent={<Typography variant={'caption'}>{
                        $translate.instant('BULK_ACTION_ORIGINAL_RECORD', {value: originalName })}</Typography>}
                    color={'primary'}
                    iconOverride={<SwapHorizIcon/>}/>
            );
        } else {
            return null;
        }

    };

    return (
        <Box sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
            width: '100%'
        })}>
            <UserAreaBreadcrumbs
                noAreaLabel={$translate.instant('EMPLOYEE_NO_AREA')}
                hierarchyId={person.hierarchyId} />


            {renderOriginalPersonAreaTooltip()}
        </Box>
    )
}

export default AreaCell;
