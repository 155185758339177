'use strict';

import template from './advisors_surveys.html';
import AdvisorsSurveyCategoryEditModal from './modals/advisor_surveys_category_edit.controller';


function AdvisorsSurveysCtrl($scope, AuthSrvc, CompanySvrc, SurveyAdvisors, $translate, Languages, ErrorSvrc, $state, AdvisorsSurveyCategoryEditModal) {
    'ngInject'
    var _this = this;

    _this.allLanguages = Languages.list;

    _this.viewMode = 'SURVEYS';

    _this.header = {
        title: 'Survey Catalog'

    };

    _this.templates = [];

    if (!AuthSrvc.isADVISOR) {
        return;
    }

    _this.loadSurveys = function () {
        SurveyAdvisors.listCategories(function (err, categories) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            } else {
                _this.categories = categories;
                SurveyAdvisors.list(function (err, templates) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        return;
                    } else {
                        _this.templates = templates;

                        _this.templates.forEach(function (template) {
                            template.category = _.find(_this.categories, function (category) {
                                return category.id === template.categoryId;
                            });
                        });
                    }
                });
            }
        });
    };

    _this.getLangKeys = function (langs) {
        if (langs)
            return Object.keys(langs);
        else
            return [];
    };

    _this.getLangFactorLangs = function (factors) {
        if (langs)
            return Object.keys(langs);
        else
            return [];
    };

    _this.getLanguageName = function (code) {
        return _.find(_this.allLanguages, function (language) {
            return language.code == code;
        }).language;
    };

    _this.editTemplate = function (survey) {
        $state.go('advisors.survey-edit', { templateId: survey.id });
    };

    _this.createTemplate = function () {
        $state.go('advisors.survey-edit', { templateId: 'new' });
    };


    _this.deleteTemplate = function (template) {

        swal(
            {
                title: 'Are you sure?',
                icon: 'warning',
                buttons: true,
                dangerMode: true
            }
        )
            .then(function (value) {
                if (value) {
                    SurveyAdvisors.delete(template.id, function (err, template) {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                        } else {
                            _this.loadSurveys();
                        }
                    });
                }
            });
    };

    _this.setViewMode = function (viewMode) {
        _this.viewMode = viewMode;
    };

    _this.editCategory = function (category) {

        AdvisorsSurveyCategoryEditModal.showEdit(category, function (updatedCategory) {
            _this.loadSurveys();
            swal('Category saved', '', 'success');
        });
    };

    _this.deleteCategory = function (category) {

        swal(
            {
                title: 'Are you sure?',
                icon: 'warning',
                buttons: true,
                dangerMode: true
            }
        )
            .then(function (value) {
                if (value) {
                    SurveyAdvisors.deleteCategory(category.id, function (err, category) {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                        } else {
                            $scope.loadSurveys();
                        }
                    });
                }
            });

    };


    this.$onInit = function () {
        _this.loadSurveys();
    };

}

function AdvisorsSurveysView($stateProvider) {
    'ngInject'
    $stateProvider.state('advisors.surveys', {

        controller: AdvisorsSurveysCtrl,
        controllerAs: '$ctrl',
        bindToController: true,
        template: template,
        url: '/surveys',
        data: { pageTitle: 'Surveys Catalog', roles: ['ADVISOR'] }
    });
}

export default AdvisorsSurveysView;
