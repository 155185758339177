import {getService} from '../../../../../../../migration_utils/react-in-angular';
import {Box, Skeleton, Typography} from '@mui/material';
import HFInfoIconWithTooltip from '../../../../../../shared/new-components/hf-info-icon-with-tooltip';
import React, {useEffect} from 'react';
import {BulkActionPerson, BulkActionType, CellProps} from '../../types';
import HierarchyName from '../../../../../../shared/new-components/hf-hierarchy-name';
import {useTheme} from '@mui/material/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {isPerson} from '../../utils';

const BasicPersonDataCell = ({person, bulkActionType}: CellProps) => {

    const $translate = getService('$translate');
    const [roleLabel, setRoleLabel] = React.useState<string>('');
    const [originalRole, setOriginalRoleLabel] = React.useState<string>('');

    const [originalPerson, setOriginalPerson] = React.useState<any>();
    const theme = useTheme();

    useEffect(() => {

        if (person.role === 'ADMIN') {
            setRoleLabel($translate.instant('USER_ROLE_ADMIN'));
        } else if (person.role === 'EMPLOYEE') {
            setRoleLabel($translate.instant('USER_ROLE_EMPLOYEE'));
        } else if (person.role === 'MANAGER') {
            setRoleLabel($translate.instant('USER_ROLE_MANAGER'));
        }


    }, [person?.role]);

    useEffect(() => {
        if (originalPerson) {
            if (originalPerson.role === 'ADMIN') {
                setOriginalRoleLabel($translate.instant('ADMIN'));
            } else if (originalPerson.role === 'EMPLOYEE') {
                setOriginalRoleLabel($translate.instant('EMPLOYEE'));
            } else if (originalPerson.role === 'MANAGER') {
                setOriginalRoleLabel($translate.instant('MANAGER'));
            }
        }



    }, [originalPerson?.role]);


    useEffect(() => {
        setOriginalPerson((person as BulkActionPerson).originalPerson);

    }, [person]);

    const renderMailView = () => {
        const mailStatusTooltip = $translate.instant('MAIL_STATUS_' + person.mailStatus);


        let tooltipIcon = 'INFO';
        let showTooltip = false;

        if (isPerson(person)) {
            if (
                person.mailStatus === 'SENT' ||
                person.mailStatus === 'OTHER' ||
                person.mailStatus === 'DELAYED' ||
                person.mailStatus === 'DEFERRAL' ||
                person.mailStatus === 'SOFTBOUNCE') {
                tooltipIcon = 'WARNING'
                showTooltip = true;
            } else if (
                person.mailStatus === 'HARDBOUNCE' ||
                person.mailStatus === 'SPAM' ||
                person.mailStatus === 'REJECTED' ||
                person.mailStatus === 'UNSUB') {
                tooltipIcon = 'ERROR'
                showTooltip = true;
            }
        }





        return (
            <Box sx={(theme) => ({  marginTop: theme.spacing(1), display:'flex', flexDirection:'row'})}>
                <Typography variant={'caption'}
                    sx={(theme) => ({color: theme.palette.primary.main})}
                >{person.email}</Typography>
                {showTooltip && (
                    <HFInfoIconWithTooltip
                        tooltipTitle={mailStatusTooltip}
                        color={'primary'}
                        icon={tooltipIcon}
                    />
                )}
            </Box>
        )


    }

    const renderOriginalRoleTooltip = () => {
        if (originalPerson
            && bulkActionType === BulkActionType.UPDATE
            && person.name !== originalPerson.name
        ) {

            return (
                <HFInfoIconWithTooltip
                    tooltipContent={<Typography variant={'caption'}>{
                        $translate.instant('BULK_ACTION_ORIGINAL_RECORD', {value: originalRole})}</Typography>}
                    color={'primary'}
                    iconOverride={<SwapHorizIcon/>}/>
            );
        } else {
            return null;
        }
    }


    const renderRoleSelector = () => {
        return <Box sx={(theme) => ({  marginTop: theme.spacing(1), display:'flex', flexDirection:'column'})}>


            <Typography variant={'caption'}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    {roleLabel}
                    {renderOriginalRoleTooltip()}
                </Box>

            </Typography>

            {person?.managedHierarchyIds?.map((hierarchyId, index) => {
                return (
                    <Typography key={hierarchyId} variant={'caption'}>
                        <HierarchyName key={hierarchyId} hierarchyId={hierarchyId}/>
                        {index < person.managedHierarchyIds.length - 1 && ', '}
                    </Typography>
                );
            })}
        </Box>;
    }

    const renderOriginalPersonNameTooltip = () => {
        if (originalPerson
            && bulkActionType === BulkActionType.UPDATE
            && person.name !== originalPerson.name
        ) {

            return (
                <HFInfoIconWithTooltip
                    tooltipContent={<Typography variant={'caption'}>{
                        $translate.instant('BULK_ACTION_ORIGINAL_RECORD', {value: originalPerson.name})}</Typography>}
                    color={'primary'}
                    iconOverride={<SwapHorizIcon/>}/>
            );
        } else {
            return null;
        }
    }


    const renderPersonName = () => {
        return (
            <Box sx={(theme) => ({  marginTop: theme.spacing(1)})}>
                <Typography variant={'caption'}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        {person.name}
                        {renderOriginalPersonNameTooltip()}
                    </Box>
                </Typography>
            </Box>
        )
    }

    if (!person.id && bulkActionType === undefined) {
        return (
            <Skeleton animation={false} variant="rounded" width={'80%'} height={20} />
        )
    }



    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            })}>

            {renderPersonName()}
            {renderMailView()}
            {renderRoleSelector()}

        </Box>
    )
}

export default BasicPersonDataCell;
