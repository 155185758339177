'use strict';

import 'metismenu';
import {isMultiHierarchies} from "../../../services/migrated/hierarchy.service";

const NavigationCtrl = function ($scope, CompanySvrc, Conversation, $state, CompanyStatus, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.pendingConversations = 0;

    _this.loadPending = function () {

        Conversation.getPending(function (err, result) {
            if (err) {
                console.log(err);
            } else {
                _this.pendingConversations = result.pending;
            }
        });
    };


    this.$onInit = function () {
        var interval;

        _this.loadPending();
        setInterval(_this.loadPending, 10000);

        isMultiHierarchies(function (err, hasHierarchies) {
            if (err) {
                _this.multiAreas = false;
            } else {
                _this.multiAreas = hasHierarchies;
            }
        });

        _this.goToParent = function (route) {
            $state.go(route);
        };


        _this.notEnoughActiveEmployeesPrelaunch = CompanyStatus.notEnoughActiveEmployeesPrelaunch;

        // Aqui tenemos que configurar un timer, que recupere los activados hasta que el status pase a false, y lo cancelamos

        $scope.$watch(
            function () {
                return CompanyStatus.notEnoughActiveEmployeesPrelaunch;
            },
            function (newVal, oldVal) {
                _this.notEnoughActiveEmployeesPrelaunch = newVal;
            }
        );


        // Ge the company
        CompanySvrc.getUserCompany(function (err, company) {
            if (err) {
                _this.errorMsg = ErrorSvrc.getErrorMessage(err);
            } else {
                _this.companyId = company.id;
            }
        });
    };

};

const NavigationComponent = angular.module('happyForceApp')
    .directive('hfNavigationMenu', function () {
        return {
            template: require('./navigation.html'),
            controller: NavigationCtrl,
            controllerAs: 'nv',
            bindToController: true
        };
    });


//Directive used to set metisMenu and minimalize button
angular.module('happyForceApp')
    .directive('sideNavigation', function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                // Call metsi to build when user signup
                scope.$watch('authentication.user', function () {
                    $timeout(function () {
                        element.metisMenu();
                    });
                });

            }
        };
    });



export default NavigationComponent;
