import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import Valuation from '../../../../../utilities/valuation.utils';
import Dates from '../../../../../utilities/date.utilities';
import ScaleChart from '../../../../../shared/components/_migrated/scale-chart/scale-chart.component';

const HiDetailOverview = ({ data, hasEnoughActiveEmployees }) => {
    const $translate = getService('$translate');
    const Settings = getService('Settings');

    const [color, setColor] = useState('');
    const [shouldDisplayData, setShouldDisplayData] = useState(false);
    const [measureDate, setMeasureDate] = useState(null);

    const slices = [{
        color: Valuation.valuationColor('BAD'),
        range: [0, 33],
        label: $translate.instant('HI_SLICE_FIRST')
    }, {
        color: Valuation.valuationColor('WARNING'),
        range: [33, 66],
        label: $translate.instant('HI_SLICE_SECOND')
    }, {
        color: Valuation.valuationColor('EXCELLENT'),
        range: [66, 100],
        label: $translate.instant('HI_SLICE_THIRD')
    }];

    const getVariationText = () => {
        let key = 'SCORE_VARIATION_';
        if (data.variation > 0) {
            key += 'UP';
        } else if (data.variation < 0) {
            key += 'DOWN';
        } else {
            key += 'EQUAL';
        }

        return $translate.instant(key, { variation: data.variation });
    };

    useEffect(() => {
        if (data) {
            setMeasureDate(Dates.fromAPIFormat(data.measureDate, Settings.apiDateFormat));
            setShouldDisplayData(hasEnoughActiveEmployees);
            setColor(Valuation.valuationColor(data.valuation, true));
        }
    }, [data, hasEnoughActiveEmployees]);

    return (
        <section className="hi-detail-detail-overview white-bg">
            <div className="row overview-row">
                <div className="col-xs-12">
                    {shouldDisplayData && data.value && (
                        <div className="row same-height">
                            <div className="col-xs-12 col-lg-10">
                                <div className="big-number-container" style={{ color: color }}>
                                    <span className="big-number">{data.value}</span>
                                    <span className="big-number-sub">pts</span>
                                </div>
                                <ScaleChart slices={slices} value={data.value} />
                                <p className="small-style m-t-xs" dangerouslySetInnerHTML={{ __html: getVariationText() }}></p>
                            </div>
                        </div>
                    )}
                    {hasEnoughActiveEmployees && data.quality === 'NO_DATA' && (
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="big-number-container" style={{ color: color }}>
                                    <span className="big-number-no-data">{ $translate.instant('NO_DATA') }</span>
                                </div>
                                <p className="small-style">
                                    { $translate.instant('SCORE_NO_DATA_SHORT_DESCRIPTION') }
                                </p>
                            </div>
                        </div>
                    )}


                    {!hasEnoughActiveEmployees && (
                        <div className="row">
                            <hf-empty-state image="images/anonymous.png"
                                message="{{ 'NOT_ENOUGH_EMPLOYEES' | translate }}"
                                submessage="{{ 'NOT_ENOUGH_EMPLOYEES_SUBMESSAGE' | translate }}">
                            </hf-empty-state>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

HiDetailOverview.propTypes = {
    data: PropTypes.object,
    hasEnoughActiveEmployees: PropTypes.bool
};

angularize(HiDetailOverview, 'hiDetailOverview', angular.module('happyForceApp'), {
    data: '<',
    hasEnoughActiveEmployees: '<'
});

export default HiDetailOverview;
