import {Drawer, Paper, styled, Typography, TypographyProps} from '@mui/material';
import {paperClasses} from '@mui/material/Paper';
import Style from '../../../utilities/style.utils';





const enum ValuationTypes {
    NO_DATA = 'NO_DATA',
    BAD = 'BAD',
    WARNING = 'WARNING',
    NEUTRAL= 'NEUTRAL',
    GOOD = 'GOOD',
    EXCELLENT = 'EXCELLENT'
}

const ValuationColors = {
    NO_DATA: Style.noData,
    BAD: Style.bad,
    WARNING: Style.warning,
    NEUTRAL: Style.neutral,
    GOOD: Style.good,
    EXCELLENT: Style.excellent
}


interface ValuationTypographyProps  {
    valuation?: ValuationTypes;
}


const ValuationTypography = styled(Typography, {
    shouldForwardProp: (prop) => {
        return prop !== 'valuation';
    }
})((prop: ValuationTypographyProps) => ({
    color: prop?.valuation ? ValuationColors[prop.valuation] : ValuationColors[ValuationTypes.NO_DATA],
}));



export { ValuationTypography }
