
import Dates from "../../utilities/date.utilities";

const ScoresV2Service = function (Restangular, $translate, Settings) {
  'ngInject';
    var Stats = Restangular.one('company').one('stats').one('scoresV2');
        var Scores = Restangular.one('company').one('scores');

        var methods = {};
        var stats = {};

        methods.getScore = function (scoreId, callback) {
            Scores.one(scoreId).get().then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        };

        methods.getQuestionData = function (scoreId, factorId, questionId, callback) {
            methods.getScore(scoreId, function (err, score) {
                if (err) {
                    callback(err);
                } else {
                    var foundFactor = _.find(score.factors, function (factor) { return factor.id == factorId; });

                    if (foundFactor) {
                        var foundQuestion = _.find(foundFactor.questions, function (question) {
                            return question.id == questionId;
                        });
                        if (foundQuestion) {
                            callback(null, foundQuestion);
                        } else {
                            callback(null, null);
                        }
                    } else {
                        callback(null, null);
                    }
                }
            });
        };

        stats.get = function (scoreId, params, callback) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
            }
            if (_params.to) {
                _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
            }
            Stats.one(scoreId).get(_params).then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        };

        stats.summary = function (params, callback) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
            }
            if (_params.to) {
                _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
            }
            Stats.get(_params).then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        };

        stats.heatmap = function (groupBy, params, callback) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
            }
            if (_params.to) {
                _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
            }
            Stats.one('grouping').one(groupBy).get(_params).then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        };

        stats.hiImpact = function (params, callback) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
            }
            if (_params.to) {
                _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
            }
            Stats.one('impact').one('hi').get(_params).then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        };

        stats.enpsImpact = function (params, callback) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
            }
            if (_params.to) {
                _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
            }
            Stats.one('impact').one('enps').get(_params).then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        };

        stats.personal = function (to, personalToken, callback) {
            var to = Dates.toAPIFormat(to, Settings.apiDateFormat);
            Stats.one('individual').one(to).one(personalToken).get().then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        };

        methods.stats = stats;

        return methods;

};

export default ScoresV2Service;
