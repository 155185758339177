export default function AnnouncementsSectionsConfiguration(Restangular, $translate) {
    'ngInject';

    var AnnouncementsConfiguration = Restangular.one('company').one('announcements').one('configuration');

    var exposed = {};


    exposed.fixedTypeIDS = {
        CELEBRATIONS_SECTION_ID: '63ad5dbdaee16935acb036c5',
        UNREAD_SECTION_ID: '63c774aa70409e2491d40c1a',
        DEFAULT_SECTION_ID: '63c783b7910ad79aaa613caf'
    };

    exposed.sectionTypes = [
        {
            id:  exposed.fixedTypeIDS.CELEBRATIONS_SECTION_ID,
            title: $translate.instant('ANNOUNCEMENTS_CELEBRATION_SECTION_CONFIG_TITLE'),
            description: $translate.instant('ANNOUNCEMENTS_CELEBRATION_SECTION_CONFIG_DESCRIPTION')
        },
        {
            id:  exposed.fixedTypeIDS.UNREAD_SECTION_ID,
            title: $translate.instant('ANNOUNCEMENTS_UNREAD_SECTION_CONFIG_TITLE'),
            description: $translate.instant('ANNOUNCEMENTS_UNREAD_SECTION_CONFIG_DESCRIPTION')
        },
        {
            id:  null,
            title: $translate.instant('ANNOUNCEMENTS_STANDARD_SECTION_CONFIG_TITLE'),
            description: $translate.instant('ANNOUNCEMENTS_STANDARD_SECTION_CONFIG_DESCRIPTION')
        }
    ];

    exposed.getConfiguration = function (callback) {
        AnnouncementsConfiguration.get().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    exposed.getDefaultConfiguration = function (sectionTypeId, callback) {
        const params = {
            sectionTypeId: sectionTypeId
        };
        AnnouncementsConfiguration.one('default').get(params).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    exposed.updateConfiguration = function (configuration, callback) {
        AnnouncementsConfiguration.customPUT(configuration, null, {}).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    return exposed;
}