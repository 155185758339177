'use strict'

import FeedbackIdeasUtils from "../feedback-ideas-utils";


function FeedbackIdeasInitiativeDetailCtrl($scope, Filters, HIService, Stats, $stateParams, Settings, ErrorSvrc, UnsplashService, FeedbackContainersService, AuthSrvc, $translate, $window) {
    'ngInject';

    var _this = this;

    _this.loading = true;

    _this.selectedFilters = {};

    // Initialize the default tags
    _this.selectedTags = [];

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        show: true,
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        show: true,
        mode: 'single',
        title: $translate.instant('GROUPS'),
        nonRemovable: false
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        show: true,
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false
    }

    function setStatusLabels() {
        const result = FeedbackIdeasUtils.getStatusColorAndLabel(_this.initiative.status);

        _this.statusLabel = $translate.instant(result.statusLabel);
        _this.statusStyle = result.statusStyle;
    }


    _this.loadInitiative = function () {
        FeedbackContainersService.get($stateParams.initiativeId, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.initiative = result;
                setStatusLabels();
            }
            _this.loading = false;
        });

    };

    _this.arrow = {
        action: function () {
            $window.history.back();
        }
    };

    this.$onInit = function () {
        _this.loadInitiative();
    };



}


const hfFeedbackIdeasInitiativeDetail = angular.module('happyForceApp')
    .directive('hfFeedbackIdeasInitiativeDetail', function () {
        return {
            template: require('./feedback-ideas-initiative-detail.html'),
            controller: FeedbackIdeasInitiativeDetailCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeasInitiativeDetail;
