'use strict';

function HiQuestionsOperationsCtrl(Languages, $scope, HIService, AuthSrvc, ErrorSvrc, $modalInstance, selectedQuestion, language) {
    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';
    $scope.question = selectedQuestion;


    // Check if we are adding a new language
    if (language) {
        $scope.isEditingLanguage = true;
    } else {
        $scope.isEditingLanguage = false;
    }

    // Now initialize each item and get the references:

    $scope.title = Languages.initializeOrGetLangValue($scope.question.title, language);

    $scope.questionLabels = [];
    $scope.question.values.forEach(function (value) {
        $scope.questionLabels.push({
            id: value.id,
            imageUrl: value.imageUrl,
            label: Languages.initializeOrGetLangValue(value.label, language)
        });
    });

    $scope.ok = function () {

        // Update the title...
        Languages.setLangValue($scope.question.title, $scope.title, language);

        // ... and for each value, update it...
        $scope.questionLabels.forEach(function (label) {
            var value = _.find($scope.question.values, function (questionValue) {
                return questionValue.id === label.id;
            });
            if (value) {
                Languages.setLangValue(value.label, label.label, language);
            } else {
                console.error('Value for id ' + label.id + ' not found in question values ' + $scope.question.values);
            }
        });

        $modalInstance.close($scope.question);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

}

//TODO: Restangular, $scope?

const HiQuestionEditModal = angular.module('happyForceApp')
    .factory('HiQuestionsEditModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showModal = function (question, language, callback) {
                $uibModal.open({
                    backdrop: 'static',
                    template: require('./hi_question_edit.html'),
                    controller: HiQuestionsOperationsCtrl,
                    resolve: {
                        selectedQuestion: function () {
                            return question;
                        },
                        language: function () {
                            return language;
                        }
                    },
                    size: ' hi_question'
                }).result.then(function (updatedQuestion) {
                    callback(updatedQuestion);
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;
        }
    );

export default HiQuestionEditModal;
