'use strict';

var GroupSelectorCtrl = function (AuthSrvc, Groups, Filters, HFController, Analytics) {
    'ngInject';
    var _this = this;

    _this.isopen = false;

    Groups.getAll({ active: true }, function (err, groups) {
        if (err) {
            console.log('Error', err);
            return;
        }

        _this.groups = groups;
    });

    _this.groups_applied = _this.selected && _this.selected.length || 0;

    _this.addGroup = function (group) {
        if (!group) {
            _this.selected = [];
        } else {
            if (!_this.selected) {
                _this.selected = [];
            }
            if (_this.selected.indexOf(group.id) == -1) {
                _this.selected.push(group.id);
            } else {
                var index =  _this.selected.indexOf(group.id);
                _this.selected.splice(index, 1); // 2nd parameter means remove one item only
            }

        }

        _this.isopen = false;
        Filters.save({ groups: _this.selected });
        HFController.publish('hf-groups', _this.selected);
        _this.groups_applied = _this.selected && _this.selected.length || 0;

        _this.trackEvent('group_select');
    };

    _this.isSelected = function (group) {
        if (!group) {
            if (!_this.selected || _this.selected.length === 0) {
                return true; // ALL
            }

            return false;
        } else {
            if (_this.selected && _this.selected.length) {
                if (_this.selected.indexOf(group.id) != -1) {
                    return true;
                }
            }

            return false;
        }
    };

    _this.trackEvent = function (action) {
        Analytics.trackEvent('groups_filter', action);
    };

    this.$onInit = function () {


    };
};




const GroupSelectorComponent = angular.module('happyForceApp')
.directive('hfGroupSelector', function () {
        return {
            scope: {
                selected: '=',
                useFilter: '=',
                additionalStyles: '@'
            },
            template: require('./group-selector.html'),
            controller: GroupSelectorCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default GroupSelectorComponent;
