'use strict';

import FeedbackStatusTypeConfigModal from './modals/feedback_status_type.modal.component';

function FeedbackStatusTypesSettingsCtrl(FeedbackStatusService, $translate, FeedbackStatusTypeConfigModal, ErrorSvrc, toaster) {
    'ngInject'

    var _this = this;


    _this.loadData = function () {
        FeedbackStatusService.list(_this.type, null, function (err, types) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.types = [];
                types.forEach(function (type) {
                    const phase = _this.phaseTypes.find(function (phase) {
                        return phase.id === type.phase;
                    });
                    if (phase) {
                        type.phaseLabel =  phase.label;
                        type.phaseStatus = phase.status;
                    }
                    _this.types.push(type);
                });
            }
        });
    };

    this.addStatusType = function (value) {

        var isEdit = true;
        if (!value) {
            // We are a new one
            value = {
                label: {
                    defaultValue: ''
                },
                description: {
                    defaultValue: ''
                },
                feedbackType: _this.type,
                archived: false,
                color: 33,//"#FFC200",
                allowReply: true,
                allowDislike: true,
                allowLike: true,
                notifyOnChange: false,
                phase: 'ONGOING'
            };
            isEdit = false;
        } else {
            value = angular.copy(value);
        }

        FeedbackStatusTypeConfigModal.openModal(_this.phaseTypes, value, isEdit, function (updatedOrCreated, newValue) {

            if (updatedOrCreated) {
                toaster.pop('success', null, ($translate.instant('FEEDBACK_CONFIGURATION_UPDATED')));
                _this.loadData();
            }
        });
    };

    this.$onInit = function () {
        _this.loadData();
    };




}

const hfFeedbackStatusTypesSettings = angular.module('happyForceApp')
    .directive('hfFeedbackStatusTypesSettings', function () {
        return {
            scope: {
                type: '=',
                phaseTypes: '='
            },
            template: require('./feedback_status_types_setting.html'),
            controller: FeedbackStatusTypesSettingsCtrl,
            controllerAs: '$feedbackStatusTypes',
            bindToController: true
        };
    });

export default hfFeedbackStatusTypesSettings;
