'use strict';

import template from './surveys_template_selector.html';

function SurveysTemplateSelectorCtrl($scope, AuthSrvc, Settings, ErrorSvrc, $translate, $stateParams, Surveys, $uibModal, toaster, $state) {
    'ngInject'
    var _this = this;

    this.$onInit = function () {


        Surveys.getCategories(function (err, categories) {
            if (err)
                ErrorSvrc.showErrorModal(err);
            else {
                if (!categories || categories.length == 0) {
                    $state.go('surveys.edit');
                } else {
                    _this.categories = categories;
                    categories.forEach(function (category) {
                        category.templateRows = [];

                        Surveys.getTemplates(category.id, function (err, templates) {
                            if (err) {
                                ErrorSvrc.showErrorModal(err);
                            }
                            else {
                                var foundTemplates = [];
                                templates.forEach(function (template) {
                                    template.blockCount = 0;
                                    template.questionCount = 0;
                                    template.blocks.forEach(function (block) {
                                        template.blockCount += 1;
                                        template.questionCount += block.questions.length;
                                    });
                                    foundTemplates.push(template);
                                });

                                var chunks = [], i = 0, n = foundTemplates.length;
                                while (i < n) {
                                    category.templateRows.push(foundTemplates.slice(i, i += 3));
                                }
                            }

                        });

                    });
                }
            }
        });

        _this.useTemplate = function (template) {
            $state.go('surveys.edit', { surveyId: template.id, surveyClone: 'TEMPLATE' });
        };
    };
}

const SurveysTemplateSelectorView = function ($stateProvider) {
    'ngInject';
    $stateProvider.state('surveys.selector', {
        url: '/selector',
        controller: SurveysTemplateSelectorCtrl,
        controllerAs: '$ctrl',
        template: template,
        data: { pageTitle: 'Surveys Template Selector', roles: ['ADMIN', 'ADVISOR'] }
    });
};

export default SurveysTemplateSelectorView;
