'use strict';

import Style from "../../../../utilities/style.utils";
import SurveyResultHeatmapDetailCtrl from "./modal/survey_result_heatmap_detail.controller";
import hfSurveyCommentsCtrl from "../../components/survey_comments/survey_comments.component";
import Numbers from "../../../../utilities/number.utilities";


function SurveyResultsHeatMapViewCtrl($scope, Surveys, SegmentsFilterService, $translate, ErrorSvrc, $uibModal, $intercom) {
    var _this = this;

    _this.heatmapGroupBy = 'HIERARCHIES';
    _this.heatmapData = [];
    _this.heatmapRows = [];
    _this.loading = false;

    _this.heatmapGroupByModes = [
        { id: 'HIERARCHIES', label: $translate.instant('SURVEY_HEAP_MAP_GROUPBY_HIERARCHY') },
        { id: 'CHARACTERISTICS', label: $translate.instant('SURVEY_HEAP_MAP_GROUPBY_CHARACTERISTIC') },
        { id: 'GROUPS', label: $translate.instant('SURVEY_HEAP_MAP_GROUPBY_GROUPS') }
    ];

    _this.evaluateColor = function (index) {
        return Style.heatmapColors[index | 0];
    };

    _this.markExpanded = function (row, result) {

        _this.selectedResult = null;

        _this.heatmapRows.forEach(function (otherRow) {
            otherRow.expanded = false;
            otherRow.results.forEach(function (otherResult) {
                otherResult.expanded = false;
            });

        });

        if (row.type != 'BLOCK') {
            result.expanded = true;
            row.expanded = true;
            _this.selectedResult = result;
        }

        $uibModal.open({
            backdrop: 'static',
            template: require('./modal/survey_result_heatmap_detail.html'),
            controller: 'SurveyResultHeatmapDetailCtrl',
            controllerAs: '$ctrl',
            resolve: {
                survey: _this.survey,
                question: row.question,
                result: _this.selectedResult

            }
        });

    };

    _this.showComments = function (row, result) {

        var filters = {};
        if (result.type == 'HIERARCHIES') {
            filters.hierarchies = [result.segment];
        } else if (result.type == 'GROUPS') {
            filters.groups = [result.segment];
        } else {
            filters.segments = [result.segment];
        }

        filters.questionFilters = _this.questionFilters;
        /*
          hierarchies: _this.hierarchies,
            groups: _this.groups,
            segments: _this.segments,
         */


        $intercom.update({ hide_default_launcher: true });
        $uibModal.open({
            backdrop: false,
            animation: true,
            backdropClass: 'right',
            template: require('./../../components/survey_comments/modal.html'),
            controller: 'hfSurveyCommentsCtrl',
            controllerAs: '$ctrl',
            size: 'right survey',
            resolve: {
                survey: _this.survey,
                roundId: function () {
                    return _this.roundId;
                },
                question: row,
                filters: filters
            }
        }).result.then(function (data) {
            $intercom.update({ hide_default_launcher: false });
        });


    };

    _this.loadHeapMap = function () {
        _this.heatmapData = null;

        if (_this.survey) {
            _this.loading = true;
            var params = {};

            if (_this.hierarchyIds && _this.hierarchyIds.length) {
                params.hierarchyId = _this.hierarchyIds;
            };


            if (_this.segmentIds && _this.segmentIds.length) {
                params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.segmentIds);
            }


            if (_this.groupIds && _this.groupIds.length) {
                params.groupId = _this.groupIds;
            };

            if (_this.fromDateEnabled)
                params.from = _this.fromDate;

            if (_this.toDateEnabled)
                params.to = _this.toDate;

            if (_this.questionFilters)
                params.questionResults = _this.questionFilters.join(',');

            if (_this.roundId)
                params.roundId = _this.roundId;

            Surveys.stats.grouping(_this.survey.id, _this.heatmapGroupBy, params, function (err, stats) {

                var rows = [];
                var rowIds = [];

                if (err) {
                    ErrorSvrc.showErrorModal(err);
                    _this.loading = false;
                } else {
                    stats.results.forEach(function (groupByResult) {
                        var id;
                        var segment;
                        if (_this.heatmapGroupBy === 'HIERARCHIES') {
                            id = groupByResult.hierarchyId;
                            segment = groupByResult.hierarchyId;
                        } else if (_this.heatmapGroupBy === 'GROUPS') {
                            id = groupByResult.groupId;
                            segment = groupByResult.groupId;
                        } else {
                            id = groupByResult.characteristic.id;
                            segment = groupByResult.characteristic;
                        }

                        // Store the non enough employees result per segment
                        var nonEnoughEmployees = groupByResult.nonEnoughEmployees;

                        groupByResult.blocks.forEach(function (blockResult) {

                            var rowContent;
                            if (!rowIds[blockResult.blockId]) {
                                var rowContent = {
                                    title: blockResult.title,
                                    type: 'BLOCK',
                                    results: []
                                };
                                rowIds[blockResult.blockId] = rowContent;
                                rows.push(rowContent);
                            } else {
                                rowContent = rowIds[blockResult.blockId];
                            }

                            rowContent.results.push({
                                index: Numbers.roundNumber(blockResult.blockIndex, 1),
                                participants: blockResult.participants,
                                votes: blockResult.votes,
                                ratings: blockResult.ratings,
                                color: _this.evaluateColor(blockResult.blockIndex),
                                nonEnoughEmployees: nonEnoughEmployees
                            });


                            blockResult.questions.forEach(function (questionResult) {

                                var rowContent;
                                if (!rowIds[questionResult.question.id]) {
                                    rowContent = {
                                        title: questionResult.question.title,
                                        question: questionResult.question,
                                        type: questionResult.question.questionType,
                                        results: []
                                    };
                                    rowIds[questionResult.question.id] = rowContent;
                                    rows.push(rowContent);
                                } else {
                                    rowContent = rowIds[questionResult.question.id];
                                }

                                rowContent.results.push({
                                    index: Numbers.roundNumber(questionResult.questionIndex, 1),
                                    comments: questionResult.comments,
                                    participants: questionResult.participants,
                                    votes: questionResult.votes,
                                    ratings: questionResult.ratings,
                                    color: (questionResult.question.questionType != 'OPEN'
                                        ? _this.evaluateColor(questionResult.questionIndex) : ''),
                                    type: _this.heatmapGroupBy,
                                    segment: segment,
                                    nonEnoughEmployees: nonEnoughEmployees
                                });

                            });

                        });

                    });
                    _this.loading = false;
                }


                _this.heatmapRows = rows;
                _this.heatmapData = stats;


            });

        }
    };


    this.$onInit = function () {

        var filterArgs = ['survey', 'roundId', 'heatmapGroupBy', 'hierarchyIds', 'segmentIds', 'groupIds', 'questionFilters', 'fromDateEnabled',
             'fromDate', 'toDateEnabled', 'toDate'];

        filterArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (typeof newVal === 'undefined') return;
                if (newVal != oldValue || !_this.heatmapData) {
                    _this.loadHeapMap();
                }

            }, true);
        });

        _this.loadHeapMap();

    };

};

const hfSurveyResultsHeatmapView = angular.module('happyForceApp')
    .directive('hfSurveyResultsHeatmapView', function () {
        return {
            scope: {
                survey: '<',
                roundId: '<',
                groupIds: '<',
                segmentIds: '<',
                hierarchyIds: '<',
                questionFilters: '<',
                fromDateEnabled: '<',
                fromDate: '<',
                toDateEnabled: '<',
                toDate: '<'
            },
            template: require('./survey_results_heatmap_view.html'),
            controller: SurveyResultsHeatMapViewCtrl,
            controllerAs: 'srh',
            bindToController: true
        };
    });

export default hfSurveyResultsHeatmapView;
