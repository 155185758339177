'use strict';
function CommentInappropriateReportsModalCtrl($scope, Comments, $modalInstance,  reportedMessages) {
    'ngInject';
    var _this = this;
    _this.reportedMessages = reportedMessages;

    _this.ok = function () {
        $modalInstance.close();
    };

    _this.exit = function () {
        $modalInstance.dismiss();
    };

}




const CommentInappropriateReportsModal = angular.module('happyForceApp')
    .factory('CommentInappropriateReportsModal',
        function ($uibModal) {
            var methods = {};
            methods.showModal = function (inappropriateOther) {

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./comment_reports_list.html'),
                    controller: CommentInappropriateReportsModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        reportedMessages: function () {
                            return inappropriateOther;
                        }

                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );

export default CommentInappropriateReportsModal;
