


export default  function Announcements(Restangular, $uibModal, Settings) {
    'ngInject';

    var Company = Restangular.one('company');

    var methods = {};

    function prepareParams(params) {

        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = moment(params.from).format(Settings.apiDateFormat);
        }

        if (_params.to) {
            _params.to = moment(params.to).format(Settings.apiDateFormat);
        }

        return _params;
    }

    methods.getOne = function (id, callback) {
        Company.one('announcements').one('read').one(id).get().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });

    };

    methods.getResume = function (id, params, callback) {
        Company.one('announcements').one(id).one('resume').get(prepareParams(params)).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.delete = function (id, callback) {
        Company.one('announcements').one(id).remove().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    methods.getSections = function (params, callback) {
        Company.one('announcements').one('sections').get(prepareParams(params)).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.getSection = function (sectionId, params, callback) {
        Company.one('announcements').one('sections').one(sectionId).get(prepareParams(params)).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.updateSection  = function (announcementId, sectionId, callback) {
        methods.getOne(announcementId, function (err, announcement) {
            if (err) {
                callback(err);
            } else {
                // This is a hack to avoid to handle this on another place. But it not a good practice.
                announcement.type = 'STANDARD';
                announcement.sectionId = sectionId;
                methods.update(announcementId, announcement, callback);
            }
        });

    };



    methods.remove = function (id, callback, error) {
        Company.one('announcements').one(id).remove().then(callback, error);
    };

    methods.POST = function (announcement,  callback, error) {
        Company.customPOST(announcement, 'announcements').then(callback, error);
    };

    methods.PUT = function (id, announcement, callback, error) {
        Company.one('announcements').customPUT(announcement, id).then(callback, error);
    };

    methods.create = function (announcement, callback) {
        methods.POST(announcement, function (data) {
            callback(null, data);
        }, callback);
    };

    methods.update = function (announcementId, announcement, callback) {
        methods.PUT(announcementId, announcement, function (data) {
            callback(null, data);
        }, callback);
    };

    methods.getCategories = function (callback) {
        Company.one('announcements').one('categories').get().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    methods.addCategory = function (category, callback) {
        Company.one('announcements').customPOST(category, 'categories').then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.updateCategory = function (id, category, callback) {
        Company.one('announcements').one('categories').customPUT(category, id, {}).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.deleteCategory = function (id, callback) {
        Company.one('announcements').one('categories').one(id).remove().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.getFeedback = function (id, params, callback) {
        Company.one('feedback').one('announcements').one(id).get(params).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };


    return methods;
}
