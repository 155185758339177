import {Box, Tooltip, Typography} from '@mui/material';
import {MRT_Row, MRT_SelectCheckbox, MRT_TableInstance} from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {ReactNode} from 'react';
import {getService} from 'reactInAngular';
import {Person} from '../../types';


type SelectionBannerProps = {
    groupedAlert: ReactNode | null;
    selectedAlert: ReactNode | null;
    table: MRT_TableInstance<Person>,
    onDeleteUser: (row: MRT_Row<Person>[]) => void;
    onInvitationResend: (row: MRT_Row<Person>[]) => void;
    onResetUserProfile: (row: MRT_Row<Person>[]) => void;
    onResubscribeUser: (row: MRT_Row<Person>[]) => void;
};

const SelectionBanner = ({onInvitationResend, onResetUserProfile, onDeleteUser , selectedAlert, table}: SelectionBannerProps) => {

    const $translate = getService('$translate');

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', width:'100%'}}>
            <Box sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '6px',
                p: '4px 12px',
                width: '100%'
            }}>
                <MRT_SelectCheckbox table={table} /> {selectedAlert}{' '}
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '6px',
                alignItems: 'center',
            }}>
                <Tooltip title={$translate.instant('EMPLOYEE_RESET')}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <IconButton color="primary" onClick={() => onResetUserProfile(table.getSelectedRowModel()?.rows)}>
                            <RestartAltIcon/>
                        </IconButton>
                        <Typography color={'primary'} variant={'caption'}>({table.getSelectedRowModel()?.rows?.length})</Typography>
                    </Box>
                </Tooltip>

                <Tooltip title={$translate.instant('RESEND_EMAIL')}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <IconButton color="primary" onClick={() => onInvitationResend(table.getSelectedRowModel()?.rows)}>
                            <EmailIcon/>
                        </IconButton>
                        <Typography color={'primary'} variant={'caption'}>({table.getSelectedRowModel()?.rows?.length})</Typography>
                    </Box>
                </Tooltip>


                <Tooltip title={$translate.instant('EMPLOYEE_DELETE')}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <IconButton color="error" onClick={() => onDeleteUser(table.getSelectedRowModel()?.rows)}>
                            <DeleteIcon color={'error'}/>
                        </IconButton>
                        <Typography color={'error'} variant={'caption'}>({table.getSelectedRowModel()?.rows?.length})</Typography>
                    </Box>

                </Tooltip>
            </Box>
        </Box>
    );
}

export default SelectionBanner;
