'use strict';

import template from './milestones.html';

function MilestonesCtrl() {
    'ngInject';
    var _this = this;

    this.$onInit = function () {

        _this.editMilestone = function (milestone) {
            _this.edit()(milestone);
        };

        _this.createMilestone = function () {
            _this.create()();
        };

        $(document.body).on('click', '.panel-group .panel-heading a', function (e) {
            if ($(this).hasClass('edit')) {
                $('.panel-group .collapse').collapse('toggle');
                $(this).toggleClass('down');
            }
            ;

            e.preventDefault();
        });
    };

};

//Directive used to set metisMenu and minimalize button
const MilestonesComponent = angular.module('happyForceApp').directive('hfMilestones', function () {
    return {
        scope: {
            milestones: '=',
            edit: '&',
            create: '&'
        },
        restrict: 'E',
        template: template,
        controller: MilestonesCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});


export default MilestonesComponent;
