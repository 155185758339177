const BillingService = function (Restangular) {
    'ngInject';
    var Company = Restangular.one('company');

    var methods = {};


    methods.initialize = function (provider, callback) {
        Company.one('billing').one('initialize').customPOST({}, provider).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    methods.getBillingInfo = function (callback) {
        Company.one('billing').get().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.saveBillingInfo = function (billingDetails, callback) {
        Company.customPOST(billingDetails, 'billing').then(function (result) {

            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    methods.getSubscription =  function (callback) {
        Company.one('billing').one('subscription').get().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.getSubscriptionManageSession =  function (callback) {
        Company.one('billing').one('subscription').one('session').get().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.reSubscribe = function (callback) {
        Company.one('billing').one('subscription').customPOST({}, 'resubscribe').then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    return methods;

};

export default BillingService;
