import {Person} from '../../types';
import React, {useEffect} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch,
    Typography
} from '@mui/material';
import {getService} from 'reactInAngular';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import {APISettings} from '../../../../../../shared/services/migrated/axiosWrapper';

interface UserResendProps {
    person: Person | Person[];
    onExecuted: (person: Person | Person[]) => void;
    onClose: () => void;
}


const UserDeleteDialog = ({person, onExecuted, onClose}: UserResendProps) => {
    const toaster = getService('toaster');

    const $translate = getService('$translate')
    const ErrorSvrc = getService('ErrorSvrc');
    const PeopleService = getService('People');
    const [executing, setExecuting] = React.useState(false);
    const [leaveReason, setLeaveReason] = React.useState('OTHER');
    const [leaveDate, setLeaveDate] = React.useState(moment(new Date()));
    const [leaveReasonEnabled, setLeaveReasonEnabled] = React.useState(true);



    const getTurnoverReason = (reason: string) => {
        if (leaveReasonEnabled) {
            return  {
                turnoverType: leaveReason,
                turnoverDate: moment(leaveDate).format(APISettings.dateFormat)
            };
        } else {
            return null;
        }
    }


    const handleMultipleUserDeletions = async (persons: Person[]) => {
        const updatedPersons: Person[] = [];
        let errored = false;
        const turnoverReason = getTurnoverReason(leaveReason);
        for (const person of persons) {
            try {
                await new Promise((resolve, reject) => {
                    PeopleService.delete(person, turnoverReason, function (err) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(person);
                        }
                    });
                }).then((updatedPerson) => {
                    updatedPersons.push(updatedPerson as Person);
                });
            } catch (error) {
                ErrorSvrc.showError(error);
                errored = true;
                setExecuting(false);
                break; // Sale del bucle si hay un error
            }
        }
        if (!errored) {
            toaster.pop('success', null, $translate.instant('EMPLOYEE_UPDATED'));
            onExecuted(person);
            onClose();
        }
        setExecuting(false);
    }

    const handleSingleUserDeletion = (person: Person) => {

        setExecuting(true);

        const turnoverReason = getTurnoverReason(leaveReason);

        PeopleService.delete(person, turnoverReason, function (resendErr) {
            setExecuting(false);
            if (resendErr) {
                ErrorSvrc.showError(resendErr);
            }
            else {
                toaster.pop('success', null, $translate.instant('EMPLOYEE_UPDATED'));
                onExecuted(person);
                onClose();
            }
        });
    };



    return (
        <Dialog
            open={!!person}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant={'h4'} >
                    {Array.isArray(person) ?
                        $translate.instant('SURE_DELETE_EMPLOYEE_GROUP', { users: person.length })
                        : $translate.instant('SURE_DELETE_EMPLOYEE', { email: person?.email })
                    }
                </Typography>

            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant={'h6'} >
                        {$translate.instant('EMPLOYEE_LEAVE_TITLE')}
                    </Typography>

                    <Typography variant={'body2'}  sx={(theme) => ({marginTop: theme.spacing(2)})}>
                        {$translate.instant('EMPLOYEE_LEAVE_DESCRIPTION')}
                    </Typography>

                    <Grid container sx={(theme) => ({marginTop: theme.spacing(2)})}>
                        <Grid item xs={6}>

                            <DatePicker
                                disabled={!leaveReasonEnabled}
                                label={$translate.instant('EMPLOYEE_SET_LEAVEDATE')}
                                value={leaveDate}
                                slotProps={{ textField: { variant: 'standard', } }}
                                onChange={(newValue) => setLeaveDate(newValue)}
                            />

                        </Grid>

                        <Grid item xs={6}>


                            <FormControl  size="small" variant={"standard"}>

                                <InputLabel
                                    htmlFor="userLeaveReason">{$translate.instant('EMPLOYEE_SET_LEAVE_REASON')}</InputLabel>

                                <Select
                                    disabled={!leaveReasonEnabled}
                                    fullWidth
                                    id="userLeaveReason"
                                    value={leaveReason}
                                    onChange={(e) => {
                                        setLeaveReason(e.target.value as string);
                                    }}
                                    aria-describedby="userLeaveReason-text">
                                    <MenuItem value={'VOLUNTARY'}>
                                        {$translate.instant('TURNOVER_TYPE_VOLUNTARY')}
                                    </MenuItem>
                                    <MenuItem value={'INVOLUNTARY'}>
                                        {$translate.instant('TURNOVER_TYPE_INVOLUNTARY')}
                                    </MenuItem>
                                    <MenuItem value={'OTHER'}>
                                        {$translate.instant('TURNOVER_TYPE_OTHER')}
                                    </MenuItem>
                                </Select>

                                <FormHelperText id="userRole-text">
                                    {leaveReason === 'VOLUNTARY' && $translate.instant('EMPLOYEE_LEAVE_VOLUNTARY_DESCRIPTION')}
                                    {leaveReason === 'INVOLUNTARY' && $translate.instant('EMPLOYEE_LEAVE_INVOLUNTARY_DESCRIPTION')}
                                    {leaveReason === 'OTHER' && $translate.instant('EMPLOYEE_LEAVE_OTHER_DESCRIPTION')}

                                </FormHelperText>

                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>

                            <FormGroup>
                                <FormControlLabel
                                    onChange={(e) => {setLeaveReasonEnabled(e.target.checked)}}
                                    control={<Switch
                                        checked={leaveReasonEnabled} />}
                                    label={<Typography variant={'caption'} fontWeight={'bold'}>
                                        {$translate.instant('EMPLOYEE_LEAVE_INFO_ENABLED')}
                                    </Typography> } />
                            </FormGroup>

                        </Grid>
                    </Grid>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{$translate.instant('CANCEL')}</Button>
                <Button
                    color="error"
                    variant="contained"
                    disabled={executing}
                    onClick={() => {Array.isArray(person) ? handleMultipleUserDeletions(person): handleSingleUserDeletion(person)}} autoFocus>
                    {$translate.instant('EMPLOYEE_DELETE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserDeleteDialog;
