import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { angularize, getService } from 'reactInAngular';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';

const LifeCycleEvolutionChart = ({ momentResults, loading }) => {
    const [chartConfig, setChartConfig] = useState(null);

    const hexToRgba = (hex, alpha) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha || 0})` : null;
    };


    function initializeSerie(id, name, type, linkedTo = null) {
        return {
            id: id,
            name: name,
            type: type,
            linkedTo: linkedTo,
            lineWidth: type !== 'line' ? 1 : 0,
            data: [],
            fillColor: {
                linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [1, hexToRgba(Highcharts.getOptions().colors[0], 0)]
                ]
            },
            marker: {
                symbol: 'circle'
            }
        };
    }

    useEffect(() => {

        if (!momentResults) {
            return;
        }


        var moments = [];
        var gainSeries = [];
        var painSeries = [];

        momentResults.forEach((result, index) => {
            moments.push(result.name);
            // Each moment can have multiple surveys

            result.surveys.forEach((survey, surveyIndex) => {

                if (gainSeries[surveyIndex] === undefined) {
                    gainSeries[surveyIndex] = initializeSerie('gain_' + surveyIndex,
                        'Opinión',
                        surveyIndex === 0 ? 'areaspline' : 'line',
                        surveyIndex === 0 ? null : 'gain_0');
                }


                if (painSeries[surveyIndex] === undefined) {
                    painSeries[surveyIndex] = initializeSerie('pain_' + surveyIndex,
                        'Importancia',
                        surveyIndex === 0 ? 'spline' : 'line',
                        surveyIndex === 0 ? null : 'pain_0');
                }


                gainSeries[surveyIndex].data.push({
                    x: index,
                    y: survey.gain,
                    label: survey.details.title.defaultValue ? survey.details.title.defaultValue : result.name
                });

                painSeries[surveyIndex].data.push({
                    x: index,
                    y: survey.pain,
                    label: survey.details.title.defaultValue ? survey.details.title.defaultValue : result.name

                });
            });

        });


        const compiledChartConfig = {
            xAxis: {
                categories: moments
            },
            title: {
                text: ''
            },
            yAxis: {
                min: 0,
                max: 10,

            },
            tooltip: {
                shared: true,
                valueSuffix: ' ',
                formatter: function () {
                    var s = '<b>' + this.x + '</b>';

                    this.points.forEach(function (point) {


                        s += '<br/>' + point.series.name + '(' + point.point.label + '): ' + point.y;
                    });

                    return s;
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.5
                }
            },
            series: Array.prototype.concat(gainSeries, painSeries)
        };

        setChartConfig(compiledChartConfig);
    }, [momentResults]);

    return (
        <HappyLoading loading={loading}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartConfig}
                containerProps={{ id: 'curveChart' }}
            />
        </HappyLoading>
    );
};

angularize(LifeCycleEvolutionChart, 'hfLifeCycleEvolutionChart', angular.module('happyForceApp'), {
    momentResults: '<',
    loading: '<'
});

export default LifeCycleEvolutionChart;
