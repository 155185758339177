'use strict';

function HRISWoltersKluwerCtrl($scope, ErrorSvrc, HRSIService, $uibModalInstance, $window, CompanySvrc) {
    'ngInject'

    $scope.executing = false;

    //TODO: Why whe have to do this?
    $scope.HRSIService = HRSIService;
    $scope.ErrorSvrc = ErrorSvrc;

    $scope.cancel = function () {
        $uibModalInstance.dismiss('close');
    };

    $scope.ok = function () {
        $scope.executing = true;
        delete $scope.errorMsg;
        var params = {
            type: 'WOLTERSKLUWER'
        };

        // We need the company id for later usage:
        CompanySvrc.getUserCompany(function (err, company) {
            if (err) {
                $scope.errorMsg = ErrorSvrc.getErrorMessage(err);
            } else {
                // Now we will save the config
                $scope.HRSIService.connect(params, function (err, result) {
                    $scope.executing = false;
                    if (err) {
                        $scope.errorMsg = ErrorSvrc.getErrorMessage(err);
                    } else {
                        // If everything goes fine, we have to redirect the user to the WK login page

                        var companyId = company.id;

                        const address = result.redirectUrl
                            + '&state=' + companyId + '_WOLTERSKLUWER';

                        // Now redirect the user to the WK login URL
                        $window.location.href = address;

                    }
                });
            }
        });
    };

}

const HRISWoltersKluwerModal = angular.module('happyForceApp')
    .factory('HRISWoltersKluwerModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showModal = function (callback) {
                $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    template: require('./hris_wolterskluwer.html'),
                    controller: HRISWoltersKluwerCtrl
                }).result.then(function () {
                    callback();
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;
        }
    );

export default HRISWoltersKluwerModal;