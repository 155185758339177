'use strict';

var CompanyStatusCtrl = function ($scope, CompanyStatus) {

    var _this = this;

    this.$onInit = function () {

        var monitoring = _this.monitoring ? _this.monitoring : [];


        _this.show = false;

        function evalMonitoringVars() {
            _this.show = false;
            monitoring.forEach(function (monitor) {
                _this.show = (_this.show || _this[monitor]);
            });
        }

        monitoring.forEach(function (key) {
            // Listeners
            $scope.$watch(
                function () {
                    return CompanyStatus[key];
                },
                function (newVal, oldVal) {
                    _this[key] = newVal;
                    evalMonitoringVars();
                });
        });
    };

};


const template = require('./company-status-message.html');


const hfCompanyStatusMessage = angular.module('happyForceApp')
    .directive('hfCompanyStatusMessage', function () {
        return {
            scope: {
                monitoring: '='
            },
            template: template,
            controller:  CompanyStatusCtrl,
            controllerAs: 'csm',
            transclude: true,
            bindToController: true
        };
    });

export default hfCompanyStatusMessage;
