'use strict';

function CommentReplyItemCtrl($scope, ClassificationService, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.commentUpdated = function (err, updatedComment) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
        }
        else {
            _this.comment = updatedComment;
        }
    };

    this.$onInit = function () {

    };
}

const hfCommentReplyItem = angular.module('happyForceApp').directive('hfCommentReplyItem', function () {
    return {
        scope: {
            comment: '=',
            replyOf: '=',
            showConversationLink: '<?',
            showReplyWithAnnouncementLink: '<?',
            size: '<'
        },
        restrict: 'E',
        template: require('./comment_reply_item.html'),
        controller: CommentReplyItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfCommentReplyItem;
