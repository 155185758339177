'use strict';

function SegmentSingleSelectorCtrl(Characteristics, $scope, Settings, $translate) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {

        if (_this.autoConfirm) {
            _this.buttons = 'no';
        } else {
            _this.buttons = 'right';
        }

        if (!_this.characteristicValues)
            _this.characteristicValues = [];

        _this.dateFormat = $translate.instant('DATE_FORMAT');
        if (_this.dateFormat === 'DATE_FORMAT')
            _this.dateFormat = Settings.defaultDateFormat;

        _this.sanitizeDateFormat = _this.dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
        _this.apiDateFormat = Settings.apiDateFormat;

        $scope.opened = false;
        _this.open = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = !$scope.opened;
        };

        _this.setValue = function (value) {
            var currentSelectedValue = _.find(_this.characteristicValues, function (c) {
                return c.characteristicId == _this.characteristic.id;
            });

            if (currentSelectedValue) {
                if (_this.characteristic.questionType === 'DATE' && _this.dateMode) {
                    currentSelectedValue.date = moment(value).format(Settings.apiDateFormat);
                } else {
                    currentSelectedValue.value = value;
                }
            } else {
                currentSelectedValue = {
                    characteristicId: _this.characteristic.id,
                    questionType: _this.characteristic.questionType
                };

                if (_this.characteristic.questionType == 'DATE' && _this.dateMode) {
                    currentSelectedValue.date = moment(value).format(Settings.apiDateFormat);
                } else {
                    currentSelectedValue.value = value;
                }

                _this.characteristicValues.push(currentSelectedValue);
            }

            this.selectedValue = currentSelectedValue;
            if (_this.characteristic.questionType != 'DATE' || !_this.dateMode)
                this.selectedValue.label = Characteristics.getCharacteristicValueName(
                    _this.selectedValue.characteristicId, _this.selectedValue.value);

            if (this.onSelect) {
                _this = this;

                $scope.$evalAsync(
                    function ($scope) {
                        _this.onSelect(null);
                    }
                );
            }
        };


        $scope.$watch(function () {
            return _this.characteristicValues;
        }, function (n, o) {
            _this.characteristic = Characteristics.getCharacteristicById(_this.characteristicId);
            if (_this.characteristic)
                _this.selectedValue = _.find(_this.characteristicValues, function (c) {
                    return c.characteristicId == _this.characteristic.id;
                });

            if (_this.selectedValue) {
                if (_this.characteristic.questionType != 'DATE' || !_this.dateMode) {
                    var valueLabel =
                        Characteristics.getCharacteristicValueName(_this.selectedValue.characteristicId, _this.selectedValue.value);

                    _this.selectedValue.label = valueLabel;
                } else if (_this.characteristic.questionType == 'DATE' && _this.dateMode) {
                    if (typeof _this.selectedValue.date === 'string')
                        _this.selectedValue.representationDate = moment(_this.selectedValue.date, Settings.apiDateFormat).toDate();
                    else
                        _this.selectedValue.representationDate = _this.selectedValue.date;
                }
            } else {
                _this.selectedValue = null;
            }
            ;
        });
    };

}



const hfSegmentSingleSelector = angular.module('happyForceApp')
    .directive('hfSegmentSingleSelector', function () {
        return {
            scope: {
                onSelect: '&',
                characteristicValues: '=',
                selectValueLabel: '@',
                characteristicId: '=?',
                disabled: '=?',
                autoConfirm: '=?',
                dateMode: '=?'
            },
            template: require('./segment-single-selector.html'),
            controller: SegmentSingleSelectorCtrl,
            controllerAs: '$ctrl',
            transclude: true,
            bindToController: true
        };
    });

export default hfSegmentSingleSelector;
