
import {useEffect, useMemo, useState} from 'react';
import {getHierarchyName} from '../services/migrated/hierarchy.service';


const useHierarchyName = (hierarchyId: string | undefined) => {


    const [hierarchyName, setHierarchyName] = useState<string>('');

    useEffect(() => {
        if (hierarchyId) {
            getHierarchyName(hierarchyId, function (err: any, name: any) {

                if (err) {
                    setHierarchyName('?')
                } else {
                    setHierarchyName(name);
                }
            });
        }
    }, [hierarchyId]);


    const memoizedHierarchyName = useMemo(() => hierarchyName, [hierarchyName]);

    return {
        hierarchyName: memoizedHierarchyName
    }
}

export default useHierarchyName;
