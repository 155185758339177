'use strict';
import hfFeedbackIdeas from "./feedback-ideas.controller";
import hfFeedbackIdeasStats from "./components/feedback-ideas-stats/feedback-ideas-stats.controller";
import FeedbackIdeasInitiativeEditorConfig from "./feedback-ideas-initiative-editor/feedback-ideas-initiative-editor";
import FeedbackIdeasInitiativeDetailConfig from "./feedback-ideas-initiative-detail/feedback-ideas-initiative-detail";
import hfFeedbackInitiativesListing
    from "./components/feedback-initiatives-listing/feedback-initiatives-listing.controller";
import hfFeedbackInitiativeBox from "./components/feedback-initiative-box/feedback-initiative-box.controller";
import hfFeedbackInitiativeDates from "./components/feedback-initiative-dates/feedback-initiative-dates.controller";
import hfFeedbackInitiativeDropdown
    from "./components/feedback-initiative-dropdown/feedback-initiative-dropdown.controller";
import FeedbackIdeasStatsDetailModal
    from "./components/feedback-ideas-stats-detail/feedback-ideas-stats-detail.controller";
import hfFeedbackIdeasListing from "./components/feedback-ideas-listing/feedback-ideas-listing.controller";

function FeedbackIdeasConfig($stateProvider) {
    'ngInject'
    $stateProvider
        .state('feedback.ideas', {
            url: '/ideas',
            template: '<hf-feedback-ideas></hf-feedback-ideas>',
            data: { pageTitle: 'SIDEMENU_IDEAS', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
            /*,
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            files: [
                                '/plugins/highcharts/highstock.js',
                                '/plugins/highcharts/highcharts-more.js']
                        }
                    ]);
                }
            }*/
        });
}

const FeedbackIdeasCnf = angular.module('happyForceApp')
    .config(FeedbackIdeasConfig);

export default FeedbackIdeasCnf;
