import template from './advisors_tools.html';
import hfAdvisorsToolsEmployeeLocator from './sections/employee_locator/advisor_tools_employee_locator.controller';


function AdvisorsToolsCtrl($scope, AuthSrvc) {
    'ngInject'
    var _this = this;

    this.$onInit = function () {

        _this.advisor = AuthSrvc.isADVISOR();
        if (!AuthSrvc.isADVISOR()) {
            return;
        };


        // Pediente de meter algo
        _this.header = {
            title: 'Advisors!',
            subtitle: 'Recopilatorio de utilidades'
        };
    };

}



function AdvisorsToolsView($stateProvider) {
    'ngInject'
    $stateProvider.state('advisors.tools', {
        url: '/advisors/tools',
        controller: AdvisorsToolsCtrl,
        controllerAs: '$ctrl',
        bindToController: true,
        template: template,
        data: { pageTitle: 'Advisors', roles: ['ADVISOR'] }
    });
}

export default AdvisorsToolsView;
