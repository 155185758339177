'use strict';

import hfAnnouncementsSectionTitleConfig from './components/announcement_section_title_config/announcement_section_title_config.controller';
import hfCelebrationsSectionConfig from './components/celebrations_section_configuration/celebrations_section_configuration.controller';
import hfDefaultSectionConfig from './components/default_section_configuration/default_section_configuration.controller';
import hfStandardSectionConfig from './components/standard_section_configuration/standard_section_configuration.controller';
import hfUnreadSectionConfig from './components/unread_section_configuration/unread_section_configuration.controller';
import AnnouncementsAddSectionModalCtrl from '../add_modal/announcements_add_section_modal.component';

function AnnouncementsSectionConfigCtrl($scope, $translate, ErrorSvrc, AnnouncementsSectionsConfiguration, toaster, AnnouncementsAddSectionModal) {
    'ngInject';

    const _this = this;

    _this.CELEBRATIONS_SECTION_ID = AnnouncementsSectionsConfiguration.fixedTypeIDS.CELEBRATIONS_SECTION_ID;
    _this.UNREAD_SECTION_ID = AnnouncementsSectionsConfiguration.fixedTypeIDS.UNREAD_SECTION_ID;
    _this.DEFAULT_SECTION_ID = AnnouncementsSectionsConfiguration.fixedTypeIDS.DEFAULT_SECTION_ID;

    _this.sections = [];
    _this.loadSectionsConfig = function () {
        AnnouncementsSectionsConfiguration.getConfiguration(function (err, currentConfiguration) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                // Load from the server....
                _this.originalSectionsConfig = currentConfiguration;
                angular.copy(_this.originalSectionsConfig, _this.sections);

                // Asume all the sections are valid
                _this.sections.forEach(function (section) {
                    section.isValid = true;
                });
                _this.configurationValid = true;
            }

        });


    };


    _this.selectSection = function (sectionId) {

        // If there is a selected section, check if it's valid before allow to change to other one
        if (!_this.selectedSection || _this.selectedSection.isValid) {
            // Unselect all sections, and set the one we want as selected
            _this.sections.forEach(function (section) {
                if (section.id === sectionId) {
                    if (_this.selectedSection && _this.selectedSection.id === sectionId) {
                        delete _this.selectedSection;
                    } else {
                        _this.selectedSection = section;
                    }
                }
            });
        };
    };

    _this.setIsValid = function (sectionId, isValid) {
        var index = _.findIndex(_this.sections, { id: sectionId });
        if (index >= 0) {
            _this.sections[index].isValid = isValid;
        }

        // Now check if all sections are valid
        _this.configurationValid = _this.sections.every(function (section) { return section.isValid;});
    };

    _this.verifyConfigChange = function () {
        _this.configChanged = !angular.equals(_this.originalSectionsConfig, _this.sections);
    };


    function moveSection(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        _this.verifyConfigChange();
    }


    _this.removeSection = function (sectionId) {
        var index = _.findIndex(_this.sections, { id: sectionId });
        if (index >= 0) {
            _this.sections.splice(index, 1);
        }
        delete _this.selectedSection;
    };

    _this.moveDownSection = function (sectionId) {
        var index = _.findIndex(_this.sections, { id: sectionId });
        if (index >= 0) {
            var newIndex = index + 1;
            if (newIndex < _this.sections.length - 1) {
                // Only allow to move if we are above the last section
                moveSection(_this.sections, index, newIndex);
            }
        }

    };

    _this.moveUpSection = function (sectionId) {
        var index = _.findIndex(_this.sections, { id: sectionId });
        if (index >= 0) {
            var newIndex = index - 1;
            if (newIndex >= 0) {
                moveSection(_this.sections, index, newIndex);
            }
        }
    };

    _this.addSection = function () {
        AnnouncementsAddSectionModal.showModal(_this.sections, function (newSection) {
            // Is is an standard section, we need to create a tempId; before sending it to the server we will have to clean it up
            if (newSection.id == null) {
                newSection.id = 'tmp-section-' + Math.random().toString(36).substr(2, 9);
            } else if (newSection.id ==  _this.CELEBRATIONS_SECTION_ID) {
                // Celebration section is valid by default
                newSection.isValid = true;
            }

            _this.sections.splice(_this.sections.length - 1, 0, newSection);
            _this.selectSection(newSection.id);
            _this.verifyConfigChange();
        });
    };

    _this.saveConfiguration = function () {
        // Before proceeed we need to clean up all the sections that have been added temporal ids
        _this.sections.forEach(function (section) {
            if (section.id && section.id.startsWith('tmp-section-'))
                delete section.id;
        });

        AnnouncementsSectionsConfiguration.updateConfiguration(_this.sections, function (err) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop('success', null, ($translate.instant('ANNOUNCEMENTS_SECTIONS_CONFIGURATION_SAVED')));
                _this.loadSectionsConfig();
            }
        });
    };

    this.$onInit = function () {
        _this.loadSectionsConfig();


        $scope.$watch(angular.bind(_this, function () {
            return _this['sections'];
        }), function (n, o) {
            if (n == o) return;
            _this.verifyConfigChange();
        }, true);
    };

}

const hfAnnouncementsSectionsConfig = angular.module('happyForceApp')
    .directive('hfAnnouncementsSectionsConfig', function () {
        return {
            template: require('./announcements_sections_config.html'),
            controller: AnnouncementsSectionConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAnnouncementsSectionsConfig;