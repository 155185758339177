import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';
import Style from "../../../../../utilities/style.utils";
import Charts from "../../../../../utilities/charts.utilities";
import Dates from "../../../../../utilities/date.utilities";
import {APISettings} from "../../../../../shared/services/migrated/axiosWrapper";
import EmptyState from "../../../../../shared/components/_migrated/empty_state/empty_state";
import HappyLoading from "../../../../../shared/components/_migrated/loader/loading.directive";

const EngagementDetailEnpsEvolution = ({ data, noData, hasEnoughActiveEmployees, showCompanyBenchmark, mode, dateGrouping }) => {
    const [loading, setLoading] = useState(true);
    const [chartConfig, setChartConfig] = useState(null);
    const $translate = getService('$translate');


    const renderChart = (data, industryName) => {
        const offset12Hours = 12 * 3600 * 1000;
        const min = mode === 'ENPS_MODE' ? -100 : 0;
        const max = mode === 'ENPS_MODE' ? 100 : 10;
        let dataMin = Number.MAX_VALUE;
        let dataMax = Number.MIN_VALUE;

        let results = [];
        let benchmarks = [];
        let industryBenchmarks = [];
        let companyBenchmarks = [];

        data.forEach(item => {
            var date = Dates.fromAPIFormat(item.from, APISettings.apiDateFormat).getTime() + offset12Hours;

            const result = mode === 'ENPS_MODE' ? item.result : item.averageResult;
            const benchmark = mode === 'ENPS_MODE' ? item.globalBenchmark : item.globalAverageBenchmark;
            const industryBenchmark = mode === 'ENPS_MODE' ? item.industryBenchmark : item.industryAverageBenchmark;
            const companyBenchmark = mode === 'ENPS_MODE' ? item.companyBenchmark : item.companyAverageBenchmark;

            results.push([date, result !== undefined ? result : null]);
            benchmarks.push([date, benchmark !== undefined ? benchmark : null]);
            industryBenchmarks.push([date, industryBenchmark !== undefined ? industryBenchmark : null]);
            companyBenchmarks.push([date, companyBenchmark !== undefined ? companyBenchmark : null]);

            dataMin = Math.min(dataMin, result ? result : 0, benchmark);
            dataMax = Math.max(dataMax, result ? result : 0, benchmark);
        });


        dataMin = Math.max(min, Math.floor(dataMin - 0.1));
        dataMax = Math.min(max, Math.ceil(dataMax + 0.1));


        var zones;
        if (mode === 'ENPS_MODE') {
            zones = [
                {
                    value: -10,
                    color: Style.bad
                },
                {
                    value: 20,
                    color: Style.neutral
                },
                {
                    value: 60,
                    color: Style.warning
                }, {
                    color: Style.good
                }];
        } else {
            zones = [
                {
                    value: 6,
                    color: Style.bad
                },
                {
                    value: 9,
                    color: Style.warning
                }, {
                    color: Style.good
                }];
        }


        Charts.initializeDateFormats();

        var series = [
            {
                name: $translate.instant('SURVEY_RESULTS_SCORE'),
                data: results,
                zones: zones,
                color: '#0CAD00',
                connectNulls: true,
                lineWidth: 2,
                showInLegend: false
            },
            {
                type: 'line',
                name: 'Happyforce',
                data: benchmarks,
                connectNulls: true,
                color: '#4A90E2',
                dashStyle: 'dash',
                step: 'right',
                lineWidth: 1,
                marker: {
                    enabled: false
                }
            },
            {
                type: 'line',
                name: industryName,
                data: industryBenchmarks,
                connectNulls: true,
                color: '#FFC200',
                dashStyle: 'dash',
                step: 'right',
                lineWidth: 1,
                marker: {
                    enabled: false
                }
            }
        ];
        if (showCompanyBenchmark) {
            series.push({
                type: 'line',
                name: $translate.instant('COMPANY'),
                data: companyBenchmarks,
                connectNulls: true,
                color: '#9A9A9A',
                dashStyle: 'dash',
                step: 'right',
                lineWidth: 1,
                marker: {
                    enabled: false
                }
            });
        }

        return {
            chart: {
                type: 'spline',
                height: 190,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                fontFamily: 'HelveticaNeue-Light'
            },
            title: null,
            plotOptions: {
                series: {
                    lineWidth: 4,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: Charts.getAxisDateFormatFromDateGrouping(dateGrouping),
                startOnTick: false,
                title: {
                    enabled: false
                }
            },
            yAxis: {
                tickColor: '#b5bbc3',
                title: {
                    enabled: false
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'Dot',
                labels: {
                    style: {
                        color: '#b5bbc3'
                    }
                },
                min: dataMin,
                max: dataMax,
                tickInterval: max / 5
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: true,
                useHTML: true,
                itemDistance: 40,
                labelFormatter: function () {
                    if (this.index === 0) {
                        return this.name;
                    }

                    var icon = '';
                    if (this.visible) {
                        icon += '<img src="/images/icon-eye-visible.svg" alt="">';
                    } else {
                        icon += '<img class="legend-item-hidden" src="/images/icon-eye-hidden.svg" alt="">';
                    }

                    return this.name + ' ' + icon;
                }
            },
            tooltip: {
                enabled: true,
                pointFormat: '<span style=\"font-size: 10px\">{series.name}: <b>{point.y:.1f}</b></span>',
                headerFormat: '<b>{point.x:' + Charts.getDateFormatFromDateGrouping(dateGrouping) + '}</b>',
                split: true,
                crosshairs: true,
                shared: true
            },
            series: series
        };

        // If is eNPS show dates by Quarters
        /*chartOptions.options.xAxis.labels = {};
        chartOptions.options.xAxis.labels.formatter = function () {
            var quarter = Math.ceil((new Date(this.value).getMonth() + 1) / 3);
            return Highcharts.dateFormat('Q' + quarter + ' %Y', this.value);
        };*/


    };

    useEffect(() => {
        if (data && !_.isEmpty(data) && mode) {
            setLoading(true);
            const CompanySvrc = getService('CompanySvrc');

            const Settings = getService('Settings');

            CompanySvrc.getCompanyIndustryName((err, industryName) => {


                industryName = (!err && industryName) ? industryName : 'Industry';

                const chartConfig = renderChart(data, industryName);
                setChartConfig(chartConfig);
                setLoading(false);
            });
        } else {
            setChartConfig(null);
        }
    }, [data, mode, dateGrouping, noData]);


    return (
        <HappyLoading message={$translate.instant('LOADING_DATA_SENTENCE_1')} loading={loading}>
            {
                noData && (
                    <EmptyState
                        image="images/empty_state_chart.png"
                        small={false}
                        message={$translate.instant('SCORE_NO_DATA')}
                        submessage={$translate.instant('SCORE_NO_DATA_DESCRIPTION')}
                    />
                )
            }

            {
                !noData && chartConfig && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartConfig}
                    />
                )
            }
        </HappyLoading>
    );
}

angularize(EngagementDetailEnpsEvolution, 'hfEngagementEnpsDetailEvolution', angular.module('happyForceApp'), {
    data: '=',
    noData: '<',
    hasEnoughActiveEmployees: '<',
    showCompanyBenchmark: '=',
    mode: '<',
    dateGrouping: '<'
});

export default EngagementDetailEnpsEvolution;
