'use strict';

import {getHierarchyAll, getHierarchyName, getHierarchyPath} from "../../services/migrated/hierarchy.service";

function MilestoneOperationsCtrl($scope, $translate, Milestone, $uibModalInstance, addMilestone, editMilestone, deleteMilestone, currentMilestone, params, Settings, ErrorSvrc) {
    'ngInject';

    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';

    $scope.isDelete = deleteMilestone;
    $scope.isEdit = editMilestone;
    $scope.isAdd = addMilestone;

    var dateFormat = $translate.instant('DATE_FORMAT');
    if (dateFormat === 'DATE_FORMAT')
      dateFormat = Settings.defaultDateFormat;

    $scope.dateRangePicker = {
        options: {
            autoUpdateInput: false, singleDatePicker: true, autoApply: true, showDropdowns: true, opens: 'center', locale: { format: dateFormat }
        }
    };

    $scope.dateFormat = dateFormat;

    if (editMilestone || deleteMilestone) {
        $scope.milestone = currentMilestone;
        $scope.dateRangePicker.options.startDate = new Date($scope.milestone.date);
    } else {
        $scope.milestone = {};
        if (params && params.date) {
            $scope.milestone.date = params.date;
        }

        if (params && params.hierarchyId) {
            $scope.milestone.hierarchyId = params.hierarchyId;
        }
    }

    $scope.pathData = [];
    $scope.rootData = [];

    function loadRootHierarchy() {
        // Get the full path of hierarchies
        //Restangular.one('company').one('hierarchy').get().then(
        getHierarchyAll(function (err, rootData) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $scope.rootData = rootData;
            }
        });
    };

    loadRootHierarchy();

    $scope.resetHierarchySelection = function () {
        $scope.pathData = new Array();
        $scope.milestone.hierarchyId = null;
    };

    $scope.setSelectedHierarchy = function (selectedHierarchy) {
        $scope.selectedHierarchy = selectedHierarchy;
        $scope.milestone.hierarchyId = selectedHierarchy;
        if (selectedHierarchy != null) {
            var params = {};
            params.hierarchyId = $scope.selectedHierarchy;

            // Get the full path of hierarchies
            getHierarchyPath(selectedHierarchy, function (err, pathData) {
                // we need to addapt the incomming path, to our model
                $scope.pathData.length = 0;
                _.forEach(pathData, function (pathElement) {

                    var children = Array();
                    _.forEach(pathElement.childrenIds, function (childrenId) {
                        getHierarchyName(childrenId, function (err, name) {
                            children.push({
                                id: childrenId,
                                name: name
                            });
                        });

                    });

                    $scope.pathData.push({
                            id: pathElement.id,
                            name: pathElement.name,
                            parentId:  pathElement.parentId,
                            children: children
                        }
                    );
                });

            }, function errorCallback(response) {

                ErrorSvrc.showErrorModal(response);
            });

        } else {
            $scope.pathData.length = 0;
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    $scope.ok = function () {
        if ($scope.milestone.selectedDate) {
            $scope.milestone.date = $scope.milestone.selectedDate;
        }

        var milestone = angular.copy($scope.milestone);
        milestone.type = 'OTHER';
        if (addMilestone) {
            Milestone.POST(milestone, function (result) {
                $uibModalInstance.close(result);
            }, function (error) {

                $scope.errored = true;
                $scope.errorMsg = error;
            });
        } else if (editMilestone) {
            Milestone.PUT(milestone, function (result) {
                $uibModalInstance.close(result);
            }, function (error) {

                $scope.errored = true;
                $scope.errorMsg = error;
            });
        } else if (deleteMilestone) {
            Milestone.DELETE(milestone.id, function (result) {
                $uibModalInstance.close({});
            }, function (error) {

                $scope.errored = true;
                $scope.errorMsg = error;
            });
        }
    };

    $scope.delete = function () {
        $uibModalInstance.close('DELETE');
    };
}


const MilestoneOperationsModals = angular.module('happyForceApp')
    .factory('MilestoneOperationsModals',
        function ($uibModal) {
            'ngInject'

            var operations = {};

            operations.createMilestone = function (params, callback) {

                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    template: require('./add.html'),
                    controller: MilestoneOperationsCtrl,
                    resolve: {
                        params: function () {
                            return params;
                        },

                        addMilestone: function () {
                            return true;
                        },

                        editMilestone: function () {
                            return false;
                        },

                        deleteMilestone: function () {
                            return false;
                        },

                        currentMilestone: function () {
                            return null;
                        }
                    }
                });
                modalInstance.result.then(function (data) {
                    callback(null, data);
                }, callback);

            };

            operations.editMilestone =  function (milestone, callback) {
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    template: require('./add.html'),
                    controller: MilestoneOperationsCtrl,
                    resolve: {
                        params: function () {
                            return {};
                        },

                        addMilestone: function () {
                            return false;
                        },

                        editMilestone: function () {
                            return true;
                        },

                        deleteMilestone: function () {
                            return false;
                        },

                        currentMilestone: function () {
                            return angular.copy(milestone);
                        }
                    }
                });
                modalInstance.result.then(function (response) {
                    if (response === 'DELETE') {
                        var modalInstanceDelete = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            template: require('./delete.html'),
                            controller: MilestoneOperationsCtrl,
                            resolve: {
                                params: function () {
                                    return {};
                                },

                                addMilestone: function () {
                                    return false;
                                },

                                editMilestone: function () {
                                    return false;
                                },

                                deleteMilestone: function () {
                                    return true;
                                },

                                currentMilestone: function () {
                                    return milestone;
                                }
                            }
                        });
                        modalInstanceDelete.result.then(function (data) {
                            callback(null, data);
                        }, callback);
                    } else {
                        callback(null, response);
                    }
                }, callback);
            };

            return operations;

        }
    );

export default MilestoneOperationsModals;

