import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import moment from 'moment';
import Numbers from '../../../../../../../utilities/number.utilities';
import Valuation from '../../../../../../../utilities/valuation.utils';
import {APISettings} from '../../../../../../../shared/services/migrated/axiosWrapper';
import Dates from '../../../../../../../utilities/date.utilities';
import EngagementDetailCharts from '../../../../../engagement_detail.charts';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ScoreQuestionRow from '../question_box_row/score_question_box';
import HfMultiLanguageText from '../../../../../../../shared/new-components/hf-multilanguage-text/hfMultilanguageText';
import EmptyState from 'root/app/shared/components/_migrated/empty_state/empty_state'; // Adjust path as needed


const ScoreFactorBox = ({ factor, scoreId, dateGrouping }) => {
    const [showQuestions, setShowQuestions] = useState(false);
    const [noData, setNoData] = useState(true);
    const [value, setValue] = useState(null);
    const [quality, setQuality] = useState('NO_DATA');
    const [valuationColor, setValuationColor] = useState('');
    const [variation, setVariation] = useState(null);
    const [previousResult, setPreviousResult] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [from, setFrom] = useState('');
    const [sparkChart, setSparkChart] = useState(null);
    const [variationText, setVariationText] = useState('');
    const [qualityText, setQualityText] = useState('');
    const [factorDetails, setFactorDetails] = useState(undefined);

    const $translate = getService('$translate');
    const ScoresV2Srvc = getService('ScoresV2Srvc');

    useEffect(() => {
        if (factor.results && factor.results.length > 0) {
            setNoData(false);
            const lastResult = factor.results.slice(-1)[0];
            setValue(Numbers.roundNumber(lastResult.result, 1));
            setQuality(lastResult.quality);
            setValuationColor(Valuation.valuationColor(lastResult.valuation));
            setVariation(Numbers.roundNumber(lastResult.variation, 1));

            const tmpQuestions = [];
            factor.results.forEach(factorResult => {
                factorResult.questions.forEach(questionResult => {
                    let question = tmpQuestions.find(q => q.id === questionResult.id);
                    if (!question) {
                        question = {
                            id: questionResult.id,
                            results: []
                        };
                        tmpQuestions.push(question);
                    }
                    question.results.push({
                        from: factorResult.from,
                        to: factorResult.to,
                        quality: questionResult.quality,
                        valuation: questionResult.valuation,
                        result: questionResult.result,
                        variation: questionResult.variation,
                        participants: questionResult.participants,
                        distribution: questionResult.distribution
                    });
                });
            });
            setQuestions(tmpQuestions);

            setFrom(moment(Dates.fromAPIFormat(lastResult.from, APISettings.apiDateFormat)).format('MMMM'));

            const chartResults = factor.results.map(result => [result.from, result.result]);
            setSparkChart(EngagementDetailCharts.factorSparkLineEvolution(chartResults, dateGrouping, 70));

            const previousResultIndex = factor.results.length - 2;
            const previousResultValue = previousResultIndex >= 0 ? Numbers.roundNumber(factor.results[previousResultIndex].result, 1) : 0

            setPreviousResult(previousResultValue);

            const keys = {
                variation: variation,
                previous: previousResultValue
            };

            if (variation > 0) {
                setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_POSITIVE', keys));
            } else if (variation < 0) {
                setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEGATIVE', keys));
            } else {
                setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEUTRAL', keys));
            }

            if (quality === 'NO_DATA') {
                setQualityText($translate.instant('NO_DATA'));
            } else if (quality === 'LOW') {
                setQualityText($translate.instant('NO_REPRESENTATIVE_SAMPLE', keys));
            } else {
                setQualityText($translate.instant('REPRESENTATIVE_SAMPLE', keys));
            }


        } else {
            setNoData(true);
        }

        ScoresV2Srvc.getScore(scoreId, (err, score) => {
            const foundFactor = _.find(score.factors, f => f.id === factor.id);
            setFactorDetails(foundFactor);
        });
    }, [factor, scoreId, dateGrouping, variation]);

    const qualityDesc = () => {
        switch (quality) {
            case 'NO_DATA':
                return $translate.instant('SCORE_NODATA_QUALITY_DESCRIPTION');
            case 'LOW':
                return $translate.instant('SCORE_LOW_QUALITY_DESCRIPTION');
            default:
                return $translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION');
        }
    };

    return (
        <div>
            <div className="row factors_box_row p-t p-b">
                <div className="col-sm-3 col-xs-6">
                    {quality !== 'NO_DATA' && value > 0 ? (
                        <div className="big-style factor-number" style={{ color: valuationColor }}>
                            {value} <span className="sub-index">pts</span>
                        </div>
                    ) : (
                        <div className="big-style factor-number" style={{ color: valuationColor }}>
                            {noData ? $translate.instant('SCORE_NO_DATA_SHORT') : ''}
                        </div>
                    )}
                    <div className="medium-style">
                        <strong>
                            <HfMultiLanguageText multilanguage={factorDetails?.name}/>
                        </strong>
                        <Tooltip title={ <HfMultiLanguageText multilanguage={factorDetails?.description}/>}>
                            <span className={'small-style'}>
                                <i className={'fa fa-info-circle m-l-xs text-success'}></i>
                            </span>

                        </Tooltip>
                    </div>
                    <p
                        className={quality === 'LOW' ? 'm-t-xs small-style underline' : ' m-t-xs small-style'}
                        dangerouslySetInnerHTML={{__html : qualityText}}
                    >
                    </p>
                </div>

                <div className={quality !== 'NO_DATA' ? 'col-sm-2 col-xs-6 border-left-right' : 'col-sm-2 col-xs-6'}>
                    <p className="factor_variation_description m-l-sm m-r-sm" dangerouslySetInnerHTML={{ __html: variationText }}></p>
                </div>

                <div className="col-sm-5 col-xs-12 text-center">
                    {quality === 'NO_DATA' &&  <EmptyState
                        image="images/no_scores.svg"
                        small={true}
                        message={$translate.instant('NO_DATA')}
                    ></EmptyState>}
                    {quality !== 'NO_DATA' && sparkChart && <HighchartsReact className="m-t-n-sm m-l-md score_chart"
                        highcharts={Highcharts}
                        options={sparkChart}/>}
                </div>

                <div className="col-sm-2 col-xs-6 text-center">
                    <a onClick={() => setShowQuestions(!showQuestions)}>
                        {$translate.instant(showQuestions ? 'ENGAGEMENT_FACTOR_HIDE_QUESTIONS' : 'ENGAGEMENT_FACTOR_SHOW_QUESTIONS')}
                    </a>
                </div>
            </div>
            <hr className="hfline" />

            {showQuestions && (
                <div className="row animated fadeIn">
                    {questions.map((question, index) => (
                        <div key={question.id} className="col-xs-12">
                            <div className="p-t p-b m-l-lg">
                                <ScoreQuestionRow
                                    scoreId={scoreId}
                                    factorId={factor.id}
                                    questionResults={question}
                                    dateGrouping={dateGrouping}
                                    numQuestions={questions.length}
                                    questionNum={index + 1}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

ScoreFactorBox.propTypes = {
    factor: PropTypes.object.isRequired,
    scoreId: PropTypes.string.isRequired,
    dateGrouping: PropTypes.string.isRequired,
};

angularize(ScoreFactorBox, 'hfScoreFactorBox', angular.module('happyForceApp'), {
    factor: '<',
    scoreId: '<',
    dateGrouping: '<'
});

export default ScoreFactorBox;
