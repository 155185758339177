'use strict';

var MultiLanguageTabPanelCtrl = function ($scope, LanguageSelectorService) {
    'ngInject'
    var _this = this;

    _this.activeLaguage = null;
    _this.canAddMore = true;

    _this.setSelectedLanguage = function (language) {
        _this.activeLaguage = language;
        if ($scope.onSelectLanguage) {
            $scope.onSelectLanguage(language);
        }
    };

    _this.addLanguage = function ($event) {
        $event.stopPropagation();
        LanguageSelectorService.showSelectLanguageModal($scope.currentLanguages, $scope.restrictToLanguageCodes, function (language) {
            if ($scope.onLanguageAdd) {
                $scope.onLanguageAdd(language);
            }
        });
    };

    _this.evalutateCanAddMore = function () {


        if (!$scope.restrictToLanguageCodes) {
            // If there are no restrictions, return true
            _this.canAddMore = true;
        } else if ($scope.restrictToLanguageCodes.length === 0) {
            // If the restrictionsArray is empty, that means that multilanguage shoudl be disabled
            _this.canAddMore = false;
        } else {
            // If all languages are already added, return false
            const allRestrictedAlreadyAded = _.every($scope.restrictToLanguageCodes, function (lang) {
                return $scope.currentLanguages.indexOf(lang) != -1; }
            );
            _this.canAddMore = !allRestrictedAlreadyAded;
        }
    };

    this.$onInit = function () {

        // Check if the panel must be displayed or not:
        _this.shouldShowPanel = !$scope.restrictToLanguageCodes || $scope.restrictToLanguageCodes.length > 0;

        // Monitor language changes of the question
        ['currentLanguages'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind($scope, function () {
                return $scope[key];
            }), function (n, o) {
                if (n != o) {
                    if (!$scope.currentLanguages || $scope.currentLanguages.indexOf(_this.activeLaguage) == -1) {
                        // The languages have changed, and the current one is not found, so swap:
                        _this.setSelectedLanguage(null);
                    }

                };
                _this.evalutateCanAddMore();
            });
        });
    };




};

const hfMultiLanguageTabPanel = angular.module('happyForceApp')
    .directive('hfMultiLanguageTabPanel', function () {
        return {
            scope: {
                onSelectLanguage: '<',
                onLanguageAdd: '<',
                currentLanguages: '<',
                restrictToLanguageCodes: '<'
            },
            template: require('./multi-language-tab-panel.html'),
            controller: MultiLanguageTabPanelCtrl,
            controllerAs: '$ctrl',
            transclude: true
        };
    });

export default hfMultiLanguageTabPanel;
