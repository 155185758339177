
'use strict';

function HFInlineWizardCtrl($scope) {
    'ngInject';

    var _this = this;


    $scope.items = [];
    $scope.currentItem = 0;
    $scope.validationError = null;


    // pass save to outside controller
    $scope.save = function () {
        if ($scope.onSave) $scope.onSave();
    };

    $scope.saveSecondary = function () {
        if ($scope.onSaveSecondary) $scope.onSaveSecondary();
    };

    $scope.cancel = function () {
        if ($scope.onCancel) $scope.onCancel();
    };

    var setCurrentPage = function (index) {
        $scope.currentItem = index;
        $scope.items.forEach(function (item) {
            item.page.active = (item.index === index);
        });
    };

    // **************************************
    // controller interface
    //
    this.addPage = function (page) {
        page.active = ($scope.items.length === 0);
        $scope.items.push({
            index: $scope.items.length,
            page: page
        });
    };

    // **************************************
    // $scope interface
    //
    $scope.goToPage = function (index) {
        // Not allow to change if is not valid
        if ($scope.items[$scope.currentItem].page.valid) {
            setCurrentPage(index);
        }
    };

    $scope.nextPage = function () {
        $scope.goToPage($scope.currentItem + 1);
    };

    $scope.prevPage = function () {
        $scope.goToPage($scope.currentItem - 1);
    };

    $scope.hasNext = function () {
        return ($scope.currentItem < $scope.items.length - 1);
    };
    $scope.hasPrev = function () {
        return $scope.currentItem > 0;
    };

    $scope.isPageValid = function () {
        return $scope.items[$scope.currentItem].page.valid;
    };

    var pageArgs = ['onCancel', 'onSave', 'onSaveSecondary'];
    var lastExecution;
    pageArgs.forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind(_this, function () {
            return _this[key];
        }), function (newVal, oldValue) {

            $scope.hasSave = ($scope.onSave) ? true : false;
            $scope.hasCancel = ($scope.onCancel) ? true : false;
            $scope.hasSaveSecondary = ($scope.onSaveSecondary) ? true : false;
        });
    });


}

angular.module('happyForceApp')
    .controller('HFInlineWizardCtrl', HFInlineWizardCtrl);


const hfInlineWizard = angular.module('happyForceApp')
    .directive('hfInlineWizard', function () {

        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: require('./inline-wizard.html'),

            scope: {
                saveText: '@',
                saveSecondaryText: '@',
                cancelText: '@',
                nextText: '@',
                previousText: '@',
                onCancel: '=',
                onSave: '=',
                onSaveSecondary: '=',
                hideSecondary: '<',
                saving: '='
            },
            controller: HFInlineWizardCtrl
        }; // end return
    })
    .directive('hfInlineWizardPage', function () {

        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div ng-show="active" ng-transclude></div>',
            scope: {
                stepTitle: '@',
                active: '@',
                valid: '='
            },

            require: '^hfInlineWizard',
            link: function (page, element, attr, inlineWizard) {
                inlineWizard.addPage(page);
            }
        }; // end return

    });

export default hfInlineWizard;
