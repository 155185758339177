import {getService} from '../../../../../../../migration_utils/react-in-angular';
import React, {useEffect, useMemo, useState} from 'react';
import {Box, Skeleton, Tooltip, Typography} from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {BulkActionPerson, BulkActionType, CellProps, PersonCharacteristic} from '../../types';
import {useGroupNames} from '../../../../../../shared/hooks/useCharacteristicValueName';
import {CharacteristicValueName, useCharacteristicNames} from '../../../../../../shared/hooks/useCharacteristicNames';
import HFInfoIconWithTooltip from '../../../../../../shared/new-components/hf-info-icon-with-tooltip';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';


const SegmentsCell = ({person, bulkActionType} : CellProps) => {

    const $translate = getService('$translate');

    const Characteristics = getService('Characteristics');

    const ErrorSvrc = getService('ErrorSvrc');

    const [characteristics, setCharacteristics] = useState([]);

    const [profileCompleted, setProfileCompleted] = React.useState(false);

    const [originalPerson, setOriginalPerson] = React.useState<any>();


    const [hasDifference, setHasDifference] = React.useState<boolean>(false);


    const memoizedPersonCharacteristics = useMemo(() => {
        return person?.characteristics ? person?.characteristics.map(({ characteristicId, value, date }) => ({ characteristicId, value, date })) : [];
    }, [characteristics]);

    const personCharacteristicNames = useCharacteristicNames(memoizedPersonCharacteristics);

    const memoizedOriginalPersonCharacteristics = useMemo(() => {
        return originalPerson?.characteristics ? originalPerson?.characteristics.map(({ characteristicId, value, date }) => ({ characteristicId, value, date })) : [];
    }, [characteristics]);

    const personOriginalCharacteristicNames = useCharacteristicNames(memoizedOriginalPersonCharacteristics);


    // Función de comparación para ordenar las características
    const compareCharacteristics = (a: PersonCharacteristic, b: PersonCharacteristic) => {
        if (a.characteristicId < b.characteristicId) return -1;
        if (a.characteristicId > b.characteristicId) return 1;
        return 0;
    }

    // Función de comparación para los valores de las características
    const compareCharacteristicValues = (a: PersonCharacteristic, b: PersonCharacteristic) => {
        if (a.characteristicId !== b.characteristicId || a.questionType !== b.questionType) return false;
        if (a.questionType === 'DATE' && a.date !== b.date) return false;
        if (a.questionType === 'LIST' && a.value !== b.value) return false;
        return true;
    }


    useEffect(() => {
        Characteristics.getAll(function (err, data) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            } else {
                setCharacteristics(data);
            }
        });
    }, []);

    useEffect(() => {
        setOriginalPerson((person as BulkActionPerson).originalPerson);
    }, [person]);



    useEffect(() => {

        const originalPersonCharacteristics = originalPerson?.characteristics?.slice().sort(compareCharacteristics) || [];
        const personCharacteristics = person?.characteristics?.slice().sort(compareCharacteristics) || [];

        if (originalPersonCharacteristics.length !== personCharacteristics.length) {
            setHasDifference(true);
            return;
        }

        const hasCharacteristicsDifference = !originalPersonCharacteristics.every((value, index) => compareCharacteristicValues(value, personCharacteristics[index]));

        setHasDifference(hasCharacteristicsDifference);


    }, [originalPerson, person]);



    useEffect(() => {
        setProfileCompleted( person?.characteristics?.length === characteristics?.length);

    }, [characteristics, person.characteristics]);

    if (!person.id  && bulkActionType === undefined) {
        return (
            <Skeleton animation={false} variant="rounded" width={'80%'} height={20} />
        )
    }




    const renderTooltipContent = (valueNames: CharacteristicValueName[], isOriginalRecord: boolean ) => {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                {isOriginalRecord &&
                    <Typography variant={'caption'}>{ $translate.instant('BULK_ACTION_ORIGINAL_RECORD', {value: '' })}</Typography>}

                {valueNames && valueNames.length > 0 && valueNames.map((value, index) => {
                    return (
                        <Typography key={index} variant={'caption'}>{value.description + ': ' + value.valueLabel}</Typography>
                    )
                })}

                {valueNames && valueNames.length === 0 && <Typography variant={'caption'}>{$translate.instant('NO_SEGMENTS_CHARACTERISTICS_TITLE')}</Typography>}
            </Box>
        )
    }

    const renderOriginalPersonSegmentsTooltip = () => {

        if (originalPerson
            && bulkActionType === BulkActionType.UPDATE
            && hasDifference
        ) {

            return (
                <HFInfoIconWithTooltip
                    tooltipContent={renderTooltipContent(personOriginalCharacteristicNames, true)}
                    color={'primary'}
                    iconOverride={<SwapHorizIcon/>}/>
            );
        } else {
            return null;
        }

    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
                width: '100%'
            }}>
            <Tooltip title={renderTooltipContent(personCharacteristicNames, false)}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '100%',
                }}>

                    <TripOriginIcon color={profileCompleted ? 'success' : 'warning'} />
                    <Typography
                        sx={(theme) => ({marginLeft: theme.spacing(1)})} variant={'caption'}>
                        {profileCompleted ? $translate.instant('PROFILE_COMPLETE') : $translate.instant('PROFILE_INCOMPLETE')}
                    </Typography>
                </Box>
            </Tooltip>
            {renderOriginalPersonSegmentsTooltip()}
        </Box>
    )
}

export default SegmentsCell;
