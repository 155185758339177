'use strict';


import Scope from "../../utilities/scope.utilities";


import template from './login.html';


function LoginCtrl($scope, $location, AuthSrvc, ErrorSvrc) {
    'ngInject';
    this.$onInit = function () {
        $scope.user = AuthSrvc.user;
        var token = $location.search().token;

        // false
        Scope.initialize($scope, ['isLogin', 'isForgot', 'completed', 'executing', 'accepted', 'errorLogin'], false);

        // true
        Scope.initialize($scope, ['rememberme'], true);

        // ''
        Scope.initialize($scope, ['failedReason', 'errorReason', 'email', 'password'], '');

        /**
         * is()
         * @param  {String}
         * @return {Boolean}
         */
        $scope.is = function (name) {
            return ($location.path() === '/' + name);
        };

        if (typeof $scope.user != 'undefined' && $scope.user.email !== '') {
            $scope.email = $scope.user.email;
        }

        $scope.current_year = new Date().getFullYear();

        /**
         * forget()
         * @return {[type]}
         */
        $scope.forget = function (email) {
            $scope.executing = true;
            $scope.email = email;
            AuthSrvc.forget({
                    email: email
                },
                function (res) {
                    $scope.executing = false;
                    $scope.completed = true;
                    $scope.failed = false;
                },

                function (err) {
                    $scope.executing = false;
                    $scope.completed = true;
                    $scope.failed = true;
                    $scope.failedReason = ErrorSvrc.getErrorMessage(err);
                });
        };

        /**
         * require()
         * @return {[type]}
         */
        $scope.require = function () {
            $scope.executing = true;
        };

        $scope.isUserCached = function () {
            if (typeof $scope.user != 'undefined' && $scope.user.email != '') {
                return true;
            } else {
                return false;
            }
        };

        $scope.goLogin = function () {
            $location.path('/onboarding');
        };

        $scope.resetForgot = function () {
            $scope.completed = false;
            $scope.failed = false;
        };

        $scope.resetErrors = function () {
            $scope.failed = false;
            $scope.failedReason = '';
        };

    };
}

function LoginView($stateProvider) {
    'ngInject';
    $stateProvider
        .state('forgot', {
            url: '/forgot',
            template: template,
            controller: LoginCtrl,
            controllerAs: 'lc',
            data: { pageTitle: 'FORGOT_PASSWORD', bodyClasses: 'login gray-bg' }
        })
        .state('require', {
            url: '/require',
            template: template,
            controller: LoginCtrl,
            controllerAs: 'lc',
            data: { pageTitle: 'REQUIRE_TEST', bodyClasses: 'login gray-bg' }
        });
}

export default LoginView;
