function FeedbackStatusTypeConfigOperationsCtrl($scope, ErrorSvrc, $modalInstance, statusType, isEdit, phaseTypes, Analytics, FeedbackStatusService) {
    'ngInject'

    $scope.statusType = statusType;
    $scope.isEdit = isEdit;
    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';
    $scope.phaseTypes = phaseTypes;
    $scope.hideSecondary = true;

    $scope.ok = function () {
        $scope.executing = true;

        if ($scope.isEdit) {
            FeedbackStatusService.update($scope.statusType.id, $scope.statusType, function (err, result) {
                if (err) {
                    Analytics.trackEvent('settings_feedback_status_type', 'update', 'fail');
                    return $scope.handleErrorResponse(err);
                } else {
                    Analytics.trackEvent('settings_feedback_status_type', 'update', 'success');
                    $modalInstance.close(result);
                }
            });
        } else {
            FeedbackStatusService.create($scope.statusType, function (err, result) {
                if (err) {
                    Analytics.trackEvent('settings_feedback_status_type', 'add', 'fail');
                    return $scope.handleErrorResponse(err);
                } else {
                    Analytics.trackEvent('settings_feedback_status_type', 'add', 'success');
                    $modalInstance.close(result);
                }
            });
        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.handleErrorResponse = function (response) {
        $scope.executing = false;
        $scope.errored = true;
        $scope.errorMsg = ErrorSvrc.getErrorMessage(response);
    };
}


const feedbackStatusTypeConfigModal = angular.module('happyForceApp')
    .factory('FeedbackStatusTypeConfigModal',
        function ($uibModal) {
            var methods = {};
            methods.openModal = function (phaseTypes, statusType, isEdit, callback) {
                $uibModal.open({
                    backdrop: true,
                    animation: true,
                    backdropClass: 'right',
                    template: require('./feedback_status_type.modal.html'),
                    controller: FeedbackStatusTypeConfigOperationsCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        phaseTypes: function () { return phaseTypes;},
                        statusType: function () { return statusType;},
                        isEdit: function () { return isEdit;}
                    }
                }).result.then(function (value) {
                    if (callback) {
                        callback(true, value);
                    }
                }, function () {
                    if (callback) {
                        callback(false);
                    }
                });
            };
            return methods;
        }
    );

export default feedbackStatusTypeConfigModal