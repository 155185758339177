'use strict';

const hfWizardIntro = angular.module('happyForceApp')
    .directive('hfWizardIntro', function () {
        return {
            scope: {
                options: '='
            },
            transclude: true,
            replace: true,
            controllerAs: '$intro',
            bindToController: true,
            require: '^hfWizard',
            template: function (element, attributes) {
                return attributes.template || require('./intro.html');
            },

            controller: function ($translate) {
                'ngInject'
                var _this = this;

                if (!_this.options) return;

                _this.goToWizard = function () {
                    angular.element('.modal-' + _this.name).removeClass('showIntro');
                };

                Object.keys(_this.options).forEach(function (key) {
                    _this[key] = _this.options[key];
                });

            }
        };
    });

export default hfWizardIntro;
