'use strict';

function HRSIBambooCtrl($scope, ErrorSvrc, HRSIService, $uibModal, $uibModalInstance) {

    $scope.executing = false;

    //TODO: Why whe have to do this?
    $scope.HRSIService = HRSIService;
    $scope.ErrorSvrc = ErrorSvrc;

    $scope.cancel = function () {
        $uibModalInstance.dismiss('close');
    };

    $scope.ok = function () {
        $scope.executing = true;
        delete $scope.errorMsg;
        var params = {
            subdomain: $scope.domain,
            userName: $scope.apiKey,
            type: 'BAMBOOHR'
        };
        $scope.HRSIService.connect(params, function (err, result) {
            $scope.executing = false;
            if (err) {
                $scope.errorMsg = ErrorSvrc.getErrorMessage(err);
            } else {
                $uibModalInstance.close();
            }
        });
    };

}

const HRISBambooModal = angular.module('happyForceApp')
    .factory('HRISBambooModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showModal = function (callback) {
                $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    template: require('./hrsi_bamboo.html'),
                    controller: HRSIBambooCtrl
                }).result.then(function () {
                    callback();
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;
        }
    );

export default HRISBambooModal;
