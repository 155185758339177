'use strict';

import Dates from "../../../utilities/date.utilities";
import Numbers from "../../../utilities/number.utilities";
import hfEngagementFollowupBox from "./components/followup_box/followup_box.component";

import HiPositioningBox from "./components/hi_positioning_box/hi_positioning_box.component";
import template from './hi_detail.html';
import {getHierarchyName} from "../../../shared/services/migrated/hierarchy.service";
import HIFunnelBox from "./components/hi_funnel_box/hi_funnel_box_component";
import EngagementHiIndividualHeatmapBox from "./components/hi_individual_heatmap/hi_individual_heatmap_component";
import hiReportModal from "./components/hi_report_modal/hi_report_modal_component";
import HiDetailOverview from "./components/overview/hi_overview_component";
import EngagementDetailEvolutionChart from '../shared/evolution_chart/engagement_evolution_chart';
import {dateQueryParamToDate} from '../../../utilities/navigation_state.utilities';


function HiDetailCtrl(
  $scope,
  Filters,
  $stateParams,
  HIService,
  ErrorSvrc,
  $translate,
  $state,
  Settings,
  Reports,
  Analytics,
) {
    'ngInject'
    var _this = this;


    _this.minValue = 0;
    _this.maxValue = 100;
    _this.scoreType = 'HI';
    _this.status = 'ACTIVATED';
    _this.stats = {};
    _this.summaryView = 1;
    _this.dateGrouping = 'MONTH';
    _this.showExportModal = false;

    _this.arrow = {
        action: function () {
            $state.go('engagement.screen');
        }
    };

    _this.dateFormat = Settings.defaultMonthDateFormat;


    _this.to = $stateParams.to ? dateQueryParamToDate($stateParams.to) : Filters.endDate();

    _this.level = Filters.level() || null;
    _this.filters = Filters.segments() || null;
    _this.groups = Filters.groups() || null;

    if ($stateParams.hierarchyId) {
        _this.level = {
            id: $stateParams.hierarchyId
        };

        // TODO: We have to do this, because the hierarchies filter doesn't display the name properly...
        getHierarchyName(
            $stateParams.hierarchyId,
            function (err, hierarchyName) {
                if (!err) {
                    _this.level.name = hierarchyName;
                }
            }
        );
    }

    if ($stateParams.filters) {
        _this.filters = $stateParams.filters;
    }

    if ($stateParams.groupId) {
        _this.groups = [$stateParams.groupId];
    }

    _this.arrow = {
        action: function () {
            $state.go('engagement.screen');
        }
    };

    _this.getParams = function () {

        var to = Dates.lastDayOfMonth(_this.to);
        var from = Dates.firstDayOfMonth(_this.to);


        var params = {
            from: from,
            to: to,
            filters: _this.filters,
            groupId: _this.groups
        };

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        return params;
    };

    _this.processVotes = function (currentVotes) {
        var votes = Object.values(currentVotes);
        _this.results = votes.map(function (vote) {
            var date = moment(vote.date);
            var dateString = date.format('DDMMYYYY');
            return {
                from: dateString,
                to: dateString,
                result: vote.indexRating,
                companyBenchmark: _this.stats.companyBenchmark,
                industryBenchmark: _this.stats.industryBenchmark,
                globalBenchmark: _this.stats.globalBenchmark,
                ranking: _this.stats.ranking
            };
        });
    };

    _this.loadHIResults = function (params) {
        HIService.stats.get(params, function (error, response) {
            if (error) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(error)) {
                    _this.hasEnoughActiveEmployees = false;
                    _this.stats = {};
                    _this.results = [];
                    _this.factors = [];
                } else {
                    Rollbar.error('Error getting engagement hi detail stats', error);
                }
                _this.loading = false;
                return;
            }

            _this.hasEnoughActiveEmployees = true;
            if (!response.results || response.results.length == 0) {
                _this.loading = false;
                return;
            }

            _this.filtered = response.filtered;

            var numResults = response.results.length;
            var globalBenchmark = response.globalBenchmark;
            var industryBenchmark = response.industryBenchmark;
            var companyBenchmark = response.companyBenchmark;
            var ranking = response.ranking;
            var current = response.results[numResults - 1];
            var value = current.periodIndex;
            var variation = current.periodIndexVariation;
            var stats = Object.assign({}, _this.stats);
            Object.assign(stats, {
                quality: current.quality,
                valuation: current.valuation,
                value: Numbers.roundNumber(value, _this.numDecimals),
                variation: Numbers.roundNumber(variation, 1),
                measureDate: current.from,
                globalBenchmark: globalBenchmark,
                industryBenchmark: industryBenchmark,
                companyBenchmark: companyBenchmark,
                ranking: ranking
            });

            if (numResults > 1) {
                var previousValue = response.results[numResults - 2].periodIndex;
                stats.previousValue = Numbers.roundNumber(
                    previousValue,
                    _this.numDecimals
                );
            }

            _this.stats = stats;
            _this.processVotes(current.votes);
            _this.loading = false;
        });
    };

    _this.loadHIStats = function () {
        _this.loading = true;
        var params = _this.getParams();
        _this.loadHIResults(params);
    };

    var loadData = function () {
        _this.from = Dates.firstDayOfMonth(_this.to);
        _this.loadHIStats();
    };

    _this.textForBenchmark = function (value, benchmark, key) {
        var difference = value - benchmark;
        var formattedDiff = Numbers.roundNumber(difference, _this.numDecimals);

        var keys = { benchmark: benchmark, difference: Math.abs(formattedDiff) };
        if (difference == 0) {
            return $translate.instant(key + '_NEUTRAL', keys);
        } else if (difference > 0) {
            return $translate.instant(key + '_POSITIVE', keys);
        } else {
            return $translate.instant(key + '_NEGATIVE', keys);
        }
    };

    _this.navigateToDetail = function (route, params) {
        $state.go(route, params);
    };


    _this.onShowExportModal = function () {
        _this.reportTo = Dates.lastDayOfMonth(_this.to);
        _this.reportFrom = Dates.firstDayOfMonth(_this.to);
        _this.showExportModal = true;
    };

    _this.onExportModalClose = (from, to) => {

        _this.showExportModal = false;
        if (from && to) {
            var params = _this.getParams();

            // Override the report dates with the modal ones:
            params.from = from;
            params.to = to;

            _this.exportingExcelReport = true;
            Reports.hi(params, function () {
                // We are quering without question and for one hierarchy,
                // so we expect only one node, with one hie, that is for the primary question
                _this.exportingExcelReport = false;
                swal($translate.instant('HI_EXPORT_SENT'), '', 'success');
                Analytics.trackEvent('hi_details', 'excel_report', 'success');

            }, function errorCallback(response) {
                ErrorSvrc.showErrorModal(response);
                _this.exportingExcelReport = false;
                Analytics.trackEvent('hi_details', 'excel_report', 'failure');
            });
        }
    };


    this.$onInit = function () {


        loadData();

        ['to', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(
                angular.bind(_this, function () {
                    return _this[key];
                }),
                function (n, o) {
                    if (n == o) {
                        return;
                    }
                    loadData();
                },
                true
            );
        });
    };
}


function HIDetailView($stateProvider) {
    'ngInject';
    $stateProvider.state('engagement.hiDetail', {
        url: '/detail/hi?hierarchyId&filters&groupId&to',
        template: template,
        controllerAs: '$ctrl',
        controller: HiDetailCtrl,
        data: {
            pageTitle: 'ENGAGEMENT_HI_DETAIL',
            roles: ['MANAGER', 'ADMIN', 'ADVISOR']
        }
    });
}

export default HIDetailView;
