'use strict';
import hfSurveyActionMenu from "./components/survey_action_menu/survey_action_menu.component";
import hfSurveyActiveBox from "./components/survey_active_box/survey_active_box.component";
import hfSurveyAudienceBadge from "./components/survey_audience_badge/survey_audience_badge";
import hfSurveyRow from "./components/survey_row/survey_row.controller";
import template from "./surveys.html";


function SurveysCtrl($scope, ErrorSvrc, Surveys, $intercom) {
    'ngInject';
    var _this = this;

    _this.currentSurveysPage = 1;

    /* Init survey status object */
    _this.surveysByStatus =  {};
    ['NOW', 'EXPIRED', 'FUTURE', 'PAUSED', 'ARCHIVED', 'DRAFT'].forEach(function (status) {

        _this.surveysByStatus[status] =  {
            from: 1,
            to: (status === 'NOW' ? 3 : 5),
            pePage: (status === 'NOW' ? 3 : 5),
            surveys: [],
            total: 0,
            loading: true
        }
    });


    _this.status = 'EXPIRED';


    _this.reloadSurvey = function () {
        // Right now, just reload the whole page
        _this.loadSurveysByStatus(null);
    }


    _this.loadSurveysByStatus = function (status) {

        if (!status) {
            ['NOW', 'EXPIRED', 'FUTURE', 'PAUSED', 'ARCHIVED', 'DRAFT'].forEach(function (status) {
                _this.loadSurveysByStatus(status);
            });
        } else  {
            _this.surveysByStatus[status].loading = true;
            var params = {};

            params.status = status;

            params.from = (_this.surveysByStatus[status].from - 1) * _this.surveysByStatus[status].pePage;
            params.to = params.from + _this.surveysByStatus[status].pePage;

            Surveys.list(params, function (err, results) {
                _this.surveysByStatus[status].loading = false;
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    _this.surveysByStatus[status].surveys = results.result;
                    _this.surveysByStatus[status].total = results.total;
                }
            });
        }
    };



    this.$onInit = function () {

        Object.keys(_this.status).forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this.status[key];
            }), function (n, o) {
                if (n == o) return;
                _this.loadSurveysByStatus(_this.status);
            });
        });


        // DO an initial load

        _this.loadSurveysByStatus(null);

        $intercom.trackEvent('dashboard_visited_surveys');
    };
}


const SurveysView =  function ($stateProvider) {
    'ngInject';
    $stateProvider.state('surveys.list', {
        url: '/',
        template: template,
        controller: SurveysCtrl,
        controllerAs: '$ctrl',
        data: { pageTitle: 'SIDEMENU_SURVEYS', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
};

export default SurveysView;


