const SurveyAdvisorsService = function (Restangular) {
    'ngInject';
    var SurveyAdvisors = Restangular.one('company').one('advisor').one('surveys');

    var methods = {};

    methods.create = function (template, callback) {
        SurveyAdvisors.customPOST(template, 'templates').then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.update = function (templateId, template, callback) {
        SurveyAdvisors.one('templates').customPUT(template, templateId).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };



    methods.delete = function (templateId, callback) {
        SurveyAdvisors.one('templates').one(templateId).remove().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.get = function (templateId, callback) {

        SurveyAdvisors.one('templates').one(templateId).get().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };


    methods.list = function (callback) {
        SurveyAdvisors.one('templates').get().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };



    // Categories


    methods.createCategory = function (category, callback) {
        SurveyAdvisors.customPOST(category, 'categories').then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.updateCategory = function (categoryId, category, callback) {
        SurveyAdvisors.one('categories').customPUT(category, categoryId).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.getCategory = function (categoryId, callback) {

        SurveyAdvisors.one(categoryId).get().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };


    methods.listCategories = function (callback) {
        SurveyAdvisors.one('categories').get().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.deleteCategory = function (id, callback) {
        SurveyAdvisors.one('categories').one(id).remove().then(function (data) {
            return callback(null, data);
        }, function (err) {
            callback(err);
        });
    };



    return methods;
};
export default SurveyAdvisorsService;
