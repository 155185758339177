'use strict';



import Dates from "../../utilities/date.utilities";




// Import all the components:
import hfEngagementAnalyzeBox from "./components/analyze_box/analyze_box.component";

import hfEngagementScoresFeedbackBox from "./components/feedback_box/feedback_box.component";

import hfImpactMark from "./components/impact_mark/impact_mark.component";

import hfEngagementPrioritiesBox from "./components/priorities_box/priorities_box.component";
import hfEngagementRankingBox from "./shared/ranking_box/ranking_box.controller";
import hfEngagementScoresImpactChart from "./shared/scores_impact_chart/scores_impact_chart.component";

import votesChart from "./shared/votes_chart/votes_chart.component";
import hfEngagementDateBrowser from "./shared/date_browser/date_browser.component";
import EngagementScoresSummaryBox from './components/scores_summary_box/scores_sumary_box';


import template from './engagement.html';
import EngagementReportModal from './components/engagement_report_type_modal/engagement_report_type_modal.component';
import EngagementENPSBox from './components/enps_box/enps_box';
import EngagementHIBox from './components/hi_box/hi_box';
import MeasuringNowBox from './shared/measuring_now_box/measuring_now_box';
import EngagementDetailEvolutionChart from './shared/evolution_chart/engagement_evolution_chart';
import {dateToQueryParam} from '../../utilities/navigation_state.utilities';
import EngagementHeatmap from '../../components/engagement/components/engagement_heatmap/engagement_heatmap';
import HFCircleDot from "../../shared/new-components/hf-circle-dot/hf-circle-dot";


function EngagementCtrl($scope, Filters, Settings, $translate, CompanySvrc, LoadingService, $intercom, $uibModal, Reports, toaster, Analytics, ErrorSvrc, $state, EngagementReportModal) {
    'ngInject';
    var _this = this;

    _this.to = Filters.endDate();
    _this.summaryView = 2;
    $scope.today = moment();

    _this.loading = true;
    _this.grouping = 'MONTH';


    CompanySvrc.getUserCompany(function (err, company) {
        if (!err) {
            $scope.minDate = moment(company.createdOn);
        }
    });

    _this.triggerResize = function () {
        $(window).trigger('resize');
        if (typeof Event === 'function') {
            window.dispatchEvent(new Event('resize'));
        } else {
            var evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    };

    _this.exportToExcel = function () {
        _this.exportingToExel = true;
        EngagementReportModal.showModal(_this.to, function () {
            toaster.pop('success', null, ($translate.instant('ENGAGEMENT_EXPORT_SENT')));
            Analytics.trackEvent('engagement', 'excel_report', 'success');
        });
    };

    _this.onInsightDetailClick = function (scoreId, hierarchyId, filters) {
        $state.go('engagement.scoreDetail', {
            scoreId: scoreId,
            hierarchyId: hierarchyId,
            filters: filters,
            to: dateToQueryParam(_this.to)
        });
    }


    this.$onInit = function () {

        $scope.$watch(function () {
            return LoadingService.isLoading();
        }, function (n, o) {
            _this.loading = LoadingService.isLoading();
            if (!_this.loading) {
                setTimeout(function () {
                    _this.triggerResize();
                }, 1000);
            }
        });

        $intercom.trackEvent('dashboard_visited_engagement');
    };
};


const EngagementView = function($stateProvider) {
    'ngInject';
    $stateProvider.state('engagement.screen', {
        url: '/',
        controller: EngagementCtrl,
        controllerAs: '$engagement',
        template: template,
        data: { pageTitle: 'SIDEMENU_ENGAGEMENT', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
};

export default EngagementView;


