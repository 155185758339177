import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import { Menu, Box, Button, Typography } from '@mui/material';
import HierarchyNestedMenuItem from './components/HierarchyNestedMenuItem';
import GroupNestedMenuItem from './components/GroupNestedMenuItem';
import SegmentNestedMenuItem from './components/SegmentNestedMenuItem';
import OptionNestedMenuItem from './components/OptionNestedMenuItem';
import DateNestedMenuItem from './components/DateNestedMenuItem';
import HFTag from '../hf-tag/hf-tag';
import angular from 'angular';
import FilterIcon from '@mui/icons-material/FilterAlt';

interface HFFilterMenuProps {
    hierarchyFiltersConfig?: any;
    groupFiltersConfig?: any;
    segmentFiltersConfig?: any;
    dateFiltersConfig?: any;
    optionFiltersConfig?: any;
    tagColor?: string;
    selectedFilters: any;
    selectedTags: any;
    updateFilters: (filters: any) => void;
}

const HfFilterMenu = ({ hierarchyFiltersConfig, groupFiltersConfig,
    segmentFiltersConfig, dateFiltersConfig, optionFiltersConfig,
    tagColor, selectedFilters, selectedTags, updateFilters }: HFFilterMenuProps) => {
    const [filters, setFilters] = useState(selectedFilters);
    const [tagFilters, setTagFilters] = useState(selectedTags);
    const [hierarchyInitialized, setHierarchyInitialized] = useState(false);
    const [hierarchyFilters, setHierarchyFilters] = useState([]);
    const [groupInitialized, setGroupInitialized] = useState(false);
    const [groupFilters, setGroupFilters] = useState([]);
    const [segmentInitialized, setSegmentInitialized] = useState(false);
    const [segmentFilters, setSegmentFilters] = useState([]);
    const [optionInitialized, setOptionInitialized] = useState(false);
    const [optionFilters, setOptionFilters] = useState([]);
    const [dateInitialized, setDateInitialized] = useState(false);
    const [dateFilters, setDateFilters] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const $translate = getService('$translate');
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (selectedTags && selectedTags.find(f => f.type === 'HIERARCHY') && hierarchyInitialized) {
            const initialHierarchyFilters = selectedTags.find(f => f.type === 'HIERARCHY');
            setHierarchyFilters(initialHierarchyFilters.selected);
        }

        if (selectedTags && selectedTags.find(f => f.type === 'GROUP') && groupInitialized) {
            const initialGroupFilters = selectedTags.find(f => f.type === 'GROUP');
            setGroupFilters(initialGroupFilters.selected);
        }

        if (selectedTags && selectedTags.find(f => f.type === 'SEGMENT') && segmentInitialized) {
            const initialSegmentFilters = selectedTags.find(f => f.type === 'SEGMENT');
            setSegmentFilters(initialSegmentFilters.selected);
        }

        if (selectedTags && selectedTags.find(f => f.type === 'OPTION') && optionInitialized) {
            const initialOptionFilters = selectedTags.find(f => f.type === 'OPTION');
            setOptionFilters(initialOptionFilters.selected);
        }

        if (selectedTags && selectedTags.find(f => f.type === 'DATE') && dateInitialized) {
            const initialDateFilters = selectedTags.find(f => f.type === 'DATE');
            setDateFilters(initialDateFilters.selected);
        }
    }, [selectedTags, hierarchyInitialized, groupInitialized, segmentInitialized, optionInitialized, dateInitialized]);

    useEffect(() => {
        if (filters) {
            updateFilters(filters);
        }
    }, [filters]);

    //DONE
    useEffect(() => {
        if (!hierarchyInitialized) {
            setHierarchyInitialized(true);
            return;
        }

        setTagFilters((prevFilters) => {
            const otherFilters = prevFilters.filter(f => f.type !== 'HIERARCHY');
            return [...otherFilters, { type: 'HIERARCHY', selected: hierarchyFilters }];
        });

        const newFilters = { ...filters };
        newFilters['hierarchyIds'] = [];
        hierarchyFilters.forEach(item => {
            newFilters['hierarchyIds'].push(item.id);
        });

        setFilters(newFilters);
    }, [hierarchyFilters]);

    //DONE
    useEffect(() => {
        if (!groupInitialized) {
            setGroupInitialized(true);
            return;
        }

        setTagFilters((prevFilters) => {
            const otherFilters = prevFilters.filter(f => f.type !== 'GROUP');
            return [...otherFilters, { type: 'GROUP', selected: groupFilters }];
        });

        const newFilters = { ...filters };
        newFilters['groups'] = [];
        groupFilters.forEach(item => {
            newFilters['groups'].push(item.id);
        });

        setFilters(newFilters);
    }, [groupFilters]);

    //DONE
    useEffect(() => {
        if (!segmentInitialized) {
            setSegmentInitialized(true);
            return;
        }

        setTagFilters((prevFilters) => {
            const otherFilters = prevFilters.filter(f => f.type !== 'SEGMENT');
            return [...otherFilters, { type: 'SEGMENT', selected: segmentFilters }];
        });

        const segmentFiltersData = transformSegmentFilters(segmentFilters);
        const newFilters = { ...filters };
        newFilters['segments'] = segmentFiltersData.filters;

        setFilters(newFilters);
    }, [segmentFilters]);

    //DONE
    const transformSegmentFilters = (inputArray) => {
        // Group by characteristicId
        const groupedData = inputArray.reduce((acc, item) => {
            const charId = item.characteristicId;
            if (!acc[charId]) {
                acc[charId] = [];
            }
            acc[charId].push(item.id);
            return acc;
        }, {});

        // Build output object
        const output = {
            type: 'segments',
            filters: Object.keys(groupedData).map((charId) => ({
                id: charId,
                values: groupedData[charId],
            })),
        };

        return output;
    };

    //DONE
    useEffect(() => {
        if (!optionInitialized) {
            setOptionInitialized(true);
            return;
        }

        setTagFilters((prevFilters) => {
            const otherFilters = prevFilters.filter(f => f.type !== 'OPTION');
            return [...otherFilters, { type: 'OPTION', selected: optionFilters }];
        });

        const newFilters = { ...filters };

        optionFiltersConfig.filters.forEach(filter => {
            delete newFilters[filter.options[0].type];
        });

        optionFilters.forEach(item => {
            if (newFilters[item.type] && newFilters[item.type].length > 0 && item.exclusive === false) {
                newFilters[item.type].push(item.id);
            } else {
                newFilters[item.type] = [item.id];
            }
        });

        setFilters(newFilters);
    }, [optionFilters]);

    //DONE
    useEffect(() => {
        if (!dateInitialized) {
            setDateInitialized(true);
            return;
        }

        setTagFilters((prevFilters) => {
            const otherFilters = prevFilters.filter(f => f.type !== 'DATE');
            return [...otherFilters, { type: 'DATE', selected: dateFilters }];
        });

        const newFilters = { ...filters };

        dateFiltersConfig.filters.forEach(filter => {
            delete newFilters[filter.id];
        });

        dateFilters.forEach(item => {
            newFilters[item.id] = item.date;
        });

        setFilters(newFilters);
    }, [dateFilters]);

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleRemove = (item, type) => {
        switch (type) {
            case 'HIERARCHY':
                setHierarchyFilters((hierarchyFilters) => hierarchyFilters.filter(d => !(d.type === item.type && d.id === item.id)));
                break;
            case 'GROUP':
                setGroupFilters((groupFilters) => groupFilters.filter(d => !(d.type === item.type && d.id === item.id)));
                break;
            case 'SEGMENT':
                setSegmentFilters((segmentFilters) => segmentFilters.filter(d => !(d.type === item.type && d.id === item.id)));
                break;
            case 'OPTION':
                setOptionFilters((optionFilters) => optionFilters.filter(d => !(d.type === item.type && d.id === item.id)));
                break;
            case 'DATE':
                setDateFilters((dateFilters) => dateFilters.filter(d => !(d.type === item.type && d.id === item.id)));
                break;
            default:
        }
    }

    const renderTags = () => {
        if (!tagFilters || !tagFilters.length) {
            return null;
        }

        return (
            <Box sx={(theme) => ({
                display: 'flex', alignContent: 'center', gap: theme.spacing(1),
                flexDirection: 'row', flexWrap: 'wrap', overflow: 'hidden'
            })}>
                {
                    tagFilters.filter((filter) => filter.selected.length > 0).map((filter) => {
                        return (
                            filter.selected.map((tag, tagIndex) => {
                                if (tag.nonRemovable) {
                                    return <HFTag key={tagIndex}
                                        text={'<strong>' + tag.option + ': </strong>' + tag.name}
                                        status={tagColor ? tagColor : 'neutral'} />;
                                } else {
                                    return <HFTag key={tagIndex}
                                        text={'<strong>' + tag.option + ': </strong>' + tag.name}
                                        status={tagColor ? tagColor : 'neutral'}
                                        onRemove={() => handleRemove(tag, filter.type)} />;
                                }
                            })
                        )
                    })

                }
            </Box>
        )


    }


    return (
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1), flexDirection: 'row' })}>
            <Button
                variant="text"
                size="small"
                style={{ textTransform: 'none' }}
                onClick={handleClick}
                startIcon={<FilterIcon />}>
                <Typography variant={'caption'} sx={{textTransform: 'capitalize'}}>
                    {$translate.instant('FILTER')}
                </Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {hierarchyFiltersConfig &&
                    <Box><HierarchyNestedMenuItem
                        open={open}
                        filters={hierarchyFilters}
                        setFilters={setHierarchyFilters}
                        mode={hierarchyFiltersConfig.mode}
                        title={hierarchyFiltersConfig.title}
                        nonRemovable={hierarchyFiltersConfig.nonRemovable}/>
                    </Box>}
                {groupFiltersConfig &&
                    <Box><GroupNestedMenuItem
                        open={open}
                        filters={groupFilters}
                        setFilters={setGroupFilters}
                        mode={groupFiltersConfig.mode}
                        title={groupFiltersConfig.title}
                        nonRemovable={groupFiltersConfig.nonRemovable}/>
                    </Box>}
                {segmentFiltersConfig &&
                    <Box><SegmentNestedMenuItem
                        open={open}
                        filters={segmentFilters}
                        setFilters={setSegmentFilters}
                        mode={segmentFiltersConfig.mode}
                        title={segmentFiltersConfig.title}
                        nonRemovable={segmentFiltersConfig.nonRemovable}/>
                    </Box>}
                {optionFiltersConfig &&
                    <Box>{optionFiltersConfig.filters.map((option, index) => (
                        <Box key={index}><OptionNestedMenuItem
                            open={open}
                            options={option.options}
                            filters={optionFilters}
                            setFilters={setOptionFilters}
                            mode={option.options[0].exclusive ? 'single' : 'multi'}
                            title={option.title}
                            nonRemovable={option.nonRemovable}/></Box>
                    ))}</Box>}
                {dateFiltersConfig &&
                    <Box>{dateFiltersConfig.filters.map((date, index) => (
                        <Box key={index}>
                            <DateNestedMenuItem
                                open={open}
                                filters={dateFilters}
                                setFilters={setDateFilters}
                                id={date.id}
                                title={date.title}
                                nonRemovable={date.nonRemovable}/>
                        </Box>
                    ))}</Box>}
            </Menu>
            {renderTags()}
        </Box>
    );

};

/*HFDateTaggingFilter.propTypes = {
    mode: PropTypes.string,
    label: PropTypes.string.isRequired,
    typeLabel: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    nonRemovable: PropTypes.bool,
    maxView: PropTypes.string,
    minView: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    view: PropTypes.string,
    format: PropTypes.string,
    tagFilterMenu: PropTypes.object.isRequired
};*/

angularize(HfFilterMenu, 'hfFilterMenu', angular.module('happyForceApp'), {
    hierarchyFiltersConfig: '<',
    groupFiltersConfig: '<',
    segmentFiltersConfig: '<',
    optionFiltersConfig: '<',
    dateFiltersConfig: '<',
    selectedFilters: '<',
    selectedTags: '<',
    updateFilters: '<',
    tagColor: '<',
});

export default HfFilterMenu;
