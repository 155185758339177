import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import {angularize, getService} from 'reactInAngular';
import HFDotLegend from 'root/app/shared/components/_migrated/dot-leyend/dotLeyend';
import Numbers from 'root/app/utilities/number.utilities';

const OpenTopic = ({ question, results, updateCallback }) => {
    const $translate = getService('$translate');

    const [chartConfig, setChartConfig] = useState(null);
    const [chartLegend, setChartLegend] = useState([]);
    const generateChartConfig = () => {

        const categories = [];

        for (var i = 0; i <= question.resultValues.length - 1; i++) {
            var value = question.resultValues[i];
            categories.push($translate.instant(value.label.defaultValue) + ' (' + results.periodResults.ratings[value.id] + ')');
        }

        var ratings = Object.keys(results.periodResults.ratingPercents).map(function (i) {
            var value = results.periodResults.ratingPercents[i];
            return {
                label: categories[i - 1],
                y: value,
                percent: Highcharts.numberFormat(value, 0) + '%'
            };
        });

        setChartConfig({
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            colors: question.resultValues.map(function (value) {
                return value.color;
            }),

            plotOptions: {
                series: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    }
                }
            },

            chart: {
                type: 'bar',
                margin: [20, 90, 60, 0],
                style: {
                    fontFamily: 'HelveticaNeue-Light'
                },
                height: 250,
                events: {
                    load: function () {
                        setTimeout(function () {
                            $(window).trigger('resize');
                        }, 1000);
                    }
                }
            },

            legend: { enabled: false },
            xAxis: {
                categories: categories,
                minorTickLength: 0,
                tickLength: 0
            },
            yAxis: {
                labels: {
                    enabled: false
                },
                title: {
                    enabled: false
                },
                minorGridLineWidth: 0,
                max: 100,
                min: 0,
                gridLineWidth: 1,
                allowDecimals: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            series: [
                {
                    data: ratings,
                    dataLabels:  [{
                        align: 'bottom',
                        format: '{point.percent}'
                    }]
                }
            ],
            title: {
                text: null
            }

        });
    }

    const generateChartLegend = () => {

        const newChartLegend = [];

        for (var i = 0; i <= question.resultValues.length - 1; i++) {
            const value = question.resultValues[i];
            newChartLegend.push({
                label: $translate.instant(value.label.defaultValue) + ' (' + results.periodResults.ratings[value.id] + ')',
                color: value.color
            });
        }

        setChartLegend(newChartLegend);

    }

    function getRecurentValue(value, result) {

        const percentValue = result.ratingPercents[value.id] ? Numbers.roundNumber(result.ratingPercents[value.id],1)  : 0
        const totalValue = result.ratings[value.id] ? result.ratings[value.id]  : 0

        return (
            <>
                {percentValue}%
                <br/>
                <p className="mini-style warm-grey">{totalValue}</p>
            </>
        )
    }


    useEffect(() => {
        generateChartConfig();
        generateChartLegend();
    }, [question, results]);

    if (!question || !results) {
        return null;
    }



    return (
        <div className="row no-padding">
            <div className="col-xs-12">
                <h5 className="big-style">{$translate.instant('TOPIC_OPEN_VOTE_RESULT')}</h5>
                <p className="mini-style warm-grey">{$translate.instant('TOPIC_OPEN_VOTE_RESULT_DESCRIPTION')}</p>
                {chartConfig && <HighchartsReact highcharts={Highcharts} options={chartConfig} />}
                <HFDotLegend items={chartLegend} />
            </div>
            {question.activationRule.ruleType === 'RECURRENT' && (
                <div className="col-xs-12 results-table">
                    <h5 className="big-style">{$translate.instant('TOPIC_OPEN_VOTE_PAST_RESULT')}</h5>
                    <p className="mini-style warm-grey">{$translate.instant('TOPIC_OPEN_VOTE_PAST_RESULT_DESCRIPTION')}</p>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="warm-grey">{$translate.instant('DATE')}</th>
                                {question?.resultValues?.map((value, index) => (
                                    <th key={index}
                                        className="text-center">{$translate.instant(value.label.defaultValue)}</th>
                                ))}
                                <th className="warm-grey">{$translate.instant('TOPIC_OPEN_VOTE_PAST_RESULT_PARTICIPANTS')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results?.results?.map((result, index) => (
                                <tr key={index}>
                                    <td className="warm-grey">{moment.utc(result.from).format('DD MMM YYYY')}</td>
                                    {question?.resultValues?.map((value, index) => (
                                        <td key={value.id + index} className="text-center">
                                            {getRecurentValue(value, result)}
                                        </td>
                                    ))}
                                    <td className="warm-grey">{result.votesPeriod}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

OpenTopic.propTypes = {
    question: PropTypes.object.isRequired,
    results: PropTypes.object.isRequired,
    updateCallback: PropTypes.func,
};

angularize(OpenTopic, 'hfOpenTopic', angular.module('happyForceApp'), {
    question: '<',
    results: '<'
});


export default OpenTopic;
