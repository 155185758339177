'use strict'



import FeedbackIdeasUtils from "../../feedback-ideas-utils";

function FeedbackInitiativesBoxCtrl($scope, Filters, HIService, Stats, $stateParams, Settings, ErrorSvrc, FeedbackStatusService, FeedbackIdeasService, FeedbackContainersService, $translate, $state, toaster) {
    'ngInject';

    const _this = this;

    function setStatusLabels() {
        const result = FeedbackIdeasUtils.getStatusColorAndLabel(_this.initiative.status);

        _this.statusLabel = $translate.instant(result.statusLabel);
        _this.statusStyle = result.statusStyle;
    }


    _this.goToDetail = function () {
        if (_this.initiative.draft) {
            $state.go('feedback.initiativeEditor', { initiativeId: _this.initiative.id });
        } else {
            $state.go('feedback.initiativeDetail', { initiativeId: _this.initiative.id });
        }
    };

    this.$onInit = function () {
        setStatusLabels();
    };

}


const hfFeedbackInitiativeBox = angular.module('happyForceApp')
    .directive('hfFeedbackInitiativeBox', function () {
        return {
            template: require('./feedback-initiative-box.html'),
            controller: FeedbackInitiativesBoxCtrl,
            scope: {
                initiative: '=',
                reload: '='
            },
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackInitiativeBox;
