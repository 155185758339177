import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import Dates from '../../../../../utilities/date.utilities';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    DialogContentText
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const HIReportModal = ({ to, show, onClose }) => {

    const $translate = getService('$translate');


    const [ranges, setRanges] = useState({
        from: Dates.firstDayOfMonth(Dates.substractDoDate(to, 12, 'months')),
        to: Dates.lastDayOfMonth(to),
    });

    const handleDateChange = (type, value)  => {
        setRanges({ ...ranges, [type]: value });
    };

    const handleOk = () => {
        onClose( moment(ranges.from).toDate(), moment(ranges.to).toDate());
    };

    const handleCancel = () => {
        onClose();
    };

    // TODO: The localization provider should be in the root of the app
    return (
        <Dialog open={show} onClose={handleCancel}>

            <DialogTitle>{$translate.instant('HI_REPORT_MODAL_TITLE')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {$translate.instant('HI_REPORT_MODAL_DESCRIPTION')}
                </DialogContentText>
                <Grid container sx={theme => ({'paddingTop': theme.spacing(2)})}>
                    <Grid item xs={6}>
                        <DatePicker
                            label={$translate.instant('FROM')}
                            onChange={value => handleDateChange('from', value)}
                            defaultValue={moment(ranges.from)} />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            label={$translate.instant('TO')}
                            onChange={value => handleDateChange('to', value)}
                            defaultValue={moment(ranges.to)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'secondary'} onClick={handleCancel}>
                    {$translate.instant('CANCEL')}
                </Button>
                <Button variant={'contained'} onClick={handleOk} color="primary">
                    {$translate.instant('EXPORT_EXCEL')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

HIReportModal.propTypes = {
    to: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

angularize(HIReportModal, 'hiReportModal', angular.module('happyForceApp'), {
    to: '<',
    show: '<',
    onClose: '<',
});

export default HIReportModal;
