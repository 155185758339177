'use strict';

function SendEmployeeReminderCtrl($scope, target, hierarchyIds, segments, groupIds, People, Analytics, ErrorSvrc, $modalInstance, $translate, AuthSrvc) {
    'ngInject';
    var _this = this;

    _this.target = target;
    _this.groupsSelected = '';
    _this.hasContent = false;

    _this.userName = AuthSrvc.getUserName();
    _this.sending = false;
    _this.useSenderName = true;
    _this.hierarchyIds = hierarchyIds;
    _this.segments = segments;
    _this.groupIds = groupIds;

    if (_this.hierarchyIds ||  _this.segments || _this.groupIds) {
        _this.hasSegmentation = true;
    }


    _this.cancel = function () {
        $('#contentEditor').summernote('destroy');
        $modalInstance.dismiss(null);
    };

    _this.submit = function () {

        Analytics.trackEvent('settings_company_employees', 'sendReminder');
        _this.sending = true;

        var segmentationParams = {
            hierarchyId: _this.hierarchyIds,
            segments: _this.filters,
            groupIds: _this.groupIds
        };

        People.reminder(_this.useSenderName, _this.subject, $('#contentEditor').summernote(
         'code'
        ), _this.target, segmentationParams,  function (err) {
            _this.sending = false;
            $('#contentEditor').summernote('destroy');
            $modalInstance.close(null);
        }, function (err) {

            _this.sending = false;

            ErrorSvrc.showErrorModal(err);
        });

    };

    var maximumImageFileSize = 1048576 * 5; // 5MB

    setTimeout(function () {
        var editor = $('#contentEditor').summernote({
            dialogsInBody: true,
            dialogsFade: false,
            placeholder: $translate.instant('EMPLOYEES_SEND_MESSAGE_CONTENT_PLACEHOLDER'),
            height: 150,
            toolbar: [['style',  ['bold', 'italic', 'underline', 'ul', 'link']]],
            maximumImageFileSize: maximumImageFileSize,
            onCreateLink: function (link) {
                setTimeout(function () {
                    $('body').addClass('modal-open');
                }, 500);

                return link;
            },
            callbacks: {
                onChange: function (contents, $editable) {
                    _this.hasContent = !$('#contentEditor').summernote('isEmpty');
                    $scope.$digest();
                }
            }
        });
    }, 500);
}

const UserSendReminderModal = angular.module('happyForceApp')
    .factory('UserSendReminderModal',
        function ($uibModal, $translate, toaster) {
            var methods = {};
            methods.showSendReminderModal = function (target, hierarchyIds, segments, groupIds, callback) {
                $uibModal.open({
                    backdrop: 'static',
                    size: ' large',
                    resolve: {
                        target: function () {
                            return target;
                        },
                        hierarchyIds: function () {
                            return hierarchyIds;
                        },
                        groupIds: function () {
                            return groupIds;
                        },
                        segments: function () {
                            return segments;
                        }
                    },
                    controller: SendEmployeeReminderCtrl,
                    template: require('./users_send_reminder.html'),
                    controllerAs: '$ctrl'
                }).result.then(function (modalParams) {
                    toaster.pop('success', null, ($translate.instant('EMPLOYEE_REMINDER_SENT')));
                    callback(null);
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );

export default UserSendReminderModal;
