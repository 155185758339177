import React from 'react';
import { Tooltip } from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';

const CommentChangeVisibilityAction = ({ comment, commentUpdatedCallback, showActionLabel }) => {
    const Comments = getService('Comments');
    const CommentSwapVisibilityModal = getService('CommentSwapVisibilityModal');
    const $translate = getService('$translate');

    const callback = (commentId) => {
        if (commentUpdatedCallback) {
            Comments.getOne(comment.id, (err, result) => {
                commentUpdatedCallback(err, result);
            });
        }
    };

    const showSwapVisibilityModal = (event) => {
        event.stopPropagation();
        CommentSwapVisibilityModal.showModal(comment.id, callback);
    };

    return (
        <span>
            {comment.allowPrivatedSwap ? (
                <a onClick={showSwapVisibilityModal} color="default" style={{display:'flex'}}>
                    <i className="fa fa-eye-slash"></i>
                    {showActionLabel &&
                        <span className={'m-l-xs'}> {$translate.instant('COMMENT_ACTION_SWAP_VISIBILITY_MANAGE')}</span>
                    }
                </a>
            ) : (
                <Tooltip title={$translate.instant('COMMENT_ACTION_SWAP_VISIBILITY_MANAGE')}>
                    <span className={'warm-grey'} style={{display:'flex'}}>
                        <i className="fa fa-eye-slash"></i>
                        {showActionLabel &&
                            <span
                                className={'m-l-xs'}> {$translate.instant('COMMENT_ACTION_SWAP_VISIBILITY_MANAGE')}</span>
                        }
                    </span>
                </Tooltip>
            )}
        </span>
    );
};

CommentChangeVisibilityAction.propTypes = {
    comment: PropTypes.object.isRequired,
    commentUpdatedCallback: PropTypes.func,
    showActionLabel: PropTypes.bool
};

angularize(CommentChangeVisibilityAction, "hfCommentChangeVisibilityAction", angular.module("happyForceApp"), {
    comment: '<',
    commentUpdatedCallback: '=',
    showActionLabel: '<'
});

export default CommentChangeVisibilityAction;
