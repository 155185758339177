const userNameFilter = function ($rootScope, People) {
    'ngInject';
    var hash = [];
    var loading = [];

    function asyncLoad(input) {
        if (input) {
            if (hash[input]) {
                return hash[input];
            } else {
                if (loading[input]) return;

                loading[input] = true;

                People.getProfile(input, function (err, user) {
                    if (err) {
                        hash[input] = $translate.instant('UNKNOWN_USER_NAME');
                    } else {
                        hash[input] = user.name;
                    }

                    loading[input] = false;
                });

            }
        } else {
            console.error('Wrong input id for hierarchy name');
        }

        return input;
    };
    asyncLoad.$stateful = true;
    return asyncLoad;
}

export default userNameFilter;
