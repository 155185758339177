

/*
    Given a text, it translates it to the User selected language. The HF Multilanguage Text
    is a JSON with the following structure:
    {
    "defaultValue": "English text",
    "langValues": {
        "en": "English text",
        "es": "Spanish text",
        "fr": "French text"
    }
    }
 */


import {useEffect, useState} from "react";
import {getService} from "reactInAngular";


interface Props {
    multilanguage: any; // <-- TODO: Define somewhere the type of this object
    replaceTokens?: boolean; // <-- TODO: Define somewhere the type of this object
};


const HfMultiLanguageText = (({multilanguage, replaceTokens=true}:Props) => {
    const [multiLanguageText, setMultiLanguageText] = useState<string>("");

    const [companyName, setCompanyName] = useState<string>("");

    const Languages = getService('Languages');
    const CompanySvrc = getService('CompanySvrc');
    const $translate = getService('$translate');


    // Use to load the tokens
    useEffect(() => {

        CompanySvrc.getUserCompany(function (err: any, company: any) {
            if (err) {
                setCompanyName($translate.instant('COMPANY'));
            } else {
                setCompanyName(company.name);
            }
        });

    }, [replaceTokens]);


    useEffect(() => {

        let finalTranslation: string = Languages.getTranslationFromUserLanguage(multilanguage);
        if (replaceTokens) {
            if (finalTranslation?.includes('*|COMPANY_NAME|*')) {
                finalTranslation= finalTranslation.replace('*|COMPANY_NAME|*', companyName);
            }
            setMultiLanguageText(finalTranslation);
        } else {
            setMultiLanguageText(finalTranslation);
        }

    }, [multilanguage, companyName]);

    return (
        `${multiLanguageText}`
    );
});

export default HfMultiLanguageText;
