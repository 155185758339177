'use strict';

function FeedbackReplyConfigCtrl($scope, $translate, Analytics) {
    'ngInject';

    $scope.commentsReplyModes = [
        { id: 'NO_REPLY', label: $translate.instant('EMPLOYEE_COMMENT_REPLY_MODE_NONE') },
        { id: 'COMMENT_AND_MULTIPLE_REPLY', label: $translate.instant('EMPLOYEE_COMMENT_REPLY_MODE_COMMENT_AND_MULTIPLE_REPLY') },
        { id: 'COMMENT_AND_ONLY_MANAGERS_REPLY', label: $translate.instant('EMPLOYEE_COMMENT_AND_ONLY_MANAGERS_REPLY') }
    ];


    Analytics.trackEvent('settings_feedback_reply_config');
}

const hfFeedbackReplyConfig = angular.module('happyForceApp')
    .directive('hfFeedbackReplyConfig', function () {
        return {
            template: require('./feedback_reply_config.html'),
            controller: FeedbackReplyConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackReplyConfig;