import Dates from "../../utilities/date.utilities";

const ScoresService = function (Restangular, $translate, Settings, Languages) {
    'ngInject';
    var Scores = Restangular.one('company').one('scores');

    var Stats = Restangular.one('company').one('stats').one('scores');

    var Feedback = Restangular.one('company').one('feedback').one('scores');

    var methods = {};

    methods.list = function (params, callback) {
        Scores.get(params).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.activate = function (scoreId, callback) {

        var params = {};

        Scores.one(scoreId).customPUT({}, 'activate', params).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.deactivate = function (scoreId, callback) {
        Scores.one(scoreId).one('deactivate').customPUT().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.get = function (scoreId, callback) {
        Scores.one(scoreId).get().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.getRecommendedDates = function (scoreId, callback) {
        Scores.one(scoreId).one('recommendedDates').get().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.factorCommentLabel = function (type, factor, value) {
        if (type === 'ENPS') {
            if (value < 7) {
                return $translate.instant('SCORE_FACTOR_COMMENT_ENPS_DETRACTOR', { rating: value });
            } else if (value < 9) {
                return $translate.instant('SCORE_FACTOR_COMMENT_ENPS_NEUTRAL', { rating: value });
            } else {
                return $translate.instant('SCORE_FACTOR_COMMENT_ENPS_PROMOTER', { rating: value });
            }
        } else {
            return $translate.instant('SCORE_FACTOR_COMMENT', { factorName: Languages.getTranslationFromUserLanguage(factor.name), rating: value });
        }
    };

    methods.valuationLabel = function (valuation) {

        if (valuation === 'EXCELLENT') {
            return $translate.instant('SCORE_VALUATION_EXCELLENT');
        } else if (valuation === 'GOOD') {
            return $translate.instant('SCORE_VALUATION_GOOD');
        } else if (valuation === 'WARNING') {
            return $translate.instant('SCORE_VALUATION_WARNING');
        } else if (valuation === 'BAD') {
            return $translate.instant('SCORE_VALUATION_BAD');
        } else {
            return $translate.instant('SCORE_VALUATION_NO_DATA');
        }
    };

    methods.factorValuationLabel = function (valuation) {

        if (valuation === 'EXCELLENT') {
            return $translate.instant('FACTOR_VALUATION_VERY_WELL');
        } else if (valuation === 'GOOD') {
            return $translate.instant('FACTOR_VALUATION_DOING_GREAT');
        } else if (valuation === 'WARNING') {
            return $translate.instant('FACTOR_VALUATION_YOU_CAN_IMPROVE');
        } else if (valuation === 'BAD') {
            return $translate.instant('FACTOR_VALUATION_YOU_NEED_TO_FOCUS');
        } else {
            return $translate.instant('FACTOR_VALUATION_NO_DATA');
        }

    };

    var stats = {};

    stats.get = function (scoreId, from, to, params, callback) {

        // This is really nasty: never-ever modify an incoming object
        if (from)
            params.from =  Dates.toAPIFormat(from, Settings.apiDateFormat);

        if (to)
            params.to =  Dates.toAPIFormat(to, Settings.apiDateFormat);

        Stats.one(scoreId).get(params).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    var benchmarks = {};

    benchmarks.get = function (scoreId, from, to,  params, callback) {


        var finalParams = angular.copy(params);

        // This is really nasty: never-ever modify an incoming object
        if (from)
            finalParams.from =  Dates.toAPIFormat(from, Settings.apiDateFormat);

        if (to)
            finalParams.to =  Dates.toAPIFormat(to, Settings.apiDateFormat);

        Stats.one(scoreId).one('benchmark').get(finalParams).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    var feedback = {};

    feedback.get = function (params, callback) {

        // This is really nasty: never-ever modify an incoming object
        if (params.from)
            params.from =  Dates.toAPIFormat(params.from, Settings.apiDateFormat);

        if (params.to)
            params.to =  Dates.toAPIFormat(params.to, Settings.apiDateFormat);

        Feedback.get(params).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    var colors = {
        standard:  [
            '#FE2847',
            '#E04528',
            '#E85F1E',
            '#EF7814',
            '#F79209',
            '#FF9300',
            '#E4B600',
            '#CAC100',
            '#B0CB00',
            '#0CAD00'
        ],
        enps: [
            '#FE2847',
            '#FE2847',
            '#FE2847',
            '#FE2847',
            '#FE2847',
            '#FE2847',
            '#FE2847',
            '#FF9300',
            '#FF9300',
            '#0CAD00',
            '#0CAD00'
        ]
    };

    methods.stats = stats;
    methods.feedback = feedback;
    methods.colors = colors;
    methods.benchmarks = benchmarks;

    return methods;

};

export default ScoresService;

