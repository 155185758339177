import Numbers from "../../../../utilities/number.utilities";
import c3 from "c3";

import template from './happiness-gauge-chart.html';
import ArrowIndicator from '../../../../shared/components/_migrated/arrow-indicator-directive/arrow-indicator';


function happinessGaugeChartCtrl($scope, $translate) {
    'ngInject';
    var _this = this;

    _this.loaded = false;

    function loadData() {

        _this.hiValue = Numbers.roundNumber(_this.seriesData.periodIndex);
        _this.participation = Numbers.roundNumber(_this.seriesData.participationPercent);
        _this.variation = Numbers.roundNumber(_this.seriesData.periodIndexVariationPercent);

        var chart = c3.generate({
            bindto: '#hi-gauge-chart',
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
            data: {
                columns: [
                    ['hi', 0]
                ],
                type: 'gauge'
            },
            gauge: {
                label: {
                    format: function (value, ratio) {
                        return '';
                    }
                },
                width: 14 // for adjusting arc thickness
            },
            color: {
                pattern: [_this.color],
                threshold: {
                    unit: 'hi',
                    values: [100]
                }
            },
            size: {
                height: $('#hi-gauge-chart').height(),
                width: $('#hi-gauge-chart').width()
            },
            legend: {
                show: false
            }
        });

        setTimeout(function () {
            chart.load({
                columns: [['hi', _this.hiValue]]
            });

            _this.loaded = true;

        }, 1500);
    };

    ['seriesData'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind(_this, function () {
            return _this[key];
        }), function (n, o) {

            if (!n || n == o) return;
            loadData();
        });
    });

    _this.getVariationLabel = function () {
        if (!_this.variation) return;
        if (_this.variation === 0) {
            return $translate.instant('SAME_AS_PREV_PERIOD');
        } else if (_this.variation < 0) {
            return $translate.instant('BELOW_AS_PREV_PERIOD', { percent: _this.variation });
        } else {
            return $translate.instant('ABOVE_AS_PREV_PERIOD', { percent: _this.variation });
        }
    };

}


const HappinessGaugeChartComponent = angular.module('happyForceApp')
    .directive('happinessGaugeChart', function () {
        return {
            scope: {
                seriesData: '=',
                color: '='
            },
            template: template,
            controller: happinessGaugeChartCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });

export default HappinessGaugeChartComponent;
