import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Numbers from '../../../../../../../utilities/number.utilities';
import Valuation from '../../../../../../../utilities/valuation.utils';
import {APISettings} from '../../../../../../../shared/services/migrated/axiosWrapper';
import EngagementDetailCharts from '../../../../../engagement_detail.charts';
import InfoIcon from '@mui/icons-material/Info';
import HFSwitch from '../../../../../../../shared/new-components/hf-switch/hf-switch.component';
import HfMultilanguageText from '../../../../../../../shared/new-components/hf-multilanguage-text/hfMultilanguageText';
import EmptyState from 'root/app/shared/components/_migrated/empty_state/empty_state';


const ScoreQuestionRow = ({ questionResults, scoreId, factorId, questionNum, numQuestions, dateGrouping }) => {
    const ScoresV2Service = getService('ScoresV2Srvc');
    const ErrorService = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const Languages = getService('Languages');

    const [selectedMode, setSelectedMode] = useState('LAST_RESULT');
    const [questionDetails, setQuestionDetails] = useState('');
    const [inverted, setInverted] = useState(false);
    const [result, setResult] = useState(0);
    const [variation, setVariation] = useState(0);
    const [previousResult, setPreviousResult] = useState(0);
    const [quality, setQuality] = useState('NO_DATA');
    const [valuationColor, setValuationColor] = useState('');
    const [variationText, setVariationText] = useState('');
    const [questionLastResultsChart, setQuestionLastResultsChart] = useState({});
    const [questionEvolutionChart, setQuestionEvolutionChart] = useState({});

    useEffect(() => {
        // Load question metadata
        ScoresV2Service.getQuestionData(scoreId, factorId, questionResults.id, (err, questionInfo) => {
            if (err) {
                ErrorService.showErrorModal(err);
            } else if (questionResults && questionResults.results && questionResults.results.length > 0) {
                setQuestionDetails(questionInfo);
                setInverted(questionInfo.inverted);

                const lastResult = questionResults.results.slice(-1)[0];

                let chartResults = questionResults.results.map(result => [result.from, result.result]);

                setResult(Numbers.roundNumber(lastResult.result, 1));
                setVariation(Numbers.roundNumber(lastResult.variation, 1));
                setQuality(lastResult.quality);
                setValuationColor(Valuation.valuationColor(lastResult.valuation));

                setQuestionLastResultsChart(EngagementDetailCharts.scoreQuestionChart(questionInfo, lastResult));
                setQuestionEvolutionChart(EngagementDetailCharts.factorSparkLineEvolution(chartResults, dateGrouping, 90));


                const previousResultIndex = questionResults.results.length - 2;
                const previousResultValue = previousResultIndex >= 0 ? Numbers.roundNumber(questionResults.results[previousResultIndex].result, 1) : 0


                setPreviousResult(previousResultValue);


            } else {
                setQuality('NO_DATA');
            }
        });
    }, [questionResults]);


    useEffect(() => {
        let keys = { variation, previous: previousResult };

        if (variation > 0) {
            setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_POSITIVE', keys));
        } else if (variation < 0) {
            setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEGATIVE', keys));
        } else {
            setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEUTRAL', keys));
        }

    }, [variation, previousResult]);


    const handleModeChange = (mode) => {
        setSelectedMode(mode);
    };

    return (
        <div className="row question_box_row p-t p-b">
            <div className="col-sm-5 col-xs-12">
                <div className="small-style warm-grey">
                    {`${$translate.instant('ENGAGEMENT_QUESTION_COUNT', {total: numQuestions, number: questionNum})}`}
                </div>
                <div className="medium-style p-t">
                    {<HfMultilanguageText multilanguage={questionDetails?.title}/>}
                    {inverted && (
                        <Tooltip title={$translate.instant('SCORE_INVERTED_QUESTION_DESCRIPTION')}>
                            <span className={'small-style'}>
                                <i className={'fa fa-info-circle m-l-xs text-success'}></i>
                            </span>
                        </Tooltip>
                    )}
                </div>
                {quality !== 'NO_DATA' && (
                    <div className="medium-style question-number m-t-xs" style={{color: valuationColor}}>
                        {result} <span className="sub-index">pts</span>
                    </div>
                )}
                {quality === 'NO_DATA' && <div className="m-t-xs">{$translate.instant('NO_DATA')}</div>}
                {quality !== 'NO_DATA' &&
                    <p key={questionResults.id} className="m-t" dangerouslySetInnerHTML={{__html: variationText}}></p>}


            </div>

            <div className="col-sm-7 col-xs-12 text-center">

                {quality === 'NO_DATA' && <EmptyState
                    image="images/no_scores.svg"
                    small={true}
                    message={$translate.instant('NO_DATA')}
                ></EmptyState>}

                {quality !== 'NO_DATA' && (
                    <>
                        <HFSwitch
                            firstLabel={$translate.instant('LAST_RESULT_MODE')}
                            firstValue={'LAST_RESULT'}
                            secondLabel={$translate.instant('EVOLUTION_MODE')}
                            secondValue={'EVOLUTION'}
                            selected={selectedMode}
                            onSelect={handleModeChange}
                        >
                        </HFSwitch>

                        <div className="question-chart-container">
                            {quality !== 'NO_DATA' && selectedMode === 'LAST_RESULT' && (
                                <HighchartsReact highcharts={Highcharts} options={questionLastResultsChart}/>
                            )}
                            {selectedMode === 'EVOLUTION' && (
                                <HighchartsReact highcharts={Highcharts} options={questionEvolutionChart}/>
                            )}
                        </div>
                    </>
                )}


            </div>


            <hr className="hfline"/>
        </div>
    );
};

ScoreQuestionRow.propTypes = {
    questionResults: PropTypes.object.isRequired,
    scoreId: PropTypes.string.isRequired,
    factorId: PropTypes.string.isRequired,
    questionNum: PropTypes.number.isRequired,
    numQuestions: PropTypes.number.isRequired,
    dateGrouping: PropTypes.string.isRequired
};

export default ScoreQuestionRow;
