const RecognitionService = function (Restangular, Settings) {
    'ngInject';
    var Recognitions = Restangular.one('company').one('recognition');

    var RecognitionStats = Restangular.one('company').one('stats').one('recognition');

    var methods = {};

    methods.list = function (params, callback) {

        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        if (params && params.hierarchyId && params.hierarchyId.id) {
            finalParams.hierarchyId = params.hierarchyId.id;
        }

        Recognitions.get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };


    var stats = {};

    stats.get = function (params, callback) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        RecognitionStats.get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    stats.rankingSource = function (params, callback) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        RecognitionStats.one('ranking').one('source').get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    stats.rankingTarget = function (params, callback) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        RecognitionStats.one('ranking').one('target').get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    methods.stats = stats;

    return methods;

};

export default RecognitionService;
