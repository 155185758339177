import Dates from 'root/app/utilities/date.utilities';
import {APISettings, doGet} from 'root/app/shared/services/migrated/axiosWrapper';



const scoreInsightsBase = '/company/scores/insights';

export const getScoreInsightsList = (dateGrouping, to, callback) => {
    const params = {
        dateGrouping: dateGrouping,
        to: Dates.toAPIFormat(to, APISettings.apiDateFormat),
    };
    const url = `${scoreInsightsBase}`;
    doGet(url, params, callback);
};


export const getScoreInsightImpacts = (insightId, dateGrouping, groupingType, to, callback) => {


    const params = {
        to: Dates.toAPIFormat(to, APISettings.apiDateFormat),
        dateGrouping: dateGrouping,
        groupingType: groupingType,
    };
    const url = `${scoreInsightsBase}/${insightId}/impacts`;
    doGet(url, params, callback);
}
