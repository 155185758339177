'use strict';

function SomethingIsWrongFeedbackItemCtrl($scope, Announcements, $translate, toaster, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.commentUpdated = function (err, updatedComment) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            _this.comment = updatedComment;
        }
    };

    this.$onInit = function () {

        // Conversation link modal, will be only displayed based on setting value
        if (typeof _this.showConversationLink != 'undefined') {
            _this.displayConversationLink = _this.showConversationLink;
        } else {
            _this.displayConversationLink = true;
        }

        // Save the vote image for latter reference
        if (_this.comment && _this.comment.votes) {
            _this.voteImage = _this.comment.votes[0].imageUrl;
        }

    };

}

const hfSomethingIsWrongFeedbackItem = angular.module('happyForceApp').directive('hfSomethingIsWrongFeedbackItem', function () {
    return {
        scope: {
            comment: '<',
            showConversationLink: '<?',
            showReplyWithAnnouncementLink: '<?',
            showModerateLink: '<?',
            size: '<'
        },
        restrict: 'E',
        template: require('./something-is-wrong_feedback_item.html'),
        controller: SomethingIsWrongFeedbackItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfSomethingIsWrongFeedbackItem;

