'use start';
import Style from "../../utilities/style.utils";

var FeedbackCharts = FeedbackCharts || {};

FeedbackCharts.commentsStatsChart =  function (feedbackTypesData, $translate) {
    var seriesData = [];
    var commentCount = 0;
    for (var x in feedbackTypesData) {
        seriesData.push(
            {
                name: $translate.instant('FEEDBACK_TYPE_RESULT_' + feedbackTypesData[x].type),
                y: feedbackTypesData[x].count || 0,
                color: feedbackTypesData[x].color,
                id: feedbackTypesData[x].type

            }
        );
        commentCount += feedbackTypesData[x].count;

    }
    /*
     Amarillo: #ffc200,
     Verde: #37c098,
     Violeta: #6c70dc,
     Rojo: #df356b
    */

    return {
        options: {

            chart: {
                backgroundColor: 'transparent',
                type: 'column',
                height: 200,
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                shared: true,
                headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span>{point.y}</b><br/>'
            },
            plotOptions: {
                column: {
                    minPointLength: 3
                }
            }
        },
        title: {
            text: null
        },
        yAxis: {
            visible: false
        },
        xAxis: {
            type: 'category',
            max: 4,
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            minorTickLength: 0,
            tickLength: 0,
            labels: {
                useHTML: true,
                animate: true,
                formatter: function () {
                    var pos = this.pos;
                    var item = seriesData[pos];
                    return '<span><img src="https://assets.myhappyforce.com/defaultImages/ft_'
                        + item.id.toLowerCase() + '.png" style="width: 40px; height: 40px;"/><br></span>';
                }
            }
        },
        series: [
            {
                type: 'column',
                data: seriesData,
                dataLabels: [{
                    enabled: true,
                    inside: true,
                    style: {
                        fontSize: '16px',
                        color: 'black',
                        textOutline: false
                    }
                }]
            }
        ],
        loading: false
    };

};

FeedbackCharts.recognitionPolarChart = function (results, colors, size, userLanguage, Languages) {

    if (!results) return;
    var categories = [];
    var seriesData = [];
    var max = 0;
    var count = 0;
    for (var i = 0; i <= results.length - 1; i++) {
        if (results[i].count > 0) {
            categories.push(Languages.getTranslationFromUserLanguage(results[i].value.name, userLanguage));
            seriesData.push(
                {
                    x: count,
                    y: results[i].count,
                    color: colors[i]
                }
            );
            count = count + 1;
            max = results[i].count > max ? results[i].count : max;
        }

    }

    var maxRadius = 5;


    return {

        options: {

            chart: {
                polar: true,
                height: size,
                backgroundColor: 'transparent'
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },

            plotOptions: {
                series: {
                    marker: {
                        radius: maxRadius,
                        fillColor: '#FFFFFF',
                        lineWidth: 2,
                        lineColor: null,
                        symbol: 'circle'
                    }
                },

                column: {
                    pointPadding: 0,
                    groupPadding: 0
                }
            }
        },

        exporting: {
            enabled: false
        },

        title: {
            text: null
        },
        pane: {
            startAngle: 0,
            endAngle: 360
        },
        xAxis: {
            categories:  categories
        },
        yAxis: {
            min: 0,
            max: max
        },

        series: [{
            type: 'line',
            pointPlacement: 'on',
            name: 'Total',
            data: seriesData,
            color: Style.linkColor
        }]
    };

};

export default FeedbackCharts;
