const ScoreIconFilter = function (ScoresV2Srvc) {
    'ngInject';
    var hash = [];
    var loading = [];

    function asyncLoad(input) {
        if (hash[input]) {
            return hash[input];
        } else {
            if (loading[input]) return;

            loading[input] = true;

            ScoresV2Srvc.getScore(input, function (err, score) {
                if (err) {
                    hash[input] = '?';
                } else {
                    hash[input] = score.iconPath;
                }

                loading[input] = false;

                //$rootScope.$apply();
            });
        }
        return '';
    };
    asyncLoad.$stateful = true;
    return asyncLoad;

};

export default ScoreIconFilter
