'use strict';

import QuestionsUtils from "../../../../../utilities/question.utilities";
import Style from "../../../../../utilities/style.utils";

function SurveyQuestionEdit($modalInstance, $translate, question, Languages, selectedLanguage, isEdit) {

    var _this = this;

    _this.isEdit = isEdit;
    _this.selectedLanguage = selectedLanguage;
    _this.customizeWeight = false;

    // Enable the multiCheckbox if needed
    if (question && ((question.minSelection && question.minSelection > 1) || (question.maxSelection && question.maxSelection > 1))) {
        _this.limitMinMaxAnswers = true;
    } else {
        _this.limitMinMaxAnswers = false;
    }


    if (_this.isEdit) {
        if (_this.selectedLanguage) {
            _this.modalSubtitle = $translate.instant('SURVEY_QUESTION_EDIT_DESCRIPTION_TRANSLATION',
                { language: Languages.getNameByCode(_this.selectedLanguage) });
        } else {
            _this.modalSubtitle = $translate.instant('SURVEY_QUESTION_EDIT_DESCRIPTION');
        }

    } else {
        _this.modalSubtitle = $translate.instant('SURVEY_QUESTION_ADD_DESCRIPTION');
    }


    _this.slider = {
        value: 2,
        rangeFirstOption: '',
        rangeLastOption: '',
        options: {
            ceil: 10,
            minLimit: 2,
            floor: 1,
            hidePointerLabels: true,
            hideLimitLabels: true,
            showSelectionBar: true,
            showTicks: true,
            showTicksValues: false
        }
    };

    /* If we are editing a language, dissable the slider */
    _this.slider.options.disabled = _this.selectedLanguage ? true : false;


    _this.wizardOptions = {
        nextButton: $translate.instant('QUESTION_NEXT'),
        prevButton: $translate.instant('QUESTION_PREV'),
        confirm: {
            text: _this.isEdit ? $translate.instant('SURVEY_UPDATE_QUESTION') : $translate.instant('SURVEY_ADD_QUESTION'),
            action: function () {
                // If the question not a TOPIC or
                if (!_this.limitMinMaxAnswers && _this.question.questionType === 'TOPIC') {
                    _this.question.minSelection = 1;
                    _this.question.maxSelection = 1;
                } else if (_this.question.questionType != 'TOPIC') {
                    delete _this.question.minSelection;
                    delete _this.question.maxSelection;
                }
                $modalInstance.close(_this.question);
            }
        },
        validate: function () {
            _this.invalidQuestion = !_this.questionForm.$valid;
            return _this.questionForm.$valid;
        },
        exit: function () {
            $modalInstance.dismiss('close');
        }
    };

    /* If we are on edit mode, and we are editing the default language, allow to delete the question */
    if (_this.isEdit && !_this.selectedLanguage) {
        _this.wizardOptions.return = {
            text: $translate.instant('DELETE'),
            className: 'button-danger',
            action: function () {
                _this.invalidQuestion = false;
                $modalInstance.close(null);
            }
        };
    };

    /* If we are not in edit mode, allow to go back to change the question type */
    if (!_this.isEdit) {
        _this.wizardOptions.return = {
            text: $translate.instant('RETURN'),
            className: 'hf-secondary',
            action: function () {
                _this.showQuestionSelection = true;
            }
        };
    };


    _this.addValue = function () {
        if (typeof _this.question.values === 'undefined') {
            _this.question.values = [];
        }

        if (_this.question.values.length > 14) return;

        var emptyValue = {
            id: _this.question.values.length + 1,
            label: { defaultValue: '' },
            color: QuestionsUtils.getTopicColor(_this.question.values.length + 1)
        };

        if (_this.selectedLanguage) {
            if (!emptyValue.langValues) emptyValue.langValues = {};
            emptyValue.langValues[_this.selectedLanguage.code] = '';
        }

        _this.question.values.push(emptyValue);
        _this.resampleValueWeights();
    };

    _this.deleteValue = function (index) {
        if (_this.question.values && _this.question.values.length > 2) {
            _this.question.values.splice(index, 1);
        }
        _this.resampleValueWeights();
    };


    _this.resampleValueWeights = function () {
        if (!_this.customizeWeight && _this.question.values) {
            var stepScore = 10 / _this.question.values.length;
            var accumulatedScore = stepScore;


            _this.question.values.forEach(function (value) {
                value.score = Math.round(accumulatedScore);
                accumulatedScore += stepScore;
            });
        }
    };


    _this.intializeQuestion = function (questionType) {
        _this.showQuestionSelection = false;
        _this.question.questionType = questionType;
    };


    if (question) {
        _this.question = angular.copy(question);
        _this.showQuestionSelection = false;
    } else {
        _this.question = {
            title: {
                defaultValue: '',
                langValues: {}
            },
            values: [],
            firstValue: {
                label: {
                    defaultValue: ''
                },
                score: 1,
                id: 1
            },
            lastValue: {
                label: {
                    defaultValue: ''
                },
                id: 10,
                score: 10

            },
            scaleMax: 10,
            computeInBlock: true,
            feedbackEnabled: true,
            minSelection: 1,
            maxSelection: 1
        };

        if (_this.selectedLanguage) {
            _this.question.title.langValues[_this.selectedLanguage.code] = '';
        }

        _this.addValue(_this.question);
        _this.addValue(_this.question);

        _this.isEdit = false;
        _this.showQuestionSelection = true;
    }


    _this.colors = Style.questionColors;

    _this.selectColor = function (value, color) {
        if (value.color)
            _this.colors.push(value.color);

        value.color = color;
        _this.colors = _.without(_this.colors, color);
    };

    function refreshColors() {
        if (!_this.question.values) return;

        _this.question.values.map(function (value, index) {
            value.id = index + 1;
            value.color = value.color || _this.colors[0];
            _this.colors = _.without(_this.colors, value.color);
        });
    };

    refreshColors();

    _this.exit = function () {
        $modalInstance.dismiss('close');
    };
}

const SurveyQuestionEditCtrl = angular.module('happyForceApp').controller('SurveyQuestionEditCtrl', SurveyQuestionEdit);

export default SurveyQuestionEditCtrl;
