
import React, { useEffect, useState } from 'react';
import angular from 'angular';
import {
    // eslint-disable-next-line camelcase
    MRT_ColumnFiltersState,
    // eslint-disable-next-line camelcase
    MRT_SortingState,
    // eslint-disable-next-line camelcase
    MRT_PaginationState,
    // eslint-disable-next-line camelcase
    MRT_Row,
    // eslint-disable-next-line camelcase
    MRT_TableOptions, createRow,
} from 'material-react-table';
import {angularize} from 'reactInAngular';
import {getService} from '../../../../../migration_utils/react-in-angular';
import {Person} from './types';

import {UserListingTable} from './components/users_table';
import UserResendInvitationDialog from './components/dialogs/UserResendInvitationDialog';
import UserResetProfileDialog from './components/dialogs/UserResetProfileDialog';
import UserResubscribeMailDialog from './components/dialogs/UserMailResubscribeDialog';
import UserDeleteDialog from './components/dialogs/UserDeleteDialog';

interface UserListingProps {
    filters: string;
    isInviting: boolean;
    onInvitingCancel: () => void;
}



const UserListing = ({ filters, isInviting, onInvitingCancel  } : UserListingProps) => {


    const [toResend, setToResend] = useState<Person | Person[] | null>(null);
    const [toReset, setToReset] = useState<Person | Person[] | null>(null);
    const [toResubscribe, setToResubscribe] = useState<Person | Person[] | null>(null);
    const [toDelete, setToDelete] = useState<Person | Person[] | null>(null);
    const [fromIndex, setFromIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);


    const [orderDesc, setOrderDesc] = useState(false);
    const [orderBy, setOrderBy] = useState('email');
    const [searchParams, setSearchParams] = useState({});
    const [totalUsers, setTotalUsers] = useState(0);
    const [data, setData] = useState<Person[]>([]);
    const [table, setTable] = useState<MRT_TableOptions<Person>['table'] | null>(null);

    //optionally, you can manage the row selection state yourself
    // eslint-disable-next-line camelcase
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    //table state
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const $translate = getService('$translate');
    const People = getService('People');
    const toaster = getService('toaster');

    const ErrorSvrc = getService('ErrorSvrc');
    const SegmentsFilterService = getService('SegmentsFilterService');

    // eslint-disable-next-line camelcase
    const [currentEditingRow, setCurrentEditingRow] = useState<MRT_Row<Person> | null>(null);
    const [currentEditingPerson, setCurrentEditingPerson] = useState<Person | null>(null);
    const [isValid, setIsValid] = useState(true);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });



    // eslint-disable-next-line camelcase
    const handleCreateUser: MRT_TableOptions<Person>['onCreatingRowSave'] = async ({
        values,
        table,
    }) => {
        if (!isValid) {
            return;
        }
        table.setCreatingRow(null); //exit creating mode

        setIsSaving(true);
        People.invite(currentEditingPerson, function (err, newUser) {

            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setData((prevData) => {
                    const newData = [...prevData];
                    newData.unshift(newUser);
                    return newData;
                });
                table.setEditingRow(null); //exit editing mode
                table.setCreatingRow(null); //exit creating mode

                if (currentEditingRow) {
                    currentEditingRow.toggleExpanded(false)

                }
                setCurrentEditingRow(null);
                setCurrentEditingPerson(null);
                toaster.pop('success', null, $translate.instant('INVITATION_SENT'));
            }
            setIsSaving(false);
            onInvitingCancel();
        });

    };




    // eslint-disable-next-line camelcase
    const handleSaveUser: MRT_TableOptions<Person>['onEditingRowSave'] = async ({
        values,
        table,
    }) => {

        if (!isValid) {
            return;
        }
        setIsSaving(true);
        People.update(currentEditingPerson, function (err, updatedPerson) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setData((prevData) => {
                    const newData = [...prevData];
                    const index = newData.findIndex((row) => row.id === updatedPerson.id);
                    newData[index] = updatedPerson;
                    return newData;
                });
                table.setEditingRow(null); //exit editing mode
                table.setCreatingRow(null); //exit cr

                if (currentEditingRow) {
                    currentEditingRow.toggleExpanded(false)

                }
                setCurrentEditingRow(null);
                setCurrentEditingPerson(null)
                toaster.pop('success', null, $translate.instant('EMPLOYEE_UPDATED'));
            }
            setIsSaving(false);

        })
    };





    useEffect(() => {
        if (filters) {
            const { searchText, selectedFilters } = JSON.parse(filters);
            const params:any = {};

            params.text = searchText;
            params.orderBy = orderBy;
            params.orderDesc = orderDesc;

            if (selectedFilters) {

                // Set filters
                if (selectedFilters.deletedStatus[0]) {
                    params.deleted = true;
                }

                if (selectedFilters.mailStatus &&  selectedFilters.mailStatus[0] !== 'ANY') {
                    params.mailStatus = selectedFilters.mailStatus[0];
                }

                if (selectedFilters.activatedStatus && selectedFilters.activatedStatus.length === 1) {
                    params.activated = selectedFilters.activatedStatus[0];
                }

                if (selectedFilters.groups && selectedFilters.groups.length) {
                    params.groupId = selectedFilters.groups;
                }

                if (selectedFilters.hierarchyIds && selectedFilters.hierarchyIds.length) {
                    params.hierarchyId = selectedFilters.hierarchyIds.join(',');
                }

                if (selectedFilters.segments && selectedFilters.segments.length) {
                    params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(selectedFilters.segments);
                }


                if (selectedFilters.profileStatus && selectedFilters.profileStatus.indexOf('NO_AREA') !== -1) {
                    params.withoutArea = true;
                }

                if (selectedFilters.profileStatus && selectedFilters.profileStatus.indexOf('INCOMPLETE_PROFILE') !== -1) {
                    params.incompleteProfile = true;
                }


                if (selectedFilters.profileStatus && selectedFilters.profileStatus.indexOf('BLOCKED') !== -1) {
                    params.blocked = true;
                }

                params.role = [];

                if (selectedFilters.employeeType) {
                    params.role = selectedFilters.employeeType;
                }
            }

            setData([])
            setSearchParams(params);
        }
    }, [filters]);


    useEffect(() => {

        if (pageSize === totalUsers) {
            setPagination({
                pageIndex: 0,
                pageSize: totalUsers
            });
        } else {
            setFromIndex(pagination.pageIndex * pagination.pageSize);
            setPageSize(pagination.pageSize);
        }
    }, [pagination.pageIndex, pagination.pageSize]);


    useEffect(() => {


        // Set the pagination
        const requestParams = {
            ...searchParams,
            from: fromIndex,
            to: pageSize,
            orderBy: ['email', 'name'],
            orderDesc: sorting[0]?.desc
        }

        if (!data.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        People.find(requestParams, function (err, data) {

            if (err) {
                ErrorSvrc.showErrorModal(err);
                setIsError(true);
            } else {
                setTotalUsers(data.total);
                setData(data.results);
                setIsError(false);
            }
            setIsLoading(false);
            setIsRefetching(false);
        });
    }, [searchParams,  fromIndex, pageSize, sorting]);

    useEffect(() => {
        if (isInviting) {
            const newRow = createRow<Person>(table, {
                id: null,
                name: '',
                activated: false,
                addedBy: undefined,
                addedOn: undefined,
                appActivationToken: undefined,
                characteristics: [],
                deleted: false,
                deletedOn: undefined,
                groups: [],
                hierarchyId: undefined,
                mailStatus: undefined,
                managedHierarchyIds: [],
                profileImagePath: undefined,
                sourceType: undefined,
                email: '',
                role: 'EMPLOYEE',
                language:  undefined,
                turnoverType: undefined,
                turnoverDate: undefined,
                blocked: false,
                debug: false,
            });

            if (currentEditingRow) {
                table.setEditingRow(null);
                currentEditingRow.toggleExpanded(false);
            }

            setCurrentEditingPerson(newRow.original);
            table.setCreatingRow(newRow);

            newRow.toggleExpanded(true);
        }

    }, [isInviting]);


    const handlePersonUpdated = (person: Person| Person[]) => {

        if (Array.isArray(person)) {
            setData((prevData) => {
                const newData = [...prevData];
                person.forEach((updatedPerson) => {
                    const index = newData.findIndex((row) => row.id === updatedPerson.id);
                    newData[index] = updatedPerson;
                });
                return newData;
            });
        } else {
            setData((prevData) => {
                const newData = [...prevData];
                const index = newData.findIndex((row) => row.id === person.id);
                newData[index] = person;
                return newData;
            });
        }
    }


    const handlePersonDeleted = (person: Person| Person[]) => {
        if (Array.isArray(person)) {
            setData((prevData) => {
                const newData = [...prevData];
                person.forEach((deletedPerson) => {
                    const index = newData.findIndex((row) => row.id === deletedPerson.id);
                    newData.splice(index, 1);
                });
                return newData;
            });
        } else {
            setData((prevData) => {
                const newData = [...prevData];
                const index = newData.findIndex((row) => row.id === person.id);
                newData.splice(index, 1);
                return newData;
            });
        }
    }

    const handleDeleteUser = (row: MRT_Row<Person> | MRT_Row<Person>[]) => {
        if (Array.isArray(row)) {
            setToDelete(row.map((r) => r.original));
        } else {
            setToDelete(row.original);
        }

    }

    const handleResendInvitation = (row: MRT_Row<Person> | MRT_Row<Person>[]) => {
        if (Array.isArray(row)) {
            setToResend(row.map((r) => r.original));
        } else {
            setToResend(row.original);
        }
    }

    const handleResetUserProfile = (row: MRT_Row<Person> | MRT_Row<Person>[]) => {
        if (Array.isArray(row)) {
            setToReset(row.map((r) => r.original));
        } else {
            setToReset(row.original);
        }
    }

    const handleResubscribeUser = (row: MRT_Row<Person> | MRT_Row<Person>[]) => {
        if (Array.isArray(row)) {
            setToResubscribe(row.map((r) => r.original));
        } else {
            setToResubscribe(row.original);
        }
    }



    return (
        <>
            <UserListingTable
                totalUsers={totalUsers}
                data={data}
                isError={isError}
                isRefetching={isRefetching}
                isLoading={isLoading}
                isSaving={isSaving}
                currentEditingRow={currentEditingRow}
                currentEditingPerson={currentEditingPerson}
                setColumnFilters={setColumnFilters}
                setGlobalFilter={setGlobalFilter}
                setSorting={setSorting}
                setCurrentEditingRow={setCurrentEditingRow}
                setCurrentEditingPerson={setCurrentEditingPerson}
                sorting={sorting}
                globalFilter={globalFilter}
                columnFilters={columnFilters}
                handleSaveUser={handleSaveUser}
                onTableCreated={(table) => {setTable(table)}}
                onValidationChange={(isValid) => {setIsValid(isValid)}}
                pagination={pagination}
                setPagination={setPagination}
                handleCreateUser={handleCreateUser}
                handleCreateUserCancel={onInvitingCancel}
                handleDeleteUser={handleDeleteUser}
                handleResendInvitation={handleResendInvitation}
                handleResetUserProfile={handleResetUserProfile}
                handleResubscribeUser={handleResubscribeUser}
                bulkActionType={undefined}
                isInviting={isInviting}
            />
            <UserResendInvitationDialog
                person={toResend}
                onExecuted={(updatedPerson) => {
                    setToResend(null);
                    handlePersonUpdated(updatedPerson)
                }}
                onClose={() => setToResend(null)}/>
            <UserResetProfileDialog
                person={toReset}
                onExecuted={(updatedPerson) => {
                    setToReset(null);
                    handlePersonUpdated(updatedPerson)
                }}
                onClose={() => setToReset(null)}/>
            <UserResubscribeMailDialog
                person={toResubscribe}
                onExecuted={(updatedPerson) => {
                    setToResubscribe(null);
                    handlePersonUpdated(updatedPerson)
                }}
                onClose={() => setToResubscribe(null)}/>

            <UserDeleteDialog
                person={toDelete}
                onExecuted={(updatedPerson) => {
                    setToDelete(null);
                    handlePersonDeleted(updatedPerson)
                }}
                onClose={() => setToDelete(null)}/>
        </>
    )

};




angularize(UserListing, 'hfUserListing', angular.module('happyForceApp'), {
    filters: '=',
    isInviting: '=',
    onInvitingCancel: '&'
});

export default UserListing;


