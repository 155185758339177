
import React from 'react';
import { angularize } from 'reactInAngular';
import Icon from '@mui/material/Icon';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Tooltip from '@mui/material/Tooltip';

const ArrowIndicator = ({ value, label }) => {
    if (value === 0) {
        return null;
    }

    return (
        <Tooltip title={label} placement="left">
            <Icon style={{ color: value < 0 ? '#FE2847' : '#0CAD00' }}>
                {value < 0 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
            </Icon>
        </Tooltip>
    );
};

angularize(ArrowIndicator, 'hfArrowIndicator', angular.module('happyForceApp'), {
    value: '<',
    label: '<'
});

export default ArrowIndicator;
