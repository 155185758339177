'use strinct';

function AnonymousMessageCtrl() {
    var _this = this;
}


const hfAnonymousMessage = angular.module('happyForceApp')
    .directive('hfAnonymousMessage', function () {
        return {
            scope: {
                showTextOnPopover: '<'
            },
            template: require('./anonymous_message.html'),
            controller: AnonymousMessageCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAnonymousMessage;
