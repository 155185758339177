'use strict';
import hfAreasSelector from "../areas-selector/areas-selector.controller";
import {getHierarchyName} from "../../../services/migrated/hierarchy.service";


function AreasBreadcumsCtrl($scope, $uibModal, $translate) {
    'ngInject';

    var _this = this;

    _this.selectAreas = function () {
        $uibModal.open({
            template: require('../areas-selector/areas-selector.html'),
            controller: 'AreasSelectorCtrl',
            controllerAs: '$ctrl',
            size: 'mini area-selector',
            resolve: {
                selected: function () {
                    return $scope.selectedAreas;
                },

                title: function () {
                    return $translate.instant('ADD_AREA');
                }
            }
        }).result.then(function (hierarchy) {
            if (hierarchy && hierarchy.id) {
                if (_this.onlyOne) {
                    $scope.selectedAreas = [{
                        id: hierarchy.id,
                        textValue: hierarchy.name
                    }];
                } else {
                    $scope.selectedAreas.push({
                        id: hierarchy.id,
                        textValue: hierarchy.name
                    });
                }
            }
        }, function (err) {
            console.log('error', err);
        });
    };

    _this.$onInit = function () {
        $scope.selectedAreas = [];
        if (_this.areas && _this.areas.length) {
            _this.areas.forEach(function (area) {

                getHierarchyName(area, function (err, name) {
                    $scope.selectedAreas.push({
                        id: area,
                        textValue: name
                    });
                });
            });
        }
        $scope.$watch(
            'selectedAreas',
            function (n, o) {
                if (!n || n === o) return;

                if ($scope.selectedAreas) {
                    _this.areas = [];
                    $scope.selectedAreas.forEach(function (area) {
                        _this.areas.push(area.id);
                    });
                }
            },
            true
        );
    };

}


const hfAreasBreadcums = angular.module('happyForceApp')
    .directive('hfAreasBreadcums', function () {
        return {
            scope: {
                areas: '=',
                addMoreLabel: '@',
                addFirstLabel: '@',
                onlyOne: '='
            },
            template: require('./areas_breadcums.html'),
            controller: AreasBreadcumsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAreasBreadcums;
