

interface IHeatmapColumn {
    id: string ;
    name: string;
    visible: boolean;
    type?: string;
    icon?: string;
    hasChildren?: boolean;
    isDeployed?: boolean;
    parentId?: string;
}

interface ISegmentInfo {
    type: string;
    hierarchyId?: string;
    groupId?: string;
    characteristic?: {
        characteristicId: string;
        value: string;
    };
}


interface IHeatmapRow {
    segmentId: string ;
    parentId: string | null;
    level: number;
    name: string;
    isDeployed: boolean;
    hasChildren: boolean;
    type: string;
    activeUsers: number;
    visible: boolean;
    segmentInfo: ISegmentInfo;
}

enum HeatmapType {
    HIERARCHIES = 'HIERARCHIES',
    CHARACTERISTICS = 'CHARACTERISTICS',
    GROUPS = 'GROUPS',
}

export {IHeatmapColumn, IHeatmapRow, ISegmentInfo, HeatmapType};

