import React from 'react';
import PropTypes from 'prop-types';
import {angularize} from 'reactInAngular';
import {Autocomplete, FormControl, InputLabel, MenuItem, NativeSelect, Select, TextField} from '@mui/material';


var moment = require('moment-timezone');
const getTimeZoneOptions = function (showTimezoneOffset) {
    var timeZones = moment.tz.names();
    var offsetTmz = [];
    for (var i in timeZones) {
        var tz = timeZones[i];
        var tzOffset = moment.tz(tz).format('Z');
        var value = parseInt(tzOffset
            .replace(':00', '.00')
            .replace(':15', '.25')
            .replace(':30', '.50')
            .replace(':45', '.75')).toFixed(2);
        var timeZoneOption = {
            label: showTimezoneOffset ? tz + ' (GMT' + tzOffset + ')' : tz,
            value: value
        };
        offsetTmz.push(timeZoneOption);
    }
    return offsetTmz;
};

/*
 A custom timezone selector that uses MUI Autocomplete component to display a list of timezones
 allowing the user to select & search one
 */
// TODO: Add an default value to the timezone selector
const HFTimeZoneSelect = ({ selectedTimezone, onSelect }) => {


    const timezones = React.useMemo(() => {return getTimeZoneOptions(false);}, []);

    const handleChange = (timezone) => {
        onSelect(timezone);
    }

    return (
        <FormControl fullWidth>
            <NativeSelect
                defaultValue={selectedTimezone ? selectedTimezone : 'UTC'}
                onChange={(e) => handleChange(e.target.value)}
                inputProps={{
                    id: 'uncontrolled-native',
                }}
            >
                {timezones.map((tz, index) => (
                    <option key={index} value={tz.label}>{tz.label}</option>
                ))}

            </NativeSelect>
        </FormControl>
    )

}

HFTimeZoneSelect.propTypes = {
    selectedTimezone: PropTypes.string,
    onSelect: PropTypes.func,
};


angularize(HFTimeZoneSelect, 'hfTimeZoneSelect', angular.module('happyForceApp'), {
    selectedTimezone: '<',
    onSelect: '<',
});

export default HFTimeZoneSelect;
