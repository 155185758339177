import * as React from 'react';
import {useEffect, useReducer} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {angularize} from '../../../../../migration_utils/react-in-angular';
import angular from 'angular';
import {getService} from 'reactInAngular';
import BulkActionSelectionStep from './user_bulk_steps/components/BulkActionSelectionStep';
import BulkActionDownloadAndUploadStep from './user_bulk_steps/components/BulkActionDownloadAndUploadStep';
import {Grid, Link, StepContent, Typography} from '@mui/material';
import {WhiteBgPaper} from 'root/app/shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import {BulkActionPerson, BulkActionType, RecordStatusErrorType} from '../../components/user_listing/types';
import {BulkActionType} from '../../components/user_listing/components/users_table';
import BulkActionReviewAndProcessPanel from './user_bulk_steps/components/BulkActionReviewAndProcessPanel';
import BulkActionReviewAndProcessStep from './user_bulk_steps/components/BulkActionReviewAndProcessStep';
import {processEmployeeXLSFile} from './user_bulk_steps/utils';
import BulkActionSelectionPanel from './user_bulk_steps/components/BulkActionSelectionPanel';
import BulkActionDownloadAndUploadPanel from './user_bulk_steps/components/BulkActionDownloadAndUploadPanel';
import {APISettings} from '../../../../shared/services/migrated/axiosWrapper';
import Dates from '../../../../utilities/date.utilities';


// Define some utility functions


interface ProcessingState {
    success: number;
    failed: number;
}

interface ProcessingAction {
    type: 'success' | 'failed' | 'reset';
}

const personActionReducer = (state: ProcessingState, action: ProcessingAction): ProcessingState => {
    switch (action.type) {
        case 'failed':
            return { ...state, failed: state.failed + 1 };
        case 'success':
            return { ...state, success: state.success + 1 };
        case 'reset':
            return { success: 0, failed: 0 };
        default:
            return state;
    }
};

const  UsersBulkStepper =() => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedAction, setSelectedAction] = React.useState<BulkActionType | null>(null);
    const [uploadedPersons, setUploadedPersons] = React.useState<BulkActionPerson[]>([]);

    const [uploadedPersonCount, setUploadedPersonCount] = React.useState<number>(0);
    const [verificationProgress, setVerificationProgress] = React.useState<number>(0);
    const [verifiedPersonCount, dispatchVerificationAction] = useReducer(personActionReducer, {success: 0, failed: 0});

    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [isVerifyng, setIsVerifying] = React.useState<boolean>(false);
    const [processingPersonCount, dispatchProcessingAction] = useReducer(personActionReducer, {success: 0, failed: 0});


    const Characteristics = getService('Characteristics');
    const ErrorSvrc = getService('ErrorSvrc');

    const $translate = getService('$translate');

    const People = getService('People');


    const handleDeleteUser = (person: BulkActionPerson) => {
        const index = uploadedPersons.findIndex(p => p.email === person.email);
        if (index > -1) {
            const newPersons = [...uploadedPersons];
            newPersons.splice(index, 1);
            setUploadedPersons(newPersons);
        }
    }

    const handleSaveUser = (index: number, person: BulkActionPerson) => {

        // We asume that the original was not found; let's try to find it again.
        person.recordFailures = [];
        processRecord(person).then((updatedPerson) => {
            const newPersons = [...uploadedPersons];
            newPersons[index] = updatedPerson;
            setUploadedPersons(newPersons);
        });
    }

    const handleNext = () => {
        if (activeStep <2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };


    const handleSelectAction = (action: BulkActionType) => {
        setSelectedAction(action);
        handleNext();
    }

    /*
    * This function is called when the upload is complete to load the original persons
    * and verify the validity of the uploaded persons before processing them
     */

    const processRecord = (person: BulkActionPerson) => new Promise<BulkActionPerson>((resolve) => {
        People.get({email: person.email},
            (err, result) => {
                dispatchVerificationAction({type: 'success'});
                person.recordFailures = person.recordFailures || [];

                if (err) {
                    if (selectedAction !== BulkActionType.INVITE) {
                        // No need to load original persons for invite action
                        person.recordFailures.push(RecordStatusErrorType.ORIGINAL_USER_NOT_FOUND);
                    }
                    resolve(person);
                } else {
                    if (selectedAction === BulkActionType.INVITE && result.deletedOn === undefined) {
                        person.recordFailures.push(RecordStatusErrorType.USER_ALREADY_EXISTS);

                        // If the record is a manager, we need to check if it has a hierarchy asignated from the file
                        if (person.role === 'MANAGER' && (!person.managedHierarchyIds || person.managedHierarchyIds.length === 0)) {
                            person.recordFailures.push(RecordStatusErrorType.MANAGER_REQUIRES_HIERARCHY);
                        }
                        resolve(person);
                    } else if (selectedAction === BulkActionType.DELETE) {
                        const toDeletePerson = {
                            ...result,
                            id: result.id,
                            turnoverType: person.turnoverType,
                            turnoverDate: person.turnoverDate
                        };

                        resolve(toDeletePerson);
                    } else {
                        const toUpdatePerson = {
                            ...person,
                            id: result.id,
                            originalPerson: result
                        };

                        // If the Excel file has a manager role but no managedHierarchyIds, we asume that we want to keep the original ones
                        if (person.role === 'MANAGER' && (!person.managedHierarchyIds || person.managedHierarchyIds.length === 0)) {
                            toUpdatePerson.managedHierarchyIds = result.managedHierarchyIds;
                        } else if (person.role !== 'MANAGER') {
                            toUpdatePerson.managedHierarchyIds = [];
                        }

                        resolve(toUpdatePerson);
                    }


                }
            });
    });


    /*
    * This function is called when the upload is complete to load the original persons
    * in the following BUlkActionType: UPDATE, DELETE.
    * It also sets the uploaded persons to the state of failed records in case is not found
     */

    const handleUploadComplete = async (persons: BulkActionPerson[]) => {

        try {
            setIsVerifying(true);
            setUploadedPersonCount(persons.length);
            dispatchVerificationAction({ type: 'reset' });
            const promises = persons.map(person => {

                return processRecord(person);
            });

            await Promise.all(promises)
                .then((loadedPersons) => {
                    setIsVerifying(false);
                    setUploadedPersons(loadedPersons);
                    handleNext();
                });
        } catch (error) {
            ErrorSvrc.showErrorModal(error);
        }


    }

    const renderSelectionActionChange = (action: BulkActionType | null) => {
        if (!action) {
            return null;
        } else  {

            return (
                <Typography variant="caption">
                    {action === BulkActionType.INVITE && $translate.instant('BULK_EMPLOYEE_PROCESSING_INVITE')}
                    {action === BulkActionType.UPDATE && $translate.instant('BULK_EMPLOYEE_PROCESSING_UPDATE')}
                    {action === BulkActionType.DELETE && $translate.instant('BULK_EMPLOYEE_PROCESSING_DELETE')}

                    <Link
                        onClick={() => {
                            setSelectedAction(null);
                            setVerificationProgress(0);
                            setActiveStep(0);
                            setIsProcessing(false);
                        }}>
                        <Typography variant="caption">
                            <span> </span>({$translate.instant('BULK_EMPLOYEE_PROCESSING_CHANGE')})
                        </Typography>
                    </Link>

                </Typography>
            )
        }
    }

    const handleRestart = () => {
        setUploadedPersons([]);
        setVerificationProgress(0);
        setActiveStep(0);
        setSelectedAction(null);
        setIsProcessing(false);
        dispatchProcessingAction({ type: 'reset' });
        dispatchVerificationAction({ type: 'reset' });
    }

    const renderPeopleLoadedActionChange = () => {
        if (activeStep < 2) {
            return null;
        } else {
            return (
                <Typography variant="caption">
                    {$translate.instant('BULK_EMPLOYEE_PROCESSING_PEOPLE_LOADED', {count: uploadedPersons.length})}
                    <Link
                        onClick={() => {
                            setUploadedPersons([]);
                            setVerificationProgress(0);
                            setActiveStep(1);
                            setIsProcessing(false);
                        }}>
                        <Typography variant="caption">
                            <span> </span>({$translate.instant('BULK_EMPLOYEE_PROCESSING_CHANGE')})
                        </Typography>
                    </Link>
                </Typography>
            )
        }
    }

    const handleUploadFile = async (file: File) => {

        Characteristics.getAll(function (err: any, characteristics: any) {

            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {

                processEmployeeXLSFile(file, setVerificationProgress, characteristics).then((persons: BulkActionPerson[]) => {
                    handleUploadComplete(persons);
                }).catch((err) => {
                    ErrorSvrc.showErrorModal(err);
                });
            }
        });
    }

    const handleProceed = async () => {


        setIsProcessing(true);
        dispatchProcessingAction({ type: 'reset' });
        const promises = uploadedPersons.map((person, index) => {

            return new Promise<BulkActionPerson>((resolve, reject) => {


                const callback = (err, result) => {
                    person.processingFailures = [];
                    if (err) {
                        dispatchProcessingAction({type: 'failed'});
                        person.processingFailures.push(err?.data?.errorCode);
                    } else {
                        dispatchProcessingAction({type: 'success'});
                    }
                    setUploadedPersons(prev => {
                        const updated = [...prev];
                        const newRecord = {...updated[index], ...result};
                        updated[index] = newRecord;
                        return updated;
                    });
                    resolve(person);
                };


                if (selectedAction === BulkActionType.INVITE) {
                    People.invite(person, callback);
                } else if (selectedAction === BulkActionType.UPDATE) {
                    People.update(person, callback);
                } else if (selectedAction === BulkActionType.DELETE) {

                    let turnoverInfo = {};
                    if (person.turnoverType || person.turnoverDate) {
                        turnoverInfo = {
                            turnoverType: person.turnoverType,
                            turnoverDate: Dates.fromAPIFormat(person.turnoverDate, APISettings.apiDateFormat)
                        };
                    }

                    People.delete(person, turnoverInfo, callback);
                } else {
                    person.processingFailures.push('UNKNOWN_ACTION');
                    reject('Invalid action');
                }

            });

        });

        await Promise.all(promises).then((processedPersons) => {
            setUploadedPersons(processedPersons);
        });


    };

    const renderRightPanel = () => {

        if (activeStep === 0) {
            return (
                <BulkActionSelectionPanel/>
            );
        } else if (activeStep === 1) {

            return (
                <BulkActionDownloadAndUploadPanel
                    progress={verificationProgress}
                    handleUploadFile={handleUploadFile}/>
            );
        } else if (activeStep === 2) {
            return <BulkActionReviewAndProcessPanel
                persons={uploadedPersons}
                currentAction={selectedAction}
                onDeleteUser={handleDeleteUser}
                onSaveUser={handleSaveUser}
            />
        }

    }

    return (
        <Box sx={{ width: '100%' }}>
            <WhiteBgPaper>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            <Step key={0} completed={activeStep > 0}>
                                <StepLabel optional={renderSelectionActionChange(selectedAction)}>
                                    {$translate.instant('BULK_EMPLOYEE_PROCESSING_SELECT_ACTION')}
                                </StepLabel>
                                <StepContent>
                                    <BulkActionSelectionStep
                                        currentAction={selectedAction}
                                        onActionSelected={handleSelectAction}
                                    />
                                </StepContent>
                            </Step>

                            <Step key={1} completed={activeStep > 1}>
                                <StepLabel optional={renderPeopleLoadedActionChange()}>
                                    {$translate.instant('BULK_EMPLOYEE_PROCESSING_SELECTED_LOAD_TEMPLATE')}
                                </StepLabel>
                                <StepContent>
                                    <BulkActionDownloadAndUploadStep
                                        currentAction={selectedAction}
                                        isVerifying={isVerifyng}
                                        totalVerifiedSuccess={verifiedPersonCount.success}
                                        totalVerifiedFailure={verifiedPersonCount.failed}
                                        totalToVerify={uploadedPersonCount}
                                    />
                                </StepContent>
                            </Step>

                            <Step key={2} completed={activeStep > 2}>
                                <StepLabel >{$translate.instant('BULK_EMPLOYEE_PROCESSING_REVIEW_LOADED')}</StepLabel>
                                <StepContent>
                                    <BulkActionReviewAndProcessStep
                                        currentAction={selectedAction}
                                        totalProcessedSuccess={processingPersonCount.success}
                                        totalProcessedFailure={processingPersonCount.failed}
                                        totalToProcess={uploadedPersons.length}
                                        isProcessing={isProcessing}
                                        onProceed={handleProceed}
                                        onRestart={handleRestart}
                                        uploadedPersons={uploadedPersons}
                                    />
                                </StepContent>
                            </Step>
                        </Stepper>

                    </Grid>
                    <Grid item xs={8}>

                        {renderRightPanel()}

                    </Grid>
                </Grid>


            </WhiteBgPaper>


        </Box>
    );
}


angularize(UsersBulkStepper, 'hfUsersBulkStepper', angular.module('happyForceApp'), {});


export default UsersBulkStepper;
