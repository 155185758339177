import {BulkActionPerson, CellProps} from '../../types';
import {getService} from '../../../../../../../migration_utils/react-in-angular';
import {Box, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import {isBulkActionPerson} from '../../utils';

const BulkActionRecordStatus = ({person, bulkActionType}: CellProps) => {

    const $translate = getService('$translate');
    const [bulkActionPerson, setBulkActionPerson] = React.useState<BulkActionPerson | null>(null);




    useEffect(() => {
        if ( isBulkActionPerson(person)) {
            setBulkActionPerson(person);
        } else {
            console.log('person', person)
        }

    }, [person]);

    const renderFailures = () => {
        if (bulkActionPerson && bulkActionPerson?.recordFailures?.length > 0) {
            return (
                <Typography variant={'caption'}>
                    {bulkActionPerson.recordFailures.map((failure) => $translate.instant('BULK_ACTION_RECORD_FAILURE_' + failure)).join(', ')}
                </Typography>
            )
        } else {
            return null;
        }
    }


    const renderRecordStatus = () => {

        if (bulkActionPerson?.recordFailures?.length > 0) {
            return (
                <Tooltip title={renderFailures()}>
                    <ErrorOutlineIcon color={'error'} />
                </Tooltip>

            )
        } else {
            return (
                <CheckCircleOutlineIcon color={'success'} />
            )
        }
    }

    const renderProcessingStatus = () => {



        if (bulkActionPerson?.processingFailures?.length > 0) {
            return (
                <Tooltip title={
                    <Typography variant={'caption'}>
                        {bulkActionPerson.processingFailures.map((failure) => $translate.instant(failure)).join(', ')}
                    </Typography>
                }>
                    <RunningWithErrorsOutlinedIcon color={'error'} />
                </Tooltip>
            )
        } else {
            return (<Tooltip title={
                <Typography variant={'caption'}>
                    {$translate.instant('BULK_ACTION_PROCESSING_RECORD_NOT_YET_PROCESSED')}
                </Typography>
            }>
                <CircleOutlinedIcon color={'disabled'} />
            </Tooltip>);
        }
    }

    return (
        <Box sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
        })}>
            {renderRecordStatus()}
            {renderProcessingStatus()}
        </Box>
    )

}

export default BulkActionRecordStatus;
