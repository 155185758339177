var UserUtils =  {};

UserUtils.getUserNameAcronym = function (userName, notFoundString, maxLetters) {

    if (userName) {
        var matches = userName.match(/\b(\w)/g);
        var acronym = matches ? matches.join('') : userName;

        if (maxLetters)
        {return acronym.substring(0, maxLetters);}
        else
        {return acronym;}
    } else if (notFoundString) {
        return notFoundString;
    } else {
        return '';
    }
};

export default UserUtils;
