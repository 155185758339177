import React from 'react';
import { Tooltip } from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';
const CommentInappropriateAction = ({ comment, replyOf, inappropriateCallback, selfHandle, showActionLabel }) => {
    const $translate = getService('$translate');
    const toaster = getService('toaster');

    const Comments = getService('Comments');
    const CommentInappropriateModal = getService('CommentInappropriateModal');
    const ErrorSvrc = getService('ErrorSvrc');

    const showInappropriate = (event) => {
        event.stopPropagation();
        CommentInappropriateModal.showModal(comment, replyOf, (params) => {
            if (selfHandle && params.hasChange) {
                const commentId = params.isReplyOf ? params.isReplyOf : params.commentId;
                const replyId = params.isReplyOf ? params.commentId : null;

                Comments.inappropiate(commentId, replyId, params, (err, updatedComment) => {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        inappropriateCallback && inappropriateCallback(err, null);
                    } else {
                        const message = updatedComment.inappropriate ? $translate.instant('INAPPROPRIATE_DONE') : $translate.instant('UNMARKED_INAPPROPRIATE');
                        toaster.pop('success', null, message);
                        inappropriateCallback && inappropriateCallback(null, updatedComment);
                    }
                });
            } else if (inappropriateCallback) {
                inappropriateCallback(null, comment);
            }
        });
    };

    return (
        <span>
            {!comment.privated ? (
                <a onClick={showInappropriate}
                    style={{display:'flex'}}
                    color={comment.inappropriate || comment.inappropriates > 0 ? 'error' : 'default'}>
                    <i className="fa fa-ban"/>
                    <span className="m-l-xs">
                        {comment.inappropriates || 0}
                        {showActionLabel && $translate.instant('COMMENT_ACTION_INAPPROPRIATE_MANAGE')}
                    </span>
                </a>
            ) : (
                <Tooltip title={$translate.instant('COMMENT_IS_PRIVATED_MODERATE_ACTION_HINT')} placement="top">
                    <span className={'warm-grey'}>
                        <i className="fa fa-ban"/>
                    </span>
                </Tooltip>
            )}
        </span>
    );
};

CommentInappropriateAction.propTypes = {
    comment: PropTypes.object.isRequired,
    replyOf: PropTypes.string,
    inappropriateCallback: PropTypes.func,
    selfHandle: PropTypes.bool,
    showActionLabel: PropTypes.bool
};

angularize(CommentInappropriateAction, 'hfCommentInappropriateAction', angular.module('happyForceApp'), {
    comment: '<',
    replyOf: '<',
    inappropriateCallback: '=',
    selfHandle: '<',
    showActionLabel: '<'
});

export default CommentInappropriateAction;
