'use strinct';

function BreadcumsCtrl() {
    var _this = this;

    this.$onInit = function () {

        _this.removeFilter = function (segment) {
            var index = _this.items.indexOf(segment);
            _this.items.splice(index, 1);

            if (_this.onChange) {
                _this.onChange(segment);
            }
        };
    };
}

const hfBreadcums = angular.module('happyForceApp')
    .directive('hfBreadcums', function () {
        return {
            scope: {
                items: '=',
                onChange: '=?'
            },
            template: require('./breadcums.html'),
            controller: BreadcumsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfBreadcums;
