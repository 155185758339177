

// Import styles
import styles from './index.less';

import * as EasyPieChart from 'easy-pie-chart';
import Highcharts from 'highcharts';
import * as firebase from 'firebase';

import heatmap from 'highcharts/modules/heatmap';
import boost from 'highcharts/modules/boost';
import customEvents from 'highcharts-custom-events';
import more from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import varyWide from 'highcharts/modules/variwide';
import groupedCategories from 'highcharts-grouped-categories';


// Initialize Highcharts modules
more(Highcharts);
heatmap(Highcharts);
boost(Highcharts);
customEvents(Highcharts);
solidGauge(Highcharts);
varyWide(Highcharts);
groupedCategories(Highcharts);


// Initialize Firebase
var config = {
    apiKey: 'AIzaSyDHC9sChpU_eGXpkXIF5Gk1g2LlDpf8vxM',
    authDomain: 'happyforce-dashboard.firebaseapp.com',
    databaseURL: 'https://happyforce-dashboard.firebaseio.com',
    projectId: 'happyforce-dashboard',
    storageBucket: 'happyforce-dashboard.appspot.com',
    messagingSenderId: '309293299735'
};
firebase.initializeApp(config);


// This is a nasty trick:
window.Highcharts = Highcharts;
window.EasyPieChart = EasyPieChart;


// Import main JS files
import app from './main';
import configureApp from './config/config';


import filters from './filters/_exports';
import components from './shared/components/_exports';
import views from './components/_views';

import './shared/services/_exports';
import './shared/new-components/_exports';

import {GoogleCharts} from 'google-charts';
import * as Sentry from '@sentry/browser';
import Charts from './utilities/charts.utilities';


Sentry.init({
    dsn: 'https://291d0e1d18020f1f3e5ca9b192ee8369@o4506269099032576.ingest.sentry.io/4506676079099904',
    integrations: [
        /*new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),*/
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    attachStacktrace: true,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});




// Load some libs & initialize them:

GoogleCharts.load('1.0', {'packages': ['orgchart']});

//if (typeof google !== 'undefined') google.load('visualization', '1.0', { packages: ['orgchart'] });

window.HFinfo = {
    version: '/*@@srcVersion@@*/',
    date: '/*@@srcDate@@*/'
};


window.$injector = null;


// Configure the views


/*
  This is the entry point of the application.
  Once the application is loaded, it will start the configuration process.
 */
app.run(configureApp).run(
    ['$rootScope', '$state', '$stateParams', '$transitions',
        function ($rootScope,   $state,   $stateParams, $transitions) {
            $transitions.onSuccess({}, function(transition) {
                var fromState = transition.from();
                var fromParams = transition.params('from');

                $rootScope.previousState = fromState;
                $rootScope.previousStateParams = fromParams;
            });

            // It's very handy to add references to $state and $stateParams to the $rootScope
            // so that you can access them from any scope within your applications.For example,
            // <li ng-class="{ active: $state.includes('contacts.list') }"> will set the <li>
            // to active whenever 'contacts.list' or one of its decendents is active.

            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
        }

    ]
).run(['$injector', function(_$injector) { window.$injector = _$injector }])


console.log('Application loaded with name ' + app.name);


export const moduleName = app.name;
