'use strict';

import React, { useState, useEffect } from 'react';
import {angularize} from 'reactInAngular';

const ScaleChart = ({ slices, value }) => {
    const [slicePercent, setSlicePercent] = useState(0);
    const [calculatedValue, setCalculatedValue] = useState(0);

    const calculateValue = (newSlicePercent) => {
        if (typeof value !== 'undefined') {
            const roundedValue = Math.round(value);
            const sliceIndex = slices.findIndex(slice => {
                return roundedValue >= slice.range[0] && roundedValue <= slice.range[1];
            });

            if (sliceIndex !== -1) {
                const min = slices[sliceIndex].range[0];
                const max = slices[sliceIndex].range[1];
                const newValue = (sliceIndex * newSlicePercent) + (newSlicePercent * ((value - min) / (max - min)));
                setCalculatedValue(newValue);
            } else {
                console.error('value ' + value + ' out of range ' + slices);
                setCalculatedValue(0);
            }
        }
    };

    useEffect(() => {
        if (slices) {
            const newSlicePercent = 100 / slices.length;
            setSlicePercent(newSlicePercent);
            calculateValue(newSlicePercent);
        }
    }, [slices, value]);



    return (
        <div className="scale-chart p-t-sm">
            <div className="row">
                <div className="col-xs-12">
                    <i className="fa fa-caret-down" style={{ marginLeft: `calc(${calculatedValue}% - 5px)` }}></i>
                </div>
            </div>
            <div className="progress mini-progress">
                {slices.map(slice => (
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${slicePercent}%`, backgroundColor: slice.color }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        key={slice.label}
                    />
                ))}
            </div>

            <div className="progress small-style leyend">
                {slices.map(slice => (
                    <div
                        className="progress-bar progress-transparent"
                        role="progressbar"
                        style={{ width: `${slicePercent}%` }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        key={slice.label}
                    >
                        <p className="mini-style">{slice.range.join(' - ')}</p>
                        <p className="mini-style">{slice.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};


angularize(ScaleChart, 'scaleChart', angular.module('happyForceApp'), {
    slices: '<',
    value: '<'
});

export default ScaleChart;



