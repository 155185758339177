'use strict';

function SurveySettingsEdit($scope, $modalInstance, survey, isEdit, Languages, selectedLanguage, $translate) {
    'ngInject'
    var _this = this;

    _this.isEdit = isEdit;
    _this.selectedLanguage = selectedLanguage;

    if (_this.selectedLanguage) {
        _this.modalSubtitle = $translate.instant('SURVEY_TEMPLATE_SETTINGS_DESCRIPTION_TRANSLATION',
            { language: Languages.getNameByCode(_this.selectedLanguage) });
    } else {
        _this.modalSubtitle = $translate.instant('SURVEY_TEMPLATE_SETTINGS_DESCRIPTION');
    }


    if (survey) {
        _this.survey = angular.copy(survey);
    } else {
        _this.survey = {
            title: {
                defaultValue: '',
                langValues: {}
            },
            description: {
                defaultValue: '',
                langValues: {}
            }
        };
        _this.isEdit = false;
    }

    $scope.ok = function () {
        $modalInstance.close(_this.survey);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('close');
    };
}

const SurveySettingsEditCtrl = angular.module('happyForceApp').controller('SurveySettingsEditCtrl', SurveySettingsEdit);

export default SurveySettingsEditCtrl;
