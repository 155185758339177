import React from 'react';
import { Tooltip } from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';

const CommentReplyWithAnnouncementAction = ({ comment, commentUpdatedCallback }) => {
    const AnnouncementReplyToCommentModal = getService('AnnouncementReplyToCommentModal');
    const Comments = getService('Comments');
    const $translate = getService('$translate');

    const announcementCallback = (announcementId) => {
        // If there is a callback and the announcement ID, reload the comment
        if (commentUpdatedCallback) {
            Comments.getOne(comment.id, (err, result) => {
                commentUpdatedCallback(err, result);
            });
        }
    };

    const showAnnouncement = (event) => {
        event.stopPropagation();
        AnnouncementReplyToCommentModal.showModal(comment.id, announcementCallback);
    };

    return (
        <span>
            {!comment.privated ? (
                <a  onClick={showAnnouncement} style={{display:'flex'}}>
                    <i className="fa fa-bullhorn"></i>
                    {comment.announcementId && <span className="m-l-xs">(1)</span>}
                </a>
            ) : (
                <Tooltip title={$translate.instant('COMMENT_IS_PRIVATED_REPLY_WITH_ANNOUNCEMENT_ACTION_HINT')} placement="top">
                    <span className={'warm-grey'} style={{display:'flex'}}>
                        <i className="fa fa-bullhorn"></i>
                        {comment.announcementId && <span className="m-l-xs">(1)</span>}
                    </span>
                </Tooltip>
            )}
        </span>
    );
};

CommentReplyWithAnnouncementAction.propTypes = {
    comment: PropTypes.object.isRequired,
    commentUpdatedCallback: PropTypes.func
};

angularize(CommentReplyWithAnnouncementAction, 'hfCommentReplyWithAnnouncementAction', angular.module('happyForceApp'), {
    comment: '<',
    commentUpdatedCallback: '='
});

export default CommentReplyWithAnnouncementAction;
