'use strict';
import template from './advisor_surveys_category_edit.html';

function AdvisorsSurveyCategoriesEditCtrl($scope, ErrorSvrc, $modalInstance, selectedCategory, $translate, Languages, Analytics, AuthSrvc, SurveyAdvisors) {
    'ngInject';
    $scope.selectedCategory = selectedCategory;
    $scope.errorMsg = '';
    $scope.AuthSrvc = AuthSrvc;
    $scope.allLanguages = Languages.list;

    $scope.ok = function () {
        $scope.executing = true;
        if ($scope.selectedCategory.id) {
            SurveyAdvisors.updateCategory($scope.selectedCategory.id, $scope.selectedCategory,  function (err, response) {
                if (err) {
                    $scope.errored = true;
                    $scope.errorMsg = ErrorSvrc.getErrorMessage(err);
                    $scope.executing = false;
                } else {

                    $modalInstance.close(response);
                }

            });
        } else {
            SurveyAdvisors.createCategory($scope.selectedCategory,  function (err, response) {
                if (err) {
                    $scope.errored = true;
                    $scope.errorMsg = ErrorSvrc.getErrorMessage(err);
                    $scope.executing = false;
                } else {
                    $modalInstance.close(response);
                }

            });
        }
    };

    $scope.getLanguageName = function (code) {
        console.log('LanguageCode is ' + code);
        return _.find($scope.allLanguages, function (language) { return language.code == code; }).language;
    };

    $scope.getLangKeys = function (langs) {
        if (langs)
         return Object.keys(langs);
        else
         return [];
    };

    $scope.removeLanguage = function (languageCode) {
        delete $scope.selectedCategory.name.langValues[languageCode];
        delete $scope.selectedCategory.description.langValues[languageCode];
    };

    $scope.addLanguage = function (languageCode) {

        if (!$scope.selectedCategory.description) {
            $scope.selectedCategory.description = {
                defaultValue: ''
            };
        }

        if (!$scope.selectedCategory.description.langValues)
         $scope.selectedCategory.description.langValues = {};
        else if ($scope.selectedCategory.description.langValues[languageCode])
         return;

        $scope.selectedCategory.description.langValues[languageCode] = '';

        if (!$scope.selectedCategory.name) {
            $scope.selectedCategory.name = {
                defaultValue: ''
            };
        }

        if (!$scope.selectedCategory.name.langValues)
               $scope.selectedCategory.name.langValues = {};
        else if ($scope.selectedCategory.name.langValues[languageCode])
               return;

        $scope.selectedCategory.name.langValues[languageCode] = '';

    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}

const AdvisorsSurveyCategoryEditModal = angular.module('happyForceApp')
    .factory('AdvisorsSurveyCategoryEditModal',
        function ($uibModal) {
            'ngInject';
            var methods = {};
            methods.showEdit = function (category, callback) {
                $uibModal.open({
                    backdrop: 'static',
                    template: template,
                    controller: AdvisorsSurveyCategoriesEditCtrl,
                    resolve: {
                        selectedCategory: function () {
                            return angular.copy(category);
                        }
                    }
                }).result.then(function (data) {
                    callback(data);
                });
            }


            return methods;

        }
    );


export default AdvisorsSurveyCategoryEditModal;
