import React, { useState, useEffect } from 'react';
import {angularize, getService} from 'reactInAngular';
import {HfBannerReact} from '../../../../shared/new-components/hf-banner/hfBanner';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import EngagementScoresSummaryRow from '../../components/scores_summary_box/components/score_sumary_row';
// Import or define other necessary components and services

const ScoreStatusEmptyState = () => {
    const [disabledCount, setDisabledCount] = useState(0);
    const [allDisabled, setAllDisabled] = useState(false);

    const ErrorSvrc = getService('ErrorSvrc');
    const AuthSrvc = getService('AuthSrvc');
    const Scores = getService('Scores');

    const $translate = getService('$translate');

    const hasAdminRoles = AuthSrvc.isADVISOR() || AuthSrvc.isADMIN();

    useEffect(() => {
        Scores.list({}, (err, results) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
             } else {
                const activatedScores = results.filter(score => score.status === 'ACTIVATED');
                const disabledScores = results.filter(score => score.status !== 'ACTIVATED');

                setDisabledCount(disabledScores.length);
                setAllDisabled(activatedScores.length === 0 && disabledScores.length > 0);
            }
        });
    }, []);



    return (
        <div className="row score_status_empty_state">
            <div className="col-xs-12">
                {disabledCount > 0 && !allDisabled && (
                    <HfBannerReact>
                        <>
                            <h4 className="mini-style font-bold">{$translate.instant('SUMMARY_BOX_DISABLED_TITLE')}</h4>
                            <p className="mini-style m-t-xs"
                               dangerouslySetInnerHTML={
                                {__html: $translate.instant('SUMMARY_BOX_DISABLED_DESCRIPTION', {num: disabledCount})}}
                            >
                            </p>
                        </>
                    </HfBannerReact>
                )}

                {allDisabled && (
                    <div className="all-disabled col-xs-12">
                        <EmptyState
                            image="images/no_scores.svg"
                            small={true}
                            message={$translate.instant('SUMMARY_BOX_ALL_DISABLED')}
                            submessage={$translate.instant('SUMMARY_BOX_CONFIGURE_SCORES_DISABLED')}
                        ></EmptyState>
                    </div>
                )}
            </div>
        </div>
    );
};


angularize(ScoreStatusEmptyState, "hfScoreStatusEmptyState", angular.module("happyForceApp"), {});

export default ScoreStatusEmptyState;
