const ManagersActivityService = function (Restangular, Settings) {
    'ngInject';
    var ManagersActivity = Restangular.one('company').one('activity');

    var methods = {};

    methods.list = function (params, callback) {

        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        ManagersActivity.get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    return methods;
};
export default ManagersActivityService;
