import {Person} from '../../../types';
import {Box} from '@mui/material';
import React from 'react';
import {getService} from '../../../../../../../../migration_utils/react-in-angular';
import UserAreaBreadcrumbs from '../../shared/userAreaBreadcrumbs';
import AreaSelectorPopover
    from '../../../../../../../shared/new-components/hf-area-tag-selector/components/AreaSelectorPopover';


interface UserAreaProps {
    person: Person;
    onRowChange: (updatedPerson: Person) => void;
}
const UserArea = ({person, onRowChange} : UserAreaProps ) => {

    const [anchorEl, setAnchorEl] = React.useState(null);


    const $translate = getService('$translate');


    return (


        <Box role={'button'}  sx={{display: 'flex', flexDirection: 'column'}}>

            <Box sx={theme => ({marginTop: theme.spacing(1)})}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}>
                <UserAreaBreadcrumbs hierarchyId={person.hierarchyId}
                    noAreaLabel={$translate.instant('EMPLOYEE_SELECT_AREA')}/>
            </Box>

            <AreaSelectorPopover
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                allowClear={true}
                hierarchyId={person.hierarchyId}
                updateHierarchyId={
                    (newHierarchyId: string) => {
                        setAnchorEl(null);
                        onRowChange({...person, hierarchyId: newHierarchyId});
                    }
                }/>


        </Box>
    );
}

export default UserArea;
