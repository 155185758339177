'use strict';

import hfPillButton from './pill-button.component';

function PillPanel($scope) {
    'ngInject';
    var _this = this;
    _this.pills = [];
    _this.pillActive = 0;

    // Add Step
    _this.addPill = function (pill) {
        var pillOrder = _this.pills.length;
        pill.number = _this.pills.length + 1;
        if (!_this.pills.length) pill.selected = true;
        _this.pills[pillOrder] = pill;
        return pillOrder;
    };

    // Remove Step
    _this.removePill = function (pill) {
        var index = _this.pills.indexOf(pill);
        if (index > 0) {
            _this.pills.splice(index, 1);
        }
    };

    _this.selectPill = function (pillIndex) {
        _this.pillActive = pillIndex;
    };

    this.$onInit = function () {



        // On stepChange
        ['pillActive'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal) {

                if (!_this.pills.length)
                    return;
                _this.pills.forEach(function (pill) {
                    pill.selected = false;
                });

                _this.pills[newVal].selected = true;
            });
        });



    };
}

//Directive used to set metisMenu and minimalize button
const hfPillPanel = angular.module('happyForceApp').directive('hfPillPanel', function () {
    return {
        scope: {
            className: '@'
        },
        restrict: 'E',
        template: require('./pill-panel.html'),
        controller: PillPanel,
        transclude: true,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfPillPanel;
