'use strict';

function QuestionFeedbackItemCtrl($scope, $translate, toaster, ErrorSvrc)  {
    'ngInject';
    var _this = this;



    _this.commentUpdated = function (err, updatedComment) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            _this.comment = updatedComment;
        }
    };

    this.$onInit = function () {

        // Conversation link modal, will be only displayed based on setting value
        if (typeof _this.showConversationLink != 'undefined') {
            _this.displayConversationLink = _this.showConversationLink;
        } else {
            _this.displayConversationLink = true;
        }

        // Save the vote image for latter reference
        /*  if (_this.comment && _this.comment.votes) {
              _this.voteImage = _this.comment.votes[0].imageUrl;
          }

          // Generate the feedback type label:
          if (_this.comment && _this.comment.feedbackType) {
              _this.feedbackTypeLabel = $translate.instant('FEEDBACK_TYPE_RESULT_' +  _this.comment.feedbackType);
          }*/

        //_this.feedbackTypeLabel = 'JODER';

    };

}

const hfQuestionFeedbackItem  = angular.module('happyForceApp').directive('hfQuestionFeedbackItem', function () {
    return {
        scope: {
            comment: '<',
            showConversationLink: '<?',
            showReplyWithAnnouncementLink: '<?',
            hideHeader: '<',
            size: '<'
        },
        restrict: 'E',
        template: require('./question_feedback_item.html'),
        controller: QuestionFeedbackItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfQuestionFeedbackItem;

