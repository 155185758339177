import React from 'react';
import {Box, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {getService} from 'reactInAngular';
import {BulkActionType} from '../../../../components/user_listing/components/users_table';




type BulkActionSelectionStepProps = {
    onActionSelected: (action: BulkActionType) => void;
    currentAction: BulkActionType | null;
}

const BulkActionSelectionStep=({onActionSelected, currentAction}: BulkActionSelectionStepProps) => {

    const $translate = getService('$translate')

    return (
        <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1)})}>

            <RadioGroup
                defaultValue={currentAction}
                name="radio-buttons-group"
            >
                <FormControlLabel value={BulkActionType.INVITE} control={<Radio />}
                    onChange={() => {onActionSelected(BulkActionType.INVITE)}}
                    label= {
                        <Typography variant={'h6'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_INVITE')}</Typography>
                    }
                />
                <FormControlLabel value="UPDATE" control={<Radio />}
                    onChange={() => {onActionSelected(BulkActionType.UPDATE)}}
                    label= {
                        <Typography variant={'h6'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_UPDATE')}</Typography>
                    }
                />
                <FormControlLabel value="DELETE" control={<Radio />}
                    onChange={() => {onActionSelected(BulkActionType.DELETE)}}
                    label= {
                        <Typography variant={'h6'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_DELETE')}</Typography>
                    }
                />
            </RadioGroup>

        </Box>
    );
}

export default BulkActionSelectionStep;
