import {Box, LinearProgress, Tooltip, Typography} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import * as React from 'react';
import {InsightInt} from '../../types';
import InsightRiskBar from './InsightRiskDistribution';
import Icon from '@mui/material/Icon';
import {ImageIcon} from '../../../../../shared/new-components/hf-styled-components/ImageIcon';
import Style from '../../../../../utilities/style.utils';
import InfoIcon from '@mui/icons-material/Info';


interface InsightItemProps {
    insight: InsightInt,
    onInsightClick: (insight: InsightInt) => void
    isSelected: boolean
}




const InsightItem = ({insight , onInsightClick, isSelected} : InsightItemProps) => {

    return (
        <Box sx={ theme => ({
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            p: theme.spacing(2),
            borderRight: '1px solid #e2e7e9',
            borderLeft: `2px solid ${isSelected ? theme.palette.primary.main : '#e2e7e9'}`,
        })}

        onClick={() => onInsightClick(insight)}

        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                flexShrink: 0 }}>

                <Avatar sx={{ bgcolor: Style.chartBg }}>
                    <ImageIcon size={'medium'} src={insight?.iconURL}></ImageIcon>
                </Avatar>

                <Box sx={{
                    overflow: 'hidden',
                    marginLeft: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    minWidth: 0,
                    gap: 1,
                    alignItems: 'left',
                    flexGrow: 1,
                    textAlign: 'left',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '& > *': {
                        minWidth: 'clamp(0px, (240px + 1px - 100%) * 999, 100%)',
                    }}}>
                    <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                        <Typography
                            variant="h6"
                            component="div"
                        >
                            {insight.title}
                        </Typography>
                        <Tooltip title={insight?.description}>
                            <InfoIcon fontSize={'small'} color="primary" />
                        </Tooltip>
                    </Box>
                </Box>

            </Box>
            <Box>
                <Typography
                    color="text.secondary"
                    variant="caption"
                >
                    {insight.theme}
                </Typography>
            </Box>

            <Box sx={ theme => ({
                paddingTop: theme.spacing(2),
            })}>
                <InsightRiskBar insight={insight}/>
            </Box>


        </Box>
    );
};

export default InsightItem;
