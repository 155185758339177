

import Participation from '../../../utilities/participation.utilities';
import Style from '../../../utilities/style.utils';
import Charts from '../../../utilities/charts.utilities';
import WizardTopicOperations from '../topics.wizard.operations';
import TopicOperationsModal from '../topics.wizard.operations';

const QuestionBox = function ( AuthSrvc, CompanySvrc, $translate, CronService, Analytics, ErrorSvrc, TopicOperationsModal, Stats, $state) {
    'ngInject'

    const _this = this;

    _this.AuthSrvc = AuthSrvc;

    _this.chartConfig = null;

    _this.loading = true;
    _this.hasEnoughActiveEmployees = true;

    CompanySvrc.getUserCompany(
        function (err, company) {
            if (err)
            {_this.companyName = $translate.instant('COMPANY');}
            else
            {_this.companyName = company.name;}
        });


    _this.editQuestion = function () {
        TopicOperationsModal.editQuestion(_this.question, function () {
            _this.updateCallback();
        });
    };

    _this.archiveQuestion = function () {
        TopicOperationsModal.archiveQuestion(_this.question.id, function () {
            _this.updateCallback();
        });
    };

    _this.changeActiveState = function () {
        TopicOperationsModal.changeActiveState(_this.question, function () {
            _this.updateCallback();
        });
    };


    _this.viewQuestion = function () {
        Analytics.trackEvent('topics', 'detail');
        $state.go('topics.detail', { selectedTopic: _this.question.id });
    };


    function buildChartConfig(question, results) {
        var labels = [];
        var values = [];
        var colors = [];
        var pos = 0;
        var showTooltip = true;

        if (results.periodParticipants > 0) {
            _.forEach(question.resultValues, function (option) {
                labels.push($translate.instant(option.label.defaultValue));
                values.push(Participation.calculateParticipation(results.periodParticipants, results.ratings[option.id]));
                if (option.color)
                {colors.push(option.color);}
                else
                {colors.push(Style.questionColors[pos++]);}
            });
        } else {
            labels.push('AA');
            values.push(100);
            colors.push(Style.chartBg);
            showTooltip = false;
        }


        if (question && question.questionType !== 'RANGE')
        {return Charts.topicConfig(labels, values, colors, showTooltip);}
        else
        {return Charts.rangeTopicConfig(labels, values, colors, false);}
    }

    this.$onInit = function () {

        // Prepare the question for render:
        if (_this.question && _this.question.activationRule && _this.question.activationRule.ruleType === 'SINGLE') {
            _this.fromDate = _this.question.activationRule.from;
            _this.toDate = _this.question.activationRule.to;
        } else if (_this.question && _this.question.activationRule && _this.question.activationRule.ruleType === 'RECURRENT') {
            _this.cronResumen = CronService.fromExpression(_this.question.activationRule.cronExpression, _this.question.activationRule.duration);
        }

        //Now, get the stats
        Stats.questions.get({
            questionId: _this.question.id
        }, function (err, stats) {
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    _this.hasEnoughActiveEmployees = false;
                } else {
                    ErrorSvrc.showErrorModal(err);
                }

                _this.loading = false;
                return console.error(err);
            }

            Analytics.trackEvent('topics', 'load_questions');

            _this.numResults = stats.results.length;
            var results = stats.periodResults;
            _this.expectedParticipants = results.expectedParticipants;
            _this.periodParticipants = results.periodParticipants;
            _this.participation = Participation.calculateParticipation(_this.expectedParticipants, _this.periodParticipants);
            _this.periodIndex = Number(results.periodIndex).toFixed(1);
            _this.chartConfig = buildChartConfig(_this.question, results);
            _this.loading = false;

            //if (callback) callback();
        });

    };

};

const hfQuestionBox = angular.module('happyForceApp').directive('hfQuestionBox', function () {
    return {
        scope: {
            question: '=',
            index: '=',
            updateCallback: '&'
        },
        restrict: 'E',
        template: require('./question-box.html'),
        controller: QuestionBox,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfQuestionBox;
