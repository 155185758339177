'use strict';
export default function PeopleService(Restangular, $log, $translate, Settings) {
    'ngInject';

    var Company = Restangular.one('company');

    var methods = {};

    methods.find = function (params, callback, error) {
        Company.one('people').one('find').get(params).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    methods.get = function (params, callback) {
        Company.one('people').get(params).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.getById = function (userId, callback) {
        Company.one('people').get({ id: userId }).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.getProfile = function (userId, callback) {
        Company.one('people').one('profile').get({ id: userId }).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.invite = function (user, callback) {
        Company.one('people').customPOST(user).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.resend = function (user, callback) {
        Company.one('people').customPOST({}, 'resendInvite', { id: user.id }).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.update = function (user, callback) {
        var params = {};
        if (user.companyId) {
            params.companyId = user.companyId;
        };

        Company.customPUT(user, 'people', params).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.engageInactives = function (callback) {
        Company.one('people').one('inactives').one('engage').post().then(function () {
            callback(null, true);
        }, function (err) {

            callback(err);
        });
    };

    methods.reminder = function (useSenderName, subject, content, target, segmentationParams, callback) {
        Company.one('people').one('reminder').customPOST({ subject: subject, content: content, signWithUserName: useSenderName }, target, segmentationParams, {}).then(function () {
            callback(null);
        }, function (err) {

            callback(err);
        });
    };

    methods.getTemplate = function (type, callback) {
        Company.one('people').one('template').one(type).withHttpConfig({ responseType: 'blob' }).get({}).then(function (blob) {
            callback(null, blob);
        }, function (err) {

            callback(err);
        });
    };

    function extract(users, item) {
        return users.map(function (user) {
            return user[item];
        });
    }



    methods.delete = function (user, turnoverData, callback) {
        var params = {};
        if (user.id) {
            params.id = user.id;
        } else {
            params.email = user.email;
        }

        if (turnoverData) {
            params.turnoverDate = moment(turnoverData.turnoverDate).format(Settings.apiDateFormat);
            params.turnoverType = turnoverData.turnoverType;
        }

        if (user.companyId) {
            params.companyId = user.companyId;
        };

        Company.one('people').customDELETE('', params).then(function (user) {
            callback(null, user);
        }, function (err) {
            callback(err);
        });
    };

    methods.reset = function (users, callback, error) {
        var params = {};
        if (users.companyId) {
            params.companyId = users.companyId;
        };

        Company.one('employees').customPOST({ ids: extract(users, 'id') }, 'reset', {}, {}).then(callback, error);
    };

    methods.reSubscribeForMail = function (user, callback) {
        Company.one('people').customPOST({}, 'resubscribe', { id: user.id }).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };



    methods.update = function (user, callback) {
        var params = {};
        if (user.companyId) {
            params.companyId = user.companyId;
        };

        Company.customPUT(user, 'people', params).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };


    var Stats = Company.one('stats');
    var stats = {};


    stats.get = function (callback) {
        Stats.one('employees').get().then(function (results) {
            callback(null, results);
        }, callback);
    };

    var advisors = {};

    advisors.locate = function (params, callback) {
        Company.one('advisor').one('people').one('locate').get(params).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    advisors.delete = function (user, companyId, callback) {
        var params = {};
        if (user.id) {
            params.id = user.id;
        } else {
            params.email = user.email;
        }

        if (companyId) {
            params.companyId = user.companyId;
        };

        Company.one('advisor').one('people').customDELETE('', params).then(function (user) {
            callback(null, user);
        }, function (err) {
            callback(err);
        });
    };

    methods.stats = stats;
    methods.advisors = advisors;

    methods.genders = [{
        label: $translate.instant('EMPLOYEE_GENDER_MAN'),
        value: 'MAN'
    },
        {
            label: $translate.instant('EMPLOYEE_GENDER_WOMAN'),
            value: 'WOMAN'
        },
        {
            label: $translate.instant('EMPLOYEE_GENDER_OTHER'),
            value: 'OTHER'
        }];

    methods.leaveReasons = [{
        label: $translate.instant('VOLUNTARY_FUNCTIONAL'),
        value: 'VOLUNTARY_FUNCTIONAL'
    },
        {
            label: $translate.instant('INVOLUNTARY'),
            value: 'INVOLUNTARY'
        },
        {
            label: $translate.instant('VOLUNTARY_DYSFUNCTIONAL_AVOIDABLE'),
            value: 'VOLUNTARY_DYSFUNCTIONAL_AVOIDABLE'
        },
        {
            label: $translate.instant('VOLUNTARY_DYSFUNCTIONAL_UNAVOIDABLE'),
            value: 'VOLUNTARY_DYSFUNCTIONAL_UNAVOIDABLE'
        }];


    methods.getRoles = function () {
        return [
            { id: 'EMPLOYEE', label: $translate.instant('EMPLOYEE') },
            { id: 'MANAGER', label: $translate.instant('MANAGER') },
            { id: 'ADMIN', label: $translate.instant('ADMIN') }
        ];
    };


    methods.notificationTypes = [
        {
            id: 'PRIVATE_COMMENT_RECEIVED',
            label: $translate.instant('PRIVATE_COMMENT_RECEIVED')
        },
        {
            id: 'INAPPROPRIATE_COMMENT_RECEIVED',
            label: $translate.instant('INAPPROPRIATE_COMMENT_RECEIVED')
        },
        {
            id: 'QUESTION_FINISHED',
            label: $translate.instant('QUESTION_FINISHED')
        }
    ];

    methods.validatePassword = function (password, repeatPassword) {
        if (!password || password.length < 6) {
            return { errorCode: 1, errorMessage: $translate.instant('ACTIVATION_PASSWORD_SMALL') };
        } else if (!repeatPassword || password != repeatPassword) {
            return { errorCode: 2, errorMessage: $translate.instant('EDIT_PASS_ERROR') };
        } else {
            return null;
        }
    };

    return methods;

};
