'use strinct';

function SegmentBreadcumsCtrl(Characteristics,  $scope, $uibModal, Languages) {
    'ngInject'

    var _this = this;

    $scope.selectedSegments = [];

    _this.selectSegments = function () {
        $uibModal.open({
            template: require('../segments-selector/segments-selector.html'),
            controller: 'SegmentsSelectorCtrl',
            controllerAs: '$ctrl',
            size: 'mini',
            resolve: {
                characteristicId: function () {
                    return null;
                },
                checked: function () {
                    return $scope.selectedSegments;
                }
            }
        }).result.then(function (data) {
            $scope.selectedSegments = data;
        }, function (data) {

            console.log('error', data);
        });
    };

    this.$onInit = function () {



        Characteristics.getAll(function (err, data) {
            if (err) {
                console.error('Error loading Characteristics (segment_breadcums)', err);
            } else {
                if (!$.isEmptyObject(_this.segments)) {
                    var fullSegments = [];
                    $scope.selectedSegments = _this.segments.map(function (segment) {
                        var fullSegment = data.filter(function (charac) {
                            return charac.id === segment.characteristicId;
                        });

                        if (fullSegment) {
                            fullSegment = fullSegment[0];
                            var selectorSegment = {
                                id: fullSegment.id,
                                questionType: fullSegment.questionType
                            };

                            selectorSegment.description = fullSegment.description;
                            if (segment.questionType === 'BOOLEAN') {
                                const textValue = segment.value ? fullSegment.yesValue : fullSegment.noValue;
                                selectorSegment.textValue = Languages.getTranslationFromUserLanguage(textValue);
                            } else if (segment.questionType === 'LIST') {
                                var value = fullSegment.values.filter(function (_value) {
                                    return _value.id === segment.value;
                                })[0];

                                selectorSegment.textValue = Languages.getTranslationFromUserLanguage(value.value);
                                selectorSegment.value = value.id;
                            } else if (segment.questionType === 'DATE') {
                                var range = fullSegment.ranges.filter(function (_range) {
                                    return _range.id === segment.value;
                                })[0];

                                selectorSegment.textValue = range.name;
                                selectorSegment.value = value.id;
                            }

                            return selectorSegment;
                        }
                    });
                }
            }
        });

        $scope.$watch(
            'selectedSegments',
            function (n, o) {
                if (!n || n === o) return;

                _this.segments = [];

                if ($scope.selectedSegments) {
                    $scope.selectedSegments.forEach(function (segmentPair) {

                        _this.segments.push({
                            questionType: segmentPair.questionType,
                            characteristicId: segmentPair.id,
                            value: segmentPair.value
                        });

                    });
                }

            },

            true
        );
    };

}

const hfSegmentBreadcums = angular.module('happyForceApp')
    .directive('hfSegmentBreadcums', function () {
        return {
            scope: {
                segments: '=',
                addMoreLabel: '@',
                addFirstLabel: '@'
            },
            template: require('./segment_breadcums.html'),
            controller: SegmentBreadcumsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSegmentBreadcums;
