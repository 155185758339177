import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import EngagementCharts from "../../../engagement.charts";
import Dates from "../../../../../utilities/date.utilities";
import Style from "../../../../../utilities/style.utils";
import HappyLoading from "../../../../../shared/components/_migrated/loader/loading.directive";
import {Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, Link, MenuItem, Select} from "@mui/material";
import {
    CharacteristicName,
    GroupName,
    HierarchyName
} from "../../../../../shared/new-components/hf-segmentation-name/hfSegmentationName";
import async from 'async';
import {getHierarchyName} from '../../../../../shared/services/migrated/hierarchy.service';

const HiPositioningBox = ({ to, navigateToDetail }) => {
    const [positioningBy, setPositioningBy] = useState('HIERARCHIES');
    const [data, setData] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [bubbleChart, setBubbleChart] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noTypes, setNoTypes] = useState(false);

    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const HIService = getService('HIService');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');
    const Languages = getService('Languages');

    const positioningByOptions = [
        { id: 'HIERARCHIES', label: $translate.instant('AREAS') },
        { id: 'GROUPS', label: $translate.instant('GROUPS') },
        { id: 'CHARACTERISTICS', label: $translate.instant('SEGMENTS') }
    ];

    const computeColor = (count) => {
        const remainder = count % Style.colors.length;
        return Style.colors[remainder === 0 ? Style.colors.length - 1 : remainder - 1];
    };

    const renderChart = () => {
        setAllSelected(data.every(item => item.selected));
        setBubbleChart(EngagementCharts.scorePositioningChart(data, 0, 100, 'Happiness Index', 'HI'));
    };

    const getSegmentationName = (segment, callback) => {
        if (positioningBy === 'HIERARCHIES') {
            getHierarchyName(segment.hierarchyId, (err, name) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, name);
                }
            });
        } else if (positioningBy === 'GROUPS') {
            callback(null, Groups.getGroupName(segment.groupId));
        } else if (positioningBy === 'CHARACTERISTICS') {
            let name = '';
            const characteristic = Characteristics.getCharacteristicById(segment.characteristic.characteristicId);
            if (characteristic) {
                name = `${characteristic.description}-${Languages.getTranslationFromUserLanguage(
                    Characteristics.getCharacteristicValueName(segment.characteristic.characteristicId, segment.characteristic.value)
                )}`;
            }
            callback(null, name);
        }
    }


    const loadResults =  () => {
        setLoading(true);
        const params = {
            from: Dates.firstDayOfMonth(to),
            to: Dates.lastDayOfMonth(to)
        };

        HIService.stats.grouping(positioningBy, params,function (err, response) {
            if (err) {
                return ErrorSvrc.showErrorModal(err);
            } else {
                const hasData = response && response.results;
                setNoTypes(response.noTypes);
                if (hasData && response.results.length) {
                    let count = 0;
                    const stream = [];
                    response.results
                        .filter(item => (item.hierarchyId || item.groupId || item.characteristic))
                        .forEach(item => {
                            stream.push(next => {
                                const finalItem = {
                                    ...item,
                                    color: computeColor(++count),
                                    selected: true  // Initially, set all items as selected
                                }
                                getSegmentationName(item, (err, name) => {
                                    if (err) {
                                        next(err, null);
                                    } else {
                                        finalItem.name = name;
                                        next(null, finalItem);
                                    }
                                });
                            });
                        });


                    async.parallel(stream, (err, results) => {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                            return;
                        } else {
                            setData(results);
                        }
                    })
                }
            }
            setLoading(false);
        });


    };

    useEffect(() => {
        renderChart();
    }, [data, allSelected]);

    const selectAll = () => {
        const isSelectAll = !allSelected;
        setData(data.map(item => ({ ...item, selected: isSelectAll })));
    };

    const gotoItemDetail = (item) => {
        const linkParams = { to };

        if (item.hierarchyId) {
            linkParams.hierarchyId = item.hierarchyId;
        } else if (item.groupId) {
            linkParams.groupId = item.groupId;
        } else if (item.characteristic) {
            linkParams.filters = `${item.characteristic.characteristicId}:${item.characteristic.value}`;
        }
        navigateToDetail('engagement.hiDetail', linkParams);
    };

    useEffect(() => {
        loadResults();
    }, [to, positioningBy]);


    const handleSelect = (item) => {

        const newData = data.map(dataItem => {

            const match =
                (item.hierarchyId && item.hierarchyId === dataItem.hierarchyId) ||
                (item.groupId && item.groupId === dataItem.groupId) ||
                (item.characteristic && item.characteristic.characteristicId === dataItem.characteristic.characteristicId && item.characteristic.value === dataItem.characteristic.value);



            if (match) {
                return { ...dataItem, selected: !dataItem.selected };
            }

            return dataItem;
        });
        setData(newData);
    };

    const renderItemForType = (item) => {
        if (item.hierarchyId) {
            return (<HierarchyName  id={item.hierarchyId}/>);
        } else if (item.groupId) {
            return (<GroupName id={item.groupId}/>
            );
        } else if (item.characteristic) {
            return (<CharacteristicName id={item.characteristic.id}
                                        valueId={item.characteristic.value}/>);
        } else {
            // TODO: log error
            return undefined;
        }
    };



    return (
        <section className="enps-positioning-box">

            <div className="row">
                <div className="col-xs-12 col-md-10">
                    <h2>{$translate.instant('ENPS_POSITIONING')}</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-push-9 col-md-3">

                    <FormControl fullWidth>
                        <InputLabel id="positioning-by-label">{$translate.instant('SHOW_BY')}</InputLabel>
                        <Select
                            labelId="positioning-by-label"
                            value={positioningBy}
                            label={$translate.instant('SHOW_BY')}
                            onChange={event => {
                                setPositioningBy(event.target.value);
                            }}
                        >
                            {positioningByOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <HappyLoading loading={loading} message={"Loading data..."}>




                {!noTypes && (
                    <div className="row">
                        <div className="col-xs-3">
                            <div className="small-style m-t-sm">
                                {$translate.instant('ENGAGEMENT_POSITIONING_FILTER')}
                            </div>
                            <div className="small-style m-t-sm">
                                <Link onClick={selectAll}>
                                    {allSelected ? $translate.instant('UNSELECT_ALL') : $translate.instant('SELECT_ALL' ) }
                                </Link>
                            </div>

                            <div className="m-t-xs positioning_chart_selector">
                                <FormGroup>

                                    {data.map(item => (
                                        <FormControlLabel
                                            key={item.hierarchyId || item.groupId || item.characteristic.characteristicId}
                                            control={<Checkbox checked={item.selected} onClick={() =>{handleSelect(item)}} />}
                                            label={renderItemForType(item)}>
                                        </FormControlLabel>

                                    ))}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-xs-9">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={bubbleChart}
                            />
                        </div>
                    </div>
                )}
                {noTypes && !loading && (
                    <div className="row">
                        <div className="col-xs-12">
                            {/* You can include your 'hf-no-segments' component here, translated to React */}
                        </div>
                    </div>
                )}
            </HappyLoading>
        </section>
    );
};

HiPositioningBox.propTypes = {
    to: PropTypes.any.isRequired,
    navigateToDetail: PropTypes.func.isRequired,
};

angularize(HiPositioningBox, "hiPositioningBox", angular.module("happyForceApp"), {
    to: "<",
    navigateToDetail: "<"
});

export default HiPositioningBox;
