'use strict';

import Numbers from "../../../../../utilities/number.utilities";

function ManagersRankingTableCtrl($scope) {
    'ngInject'

    var _this = this;

    this.$onInit = function () {
        _this.dateFormat = 'DD MMM YYYY';


        function getScore(results) {

            var result = null;
            var subfix = '';


            if (_this.type == 'OVERALL_SCORE') {
                result = results.score;
                subfix = 'pts';
            } else if (_this.type == 'ACTIVITY_ALL') {
                if (results.activityTypes) {
                    Object.keys(results.activityTypes).forEach(function (activity) {
                        result += results.activityTypes[activity];
                    });
                }
                subfix = 'pts';
            } else if (_this.type.startsWith('ACTIVITY')) {
                var activity = _this.type.replace('ACTIVITY_', '');
                result = (results.activityTypes && results.activityTypes[activity]) ? results.activityTypes[activity] : null;
                subfix = 'pts';
            } else if (_this.type == 'RESPONSE_OVERALL') {
                result = results.overallResponseTime ? Numbers.roundNumber((results.overallResponseTime / 60 / 60), 1) : null;
                subfix = 'h';
            } else if (_this.type == 'RESPONSE_FIRST_TIME') {
                result = results.firstResponseTime ? Numbers.roundNumber((results.firstResponseTime / 60 / 60), 1) + 'h' : null;
                subfix = 'h';
            } else if (_this.type == 'OVERALL_ENGAGEMENT') {
                result = Numbers.roundNumber(results.engagementScore, 2);
                subfix = 'pts';
            } else if (_this.type.startsWith('SCORE_')) {
                var scoreId = _this.type.split('_')[1];
                result = Numbers.roundNumber(
                    ((results.engagementScores && results.engagementScores[scoreId]) ? results.engagementScores[scoreId] : null), 2);
                subfix = 'pts';
            }

            if (result)
                return result + ' ' + subfix;
            else
                return result;
        }


        function renderTable() {
            if (_this.groupBy == 'WEEK') {
                _this.dateFormat = 'DD MMM YYYY';
            } else if (_this.groupBy == 'MONTH') {
                _this.dateFormat = 'MMM YYYY';
            } else if (_this.groupBy == 'QUARTER') {
                _this.dateFormat = '[q]Q YYYY';
            }

            if (_this.data) {
                _this.results = [];
                _this.rowHeaders = [];

                // Build the column
                _this.columnHeaders = Object.keys(_this.data);
                _this.columnHeaders.sort();

                // Now we have the dates; let's build the unique user Ids:
                _this.columnHeaders.forEach(function (date) {
                    _this.rowHeaders = _.union(_this.rowHeaders, _.map(_this.data[date], function (userRanking) {
                        return userRanking.userId;
                    }));

                });

                _this.rowHeaders.forEach(function (userId) {
                    var individualResults = [];
                    _this.columnHeaders.forEach(function (date) {

                        var userEntry = {};

                        var userDateResults = _.find(_this.data[date], function (userDateResults) {
                            return userDateResults.userId == userId;
                        });
                        if (userDateResults) {
                            userEntry.score = getScore(userDateResults);
                            userEntry.activityTypes = userDateResults.activityTypes;
                        } else {
                            userEntry.score = 0;
                        }

                        individualResults.push(userEntry);
                    });
                    _this.results.push(individualResults);

                });


                // Now, lets build the user


                //  _this.rowHeaders = ['', 'Row 1', 'Row 2'];
                // _this.columnHeaders = ['Column 1', 'Column 2'];

            }
        }

        ['data', 'groupBy', 'type'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;
                renderTable();
            });
        });

        renderTable();

    };
};

const hfManagersRankingTable = angular.module('happyForceApp').directive('hfManagersRankingTable', function () {
    return {
        scope: {
            data: '=',
            groupBy: '=',
            type: '='
        },
        restrict: 'E',
        template: require('./managers_ranking_table.html'),
        controller: ManagersRankingTableCtrl,
        controllerAs: '$managersrankingtable',
        bindToController: true
    };
});

export default hfManagersRankingTable;
