const DateGroupingFormat = function (Settings) {
    'ngInject';
    return function (date, grouping) {
        if (typeof date !== 'undefined') {
            return Date.formatDateFromGrouping(date, grouping, Settings);
        } else {
            return '';
        }
    };
};

export default DateGroupingFormat;


