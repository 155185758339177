import {
    Box,
    Grid,
    Paper,
    Typography
} from '@mui/material';
import React from 'react';
import {BulkActionType, Person} from '../../types';
import { getService } from 'reactInAngular';
import UserBasicDetails from './components/UserBasicDetails';
import UserGroups from './components/UserGroups';
import UserSegments from './components/UserSegments';
import UserArea from './components/UserArea';
import AreaSelectorPopover
    from '../../../../../../shared/new-components/hf-area-tag-selector/components/AreaSelectorPopover';
import UserDeletedFields from './components/UserDeletedFields';
import UserAdvisorSettings from './components/UserAdvisorSettings';


interface PersonDetailPanelProps {
    person: Person | null;
    onRowChange: (updatedPerson: Person) => void;
    onValidationChange: (isValid: boolean) => void;
    isInvite?: boolean;
}

const PersonDetailPanel = ({ person, onRowChange, onValidationChange, isInvite=false } : PersonDetailPanelProps) => {

    const $translate = getService('$translate');
    const AuthSrvc = getService('AuthSrvc');


    if (!person) {
        return (
            <Typography>
                No person selected
            </Typography>
        );
    }

    const renderDeletedFields = () => {
        if (person.deleted) {
            return (
                <>

                    <Box sx={(theme) => ({marginTop: theme.spacing(2)})}>

                        <Typography variant={'h6'}
                            sx={theme => ({marginTop: theme.spacing(1)})}
                            fontWeight={'bold'}>{$translate.instant('EMPLOYEE_TURNOVER')}
                        </Typography>

                        <Typography variant={'caption'}
                            sx={theme => ({marginTop: theme.spacing(1), color: theme.palette.text.secondary})}
                        >{$translate.instant('EMPLOYEE_TURNOVER_DESCRIPTION')}
                        </Typography>

                    </Box>
                    <Box  sx={theme => ({marginTop: theme.spacing(1)})}>
                        <UserDeletedFields person={person} onRowChange={onRowChange} onValidationChange={onValidationChange}/>
                    </Box>
                </>

            );
        }
    }

    const renderAdvisorSettings = () => {
        if (!AuthSrvc.isADVISOR() ) {
            return null;
        }
        return (
            <>

                <Box sx={(theme) => ({marginTop: theme.spacing(2)})}>

                    <Typography variant={'h6'}
                        sx={theme => ({marginTop: theme.spacing(1)})}
                        fontWeight={'bold'}>{$translate.instant('EMPLOYEE_ADVISOR_SETTINGS')}
                    </Typography>

                    <Typography variant={'caption'}
                        sx={theme => ({marginTop: theme.spacing(1), color: theme.palette.text.secondary})}
                    >{$translate.instant('EMPLOYEE_ADVISOR_SETTINGS_DESCRIPTION')}
                    </Typography>

                </Box>
                <Box  sx={theme => ({marginTop: theme.spacing(1)})}>
                    <UserAdvisorSettings person={person} onRowChange={onRowChange} />
                </Box>
            </>

        );

    }

    return (
        <Paper sx={{padding: 0}} elevation={0}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>

                    <Typography variant={'h6'}
                        sx={theme => ({marginTop: theme.spacing(1)})}
                        fontWeight={'bold'}>{$translate.instant('EMPLOYEE_BASIC_DETAILS')}
                    </Typography>


                    <Typography variant={'caption'}
                        sx={theme => ({marginTop: theme.spacing(1), color: theme.palette.text.secondary})}
                    >{$translate.instant('EMPLOYEE_BASIC_DETAILS_DESCRIPTION')}
                    </Typography>


                    <Box
                        sx={theme => ({marginTop: theme.spacing(2), width: '80%', minWidth: '300px'})}
                    >
                        <UserBasicDetails
                            person={person}
                            onRowChange={onRowChange}
                            onValidationChange={onValidationChange}
                            isInvite={isInvite}
                        />

                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>

                    <Typography variant={'h6'}
                        sx={theme => ({marginTop: theme.spacing(1)})}
                        fontWeight={'bold'}>{$translate.instant('EMPLOYEE_PROFILE')}
                    </Typography>

                    <Typography variant={'caption'}
                        sx={theme => ({marginTop: theme.spacing(1), color: theme.palette.text.secondary})}
                    >{$translate.instant('EMPLOYEE_PROFILE_DESCRIPTION')}
                    </Typography>

                    <Box
                        sx={theme => ({marginTop: theme.spacing(2), width: '80%', minWidth: '300px'})}
                    >

                        <UserSegments person={person} onRowChange={onRowChange} onValidationChange={onValidationChange}/>

                    </Box>

                </Grid>

                <Grid item xs={12} sm={4}>

                    <Typography variant={'h6'}
                        sx={theme => ({marginTop: theme.spacing(1)})}
                        fontWeight={'bold'}>{$translate.instant('EMPLOYEE_AREA')}
                    </Typography>

                    <Typography variant={'caption'}
                        sx={theme => ({marginTop: theme.spacing(1), color: theme.palette.text.secondary})}
                    >{$translate.instant('EMPLOYEE_AREA_DESCRIPTION')}
                    </Typography>

                    <Box>
                        <UserArea person={person} onRowChange={onRowChange}/>
                    </Box>

                    <Box sx={(theme) => ({marginTop: theme.spacing(2)})}>

                        <Typography variant={'h6'}
                            sx={theme => ({marginTop: theme.spacing(1)})}
                            fontWeight={'bold'}>{$translate.instant('EMPLOYEE_GROUPS')}
                        </Typography>

                        <Typography variant={'caption'}
                            sx={theme => ({marginTop: theme.spacing(1), color: theme.palette.text.secondary})}
                        >{$translate.instant('EMPLOYEE_GROUPS_DESCRIPTION')}
                        </Typography>

                    </Box>
                    <Box>
                        <UserGroups person={person} onRowChange={onRowChange}/>
                    </Box>

                    {renderDeletedFields()}

                    {renderAdvisorSettings()}




                </Grid>
            </Grid>
        </Paper>



    );
}

export default PersonDetailPanel;
