'use strict';

import Style from "../../../../../../utilities/style.utils";

function AnnouncementsListingRowController($scope, $translate, Announcements, Comments, AnnouncementCommentsModal) {
    'ngInject';
    var _this = this;

    _this.$onInit = function () {
        $scope.$watch('announcement', function (newValue, oldValue) {

            if (_this.announcement) {
                // Set the status color as published
                if (!_this.announcement.archived && !_this.announcement.draft) {
                    _this.statusColor = Style.faceGood;
                    _this.statusDescription = $translate.instant('ANNOUNCEMENTS_PUBLISHED_RESULT_DESCRIPTION');

                } else if (_this.announcement.archived) {
                    _this.statusColor = Style.faceHappy;
                    _this.statusDescription = $translate.instant('ANNOUNCEMENTS_ARCHIVED_RESULT_DESCRIPTION');
                } else if (_this.announcement.draft) {
                    _this.statusColor = Style.neutral;
                    _this.statusDescription = $translate.instant('ANNOUNCEMENTS_DRAFT_RESULT_DESCRIPTION');
                }
            }

        }, true);

        _this.showComments = function () {
            AnnouncementCommentsModal.showComments(_this.announcement);
        };
    };

    $scope.$on('announcementUpdated', function (event, updateParams) {
        if (_this.announcement && _this.announcement.id === updateParams.id) {
            Announcements.getOne(updateParams.id, function (err, announcement) {
                if (!err) {
                    _this.announcement = announcement;
                }

            });
        }
    });
};

//Directive used to set metisMenu and minimalize button
const hfAnnouncementListingRow = angular.module('happyForceApp')
    .directive('hfAnnouncementListingRow', function () {
        return {
            scope: {
                announcement: '<',
                onSelect: '&',
                isSelected: '<'
            },
            transclude: true,
            replace: true,
            template: require('./announcement-listing-row.html'),
            controller: AnnouncementsListingRowController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAnnouncementListingRow;
