'use strict';

function AnnouncementsUnreadSectionConfigCtrl($scope, $translate) {
    'ngInject';

    const _this = this;

    _this.layoutTypes = [
        { id: 'HORIZONTAL', label: $translate.instant('ANNOUNCEMENTS_SECTION_LAYOUT_HORIZONTAL') },
        { id: 'VERTICAL', label: $translate.instant('ANNOUNCEMENTS_SECTION_LAYOUT_VERTICAL') }
    ];

    _this.representationTypes = [
        { id: 'COVER', label: $translate.instant('ANNOUNCEMENTS_SECTION_REPRESENTATION_COVER') },
        { id: 'STANDARD', label: $translate.instant('ANNOUNCEMENTS_SECTION_REPRESENTATION_STANDARD') }
    ];

    _this.removeSection = function () {
        _this.onRemoveSection(_this.sectionData.id);
    };

    _this.moveUp = function () {
        _this.onMoveUp(_this.sectionData.id);
    };

    _this.moveDown = function () {
        _this.onMoveDown(_this.sectionData.id);
    };


    _this.$onInit = function () {
        $scope.$watch(angular.bind(_this, function () {
            return _this['sectionData'];
        }), function (n, o) {
            if (n == o) return;

            // We just validate here if the title is valid
            const isTitleValid = !!_this.sectionData.title && !!_this.sectionData.title.defaultValue &&
                _this.sectionData.title.defaultValue.length > 0;
            const isLayoutValid = _this.sectionData.layout == 'VERTICAL'
                || (_this.sectionData.layout == 'HORIZONTAL' &&  _this.sectionData.representation);

            _this.setIsValid(_this.sectionData.id, isTitleValid && isLayoutValid);
        }, true);
    };
}



const hfUnreadSectionConfig = angular.module('happyForceApp')
    .directive('hfUnreadSectionConfig', function () {
        return {
            template: require('./unread_section_configuration.html'),
            controller: AnnouncementsUnreadSectionConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                sectionData: '<',
                onRemoveSection: '<',
                setIsValid: '<',
                onMoveUp: '<',
                onMoveDown: '<'
            }
        };
    });

export default hfUnreadSectionConfig;