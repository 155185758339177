'use strict';


import Dates from '../../../utilities/date.utilities';
import {APISettings, doGet} from './axiosWrapper';


const baseUrl = `/company/anonymity`;

export const checkAnonymity = function (params, callback) {
    const from = Dates.toAPIFormat(params.from, APISettings.apiDateFormat);
    const to = Dates.toAPIFormat(params.to, APISettings.apiDateFormat);
    const queryParams = { ...params, from, to };
    doGet(baseUrl, queryParams, callback);
};

