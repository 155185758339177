
import {useEffect, useMemo, useState} from 'react';
import { getService } from 'reactInAngular';


const useGroupName = (groupId: string | undefined) => {


    const [groupName, setGroupName] = useState<string>('');

    const Groups = getService('Groups');

    useEffect(() => {
        if (groupId) {
            Groups.getGroupName(groupId).then((name: any) => {
                setGroupName(name);
            });
        }
    }, [groupId]);


    const memoizedGroupName = useMemo(() => groupName, [groupName]);

    return memoizedGroupName;
}

const useGroupNames = (groupIds: string[] | undefined) => {
    const [groupNames, setGroupNames] = useState<{ groupId: string, groupName: string }[]>([]);

    const Groups = getService('Groups');

    useEffect(() => {
        const fetchGroupNames = async () => {
            if (groupIds && groupIds.length > 0) {
                const promises = groupIds.map(async (id) => {
                    const name = await Groups.getGroupName(id);
                    return { groupId: id, groupName: name };
                });

                const results = await Promise.all(promises);
                setGroupNames(results);
            }
        };

        fetchGroupNames();
    }, [groupIds]);

    const memoizedGroupNames = useMemo(() => groupNames, [groupNames]);

    return memoizedGroupNames;
};

export { useGroupName, useGroupNames };

