'use strict';

import SurveyQuestionEditCtrl from "../survey_question_edit/survey_question_edit.controller";


var SurveyBlockCtrl = function ($scope, $uibModal) {
    'ngInject'
    var _this = this;

    /*_this.$onInit = function () {

        // Monitor language changes of the question
        ['item','selectedLanguage'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n != o) {
                    validateItem();
                };

            });
        });
    };*/



    _this.up = function () {
        if (_this.upBlock) {
            _this.upBlock(_this.blockIndex);
        }
    };

    _this.down = function () {
        if (_this.downBlock) {
            _this.downBlock(_this.blockIndex);
        }
    };

    _this.edit = function () {
        if (_this.editBlock) {
            _this.editBlock(_this.blockIndex, _this.block);
        }
    };

    _this.questionUp = function (questionIndex) {
        if (_this.upQuestion) {
            _this.upQuestion(_this.blockIndex, questionIndex);
        }
    };


    _this.questionDown = function (questionIndex) {
        if (_this.downQuestion) {
            _this.downQuestion(_this.blockIndex, questionIndex);
        }
    };


    _this.editQuestion = function ($index, question) {
        $uibModal.open({
            backdrop: 'static',
            template: require('./../survey_question_edit/survey_question_edit.html'),
            controller: 'SurveyQuestionEditCtrl',
            controllerAs: '$ctrl',
            size: 'survey-question',
            resolve: {
                question: question,
                selectedLanguage: function () { return _this.selectedLanguage;},
                isEdit: question ? true : false
            }
        }).result.then(function (editedQuestion) {
            if (!question) {
                // This is an edition
                _this.block.questions.push(editedQuestion);
            } else {
                if (editedQuestion) {
                    _this.block.questions[$index] = editedQuestion;
                } else {
                    _this.block.questions.splice($index, 1);
                }
            }
            _this.onBlockUpdated(_this.block);

        }, function (res) {
            console.log('Modal ' + res);
        });
    };


    _this.cloneQuestion = function (question) {
        var newQuestion = angular.copy(question);
        delete newQuestion.id;
        _this.block.questions.push(newQuestion);
        _this.onBlockUpdated(_this.block);
    };


};

const hfSurveyBlock = angular.module('happyForceApp')
    .directive('hfSurveyBlock', function () {
        return {
            scope: {
                block: '=',
                blockIndex: '=',
                selectedLanguage: '=',
                upBlock: '=',
                downBlock: '=',
                editBlock: '=',
                upQuestion: '=',
                downQuestion: '=',
                onBlockUpdated: '='
            },
            template: require('./survey_block.html'),
            controller: SurveyBlockCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSurveyBlock;
