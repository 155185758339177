import async from 'async';
import Numbers from "../../../utilities/number.utilities";
import Valuation from "../../../utilities/valuation.utils";

// Components
import HappinessIndexEvolutionComponent from "./happiness-evolution-chart/happiness-evolution-chart.controller";
import HappinessGaugeChartComponent from "./happiness-gauge-chart/happiness-gauge-chart.controller";
import MilestonesComponent from "./milestones/milestones.component";
import VotesDistributionChartComponent from "./votes-distribution-chart/votes-distribution-chart.controller";
import template from './happiness.html';

function happinessIndexCtrl($scope, $translate, HIService, Settings, ErrorSvrc, $log, Milestone, MilestoneOperationsModals) {
    var _this = this;

    this.$onInit = function () {

        _this.leyendGraphItems = [];
        _this.leyendGraphItems.push({ color: '#3ebfde', label: $translate.instant('COL_EVO_PARTICIPATION') });
        _this.leyendGraphItems.push({ color: '#ff5f3d', label: $translate.instant('HAPPINESS_INDEX') });

        var params = {};
        var streams = [];

        streams.push(function (next) {
            HIService.get(next);
        });

        streams.push(function (hiQuestion, next) {
            HIService.stats
                .get(params, function (err, stats) {
                    if (err) {
                        console.log('ERROR getting Stats', err);
                        return next(err);
                    }

                    var seriesData = stats && stats.periodResults;
                    var response = {};

                    if (seriesData) {
                        seriesData.question = hiQuestion;
                        response.questionsData = seriesData;

                        response.votesPeriod = seriesData.votesPeriod;

                        response.questionValues = hiQuestion.values;

                        if (response.questionValues) {
                            response.questionValues.forEach(function (questionVote) {
                                questionVote.percent = Numbers.roundNumber(seriesData.ratingPercents[questionVote.id]);
                            });
                        }

                        response.periodHi = Numbers.roundNumber(seriesData.periodIndex);
                        response.hiColor = Valuation.valuationColor(seriesData.valuation);
                        response.periodVariation = Numbers.roundNumber(seriesData.periodIndexVariation);

                        response.expectedParticipants = seriesData.expectedParticipants;
                        response.invitedParticipants = seriesData.invitedParticipants;
                        response.returningNewParticipants = seriesData.newParticipants;
                        response.returningNewParticipantsVariation = seriesData.newParticipantsVariation;

                        response.lostParticipants = seriesData.lostParticipants;
                        response.lostParticipantsVariation = seriesData.lostParticipantsVariation;

                        response.periodParticipants = seriesData.periodParticipants;
                        response.periodParticipantsVariation = seriesData.periodParticipantsVariation;
                        response.participationPercent = Numbers.roundNumber(seriesData.participationPercent);

                        response.periodData = [];
                        response.participationData = [];
                        Object.keys(seriesData.votes).forEach(function (date) {
                            var timestamp = parseInt(moment(date).format('x'));
                            var vote = seriesData.votes[date];
                            var roundedIndex = Numbers.roundNumber(vote.indexRating);
                            response.periodData.push([timestamp, roundedIndex > 0 ? roundedIndex : null]);
                            response.participationData.push([timestamp, vote.totalVotes > 0 ? vote.totalVotes : null]);
                        });
                    }

                    next(null, response);

                });

        });

        streams.push(function (response, next) {
            // Get Milestones for dates
            Milestone.GET(_this.dates.from, _this.dates.to, params, function (err, milestones) {
                if (err) {
                    console.error('ERROR EN HITOS', err);
                    return next(null, response);
                }

                _this.milestones = milestones;
                next(null, response);
            });
        });

        function loadData() {
            _this.hasEnoughActiveEmployees = true;

            params.from = _this.dates.from;
            params.to = _this.dates.to;

            params.hierarchyId = _this.level && _this.level.id;
            params.filters = _this.filters;
            params.groupId = _this.groups;

            $scope.$emit('loading', true);
            async.waterfall(streams, function (err, response) {
                $scope.$emit('loading', false);

                if (err) {
                    _this.loadingQuestionStats = false;

                    if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                        _this.hasEnoughActiveEmployees = false;
                    } else {
                        ErrorSvrc.showErrorModal(err);

                    }

                    return;

                }

                Object.keys(response).forEach(function (key) {
                    _this[key] = response[key];
                });
            });
        }

        loadData();

        var lastExecution;
        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                loadData();
            }, true);
        });

        _this.createMilestone = function () {

            var milestone = {};
            if (_this.level && _this.level.id) {
                milestone.hierarchyId = _this.level.id;
            }


            MilestoneOperationsModals.createMilestone(milestone, function (err, result) {
                if (err || !result) return;
                loadData();
            });
        };

        _this.editMilestone = function (milestone) {
            MilestoneOperationsModals.editMilestone(milestone, function (err, result) {
                if (err || !result) return;
                loadData();
            });
        };

        _this.hoverMilestone = function (milestone) {
            $('#hito_' + milestone.id).toggleClass('hover');
        };

        _this.addChartMilestone = function (date) {
            var params = { date: date };
            if (_this.level && _this.level.id) {
                params.hierarchyId = _this.level.id;
            }

            MilestoneOperationsModals.createMilestone(params, function (err, result) {
                if (err || !result) {
                    return;
                }

                loadData();
            });
        };
    };

}


//Directive used to set metisMenu and minimalize button
const HappynessIndexComponent = angular.module('happyForceApp')
    .directive('happinessIndex', function () {
        return {
            template: template,
            restrict: 'E',
            scope: {
                dates: '=',
                level: '=',
                filters: '=',
                groups: '='
            },

            controller: happinessIndexCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default HappynessIndexComponent;
