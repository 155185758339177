import React from 'react';
import { angularize } from 'reactInAngular';

const HFCircleDot = ({ color, type = 'dot' }) => {
    return (
        <>
            {type === 'dot' ? (
                <span className="hf-dot" style={{ backgroundColor: color }}></span>
            ) : (
                <span className="hf-oval" style={{ borderColor: color }}></span>
            )}
        </>
    );
};

angularize(HFCircleDot, 'hfCircleDot', angular.module('happyForceApp'), {
    color: '<',
    type: '<'
});

export default HFCircleDot;
