'use strict';

import template from './hf-file-upload.html';

var HFFileUploadCtrl = function ($scope, $translate, Attachments) {
    'ngInject'
    var _this = this;
    _this.uploading = false;

    _this.uploadFiles = function (files) {
        if (files && files.length) {
            _this.uploading = true;
            for (var i = 0; i < files.length; i++) {
                Attachments.upload(files[i], function (err, $file) {
                    _this.uploading = false;
                    if (!err && $file !== -1) {

                        if (_this.onUpload) {
                            _this.onUpload($file);
                        }
                    } else {
                        var messageError = $translate.instant(
                            'UPLOAD_FILE_ERROR_DESC',
                            { name: $file.name }
                        );
                        swal(
                            $translate.instant(
                                (err && err.errorMessage) || 'UPLOAD_FILE_ERROR'
                            ),
                            messageError,
                            'error'
                        );
                    }
                });
            }
        }
    };




    this.$onInit = function () {

    };
};

const hfFileUpload = angular.module('happyForceApp')
    .directive('hfFileUpload', function () {
        return {
            scope: {
                files: '<',
                onUpload: '<'
            },
            template: template,
            controller: HFFileUploadCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });

export default hfFileUpload;
