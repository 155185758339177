'use strict';

function AnnouncementsSectionTitleConfigCtrl($scope, Languages) {
    'ngInject';
    
    const _this = this;

    _this.$onInit = function () {
        $scope.$watch(angular.bind(_this, function () {
            return _this['titleData'];
        }), function (n, o) {
            if (n == o) return;
            _this.onUpdate(_this.titleData);
        }, true);
        computeCurrentLanguages();
    };



    function computeCurrentLanguages() {
        _this.titleLanguages = [];
        if (_this.titleData && _this.titleData.langValues != null) {
            Object.keys(_this.titleData.langValues).forEach(function (x) { _this.titleLanguages.push(x);});
        }
    }


    _this.selectLanguage = function (language) {
        _this.selectedLanguage = language;
        _this.selectedLanguageTranslation = Languages.getNameByCode(language);
    };

    _this.addLanguage = function (language) {

        Languages.setLangValue(_this.titleData, null, language);

        // Update the language list
        computeCurrentLanguages();
    };


    _this.removeLanguage = function () {
        Languages.removeLangValue(_this.titleData, _this.selectedLanguage);
        computeCurrentLanguages();
    };

}


const hfAnnouncementsSectionTitleConfig = angular.module('happyForceApp')
    .directive('hfAnnouncementsSectionTitleConfig', function () {
        return {
            template: require('./announcement_section_title_config.html'),
            controller: AnnouncementsSectionTitleConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                titleData: '<',
                onUpdate: '<'
            }
        };
    });

export default hfAnnouncementsSectionTitleConfig;