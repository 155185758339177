'use strict';

import Style from "../../../../../utilities/style.utils";

function ScoresFeedbackItemCtrl($scope, ErrorSvrc, Languages) {
    'ngInject';
    var _this = this;

    _this.commentUpdated = function (err, updatedComment) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
        }
        else {
            _this.comment = updatedComment;
        }
    };



    this.$onInit = function () {

        // Conversation link modal, will be only displayed based on setting value
        if (typeof _this.showConversationLink != 'undefined') {
            _this.displayConversationLink = _this.showConversationLink;
        } else {
            _this.displayConversationLink = true;
        }

        var calculatePunctuationColor = function (value, inverted) {
            if (inverted) {
                if (value < 5) {
                    return Style.excellent;
                } else if (value < 6) {
                    return Style.warning;
                } else {
                    return Style.bad;
                }
            } else {
                if (value > 6) {
                    return Style.excellent;
                } else if (value > 5) {
                    return Style.warning;
                } else {
                    return Style.bad;
                }
            }
        };

        _this.score = _this.comment.votes[0].score;

        _this.voteColor = calculatePunctuationColor(_this.score, _this.comment.invertedQuestion);
        _this.name = Languages.getTranslationFromUserLanguage(_this.comment.scoreName) + ' - ' + Languages.getTranslationFromUserLanguage(_this.comment.factorName);
    };

}

const hfScoreFeedbackItem = angular.module('happyForceApp').directive('hfScoreFeedbackItem', function () {
    return {
        scope: {
            comment: '=',
            showConversationLink: '<?',
            size: '<'
        },
        restrict: 'E',
        template: require('./score_feedback_item.html'),
        controller: ScoresFeedbackItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfScoreFeedbackItem;

