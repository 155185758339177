'use strict';

var StepsCtrl = function () {
    var _this = this;

    _this.getItemClass = function (position, step) {
        var positionClass = 'col-xs-' + (Math.floor(12 / _this.steps.length)) + ' position-' + step.number;

        positionClass += (_this.active === position) ? ' active ' : '';

        //Add column offset to first step, if there are more than one step, and this is the first one
        /*if (position === 0 && _this.steps.length > 1 && (_this.steps.length % 2) === 1)
            positionClass += ' col-md-offset-1 col-xs-offset-1';*/

        return positionClass;
    };

    if (_this.clickable) {
        _this.setStep = function (value) {
            _this.active = value - 1;
        };
    }


    this.$onInit = function () {


    };

};

const hfSteps = angular.module('happyForceApp')
    .directive('hfSteps', function () {
        return {
            scope: {
                steps: '=',
                active: '=',
                clickable: '='
            },
            template: require('./steps.html'),
            controller: StepsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSteps;

