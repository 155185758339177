import Dates from "../../utilities/date.utilities";

const SurveysService = function (Restangular, Settings, $translate) {
  'ngInject';
    var Company = Restangular.one('company');
    var Survey = Company.one('surveys');
    var SurveyStats = Restangular.one('company').one('stats').one('surveys');

    var Feedback = Restangular.one('company').one('feedback').one('surveys');

    var methods = {};


    methods.create = function (survey, callback) {
        Company.customPOST(survey, 'surveys').then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.update = function (surveyId, survey, callback) {
        Survey.one(surveyId).customPUT(survey).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };


    methods.delete = function (surveyId, callback) {
        Survey.one(surveyId).remove().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };



    methods.get = function (surveyId, callback) {
        Survey.one(surveyId).get().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };


    methods.list = function (params, callback) {
        Survey.get(params).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.setPlan = function (surveyId, plan, callback) {
        Survey.one(surveyId).one('plan').customPUT(plan).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.sendReminder = function (surveyId, content, callback) {
        Survey.one(surveyId).customPOST(content, 'reminder').then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.getCategories = function (callback) {
        Survey.one('templates').one('categories').get().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.getTemplates = function (categoryId, callback) {
        Survey.one('templates').get({ categoryId:  categoryId }).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.getTemplate = function (templateId, callback) {
        Survey.one('templates').one(templateId).get().then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };




    var stats = {};

    stats.results = function (surveyId, params, callback) {

        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }

        if (_params.to) {
            _params.to =  Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }

        SurveyStats.one(surveyId).get(_params).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    stats.grouping = function (surveyId, type, params, callback) {

        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }

        if (_params.to) {
            _params.to =  Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }

        SurveyStats.one(surveyId).one('grouping').one(type).get(_params).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    stats.evolution = function (surveyId, params, callback) {
        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }

        if (_params.to) {
            _params.to =  Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }

        SurveyStats.one(surveyId).one('evolution').get(_params).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    var feedback = {};

    feedback.get = function (surveyId, params, callback) {
        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }

        if (_params.to) {
            _params.to =  Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }
        Feedback.one(surveyId).get(_params).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.stats = stats;



    methods.feedback = feedback;

    methods.groupingTypes = [
        { id: 'WEEK', label: $translate.instant('DATE_GROUPING_WEEK') },
        { id: 'MONTH', label: $translate.instant('DATE_GROUPING_MONTH') },
        { id: 'QUARTER', label: $translate.instant('DATE_GROUPING_QUARTER') },
        { id: 'YEAR', label: $translate.instant('DATE_GROUPING_YEAR') }
    ];

    return methods;

};

export default SurveysService;
