'use strict';

var GroupsTaggingFilterCtrl = function ($rootScope, $compile, $scope, $element, Groups) {
    'ngInject'
    var _this = this;

    _this.groupFilters = [];


    function removeFilter(id) {
        // First, remove the element from the groups list
        if (_this.mode == 'multi') {
            _this.groupFilters = _.without(_this.groupFilters, id);
        } else {
            _this.groupFilters = [];
        }
        _this.notifyFilterChanges();
    }

    function renderTags() {
        // Based on the mode, we need to render the tags differently
        if (_this.mode == 'multi') {
            // Multi mode:
            if (_this.groupFilters.length > 0) {
                _this.groupFilters.forEach(function (groupId) {
                    Groups.getGroup(groupId, function (err, group) {
                        _this.tagFilterMenu.insertFilterTag(groupId, _this.type, group.name, _this.typeLabel, _this.nonRemovable, removeFilter);
                    });
                });
            }
        } else if (_this.mode == 'single') {
            // Single mode:
            if (_this.groupFilters.length > 0) {
                const groupId = _this.groupFilters[0];
                Groups.getGroup(groupId, function (err, group) {
                    _this.tagFilterMenu.insertFilterTag('dummy', _this.type, group.name, _this.typeLabel,  _this.nonRemovable, removeFilter);
                });
            }
        } else {
            // Grouped mode:
            if (_this.groupFilters.length > 0) {
                var streams = [];
                _this.groupFilters.forEach(function (groupId) {
                    // Only add the names already not added:
                    streams.push(function (next) {
                        Groups.getGroup(groupId, function (err, group) {
                            if (err) {
                                next(err, '?');
                            } else {
                                next(null, group.name);
                            }
                        });
                    });
                });

                async.parallel(streams, function (err, names) {
                    if (err) {
                        console.log(err);
                    }
                    const name = names.join(', ');
                    _this.tagFilterMenu.insertFilterTag('dummy', _this.type, name, _this.typeLabel, _this.nonRemovable, removeFilter);
                });
            }
        }
    }

    _this.selectGroup = function (groupId) {

        if (_this.groupFilters.indexOf(groupId) >= 0) {
            // If the group is already added, this is a removal
            _this.tagFilterMenu.removeTag(groupId, _this.type);
            _this.groupFilters = _.without(_this.groupFilters, groupId);
        } else if (_this.mode == 'single') {
            // If the mode is single, we need to remove the previous hierarchy
            _this.groupFilters = [groupId];
        } else {
            // Othewise, we add it and render the tag
            _this.groupFilters.push(groupId);
        }

        renderTags();

        // Notify filter changes:
        _this.notifyFilterChanges();
    };

    _this.notifyFilterChanges = function () {

        var filtersNotifyMsg = {
            type: _this.type,
            filters: _this.groupFilters
        };

        $rootScope.$broadcast('filterChange-' + _this.tagFilterMenu.filterId, filtersNotifyMsg);
    };

    _this.prevent = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };

    // Load all groups
    _this.$onInit = function () {
        Groups.getAll(null, function (err, groups) {
            _this.groups = angular.copy(groups);
        });

        // If there are filters, render them
        if (_this.tagFilterMenu.filters && _this.tagFilterMenu.filters[_this.type]) {
            _this.groupFilters = _this.tagFilterMenu.filters[_this.type];
            renderTags();
        }
    };
};



const hfGroupsTaggingFilter = angular.module('happyForceApp').directive('hfGroupsTaggingFilter', function () {
    return {
        restrict: 'E',
        scope: {
            mode: '<',
            label: '@',
            typeLabel: '@',
            type: '@',
            nonRemovable: '<'
        },
        require: {
            tagFilterMenu: '^hfTagFilterMenu'
        },
        template: require('./groups-tagging-filter.html'),
        controllerAs: '$ctrl',
        bindToController: true,
        transclude: false,
        replace: true,
        controller: GroupsTaggingFilterCtrl
    };
});

export default hfGroupsTaggingFilter;
