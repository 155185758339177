'use strict';

const hfFooter = angular.module('happyForceApp')
    .directive('hfFooter', function () {
        return {
            template: require('./footer.html'),
            controller: function ($scope, $translate) {
                $scope.year = new Date().getFullYear();

                var terms = {
                    en: 'https://myhappyforce.com/en/terms-conditions/',
                    es: 'https://myhappyforce.com/es/terminos-y-condiciones/'
                };


                var privacy = {
                    en: 'https://myhappyforce.com/en/privacy-policy/',
                    es: 'https://myhappyforce.com/es/politica-de-privacidad/'
                };

                $scope.terms_url = terms[$translate.use()];
                $scope.privacy_url = privacy[$translate.use()];
            }
        };
    });

export default hfFooter;
