import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    LinearProgress,
    Typography,
    Avatar, Grid, Input, FormHelperText, Box, Switch
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const UserProfileSettings = ({ show, onClose }) => {
    const AuthSrvc = getService('AuthSrvc');
    const ErrorSvrc = getService('ErrorSvrc');
    const $state = getService('$state');
    const $translate =  getService('$translate');

    const [user, setUser] = useState(null);

    const [newpassword, setNewpassword] = useState('');
    const [newpasswordbis, setNewpasswordbis] = useState('');
    const [changed, setChanged] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [actualLang, setActualLang] = useState(AuthSrvc.getLanguage());

    const [errored, setErrored] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const notificationTypes = [
        {
            type: 'PRIVATE_COMMENT_RECEIVED',
            label: $translate.instant('PRIVATE_COMMENT_RECEIVED'),
            mailAvailableFrequency: ['IMMEDIATELY', 'NEVER'],
            pushAvailableFrequency: ['IMMEDIATELY', 'NEVER']
        },
        {
            type: 'INAPPROPRIATE_COMMENT_RECEIVED',
            label: $translate.instant('INAPPROPRIATE_COMMENT_RECEIVED'),
            mailAvailableFrequency: ['IMMEDIATELY', 'NEVER'],
            pushAvailableFrequency: ['IMMEDIATELY', 'NEVER']
        },
        {
            type: 'COMMENT_RECEIVED',
            label: $translate.instant('COMMENT_RECEIVED'),
            mailAvailableFrequency: ['IMMEDIATELY', 'NEVER'],
            pushAvailableFrequency: ['IMMEDIATELY', 'NEVER']
        },
        {
            type: 'QUESTION_FINISHED',
            label: $translate.instant('QUESTION_FINISHED'),
            mailAvailableFrequency: ['IMMEDIATELY', 'NEVER'],
            pushAvailableFrequency: ['IMMEDIATELY', 'NEVER']
        },
    ]

    useEffect(() => {

        AuthSrvc.me((err, me) => {
            setUser({ ... me?.companyUser});
        });


    }, [show]);

    useEffect(() => {

        if (changed) {
            if (newpassword.length <= 5) {
                setErrored(true);
                setErrorMsg($translate.instant('EDIT_PASS_TO_SHORT'));
            } else if (newpassword !== newpasswordbis) {
                setErrored(true);
                setErrorMsg($translate.instant('EDIT_PASS_ERROR'));
            } else {
                setErrored(false);
                setErrorMsg('');
            }
        }

    },[newpassword, newpasswordbis, changed]);


    const setUserImage = (files) => {
        setUploading(true);
        AuthSrvc.setUserImage(files[0], (updatedUser) => {
            setUploading(false);
            setUser(updatedUser);
            swal($translate.instant('PROFILE_IMG_UPDATED'), '', 'success');
        }, (loaded, total) => {
            setUploadProgress((100 * loaded) / total);
        }, () => {
            setUploading(false);
        });
    };

    const updateUser = () => {

        if (newpassword !== '') {
            user.password = newpassword;
        }

        user.language = actualLang;

        AuthSrvc.updateUser(user, (updatedUser) => {
            setUser(updatedUser);
            swal($translate.instant('PROFILE_UPDATED'), '', 'success');
            onClose();
            $state.reload();

        }, (response) => {
            ErrorSvrc.showErrorModal(response);
        });
    };

    const cancel = () => {
        onClose();
    };


    if (!show) {
        return null;
    }

    function renderNotificationSetting(notificationType) {

        const userPushNotificationSetting = user.pushNotificationSettings.find((n) => n.type === notificationType.type);
        const userMailNotificationSetting = user.mailNotificationSettings.find((n) => n.type === notificationType.type);

        return (

            <Grid container spacing={2} sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Grid item xs={8}>
                    <Typography variant="h6" margin="normal">{notificationType.label}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth  size={'small'}>

                        <Switch
                            checked={userPushNotificationSetting.frequency === 'IMMEDIATELY'}
                            onChange={event => {
                                if (event.target.checked) {
                                    userPushNotificationSetting.frequency = 'IMMEDIATELY'
                                } else {
                                    userPushNotificationSetting.frequency = 'NEVER';
                                }
                                setUser({...user, pushNotificationSettings:  user.pushNotificationSettings});
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <FormControl fullWidth  size={'small'}>
                        <Switch
                            checked={userMailNotificationSetting.frequency === 'IMMEDIATELY'}
                            onChange={event => {
                                if (event.target.checked) {
                                    userMailNotificationSetting.frequency = 'IMMEDIATELY'
                                } else {
                                    userMailNotificationSetting.frequency = 'NEVER';
                                }
                                setUser({...user, mailNotificationSettings:  user.mailNotificationSettings});
                            }}
                        />
                    </FormControl>
                </Grid>

            </Grid>
        );
    }

    return (
        <Dialog open={show} onClose={cancel}>
            <DialogTitle>
                <Typography variant={'h1'} >
                    {$translate.instant('MY_PROFILE')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <form noValidate autoComplete="off">

                            <FormControl variant="standard" fullWidth sx={theme => ({marginBottom: theme.spacing(2)})}>
                                <InputLabel
                                    shrink="true"
                                    htmlFor="userName">{$translate.instant('MY_PROFILE_USER_NAME')}</InputLabel>
                                <Input
                                    fullWidth
                                    id="userName"
                                    value={user.name}
                                    onChange={(e) => {
                                        setUser({...user, name: e.target.value});
                                        setChanged(true);
                                    }}
                                    aria-describedby="userName-text"
                                />
                                <FormHelperText id="userName-text">
                                    {$translate.instant('MY_PROFILE_USER_NAME_DESCRIPTION')}
                                </FormHelperText>
                            </FormControl>
                            <FormControl  variant="standard" fullWidth sx={theme => ({marginBottom: theme.spacing(2)})}>
                                <InputLabel shrink="true"
                                    htmlFor="newUserPassword">{$translate.instant('NEW_PASS')}</InputLabel>
                                <Input
                                    fullWidth
                                    id="newUserPassword"
                                    value={newpassword}
                                    placeholder={'******'}
                                    type="password"
                                    onChange={(e) => {
                                        setChanged(true);
                                        setNewpassword(e.target.value);
                                    }}
                                    aria-describedby="newUserPassword-text"
                                />
                                <FormHelperText id="newUserPassword-text">
                                    {$translate.instant('NEW_PASS_DESCRIPTION')}
                                </FormHelperText>
                            </FormControl>




                            <FormControl error={errored}  variant="standard" fullWidth sx={theme => ({marginBottom: theme.spacing(2)})}>
                                <InputLabel  shrink="true"
                                    htmlFor="userPasswordBiss">{$translate.instant('REP_NEW_PASS')}</InputLabel>
                                <Input
                                    fullWidth
                                    id="userPasswordBiss"
                                    value={newpasswordbis}
                                    placeholder={'******'}
                                    type="password"
                                    onChange={(e) => {
                                        setChanged(true);
                                        setNewpasswordbis(e.target.value);
                                    }}
                                    aria-describedby="userPasswordBiss-text"
                                />
                                <FormHelperText id="userPasswordBiss-text">
                                    {errorMsg}
                                </FormHelperText>
                            </FormControl>
                        </form>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <Avatar
                                alt={user.name}
                                src={AuthSrvc.getUserImage()}
                                sx={{width: 100, height: 100, mb: 2}}
                            >
                                {user.name[0]?.toUpperCase() || 'HF'}
                            </Avatar>

                            <input
                                type="file"
                                style={{display: 'none'}}
                                id="raised-button-file"
                                onChange={(e) => setUserImage(e.target.files)}
                            />
                            <label htmlFor="raised-button-file">
                                <Button size="small" component="span">
                                    {$translate.instant('CHANGE_PROFILE_PIC')}
                                </Button>
                            </label>
                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold"
                            margin="normal">{$translate.instant('LANGUAGE')}</Typography>

                        <Typography variant="h6"
                            margin="normal">{$translate.instant('MY_PROFILE_LANGUAGE_DESCRIPTION')}</Typography>

                        <FormControl fullWidth size={'small'}>
                            <Select
                                value={actualLang}
                                onChange={(e) => {setActualLang(e.target.value)}}
                            >
                                <MenuItem value={'en'}>English</MenuItem>
                                <MenuItem value={'es'}>Español</MenuItem>
                            </Select>
                        </FormControl>


                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold"
                            margin="normal">{$translate.instant('USER_PROFILE_NOTIFICATION_OPTIONS')}</Typography>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Grid container spacing={2} sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle2">{$translate.instant('USER_PROFILE_NOTIFICATION_OPTIONS_DESCRIPTION')}</Typography>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">{$translate.instant('USER_PROFILE_NOTIFICATION_PUSH')}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">{$translate.instant('USER_PROFILE_NOTIFICATION_MAIL')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>


                            {notificationTypes?.map((notification) => (



                                <Grid item xs={12} key={notification.type}>
                                    {renderNotificationSetting(notification)}
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} variant={'contained'} color={'secondary'} >{$translate.instant('CANCEL')}</Button>
                <Button
                    onClick={updateUser}
                    variant={'contained'}
                    color="primary"
                    disabled={errored || user?.name === ''}
                >
                    {$translate.instant('SAVE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UserProfileSettings.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default UserProfileSettings;
