function CommentConversationsModalCtrl(CompanySvrc, Comments, $translate, comment, ErrorSvrc, $modalInstance) {
    'ngInject';
    var _this = this;

    _this.loading = false;
    _this.posting = false;
    _this.reply = false;
    _this.signReply = true;


    _this.comment = comment;

    _this.handleConversationStarted = function (err, conversationId) {
        if (!err) {
            _this.comment.conversationId = conversationId;
        }
    };

    _this.close = function () {
        $modalInstance.dismiss();
    };

}

const CommentConversationModal = angular.module('happyForceApp')
    .factory('CommentConversationModal',
        function ($uibModal, Comments, $intercom) {
            var methods = {};

            methods.showModal = function (comment, callback) {
                $intercom.update({ hide_default_launcher: true });

                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);


                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'right',
                    template: require('./comment-conversation-modal.html'),
                    controller: CommentConversationsModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        comment: comment
                    }
                }).result.then(function (params) {
                    $intercom.update({ hide_default_launcher: false });
                    if (callback) {
                        Comments.getOne(comment.id, function (err, updatedComment) {
                            callback(err, updatedComment);
                        });
                    }
                }, function (params) {
                    // If there is a callback, reload allays the comment, so we have a fresh version of it
                    $intercom.update({ hide_default_launcher: false });
                    if (callback) {
                        Comments.getOne(comment.id, function (err, updatedComment) {
                            callback(err, updatedComment);
                        });
                    }
                });
            };

            return methods;

        }
    );

export default CommentConversationModal;
