'use strict';

function SettingsAlertsAndRemindersCtrl($scope, Analytics) {
    'ngInject';

    var _this = this;


    _this.validHours = [];
    _this.validMorningHours = [];
    _this.validMiddayHours = [];
    _this.validAfternoon = [];
    _this.validMinutes = [];
    _this.selectedAlertHour = '00';
    _this.selectedAlertMinute = '00';
    _this.reminderTimes = {};

    _this.reminderTimes['MORNING'] = { hour: '00', minute: '00' };
    _this.reminderTimes['MIDDAY'] = { hour: '00', minute: '00' };
    _this.reminderTimes['AFTERNOON'] = { hour: '00', minute: '00' };



    for (let i = 0; i < 24; i++) {
        let hourString = i < 10 ? '0' + i : i;
        if (i <= 11) {
            _this.validMorningHours.push(hourString);
        } else if (i >= 12 && i < 15) {
            _this.validMiddayHours.push(hourString);
        } else if (i >= 15 && i < 23) {
            _this.validAfternoon.push(hourString);
        }
        _this.validHours.push(hourString);
    }


    for (let i = 0; i < 60; i = i + 15) {
        _this.validMinutes.push(i < 10 ? '0' + i : i);
    }

    _this.isDayIncluded = function (list, day) {
        return _this.companyConfiguration[list] && _this.companyConfiguration[list].indexOf(day) != -1;
    };

    _this.updateSelection = function (list, day) {
        if (_this.isDayIncluded(list, day)) {
            var index = _this.companyConfiguration[list].indexOf(day);
            _this.companyConfiguration[list].splice(index, 1);
        } else {
            _this.companyConfiguration[list].push(day);
        }
    };

    _this.updateAlertTime = function () {
        _this.companyConfiguration.alertHour = parseInt(_this.selectedAlertHour);
        _this.companyConfiguration.alertMinute = parseInt(_this.selectedAlertMinute);
        if (_this.onUpdate) {
            _this.onUpdate(_this.companyConfiguration);
        }
    };

    _this.updateReminderTimes = function (timeFrame) {
        _this.companyConfiguration.voteReminderTimeConfig[timeFrame].hour = parseInt(_this.reminderTimes[timeFrame].hour);
        _this.companyConfiguration.voteReminderTimeConfig[timeFrame].minute = parseInt(_this.reminderTimes[timeFrame].minute);
        if (_this.onUpdate) {
            _this.onUpdate(_this.companyConfiguration);
        }
    };



    function roundHour(hour) {
        if (hour != null && parseInt(hour) < 10) {
            return '0' + hour;
        } else {
            // Convert to string
            return '' + hour;
        }

    }

    function roundMinute(minute) {
        if (minute != null) {
            if (minute < 15) {
                return '00';
            } else if (_this.companyConfiguration.alertMinute < 30) {
                return '15';
            } else if (_this.companyConfiguration.alertMinute < 45) {
                return '30';
            }  else {
                return '45';
            }
        } else {
            return minute;
        }
    }



    _this.parseConfiguration = function () {
        if (_this.companyConfiguration) {

            // Parse alert time
            _this.selectedAlertHour = roundHour(_this.companyConfiguration.alertHour);
            _this.selectedAlertMinute = roundMinute(_this.companyConfiguration.alertMinute);


            // Parse engagement notification times
            _this.reminderTimes['MORNING'] = {
                hour: roundHour(_this.companyConfiguration.voteReminderTimeConfig['MORNING'].hour),
                minute: roundHour(_this.companyConfiguration.voteReminderTimeConfig['MORNING'].minute)
            };

            _this.reminderTimes['MIDDAY'] = {
                hour: roundHour(_this.companyConfiguration.voteReminderTimeConfig['MIDDAY'].hour),
                minute: roundHour(_this.companyConfiguration.voteReminderTimeConfig['MIDDAY'].minute)
            };

            _this.reminderTimes['AFTERNOON'] = {
                hour: roundHour(_this.companyConfiguration.voteReminderTimeConfig['AFTERNOON'].hour),
                minute: roundHour(_this.companyConfiguration.voteReminderTimeConfig['AFTERNOON'].minute)
            };


        }
    };

    _this.saveCompanyAndSettings = function () {

        _this.onSave();
    };

    _this.$onInit = function () {

        ['companyConfiguration'].forEach(function (key) {
            // Listeners
            $scope.$watch(
                angular.bind(_this, function () {
                    return _this[key];
                }),
                function (n, o) {
                    if (n == o) {
                        return;
                    }
                    _this.parseConfiguration();
                },
                true
            );

            _this.parseConfiguration();
        });
    };

    Analytics.trackEvent('settings_alerts_and_reminders');
}

const hfSettingsAlertsAndReminders = angular.module('happyForceApp')
    .directive('hfSettingsAlertsAndReminders', function () {
        return {
            template: require('./settings_alerts_reminders.html'),
            controller: SettingsAlertsAndRemindersCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                companyConfiguration: '<',
                onUpdate: '&',
                onSave: '&'
            }
        };
    });

export default hfSettingsAlertsAndReminders;