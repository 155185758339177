'use strict';

import template from './silos_config.html';

import {getHierarchyName} from "../../../../../../shared/services/migrated/hierarchy.service";
import SilosEditorModal from "./components/silos_editor_modal/silos_editor_modal.component";

function SilosConfigCtrl(Restangular, AuthSrvc, $scope, Silos, $translate, $rootScope, Analytics, SilosEditorModal) {
    'ngInject';
    var _this = this;


    const loadSilos = function () {
        Silos.getAll(function (silos) {
            _this.silos = silos;
            _this.silos.forEach(function (silo) {
                getHierarchyName(silo.startHierarchyId, function (err, name) {
                    silo.startHierarchyName = name;
                });
            });
        }, function (err) {

            console.log('Error', err);
        });
    };


    _this.addSilo = function () {

        SilosEditorModal.showAdd(function () {
            swal($translate.instant('SILO_CREATION_SUCCESS'), '', 'success');
            loadSilos();
        });
    };

    _this.editSilo = function (silo) {
        silo.level = {
            name: silo.startHierarchyName,
            id: silo.startHierarchyId
        };

        const toEditSilo = angular.copy(silo);
        toEditSilo.level = {
            name: silo.startHierarchyName,
            id: silo.startHierarchyId
        };

        SilosEditorModal.showEdit(toEditSilo, function (updatedSilo) {
            // If updatedSilo is not set, it means the user has deleted the silo
            if (!updatedSilo) {
                swal($translate.instant('SILO_REMOVED'), '', 'success');
            } else {
                swal($translate.instant('SILO_UPDATED_SUCCESS'), '', 'success');
            }

            loadSilos();
        });
    };


    loadSilos();

}



const silosConfigComponent = angular.module('happyForceApp')
    .directive('silosConfig', function () {
        return {
            template: template,
            controller: SilosConfigCtrl,
            controllerAs: 'slctrl',
            bindToController: true
        };
    });

export default silosConfigComponent;
