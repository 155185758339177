'use strict';

import {
    deleteHierarchy,
    postHierarchy,
    putHierarchy
} from "../../../../../../shared/services/migrated/hierarchy.service";

function HierarchyEditorModalCtrl($scope, AuthSrvc, CompanySvrc, ErrorSvrc, $modalInstance, selectedLevel) {
    'ngInject'
    $scope.isAddLevel = false;
    $scope.isEditLevel = false;
    $scope.isDeleteLevel = false;
    $scope.selectedLevel = selectedLevel;
    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';

    $scope.initAddLevel = function () {
        $scope.isAddLevel = true;
    };

    $scope.initEditLevel = function () {
        $scope.isEditLevel = true;
    };

    $scope.initDeleteLevel = function () {
        $scope.isDeleteLevel = true;
    };

    $scope.ok = function () {
        $scope.executing = true;

        if ($scope.isAddLevel === true) {
            postHierarchy($scope.selectedLevel, function (updatedOrgdata) {
                    $modalInstance.close(updatedOrgdata);
                },

                function (response) {
                    $scope.handleErrorResponse(response);
                });
        } else if ($scope.isEditLevel === true) {
            putHierarchy($scope.selectedLevel, $scope.selectedLevel.id, function (updatedOrgdata) {
                    $modalInstance.close(updatedOrgdata);
                },
                function (response) {
                    $scope.handleErrorResponse(response);
                });
        } else if ($scope.isDeleteLevel === true) {
            deleteHierarchy($scope.selectedLevel.id, function (updatedOrgdata) {
                $modalInstance.close(updatedOrgdata);
            },
            function (response) {
                $scope.handleErrorResponse(response);
            });
        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.handleErrorResponse = function (response) {
        $scope.executing = false;
        $scope.errored = true;
        $scope.errorMsg = ErrorSvrc.getErrorMessage(response);
    };

}





const HierarchyEditorModal = angular.module('happyForceApp')
    .factory('HierarchyEditorModal',
        function ($uibModal) {
            var methods = {};
            methods.showAdd = function (selectedLevel, callback) {
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./add.html'),
                    controller: HierarchyEditorModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        isAddLevel: true,
                        isEditLevel: false,
                        isDeleteLevel: false,
                        selectedLevel: selectedLevel
                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            methods.showEdit = function (selectedLevel, callback) {
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./edit.html'),
                    controller: HierarchyEditorModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        isAddLevel: false,
                        isEditLevel: true,
                        isDeleteLevel: false,
                        selectedLevel: selectedLevel
                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            methods.showDelete = function (selectedLevel, callback) {
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./delete.html'),
                    controller: HierarchyEditorModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        isAddLevel: false,
                        isEditLevel: false,
                        isDeleteLevel: false,
                        selectedLevel: selectedLevel

                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );
export default HierarchyEditorModal;
