import {useEffect, useMemo, useState} from 'react';
import {getService} from 'reactInAngular';


const usePersonName = (personId: string) => {
    const People = getService('People');


    const [personName, setPersonName] = useState<string>('');

    useEffect(() => {
        if (personId) {
            People.getProfile(personId, function (err: any, person: any) {
                if (err) {
                    setPersonName('?')
                } else {
                    setPersonName(person.name);
                }
            });
        }

    }, [personId]);


    const memoizedPersonName = useMemo(() => personName, [personName]);

    return {
        personName: memoizedPersonName
    }
}

export default usePersonName;
