import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dates from '../../../../utilities/date.utilities';
import EngagementScoresSummaryRow from './components/score_sumary_row';
import {angularize, getService} from 'reactInAngular';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import ScoreStatusEmptyState from '../../shared/score_status_empty_state/score_status_empty_state';
// Import other necessary services and components

const EngagementScoresSummaryBox = ({ to, dateGrouping }) => {
    const [scores, setScores] = useState([]);
    const [allDisabled, setAllDisabled] = useState(false);

    const Scores = getService('Scores');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    useEffect(() => {
        Scores.list({},(err, results) => {
            if (err) {
                // Handle errors
                ErrorSvrc.showErrorModal(err);
            } else {
                setScores(results);
            }
        });
    }, []);



    return (
        <section className="scores_box white-bg">


            <div className="row">
                <div className="col-xs-12">
                    <h2>
                        {$translate.instant('ENGAGEMENT_SCORES_SUMARY_BOX')}
                    </h2>
                    <p className="mini-style warm-grey m-t-xs"
                        dangerouslySetInnerHTML={{
                            __html: $translate.instant('ENGAGEMENT_SCORES_SUMMARY_AS_OF',
                                {days:  moment(to).format(Dates.getDateFormatFromGrouping(dateGrouping))})
                        }}/>

                </div>
            </div>

            <div>
                {scores.map((score, index) => (
                    <div key={index} className="m-t-xs">
                        <EngagementScoresSummaryRow
                            score={score}
                            to={to}
                            dateGrouping={dateGrouping}
                        />

                        {index < scores.length - 1 &&  (
                            <hr className="hfline" />
                        )}
                    </div>
                ))}
            </div>

        </section>
    );
};

EngagementScoresSummaryBox.propTypes = {
    to: PropTypes.instanceOf(Date).isRequired,
    dateGrouping: PropTypes.string.isRequired,
};

angularize(EngagementScoresSummaryBox, 'hfEngagementScoresSummaryBox', angular.module('happyForceApp'), {
    to: '<',
    dateGrouping: '<'
});

export default EngagementScoresSummaryBox;
