'use strict';

function RecognitionConfig($stateProvider) {
    'ngInject'
    $stateProvider
    .state('feedback.recognition', {
        url: '/recognition',
        template: '<hf-recognition-section></hf-recognition-section>',
        data: { pageTitle: 'RECOGNITION_SECTION', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
    });
}

const hfRecognitionConfig = angular.module('happyForceApp')
.config(RecognitionConfig);

export default hfRecognitionConfig;
