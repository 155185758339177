import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React from 'react';



interface VariationArrowProps {
    variation: number;
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}


const VariationArrow = ({variation, fontSize = 'medium'}: VariationArrowProps) => {

    if (variation > 0) {
        return   <ArrowDropUpIcon fontSize={fontSize} color={'success'} />
    } else if (variation < 0) {
        return   <ArrowDropDownIcon fontSize={fontSize} color={'error'} />
    } else if (variation === 0) {
        return   <DragHandleIcon fontSize={fontSize} color={'disabled'} />
    }

    return null;
}

export default VariationArrow;
