import Dates from "../../utilities/date.utilities";

const QuestionsService = function (Restangular, Settings, Rollbar, $log, $uibModal) {
    'ngInject';
    var Company = Restangular.one('company');

    var CRUD = {
        GET: function (params) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
            }

            if (_params.to) {
                _params.to =  Dates.toAPIFormat(params.to, Settings.apiDateFormat);
            }

            $log.debug('Questions.find()', params);
            return Company.one('questions').one('find').get(params);
        },

        PUT: function (params) {
            return Company.one('questions').customPUT(params, params.id);
        },

        POST: function (params) {
            return Company.customPOST(params, 'questions');
        },

        DELETE: function (questionId) {
            return Company.one('questions').one(questionId).remove(); //.then(callback, error);
            //return Company.customDELETE(params.id, 'questions');
        },

        CHANGEACTIVE: function (questionId, state) {
            return Company.one('questions').one(questionId).one('active').one(state).put(); //.then(callback, error);
        },

        ARCHIVE: function (questionId) {
            return Company.one('questions').one(questionId).one('archive').put();
        }
    };

    // NEW METHODS
    var methods = {};

    methods.create = function (params, callback) {
        CRUD.POST(params)
            .then(function (question) {
                $log.debug('Questions.create Response', question, params);
                callback(null, question);
            }, function (err) {

                Rollbar.error('Error creating question', err, params);
                callback(err.data && err.data.errorMessage);
            });
    };

    methods.update = function (params, callback) {
        CRUD.PUT(params)
            .then(function (question) {
                $log.debug('Questions.update Response', question, params);
                callback(null, question);
            }, function (err) {

                Rollbar.error('Error updating question', err, params);
                callback(err.data && err.data.errorMessage);
            });
    };

    methods.find = function (params, callback) {
        CRUD.GET(params)
            .then(function (questions) {
                $log.debug('Questions.find Response', questions, params);
                callback(null, questions);
            }, function (err) {

                Rollbar.error('Error loading questions', err, params);
                callback(err);
            });
    };

    methods.remove = function (questionId, callback) {
        CRUD.DELETE(questionId)
            .then(function (question) {
                $log.debug('Questions.remove Response', question, questionId);
                callback(null, question);
            }, function (err) {

                Rollbar.error('Error removing questions', err, questionId);
                callback(err.data && err.data.errorMessage);
            });
    };

    methods.archive = function (questionId, callback) {
        CRUD.ARCHIVE(questionId)
            .then(function (question) {
                $log.debug('Questions.archived Response', question, questionId);
                callback(null, question);
            }, function (err) {

                Rollbar.error('Error archiviving questions', err, questionId);
                callback(err.data && err.data.errorMessage);
            });
    };

    var Stats = Company.one('stats');
    var stats = {};

    stats.get = function (params, callback, error) {
        Stats.one('questions')
            .one('from', Dates.toAPIFormat(params.from, Settings.apiDateFormat))
            .one('to', Dates.toAPIFormat(params.to, Settings.apiDateFormat))
            .get(params.extras || null).then(callback, error);
    };

    methods.stats = stats;
    methods.CRUD = CRUD;



    return methods;
};

export default QuestionsService;
