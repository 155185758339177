import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import async from 'async';
import EngagementCharts from '../../../engagement.charts';
import Style from '../../../../../utilities/style.utils';
import Dates from '../../../../../utilities/date.utilities';
import {getHierarchyName} from '../../../../../shared/services/migrated/hierarchy.service';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {
    CharacteristicName,
    GroupName,
    HierarchyName
} from '../../../../../shared/new-components/hf-segmentation-name/hfSegmentationName';

const ScorePositioningBox = ({ to, score, navigateToDetail, dateGrouping }) => {
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const Languages = getService('Languages');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');

    const [positioningBy, setPositioningBy] = useState('HIERARCHIES');
    const positioningByOptions = [
        { id: 'HIERARCHIES', label: $translate.instant('AREAS') },
        { id: 'GROUPS', label: $translate.instant('GROUPS') },
        { id: 'CHARACTERISTICS', label: $translate.instant('SEGMENTS') }
    ];
    const [data, setData] = useState([]);
    const [bubbleChart, setBubbleChart] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [noTypes, setNoTypes] = useState(false);
    const [scoreName, setScoreName] = useState('');
    const [loading, setLoading] = useState(false);

    const computeColor = (count) => {
        const remainder = count % Style.colors.length;
        return Style.colors[remainder === 0 ? Style.colors.length - 1 : remainder - 1];
    };

    const gotoItemDetail = (item) => {
        const linkParams = { to };
        if (item.hierarchyId) {
            linkParams.hierarchyId = item.hierarchyId;
        } else if (item.groupId) {
            linkParams.groupId = item.groupId;
        } else if (item.characteristic) {
            linkParams.filters = `${item.characteristic.characteristicId}:${item.characteristic.value}`;
        }
        navigateToDetail('engagement.scoreDetail', linkParams);
    };


    const getSegmentationName = (segment, callback) => {
        if (positioningBy === 'HIERARCHIES') {
            getHierarchyName(segment.hierarchyId, (err, name) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, name);
                }
            });
        } else if (positioningBy === 'GROUPS') {
            callback(null, Groups.getGroupName(segment.groupId));
        } else if (positioningBy === 'CHARACTERISTICS') {
            let name = '';
            const characteristic = Characteristics.getCharacteristicById(segment.characteristic.characteristicId);
            if (characteristic) {
                name = `${characteristic.description}-${Languages.getTranslationFromUserLanguage(
                    Characteristics.getCharacteristicValueName(segment.characteristic.characteristicId, segment.characteristic.value)
                )}`;
            }
            callback(null, name);
        }

    }

    const loadResults = () => {

        setLoading(true);
        setBubbleChart(null);
        const toFormatted = Dates.lastDayOfMonth(to);
        const params = {
            scoreId: score,
            from: Dates.firstDayOfMonth(to),
            to: toFormatted,
            grouping: dateGrouping
        };



        ScoresV2Srvc.stats.heatmap(positioningBy, params, (err, response) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }

            const hasData = response && response.results && response.results.length > 0;
            setNoTypes(!hasData);

            if (hasData) {
                const result = response.results[0];
                const stream = [];
                let count = 0;

                result.results
                    .filter(item => item.hierarchyId || item.groupId || item.characteristic)
                    .forEach(item => {


                        if (!item.nonEnoughEmployees) {

                            stream.push(next => {
                                const finalItem = {
                                    characteristic: item.characteristic,
                                    groupId: item.groupId,
                                    hierarchyId: item.hierarchyId,
                                    value: item.result.result,
                                    participants: item.result.participants,
                                    expectedParticipants: item.result.expectedParticipants,
                                    color: computeColor(++count),
                                    selected: true  // Initially, set all items as selected
                                }
                                getSegmentationName(item, (err, name) => {
                                    if (err) {
                                        next(err, null);
                                    } else {
                                        finalItem.name = name;
                                        next(null, finalItem);
                                    }
                                });
                            });

                        }
                    });


                async.parallel(stream, (err, results) => {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        return;
                    } else {
                        setData(results);
                    }
                })
            }

            setLoading(false);
        });
    };

    useEffect(() => {
        loadResults();
    }, [to, dateGrouping, positioningBy]);


    useEffect(() => {
        ScoresV2Srvc.getScore(score, function (err, score) {
            if (err) {
                setScoreName('?');
            } else {
                setScoreName(Languages.getTranslationFromUserLanguage(score.name, null));
            }
        });


    }, [score]);

    useEffect(() => {
        setAllSelected(data.every(item => item.selected));
        if (scoreName)
        {setBubbleChart(EngagementCharts.scorePositioningChart(data, 0, 10, scoreName, 'SCORE', $translate));}
    }, [data, scoreName]);

    const handleSelectAll = () => {
        const isSelectAll = !allSelected;
        setData(data.map(item => ({ ...item, selected: isSelectAll })));
    };

    const handleSelect = (item) => {

        const newData = data.map(dataItem => {

            const match =
                (item.hierarchyId && item.hierarchyId === dataItem.hierarchyId) ||
                (item.groupId && item.groupId === dataItem.groupId) ||
                (item.characteristic && item.characteristic.characteristicId === dataItem.characteristic.characteristicId && item.characteristic.value === dataItem.characteristic.value);

            if (match) {
                return { ...dataItem, selected: !dataItem.selected };
            }

            return dataItem;
        });
        setData(newData);
    };

    const renderItemForType = (item) => {
        if (item.hierarchyId) {
            return (<HierarchyName  id={item.hierarchyId}/>);
        } else if (item.groupId) {
            return (<GroupName id={item.groupId}/>
            );
        } else if (item.characteristic) {
            return (<CharacteristicName id={item.characteristic.id}
                valueId={item.characteristic.value}/>);
        } else {
            // TODO: log error
            return undefined;
        }
    };

    return (
        <section className="score-positioning-box">
            <div className="row">
                <div className="col-xs-12 col-md-10">
                    <h2>{ $translate.instant('SCORE_POSITIONING') }</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-push-9 col-md-3">
                    <FormControl fullWidth>
                        <InputLabel id="positioning-by-label">{ $translate.instant('SHOW_BY') }</InputLabel>
                        <Select
                            labelId="positioning-by-label"
                            value={positioningBy}
                            onChange={(e) => setPositioningBy(e.target.value)}
                            label={ $translate.instant('SHOW_BY') }
                        >
                            {positioningByOptions.map(option => (
                                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <HappyLoading loading={loading} message={'Loading data...'} >
                {!noTypes && (
                    <div className="row">
                        <div className="col-xs-3">
                            <div className="small-style m-t-sm">
                                { $translate.instant('ENGAGEMENT_POSITIONING_FILTER') }
                            </div>
                            <div className="small-style m-t-sm">
                                <a onClick={handleSelectAll}>{allSelected ? $translate.instant('UNSELECT_ALL') : $translate.instant('SELECT_ALL') }</a>
                            </div>
                            <div className="m-t-xs positioning_chart_selector">
                                <FormGroup>
                                    {data.map((item, index) => (


                                        <FormControlLabel
                                            key={item.hierarchyId || item.groupId || item.characteristic.characteristicId}
                                            control={<Checkbox checked={item.selected} onClick={() =>{handleSelect(item)}} />}
                                            label={renderItemForType(item)}>
                                        </FormControlLabel>
                                    ))}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-xs-9">
                            {bubbleChart && <HighchartsReact highcharts={Highcharts} options={bubbleChart} />}
                        </div>
                    </div>
                )}

                {noTypes && !loading && (
                    <div className="row">
                        <div className="col-xs-12">
                            {/* You can include your 'hf-no-segments' component here, translated to React */}
                        </div>
                    </div>
                )}
            </HappyLoading>

        </section>
    );
};

ScorePositioningBox.propTypes = {
    to: PropTypes.any,
    score: PropTypes.any,
    navigateToDetail: PropTypes.func,
    dateGrouping: PropTypes.any
};

angularize(ScorePositioningBox, 'hfScorePositioningBox', angular.module('happyForceApp'), {
    to: '<',
    score: '<',
    navigateToDetail: '<',
    dateGrouping: '<'
});

export default ScorePositioningBox;
