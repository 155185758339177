'use strict';

function SettingsNotificationsCtrl($scope, Analytics) {
    'ngInject';

    var _this = this;

    $scope.isDayIncluded = function (list, day) {
        return list && list.indexOf(day) != -1;
    };

    $scope.updateSelection = function (list, day) {
        if ($scope.isDayIncluded(list, day)) {
            var index = list.indexOf(day);
            list.splice(index, 1);
        } else {
            list.push(day);
        }
    };

    Analytics.trackEvent('settings_notifications');
}

const hfSettingsNotifications = angular.module('happyForceApp')
    .directive('hfSettingsNotifications', function () {
        return {
            template: require('./settings_notifications.html'),
            controller: SettingsNotificationsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSettingsNotifications;