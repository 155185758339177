'use strict';

function CompanySelectorCtrl(CompanySvrc, AuthSrvc, $state, ErrorSvrc, $window, Restangular) {
    'ngInject'

    var _this = this;
    this.$onInit = function () {
        var pageSize = 50;
        _this.user = AuthSrvc.user();
        _this.companyDetails = null;
        _this.selectedCompany = null;
        _this.showSelector = false;
        _this.loading = true;
        _this.canChangeCompany = false;
        _this.errorMsg = '';
        _this.incognitoMode = false;
        _this.companies = [];
        _this.companiesIds = [];

        if (AuthSrvc.isADVISOR()) {
            var advisors = JSON.parse(localStorage.getItem('advisors'));
            if (advisors && advisors.incognitoMode) {
                _this.incognitoMode = advisors.incognitoMode;
            }
        }

        _this.getCompanyImage = function () {
            if (_this.incognitoMode) {
                return 'images/avengers-logo.jpg';
            } else if (!_this.companyDetails || typeof _this.companyDetails.imageUrl === 'undefined') {
                return 'images/placeholder_enterprise.png';
            } else {
                return _this.companyDetails.imageUrl;
            }
        };

        _this.ok = function () {
            CompanySvrc.resetCompanyStatus();
            _this.changeCompany(_this.selectedCompany);
        };

        _this.showSelect = function () {
            _this.showSelector = !_this.showSelector;

        };

        _this.loadCompanies = function (from, to) {
            /*
                TODO: ESTO ES LO MAS GITANO QUE HE  HECHO EN MI VIDA ->
                 we have more than 800 Companies on the service, so we need to load them paginating or we can reach timeouts on the API
                 (at least right now, is happening on staging).
                 However the ugly ui-select doesn't allows you to load by pagination while scrolling, so we need to load all companies in batches
             */
            CompanySvrc.getAllCompanies(function (err, companies) {
                if (err) {
                    _this.executing = false;
                    _this.errored = true;
                    _this.errorMsg = ErrorSvrc.getErrorMessage(err);
                } else {
                    companies.result.forEach(function (company) {
                        if (_this.companiesIds.indexOf(company.id) == -1) {
                            _this.companies.push(company);
                            _this.companiesIds.push(company.id);
                        }

                    });

                    if (_this.companies.length > 1)
                        _this.canChangeCompany = true;

                    if (companies.total > companies.to) {
                        _this.loadCompanies(companies.from + pageSize, companies.to + pageSize);
                    } else {
                        _this.loading = false;
                    }


                }
            }, null, from, to);
        };


        _this.changeCompany = function (id) {
            CompanySvrc.changeCompany(id, function (companyDetails) {
                AuthSrvc.me(function (err, result) {
                    $state.go('private.dashboard');
                    $window.location.reload();
                });

            });
        };


        // Ge the company
        CompanySvrc.getUserCompany(function (err, company) {
            if (err) {
                _this.errorMsg = ErrorSvrc.getErrorMessage(err);
            } else {
                _this.companyDetails = Restangular.copy(company);
                CompanySvrc.getCompanyConfig(company.id, function (err, companyConfig) {
                    if (err)
                        console.log('Error updating company config' + err);
                    else
                        _this.companyConfig = companyConfig;
                });
            }
        });


        _this.loadCompanies(0, pageSize);
    };
}


'use strict';

//Directive used to set metisMenu and minimalize button
const hfCompanyControl = angular.module('happyForceApp')
    .directive('hfCompanyControl', function () {
        return {
            template: require('./company-selector.html'),
            controller: CompanySelectorCtrl,
            controllerAs: 'cs',
            bindToController: true
        };
    });

export default hfCompanyControl;

