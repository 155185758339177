var Numbers =  {};

Numbers.roundNumber = function (number, decimals) {
    if (!decimals)
        decimals = 0;
    if (!number)
        number = 0;

    return Number(number.toFixed(decimals));
};

Numbers.calculateVariation = function (previousValue, currentValue) {
    if (previousValue === 0) {
        return Numbers.roundNumber(0);
    }

    return Numbers.roundNumber(currentValue - previousValue);
};


Numbers.calculatePercent = function (pct, number) {
    if (number < 1)
     return 100;
    else
     return pct * 100 / number;
};


export default Numbers;
