function GroupsConfigOperationsCtrl($scope, $uibModalInstance, AuthSrvc, Groups, $translate, $rootScope, selected, edit) {
    var _this = this;

    _this.AuthSrvc = AuthSrvc;
    _this.cancel = function () {
        $uibModalInstance.dismiss('close');
    };

    _this.targetGroupName = function () {
        return Groups.getGroupName(_this.group.targetGroup);
    };

    _this.edit = edit;
    _this.group = (function (selected) {
        var policies = [];

        selected.exitPolicies.forEach(function (policy) {
            if (policy.policyType === 'ELAPSED_DAYS') {
                selected.ELAPSED_DAYS = policy.days;
            } else if (policy.policyType === 'ALL_QUESTIONS') {
                selected.ALL_QUESTIONS = policy.policyType;
            } else if (policy.policyType === 'NONE' || !policy.policyType) {
                selected.NONE = 'NONE';
            }

            !selected.NONE && policies.push(policy.policyType);
            selected.exitAction = (policy.exitAction && policy.exitAction.actionType) || 'NONE';
            selected.targetGroup = (policy.exitAction && policy.exitAction.targetGroup) || null;
        });

        selected.oldPolicies = selected.exitPolicies;
        selected.exitPolicies = policies;

        selected.exitOptions = _this.exitOptions = (selected.exitPolicies.length);

        if (!selected.ELAPSED_DAYS) {
            selected.ELAPSED_DAYS = 1;
        }

        return selected;
    })(selected);

    if (!edit) {
        _this.group.exitAction = 'REMOVE_GROUP';
        _this.group.ELAPSED_DAYS = 1;
        _this.group.exitOptions = _this.exitOptions = false;
    }

    var policies =  {
        NONE: function () {
            return Groups.addExitPolicy({
                type: 'NONE'
            });
        },

        ELAPSED_DAYS: function (value) {
            return Groups.addExitPolicy({
                type: 'ELAPSED_DAYS',
                days: value || 0
            });
        },

        ALL_QUESTIONS: function (value) {
            return Groups.addExitPolicy({
                type: 'ALL_QUESTIONS'
            });
        }
    };

    function prepareGroup(rawGroup) {
        var group = Groups();

        if (rawGroup.name)
            group.name = rawGroup.name;

        if (rawGroup.allowedHierarchies)
            group.allowedHierarchies = rawGroup.allowedHierarchies;

        group.exitPolicies = [];
        if (_this.exitOptions && rawGroup.exitPolicies && rawGroup.exitPolicies.length) {
            rawGroup.exitPolicies
                 .forEach(function (key) {
                    if (policies[key]) {
                        var policy = policies[key](rawGroup[key]);
                        policy.exitAction = Groups.addExitAction(_this.group.exitAction, rawGroup);
                        group.exitPolicies.push(policy);
                    }
                });
        } else {
            var policy = Groups.addExitPolicy({
                type: 'NONE'
            });
            policy.exitAction = Groups.addExitAction('NONE');
            group.exitPolicies.push(policy);
        }

        group.groupFlags = rawGroup.groupFlags;
        return group;
    }

    $scope.working = null;

    _this.submit = function () {
        $scope.working = true;
        var finalGroup = prepareGroup(_this.group);
        Groups.create(finalGroup, function (err, groupCreated) {
            $scope.working = null;

            if (err) {
                swal($translate.instant('GROUP_CREATION_ERROR'), $translate.instant(err), 'error');
                return;
            }

            $uibModalInstance.close(groupCreated);
            swal($translate.instant('GROUP_CREATION_SUCCESS'), '', 'success');
        });
    };

    _this.update = function () {
        $scope.working = true;
        var finalGroup = prepareGroup(_this.group);
        Groups.update(_this.group.id, finalGroup, function (err, groupUpdated) {
            $scope.working = null;
            if (err) {
                swal($translate.instant('GROUP_CREATION_ERROR'), $translate.instant(err), 'error');
                return;
            }

            $uibModalInstance.close(groupUpdated);
            swal($translate.instant('GROUP_CREATION_UPDATED'), '', 'success');
        });
    };

    _this.delete = function () {
        $scope.working = true;
        Groups.remove(_this.group.id, function (err, data) {
            $scope.working = null;
            $uibModalInstance.close();
            swal($translate.instant('GROUP_DELETED'), '', 'success');
        });
    };

    _this.goToSelector = function () {
        angular.element('.modal-groups').addClass('showIntro');
    };

    _this.wizardTitle = edit ? $translate.instant('GROUP_EDIT_TITLE') : $translate.instant('GROUP_ADD');
    _this.wizardSubtitle = null;

    _this.wizardOptions = {
        nextButton: $translate.instant('QUESTION_NEXT'),
        prevButton: $translate.instant('QUESTION_PREV'),
        confirm: {
            text: edit ? $translate.instant('GROUP_EDIT') : $translate.instant('GROUP_ADD_SUBMIT'),
            className: 'btn ok-button pull-right uppercase',
            action: function () {
                if (edit) {
                    _this.update();
                } else {
                    _this.submit();
                }

                console.log('CONFIRM');
            }
        },
        return: {
            text: edit ? $translate.instant('DELETE') : $translate.instant('RETURN'),
            className: edit ? 'button-delete btn-danger' : 'hf-secondary',
            action: function () {
                if (edit) {
                    _this.delete();
                } else {
                    _this.goToSelector();
                }

                console.log('RETURN');
            }
        },
        validate: validateStep,
        exit: function () {
            _this.cancel();
        }
    };

    _this.wizardIntroOptions = {
        name: 'groups',
        image: (new Date().getTime() % 2) ? 'images/create_group.png' : 'images/create_group2.png',
        title: _this.wizardTitle,
        subtitle: _this.wizardSubtitle,
        explication: $translate.instant('GROUP_DESCRIPTION'),
        cancel: {
            text: $translate.instant('EXIT'),
            action: function () {
                _this.cancel();
            }
        },
        success: {
            text: $translate.instant('NEW_GROUPS')
        }
    };

    function findStep(classes) {
        if (!classes) return;
        var findStep = classes.match(/step-(\d)/);
        var actual = 1;
        if (findStep && findStep[1]) {
            actual = parseInt(findStep[1]);
        }

        return actual;
    }

    function validateStep() {
        var actualStep = findStep(angular.element('.modal-groups').attr('class'));
        var step = '.step-' + actualStep;

        angular.element(step + ' .error').removeClass('error');
        angular.forEach(angular.element(step + ' [required]'), function (item) {
            if (item.value === '') {
                var $item = angular.element(item);

                if ($item.parent().attr('class') === 'special-input-input') {
                    $item = $item.parent();
                }

                $item.addClass('error');
            }
        });

        return (angular.element(step + ' .error').length === 0);

    }
}

const GroupAddModal = angular.module('happyForceApp')
    .factory('GroupAddModal',
        function ($uibModal, Groups) {
            'ngInject'

            var methods = {};

            methods.showModal = function (callback) {
                $uibModal.open({
                    backdrop: 'static',
                    template: require('./group_add.html'),
                    controller: GroupsConfigOperationsCtrl,
                    controllerAs: 'slc',
                    size: 'groups add showIntro',
                    bindToController: true,
                    resolve: {
                        selected: function () {
                            return Groups();
                        },
    
                        edit: function () {
                            return false;
                        }
    
                    }
                }).result.then(function (newGroup) {
                    //loadGroups();
                    callback(newGroup);
                    Analytics.trackEvent('settings_company_groups', 'add', 'success');
                }, function (err) {
    
                    Analytics.trackEvent('settings_company_groups', 'add', 'fail');
    
                    console.error('ERORR!!', err);
                });
            };

            return methods;
        }
    );

export default GroupAddModal;
