import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { angularize, getService } from 'reactInAngular';
import moment from 'moment';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Numbers from '../../../../utilities/number.utilities';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import Charts from '../../../../utilities/charts.utilities';

const SurveyContinuousEvolutionView = ({ survey, groupIds, segmentIds, hierarchyIds, questionFilters, fromDateEnabled, fromDate, toDateEnabled, toDate }) => {
    const $translate = getService('$translate');
    const Surveys = getService('Surveys');
    const ErrorSvrc = getService('ErrorSvrc');
    const [chartConfig, setChartConfig] = useState(null);
    const [dateGrouping, setDateGrouping] = useState('MONTH');
    const [loading, setLoading] = useState(true);
    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        setLoading(true);
        setChartConfig(null); // Clear previous chart

        const params = {
            grouping: dateGrouping,
            ...(hierarchyIds && { hierarchyId: hierarchyIds }),
            ...(segmentIds && { filters: segmentIds.join(',') }),
            ...(groupIds && { groupId: groupIds.join(',') }),
            ...(questionFilters && { questionResults: questionFilters.join(',') }),
            ...(fromDateEnabled && { from: fromDate }),
            ...(toDateEnabled && { to: toDate })
        };

        Surveys.stats.evolution(survey.id, params, function (err, evolution) {
            setLoading(false);
            if (err) {
                ErrorSvrc.showErrorModal(error);
            } else {
                if (evolution && evolution.results) {
                    const evolutionData = evolution.results.map(result => ({
                        index: result.periodIndex,
                        participants: result.participants,
                        date: result.from
                    }));
                    if (evolutionData.length > 0) {
                        setHasData(true);
                        buildChart(evolutionData);
                    } else {
                        setHasData(false);
                    }
                } else {
                    setHasData(false);
                }
            }
        });
    }, [survey, dateGrouping, groupIds, segmentIds, hierarchyIds, questionFilters, fromDateEnabled, fromDate, toDateEnabled, toDate, Surveys, ErrorSvrc, $translate]);

    const buildChart = (evolutionData) => {
        const participants = evolutionData.map(item => ({
            x: moment(item.date).toDate(),
            y: item.participants > 0 ? item.participants : null
        }));
        const index = evolutionData.map(item => ({
            x: moment(item.date).toDate(),
            y: item.participants > 0 ? Numbers.roundNumber(item.index,2) : null
        }));

        const newChartConfig = {
            chart: {
                type: 'spline',
                height: 190,
                backgroundColor: 'rgba(255, 255, 255, 0.0)'
            },
            title: null,
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat(Charts.getDateFormatFromDateGrouping(dateGrouping), this.value); // Formato de fecha personalizado para las etiquetas del eje X
                    }
                }
            },
            yAxis: [{
                title: { text: $translate.instant('SURVEY_RESULTS_SCORE') }
            }, {
                title: { text: $translate.instant('DASHBOARD_PARTICIPANTS') },
                opposite: true
            }],
            series: [{
                name: $translate.instant('DASHBOARD_PARTICIPANTS'),
                data: participants,
                yAxis: 1,
                type: 'column',
                connectNulls: true
            }, {
                name: $translate.instant('SURVEY_RESULTS_SCORE'),
                data: index,
                connectNulls: true
            }],
            tooltip: {
                shared: true,
                xDateFormat: Charts.getDateFormatFromDateGrouping(dateGrouping),
                formatter: function () {
                    return this.points.reduce(function (s, point) {
                        return s + '<br/>' + point.series.name + ': ' +
                            point.y + (point.series.options.yAxis === 1 ? ' participants' : ' score');
                    }, '<b>' + Highcharts.dateFormat(Charts.getDateFormatFromDateGrouping(dateGrouping), this.x) + '</b>');
                }
            }
        };

        setChartConfig(newChartConfig);
    };

    return (
        <HappyLoading loading={loading}>
            <div className="row">
                <div className="col-xs-8">
                    <h2>{ $translate.instant('SURVEY_EVOLUTION') }</h2>
                    <div className="small-style warm-grey m-t-xs">{ $translate.instant('SURVEY_EVOLUTION_DESCRIPTION') }</div>
                </div>
                <div className="col-xs-4">
                    <FormControl fullWidth>
                        <Select
                            value={dateGrouping}
                            onChange={(event) => setDateGrouping(event.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="MONTH">Monthly</MenuItem>
                            <MenuItem value="QUARTER">Quarterly</MenuItem>
                            <MenuItem value="YEAR">Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

            {hasData && chartConfig && (
                <div className="row p-t">
                    <div className="col-xs-12">
                        <HighchartsReact highcharts={Highcharts} options={chartConfig} />
                    </div>
                </div>
            )}

            {!hasData && (
                <div className="row p-t">
                    <div className="col-xs-12 text-center">

                        <EmptyState
                            image="images/no_results.svg"
                            small={true}
                            message={$translate.instant('NO_DATA')}
                            submessage={$translate.instant('NO_DATA_EVOLUTION_SUBMESSAGE')}
                        />
                    </div>
                </div>
            )}
        </HappyLoading>
    );
};

angularize(SurveyContinuousEvolutionView, 'hfSurveyContinuousEvolutionView', angular.module('happyForceApp'), {
    survey: '=',
    groupIds: '=',
    segmentIds: '=',
    hierarchyIds: '=',
    questionFilters: '=',
    fromDateEnabled: '=',
    fromDate: '=',
    toDateEnabled: '=',
    toDate: '='
});

export default SurveyContinuousEvolutionView;
