'use strict';

import {getHierarchyAll, getHierarchyName} from "../../../../services/migrated/hierarchy.service";
import async from "async";

var HierarchyTaggingFilterCtrl = function ($rootScope, $window, $translate) {
    'ngInject'

    var _this = this;

    _this.hierarchyFilters = [];

    function removeFilter(id) {
        // First, remove the element from the hierarchies list
        if (_this.mode == 'multi') {
            _this.hierarchyFilters = _.without(_this.hierarchyFilters, id);
        } else {
            _this.hierarchyFilters = [];
        }
        _this.notifyFilterChanges();
    }




    function renderTags() {

        // Based on the mode, we need to render the tags differently
        if (_this.mode == 'multi') {
            // Multi mode:
            if (_this.hierarchyFilters.length > 0) {
                _this.hierarchyFilters.forEach(function (hierarchyId) {
                    if (hierarchyId)
                        getHierarchyName(hierarchyId, function (err, name) {
                            _this.tagFilterMenu.insertFilterTag(hierarchyId, _this.type, name, _this.typeLabel,  _this.nonRemovable, removeFilter);
                        });
                    else
                        _this.tagFilterMenu.insertFilterTag(hierarchyId, _this.type, $translate.instant('ALL_AREAS'),
                            _this.typeLabel,  _this.nonRemovable,
                            removeFilter);
                });
            }
        } else if (_this.mode == 'single') {
            // Single mode:
            if (_this.hierarchyFilters.length > 0) {
                const hierarchyId = _this.hierarchyFilters[0];
                if (hierarchyId)
                    getHierarchyName(hierarchyId, function (err, name) {
                        _this.tagFilterMenu.insertFilterTag('dummy', _this.type, name, _this.typeLabel,  _this.nonRemovable, removeFilter);
                    });
                else
                    _this.tagFilterMenu.insertFilterTag('dummy', _this.type, $translate.instant('ALL_AREAS'), _this.typeLabel,  _this.nonRemovable,
                        removeFilter);
            } else {
                _this.tagFilterMenu.removeTag('dummy', _this.type);
            }
        } else {
            // Grouped mode:
            if (_this.hierarchyFilters.length > 0) {
                var streams = [];
                _this.hierarchyFilters.forEach(function (hierarchyId) {
                    // Only add the names already not added:
                    streams.push(function (next) {
                        if (hierarchyId)
                            getHierarchyName(hierarchyId, function (err, name) {
                                if (err) {
                                    next(err, '?');
                                } else {
                                    next(null, name);
                                }
                            });
                        else
                            next(null, $translate.instant('ALL_AREAS'));
                    });
                });

                async.parallel(streams, function (err, names) {
                    if (err) {
                        console.log(err);
                    }
                    const name = names.join(', ');
                    _this.tagFilterMenu.insertFilterTag('dummy', _this.type, name, _this.typeLabel,  _this.nonRemovable, removeFilter);

                });
            } else {
                _this.tagFilterMenu.removeTag('dummy', _this.type);
            }
        }
    };


    _this.selectHierarchy = function (hierarchyId) {
        if (_this.hierarchyFilters.indexOf(hierarchyId) >= 0) {
            // If the hierarchy is already added, this is a removal
            _this.tagFilterMenu.removeTag(hierarchyId, _this.type);
            _this.hierarchyFilters = _.without(_this.hierarchyFilters, hierarchyId);
        } else if (_this.mode == 'single') {
            // If the mode is single, we need to remove the previous hierarchy
            _this.hierarchyFilters = [hierarchyId];
        } else {
            // Othewise, we add it and render the tag
            _this.hierarchyFilters.push(hierarchyId);

        }

        renderTags();

        // Notify filter changes:
        _this.notifyFilterChanges();
    };

    _this.notifyFilterChanges = function () {

        var filtersNotifyMsg = {
            type: _this.type,
            filters: _this.hierarchyFilters
        };

        $rootScope.$broadcast('filterChange-' + _this.tagFilterMenu.filterId, filtersNotifyMsg);
    };

    _this.prevent = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };

    // Load all hierarchies
    _this.$onInit = function () {
        _this.isEllipsis = $window.innerWidth < 1800;

        getHierarchyAll(function (err, hierarchies) {
            _this.hierarchies = angular.copy(hierarchies);
        });

        // If there are filters, render them
        if (_this.tagFilterMenu.filters && _this.tagFilterMenu.filters[_this.type]) {
            _this.hierarchyFilters = _this.tagFilterMenu.filters[_this.type];
            renderTags();
        }
    };
};



const hfHierarchyTaggingFilter = angular.module('happyForceApp').directive('hfHierarchyTaggingFilter', function () {
    return {
        restrict: 'E',
        scope: {
            mode: '<',
            label: '@',
            typeLabel: '@',
            type: '@',
            showAllCompany: '<',
            nonRemovable: '<'
        },
        require: {
            tagFilterMenu: '^hfTagFilterMenu'
        },
        template: require('./hierarchy-tagging-filter.html'),
        controllerAs: '$ctrl',
        bindToController: true,
        transclude: false,
        replace: true,
        controller: HierarchyTaggingFilterCtrl
    };
});

export default hfHierarchyTaggingFilter;
