import {getService} from '../../../../../../../migration_utils/react-in-angular';
import usePersonName from '../../../../../../shared/hooks/usePersonName';
import {Box, Skeleton, Typography} from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import HFInfoIconWithTooltip from '../../../../../../shared/new-components/hf-info-icon-with-tooltip';
import moment from 'moment';
import React from 'react';
import {CellProps} from '../../types';
import HFTag from '../../../../../../shared/new-components/hf-tag/hf-tag';
import {isPerson} from '../../utils';

const StatusCell = ({person, bulkActionType}: CellProps) => {

    const $translate = getService('$translate');

    const {personName} = usePersonName(person.addedBy)

    if (!person.id && bulkActionType === undefined) {
        return (
            <></>
        )
    }

    const renderTooltip = () => {

        if (person.sourceType || person.addedOn) {
            return (
                <HFInfoIconWithTooltip
                    color={'primary'}
                    icon={'INFO'}
                    tooltipContent={
                        (<Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            alignItems: 'left',
                        }}>
                            <Typography
                                variant={'caption'}>
                                {$translate.instant('EMPLOYEE_REGISTER_DATE')}: {moment(person.addedOn).format('DD MMM YYYY hh:mm')}
                            </Typography>
                            {person.sourceType === 'USER' && (
                                <Typography sx={(theme) => ({marginTop: theme.spacing(1)})}
                                    variant={'caption'}>
                                    {$translate.instant('INVITED_BY', {name: personName})}
                                </Typography>
                            )}

                            {person.sourceType !== 'USER' && (
                                <Typography sx={(theme) => ({marginTop: theme.spacing(1)})}
                                    variant={'caption'}>
                                    {$translate.instant('ADDED_VIA', {source: $translate.instant('ADDED_VIA_' + person.sourceType)})}
                                </Typography>
                            )}
                        </Box>)
                    }
                />
            )
        } else {
            return null;
        }

    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: '100%',
                width: '100%'
            }}>




            {isPerson(person) && (!person.deleted) && (
                <>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%'}}
                    >
                        <TripOriginIcon color={person.activated ? 'success' : 'warning'} />
                        <Typography
                            sx={(theme) => ({marginLeft: theme.spacing(1)})} variant={'caption'}>
                            {person.activated ? $translate.instant('EMPLOYEE_ACTIVATED') : $translate.instant('EMPLOYEE_NOT_ACTIVATED')}
                        </Typography>

                        {(person.addedOn || person.sourceType) && (
                            <></>
                        )}

                        {renderTooltip()}

                    </Box>

                    {(person.appActivationToken) && (
                        <Typography sx={(theme) => ({marginTop: theme.spacing(1)})}
                            color={'text.secondary'}
                            variant={'caption'}>
                            {$translate.instant('ACTIVATION_CODE')}: {person.appActivationToken}
                        </Typography>
                    )}

                    {person.debug && (
                        <Box sx={(theme) => ({marginTop: theme.spacing(1)})}>
                            <HFTag
                                status={'warning'}
                                text={$translate.instant('EMPLOYEE_DEBUG')}
                            />
                        </Box>
                    )}

                    {person.blocked && (
                        <Box sx={(theme) => ({marginTop: theme.spacing(1)})}>
                            <HFTag
                                status={'warning'}
                                text={$translate.instant('EMPLOYEE_BLOCKED')}
                            />
                        </Box>
                    )}

                </>

            )}


            {isPerson(person) && person.deleted && (
                <>
                    <Typography sx={(theme) => ({marginTop: theme.spacing(1)})}
                        color={'error'}
                        variant={'caption'}>
                        {$translate.instant('EMPLOYEE_DELETED_DATE')}: {moment(person.deletedOn).format('DD MMM YYYY hh:mm')}
                    </Typography>


                    <Typography sx={(theme) => ({marginTop: theme.spacing(1)})}
                        variant={'caption'}>
                        {$translate.instant('EMPLOYEE_LEAVE_DATE')}: {person.turnoverDate ? moment(person.turnoverDate).format('DD MMM YYYY') : ''}
                    </Typography>

                    <Typography sx={(theme) => ({marginTop: theme.spacing(1)})}
                        variant={'caption'}>
                        {$translate.instant('EMPLOYEE_LEAVE_REASON')}: {person.turnoverType ? $translate.instant('TURNOVER_TYPE_' + person.turnoverType): ''}
                    </Typography>


                </>
            )}


        </Box>
    )

}

export default StatusCell;
