'use strict';

import axios from 'axios';
import * as Sentry from '@sentry/browser';

const configureApp = function (Languages, $rootScope, Restangular, editableOptions, editableThemes, AuthSrvc, CompanySvrc, $location, moment, $translate, Settings, $cacheFactory, $window, Analytics, $intercom, Characteristics, Groups, CompanyStatus, $state) {
    'ngInject';


    /* @ngInject */


    $rootScope.Language = Languages;
    $rootScope.bodyClasses = 'default gray-bg';

    editableOptions.theme = 'bs3';
    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-xs';

    // IE SUCKS!
    window.isIE = window.navigator.userAgent.match(/(MSIE|Trident)/);
    $('body').addClass((window.isIE)  ? 'ie' : 'non-ie');

    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-circle btn-circle-xs';
    editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'

    // BORRAR ESTO CUANDO PODAMOS!!
    $rootScope.AuthSrvc = AuthSrvc;
    $rootScope.Company = CompanySvrc;

    // No se para que está
    $rootScope.path = '';
    $rootScope.$watch(function () {
        $rootScope.path = $location.path();
    }, function () {

    });

    // Momment Locale
    moment.locale($translate.use() || 'en');

    $rootScope.recording = false;

    // Restangular configuration
    Restangular.setBaseUrl(Settings.APIUrl);
    Restangular.setDefaultHeaders({ 'Content-Type': 'application/json;charset=UTF-8' });

    var cache = $cacheFactory('http');
    Restangular.setDefaultHttpFields({ cache: cache });

    localStorage.setItem('Happyforce-Settings', JSON.stringify(Settings));

    Restangular.setResponseInterceptor(function (response, operation) {
        if (operation === 'put' || operation === 'post' || operation === 'remove') {
            cache.removeAll();
        }
        return response;
    });

    setInterval(function () {
        cache.removeAll();
    }, 60000);

    Restangular.setErrorInterceptor(function (resp) {


        const payload = {
            status: resp?.status,
            statusText: resp?.statusText,
            url: resp?.config?.url,
            path: $location.path(),
        }

        Sentry.captureException(JSON.stringify(payload), 'NetworkError');

        if (resp.status == 401 && AuthSrvc.isPublic($location.path()) === false) {
            AuthSrvc.invalidate();
            $location.path('/onboarding');
        }
        if (resp.status === 400) {
            resp.noLogout = true;
        }
    });

    /*
    ADD here axios interceptors, WHILE we migrate to axios
     */

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response && error.response.status === 401 && AuthSrvc.isPublic($location.path()) === false) {
            AuthSrvc.invalidate();
            $location.path('/onboarding');
        }
        return Promise.reject(error);
    });


    // Add a request interceptor
    axios.interceptors.request.use(function (config) {

        // If we have a companyId, add it to the request
        if (config.params && config.params.companyId) {
            config.headers.companyId = config.params.companyId;
        }

        // If we have a session, add it to the request
        if (AuthSrvc.sessionId()) {
            config.headers.SessionId = AuthSrvc.sessionId();
        }

        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    $rootScope
        .$on('$locationChangeSuccess', function () {
            if (AuthSrvc.isLoggedIn()) {

                Restangular.setDefaultHeaders({ SessionId: AuthSrvc.sessionId() });

                Restangular.addFullRequestInterceptor(function (element, operation, what, url, headers, params, httpConfig) {
                    if (params.companyId) {
                        headers.companyId = params.companyId;
                    }

                    return {
                        element: element,
                        headers: headers,
                        params: params,
                        httpConfig: httpConfig
                    };
                });



                CompanySvrc.getUserCompany(function (err, company) {
                    if (err) {
                        console.error(err);
                    } else {
                        Analytics.set('dimension1', company.id);
                        Analytics.set('dimension2', AuthSrvc.user().email);
                        Analytics.set('dimension2', AuthSrvc.user().role);

                        var intercomUserSettings = {
                            app_id: "xp9iy0t4",
                            api_base: "https://api-iam.intercom.io",
                            user_id: AuthSrvc.user().id,
                            email: AuthSrvc.user().email,
                            companies: [{
                                company_id: company.id,
                            }]
                        };


                        // On little screens, attach the Intercom messenger on a button
                        if (window.matchMedia('only screen and (max-width: 760px)').matches) {
                            intercomUserSettings.custom_launcher_selector = '#intercom_custom_launcher';
                            intercomUserSettings.hide_default_launcher = true;
                        }


                        window.Intercom("boot", intercomUserSettings);
                        $intercom.boot(intercomUserSettings);

                        Sentry.setUser({
                            id: AuthSrvc.user().id,
                            email:  AuthSrvc.user().email
                        });
                    }

                });








                Characteristics.start();

                Groups.start();

                // Intialize the status retrieval
                CompanyStatus.retrieveStatus();

            } else {
                AuthSrvc.invalidate();

                // $urlRouter.sync();
            };
        });

    $rootScope
        .$on('$stateChangeStart', function (event, toState, toStateParams, fromState, fromParams, options) {
            if (!AuthSrvc.isLoggedIn() && !AuthSrvc.isPublic(toState.name)) {
                $state.go('onboarding', {
                    redirect: toState.name,
                    extras: toStateParams });
                event.preventDefault();
            } else {

                Restangular.setDefaultHeaders({ SessionId: AuthSrvc.sessionId() });

                var blockedRoutes = ['scores', 'feedback', 'analytics'];
                var isABlockedRoute = blockedRoutes.filter(function (route) {
                    return toState.name.indexOf(route) > -1;
                });

                /*if (isABlockedRoute.length) {
                    if (!CompanyStatus.canAccessToRoute())
                        event.preventDefault();
                }*/

                CompanySvrc.getUserCompany(function (err, company) {
                    if (err) {
                        console.log(err);
                    } else {
                        if (!AuthSrvc.isADVISOR() && company.launchStatus === 'CREATED' && toState.name !== 'setup.initial') {
                            event.preventDefault();
                            return $state.go('setup.initial');
                        }

                        if (
                            window.hj
                            && $rootScope.recording == false
                            && (company.launchStatus === 'CREATED' || company.launchStatus === 'PRELAUNCH')) {
                            hj('trigger', 'in_onboarding');
                            $rootScope.recording = true;
                        }

                        if (company.launchStatus != 'CREATED' && toState.name === 'setup.initial') {
                            event.preventDefault();
                            return $state.go('private.dashboard');
                        }

                        if (toState.data.roles) {
                            if (!AuthSrvc.userHasRole(toState.data.roles)) {
                                event.preventDefault();
                                swal({
                                    title: $translate.instant('ROLE_NOT_ALLOWED'),
                                    type: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#DD6B55',
                                    confirmButtonText: 'Ok',
                                    closeOnConfirm: true
                                })
                                    .then(function () {
                                        $state.go('onboarding', {
                                            extras: toStateParams
                                        });
                                    });
                            }
                        }
                    }
                });


                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
            }

        });

    $rootScope
        .$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

            if (angular.isDefined(toState.data.bodyClasses)) {
                $rootScope.bodyClasses = toState.data.bodyClasses;
                return;
            }


            // Mobile mini-navbar ñapa
            setTimeout(function () {
                if ($('body.mini-navbar').length)
                    $(window).trigger('resize');
            }, 500);

        });

    angular.element($window).bind('resize', function () {
        if (AuthSrvc.isLoggedIn()) {
            var intercomDisplaySettings = {};

            // On little screens, attach the Intercom messenger on a button
            if (window.matchMedia('only screen and (max-width: 760px)').matches) {
                intercomDisplaySettings.custom_launcher_selector = '#intercom_custom_launcher';
                intercomDisplaySettings.hide_default_launcher = true;
            } else {
                intercomDisplaySettings.custom_launcher_selector = null;
                intercomDisplaySettings.hide_default_launcher = false;
            }

            $intercom.update(intercomDisplaySettings);
        }
    });
}

export default configureApp;

