'use strict';
function CommentSwapVisibilityModalCtrl($scope, Comments, $modalInstance, ErrorSvrc, commentId) {
    'ngInject';

    var _this = this;
    _this.commentId = commentId;

    _this.originalPrivated = false;

    _this.currentPrivate = false;


    _this.ok = function () {
        Comments.showSwapVisibilityModal(_this.commentId, _this.comment.privated, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $modalInstance.close();
            }

        });

    };

    _this.exit = function () {
        $modalInstance.dismiss();
    };


    Comments.getOne(_this.commentId, function (err, result) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            _this.comment = result;
            _this.currentPrivated = _this.comment.privated;
            _this.originalPrivated = _this.comment.originalPrivated;
        }
    });

}




const CommentSwapVisibilityModal = angular.module('happyForceApp')
    .factory('CommentSwapVisibilityModal',
        function ($uibModal) {
            var methods = {};
            methods.showModal = function (commentId, callback) {

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./comment-swap-visibility-modal.html'),
                    controller: CommentSwapVisibilityModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        commentId: function () {
                            return commentId;
                        }

                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );
export default CommentSwapVisibilityModal;
