'use strict';

import firebase from "firebase";

import template from './company-create-defaults.html';
import {getHierarchyName} from "../../shared/services/migrated/hierarchy.service";
import async from 'async';

function CompanyCreateDefaultsCtrl($scope, $translate, $uibModal, CronService, Questions, Announcements, Settings, $uibModalStack, Languages) {
   'ngInit'

    $scope.executing = false;
    $scope.uploadProgress = 0;
    $scope.uploading = false;
    $scope.company = {
        name: ''
    };

    $scope.wizardTitle = 'Create default data';
    $scope.wizardSubtitle = '';

    $scope.dateFormat = $translate.instant('DATE_FORMAT');
    if ($scope.dateFormat === 'DATE_FORMAT')
        $scope.dateFormat = Settings.defaultDateFormat;

    function findStep(classes) {
        if (!classes) return;
        var findStep = classes.match(/hf-step-(\d)/);
        var actual = 1;
        if (findStep && findStep[1]) {
            actual = parseInt(findStep[1]);
        }

        return actual;
    }

    function validateStep() {
        var actualStep = findStep(angular.element('hf-company-create .inmodal').attr('class'));
        var step = '.hf-step-' + actualStep;

        angular.element(step + ' .error').removeClass('error');
        angular.forEach(angular.element(step + ' [required]'), function (item) {
            if (item.value === '') {
                var $item = angular.element(item);

                if ($item.parent().attr('class') === 'special-input-input') {
                    $item = $item.parent();
                }

                $item.addClass('error');
            }
        });

        return (angular.element(step + ' .error').length === 0 && angular.element(step + ' .collission_error').length === 0);

    }

    $scope.wizardOptions = {
        nextButton: $translate.instant('NEXT'),
        prevButton: $translate.instant('PREV'),
        confirm: {
            text: $translate.instant('CREATE'),
            action: function () {
                createCompany();
            }
        },
        return: {
            text: $translate.instant('RETURN'),
            className: 'hf-secondary',
            action: function () {
                $scope.goToSelector();
            }
        },
        validate: validateStep,
        exit: function () {
            $scope.exit();
        }
    };

    var lastDate;

    function prepareQuestion(question) {
        question.title = {
            defaultValue: question.title.replace('{company}', $scope.company.name)
        };

        if (question.activationRule.ruleType === 'SINGLE') {
            question.activationRule.from = moment(lastDate).add(1, 'd').format('DDMMYYYY');
            question.activationRule.to = moment(lastDate).add($scope.questionDuration, 'd').format('DDMMYYYY');
        } else {
            question.activationRule.startAt = moment(lastDate).add(1, 'd').format('DDMMYYYY');
            var cronDay = moment(lastDate).add(1, 'd').format('DD');
            question.activationRule.cronExpression = question.activationRule.cronExpression.replace('{day}', cronDay);
            question.activationRule.resumen = CronService.fromExpression(question.activationRule.cronExpression, $scope.questionDuration);
        }

        question.values = question.values.map(function (i) {
            return {
                id: i.id,
                color: i.color,
                label: {
                    defaultValue: i.value
                }
            };
        });

        if (question.values.length > 0) {
            question.minSelection = 1;
            question.maxSelection = 1;
        }

        lastDate = moment(lastDate).add($scope.questionDuration, 'd');

        return question;
    }

    var startDate;
    var duration = 7;

    function prepareAnnouncement(announcement) {
        announcement.title = announcement.title.replace('{company}', $scope.company.name);
        announcement.message = announcement.message.replace('{company}', $scope.company.name);

        announcement.effectiveDate = moment(startDate).format('DDMMYYYY');
        announcement.expirationDate = moment(startDate).add(duration, 'd').format('DDMMYYYY');

        startDate = moment(startDate).add(duration, 'd');
        return announcement;
    }

    var blocked = false;

    $scope.deselectAllQuestions = function () {
        $scope.selectedQuestions = [];
    };

    $scope.deselectAllAnnouncements = function () {
        $scope.selectedAnnouncements = [];
    };

    $scope.questions = [];

    function createCompany() {
        if (blocked) return;
        blocked = true;

        var finalCompany = angular.copy($scope.company);
        lastDate = $scope.company.startDate;
        startDate = $scope.company.startDate;

        var stream = [];

        // Questions
        stream.push(function (next) {
            var parallelStream = [];
            if (!$scope.selectedQuestions) return next();
            console.log('Creamos las questions', $scope.selectedQuestions.length);

            $scope.selectedQuestions.forEach(function (question, index) {
                delete question.id;
                parallelStream.push(function (parallelNext) {
                    Questions.create(prepareQuestion(question), function (err, data) {
                        console.log('Question', index, 'creada!');
                        parallelNext(err);
                    });
                });
            });

            if (!$scope.selectedAnnouncements) return next();
            console.log('Creamos los anuncios', $scope.selectedAnnouncements.length);

            $scope.selectedAnnouncements.forEach(function (announcement, index) {
                delete announcement.id;

                parallelStream.push(function (parallelNext) {
                    Announcements.create(prepareAnnouncement(announcement), function (err, data) {
                        console.log('Anuncio', index, 'creado!');
                        parallelNext(err);
                    });
                });
            });

            async.parallel(parallelStream, next);
        });

        async.waterfall(stream, function (err) {
            blocked = false;
            if (err) {
                return swal('Error creating company!', err, 'error');
            }

            swal('Created!', '', 'success');
            $scope.exit();
        });
    }

    $scope.getHierchyName = function (hierarchyId) {
        if (!hierarchyId) {
            return;
        }
        var hierarchyNames = hierarchyId.map(function (hierarchy) {
            return getHierarchyName(hierarchy);
        });

        return hierarchyNames;
    };

    $scope.announcements = [];
    $scope.questions = [];

    $scope.selectedAnnouncements = [];
    $scope.selectedQuestions = [];

    $scope.company.startDate = moment();
    $scope.questionDuration = 7; // Days

    $scope.actual = {
        lang: 'es'
    };

    var loadDefaults = function () {
        var refA = firebase.database().ref().child('announcements').child($scope.actual.lang);
        refA.on('value', function (snapshot) {
            $scope.announcements = angular.copy(snapshot.val());
            checkAllAnnouncements();
        }, function (errorObject) {

            console.log('The read failed: ' + errorObject.code);
        });

        var refQ = firebase.database().ref().child('questions').child($scope.actual.lang);

        refQ.on('value', function (snapshot) {
            $scope.questions = angular.copy(snapshot.val());
            $scope.families = _.uniq($scope.questions?.map(function (question) {
                return question.family;
            }));

            checkAllQuestions();
        }, function (errorObject) {

            console.log('The read failed: ' + errorObject.code);
        });
    };

    function checkAllQuestions() {
        $scope.selectedQuestions = angular.copy($scope.questions);
    };

    function checkAllAnnouncements() {
        $scope.selectedAnnouncements = [];
        Object.keys($scope.announcements).forEach(function (announcement) {
            $scope.selectedAnnouncements.push($scope.announcements[announcement]);
        });
    };

    $scope.$watch('actual', function (n, o) {
        loadDefaults();
    }, true);

    $scope.calculateDate = function (index, date, add) {
        var days = (index + add) + 1;
        return moment($scope.startDate).add(days, 'd').format('DDMMYYYY');
    };

    $scope.exit = function () {
        $uibModalStack.dismissAll();
    };

    $scope.langs = Languages.appLanguages.map(function (lang) {return lang.id;});


    this.$onInit = function () {

    };

};






const CompanyCreateDefaultsModal = angular.module('happyForceApp')
    .factory('CompanyCreateDefaultsModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showModal = function (callback) {
                $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    template: require('./company-create-defaults.html'),
                    controller: CompanyCreateDefaultsCtrl,
                    controllerAs: '$ctrl',
                    size: 'createNewCompany defaults',
                }).result.then(function (results) {
                    if (results) {
                        callback();
                    }
                });
            };

            return methods;

        }
    );

export default CompanyCreateDefaultsModal;
