'use strict';

import activationSegmentChart from "./activation-segment-chart/activation-segment-chart.controller";


function activationDistributionCtrl($scope, Characteristics) {
    'ngInject'
    var _this = this;

    function loadData() {

        $scope.loading = true;

        Characteristics.getAll(function (err, characteristics) {
            if (err) {
                ErrorSvrc.showErrorModal(error);
                $scope.loading = false;
            } else {
                _this.characteristics = characteristics;
                $scope.loading = false;
            }
        });
    }

    this.$onInit = function () {

        ['dates', 'level', 'groups', 'filters', 'from', 'to'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                // Now compute the stats
                loadData();
            }, true);
        });

    };


}



const activationDistribution = angular.module('happyForceApp')
    .directive('activationDistribution', function () {
        return {
            scope: {
                from: '=',
                to: '=',
                level: '=',
                filters: '=',
                groups: '='
            },
            template: require('./activation-distribution.html'),
            controller: activationDistributionCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });

export default activationDistribution;
