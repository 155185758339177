import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button } from '@mui/material';
import HfMultiLanguageText from 'root/app/shared/new-components/hf-multilanguage-text/hfMultilanguageText';
import {darkDropdown} from 'root/app/utilities/color.utilities';
import {ArrowDropDown} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const CommentContainerSelector = ({ comment, onChange, noContainerText, removeContainerText, noContainerOnPeriodText }) => {
    const [containers, setContainers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const FeedbackContainersService = getService('FeedbackContainersService');
    const Comments = getService('Comments');
    const ErrorSvrc = getService('ErrorSvrc');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeFeedbackContainer = (newContainerId) => {
        if (newContainerId !== comment.containerId) {
            Comments.setCommentContainer(comment.id, newContainerId, (err, result) => {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    comment.container = result.container;
                    onChange && onChange(err, comment);
                }
            });
        }
        handleClose();
    };

    const removeContainer = () => {
        Comments.removeCommentContainer(comment.id, (err, result) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                comment.container = result.container;
                onChange && onChange(err, comment);
            }
        });
        handleClose();
    };

    useEffect(() => {
        const params = {
            activeOn: comment.date,
            toContainer: 100,
            draft: false
        };

        FeedbackContainersService.list(comment.feedbackType, params, (err, fetchedContainers) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setContainers(fetchedContainers.results);
            }
        });
    }, [comment.date, comment.feedbackType]);

    return (
        <div>
            <Tooltip title={
                comment.container ?  <HfMultiLanguageText
                    multilanguage={comment.container.title}
                    replaceTokens={false}/> : noContainerText}
            >
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    startIcon={<ArrowDropDown />}
                    sx={{
                        color: 'gray',
                        justifyContent: 'left',
                        width: '100%',
                        backgroundColor: '#f2f2f2',
                        border: '0px',
                        fontSize: '12px',
                        display: 'inline-flex',
                        maxHeight: '27px',
                        textTransform: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {comment.container ?  <HfMultiLanguageText
                        multilanguage={comment.container.title}
                        replaceTokens={false}/> : noContainerText}
                </Button>
            </Tooltip>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiMenu-paper':
                        {
                            backgroundColor: darkDropdown,
                            boxShadow: '0 0 3px rgba(86, 96, 117, 0.7)',
                            border: 'medium none',
                            borderRadius: '3px',
                            fontSize: '12px',
                            padding: '0',
                        },
                }}
            >
                {containers.map((container) => (
                    <MenuItem key={container.id} onClick={() => changeFeedbackContainer(container.id)}>
                        <div className="small-style dark-dropdown-menu-item">
                            <HfMultiLanguageText
                                multilanguage={container.title}
                                replaceTokens={false}/>
                        </div>
                    </MenuItem>
                ))}
                {comment.container && <hr />}
                {comment.container && (
                    <MenuItem onClick={removeContainer}>
                        <div className="small-style dark-dropdown-menu-item">
                            <span className={'m-r-xs'} style={{color: 'red'}}>■</span>
                            {removeContainerText}
                        </div>
                    </MenuItem>
                )}
                {(!containers || containers.length === 0) && (
                    <MenuItem>
                        <div className="small-style dark-dropdown-menu-item">
                            {noContainerOnPeriodText}
                        </div>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

CommentContainerSelector.propTypes = {
    comment: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    noContainerText: PropTypes.string,
    removeContainerText: PropTypes.string,
    noContainerOnPeriodText: PropTypes.string
};

angularize(CommentContainerSelector, 'hfCommentContainerSelector', angular.module('happyForceApp'), {
    comment: '=',
    onChange: '=',
    noContainerText: '=',
    removeContainerText: '=',
    noContainerOnPeriodText: '='
});

export default CommentContainerSelector;
