'use strict';

import React, {useEffect} from 'react';
import {angularize, getService} from 'reactInAngular';
import PropTypes from 'prop-types';
import Numbers from '../../../../utilities/number.utilities';
import {Tooltip} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const VotesChart = ({ values, totalVotes }) => {


    const [gridColumns, setGridColumns] = React.useState(null);
    const [columValues, setColumValues] = React.useState(null);

    const $translate = getService('$translate');

    useEffect(() => {

        const newValues = values?.map((value, index) => {


            const percent = Numbers.roundNumber((value.value / totalVotes) * 100, 0);

            return {
                color: value.color,
                percent: percent,
                label: $translate.instant('VOTES_CHART_VOTES', { percent: percent, votes: value.value })
            }
        });

        setColumValues(newValues);
        setGridColumns(newValues?.map((entry) => `${entry.percent}%`).join(' '));


    }, [values, totalVotes]);


    return (
        <section className="votes-chart-box">
            <div style={{ gridTemplateColumns: gridColumns }} className="p-t-sm grid-container">
                {columValues?.map((value, index) => (
                    <span key={index} className="grid-item">
            <div
                className={`grid-item-content ${index === 0 ? 'grid-item-first' : ''} ${
                    index === values.length - 1 ? 'grid-item-last' : ''
                }`}
                style={{ backgroundColor: value.color }}
            >
                <Tooltip title={value.label}>
                        <span className="small-style item-label">{value.label}</span>
                </Tooltip>

            </div>
          </span>
                ))}
            </div>
        </section>
    );
};

VotesChart.propTypes = {
    totalVotes: PropTypes.number.isRequired,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            color: PropTypes.string.isRequired
        })
    ).isRequired,
};

angularize(VotesChart, 'votesChart', angular.module('happyForceApp'), {
    values: '=',
});

export default VotesChart;
