

import hfSurveyResultsListView
    from '../../../surveys/survey_results/survey_results_list_view/survey_results_list_view.controller';
import hfSurveyResultsHeatmapView
    from '../../../surveys/survey_results/survey_results_heatmap_view/survey_results_heatmap_view.controller';
import hfSurveyFeedbackView
    from '../../../surveys/survey_results/survey_feedback_view/survey_feedback_view.controller';


function LifeCycleDetailsModalCtrl(surveyResults,
    groupIds,
    segmentIds,
    hierarchyIds,
    fromDateEnabled,
    fromDate,
    toDateEnabled,
    toDate,
    $modalInstance,
    $state) {
    'ngInject';

    var _this = this;


    _this.survey = surveyResults;
    _this.groupIds = groupIds;
    _this.segmentIds = segmentIds;
    _this.hierarchyIds = hierarchyIds;
    _this.fromDateEnabled = fromDateEnabled;
    _this.fromDate = fromDate;
    _this.toDateEnabled = toDateEnabled;
    _this.toDate = toDate;


    _this.mode = 'LIST';

    _this.close = function () {
        $modalInstance.dismiss('cancel');
    }

    _this.navigateToFullReport = function () {
        $modalInstance.dismiss('cancel');
        $state.go('surveys.results', { surveyId: _this.survey.id });

    }


}


const LifeCycleDetailsModal = angular.module('happyForceApp')
    .factory('LifeCycleDetailsModal',
        function ($uibModal) {
            var methods = {};


            methods.showModal = function (surveyResults, groupIds, segmentIds, hierarchyIds, fromDateEnabled, fromDate, toDateEnabled, toDate) {

                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'lg',
                    resolve: {
                        surveyResults: function () {
                            return surveyResults;
                        },
                        groupIds: function () {
                            return groupIds;
                        },
                        segmentIds: function () {
                            return segmentIds;
                        },
                        hierarchyIds: function () {
                            return hierarchyIds;
                        },
                        fromDateEnabled: function () {
                            return fromDateEnabled;
                        },
                        fromDate: function () {
                            return fromDate;
                        },
                        toDateEnabled: function () {
                            return toDateEnabled;
                        },
                        toDate: function () {
                            return toDate;
                        }
                    },
                    template: require('./life-cycle-details-modal.html'),
                    controller: LifeCycleDetailsModalCtrl,
                    controllerAs: '$ctrl'
                });

            };

            return methods;
        });


export default LifeCycleDetailsModal;
