import Box from '@mui/material/Box';
import {Paper, Typography} from '@mui/material';
import {blue} from '@mui/material/colors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as React from 'react';
import {getService} from 'reactInAngular';


const BulkActionSelectionPanel =() => {

    const $translate = getService('$translate');

    return (
        <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

            <Paper elevation={0} sx={(theme) => ({
                backgroundColor: blue[200],
                color: theme.palette.info.contrastText,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            })}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <Typography variant={'h3'}>{$translate.instant('BULK_EMPLOYEE_PENDING_RECORDS')}</Typography>

                    <CloudUploadIcon sx={{fontSize: 100}} />

                    <Typography variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PENDING_RECORD_DETAILS')}</Typography>

                </Box>
            </Paper>

        </Box>
    );
}

export default BulkActionSelectionPanel;
