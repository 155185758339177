/**
 * Created by dcastro on 22/03/14.
 */

import angular  from 'angular';


export default function CompanySvrc($http, Upload, Restangular, AuthSrvc, Settings, DeleteCompanyModal, $uibModal, $cacheFactory, $translate, ErrorSvrc) {
    'ngInject';
    var industryTypes = [
        { id: 'ACCOMMODATION_AND_FOOT_SERVICES', label: $translate.instant('ACCOMMODATION_AND_FOOT_SERVICES') },
        { id: 'ARTS_ENTERTAINMENT_RECREATION', label: $translate.instant('ARTS_ENTERTAINMENT_RECREATION') },
        { id: 'COMPUTER_SOFTWARE_IT_SERVICES', label: $translate.instant('COMPUTER_SOFTWARE_IT_SERVICES') },
        { id: 'CONSTRUCTION', label: $translate.instant('CONSTRUCTION') },
        { id: 'EDUCATIONAL_SERVICES', label: $translate.instant('EDUCATIONAL_SERVICES') },
        { id: 'FINANCIAL_SERVICES_INSURANCE', label: $translate.instant('FINANCIAL_SERVICES_INSURANCE') },
        { id: 'HEALTH_CARE_SOCIAL_ASSISTANCE', label: $translate.instant('HEALTH_CARE_SOCIAL_ASSISTANCE') },
        { id: 'MANAGEMENT_CONSULTING', label: $translate.instant('MANAGEMENT_CONSULTING') },
        { id: 'MANUFACTURING', label: $translate.instant('MANUFACTURING') },
        { id: 'MARKETING_ADVERTISING', label: $translate.instant('MARKETING_ADVERTISING') },
        { id: 'NATURAL_RESOURCES', label: $translate.instant('NATURAL_RESOURCES') },
        { id: 'NON_PROFIT_ORGANIZATION', label: $translate.instant('NON_PROFIT_ORGANIZATION') },
        { id: 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES', label: $translate.instant('PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES') },
        { id: 'PUBLIC_AND_GOVERMENT_ADMINISTRATION', label: $translate.instant('PUBLIC_AND_GOVERMENT_ADMINISTRATION') },
        { id: 'REAL_ESTATE_RENTAL_AND_LEASING', label: $translate.instant('REAL_ESTATE_RENTAL_AND_LEASING') },
        { id: 'TRANSPORTATION_AND_WAREHOUSING', label: $translate.instant('TRANSPORTATION_AND_WAREHOUSING') },
        { id: 'UTILITIES_AND_SERVICES', label: $translate.instant('UTILITIES_AND_SERVICES') },
        { id: 'WHOLESALE_AND_RETAIL_TRADE', label: $translate.instant('WHOLESALE_AND_RETAIL_TRADE') }
    ];

    var jobEnvironmentTypes = [
        { id: 'OFFICE', label: 'Office' },
        { id: 'NON_DESKTOP', label: 'Non Desktop' },
        { id: 'GEEK', label: 'Geek people' }
    ];


    //loadUserCompany(function () {}, function () {});
    return {
        resetUserCompanyCache: function () {
            $cacheFactory.get('http').removeAll();
        },

        getConfigKey: function (key, callback) {
            Restangular.one('company').one('config').get().then(function (companyConfig) {
                if (companyConfig && companyConfig.hasOwnProperty(key)) {
                    callback(null, companyConfig[key]);
                } else {
                    callback({ err: 'NO_CONFIG_FOUND', msg: 'Key ' + key + ' not found!' });
                }
            }, function (error) {
                callback(error);
            });
        },

        getUserCompanyConfig: function (callback) {
            Restangular.one('company').one('config').get().then(function (companyConfig) {
                callback(null, companyConfig);
            }, function (error) {
                callback(error);
            });
        },

        getAllCompanies: function (callback, name, from, to) {
            var searchParams = {};
            if (name)
            {searchParams.name = name;}
            if (from)
            {searchParams.from = from;}
            if (to)
            {searchParams.to = to;}
            Restangular.all('company').one('list').get(searchParams).then(function (results) {
                callback(null, results);
            }, function (err) {
                callback(err);
            });
        },

        getCompany: function (companyId, callback) {
            Restangular.all('company').one(companyId).get().then(function (company) {
                callback(null, company);
            }, function (err) {
                callback(err);
            });
        },

        createCompany: function (callback) {
            Restangular.all('company').post().then(
                function (data) {
                    callback(null, data);
                }, function (err) {
                    callback(err);
                }
            );
        },

        changeCompany: function (id, success) {
            Restangular.all('company').one('change', id).post().then(
                function () {
                    Restangular.one('company').one('config').get().then(function (companyDetails) {
                        $cacheFactory.get('http').removeAll();
                        success(companyDetails);
                    });
                }
            );
        },

        getUserCompany: function (callback)
        {
            Restangular.one('company').get().then(function (companyDetails) {
                callback(null, companyDetails);
            }, function (err) {
                callback(err);
            });
        },

        getCompanyIndustryName: function (callback)
        {
            Restangular.one('company').one('config').get().then(function (companyConfig) {

                var industryType = _.find(industryTypes, function (industry) {
                    return industry.id === companyConfig.industryType;
                });

                if (industryType) {
                    callback(null, industryType.label);
                } else {
                    callback(null, null);
                }

            }, function (error) {
                callback(error);
            });
        },

        saveCompany: function (companyDetails, callback) {
            Restangular.one('company').post('config', companyDetails).then(function (updatedCompany) {
                $cacheFactory.get('http').removeAll();
                callback(null, updatedCompany);
            }, function (error) {
                callback(error);
            });
        },

        setup: function (setupParams, callback) {
            Restangular.one('company').one('config').post('setup', setupParams).then(function (updatedCompany) {
                $cacheFactory.get('http').removeAll();
                callback(null, updatedCompany);
            }, function (error) {
                callback(error);
            });
        },
        setCompanyImage: function (file, callback, progress) {
            Upload.upload({
                url: Settings.APIUrl + '/company/config/image',
                headers: { SessionId: AuthSrvc.sessionId() },
                data: {
                    file: file
                }
            }).progress(function (evt) {
                if (progress)
                {progress(evt.loaded, evt.total);}
            }).success(function (updatedCompany, status, headers, config) {
                callback(null, updatedCompany);
            }).error(function (response) {
                callback(response);
            });
        },

        checkValidDomain: function (domain, callback) {
            Restangular.one('company').one('config').one('domain').one(domain).get().then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err, null);
            });
        },
        getCompanyConfig: function (id, callback, params) {
            Restangular.one('company').one('config').one(id).get(params).then(function (companyConfig) {
                callback(null, companyConfig);
            }, function (error) {
                callback(error);
            });
        },

        getCurrentCompanyConfig: function (callback) {
            Restangular.one('company').one('config').get().then(function (companyConfig) {
                callback(null, companyConfig);
            }, function (error) {
                callback(error);
            });
        },

        saveCompanyConfig: function (id, companyConfig, callback) {

            Restangular.one('company').one('config').customPOST(companyConfig, id, { companyId: id }).then(function (companyConfig) {
                $cacheFactory.get('http').removeAll();
                callback(null, companyConfig);
            }, function (err) {
                callback(err);
            });
        },

        deleteCompany: function (id, secret, callback) {
            Restangular.one('company').one(id).remove({ secret: secret }).then(function (deletedCompany) {
                callback(null, deletedCompany);
            }, function (response) {
                callback(response);
            });
        },

        resetCompany: function (id, secret, callback) {

            Restangular.one('company').one('reset').customPOST({}, id, { secret: secret }).then(function (resetedCompany) {
                $cacheFactory.get('http').removeAll();
                callback(null, resetedCompany);
            }, function (response) {
                callback(response);
            });
        },




        changeCompanyStatus: function (newCompanyStatus, callback) {
            this.getUserCompany(function (err, company) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {

                    var swalParams = {
                        icon: 'warning',
                        closeOnEsc: false,
                        buttons: {
                            cancel: $translate.instant('CANCEL'),
                            confirm: {
                                text: $translate.instant('CONFIRM'),
                                value: 'confirm'
                            }
                        },
                        closeModal: true,
                        title: '¿Cambiar estado de empresa a' + newCompanyStatus + '?',
                        text: '🚨 Ojo, que la empresa en la que estas es: ' + company.name
                    };

                    swal(swalParams)
                        .then(function (value) {
                            switch (value) {
                                case 'confirm':
                                    Restangular.all('company').one('config').one(company.id)
                                        .customPOST({}, newCompanyStatus, { companyId: company.id }).then(function (companyConfig) {
                                            callback(null, companyConfig);
                                        }, function (err) {
                                            callback(err);
                                        });
                                    break;
                                default:
                            }
                        });


                }
            });
        },

        getMergeDevToken: function (callback) {
            Restangular.one('company').one('config').one('integration').one('getToken').get().then(function (linkToken) {
                callback(null, linkToken);
            }, function (error) {
                callback(error);
            });
        },
        saveMergeDevToken: function (publicToken, callback) {
            Restangular.one('company').one('config').one('integration').one('getToken').get().then(function (linkToken) {
                callback(null, linkToken);
            }, function (error) {
                callback(error);
            });
        },
        industryTypesList: industryTypes,
        jobEnvironmentTypesList: jobEnvironmentTypes
    };
}

angular.module('happyForceApp').service('CompanySvrc',  CompanySvrc);
