'use strict';


const AnnounncementsSearchUtils = angular.module('happyForceApp')
    .factory('AnnounncementsSearchUtils', function (SegmentsFilterService) {

        var methods = {};

        methods.compileParamsFromSearch = function (selectedFilters, text) {

            var searchParams = {};

            // Add the status filters
            if (selectedFilters['status']) {
                const status = selectedFilters['status'][0];

                if (status === 'LIVE') {
                    searchParams.published = true;
                } else if (status === 'DRAFT') {
                    searchParams.draft = true;
                } else if (status === 'ARCHIVED') {
                    searchParams.archived = true;
                }
            }


            // Add the segmentation filters

            if (selectedFilters.hierarchyIds && selectedFilters.hierarchyIds.length) {
                searchParams.hierarchyId = selectedFilters.hierarchyIds.join(',');
            }

            if (selectedFilters.groups && selectedFilters.groups.length) {
                searchParams.groupId = selectedFilters.groups.join(',');
            }

            if (selectedFilters.segments && selectedFilters.segments.length) {
                searchParams.filters = SegmentsFilterService.compileFilterStringFromTagSegments(selectedFilters.segments);
            }




            if (text) {
                searchParams.announcementText = text;
            }



            // Add the date filters
            if (selectedFilters['from']) {
                searchParams.from = selectedFilters['from'];
            }
            if (selectedFilters['to']) {
                searchParams.to = selectedFilters['to'];
            }

            // Add the signature filters
            if (selectedFilters['signatureType']) {
                const signatureType = selectedFilters['signatureType'][0];
                if (signatureType === 'MANAGER_SIGNED') {
                    searchParams.signed = true;
                } else if (signatureType === 'HAPPYFORCE_SIGNED') {
                    searchParams.hfSigned = true;
                }
            }

            /* Add the category filters */

            if (selectedFilters['categories']) {
                const categoryIds = selectedFilters['categories'];
                if (categoryIds && categoryIds.length > 0)
                    searchParams.categoryId = categoryIds;
            }

            /* Add the others filters */
            if (selectedFilters['others']) {
                selectedFilters['others'].forEach(function (otherFlag) {
                    if (otherFlag === 'REPLIED') {
                        searchParams.reply = true;
                    } else if (otherFlag === 'HAS_REACTIONS') {
                        searchParams.hasReactions = true;
                    }  else if (otherFlag === 'HAS_COMMENTS') {
                        searchParams.hasComments = true;
                    }
                });
            };

            return searchParams;

        };


        return methods;


    }
);


export default AnnounncementsSearchUtils;
