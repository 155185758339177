import {Box, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import {getService} from 'reactInAngular';
import {MRT_Row, MRT_TableInstance} from 'material-react-table';
import {Person} from '../../types';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EmailIcon from '@mui/icons-material/Email';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import {BulkActionType} from '../users_table';
interface UserListingActionsMenuProps {
    row: MRT_Row<Person>;
    table: MRT_TableInstance<Person>;
    setCurrentEditingRow: (row: MRT_Row<Person>) => void;
    setCurrentEditingPerson: (person: Person) => void;
    currentEditingRow: MRT_Row<Person>;
    onDeleteUser: (row: MRT_Row<Person>) => void;
    onInvitationResend: (row: MRT_Row<Person>) => void;
    onResetUserProfile: (row: MRT_Row<Person>) => void;
    onResubscribeUser: (row: MRT_Row<Person>) => void;
    bulkActionType: BulkActionType | undefined;
}

const UserListingActionsMenu = ({row, table, setCurrentEditingRow, setCurrentEditingPerson, currentEditingRow, onDeleteUser, onInvitationResend, onResubscribeUser, onResetUserProfile, bulkActionType}: UserListingActionsMenuProps) => {

    const [rowAnchorEl, setRowAnchorEl] = React.useState<null | HTMLElement>(null);

    const $translate = getService('$translate');

    /*
        Render non bulk action options if the bulk action type is not defined or the employee is not deleted
     */

    const renderNonBulkActionOption = () => {
        if (bulkActionType === undefined && row.original?.deleted === false) {
            return (
                <div>
                    <MenuItem onClick={() => onResetUserProfile(row)}>
                        <ListItemIcon>
                            <RestartAltIcon/>
                        </ListItemIcon>
                        <ListItemText>{$translate.instant('EMPLOYEE_RESET')}</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => onInvitationResend(row)}>
                        <ListItemIcon>
                            <EmailIcon/>
                        </ListItemIcon>
                        <ListItemText>{$translate.instant('RESEND_EMAIL')}</ListItemText>
                    </MenuItem>

                    {row.original?.mailStatus === 'UNSUB' && (
                        <MenuItem onClick={() => onResubscribeUser(row)}>
                            <ListItemIcon>
                                <InsertLinkIcon/>
                            </ListItemIcon>
                            <ListItemText>{$translate.instant('EMPLOYEE_RESUBSCRIBE_MAIL')}</ListItemText>
                        </MenuItem>
                    )}
                </div>
            );
        }
    };


    const renderActionMenuDropDown = () => {

        if (bulkActionType === undefined && row.original?.deleted === true) {
            // If the employee is deleted, do not render the action menu if the bulk action type is not defined
            return null;
        }

        return (
            <Box>
                <IconButton
                    color={'primary'}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(event) => {
                        setRowAnchorEl(event.currentTarget);
                    }}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={rowAnchorEl}
                    open={!!rowAnchorEl}
                    onClose={(event) => {setRowAnchorEl(null)}}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >

                    {renderNonBulkActionOption()}

                    <MenuItem onClick={() => onDeleteUser(row)}>
                        <ListItemIcon color={'error'}>
                            <DeleteIcon color={'error'}/>
                        </ListItemIcon>
                        <ListItemText>{$translate.instant('EMPLOYEE_DELETE')}</ListItemText>
                    </MenuItem>

                </Menu>
            </Box>
        );

    }

    return (
        <Box sx={{display: 'flex', gap: '1rem'}}>

            <Tooltip title={$translate.instant('EDIT')}>
                <IconButton color={'primary'} onClick={() => {

                    if (currentEditingRow) {
                        currentEditingRow.toggleExpanded(false);
                    }
                    row._valuesCache = {
                        person: {...row.original}
                    };
                    table.setCreatingRow(null); //exit creating mode
                    table.setEditingRow(row);

                    row.toggleExpanded(true);
                    setCurrentEditingRow(row);
                    setCurrentEditingPerson(row._valuesCache.person);
                }}>
                    <ModeEditIcon/>
                </IconButton>
            </Tooltip>

            {renderActionMenuDropDown()}

        </Box>
    );
}

export default UserListingActionsMenu;
