const FeedbackIdeasService = function (Restangular, Settings) {
    'ngInject';
    // This is a hack; we are calling to the same endpoint that for normal comments
    var Ideas = Restangular.one('company').one('comments');

    var InnovationStats = Restangular.one('company').one('stats').one('innovation');



    var methods = {};

    methods.list = function (params, callback) {

        var finalParams = angular.copy(params);
        finalParams.feedbackTypes = ['SUGGESTION'];
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        if (params && params.hierarchyId && params.hierarchyId.id) {
            finalParams.hierarchyId = params.hierarchyId.id;
        }

        Ideas.get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    var stats = {};

    stats.get = function (params, callback) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        if (params && params.hierarchyId && params.hierarchyId.id) {
            finalParams.hierarchyId = params.hierarchyId.id;
        }

        InnovationStats.get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    stats.ranking = function (params, callback) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        if (params && params.hierarchyId && params.hierarchyId.id) {
            finalParams.hierarchyId = params.hierarchyId.id;
        }

        InnovationStats.one('ranking').get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.stats = stats;


    methods.stats.phaseconfig = [
        {
            id: 'NO_PHASE',
            label: 'FEEDBACK_IDEAS_INNOVATION_IDEAS_NO_PHASE',
            color: '#67748A',
            linkedToInitiativeColor: '#CCD1D8'
        },
        {
            id: 'ONGOING',
            label: 'FEEDBACK_IDEAS_INNOVATION_IDEAS_ONGOING',
            color: '#FF9300',
            linkedToInitiativeColor: '#FFE4BF'
        },
        {
            id: 'DISCARDED',
            label: 'FEEDBACK_IDEAS_INNOVATION_IDEAS_DISCARTED',
            color: '#DF356B',
            linkedToInitiativeColor: '#F7CDDA'
        },
        {
            id: 'ACCEPTED',
            label: 'FEEDBACK_IDEAS_INNOVATION_IDEAS_ACCEPTED',
            color: '#71CA00',
            linkedToInitiativeColor: '#DBF2BF'
        }
    ];

    return methods;

};

export default FeedbackIdeasService;

