'use strict';

var SegmentsSelectorCtrl = function (Characteristics, $modalInstance, checked, characteristicId, Analytics) {
    var _this = this;

    _this.questions = [];
    _this.checked = checked;
    _this.characteristicId = characteristicId;


    _this.selectQuestion = function (question, selectedValue) {
        question.value = selectedValue;
        question.characteristicId = question.id;

        if (question.questionType === 'BOOLEAN') {
            // Just remove the values from the checked questions
            if (!_this.checked[question.id + ':' + selectedValue]) {
                delete _this.checked[question.id + ':' + selectedValue];
                delete question.textValue;
            } else {
                // If the value is present, set the text value

                question.textValue = selectedValue ? question.yesValue : question.noValue;
            }

        } else if (question.questionType === 'LIST') {
            var value = question.values.filter(function (value) {
                return value.id === question.value;
            })[0];

            question.textValue = value.value;
        } else if (question.questionType === 'DATE') {
            var value = question.ranges.filter(function (value) {
                return value.id === question.value;
            })[0];

            question.textValue = value.name;
        }

        var existing = _this.questions.filter(function (item) {
            return question.id === item.id && item.value === question.value;
        });

        if (!existing || !existing.length) {
            _this.questions.push(angular.copy(question));
        } else {
            _this.questions = _this.questions.filter(function (_question) {
                if (_question.questionType === 'LIST') {
                    return (_question.textValue !== question.textValue);
                }

                return (_question.characteristicId !== question.id);
            });
        }

        //Finally, filter for the checked ones:
        _this.questions = _this.questions.filter(function(finalQuestion) {
            return (_this.checked &&  _this.checked[finalQuestion.characteristicId+':'+finalQuestion.value]);
        });
    };

    _this.applyFilters = function () {
        _this.trackEvent('segments_selected');
        $modalInstance.close(_this.questions);
    };

    _this.cancelFilter = function () {
        _this.trackEvent('filter_canceled');
        $modalInstance.close(checked || []);
    };

    _this.trackEvent = function (action) {
        Analytics.trackEvent('segments_filter', action);
    };

    Characteristics.getAll(function (err, data) {
        if (err) {
            console.error('Error loading Characteristics (segments-selector)', error);
        } else {
            if (_this.characteristicId) {
                // Filter the characteristics by id
                  _this.segmentedQuestions = data.filter(function (segment) {
                        return segment.id == _this.characteristicId;
                    });
            } else {
                // Show all characteristics
                _this.segmentedQuestions = data;
            }

            // Now let's review the checked ones
            if (_this.checked) {

                Object.keys(_this.checked).forEach(function (checkedId) {
                    var questionValue = checkedId.split(':');
                    var selected = _this.checked[checkedId];
                    // If the checked is true, let's locate the question
                    if (selected) {
                        var question = _this.segmentedQuestions.find(function (segment) {
                            return segment.id == questionValue[0];
                        });
                        if (question) {
                            // if is found, mark it as checked
                            _this.selectQuestion(question, questionValue[1]);
                        }

                    }
                });


            }

            if (!$.isEmptyObject(checked)) {
                checked.forEach(function (check) {
                    _this.selectQuestion(check, check.value);
                });
            }
        }
    });

    _this.$onInit = function () {

    };
};

const hfSegmentsSelector = angular.module('happyForceApp')
  .controller('SegmentsSelectorCtrl', SegmentsSelectorCtrl).directive('hfSegmentsSelector', function () {
    return {
        scope: {
            selected: '=',
            useFilter: '='
        },
        template: require('./segments-selector.html'),
        controller: 'SegmentsSelectorCtrl',
        controllerAs: '$ctrl',
        transclude: true,
        bindToController: true
    };
});


export default hfSegmentsSelector;
