'use strict';

//angular.module("angular-cron-jobs", ["templates-angularcronjobs"]);

import cronConfig from 'root/app/shared/components/cron-selector/cronService';


const cronSelection = angular.module('angular-cron-jobs')
    .directive('cronSelection', function (cronService, baseFrequency, $translate, CronService, Settings) {
        return {
            restrict: 'EA',
            replace: true,
            transclude: true,
            require: 'ngModel',
            scope: {
                ngModel: '=',
                config: '=',
                myFrequency: '=?frequency',
                duration: '=?',
                date: '=',
                resumen: '=?'
            },
            controller: function ($scope) {
                $scope.dateFormatCron = $translate.instant('DATE_FORMAT');
                if ($scope.dateFormatCron === 'DATE_FORMAT') {
                    $scope.dateFormatCron = Settings.defaultDateFormat;
                }

                $scope.yesterday = moment().subtract(1, 'd');

                $scope.dateFormatCronMonth = 'DD';

            },

            template: require('./cron.html'),

            link: function ($scope, $el, $attr, $ngModel) {

                var modelChanged = false;

                $scope.baseFrequency = baseFrequency;

                $scope.frequency = [{
                    value: 1,
                    label: 'Minute'
                }, {
                    value: 2,
                    label: 'Hour'
                }, {
                    value: 3,
                    label: $translate.instant('EVERY_DAY')
                }, {
                    value: 4,
                    label: $translate.instant('EVERY_WEEK')
                }, {
                    value: 5,
                    label: $translate.instant('EVERY_MONTH')
                }, {
                    value: 6,
                    label: $translate.instant('EVERY_YEAR')
                }];

                $scope.$watch('ngModel', function (newValue) {
                    if (angular.isDefined(newValue) && newValue) {
                        modelChanged = true;
                        $scope.myFrequency = cronService.fromCron(newValue, $scope.allowMultiple, $scope.cronStyle);

                    } else if (newValue === '') {
                        $scope.myFrequency = undefined;
                    }
                });

                if (typeof $scope.config === 'object' && !$scope.config.length) {
                    if (typeof $scope.config.options === 'object') {
                        var optionsKeyArray = Object.keys($scope.config.options);
                        for (var i in optionsKeyArray) {
                            var currentKey = optionsKeyArray[i].replace(/^allow/, '');
                            var originalKey = optionsKeyArray[i];
                            if (!$scope.config.options[originalKey]) {
                                for (var b in $scope.frequency) {
                                    if ($scope.frequency[b].label === currentKey) {
                                        $scope.frequency.splice(b, 1);
                                    }
                                }
                            }
                        }
                    }

                    if (angular.isDefined($scope.config.allowMultiple)) {
                        $scope.allowMultiple = $scope.config.allowMultiple;
                    } else {
                        $scope.allowMultiple = false;
                    }

                    if (angular.isDefined($scope.config.quartz) && $scope.config.quartz) {
                        $scope.cronStyle = 'quartz';
                    } else {
                        $scope.cronStyle = 'default';
                    }
                }

                $scope.minuteValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
                $scope.hourValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                $scope.monthValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

                $scope.dayValues = [1, 2, 3, 4, 5, 6, 7];

                var languageKey = $translate.proposedLanguage() || $translate.use();
                if (languageKey === 'es') {
                    $scope.dayValues = [2, 3, 4, 5, 6, 7, 1];
                }

                // Hack para que funcione con fechas locales
                $scope.localDate = {
                    value: $scope.date
                };

                function parseDayMonth(date) {
                    var mDate = moment(date);

                    $scope.dayOfMonthValues = [mDate.format('D')];
                }

                parseDayMonth($scope.date);

                var items = [];
                $scope.colissionMessage = false;

                function collissionMessage(duration, frequency) {
                    $scope.colissionMessage = false;

                    if (duration > 1) {
                        initialDuration = $scope.duration;

                        if (frequency.base === 3) { // Cada día y duración mayor a 1 día
                            $scope.colissionMessage = true;
                        }

                        if (frequency.base === 4) { // Cada semana
                            if (frequency.dayValues && frequency.dayValues.length >= 2) {
                                var next = frequency.dayValues[frequency.dayValues.length - 1];
                                var prev = frequency.dayValues[frequency.dayValues.length - 2];

                                var diff = (moment().day(prev - 1).diff(moment().day(next - 1), 'd'));
                                if (diff < 0) {
                                    diff = diff *= -1;
                                }
                                if (diff <= duration) {
                                    $scope.colissionMessage = true;
                                }
                            }
                        }

                        if (frequency.base === 5 && duration > 30) { // cada mes
                            $scope.colissionMessage = true;
                        }

                        if (frequency.base === 6 && duration > 365) { // cada año
                            $scope.colissionMessage = true;
                        }
                    } else {
                        if (frequency.base === 4) {
                            if (!frequency.dayValues || !frequency.dayValues.length) {
                                $scope.colissionMessage = true;
                            }
                        }
                    }
                }

                var initialDuration = $scope.duration;
                $scope.$watch('duration', function (n, o) {
                    if (!n || n == o) {
                        return;
                    }

                    collissionMessage(n, $scope.myFrequency);
                    updateCron();
                });

                function updateCron() {
                    $scope.resumen = CronService.createResumen($scope.myFrequency, $scope.duration, $scope.date);
                    var newVal = cronService.setCron($scope.myFrequency, $scope.cronStyle);
                    $ngModel.$setViewValue(newVal);
                    $scope.expression = newVal;
                }

                // Usamos la fecha
                $scope.$watch('localDate', function (n, o) {
                    if (!n || n == o) {
                        return;
                    }
                    $scope.date = n.value;
                    parseDayMonth($scope.date);
                    $scope.myFrequency.dayOfMonthValues = $scope.dayOfMonthValues[0];
                    $scope.myFrequency.monthValues = $scope.monthValues[0];
                    updateCron();
                }, true);

                $scope.$watch('myFrequency', function (n, o) {
                    var evaluate = true;
                    if (n && o) {
                        $scope.colissionMessage = false;

                        if ((n && n.base && (!o || n.base !== o.base) && !modelChanged) || (n.base !== o.base)) {
                            setInitialValuesForBase(n);
                        } else if (n && n.base && o && o.base) {
                            modelChanged = false;
                        }

                        collissionMessage($scope.duration, n);

                        if (n.base === 3) {
                            $scope.duration = -1;
                        } else {
                            $scope.duration = initialDuration;
                        }

                        if (!n.base === 6 && (!n.dayValues || !n.dayValues.length)
                            || n.base === 4 && (!n.dayValues || !n.dayValues.length)
                        ) {
                            evaluate = false;
                        }

                        if (evaluate) {
                            updateCron();
                        }
                    }
                }, true);

                function setInitialValuesForBase(freq) {
                    freq.base = parseInt(freq.base);

                    if (freq.base >= baseFrequency.hour) {
                        freq.minuteValues = $scope.minuteValues[0];
                    }

                    if (freq.base >= baseFrequency.day) {
                        freq.hourValues = $scope.hourValues[0];
                    }

                    if (freq.base === baseFrequency.week) {
                        freq.dayValues = [$scope.dayValues[0]];
                    }

                    if (freq.base >= baseFrequency.month) {
                        freq.dayOfMonthValues = $scope.dayOfMonthValues[0];
                    }

                    if (freq.base === baseFrequency.year) {
                        freq.monthValues = $scope.monthValues[0];
                    }
                }

            }
        };
    }).filter('cronDayName', function ($translate) {
        return function (input, cronType) {
            var days;
            days = {
                1: $translate.instant('MINI_SUNDAY'),
                2: $translate.instant('MINI_MONDAY'),
                3: $translate.instant('MINI_TUESDAY'),
                4: $translate.instant('MINI_WEDNESDAY'),
                5: $translate.instant('MINI_THURSDAY'),
                6: $translate.instant('MINI_FRIDAY'),
                7: $translate.instant('MINI_SATURDAY')
            };

            if (input !== null && angular.isDefined(days[input])) {
                return days[input];
            } else {
                return null;
            }
        };
    })
    .filter('cronMonthName', function ($translate) {
        return function (input, cronType) {
            var months;
            months = {
                1: $translate.instant('MINI_JANUARY'),
                2: $translate.instant('MINI_FEBRUARY'),
                3: $translate.instant('MINI_MARCH'),
                4: $translate.instant('MINI_APRIL'),
                5: $translate.instant('MINI_MAY'),
                6: $translate.instant('MINI_JUNE'),
                7: $translate.instant('MINI_JULY'),
                8: $translate.instant('MINI_AUGUST'),
                9: $translate.instant('MINI_SEPTEMBER'),
                10: $translate.instant('MINI_OCTOBER'),
                11: $translate.instant('MINI_NOVEMBER'),
                12: $translate.instant('MINI_DECEMBER')
            };

            if (input !== null && angular.isDefined(months[input])) {
                return months[input];
            } else {
                return null;
            }
        };
    }).directive('ngMultiple', function () {
        return {
            restrict: 'A',
            scope: {
                ngMultiple: '='
            },
            link: function (scope, element) {
                var unwatch = scope.$watch('ngMultiple', function (newValue) {
                    if (newValue) {
                        element.attr('multiple', 'multiple');
                    } else {
                        element.removeAttr('multiple');
                    }
                });
            }
        };
    });


export default cronSelection;
