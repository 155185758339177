'use strict';

import FeedbackCharts from "../../../../feedback.charts";
import Style from "../../../../../../utilities/style.utils";

var RecognitionPolarChartCtrl = function ($scope, Languages, AuthSrvc) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {

        _this.results = [];

        function renderChart() {

            var results = _this.recognitionResults;

            const userLanguage = AuthSrvc.getLanguage();

            _this.recognitionLegend = [];
            _this.recognitionPolarChart = FeedbackCharts.recognitionPolarChart(results, Style.recognitionColors, _this.size, userLanguage, Languages);

            ;

            for (var i = 0; i <= results.length - 1; i++) {
                _this.recognitionLegend.push({
                    count: results[i].count,
                    label: (Languages.getTranslationFromUserLanguage(results[i].value.name, userLanguage)) + ' (' + results[i].count + ')',
                    color: Style.recognitionColors[i],
                    class: results[i].count === 0 ? 'zero' : ''
                });
            }

            _this.recognitionLegend.sort(function (a, b) {
                return a.count > b.count ? -1 : 1;
            });
        }

        ['recognitionResults'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {


                renderChart();
            });
        });
    };

};


const recognitionPolarChart = angular.module('happyForceApp')
    .directive('recognitionPolarChart', function () {
        return {
            scope: {
                recognitionResults: '=',
                additionalStyles: '@',
                size: '='
            },
            restrict: 'E',
            template: require('./recognition-polar-chart.html'),
            controller: RecognitionPolarChartCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default recognitionPolarChart;


