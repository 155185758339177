import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Breadcrumbs} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {getService} from 'reactInAngular';
import {getHierarchyPath} from '../../../../../../shared/services/migrated/hierarchy.service';
import HFTag from '../../../../../../shared/new-components/hf-tag/hf-tag';
import * as Sentry from '@sentry/browser';

interface UserAreaBreadcrumbsProps {
    hierarchyId: string;
    noAreaLabel: string;
}

const UserAreaBreadcrumbs = ({hierarchyId,noAreaLabel }: UserAreaBreadcrumbsProps) => {

    const [userHierarchyPath, setUserHierarchyPath] = useState([]);

    useEffect(() => {
        if (hierarchyId) {
            getHierarchyPath(hierarchyId, (err, hierarchyPath) => {
                if (err) {
                    console.error(err);
                } else {
                    setUserHierarchyPath(hierarchyPath);
                }
            });
        }
    }, [hierarchyId]);



    return (
        <Box>
            {hierarchyId && (
                <Breadcrumbs aria-label="breadcrumb"
                    separator={<ArrowRightIcon/>}
                    maxItems={2}>
                    {userHierarchyPath.map((hierarchy, index) => (
                        <Box key={hierarchy.id}>
                            <HFTag
                                text={hierarchy.name}
                                status={index < userHierarchyPath.length - 1 ? 'neutral' : 'primary'}/>
                        </Box>

                    ))}
                </Breadcrumbs>
            )}

            {!hierarchyId && (
                <Box >
                    <HFTag
                        text={ noAreaLabel}
                        status={'warning'}/>
                </Box>
            )}
        </Box>
    );
};

export default UserAreaBreadcrumbs;
