'use strict';

import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';

const EngagementRankingBox = ({ scoreId, ranking, type }) => {
    const [rankingImg, setRankingImg] = useState(null);
    const [rankingLabel, setRankingLabel] = useState(null);

    const updateRankingLabelAndImage = () => {
        if (ranking) {
            const RankingService = getService('RankingService');
            const $translate = getService('$translate');
            const Languages = getService('Languages');
            const ScoresV2Srvc = getService('ScoresV2Srvc');

            setRankingImg(RankingService.getRankingImage(ranking));

            if (type === 'HI' || type === 'ENPS') {
                setRankingLabel(
                    $translate.instant(
                        RankingService.getRankingLabel(ranking),
                        { name: (type === 'HI' ? 'HI' : 'eNPS') }
                    )
                );
            } else {
                ScoresV2Srvc.getScore(scoreId, (err, score) => {
                    setRankingLabel(
                        $translate.instant(
                            RankingService.getRankingLabel(ranking),
                            { name: Languages.getTranslationFromUserLanguage(score.name) }
                        )
                    );
                });
            }
        }
    };

    useEffect(() => {
        updateRankingLabelAndImage();
    }, [ranking]);

    return (
        <div className="row">
            <div className="col-xs-12">
                <div className="text-center">
                    <img className="img-sm" src={rankingImg} alt="Ranking" />
                </div>
                <div className="m-t-xs">
                    <div className="small-style" dangerouslySetInnerHTML={{ __html: rankingLabel }}></div>
                </div>
            </div>
        </div>
    );
};

angularize(EngagementRankingBox, 'hfEngagementRankingBox', angular.module('happyForceApp'), {
    scoreId: '=',
    ranking: '=',
    type: '='
});

export default EngagementRankingBox;
