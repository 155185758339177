import {
    LiteralUnion,
    MaterialReactTable,
    type MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_Row,
    type MRT_RowSelectionState,
    MRT_SortingState,
    MRT_TableInstance, MRT_TablePagination,
    MRT_ToolbarAlertBanner,
    useMaterialReactTable
} from 'material-react-table';
import UserListingActionsMenu from '../actions_menu';
import {Box, Grid} from '@mui/material';
import UserDetailPanel from '../user_editor/UserDetailPanel';
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {BulkActionPerson, BulkActionType, Person} from '../../types';
import AvatarCell from '../cell_types/AvatarCell';
import BasicPersonDataCell from '../cell_types/BasicPersonDataCell';
import AreaCell from '../cell_types/AreaCell';
import SegmentsCell from '../cell_types/SegmentsCell';
import GroupsCell from '../cell_types/GroupsCell';
import StatusCell from '../cell_types/StatusCell';
import {getService} from '../../../../../../../migration_utils/react-in-angular';
import {DeepKeys} from '@tanstack/react-table';
import SelectionBanner from '../selection_banner';
import BulkActionRecordStatus from '../cell_types/BulkActionRecordStatus';


interface UserListingTableProps {
    totalUsers: number;
    data: Person[];
    isError: boolean;
    isRefetching: boolean;
    isLoading: boolean;
    isSaving: boolean;
    currentEditingRow: MRT_Row<Person> | MRT_Row<BulkActionPerson>  | null;
    currentEditingPerson: Person | BulkActionPerson | null;
    setColumnFilters: Dispatch<SetStateAction<MRT_ColumnFiltersState>>;
    setGlobalFilter: Dispatch<SetStateAction<string>>;
    setSorting: Dispatch<SetStateAction<MRT_SortingState>>;
    setCurrentEditingRow: (editRow: any) => void;
    setCurrentEditingPerson: ((person: Person) => void) | ((person: BulkActionPerson) => void);
    setPagination: Dispatch<SetStateAction<MRT_PaginationState>>;
    sorting: MRT_SortingState;
    globalFilter: string;
    columnFilters: MRT_ColumnFiltersState;
    onTableCreated: (table: MRT_TableInstance<Person>) => void;
    pagination: MRT_PaginationState;
    handleSaveUser: ((props: {
        exitEditingMode: () => void;
        row: MRT_Row<Person>;
        table: MRT_TableInstance<Person>;
        values: Record<LiteralUnion<string & DeepKeys<Person>>, any>;
    }) => Promise<void> | void) | ((props: {
        exitEditingMode: () => void;
        row: MRT_Row<BulkActionPerson>;
        table: MRT_TableInstance<BulkActionPerson>;
        values: Record<LiteralUnion<string & DeepKeys<BulkActionPerson>>, any>;
    }) => Promise<void> | void);
    handleCreateUser: ((props: {
        exitCreatingMode: () => void;
        row: MRT_Row<Person>;
        table: MRT_TableInstance<Person >;
        values: Record<LiteralUnion<string & DeepKeys<Person>>, any>;
    }) => void) | ((props: {
        exitCreatingMode: () => void;
        row: MRT_Row<BulkActionPerson>;
        table: MRT_TableInstance<BulkActionPerson >;
        values: Record<LiteralUnion<string & DeepKeys<BulkActionPerson>>, any>;
    }) => void);
    handleCreateUserCancel: () => void;
    onValidationChange: (isValid: boolean) => void;
    handleDeleteUser: ((row: MRT_Row<Person> | MRT_Row<Person>[]) => void) | ((row: MRT_Row<BulkActionPerson> | MRT_Row<BulkActionPerson>[]) => void);
    handleResendInvitation: (row: MRT_Row<Person> | MRT_Row<Person>[]) => void;
    handleResetUserProfile: (row: MRT_Row<Person> | MRT_Row<Person>[]) => void;
    handleResubscribeUser: (row: MRT_Row<Person> | MRT_Row<Person>[]) => void;
    bulkActionType?: BulkActionType | undefined;
    isInviting?: boolean | undefined;
}




const UserListingTable = ({
    totalUsers,
    data,
    currentEditingRow,
    isError,
    isRefetching,
    isSaving,
    isLoading ,
    setColumnFilters,
    setPagination,
    sorting,
    setGlobalFilter,
    setSorting,
    setCurrentEditingRow,
    setCurrentEditingPerson,
    currentEditingPerson,
    columnFilters,
    globalFilter,
    onTableCreated,
    pagination,
    handleSaveUser,
    handleCreateUser,
    handleCreateUserCancel,
    onValidationChange,
    handleDeleteUser,
    handleResendInvitation,
    handleResetUserProfile,
    handleResubscribeUser,
    bulkActionType = undefined,
    isInviting=false
} : UserListingTableProps) => {

    const $translate = getService('$translate');

    const [isValid, setIsValid] = useState(true);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const isForBulkAction = bulkActionType !== undefined;

    // eslint-disable-next-line camelcase
    const columns = useMemo<MRT_ColumnDef<Person>[]>(
        //column definitions...
        () => {

            const columns: MRT_ColumnDef<Person>[] = [];

            if (!isForBulkAction) {
                columns.push(
                    {

                        accessorKey: 'person_avatar',
                        header: null,
                        enableSorting: false,
                        grow: false,
                        minSize: 80,
                        maxSize: 80,
                        enableEditing: false,
                        Cell: ({row}) => <AvatarCell person={row.original}
                            bulkActionType={bulkActionType}/>
                    }
                );
            } else {
                columns.push(
                    {

                        accessorKey: 'recordFailures',
                        header: null,
                        enableSorting: true,
                        grow: false,
                        minSize: 80,
                        maxSize: 80,
                        enableEditing: false,
                        Cell: ({row}) => <BulkActionRecordStatus person={row.original}
                            bulkActionType={bulkActionType}/>
                    }
                );

            }

            columns.push(
                {
                    accessorKey: 'person_basic',
                    header: $translate.instant('NAME'),
                    enableEditing: false,
                    enableSorting: true,
                    sortDescFirst: true,
                    sortingFn: (rowA, rowB, columnId):number => {
                        if (rowA.original?.name && rowB.original?.name) {
                            return rowA?.original?.name.localeCompare(rowB?.original?.name);
                        } else {
                            return rowA?.original?.email?.localeCompare(rowB?.original?.email);
                        }
                    },
                    Cell: ({ row }) => <BasicPersonDataCell person={row.original}
                        bulkActionType={bulkActionType}/>
                }
            );

            if (!isForBulkAction || bulkActionType != BulkActionType.DELETE) {
                columns.push(
                    {
                        accessorKey: 'person_area',
                        header: $translate.instant('AREA'),
                        enableEditing: false,
                        enableSorting: false,
                        Cell: ({row}) => <AreaCell person={row.original}
                            bulkActionType={bulkActionType}/>,
                        muiEditTextFieldProps: {
                            fullWidth: true,
                        }
                    },
                    {
                        accessorKey: 'person_segments',
                        header: $translate.instant('PROFILE'),
                        enableEditing: false,
                        enableSorting: false,
                        Cell: ({row}) => <SegmentsCell person={row.original}
                            bulkActionType={bulkActionType}/>
                    },
                    {
                        accessorKey: 'person_groups',
                        header: $translate.instant('GROUPS'),
                        enableEditing: false,
                        enableSorting: false,
                        Cell: ({row}) => <GroupsCell person={row.original} bulkActionType={bulkActionType}/>

                    }
                );
            }

            if (!isForBulkAction || bulkActionType === BulkActionType.DELETE) {
                columns.push(
                    {
                        accessorKey: 'person_status',
                        header: $translate.instant('STATUS'),
                        enableSorting: false,
                        enableEditing: false,
                        Cell: ({ row }) => <StatusCell  person={row.original}/>
                    }
                );

            }



            return columns;
        },
        [], //end
    );

    useEffect(() => {
        onValidationChange(isValid);
    },[isValid])


    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: !isForBulkAction,
        positionToolbarAlertBanner: 'none',
        muiToolbarAlertBannerProps: {
            color: 'none',
        },
        renderTopToolbar: ({table}) => (
            <Grid container>
                <Grid item xs={12} sm={8}>
                    <MRT_ToolbarAlertBanner
                        stackAlertBanner={true} table={table} />

                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <MRT_TablePagination table={table} />
                    </Box>
                </Grid>

            </Grid>
        ),
        onRowSelectionChange: setRowSelection,
        renderToolbarAlertBannerContent: ({groupedAlert, selectedAlert, table}) => (
            <SelectionBanner
                groupedAlert={groupedAlert}
                selectedAlert={selectedAlert}
                table={table}
                onDeleteUser={handleDeleteUser}
                onInvitationResend={handleResendInvitation}
                onResetUserProfile={handleResetUserProfile}
                onResubscribeUser={handleResubscribeUser}
            />
        ),
        getRowId: (row) => row.id,
        enableRowActions: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            showColumnFilters: false,
            density: 'compact',
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
            sorting: [
                { id: 'person_basic', desc: false }, //then sort by city in descending order by default
            ],
        },

        manualFiltering: !isForBulkAction,
        manualPagination: !isForBulkAction,
        manualSorting: !isForBulkAction,
        onSortingChange: setSorting,
        enableColumnFilters: false,
        enableColumnActions: false,
        createDisplayMode: 'row', // ('modal', and 'custom' are also available)
        editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        enableExpandAll: false, //hide expand all double arrow in column header
        enableExpanding: false,
        rowCount: totalUsers,
        state: {
            rowSelection,
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: false,
            showSkeletons: isLoading,
            showProgressBars: isRefetching || isLoading,
            isSaving: isSaving,
            sorting,
        },
        muiTablePaperProps: {
            elevation: 0,
        },
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 25, 50, totalUsers > 200 ? 200 : totalUsers],
            sx: {
                marginTop: 0,
                padding: 0,
            }
        },
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableFilters: false,
        enableHiding: false,
        muiTableHeadCellProps: {
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
                fontWeight: 'bold',
                fontSize: '14px',
            },
        },
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: '',
                size: 0, // hide the column
            },
            'mrt-row-select': {
                size: 25,
            }
        },
        renderRowActions: ({ row, table }) => {

            return (<UserListingActionsMenu
                row={row}
                setCurrentEditingRow={setCurrentEditingRow}
                setCurrentEditingPerson={setCurrentEditingPerson}
                currentEditingRow={currentEditingRow}
                onDeleteUser={handleDeleteUser}
                onInvitationResend={handleResendInvitation}
                onResetUserProfile={handleResetUserProfile}
                onResubscribeUser={handleResubscribeUser}
                bulkActionType={bulkActionType}
                table={table}/>)
        },


        muiExpandButtonProps: ({row}) => ({
            children: <Box/>, // <-- empty box to hide the expand button
        }),
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onEditingRowSave: handleSaveUser,
        onCreatingRowSave: handleCreateUser,
        onCreatingRowCancel: (row) => {
            setIsValid(true);
            table.setCreatingRow(null);
            handleCreateUserCancel();
        },
        onEditingRowCancel: (row) => {
            if (currentEditingRow) {
                currentEditingRow.toggleExpanded(false)
            }
            setIsValid(true);
            setCurrentEditingRow(null);
        },
        renderDetailPanel: ({ row }) =>
        {
            return <UserDetailPanel
                person={currentEditingPerson}
                isInvite={isInviting}
                onRowChange={(updatedPerson) => {
                    setCurrentEditingPerson(updatedPerson);
                    row._valuesCache?.person = updatedPerson;
                }}
                onValidationChange={(isValid) => {
                    setIsValid(isValid);
                }}
            />
        },
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                paddingLeft: 0,
                paddingRight: 0,
            }
        }),
        positionPagination: 'both',
    });

    onTableCreated(table);

    return ( <MaterialReactTable table={table} /> );


}

export  {BulkActionType, UserListingTable};
