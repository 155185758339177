'use strict';
import hfConversationEntry from "./components/conversation-entry/feedback-conversation-entry.controller";
import hfConversationSourcePreview from "./components/conversation-source-preview/conversation-source-preview.controller";
import HFPulseMark from "../../../shared/new-components/hf-pulse-mark/hf-pulse-mark";

function FeedbackConversationsCtrl($scope, AuthSrvc, Conversation, Comments, $translate, ErrorSvrc, CompanySvrc, $state, Analytics, $stateParams, $intercom, People) {
    var _this = this;

    _this.loading = true;
    _this.type = 'conversation';
    _this.totalConversations = 0;
    _this.currentConversationsPage = 1;
    _this.conversations = [];
    _this.order_by = 'RECENT';

    // Initialize the default filters
    _this.selectedFilters = {
        conversationStatus: ['ALL']
    };

    // Initialize the default tags //TODO:!!
    _this.selectedTags = [{
        "type": "OPTION",
        "selected": [{
            "type": "conversationStatus",
            "id": "ALL",
            "title": $translate.instant('CONVERSATION_LAST_MESSAGE_FROM'),
            "selectable": true,
            "exclusive": true,
            "name": $translate.instant('CONVERSATIONS_ALL'), 
            "option": $translate.instant('CONVERSATION_LAST_MESSAGE_FROM'),
            "nonRemovable": true
        }]
    }];

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.conversationStatus = {
        title: $translate.instant('CONVERSATION_LAST_MESSAGE_FROM'),
        nonRemovable: true,
        options: [
            {
                type: 'conversationStatus',
                id: 'ALL',
                title: $translate.instant('CONVERSATIONS_ALL'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'conversationStatus',
                id: 'PENDING',
                title: $translate.instant('CONVERSATIONS_PENDING'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'conversationStatus',
                id: 'REPLIED',
                title: $translate.instant('CONVERSATIONS_REPLIED'),
                selectable: true,
                exclusive: true
            }
        ]
    };

    _this.conversationOwner = {
        title: $translate.instant('CONVERSATION_OWNER'),
        nonRemovable: true,
        options: [
            {
                type: 'conversationOwner',
                id: null,
                title: $translate.instant('CONVERSATIONS_ALL'),
                selectable: true,
                exclusive: true
            },
            {
                type: 'conversationOwner',
                id: AuthSrvc.user().id,
                title: $translate.instant('CONVERSATIONS_ME'),
                selectable: true,
                exclusive: false
            }
        ]
    };

    _this.optionFiltersConfig = {
        filters: [_this.conversationStatus]
    };

    if (AuthSrvc.isADVISOR() || AuthSrvc.isADMIN()) {
        People.find({
            from: 0,
            to: 1000,
            role: 'MANAGER',
            deleted: false

        }, function (err, managers) {
            if (err)
                return;

            managers.results.forEach(function (manager) {
                _this.conversationOwner.options.push({
                    type: 'conversationOwner',
                    id: manager.id,
                    title: manager.name,
                    selectable: true,
                    exclusive: false
                });
            });
        });

        _this.optionFiltersConfig.filters.push(_this.conversationOwner);
    }

    _this.dateFrom = {
        title: $translate.instant('FROM'),
        nonRemovable: false,
        id: 'from',
    };

    _this.dateTo = {
        title: $translate.instant('TO'),
        nonRemovable: false,
        id: 'to'
    };

    _this.dateFiltersConfig = {
        filters: [_this.dateFrom, _this.dateTo]
    };

    if (AuthSrvc.isADVISOR() || AuthSrvc.isADMIN()) {
        _this.selectedFilters.conversationOwner = [AuthSrvc.user().id];
    }

    _this.orderTypes = [
        { id: 'RECENT', label: $translate.instant('RECENT') },
        { id: 'OLDER', label: $translate.instant('OLDER') }
    ];


    _this.header = {
        title: $translate.instant('CONVERSATIONS_SECTION_TITLE'),
        subtitle: $translate.instant('CONVERSATIONS_SECTION_SUBTITLE')
    };


    _this.sortBy = function (orderBy) {
        _this.order_by = orderBy;
    };

    _this.loadConversations = function () {



        delete _this.conversationId;

        var params = {
            fromConversation: (_this.currentConversationsPage - 1) * 10,
            toConversation: ((_this.currentConversationsPage - 1) * 10) + 10,
            orderBy: _this.order_by
        };

        if (_this.selectedFilters['conversationStatus']) {
            params.status = _this.selectedFilters['conversationStatus'][0];
        } else {
            params.status = 'ALL';
        }

        if (_this.selectedFilters['conversationOwner']) {

            var owners = _.filter(_this.selectedFilters['conversationOwner'], function (owner) { return owner != null; });
            if (owners.length > 0)
                params.ownerId = owners;
        }

        if (_this.selectedFilters['from']) {
            params.from = _this.selectedFilters['from'];
        }


        if (_this.selectedFilters['to']) {
            params.to = _this.selectedFilters['to'];
        }

        Conversation.getAll(params, function (err, conversations) {
            _this.loading = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
                console.log('ERROR getAll', err);
            }

            var results = conversations && conversations.results;
            _this.totalConversations = conversations.total;
            if (results) {
                _this.conversations = results;
            } else {
                this.conversations = [];
            }
        });

    };

    _this.selectConversation = function (conversationId) {
        setTimeout(function () {
            $(window).trigger('resize');
        }, 500);

        _this.conversationId = conversationId;

    };

    _this.conversationStarted = function (err, conversationId) {
        if (!err) {
            console.log('Conversarion started');
            _this.conversationId = conversationId;
        }
    };

    this.$onInit = function () {
        _this.status = 'ALL';


        ['selectedFilters', 'currentConversationsPage', 'order_by'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                _this.loadConversations();
            }, true);
        });

        _this.loadConversations();

        Analytics.trackEvent('dashboard_visited_conversations');
        $intercom.trackEvent('dashboard_visited_conversations');
    };

}

const hfConversationsSection = angular.module('happyForceApp')
    .directive('hfConversationsSection', function () {
        return {
            template: require('./feedback-conversations.html'),
            controller: FeedbackConversationsCtrl,
            controllerAs: '$ctrl',
            bindToController: false
        };
    });

export default hfConversationsSection;
