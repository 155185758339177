'use strict';

import UserUtils from "../../../../utilities/user.utilities";

var UserSmallCtrl = function ($scope, People, ErrorSvrc, $translate) {
    'ngInject'
    var _this = this;

    _this.update = function () {
        this.preText = _this.preText;
        this.postText = _this.postText;
        this.subImage = _this.subImage;
        this.hierarchyAnonymized = _this.hierarchyAnonymized;
        this.isAnnonymous = !_this.userId;

        if (_this.userId) {
            People.getProfile(_this.userId, function (err, user) {
                if (err) {
                    _this.user = {
                        shortName: '?',
                        name: $translate.instant('UNKNOWN_USER_NAME')
                    };
                } else {
                    user.shortName = UserUtils.getUserNameAcronym(user.name, 'HF', 2);
                    _this.user = user;

                }
            });

        } else if (_this.hierarchyName) {
            _this.user = {
                name: $translate.instant('HIERARCHY_SOMEONE_OF', { hierarchyName: _this.hierarchyName }),
                profileImagePath: 'images/annonymous_badge.png'
            };

        } else {
            _this.user = {
                shortName: '?',
                name: $translate.instant('UNKNOWN_USER_NAME')
            };
        }
    };


    this.$onInit = function () {


        ['hierarchyAnonymized', 'userId', 'hierarchyName'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                _this.update();
            });
        });

        _this.update();
    };



    _this.is = function (value) {
        if (_this.section === value) {
            return true;
        } else {
            return false;
        }
    };
};


const hfUserSmall = angular.module('happyForceApp')
    .directive('hfUserSmall', function () {
        return {
            scope: {
                userId: '<',
                hierarchyName: '<',
                hierarchyAnonymized: '<',
                preText: '@',
                postText: '@',
                subImage: '@'
            },
            template: require('./user-small.html'),
            controller: UserSmallCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });

export default hfUserSmall;
