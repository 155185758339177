import React, {useEffect} from 'react';

import {Box} from '@mui/material';
import AreaClickableTag from './components/AreaClickableTag';




interface HfAreaTagSelectorProps {
    hierarchyId?: string | string[];
    updateHierarchyId: (hierarchyId: string | string[]) => void;
    onRemove?: (hierarchyId: string) => void;
    addLabel?: string;
}



const HfAreaTagSelector = ({hierarchyId, updateHierarchyId, onRemove, addLabel}: HfAreaTagSelectorProps) => {


    const [hierarchyIds, setHierarchyIds] = React.useState<string[]>([]);
    const [isArray, setIsArray] = React.useState<boolean>(false);

    useEffect(() => {

        if (Array.isArray(hierarchyId)) {
            setHierarchyIds(hierarchyId);
            setIsArray(true);
        } else {
            setHierarchyIds([hierarchyId]);
            setIsArray(false);
        }

    }, [hierarchyId]);

    if (!hierarchyIds) {
        return null;
    }


    return (
        <Box sx={theme => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: theme.spacing(1)
        })}>

            {/* Render all the manager areas */}
            {hierarchyIds.map((hierarchyId, index) => (
                <Box key={hierarchyId} sx={theme => ({marginRight: theme.spacing(1)})}>
                    <AreaClickableTag
                        hierarchyId={hierarchyId}
                        onRemove={() => {
                            if (onRemove) {
                                onRemove(hierarchyId);
                            }
                        }}
                        updateHierarchyId={(newHierarchyId) => {

                            if (newHierarchyId !== hierarchyId) {
                                if (isArray) {
                                    const newHierarchyIds = hierarchyIds.filter((_, i) => i !== index);
                                    newHierarchyIds.push(newHierarchyId);
                                    updateHierarchyId(newHierarchyIds);
                                } else {
                                    updateHierarchyId(newHierarchyId);
                                }

                            }
                        }}
                    />
                </Box>
            ))}
            {/* Add a button to add a new manager area */}
            <Box  sx={theme => ({marginRight: theme.spacing(1)})}>
                <AreaClickableTag
                    isAddButton
                    addLabel={addLabel}
                    updateHierarchyId={
                        (newHierarchyId) => {
                            if (!hierarchyIds?.includes(newHierarchyId)) {
                                if (isArray) {
                                    const newHierarchyIds = hierarchyIds.filter((id, i) => id !== newHierarchyId);
                                    newHierarchyIds.push(newHierarchyId);
                                    updateHierarchyId(newHierarchyIds);
                                } else {
                                    updateHierarchyId(newHierarchyId);
                                }
                            }
                        }
                    }/>
            </Box>
        </Box>

    );
}


export default HfAreaTagSelector;
