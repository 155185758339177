


const routes = function ($stateProvider, $urlRouterProvider, $ocLazyLoadProvider) {
    'ngInject';
    $urlRouterProvider.otherwise('/onboarding');

    //$locationProvider.html5Mode(true);

    $ocLazyLoadProvider.config({
        // Set to true if you want to see what and when is dynamically loaded
        debug: true
    });

    // Private
    $stateProvider
        .state('private', {
            abstract: true,
            url: '/private',
            template: require('../partials/common/content.html'),
        });

    /**
     *   Rutas Topics
     */

    $stateProvider
        .state('topics', {
            abstract: true,
            url: '/topics',
            template: require('../partials/common/content.html')
        });

    $stateProvider
        .state('conversations', {
            abstract: true,
            url: '/conversations',
            template: require('../partials/common/content.html')
        });

    $stateProvider
        .state('company', {
            abstract: true,
            url: '/company',
            template: require('../partials/common/content.html')
        });

    // Announcements
    $stateProvider
        .state('announcements', {
            abstract: true,
            url: '/announcements',
            template: require('../partials/common/content.html')
        });

    // Analytics
    $stateProvider
        .state('analytics', {
            abstract: true,
            url: '/analytics',
            template: require('../partials/common/content.html')
        });

    // Feedback
    $stateProvider
        .state('feedback', {
            abstract: true,
            url: '/feedback',
            template: require('../partials/common/content.html')
        });

    $stateProvider
        .state('advisors', {
            abstract: true,
            url: '/advisors',
            template: require('../partials/common/content.html')
        });

    /*   $stateProvider
        .state('setup', {
            abstract: true,
            url: '/setup',
            template: require('../partials/common/content.html')
        });
*/
    $stateProvider
        .state('manage', {
            abstract: true,
            url: '/manage',
            template:  require('../partials/common/content.html')
        });


    // Surveys
    $stateProvider
        .state('surveys', {
            abstract: true,
            url: '/surveys',
            template: require('../partials/common/content.html')
        });


    // Engagement Screen
    $stateProvider
        .state('engagement', {
            abstract: true,
            url: '/engagement',
            template: require('../partials/common/content.html')
        });

    //TODO: if this is not working, try to do a new custom with barcelonesa. As this one, but be carefu with hflifecycle error.


    // Engagement Beta Screen
    $stateProvider
        .state('beta', {
            abstract: true,
            url: '/beta',
            template: require('../partials/common/content.html')
        });


    // Mas Movil Custom
    $stateProvider
        .state('custom', {
            abstract: true,
            url: '/lc',
            template: require('../partials/common/content.html')
        });


    // Engagement Screen
    $stateProvider
        .state('reports', {
            abstract: true,
            url: '/reports',
            template: require('../partials/common/content.html')
        });


    // Launch Screen
    $stateProvider
        .state('launch', {
            abstract: true,
            url: '/launch',
            template: require('../partials/common/content.html')
        });

    // Journey Screen
    $stateProvider
        .state('journey', {
            abstract: true,
            url: '/journey',
            template: require('../partials/common/react_content.html')
        });

};


export default routes;
