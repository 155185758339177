import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Numbers from 'root/app/utilities/number.utilities';
import Style from 'root/app/utilities/style.utils';

const RangeTopic = ({ question, results }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [periodData, setPeriodData] = useState({
        index: '',
        color: '',
        participation: '',
        participants: '',
        expectedParticipants: '',
    });
    const [recurrentChartConfig, setRecurrentChartConfig] = useState(null);
    const [distributionChartConfig, setDistributionChartConfig] = useState(null);
    const $translate = getService('$translate');


    const buildEvolutionConfigChart = (data, question) => {

        var formatedData = [];
        data.forEach(function (dataElement) {
            formatedData.push([dataElement.date,  Numbers.roundNumber(dataElement.index, 1)]);
        });

        const maxValue = question.values.reduce((max, value) => Math.max(max, value.score), 0);

        const chartColorZones = question.values.map((value, index) => {
            return {
                value: value.score,
                color: value.color,
            }
        });

        return {
            chart: {
                type: 'areaspline',
                height: 190,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                fontFamily: 'HelveticaNeue-Light',
            },
            title: {
                text: '',
                enabled: false
            },

            plotOptions: {
                series: {
                    lineWidth: 1,
                    allowPointSelect: true,
                    dataLabels: {
                        enabled: false
                    },
                    marker: {
                        enabled: true,
                    },
                    point: {
                        events: {
                            select: function () {
                                onEvolutionChartClick(event && event.point && event.point.x);
                            },
                            unselect: function () {
                                onEvolutionChartClick(null);
                            }
                        }
                    },
                }
            },
            xAxis: {
                type: 'datetime',

                startOnTick: false,
                title: {
                    enabled: false
                }

            },
            yAxis: {
                tickColor: '#b5bbc3',
                title: {
                    enabled: false
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'Dot',
                labels: {
                    style: {
                        color: '#b5bbc3'
                    }
                },
                min: 0,
                max: maxValue,
                tickInterval: 2
            },

            tooltip: {
                enabled: true,
                headerFormat: '<b>{point.x:%d %b %Y}:</b><br>',
                pointFormat: '{point.y:.1f}'
            },

            series: [{
                showInLegend: false,
                data: formatedData,
                zones: chartColorZones,
            }]
        };
    };


    const buildDistributionConfigChart = (labels, values, colors) =>  {

        var series = [];

        $.each(values, function (x, value) {
            series.push({ name: labels[x], data:  [Numbers.roundNumber(value, 1)] });
        });

        return {
            colors: colors,
            chart: {
                type: 'bar',
                height: 60,
                spacingBottom: 0,
                marginTop: 0,
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },

            xAxis: {
                visible: false
            },
            yAxis: {
                visible: false
            },
            legend: {
                reversed: true,
                enabled: false
            },
            tooltip: {
                enabled: true,

                formatter: function () {
                    return this.series.name + ': <b>' + this.y + '%</b>';
                }
            },
            title: {
                text: ''
            },
            series: series
        };
    };

    const computeResults = (result, questionValues) => {
        let labels = [];
        let values = [];
        let colors = [];
        let index = 0;
        let color = '#EEEEEE';

        if (result.periodParticipants > 0) {
            let pos = 0;
            let maxValue = 0;
            _.forEach(questionValues, function (option) {
                labels.push($translate.instant(option.label.defaultValue));
                let currentValue = result.ratingPercents[option.id];
                let currentColor = Style.questionColors[pos++];
                if (currentValue > maxValue) {
                    color = currentColor;
                    maxValue = currentValue;
                }

                values.push(currentValue);
                colors.push(currentColor);
            });

            index = Numbers.roundNumber(result.periodIndex, 1);
        } else {
            labels.push('');
            values.push(100);
            colors.push('#EEEEEE');
        }

        return {
            labels,
            values,
            colors,
            index,
            color,
            expectedParticipants: result.expectedParticipants,
            periodParticipants: result.periodParticipants,
        };
    };

    const updateCurrentData = () => {
        if (!results || !question) {return;}


        if (selectedDate) {

            const dateResult = _.find(results.results, function (elem) {
                return new Date(elem.from).getTime() === selectedDate;
            });

            if (dateResult) {

                const filteredResult = computeResults(dateResult, question.values);

                setPeriodData({
                    index: filteredResult.index,
                    color: filteredResult.color,
                    participants: filteredResult.periodParticipants,
                    expectedParticipants: filteredResult.expectedParticipants,
                    participation: Numbers.roundNumber(
                        Numbers.calculatePercent(filteredResult.periodParticipants, filteredResult.expectedParticipants),
                        1,
                    ),
                });

                setDistributionChartConfig(
                    buildDistributionConfigChart(filteredResult.labels, filteredResult.values, filteredResult.colors),
                );
            }

        } else {

            const globalResults = computeResults(results.periodResults, question.values);

            setPeriodData({
                index: globalResults.index,
                color: globalResults.color,
                participants: globalResults.periodParticipants,
                expectedParticipants: globalResults.expectedParticipants,
                participation: Numbers.roundNumber(
                    Numbers.calculatePercent(globalResults.periodParticipants, globalResults.expectedParticipants),
                    1,
                ),
            });

            setDistributionChartConfig(
                buildDistributionConfigChart(globalResults.labels, globalResults.values, globalResults.colors),
            );
        }
    };

    const updateRecurrentChart = () => {
        if (!results || !question) {return;}

        if (question.activationRule && question.activationRule.ruleType === 'RECURRENT') {
            const recurrentResults = results.results.filter((recurrentResult) => {
                return recurrentResult.periodIndex > 0;
            }).map((recurrentResult) => ({
                date: new Date(recurrentResult.from).getTime(),
                index: recurrentResult.periodIndex,
            }));

            setRecurrentChartConfig(buildEvolutionConfigChart(recurrentResults, question));
        } else {
            setRecurrentChartConfig(null);
        }
    }



    /*
    Used to handle the click event on the evolution chart, in order to show only the selected period
    result
    * @param {string} date
     */
    const onEvolutionChartClick =  (date) => {
        if (!!selectedDate || selectedDate === date) {
            setSelectedDate(null);
        } else {
            setSelectedDate(date);
        }
    };



    useEffect(() => {
        updateRecurrentChart();
        updateCurrentData();
    }, [question, results, selectedDate]);

    return (
        <div className="row no-padding">
            <div className="col-xs-12 col-sm-4">
                <h5 className="big-style">{$translate.instant('TOPIC_RANGE_VOTE_RESULT')}</h5>
                <p className="mini-style warm-grey">{$translate.instant('TOPIC_RANGE_VOTE_RESULT_DESCRIPTION')}</p>


                <div className="big-number-container text-center" style={{color: periodData.color}}>
                    <span className="big-number">{periodData.index}</span>
                    <span className="big-number-sub">pts</span>
                </div>

                <div className="text-center">
                    <span className="small-style warm-grey">{$translate.instant('TOPIC_DETAILS_QUESTION_PARTICIPATION')}</span>
                    <span className="small-style"> {periodData.participation}%</span>
                    <span
                        className="warm-grey"> ({periodData.participants} {$translate.instant('OF')} {periodData.expectedParticipants})</span>
                </div>

                {(question.activationRule.ruleType === 'RECURRENT' && distributionChartConfig) && (
                    <div className="p-t">
                        <p className="small-style warm-grey">{$translate.instant('TOPIC_RANGE_VOTE_RESULT_DISTRIBUTION')}</p>
                        <HighchartsReact highcharts={Highcharts} options={distributionChartConfig} />
                    </div>
                )}

            </div>
            {question.activationRule.ruleType === 'RECURRENT' && (
                <div className="col-xs-12 col-sm-8">
                    <h5 className="big-style">{$translate.instant('TOPIC_RANGE_VOTE_RESULT_EVOLUTION')}</h5>
                    <p
                        className="mini-style warm-grey">{$translate.instant('TOPIC_RANGE_VOTE_RESULT_EVOLUTION_DESCRIPTION')}</p>
                    {recurrentChartConfig &&
                        <HighchartsReact highcharts={Highcharts} options={recurrentChartConfig}/>}

                    {selectedDate && (
                        <div className="text-center">
                            <span className="small-style warm-grey">
                                {$translate.instant(
                                    'TOPIC_VOTE_RESULT_VIEWING_DETAILS',
                                    {selectedDate: new Date(selectedDate).toLocaleDateString()})
                                }
                            </span>
                        </div>
                    )}
                </div>
            )}
            {(question.activationRule.ruleType !== 'RECURRENT' && distributionChartConfig) && (
                <div className="col-xs-12 col-sm-8" style={{paddingTop: question.activationRule.ruleType === 'RECURRENT' ? '0' : '1rem'}}>
                    <h5 className="big-style">{$translate.instant('TOPIC_RANGE_VOTE_RESULT_DISTRIBUTION')}</h5>
                    <p
                        className="mini-style warm-grey">{$translate.instant('TOPIC_RANGE_VOTE_RESULT_DISTRIBUTION_DESCRIPTION')}</p>
                    {distributionChartConfig &&
                        <HighchartsReact highcharts={Highcharts} options={distributionChartConfig}/>}
                </div>
            )}



        </div>
    );
};

RangeTopic.propTypes = {
    question: PropTypes.object.isRequired,
    results: PropTypes.object.isRequired,
    updateCallback: PropTypes.func,
};

angularize(RangeTopic, 'hfRangeTopic', angular.module('happyForceApp'), {
    question: '<',
    results: '<',
    updateCallback: '&',
});

export default RangeTopic;
