import React, {FC, useEffect, useState, JSX} from 'react';
import {getService} from 'reactInAngular';
import {IHeatmapColumn, HeatmapType, IHeatmapRow,} from './heatmap-types';
import {
    buildLoadedSegmentKey,
    createRowDefinition
} from './heatmap_utils';
import HeatmapColumn from './heatmap_column';
import HeatmapRow from './heatmap_row';
import angular from 'angular';
import {getHierarchies} from '../../services/migrated/hierarchy.service';
import Dates from '../../../utilities/date.utilities';
import {APISettings} from '../../services/migrated/axiosWrapper';
import HappyLoading from '../../components/_migrated/loader/loading.directive';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';


// Define interfaces for props and any complex objects used within the component
interface HeatmapProps {
    to: Date;
    dateGrouping: string;
    columns: IHeatmapColumn[];
    buildCell: (column: IHeatmapColumn, row: IHeatmapRow, keyDate: string) => JSX.Element;
    onColumnPress: (columnId: string, showChildren: boolean) => void;
    onDataRequired: (idsToFetch: string[]) => void;
    loadingData: boolean;
    preparingColumns: boolean;
    rowsNotEnoughEmployees: Set<string>;
    heatmapGroupBy: HeatmapType;
    onHeatmapGroupByChange?: (heatmapGroupBy: HeatmapType) => void;

}



const HFHeatmap: FC<HeatmapProps> = ({
    to,
    dateGrouping ,
    buildCell,
    columns,
    onDataRequired,
    onColumnPress,
    loadingData,
    preparingColumns,
    rowsNotEnoughEmployees,
    heatmapGroupBy,
    onHeatmapGroupByChange
}) => {


    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');
    const CompanySvrc = getService('CompanySvrc');
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');


    // Use it to the grid to have 2 columns as margin
    const numPaddingColumns = 2;


    const [rows, setRows] = useState<IHeatmapRow[]>([]);
    const [preparingRows, setPreparingRows] = useState<boolean>(false);

    const groupByOptions = [
        { id: HeatmapType.HIERARCHIES, label: $translate.instant('AREAS') },
        { id: HeatmapType.GROUPS, label: $translate.instant('GROUPS') },
        { id: HeatmapType.CHARACTERISTICS, label: $translate.instant('SEGMENTS') }
    ];


    function loadRecursiveHierarchies(parentId, hierarchiesToLoad, hierarchyLevel, hierarchyRows) {
        hierarchyLevel++;
        hierarchiesToLoad.forEach(function (hierarchy, index) {
            const hierarchyRow = createRowDefinition(hierarchy.id, hierarchy.name,
                'HIERARCHY', hierarchy.activeEmployees,
                false, parentId, false, hierarchyLevel, false);
            hierarchyRows.push(hierarchyRow);
            if (hierarchy.children) {
                hierarchyRow.hasChildren = true;
                loadRecursiveHierarchies(hierarchy.id, hierarchy.children, hierarchyLevel, hierarchyRows);
            }

        });
    }



    /*
    * Verify all the rows that need data from the API
     */

    const verifyRequiresData = () => {

        const requiredIds = [];



        rows.forEach((row) => {
            if (row.visible &&
                (row.type !== 'CHARACTERISTIC' ||
                    (row.type === 'CHARACTERISTIC' && row.parentId !== null))) {

                if (row.segmentId !== 'Company') {
                    /*
                          const keyDate = Dates.toAPIFormat(to, APISettings.apiDateFormat);
                          const toLoadKey = buildLoadedSegmentKey(row.segmentId, keyDate, heatmapGroupBy, dateGrouping);
                     */


                    requiredIds.push(row.segmentId);
                }
            }
        });

        if (requiredIds.length > 0) {
            onDataRequired(requiredIds);
        }
    };




    const swapRowDeployed = (segmentId, showChildren) => {

        // Check if the row has children
        if (rows?.filter(r => r.segmentId === segmentId)[0]?.hasChildren) {
            const updatedRows = angular.copy(rows);

            updatedRows.forEach((row) => {
                if (row.segmentId === segmentId) {
                    row.isDeployed = showChildren;
                }
                if (row.parentId === segmentId) {
                    row.visible = showChildren;
                }
            });
            setRows(updatedRows);
        }
    }

    // Update the heatmap rows when the group by option changes
    useEffect(() => {

        const tmpRows:IHeatmapRow[] = [];
        setPreparingRows(true); // Start loading

        //Add company row
        CompanySvrc.getUserCompany(function (err, results) {

            const companyRow = createRowDefinition('Company', results.name, 'HIERARCHY', undefined,
                false, null, false, 0, false);
            companyRow.visible = true;

            tmpRows.push(companyRow);

            //List hierarchies!
            if (HeatmapType.HIERARCHIES === heatmapGroupBy) {
                getHierarchies(function (err, results) {
                    if (!err) {

                        results.forEach(function (hierarchy) {
                            const hierarchyRow = createRowDefinition(hierarchy.id, hierarchy.name, 'HIERARCHY', hierarchy.activeEmployees,
                                true, null, false, 0, false);
                            if (hierarchy.children) {
                                hierarchyRow.hasChildren = true;
                            }

                            tmpRows.push(hierarchyRow);

                            if (hierarchy.children) {
                                //Add recursive children to row
                                loadRecursiveHierarchies(hierarchy.id, hierarchy.children, 0, tmpRows);
                            }
                        });
                        setRows(tmpRows);
                    } else {
                        ErrorSvrc.showErrorModal(err);
                    }
                    setPreparingRows(false); // End loading
                });
            } else if (HeatmapType.GROUPS === heatmapGroupBy) {
                Groups.getAll({
                    active: true
                }, function (err, results) {
                    if (!err) {
                        results.forEach(function (group) {
                            const groupRow = createRowDefinition(group.id, group.name, 'GROUP', group.employeeCount,
                                true, null, false, 0, false);

                            tmpRows.push(groupRow);
                        });
                        setRows(tmpRows);
                    } else {
                        ErrorSvrc.showErrorModal(err);
                    }
                    setPreparingRows(false); // End loading
                });
            } else if (HeatmapType.CHARACTERISTICS === heatmapGroupBy) {
                Characteristics.getAll(function (err, results) {
                    if (!err) {
                        results.forEach(function (characteristic) {
                            const characteristicRow = createRowDefinition(characteristic.id, characteristic.description, 'CHARACTERISTIC', characteristic.employeeCount,
                                true, null, false, 0, false);

                            if (characteristic.values || characteristic.ranges) {
                                characteristicRow.hasChildren = true;
                            }
                            tmpRows.push(characteristicRow);

                            if (characteristic.values) {
                                characteristic.values.forEach(function (characteristicValue) {
                                    const rowId = characteristic.id + ':' + characteristicValue.id;
                                    const characteristicRowValue = createRowDefinition(rowId, characteristicValue.value.defaultValue,
                                        'CHARACTERISTIC', characteristicValue.employeeCount,
                                        false, characteristic.id, false, 1, false);

                                    tmpRows.push(characteristicRowValue);
                                });
                            } else if (characteristic.ranges) {
                                characteristic.ranges.forEach(function (characteristicValue) {
                                    const rowId = characteristic.id + ':' + characteristicValue.id;

                                    const characteristicRowValue = createRowDefinition(rowId, characteristicValue.name,
                                        'CHARACTERISTIC', characteristicValue.employeeCount,
                                        false, characteristic.id, false, 1, false);

                                    tmpRows.push(characteristicRowValue);
                                });
                            }
                        });
                        setRows(tmpRows);
                    } else {
                        ErrorSvrc.showErrorModal(err);
                    }
                    setPreparingRows(false); // End loading

                });
            }
        });
    }, [heatmapGroupBy]);


    useEffect(() => {
        if (rows && rows.length && to && dateGrouping) {
            verifyRequiresData();
        }
    }, [rows, to, dateGrouping]);


    const numVisibleColumns = columns.filter(c => c.visible).length + numPaddingColumns;

    const keyDate = Dates.toAPIFormat(to, APISettings.apiDateFormat);

    return (
        <section className="new_heatmap">
            <HappyLoading loading={preparingColumns || preparingRows}>
                <div className="row">
                    <div className="col-xs-12 col-md-push-9 col-md-3 m-b-new-lg">


                    </div>
                </div>



                <div className="row">
                    <div className="col-xs-12">
                        <div className="heatmap"
                            style={{
                                gridTemplateColumns: 'repeat(' + numVisibleColumns + ', 100px)',
                                gridTemplateRows: 'minmax(60px, 60px)'
                            }}>

                            <div className="filter-cell">
                                <FormControl fullWidth size="small">
                                    <InputLabel id="show-by-select-label">{$translate.instant('SHOW_BY')}</InputLabel>
                                    <Select
                                        labelId="show-by-select-label"
                                        label={$translate.instant('SHOW_BY')}
                                        value={heatmapGroupBy}
                                        onChange={event => {
                                            onHeatmapGroupByChange(HeatmapType[event.target.value]);
                                        }}
                                    >
                                        {groupByOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            {columns.filter(c => c.visible).map((column, index) => (
                                <HeatmapColumn
                                    key={column.id}
                                    position={index}
                                    style={{gridColumn: '' + (index + 3) + ''}}
                                    column={column}
                                    onSwapColumnDeployed={onColumnPress}>
                                </HeatmapColumn>
                            ))}

                            {rows.filter(r => r.visible).map((row, index) => (

                                <HeatmapRow
                                    key={row.segmentId}
                                    row={row}
                                    columns={columns}
                                    loading={loadingData}
                                    style={{gridRow: '' + (index + 2) + ''}}
                                    hasNonEnoughEmployees={rowsNotEnoughEmployees.has(buildLoadedSegmentKey(row.segmentId, keyDate, heatmapGroupBy, dateGrouping))}
                                    onSwapRowDeployed={swapRowDeployed}>
                                    {
                                        columns?.map((column, index) => {
                                            if (column.visible && (row.type !== 'CHARACTERISTIC' || (row.type === 'CHARACTERISTIC' && row.parentId !== null))) {
                                                return (
                                                    <div
                                                        id={`${row.segmentId}-${column.type}-${column.id}`}
                                                        key={`${row.segmentId}-${column.type}-${column.id}`}
                                                        className={'cellrowheatmap'}
                                                        style={index > 0 ? {marginLeft: '4px'} : {}}>
                                                        {buildCell(column, row, keyDate)}
                                                    </div>
                                                );
                                            }
                                        })
                                    }
                                </HeatmapRow>
                            ))}

                        </div>
                    </div>
                </div>

            </HappyLoading>


        </section>
    );
};

export default HFHeatmap;
