'use strict';

import Dates from "../../../utilities/date.utilities";
import Numbers from "../../../utilities/number.utilities";
import hfEngagementEnpsBenchmark from "./components/enps_benchmark/enps_bechmark.component";
import hfEngagementEnpsDetailEvolution from "./components/enps_evolution_chart/enps_evolution.component";


import hfEnpsDetailOverview from "./components/overview/enps_overview.component";
import hfEngagementEnpsPositioningBox from "./components/positioning_box/enps_positioning_box.component";
import hfEngagementEnpsVotesDistribution from "./components/votes_distribution/enps_votes_distribution.component";
import hfEngagementEnpsIndividualHeatmapBox
    from "./components/enps_individual_heatmap/enps_individual_heatmap.component";
import template from './enps_detail.html';
import {getENPSStats} from "../../../shared/services/migrated/enps.service";
import {getHierarchyName} from "../../../shared/services/migrated/hierarchy.service";
import ENPSFunnelBox from "./components/enps_funnel_box/enps_funnel_box.component";
import {dateQueryParamToDate} from '../../../utilities/navigation_state.utilities';


function ENPSDetailCtrl($scope, $state, ErrorSvrc, Settings, Filters, $intercom, $stateParams) {
    'ngInject';
    var _this = this;

    _this.selectedENPSMode = 'ENPS_MODE';

    _this.grouping = 'QUARTER';

    _this.summaryView = 1;



    _this.to = $stateParams.to ? dateQueryParamToDate($stateParams.to) : Filters.endDate();

    _this.from = moment(_this.to).subtract(4, 'Q').toDate();


    _this.level = Filters.level() || null;
    _this.filters = Filters.segments() || null;
    _this.groups = Filters.groups() || null;

    if ($stateParams.hierarchyId) {
        _this.level = {
            id: $stateParams.hierarchyId
        };

        // TODO: We have to do this, because the hierarchies filter doesn't display the name properly...
        getHierarchyName(
            $stateParams.hierarchyId,
            function (err, hierarchyName) {
                if (!err) {
                    _this.level.name = hierarchyName;
                }
            }
        );
    }

    if ($stateParams.filters) {
        _this.filters = $stateParams.filters;
    }

    if ($stateParams.groupId) {
        _this.groups = [$stateParams.groupId];
    }


    _this.arrow = {
        action: function () {
            $state.go('engagement.screen');
        }
    };

    _this.loading = true;

    _this.selectMode = function (mode) {
        _this.selectedENPSMode =  mode;
        $scope.$digest();
    }

    function loadENPS() {

        _this.loading = true;
        var to = Dates.lastDayOfMonth(_this.to);
        var params = {
            to: to,
            filters: _this.filters,
            groupId: _this.groups,
            grouping: _this.grouping
        };

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        _this.hasEnoughActiveEmployees = true;

        getENPSStats(params, function (err, response) {
            if (err) {
                if (err) {
                    if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                        _this.hasEnoughActiveEmployees = false;
                    } else {
                        ErrorSvrc.showErrorModal(err);
                        console.error('ERROR getting ENPS Stats', err);
                    }
                }
            } else {
                _this.scoreId = response.id;
                var numResults = response.results.length;
                var current = response.results[numResults - 1];
                if (response.results.length > 0 && current.quality != 'NO_DATA') {
                    var value = current.result;
                    var variation = current.variation;
                    var averageValue = current.averageResult;
                    var averageVariation = current.averageVariation;

                    _this.filtered = response.filtered;
                    _this.stats = {
                        quality: current.quality,
                        valuation: current.valuation,
                        value: Numbers.roundNumber(value, 1),
                        averageValue: Numbers.roundNumber(averageValue, 1),
                        variation: Numbers.roundNumber(variation, 1),
                        averageVariation: Numbers.roundNumber(averageVariation, 1),
                        measuringNow: current.measuringNow,
                        measureDate: current.from,
                        companyBenchmark: current.companyBenchmark,
                        companyAverageBenchmark: current.companyAverageBenchmark,
                        industryBenchmark: current.industryBenchmark,
                        industryAverageBenchmark: current.industryAverageBenchmark,
                        globalBenchmark: current.globalBenchmark,
                        globalAverageBenchmark: current.globalAverageBenchmark,

                        ranking: current.ranking
                    };

                    if (numResults > 1) {
                        var previousValue = response.results[numResults - 2].result;
                        _this.stats.previousValue = Numbers.roundNumber(previousValue, _this.numDecimals);
                    }
                    _this.results = response.results;
                    _this.measuringNow = current.measuringNow;
                    _this.noData = false;
                    _this.measureFromDate = Dates.fromAPIFormat(current.from, Settings.apiDateFormat);
                    _this.measureToDate = Dates.fromAPIFormat(current.to, Settings.apiDateFormat);
                    _this.currentClassification = current.classification;
                    var totalClassified = 0;
                    Object.keys(current.classification).forEach(function (classification) {
                        totalClassified += current.classification[classification];
                    });
                    _this.stats.totalClassified = totalClassified;
                } else {
                    _this.measuringNow = false;
                    delete _this.stats;
                    _this.noData = true;
                    _this.measureFromDate = Dates.firstDayOfMonth(_this.from);
                    _this.measureToDate = Dates.firstDayOfMonth(_this.to);
                }

            }
            _this.loading = false;
        });
    };

    _this.navigateToDetail = function (route, params) {
        $state.go(route, params);
    };




    this.$onInit = function () {




        ['to', 'level', 'filters', 'groups', 'grouping'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                loadENPS();
            }, true);
        });


        loadENPS();

        $intercom.trackEvent('dashboard_visited_enps_detail');
    };

}



function ENPSDetailView($stateProvider) {
    'ngInject';
    $stateProvider.state('engagement.enpsDetail', {
        url: '/detail/enps?hierarchyId&filters&groupId&to',
        template: template,
        controllerAs: '$ctrl',
        controller: ENPSDetailCtrl,
        data: {
            pageTitle: 'ENGAGEMENT_ENPS_DETAIL',
            roles: ['MANAGER', 'ADMIN', 'ADVISOR']
        }
    });
}

export default ENPSDetailView;
