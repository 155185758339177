import useHierarchyName from '../../hooks/useHierarchyName';


const HierarchyName = ({hierarchyId}: {hierarchyId: string}) => {
    const {hierarchyName} = useHierarchyName(hierarchyId);
    return (
        <span>{hierarchyName}</span>
    );
}

export default HierarchyName;
