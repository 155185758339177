'use strict';

const hfWizardStep = angular.module('happyForceApp')
    .directive('hfWizardStep', function () {
        return {
            scope: {
                options: '='
            },
            transclude: true,
            controllerAs: '$step',
            require: '^hfWizard',
            template: function (element, attributes) {
                return attributes.template || require('./step.html');
            },

            link: function ($scope, $element, $attrs, hfWizard) {
                $scope.hidden = false;

                $attrs.$observe('hfTitle', function (value) {
                    $scope.title = value;
                    hfWizard.addStep($scope);
                });

                $attrs.$observe('hfHide', function (value) {
                    $scope.hidden = (value == 'true');
                });

                $scope.$on('$destroy', function () {
                    hfWizard.removeStep($scope);
                });

            }
        };
    });

export default hfWizardStep;
