'use strict';

function SettingsAPIIntegrationCtrl($scope, AuthSrvc, CompanySvrc, $translate, $window, ErrorSvrc, Languages, Analytics) {
    'ngInject'

    var _this = this;

    $scope.generateApiKey = function () {
        $scope.companyConfig.apiKey = guid();
    };

    function guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    Analytics.trackEvent('settings_api_integration');
}

const hfApiIntegrationSettings = angular.module('happyForceApp')
    .directive('hfApiIntegrationSettings', function () {
        return {
            template: require('./settings_api_integration.html'),
            controller: SettingsAPIIntegrationCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });