const CronService =  function ($translate) {
    'ngInject';
    var days = {
        1: $translate.instant('SUNDAY'),
        2: $translate.instant('MONDAY'),
        3: $translate.instant('TUESDAY'),
        4: $translate.instant('WEDNESDAY'),
        5: $translate.instant('THURSDAY'),
        6: $translate.instant('FRIDAY'),
        7: $translate.instant('SATURDAY')
    };

    var months = {
        1: $translate.instant('JANUARY'),
        2: $translate.instant('FEBRUARY'),
        3: $translate.instant('MARCH'),
        4: $translate.instant('APRIL'),
        5: $translate.instant('MAY'),
        6: $translate.instant('JUNE'),
        7: $translate.instant('JULY'),
        8: $translate.instant('AUGUST'),
        9: $translate.instant('SEPTEMBER'),
        10: $translate.instant('OCTOBER'),
        11: $translate.instant('NOVEMBER'),
        12: $translate.instant('DECEMBER')
    };

    var methods = {};

    methods.createResumen = function (freq, duration) {
        var resumen;
        if (freq.base === 3) resumen = $translate.instant('EVERY_DAY');
        if (freq.base === 4) {
            var weekDays = [];
            var dayValues =  (freq.dayValues && freq.dayValues.length) ? freq.dayValues.sort() : [freq.dayValues];
            dayValues.forEach(function (day, index, array) {
                weekDays.push(days[day]);
            });

            // jscs:disable maximumLineLength
            // 'Los {{weekdays}} de cada semana durante {{duration}} dias.'
            resumen = $translate.instant('EVERY_WEEK_RESUMEN', { weekdays: weekDays.length ? weekDays.join(',') : '', duration: duration });

            // jscs:enable maximumLineLength
        }

        if (freq.base === 5 || freq.base === 6) {
            var dayofMonth = freq.dayOfMonthValues && freq.dayOfMonthValues[0] || moment().format('D');

            if (freq.base === 6) {
                var monthsStr = '';
                if (freq.monthValues instanceof Array) {
                    freq.monthValues.forEach(function (m, idx, array) {
                        var comma = (idx !== array.length - 1) ? ', ' : '';
                        monthsStr += months[m] + comma;
                    });
                } else {
                    monthsStr = months[freq.monthValues];
                }

                // jscs:disable maximumLineLength
                // 'El {{monthday}} de {{month}} de cada año.'
                resumen = $translate.instant('EVERY_YEAR_RESUMEN', { monthday: dayofMonth, month: monthsStr, duration: duration });

                // jscs:enable maximumLineLength
            } else {

                // jscs:disable maximumLineLength
                // 'El {{monthday}} de cada mes durante {{duration}} dias.'
                resumen = $translate.instant('EVERY_MONTH_RESUMEN', { monthday: dayofMonth, duration: duration });

                // jscs:enable maximumLineLength
            }

        }

        return resumen;
    };

    /*
    Cada día:       (0 0 0 * * ?)
    Cada semana:    (0 0 0 ? * 2)
    Cada mes:       (0 0 0 6 * ?)
    Cada año:       (0 0 0 6 4 ?)
     */
    methods.fromExpression = function (expression, duration) {
        var splitted = expression.split(' ');
        if (!splitted) return;

        var freq = {};
        if (splitted[3] === '*') freq.base = 3;
        else if (splitted[3] === '?') {
            freq.base = 4;
            freq.dayValues = splitted[5].split(',');
        } else {
            freq.dayOfMonthValues = [splitted[3]];
            if (splitted[4] === '*') {
                freq.base = 5;
            } else {
                freq.base = 6;
                if (splitted[4].indexOf(',') > -1) {
                    splitted[4] = splitted[4].split(',');
                }

                freq.monthValues = splitted[4];
            }
        }

        return methods.createResumen(freq, duration);

    };

    return methods;

};

export default CronService;

