const LaunchService = function (Restangular, People) {
    'ngInject';
    var methods = {};
    var stepUtils = {};

    var Company = Restangular.one('company');

    methods.statuses = {
        COMPLETED: 'COMPLETED',
        IGNORE: 'IGNORE',
        INCOMPLETE: 'INCOMPLETE'
    };

    methods.GET = function (params, callback) {
        Company.one('user').one('onboard').get(params).then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    methods.PUT = function (stepId, status, callback) {
        Company.one('user').one('onboard').one(stepId).one(status).put({}).then(function (results) {
            callback(null, results);
        }, callback);
    };

    methods.DELETE = function (callback) {
        Company.one('user').one('onboard').delete().then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    methods.getSteps = function (user, callback) {
        return methods.GET({}, callback);
    };


    methods.getUserInfo = function (user, callback) {
        return People.getById(user.id, callback);
    };

    methods.resendInvitation = function (email, callback) {
        Restangular.one('employee').one('resend').customPOST({ email: email }, email).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    // StepUtils
    stepUtils.setStatus = function (ev, stepId, status, callback) {
        if (ev) ev.stopPropagation();

        /* Intercom('trackEvent', stepId, {
             status: status
         });*/

        methods.PUT(stepId, status, function (err, data) {
            callback && callback(err, data);
        });
    };


    stepUtils.startTour = function (tourId) {
        Intercom('startTour', tourId);
    };

    methods.utils = stepUtils;
    return methods;
};

export default LaunchService;
