'use strict';

function AnnouncementsCategoryConfigOperationsCtrl($scope, ErrorSvrc, $modalInstance, category, isEdit, isDelete, Analytics, Announcements) {
    'ngInject'

    $scope.category = category;
    $scope.isEdit = isEdit;
    $scope.isDelete = isDelete;
    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';


    $scope.ok = function () {
        $scope.executing = true;

        if ($scope.isDelete) {
            Announcements.deleteCategory($scope.category.id, function (err, result) {
                if (err) {
                    Analytics.trackEvent('settings_communicate_announcements_category', 'delete', 'fail');
                    return $scope.handleErrorResponse(err);
                } else {
                    Analytics.trackEvent('settings_communicate_announcements_category', 'delete', 'success');
                    $modalInstance.close(result);
                }
            });
        } else if ($scope.isEdit) {
            $scope.category.color = $scope.category.color || '#EBF9F5';
            Announcements.updateCategory($scope.category.id, $scope.category, function (err, result) {
                if (err) {
                    Analytics.trackEvent('settings_communicate_announcements_category', 'update', 'fail');
                    return $scope.handleErrorResponse(err);
                } else {
                    Analytics.trackEvent('settings_communicate_announcements_category', 'update', 'success');
                    $modalInstance.close(result);
                }
            });
        } else {
            $scope.category.color = $scope.category.color || '#EBF9F5';
            Announcements.addCategory($scope.category, function (err, result) {
                if (err) {
                    Analytics.trackEvent('settings_communicate_announcements_category', 'add', 'fail');
                    return $scope.handleErrorResponse(err);
                } else {
                    Analytics.trackEvent('settings_communicate_announcements_category', 'add', 'success');
                    $modalInstance.close(result);
                }
            });
        }
    };

    $scope.delete = function () {
        $modalInstance.dismiss('delete');
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.handleErrorResponse = function (response) {
        $scope.executing = false;
        $scope.errored = true;
        $scope.errorMsg = ErrorSvrc.getErrorMessage(response);
    };
}

const AnnouncementCategoryEditModal = angular.module('happyForceApp')
    .factory('AnnouncementCategoryEditModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showModal = function (category, callback) {
                var isEdit = true;
                if (!category) {
                    // We are a new one
                    category = {
                        name: ''
                    };
                    isEdit = false;
                } else {
                    category = angular.copy(category);
                }

                $uibModal.open({
                    backdrop: 'static',
                    template: require('./add_category.html'),
                    controller: AnnouncementsCategoryConfigOperationsCtrl,
                    resolve: {
                        category: function () {
                            return category;
                        },

                        isEdit: isEdit,
                        isDelete: false
                    },
                    size: ' hi_question'
                }).result.then(function (result) {
                    if (isEdit) {
                        callback(result, 'update');
                    } else {
                        callback(result, 'add');
                    }
                }, function (closeAction) {
                    if (closeAction === 'delete') {
                        //TODO: fix this
                        $uibModal.open({
                            backdrop: 'static',
                            template: require('./delete.html'),
                            controller: AnnouncementsCategoryConfigOperationsCtrl,
                            resolve: {
                                isDelete: true,
                                isEdit: false,
                                category: function () {
                                    return category;
                                }
                            }
                        }).result.then(function (deletedCategory) {
                            // Updating the list and removing the public after the response is OK.
                            callback(deletedCategory, 'delete');

                        }, function () {
                        });
                    }
                });
            };

            return methods;
        }
    );

export default AnnouncementCategoryEditModal;
