import { useEffect, useState } from 'react';
import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, ListItemText, Box, Typography } from '@mui/material';

interface OptionNestedMenuItemProps {
    filters?: any;
    setFilters?: (filters: any) => void;
    open?: boolean;
    mode?: string;
    title?: string;
    options?: any;
    nonRemovable: boolean;
}

const OptionNestedMenuItem = ({ filters, setFilters, open, mode, title, options, nonRemovable }: OptionNestedMenuItemProps) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    //Used to load selected groups from filters
    useEffect(() => {
        const initialSelectedOptions = [];
        if (filters && filters.length > 0) {
            filters.forEach((filter) => {
                initialSelectedOptions.push(filter);
            });
            setSelectedOptions(initialSelectedOptions);
        }
    }, [filters]);

    //handle with remove/add
    const handleFilter = (item) => {
        item.name = item.title
        item.option = title
        item.nonRemovable = nonRemovable

        if (mode === 'multi') {
            if (filters && filters.findIndex(e => e.id === item.id) > -1) {
                setFilters(filters => filters.filter(filterItem => filterItem.id !== item.id));
            } else {
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else if (mode === 'single') { //DONE
            //Check if exists one of the type
            if (filters.findIndex(e => e.type === item.type) > -1) {
                //If nonRemovable, check if do nothing or replace
                if (nonRemovable) {
                    //If id is the same
                    if (filters && filters.findIndex(e => e.id === item.id) > -1
                        && filters.findIndex(e => e.id === item.id) === filters.findIndex(e => e.type === item.type)) { //Do nothing
                        return;
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                } else {
                    if (filters && filters.findIndex(e => e.id === item.id) > -1) { //Same id, remove it
                        setFilters(filters => filters.filter(filterItem => filterItem.type !== item.type));
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                }
            } else { //No same type, add it
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else {
            setFilters(optionFilters => [...optionFilters, item]);
        }

    }

    const handleCheckboxChange = (item) => {
        handleFilter(item);
        setSelectedOptions((prevSelected) => {
            const itemExists = prevSelected.some(e => e.id === item.id);

            if (itemExists) {
                return prevSelected.filter(e => e.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const handleRadioChange = (item) => {
        handleFilter(item);

        const itemExists = selectedOptions.some(e => e.id === item.id)
            && selectedOptions.findIndex(e => e.id === item.id) === selectedOptions.findIndex(e => e.type === item.type);
        if (itemExists && nonRemovable && selectedOptions.length <= 1) {
            return;
        } else {
            setSelectedOptions(() => {
                if (itemExists) {
                    if (!nonRemovable) {
                        return [];
                    } else {
                        return [item];
                    }
                } else {
                    return [item];
                }
            });
        }

    };

    const renderOptions = (options) => {
        if (!options) {
            return null;
        }

        if (mode === 'multi') {
            return options.map((item, index) => {
                const isChecked = selectedOptions.findIndex(e => e.id === item.id) > -1;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleCheckboxChange(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isChecked} />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderOptions(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleCheckboxChange(item)}>
                            <Checkbox
                                checked={isChecked} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.title}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return options.map((item, index) => {
                const isSelected = selectedOptions.findIndex(e => e.id === item.id) > -1 &&
                    selectedOptions.findIndex(e => e.id === item.id) === selectedOptions.findIndex(e => e.type === item.type);
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleRadioChange(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Radio
                                        checked={isSelected}
                                    />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderOptions(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            sx={{ paddingLeft: 1 }}
                            key={index}
                            onClick={() => handleRadioChange(item)}
                        >
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.title}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            //TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderOptions(options)}
        </NestedMenuItem>
    );
};

export default OptionNestedMenuItem;