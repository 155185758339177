function CommentRepliesModalCtrl(CompanySvrc, Comments, $translate, comment, ErrorSvrc, $modalInstance) {
    'ngInject';
    var _this = this;

    _this.loading = false;
    _this.posting = false;
    _this.reply = false;
    _this.signReply = true;


    _this.comment = comment;

    _this.predefinedReplies = [
        {
            code: 1,
            label: $translate.instant('MANAGER_REPLY_PREDEFINED_TEXT_WORKING_ON_IT'),
            value: 1
        },
        {
            code: 2,
            label: $translate.instant('MANAGER_REPLY_PREDEFINED_TEXT_THANKS_FOR_SHARING'),
            value: 2
        },
        {
            code: 3,
            label: $translate.instant('MANAGER_REPLY_PREDEFINED_TEXT_GREAT_IDEA'),
            value: 3
        }
    ];

    CompanySvrc.getCurrentCompanyConfig(function (err, companyConfig) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            if (companyConfig.commentsReplyMode === 'NO_REPLY')
                _this.hideReply = true;
        }
    });

    _this.showDate = function (actual, prev) {
        var mActual = moment(actual).format('DDMMYYYY');
        var mPrev = moment(prev).format('DDMMYYYY');
        return (mPrev !== mActual);
    };

    _this.sendReply = function () {

        _this.posting = true;
        Comments.sendReply(_this.comment.id, _this.replyMessage, _this.signReply, function (err, comment) {
            _this.posting = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.signReply = false;
                _this.comment = comment;
                _this.replyMessage = '';
            }
        });
    };


    _this.close = function () {
        $modalInstance.dismiss();
    };


}

const CommentRepliesModal= angular.module('happyForceApp')
    .factory('CommentRepliesModal',
        function ($uibModal, Comments, $intercom) {
            var methods = {};

            methods.showModal = function (comment, callback) {
                $intercom.update({ hide_default_launcher: true });

                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);


                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'right',
                    template: require('./comment-replies-modal.html'),
                    controller: CommentRepliesModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        comment: comment
                    }
                }).result.then(function (params) {
                    $intercom.update({ hide_default_launcher: false });
                    if (callback) {
                        Comments.getOne(comment.id, function (err, updatedComment) {
                            callback(err, updatedComment);
                        });
                    }
                }, function (params) {
                    // If there is a callback, reload allays the comment, so we have a fresh version of it
                    $intercom.update({ hide_default_launcher: false });
                    if (callback) {
                        Comments.getOne(comment.id, function (err, updatedComment) {
                            callback(err, updatedComment);
                        });
                    }
                });
            };

            return methods;

        }
    );

export default CommentRepliesModal;
