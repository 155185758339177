'use strict';

function AnnouncementMoveToSectionController(Announcements, announcement, toaster, $modalInstance, $translate, ErrorSvrc) {
    'ngInject'
    var _this = this;

    _this.selectedSectionId = announcement.sectionId;
    _this.currentSectionId = announcement.sectionId;

    Announcements.getSections({}, function (error, response) {
        _this.loadingSections = false;
        if (error) {
            ErrorSvrc.showErrorModal(error);
            return;
        }

        _this.sections = [];
        response.forEach(function (section) {
            if (section.publishingCapable) {
                _this.sections.push(section);
            }
        });

    });

    _this.ok = function () {
        Announcements.updateSection(announcement.id, _this.selectedSectionId, function (err, announcement) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            } else {
                toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_UPDATED')));
                $modalInstance.close(announcement);
            }
        });
    };

    _this.cancel = function () {
        $modalInstance.dismiss();
    };
};


const AnnouncementMoveToSectionModal = angular.module('happyForceApp')
    .factory('AnnouncementMoveToSectionModal',
        function ($uibModal, Announcements, ErrorSvrc) {
            var methods = {};

            methods.showSectionSelector = function (announcementId, callback) {

                Announcements.getOne(announcementId, function (err, announcement) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                    } else {
                        setTimeout(function () {
                            $(window).trigger('resize');
                        }, 500);

                        $uibModal.open({
                            backdrop: false,
                            animation: true,
                            size: 'md',
                            template: require('./announcement-move-to-section-selector.html'),
                            controller: AnnouncementMoveToSectionController,
                            controllerAs: '$ctrl',
                            resolve: {
                                announcement: announcement
                            }
                        }).result.then(function (announcement) {
                            if (callback) {
                                callback(null, announcement);
                            }
                        }, function (params) {
                        });
                    }
                });
            };


            return methods;

        }
    );

export default AnnouncementMoveToSectionModal;


