'use strict';

import UserUtils from "../../../../utilities/user.utilities";
import LoggedUserProfileCtrl from "./modal/user_profile.controller";


var ProfileCtrl = function (AuthSrvc, $location, ErrorSvrc, $uibModal, $window) {
    'ngInject'
    var _this = this;

    this.user = AuthSrvc.user();

    this.getUserImage = AuthSrvc.getUserImage;

    this.hasUserImage = AuthSrvc.hasUserImage;

    this.shortName = UserUtils.getUserNameAcronym(this.user.name, 'HF', 2);

    this.isLoggedIn = function () {
        return AuthSrvc.isPublic($location.path()) == false && AuthSrvc.isLoggedIn();
    };

    this.logout = function () {
        localStorage.removeItem('HappyForce-happyForceUser');
        AuthSrvc.logout(
            function (res) {
                $location.path('/onboarding');
                setTimeout(function () {
                    // This prevents displaying a ROLE_NOT_ALLOWED error in Safari
                    // Reload was called before the transition to onboarding is complete
                    $window.location.reload();
                }, 0);
            },

            function (err) {
                $location.path('/onboarding');
                ErrorSvrc.showErrorModal('Failed to logout');

            }
        );
    };

    this.editUserProfile = function () {
        var modalInstance = $uibModal.open({
            backdrop: 'static',
            template: require('./modal/user_profile.html'),
            controller: 'LoggedUserProfileCtrl',
            size: ' profile',
            resolve: {
                selectedUser: function () {
                    return AuthSrvc.user();
                }
            },
            animation: true
        });

        modalInstance.result.then(function () {
            _this.user = AuthSrvc.user();
        }, function () {
        });
    };


};

//Directive used to set metisMenu and minimalize button
const happyProfile = angular.module('happyForceApp')
    .directive('happyProfile', function () {
        return {
            restrict: 'E',
            template: require('./profile.html'),
            controller: ProfileCtrl,
            controllerAs: 'pr',
            bindToController: true
        };
    });


export default happyProfile;
