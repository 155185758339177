'use strict';

function SilosModalCtrl($scope, $uibModalInstance, AuthSrvc, Silos, silo, isAdd,  $translate, ErrorSvrc, Analytics) {
    'ngInject';
    var _this = this;

    _this.cancel = function () {
        $uibModalInstance.dismiss('close');
    };

    _this.silo = silo;
    if (!isAdd && silo) {
        _this.level = {
            id: silo.startHierarchyId
        };
    } else {
        _this.level = null;
    }
    _this.isAdd = isAdd;

    _this.submit = function () {
        _this.silo.startHierarchyId = _this.level && _this.level.id;

        if (isAdd) {
            Silos.add(_this.silo, function (err, siloCreated) {

                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    $uibModalInstance.close(siloCreated);

                }

            });
        } else {
            Silos.update(_this.silo.id, _this.silo, function (err, siloUpdated) {

                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    $uibModalInstance.close(siloUpdated);
                }

            });
        }

    };

    _this.deleteSilo = function () {
        Silos.remove(_this.silo.id, function (err, deletedSilo) {
            if (err) {
                swal($translate.instant('SILO_REMOVED_FAIL'), $translate.instant(err), 'error');
                Analytics.trackEvent('settings_company_silos', 'delete_silo', 'fail');
            } else {
                $uibModalInstance.close();
            }

        });
    };


}



const SilosEditorModal = angular.module('happyForceApp')
    .factory('SilosEditorModal',
        function ($uibModal) {
            var methods = {};
            methods.showAdd = function (callback) {
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./edit.html'),
                    controller: SilosModalCtrl,
                    controllerAs: 'slc',
                    resolve: {
                        silo: {},
                        isAdd: true
                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            methods.showEdit = function (silo, callback) {
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./edit.html'),
                    controller: SilosModalCtrl,
                    controllerAs: 'slc',
                    resolve: {
                        silo: silo,
                        isAdd: false
                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };


            return methods;

        }
    );
export default SilosEditorModal;

