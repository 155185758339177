'use strict';

import HRISBambooModal from './modals/hrsi_bamboo.controller';
import HRISPersonioModal from './modals/hris_personio.controller';
import HRISWoltersKluwerModal from './modals/hris_wolterskluwer.controller';

function SettingsHRSICtrl($translate, HRISBambooModal, HRISPersonioModal, HRISWoltersKluwerModal, HRSIService, ErrorSvrc, $intercom, $uibModal) {
    'ngInject';

    var _this = this;

    _this.types = HRSIService.types;
    _this.currentType = null;
    _this.loading = false;

    _this.loadCurrentConfiguration = function () {
        _this.loading = true;
        HRSIService.get(function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                if (result != null) {
                    _this.hasConfiguration = true;
                    if (_this.hasConfiguration) {
                        _this.configuration = result;
                        _this.currentType = _.find(_this.types, function (type) { return type.type == _this.configuration.type; });
                    }
                }                

            }
            _this.loading = false;
        });
    };


    _this.initConnection = function (type) {
        if (type === 'BAMBOOHR') {
            HRISBambooModal.showModal(function () {
                swal($translate.instant('HRSI_CONNECTION_SET'), '', 'success');
                _this.loadCurrentConfiguration();
            });
        } else if (type === 'PERSONIO') {
            HRISPersonioModal.showModal(function () {
                swal($translate.instant('HRSI_CONNECTION_SET'), '', 'success');
                _this.loadCurrentConfiguration();
            });
        } else if (type === 'WOLTERSKLUWER') {
            HRISWoltersKluwerModal.showModal(function () {
                swal($translate.instant('HRSI_CONNECTION_SET'), '', 'success');
                _this.loadCurrentConfiguration();
            });
        }
    };

    _this.disconnect = function () {
        if (_this.hasConfiguration) {
            HRSIService.disconnect(function (err) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    swal($translate.instant('HRSI_CONNECTION_DISCONNECTED'), '', 'success');
                    _this.loadCurrentConfiguration();
                }
            });
        }
    };


    /*_this.initConnection = function () {

        CompanySvrc.getMergeDevToken(function (err, result) {
            if (err) {
                console.log(err);
            } else {
                MergeLink.initialize({
                    linkToken: result.linkToken, // Replace ADD_GENERATED_LINK_TOKEN with the token retrieved from your backend (Step 1)
                    onSuccess: function (publicToken) {
                        // Save the token to the server and open it:
                        console.log(publicToken);
                        CompanySvrc.saveMergeDevToken(publicToken, function (err, result) {
                            if (err)
                                console.log(err);
                        });


                    },
                    onReady: function () {
                        console.log('Is ready');
                        MergeLink.openLink();

                        // TODO: Disable button
                    }
                });
            }
        });

    };*/

    _this.loadCurrentConfiguration();


    $intercom.trackEvent('dashboard_visited_hrsi_settings');
};

const hfHrsiIntegrationSettings = angular.module('happyForceApp')
    .directive('hfHrsiIntegrationSettings', function () {
        return {
            template: require('./settings_hri_integration.html'),
            controller: SettingsHRSICtrl,
            controllerAs: '$hrsi',
            bindToController: true
        };
    });

export default hfHrsiIntegrationSettings;