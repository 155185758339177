var StringUtils = {};

StringUtils.validateEmail = function (email) {
    email = email.replace(/^\s+|\s+$/g, '');

    var re = /^\S+@\S+$/;
    return re.test(String(email).toLowerCase());
};

export default StringUtils;
