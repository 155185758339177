import {useEffect, useMemo, useState} from 'react';
import {getService} from '../../../migration_utils/react-in-angular';
import moment from 'moment/moment';
import Dates from '../../utilities/date.utilities';
import {APISettings} from '../services/migrated/axiosWrapper';



interface CharacteristicValue {
    characteristicId: string;
    value?: string;
    date?: string;
}

interface CharacteristicValueName {
    description: string;
    valueLabel: string;
}


const buildValueLabel = async (characteristicId: string, value: string, date: string) => {
    const Languages = getService('Languages');
    const Characteristics = getService('Characteristics');
    const characteristic = await Characteristics.getCharacteristicById(characteristicId);


    const valueLabel: CharacteristicValueName = {
        description: characteristic?.description,
        valueLabel: ''
    };

    if (characteristic?.questionType === 'LIST') {
        const valueItem = await Characteristics.getCharacteristicValueName(characteristicId, value);
        valueLabel.valueLabel = Languages.getTranslationFromUserLanguage(valueItem);
    } else if (characteristic?.questionType === 'DATE') {
        valueLabel.valueLabel = moment(Dates.fromAPIFormat(date, APISettings.apiDateFormat)).format('DD-MMM-YYYY');
    }
    return valueLabel;
};

const useCharacteristicName = ({characteristicId, value, date}:CharacteristicValue): CharacteristicValueName => {


    const [characteristicValueName, setCharacteristicValueName] = useState<CharacteristicValueName | undefined>(undefined);


    useEffect(() => {
        const fetchCharacteristicName = async () => {
            if (characteristicId) {
                return buildValueLabel(characteristicId, value, date)
            }
        }

        fetchCharacteristicName()

    }, [characteristicId, value, date]);


    const memoizedCharacteristicName = useMemo(() => characteristicValueName, [characteristicValueName]);

    return memoizedCharacteristicName;
}



const useCharacteristicNames = (characteristicPairs: CharacteristicValue[] | undefined): CharacteristicValueName[] => {
    const [characteristicValueNames, setCharacteristicValueNames] = useState<CharacteristicValueName[]>([]);


    useEffect(() => {
        const fetchCharacteristicNames = async () => {
            if (characteristicPairs && characteristicPairs.length > 0) {
                const promises = characteristicPairs.map(async ({ characteristicId, value, date }) => {
                    return await buildValueLabel(characteristicId, value, date);
                });

                const results = await Promise.all(promises);

                setCharacteristicValueNames(results);
            }
        };

        fetchCharacteristicNames();
    }, [characteristicPairs]);

    const memoizedCharacteristicNames = useMemo(() => characteristicValueNames, [characteristicValueNames]);

    return memoizedCharacteristicNames;
}


export { useCharacteristicName, useCharacteristicNames, CharacteristicValueName };
