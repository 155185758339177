'use strict';

function SettingsEngagementMailsCtrl(Analytics) {
    'ngInject'

    var _this = this;

    Analytics.trackEvent('settings_engagement_mails');
}

// Takes care about login & activation

const hfSettingsEngagementMails = angular.module('happyForceApp')
    .directive('hfSettingsEngagementMails', function () {
        return {
            template: require('./settings_engagement_mails.html'),
            controller: SettingsEngagementMailsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSettingsEngagementMails;