const ngOrgchart = angular.module('happyForceApp').directive('ngOrgchart', function ($translate, CompanySvrc) {

    return {
        scope: {
            selectedItem: '=',
            orgdata: '='
        },
        restrict: 'E',
        template: '<div id="orgChart"></div>',
        link: function ($scope, elem, $attrs) {
            var _this = this;

            function configureDirective() {
                $scope.$watch($attrs.orgdata, function (value) {
                    draw(value);
                }, true); // true is for deep object equality checking

                function draw(orgdata) {

                    $scope.orgDataTable = new google.visualization.DataTable();
                    $scope.orgDataTable.addColumn('string', 'Name');
                    $scope.orgDataTable.addColumn('string', 'Manager');
                    $scope.orgDataTable.addColumn('string', 'UniqueId');

                    $scope.orgDataTable.addRows([
                     [{ v: 'root', f: '<i class="fa fa-building-o fa-2x"></i>' }, '', 'root']
                    ]);

                    formatLevels(orgdata, $scope.orgDataTable, { id: 'root', name: 'raiz' });

                    $scope.chart = new google.visualization.OrgChart(document.getElementById('orgChart'));
                    $scope.chart.draw($scope.orgDataTable, { allowHtml: true });
                    google.visualization.events.addListener($scope.chart, 'select', selectHandler);
                }
            }

            function selectHandler() {
                var item = $scope.chart.getSelection()[0];
                if (typeof item != 'undefined') {
                    // Esto nos saca el 3er valor del array... manda cojones!
                    var selectedId =  $scope.orgDataTable.getValue(item.row, 2);
                    var result = setSelectedItem(selectedId);
                    $scope.selectedItem = result;
                    $scope.$apply();
                } else {
                    $scope.selectedItem = { id: 'root', name: 'raiz' };
                    $scope.$apply();
                }

            }

            function formatLevels(orgdata, dataTable, parentItem) {
                for (var i = 0; i < orgdata.length; i++) {
                    var item = orgdata[i];
                    var badgeClazz = 'badge-primary';
                    var explanationReason = '';
                    var badgeText = '';

                    var formatedItem = '<div class="small-style font-bold">' + item.name + '</div><hr>';

                    if (_this.minActiveEmployees && _this.minActiveEmployees > item.activeEmployees) {
                        badgeClazz = 'badge-danger';
                        explanationReason = $translate.instant('ORG_TREE_MIN_NOT_REACHED', { minEmployees: _this.minActiveEmployees });

                    } else if (!item.children && item.expectedEmployees != 0 && item.activeEmployees > item.expectedEmployees) {
                        badgeClazz = 'badge-danger';
                        explanationReason = $translate.instant('ORG_TREE_MORE_THAN_EXPECTED', { expectedEmployees: item.expectedEmployees });

                    }

                    if (item.expectedEmployees != 0) {
                        badgeText = item.activeEmployees + '/' + item.expectedEmployees;
                    } else {
                        badgeText = item.activeEmployees;
                    }

                    formatedItem = formatedItem + '<span class="badge ' + badgeClazz + '">' + badgeText + '</span>';
                    if (explanationReason != '')
                     formatedItem = formatedItem + '<div class="mini-style text-danger">' +  explanationReason  + '</div>';

                    if (parentItem === null) {
                        dataTable.addRows([
                            [{ v: item.id, f: formatedItem }, '', item.id]
                        ]);
                    } else {
                        dataTable.addRows([
                            [{ v: item.id, f: formatedItem }, parentItem.id, item.id]
                        ]);
                    }

                    if (typeof item.children != 'undefined' && item.children != null) {
                        formatLevels(item.children, dataTable, item);
                    }
                }
            }

            function composeChartId(item, parentItem)
            {
                if (parentItem === null) {
                    return item.id;
                } else {
                    return parentItem.id + '-' + item.id;
                }
            }

            function setSelectedItem(selectedId)
            {
                if (selectedId === 'root') {
                    return { id: 'root', name: 'raiz' };;
                } else {
                    var findRecursive = function (selectedId, data) {
                        for (var i = 0; i < data.length; i++)
                        {
                            if (data[i].id == selectedId) {
                                return data[i];
                            } else if (data[i].children) {
                                var result =  findRecursive(selectedId, data[i].children);
                                if (typeof result != 'undefined')
                                    return result;
                            }

                        }
                    };

                    return findRecursive(selectedId, $scope.orgdata);
                }
            }

            CompanySvrc.getConfigKey('minActiveEmployees', function (err, value) {
                if (err) {
                    console.log('Cant retrieve minActiveEmployees due an error!: ' + err);
                } else {
                    _this.minActiveEmployees = value;
                }
                configureDirective();
            });
        }
    };
});

export default ngOrgchart;
