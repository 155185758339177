const HRSISettinsService = function (Restangular) {
    'ngInject';
    var HRSI = Restangular.one('company').one('hris');


    var methods = {};

    methods.types  = [
        {
            type: 'BAMBOOHR',
            name: 'BambooHR'
        },
        {
            type: 'PERSONIO',
            name: 'Personio'
        },
        {
            type: 'WOLTERSKLUWER',
            name: 'Wolters Kluwer'
        }
    ];

    methods.connect = function (params, callback) {
        HRSI.customPOST(params).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.disconnect = function (callback) {
        HRSI.remove().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.get = function (callback) {
        HRSI.get().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    return methods;
};

export default HRSISettinsService;
