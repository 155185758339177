
import { saveAs } from 'file-saver';

const FileSaver = function () {
    'ngInject';
    var methods = {};

    methods.saveFile = function (blob, filename) {
        saveAs(blob, filename);
    };
    return methods;
};
export default FileSaver;
