'use strict';

function SettingsMeasureTransparencyConfigCtrl($scope, $translate, Analytics) {

    $scope.statsTransparency = [
        { id: 'ALL', label: $translate.instant('COMPANY_MEASURE_TRANSPARENCY_STATS_ALL') },
        { id: 'MANAGERS', label: $translate.instant('COMPANY_MEASURE_TRANSPARENCY_STATS_MANAGERS') }
    ];

    Analytics.trackEvent('settings_mesaure_transparency_config');


}

const hfMeasureTransparencyConfig = angular.module('happyForceApp')
    .directive('hfMeasureTransparencyConfig', function () {
        return {
            template: require('./settings_measure_transparency_config.html'),
            controller: SettingsMeasureTransparencyConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfMeasureTransparencyConfig;