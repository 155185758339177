'use strict';

import template from './advisor_tools_employee_locator.html';

function AdvisorsToolsEmployeeLocatorController($scope, ErrorSvrc, People, CompanySvrc, $translate) {
    'ngInject';

    var _this = this;



    _this.ready = false;
    _this.employees = [];
    _this.searchText = '';
    _this.loading = false;

    function completeEmployeeCompanyNames(person) {

        person.roles.forEach(function (role) {
            CompanySvrc.getCompany(role.companyId, function (err, result) {
                if (err) {
                    role.companyName = 'Error loading company name';
                    role.companyStatus = 'Error loading company status';
                } else {
                    role.companyName = result.name;
                    role.companyStatus = result.status;
                }
            });
        });


    };

    _this.searchEmployees = function () {
        if (_this.searchText && _this.searchText.length > 2) {

            People.advisors.locate({ text: _this.searchText }, function (err, results) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    _this.persons = results;
                    _this.persons.forEach(function (person) {
                        completeEmployeeCompanyNames(person);
                    });

                }

            });
        }
    };

    _this.resendMail = function (index, employee) {
        People.resend(employee, function (employee) {
            _this.employees[index] = employee;
            swal($translate.instant('INVITATION_RESENT'), '', 'success');
        }, function (err) {

            ErrorSvrc.showErrorModal(err);
        });
    };

    _this.resetEmployee = function (index, employee) {

        delete employee.hierarchyId;
        delete employee.characteristics;
        People.update(employee, function (err, resetEmployee) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.employees[index] = resetEmployee;
                swal($translate.instant('EMPLOYEE_RESETED'), '', 'success');
            }

        });

    };

    _this.deleteEmployee = function (index, employee, companyId) {
        employee.companyId = companyId;
        People.advisors.delete(employee, companyId, function (err, employee) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.employees[index] = employee;
                swal($translate.instant('EMPLOYEE_DELETED'), '', 'success');
            }

        });
    };


    this.$onInit = function () {

        $scope.$watch(angular.bind($scope, function () {
            return _this.searchText;
        }), function (newVal, oldVal) {
            if (newVal === oldVal) return;
            _this.searchEmployees();
        }, true);

    };

}


const hfAdvisorsToolsEmployeeLocator = angular.module('happyForceApp')
    .directive('hfAdvisorsToolsEmployeeLocator', function () {
        return {
            template: template,
            controller: AdvisorsToolsEmployeeLocatorController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAdvisorsToolsEmployeeLocator;
