    const EmployeeCharacteristicFilter = function (Characteristics) {
        'ngInject';
        return function (employeeCharacteristics, characteristicId) {
            var value;
            if (employeeCharacteristics) {
                value = _.find(employeeCharacteristics, function (characteristic) {
                    return characteristic.characteristicId == characteristicId;
                });

            }

            if (value && !value.label) {
                value.label = Characteristics.getCharacteristicValueName(characteristicId, value.value);
            }

            return value;

        };
    };
    
    export default EmployeeCharacteristicFilter;