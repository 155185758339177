
import moment from "moment-timezone";

export default function () {
    'ngInject';
    var methods = {};

    methods.storage = {};
    methods.startDate = function () {
        var startDate = moment(moment().subtract(1, 'months').utc().format('YYYY-MM-DD HH:mm:ss')).toDate();
        return startDate;
    };

    methods.endDate = function () {
        var endDate = moment(moment().utc().format('YYYY-MM-DD HH:mm:ss')).toDate();
        return endDate;
    };

    methods.level = function () {
        var level;
        return level;
    };

    methods.segments = function () {
        var segments;
        return segments;
    };

    methods.groups = function () {
        var groups;
        return groups;
    };

    methods.save = function (filters) {
    };

    methods.reset = function () {
    };

    return methods;
};
