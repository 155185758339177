'use strict';

const CircleGraphCtrl = function () {
    var _this = this;

    this.$onInit = function () {

        _this.options = {
            size: _this.size,
            barColor: function () {
                return _this.color;
            },
            trackColor: '#f3f3f3',
            scaleColor: false,
            lineWidth: 8,
            lineCap: 'circle',
            animate: {
                duration: 800,
                enabled: true
            }
        };

        _this.customStyle = {
            width: _this.size + 'px',
            height: _this.size + 'px',
            'line-height': _this.size + 'px'
        };
    };
};




const hfCircleGraph = angular.module('happyForceApp')
    .directive('hfCircleGraph', function () {
        return {
            scope: {
                percent: '=',
                size: '=',
                color: '=',
                variation: '='
            },
            template: require('./circle-graph.html'),
            controller: CircleGraphCtrl,
            controllerAs: 'cg',
            bindToController: true
        };
    });

export default hfCircleGraph;
