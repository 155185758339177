import {Person} from '../../../types';
import React from 'react';
import {Box, FormControlLabel, FormGroup, Switch, Typography} from '@mui/material';
import {getService} from 'reactInAngular';


interface UserAdvisorSettingsProps {
    person: Person;
    onRowChange: (updatedPerson: Person) => void;
}

const UserAdvisorSettings = ({person, onRowChange}: UserAdvisorSettingsProps) => {

    const $translate = getService('$translate');

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <FormGroup>
                <FormControlLabel
                    onChange={(e) => {onRowChange({...person, blocked: event.target.checked})}}
                    control={<Switch
                        checked={person.blocked} />}
                    label={<Typography variant={'caption'} fontWeight={'bold'}>
                        {$translate.instant('BLOCK_EMPLOYEE')}
                    </Typography> } />
            </FormGroup>

            <FormGroup>
                <FormControlLabel
                    onChange={(e) => {onRowChange({...person, debug: event.target.checked})}}
                    control={<Switch
                        checked={person.debug} />}
                    label={<Typography variant={'caption'} fontWeight={'bold'}>
                        {$translate.instant('DEBUG_MODE')}
                    </Typography> } />
            </FormGroup>
        </Box>
    )
}

export default UserAdvisorSettings;
