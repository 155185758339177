'use strict';


function CharacteristicEditCtrl($scope, $modalInstance, $translate, characteristic, isEdit, language, Languages) {
    'ngInject'
    var _this = this;
    _this.isEdit = isEdit;
    _this.characteristic = characteristic;

    _this.isShowSegmentType = !isEdit;
    _this.language = language;
    _this.languageTranslation = Languages.getNameByCode(language);

    _this.isEditingLanguage = (language != null);

    _this.newCharacteristicValue = {
        defaultValue: ''
    };

    _this.newCharacteristicRange = {
        from: null,
        fromType: 'YEAR',
        to: null,
        toType: 'YEAR',
        name: ''
    };

    _this.dateRangeTypes = [
        { id: 'MONTH', label: $translate.instant('MONTHS') },
        { id: 'YEAR', label: $translate.instant('YEARS') }
    ];


    _this.wizardOptions = {
        nextButton: $translate.instant('NEXT'),
        prevButton: $translate.instant('PREV'),
        confirm: {
            text: _this.isEdit ? $translate.instant('SAVE') : $translate.instant('ADD'),
            action: function () {
                if (_this.characteristicValuesForm) {
                    _this.invalidQuestion = !_this.characteristicForm.$valid;
                } else {
                    _this.invalidQuestion = false;
                }
                if (_this.characteristicValuesForm) {
                    _this.invalidValues = !_this.characteristicValuesForm.$valid;
                } else {
                    _this.invalidValues = false;
                }

                if (!_this.invalidValues && !_this.invalidQuestion) {
                    $modalInstance.close(_this.compileCharacteristic());
                }

            }
        },
        return: {
            text: _this.isEdit ? (characteristic.core ? $translate.instant('EXIT') : $translate.instant('DELETE')) : $translate.instant('RETURN'),
            className: (_this.isEdit && !characteristic.core) ? 'btn btn-danger' : 'btn',
            action: function () {
                _this.invalidQuestion = false;
                if (_this.isEdit && !characteristic.core) {
                    $modalInstance.dismiss('delete');
                } else if (_this.isEdit && characteristic.core) {
                    _this.exit();
                } else
                    _this.isShowSegmentType = true;
            }
        },
        validate: function () {
            _this.invalidQuestion = !_this.characteristicForm.$valid;
            return _this.characteristicForm.$valid;
        },
        exit: function () {
            _this.exit();
        }
    };

    _this.initializeSegment = function (questionType) {
        _this.isShowSegmentType = false;
        _this.characteristic.questionType =  questionType;
    };


    _this.addValue = function (newCharacteristicValue) {
        var found = _.find(_this.characteristic.values, function (value) { return value.value === newCharacteristicValue; });

        if (typeof found === 'undefined') {
            _this.characteristic.values.push({ value: { defaultValue: newCharacteristicValue } });
        }

        _this.newCharacteristicValue = '';
    };

    _this.removeValue = function (toRemoveCharacteristicValue) {
        for (var i = 0; i < _this.characteristic.values.length; i++) {
            if (_this.characteristic.values[i].value == toRemoveCharacteristicValue.value)
            {
                _this.characteristic.values.splice(i, 1);
                break;
            }
        }
    };


    _this.addRange = function (newCharacteristicRange) {
        _this.characteristic.ranges.push(newCharacteristicRange);
        _this.newCharacteristicRange =  {
            from: null,
            fromType: 'YEAR',
            to: null,
            toType: 'YEAR',
            name: ''
        };
    };

    _this.removeRange = function (index) {
        _this.characteristic.ranges.splice(index, 1);
    };

    _this.compileCharacteristic = function () {
        var characteristic = {
            questionType: _this.characteristic.questionType,
            description: _this.characteristic.description,
            question: _this.characteristic.question,
            privated: _this.characteristic.privated
        };

        if (characteristic.questionType == 'BOOLEAN') {
            characteristic.yesValue = _this.characteristic.yesValue;
            characteristic.noValue = _this.characteristic.noValue;
        }

        if (characteristic.questionType == 'LIST') {
            characteristic.values = _this.characteristic.values;
        }

        if (characteristic.questionType == 'DATE') {
            characteristic.ranges = _this.characteristic.ranges;
        }

        if (_this.characteristic.id) {
            characteristic.id = _this.characteristic.id;
        }

        if (_this.characteristic.companyId) {
            characteristic.companyId = _this.characteristic.companyId;
        }


        return characteristic;

    };

    _this.exit = function () {
        $modalInstance.dismiss('close');
    };


}

const templateEdit = require('./characteristic_edit.html');
const templateDelete = require('./../characteristic_delete/characteristic_delete.html');


const CharacteristicEditModal = angular.module('happyForceApp')
    .factory('CharacteristicsEditModal',
        function (Characteristics, $uibModal, ErrorSvrc, $translate, Analytics) {
            'ngInject'

            var methods = {};

            methods.showModal = function (selectedCharacteristic, selectedLanguage, callback) {
                $uibModal.open({
                    backdrop: 'static',
                    template: templateEdit,
                    controller: CharacteristicEditCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        characteristic: angular.copy(selectedCharacteristic),
                        isEdit: true,
                        language: function () {return selectedLanguage;}
                    }
                }).result.then(function (updatedCharacteristic) {

                    Characteristics.update(updatedCharacteristic.id, updatedCharacteristic, function (err, updatedCharacteristic) {
                        if (err) {
                            callback(err, null);
                        } else {
                            swal($translate.instant('CHARACTERISTICS_SAVED'), '', 'success');
                            Analytics.trackEvent('settings_company_characteristics', 'edit', 'success');
                        }
                    });
                }, function (closeAction) {
                    if (closeAction === 'delete') {
                        $uibModal.open({
                            backdrop: 'static',
                            template: templateDelete,
                            controller: 'CharacteristicsDeleteCtrl',
                            resolve: {
                                selectedCharacteristic: function () {
                                    return angular.copy(selectedCharacteristic);
                                }
                            }
                        }).result.then(function (deleteCharacteristic) {

                            Characteristics.remove(deleteCharacteristic.id, function (removedCharacteristic) {
                                swal($translate.instant('CHARACTERISTICS_DELETED'), '', 'success');
                                Analytics.trackEvent('settings_company_characteristics', 'delete', 'success');
                                callback(null, removedCharacteristic);
                            }, function (err) {
                                callback(err, null);
                                ErrorSvrc.showErrorModal(err);
                            });

                        }, function () {
                            console.info('Modal dismissed at: ' + new Date());
                        });
                    } else {
                        console.info('Modal dismissed at: ' + new Date());
                    }
                });

            };

            return methods;
        }
    );

export default CharacteristicEditModal;
