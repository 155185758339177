'use strict';

import Numbers from "../../../../utilities/number.utilities";

function SurveyRowCtrl($scope, AuthSrvc, Settings, ErrorSvrc, Surveys, CompanySvrc, $translate, $state, $uibModal) {
    'ngInject'
    var _this = this;
    _this.collapseBlockDetails = true;

    _this.loadResults = function () {
        if (_this.survey.schedulePlan) {
            Surveys.stats.results(_this.survey.id, {}, function (err, results) {
                var survey = _this.survey;
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    survey.result = results.result;
                    if (survey.result) {
                        survey.result.participationPercent =
                            Numbers.roundNumber(Numbers.calculatePercent(survey.result.participants, survey.result.expectedParticipants), 1);

                        survey.result.blocks.forEach(function (block) {
                            block.participationPercent =
                                Numbers.roundNumber(Numbers.calculatePercent(block.participants, survey.result.expectedParticipants), 1);
                            block.questions.forEach(function (question) {
                                question.participationPercent =
                                    Numbers.roundNumber(Numbers.calculatePercent(question.participants, survey.result.expectedParticipants), 1);
                            });
                        });
                    }
                }
            });
        }
    };

    _this.goToResults = function () {
        if (_this.survey.schedulePlan) {
            $state.go('surveys.results', {surveyId: _this.survey.id});
        }
        else {
            $state.go('surveys.edit', {surveyId: _this.survey.id});
        }
    };

    this.$onInit = function () {
        _this.loadResults();
    };

}

const hfSurveyRow = angular.module('happyForceApp').directive('hfSurveyRow', function () {
    return {
        scope: {
            survey: '<',
            reloadSurvey: '<',
            reloadStatus: '<'
        },
        restrict: 'A',
        template: require('./survey_row.html'),
        controller: SurveyRowCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfSurveyRow;
