
/*
    A utility component to display the Score, Score Factors and Question titles for a given score.++
 */


import {getService} from 'reactInAngular'
import {useEffect, useState, JSX} from 'react';
import React from 'react';

interface ScoreNameProps {
    scoreId: string;
}


const ScoreName = (({scoreId}:ScoreNameProps): JSX.Element => {

    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const Languages = getService('Languages');

    const [scoreName, setScoreName] = useState<string>('');
    const [factorNames, setFactorNames] = useState<string[]>([]);

    useEffect(() => {
        ScoresV2Srvc.getScore(scoreId, function (err:any, score: any) {
            if (err) {
                // TODO: Handle error
                console.log(err);
            } else {
                setScoreName( Languages.getTranslationFromUserLanguage(score.name));
            }
        });
    }, [scoreId]);
    return (
        <>{scoreName}</>
    );
});

interface ScoreFactorNameProps {
    scoreId: string;
    factorId: string;
}


const ScoreFactorName = (({scoreId, factorId}:ScoreFactorNameProps): JSX.Element => {

    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const Languages = getService('Languages');

    const [factorName, setFactorName] = useState<string>('');

    useEffect(() => {
        if (!scoreId || !factorId) return;
        ScoresV2Srvc.getScore(scoreId, function (err:any, score: any) {
            if (err) {
                // TODO: Handle error
                console.log(err);
            } else {
                const factor = score?.factors.find((factor:any) => {return factor.id === factorId});

                // TODO: Handle the not found case
                setFactorName( factor?.name ? Languages.getTranslationFromUserLanguage(factor?.name) : '?');
            }
        });
    }, [scoreId, factorId]);
    return (
        <>{factorName}</>
    );
});


export {ScoreName, ScoreFactorName}
