'use strict';

const stripeHTMLFilter = function () {
    'ngInject'

    return function (htmlString) {

        if (htmlString) {
            return htmlString.replace(/(<([^>]+)>)/gi, '');
        } else {
            return htmlString;
        }

    };


};

export default stripeHTMLFilter;

