'use strict';

import Numbers from "../../../../../utilities/number.utilities";
import Valuation from "../../../../../utilities/valuation.utils";
import ArrowIndicator from '../../../../../shared/components/_migrated/arrow-indicator-directive/arrow-indicator';

function participationNumberCtrl($scope, $translate, ParticipationService) {
    'nInject'
    var _this = this;


    function parseData() {

        _this.percent = Numbers.roundNumber(Numbers.calculatePercent(_this.data.participants, _this.data.activated), 1);

        if (_this.percent > 100) {
            _this.percent = 100;
        }

        _this.variation = 10;
        _this.label = ParticipationService.getValuationLabel(_this.data.valuation);
        _this.color = Valuation.valuationColor(_this.data.valuation);

        _this.participantsVariationPercent =  Numbers.roundNumber(_this.data.participantsVariationPercent, 1);


        _this.slices = [{
            color: Valuation.valuationColor('BAD'),
            range: [0, 30],
            label: $translate.instant('PARTICIPATION_BAD')
        }, {
            color: Valuation.valuationColor('WARNING'),
            range: [30, 50],
            label: $translate.instant('PARTICIPATION_CAUTION')
        }, {
            color: Valuation.valuationColor('EXCELLENT'),
            range: [50, 70],
            label: $translate.instant('PARTICIPATION_GOOD')
        }, {
            color: Valuation.valuationColor('EXCELLENT'),
            range: [70, 100],
            label: $translate.instant('PARTICIPATION_EXCELLENT')
        }];
    };

    this.$onInit = function () {
        ['data', 'hasEnoughActiveEmployees'].forEach(function (key) {
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) return;
                parseData();
            });
        });

        parseData();
    };





}


const participationNumber = angular.module('happyForceApp')
    .directive('participationNumber', function () {
        return {
            scope: {
                data: '=',
                hasEnoughActiveEmployees: '='
            },
            template: require('./participation-number.html'),
            controller: participationNumberCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });


export default participationNumber;
