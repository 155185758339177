import Numbers from "../../utilities/number.utilities";
import {getService} from "reactInAngular";

var EngagementCharts = EngagementCharts || {};


EngagementCharts.scorePositioningChart = function (data, min, max, name, type) {


    const $translate = getService('$translate');

    var series = [];
    data.forEach(function (item) {
        if (item.selected && !item.nonEnoughEmployees) {

            const participation = Numbers.calculatePercent(item.participants, item.expectedParticipants);
            let value;

            if (type === 'SCORE') {
                value = item.value;

            } else {
                value = item.result;

            }

            series.push({
                x: value,
                y: participation > 100 ? 100 : participation,
                z: item.expectedParticipants,
                name: item.name,
                color: item.color

            });
        }
    });

    var stops;
    var positivePlot = 50;

    if (type === 'ENPS') {
        stops =  [
            [0, 'rgba(150, 214, 0, 0.5)'],
            [0.2, 'rgba(150, 214, 0, 0.3)'],
            [0.4, 'rgba(255, 172, 0, 0.3)'],
            [0.9, 'rgba(217, 44, 50, 0.3)'],
            [1, 'rgba(217, 44, 50, 0.4)']
        ];
        positivePlot = 20;
    } else {
        stops =  [
            [0, 'rgba(150, 214, 0, 0.5)'],
            [0.4, 'rgba(150, 214, 0, 0.3)'],
            [0.8, 'rgba(255, 172, 0, 0.3)'],
            [0.9, 'rgba(217, 44, 50, 0.3)'],
            [1, 'rgba(217, 44, 50, 0.4)']
        ];
        if (type === 'STANDARD')
            positivePlot = 5;
    }

    return {
        chart: {
            style: {
                fontWeight: 'normal',
                color: '#585858'
            },
            type: 'bubble',
            plotBorderWidth: 0,
            zoomType: 'xy',
            plotBackgroundColor: {
                radialGradient: { cx: 1, cy: 0, r: 1 },
                stops: stops
            }
        },

        legend: {
            enabled: false
        },

        title: null,

        xAxis: {
            min: min,
            max: max,
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 0,
            title: {
                text: name
            },
            labels: {
                format: '{value}pts'
            },
            plotLines: [{
                color: 'grey',
                dashStyle: 'dot',
                width: 1,
                value: positivePlot,
                zIndex: 3
            }]
        },

        yAxis: {
            min: -10,
            max: 110,
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 0,
            title: {
                text: $translate.instant('PARTICIPATION')
            },
            labels: {
                format: '{value}%'
            },
            maxPadding: 0.2,
            plotLines: [{
                color: 'grey',
                dashStyle: 'dot',
                width: 1,
                value: 50,
                zIndex: 3
            }]
        },

        tooltip: {
            shared: true,
            useHTML: true,
            headerFormat: '<div class="row">',
            pointFormat: '<div class="col-xs-12">{point.name}:</div>' +
                '<div class="col-xs-12"><small>' + name + ': {point.x:,.1f}pts</small></div>' +
                '<div class="col-xs-12"><small>' + $translate.instant('PARTICIPATION') + ':{point.y:,.0f}%</small></div>' +
                '<div class="col-xs-12"><small>' + $translate.instant('EMPLOYEES') + ':{point.z:,.0f}</small></div>',
            footerFormat: '</div>',
            valueDecimals: 2
        },

        plotOptions: {
            bubble: {
                minSize: '1%',
                maxSize: '20%'
            },
            series: {
                dataLabels: {
                    enabled: true,
                    allowOverlap: false,
                    format: '{point.name}',
                    padding: 0,
                    style: {
                        fontWeight: 'normal',
                        fontSize: '10px',
                        textOutline: 'none',
                        color: '#585858'
                    }
                }
            }
        },

        series: [{
            data: series,
            zMin: 0
        }]
    };
};

export default EngagementCharts;
