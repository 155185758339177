angular.module('happyForceApp')
    .factory('Employees', function (Restangular, $log, $translate, Rollbar) {

        var Company = Restangular.one('company');

        var methods = {};

        methods.find = function (params, callback, error) {
            Company.one('employees').one('find').get(params).then(function (results) {
                callback(null, results);
            }, function (err) {

                callback(err);
            });
        };

        methods.get = function (params, callback, error) {
            Company.one('employees').get(params).then(function (results) {
                callback(null, results);
            }, function (err) {

                callback(err);
            });
        };

        methods.POST = function (params, callback, error) {
            Company.one('employees').customPOST(params.POST, params.type, {}, {}).then(callback, error);
        };

        methods.invite = function (employee, callback) {
            Company.one('employees').customPOST(employee).then(function (result) {
                callback(null, result);
            }, function (err) {

                callback(err);
            });
        };

        methods.update = function (employee, callback) {
            var params = {};
            if (employee.companyId) {
                params.companyId = employee.companyId;
            };

            Company.customPUT(employee, 'employees', params).then(function (result) {
                callback(null, result);
            }, function (err) {

                callback(err);
            });
        };

        methods.bulk = function (action, request, callback) {
            Company.one('employees').one('bulk').one(action).customPOST(request).then(function (results) {
                callback(null, results);
            }, function (err) {

                callback(err);
            });
        };

        methods.engageInactives = function (callback) {
            Company.one('employees').one('inactives').one('engage').post().then(function () {
                callback(null, true);
            }, function (err) {

                callback(err);
            });
        };

        methods.reminder = function (useSenderName, subject, content, target, segmentationParams, callback) {
            Company.one('employees').one('reminder').customPOST({ subject: subject, content: content, signWithUserName: useSenderName }, target, segmentationParams, {}).then(function () {
                callback(null);
            }, function (err) {

                callback(err);
            });
        };

        methods.getTemplate = function (type, callback) {
            Company.one('employees').one('template').one(type).withHttpConfig({ responseType: 'blob' }).get({}).then(function (blob) {
                callback(null, blob);
            }, function (err) {

                callback(err);
            });
        };

        function extract(employees, item) {
            return employees.map(function (employee) {
                return employee[item];
            });
        }

        methods.resend = function (employee, callback, error) {
            var params = {};
            if (employee.companyId) {
                params.companyId = employee.companyId;
            };

            Company.one('employees').customPOST(employee, 'resendInvite', params, {}).then(callback, error);
        };

        methods.delete = function (employee, callback) {
            var params = {};
            if (employee.id) {
                params.id = employee.id;
            } else {
                params.email = employee.email;
            }

            if (employee.companyId) {
                params.companyId = employee.companyId;
            };

            Company.one('employees').customDELETE('', params).then(function (employee) {
                callback(null, employee);
            }, callback);
        };

        methods.reset = function (employees, callback, error) {
            var params = {};
            if (employee.companyId) {
                params.companyId = employee.companyId;
            };

            Company.one('employees').customPOST({ ids: extract(employees, 'id') }, 'reset', {}, {}).then(callback, error);
        };

        methods.setGroup = function (employees, group, callback) {
            if (group === 'all') {
                group = '';
            }

            Company.one('employees')
                .one('group', group)
                .customPUT({ ids: extract(employees, 'id') }, '', {}, {})
                .then(function (data) {
                    $log.debug('Group added!', group, employees, data);
                    callback(null, data);
                }, function (err) {

                    Rollbar.error(err);
                    callback(err);
                });
        };

        methods.removeGroup = function (employees, group, callback) {
            $log.debug('Group remove', employees, group);
            Company.one('employees')
                .one('group', group.id)
                .customDELETE('', { ids: extract(employees, 'id') }, {}, {})
                .then(function (data) {
                    callback(null, data);
                }, function (err) {

                    Rollbar.error(err);
                    callback(err);
                });
        };

        var Stats = Company.one('stats');
        var stats = {};

        stats.get = function (callback) {
            Stats.one('employees').get().then(function (results) {
                callback(null, results);
            }, callback);
        };

        var advisors = {};

        advisors.locate = function (params, callback) {
            Company.one('advisor').one('employees').one('locate').get(params).then(function (results) {
                callback(null, results);
            }, function (err) {

                callback(err);
            });
        };

        methods.stats = stats;
        methods.advisors = advisors;

        methods.genders = [{
            label: $translate.instant('EMPLOYEE_GENDER_MAN'),
            value: 'MAN'
        },
        {
            label: $translate.instant('EMPLOYEE_GENDER_WOMAN'),
            value: 'WOMAN'
        },
        {
            label: $translate.instant('EMPLOYEE_GENDER_OTHER'),
            value: 'OTHER'
        }];

        methods.leaveReasons = [{
            label: $translate.instant('VOLUNTARY_FUNCTIONAL'),
            value: 'VOLUNTARY_FUNCTIONAL'
        },
        {
            label: $translate.instant('INVOLUNTARY'),
            value: 'INVOLUNTARY'
        },
        {
            label: $translate.instant('VOLUNTARY_DYSFUNCTIONAL_AVOIDABLE'),
            value: 'VOLUNTARY_DYSFUNCTIONAL_AVOIDABLE'
        },
        {
            label: $translate.instant('VOLUNTARY_DYSFUNCTIONAL_UNAVOIDABLE'),
            value: 'VOLUNTARY_DYSFUNCTIONAL_UNAVOIDABLE'
        }];

        return methods;

    });
