'use strict';

const SanitizeFilter = function ($sce) {
    'ngInject';
    return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    };
};

export default SanitizeFilter;
