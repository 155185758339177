'use strict';
import CompanyCreateModal from '../../../../components/company/company-create.modal';
import CompanyCreateDefaultsModal from '../../../../components/company/company-create-defaults.modal';


var QuickMenuCtrl = function (AuthSrvc, Restangular, Settings, $uibModal, CompanyCreateModal, CompanyCreateDefaultsModal) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {

        _this.isADVISOR = AuthSrvc.isADVISOR();

        _this.advisors = {};

        function saveAdvisors() {
            localStorage.setItem('advisors', JSON.stringify(_this.advisors));
        }

        function setEnvironment() {
            _this.env = _this.settings.APIUrl.indexOf('staging') > -1 ? 'stage' : _this.settings.APIUrl.indexOf('localhost') > -1 ? 'local' : 'prod';
        }

        if (AuthSrvc.isADVISOR) {
            _this.settings = Settings;
            if (!localStorage.getItem('advisors')) {
                localStorage.setItem('advisors', '{}');
            }

            _this.advisors = JSON.parse(localStorage.getItem('advisors'));

            _this.changeUrl = function () {
                Restangular.setBaseUrl(Settings.APIUrl);
                setEnvironment();
            };

            _this.toogleLabels = function () {
                if (_this.advisors.showLabels) {
                    _this.advisors.showLabels = false;
                } else {
                    _this.advisors.showLabels = true;
                }

                saveAdvisors();
            };

            _this.toogleAvengersMode = function () {
                if (_this.advisors.incognitoMode) {
                    _this.advisors.incognitoMode = false;
                } else {
                    _this.advisors.incognitoMode = true;
                }

                saveAdvisors();
            };

            setEnvironment();

            _this.createNewCompany = function () {

                CompanyCreateModal.showModal(function () {
                    window.location.reload();
                });
            };

            _this.createDefaults = function () {

                CompanyCreateDefaultsModal.showModal(function () {
                    window.location.reload();
                });
            };
        }
    };
};



//Directive used to set metisMenu and minimalize button
const hfQuickMenu = angular.module('happyForceApp')
    .directive('hfQuickMenu', function () {
        return {
            template: require('./quick-menu.html'),
            controller: QuickMenuCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });


export default hfQuickMenu;
