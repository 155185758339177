'use strict';

import {getHierarchyName} from "../../../services/migrated/hierarchy.service";

function AudienceItemCtrl($window, $scope, ErrorSvrc, AuthSrvc, Characteristics, Groups, $state, $translate, CompanySvrc, Languages) {
    'ngInject';

    $scope.getGroupName = function (groupId) {
        return Groups.getGroupName(groupId);
    };

    $scope.hierarchyNames = {};


    function checkIsArrayOrWrap(params) {
        var array;
        if (!Array.isArray(params) && params) {
            array = [params];
        } else {
            array = params;
        }

        return array;
    }

    var getHierarchyNames = function (hierarchyIds) {

        $scope.hierarchyNames = {};

        if (isEmpty(hierarchyIds)) {
            $scope.hierarchyText = $translate.instant('AUDIENCE_ALL_DEPARMENTS');
        }
        _.forEach(hierarchyIds, function (hierarchyId) {
            getHierarchyName(hierarchyId, function (error, name) {
                $scope.hierarchyNames[hierarchyId] = name;
                var hierarchies = _.values($scope.hierarchyNames).join(', ');
                $scope.hierarchyText =  $translate.instant('AUDIENCE_SOME_DEPARMENTS', { hierarchies: hierarchies });
                generateVisibleTo();
            });
        });
    };

    var getCharacteristicNames = function (characteristics) {


        if (isEmpty(characteristics)) {
            $scope.characteristicText = $translate.instant('AUDIENCE_ALL_CHARACTERISTICS');
        } else {
            var characteristicNames = [];
            var descriptions = {};
            _.forEach(characteristics, function (characteristic) {
                var charactValue = Characteristics.operations.getCharacteristicValue(characteristic);
                if (!charactValue) {
                    var unknownText = $translate.instant('UNKNOWN');
                    charactValue = { description: unknownText, value: unknownText };
                }

                if (!descriptions[charactValue.description]) {
                    descriptions[charactValue.description] = [];
                }

                descriptions[charactValue.description].push(Languages.getTranslationFromUserLanguage(charactValue.value));
            });

            for (var description in descriptions) {
                var joinedValues = descriptions[description].join(', ');
                characteristicNames.push(description + '(' + joinedValues + ')');
            }

            var joinedNames = characteristicNames.join(', ');
            $scope.characteristicText = $translate.instant('AUDIENCE_SOME_CHARACTERISTICS', { characteristics: joinedNames });
        }
        generateVisibleTo();
    };

    var getGroupNames = function (groups) {


        if (isEmpty(groups)) {
            $scope.groupText = $translate.instant('AUDIENCE_ALL_GROUPS');
        } else {
            var groupNames = [];
            _.forEach(groups, function (groupId) {
                groupNames.push($scope.getGroupName(groupId));
            });

            var groupNameText = groupNames.join(', ');
            $scope.groupText =  $translate.instant('AUDIENCE_SOME_GROUPS', { groups: groupNameText });
        }
        generateVisibleTo();
    };


    if (!$scope.audienceTarget) {
        // No audience target; get all company name:

        CompanySvrc.getUserCompany(function (err, result) {
            if (err) {
                $scope.audienceTarget = $translate.instant('COMPANY');
            } else {
                $scope.audienceTarget = result.name;
            }
            generateVisibleTo();
        });

    }

    function generateVisibleTo() {

        if (isEmpty($scope.hierarchyIdsArray) && isEmpty($scope.characteristicsArray) && isEmpty($scope.groupIdsArray)) {
            $scope.toAll = true;
            $scope.visibleTo = generateHTML($scope.audienceAllTranslationKey, {
                audienceItem: $scope.audienceItem,
                audienceTarget: $scope.audienceTarget
            });

        } else {
            $scope.toAll = false;
            $scope.visibleTo = generateHTML($scope.audienceTranslationKey, {
                hierarchies: $scope.hierarchyText,
                characteristics: $scope.characteristicText,
                groups: $scope.groupText,
                audienceItem: $scope.audienceItem,
                audienceTarget: $scope.audienceTarget
            });
        }
    }

    function processData() {
        getHierarchyNames($scope.hierarchyIdsArray);
        getCharacteristicNames($scope.characteristicsArray);
        getGroupNames($scope.groupIdsArray);
    }

    function generateHTML(key, options) {
        return $translate.instant(key, options);
    }

    function isEmpty(array) {
        return _.isUndefined(array) || !Array.isArray(array) || array.length == 0;
    }

    this.$onInit = function () {
        $scope.$watchGroup(['hierarchyIds', 'characteristics', 'groupIds', 'audienceTarget'], function (n, o) {

            $scope.hierarchyIdsArray = checkIsArrayOrWrap($scope.hierarchyIds);
            $scope.characteristicsArray = checkIsArrayOrWrap($scope.characteristics);
            $scope.groupIdsArray = checkIsArrayOrWrap($scope.groupIds);
            processData();
        });
    };


}

//Directive used to set metisMenu and minimalize button
const hfAudienceItem = angular.module('happyForceApp')
    .directive('hfAudienceItem', function () {
        return {
            scope: {
                audienceItem: '@',
                audienceTranslationKey: '@',
                audienceAllTranslationKey: '@',
                audienceTarget: '=',
                hierarchyIds: '=',
                characteristics: '=',
                groupIds: '=',
                fromDate: '=',
                toDate: '=',
                popupMode: '<'
            },
            template: require('./audience-item.html'),
            controller: AudienceItemCtrl,
            controllerAs: '$ctrl'
        };
    });

export default hfAudienceItem;
