const languageNameFilter = function (CompanySvrc, $translate, Languages) {
    'ngInject'
    var _this = this;

    return function (input, options) {
        if (!_this.languageNames) {
            _this.languageNames = [];
        }
        if (input) {
            if (!_this.languageNames[input]) {
                _this.languageNames[input] = Languages.getNameByCode(input);
            }
            return _this.languageNames[input];
        } else {
            return '';
        }

    };
}

export default languageNameFilter


