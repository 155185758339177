

'use strict';

function AnnouncementsCelebrationSectionConfigCtrl($scope, Languages) {
    'ngInject';

    const _this = this;

    _this.hasAnyTypeEnabled = true;


    _this.setSelected = function (type) {
        _this.selectedType = type;
        computeTypeLanguages();
    };


    _this.removeSection = function () {
        _this.onRemoveSection(_this.sectionData.id);
    };

    _this.moveUp = function () {
        _this.onMoveUp(_this.sectionData.id);
    };

    _this.moveDown = function () {
        _this.onMoveDown(_this.sectionData.id);
    };

    _this.onContentUpdated = function (content) {
        if (_this.selectedLanguage)
            _this.selectedType.message[_this.selectedLanguage] = content;
        else
            _this.selectedType.message.defaultValue = content;
    };


    function computeTypeLanguages() {
        _this.typeLanguages = [];
        if (_this.selectedType && _this.selectedType.title.langValues != null) {
            Object.keys(_this.selectedType.title.langValues).forEach(function (x) { _this.typeLanguages.push(x);});
        }
    }


    _this.selectLanguage = function (language) {
        _this.selectedLanguage = language;
        _this.selectedLanguageTranslation = Languages.getNameByCode(language);
    };

    _this.addLanguage = function (language) {

        Languages.setLangValue(_this.selectedType.title, null, language);
        Languages.setLangValue(_this.selectedType.message, null, language);

        // Update the language list
        computeTypeLanguages();
    };


    _this.removeLanguage = function () {
        Languages.removeLangValue(_this.selectedType.title, _this.selectedLanguage);
        Languages.removeLangValue(_this.selectedType.message, _this.selectedLanguage);
        computeTypeLanguages();
    };


    _this.$onInit = function () {
        $scope.$watch(angular.bind(_this, function () {
            return _this['sectionData'];
        }), function (n, o) {
            if (n == o) return;

            // We just validate here if the title is valid
            const isTitleValid = !!_this.sectionData.title && !!_this.sectionData.title.defaultValue
                && _this.sectionData.title.defaultValue.length > 0;

            const hasTypeConfigurations = _this.sectionData.eventTypeConfigurations && _this.sectionData.eventTypeConfigurations.length >= 0;

            _this.hasAnyTypeEnabled = _.some(_this.sectionData.eventTypeConfigurations, function (eventType) {return eventType.enabled;});

            _this.setIsValid(_this.sectionData.id, isTitleValid && hasTypeConfigurations && hasAnyTypeEnabled);

        }, true);

        if (_this.sectionData != null)
            computeTypeLanguages();
    };




}



const hfCelebrationsSectionConfig = angular.module('happyForceApp')
    .directive('hfCelebrationsSectionConfig', function () {
        return {
            template: require('./celebrations_section_configuration.html'),
            controller: AnnouncementsCelebrationSectionConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                sectionData: '<',
                onRemoveSection: '<',
                setIsValid: '<',
                onMoveUp: '<',
                onMoveDown: '<'
            }
        };
    });

    export default hfCelebrationsSectionConfig;
