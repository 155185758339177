'use strict';

import template from './advisors_surveys_edit.html';

function AdvisorsSurveysEditCtrl($scope, ErrorSvrc, CompanySvrc, $translate, Languages, SurveyAdvisors, toaster, $stateParams) {


    var _this = this;


    _this.allLanguages = _.filter(Languages.list, function (language) {
        return language.code != 'en';
    });

    _this.industryTypes = CompanySvrc.industryTypesList;
    _this.companies = [];


    if ($stateParams.templateId == 'new') {
        _this.template = {
            title: {
                defaultValue: ''
            },
            description: {
                defaultValue: ''
            },
            catalogTitle: {
                defaultValue: ''
            },
            catalogDescription: {
                defaultValue: ''
            },
            active: false,
            categoryId: null,
            restrictedToCompanies: [],
            restrictedToIndustries: [],
            imagePath: 'https://assets.myhappyforce.com/defaultImages/survey_icons/emojione_green-book.png',
            blocks: []
        };
    } else {
        SurveyAdvisors.get($stateParams.templateId, function (err, result) {
            if (err)
                ErrorSvrc.showErrorModal(err);
            else {
                _this.template = result;
            }
        });
    }


    _this.templateDone = function (template) {

        if (_this.template.id) {
            SurveyAdvisors.update(_this.template.id, template, function (err, result) {
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    _this.template = result;
                    toaster.pop({
                        type: 'success',
                        title: 'Chachi!, plantilla de encuesta actualizada',
                        showCloseButton: true,
                        timeout: 5000
                    });
                }
            });
        } else {
            SurveyAdvisors.create(template, function (err, result) {
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    _this.template = result;
                    toaster.pop({
                        type: 'success',
                        title: 'Chachi!, plantilla de encuesta creada',
                        showCloseButton: true,
                        timeout: 5000
                    });
                }
            });
        }


    };

    _this.surveyAddRestrictedIndustry = function (industryId) {
        if (!_this.template.restrictedToIndustries)
            _this.template.restrictedToIndustries = [];
        if (_this.template.restrictedToIndustries.indexOf(industryId) == -1)
            _this.template.restrictedToIndustries.push(industryId);
    };

    _this.surveyRemoveRestrictedIndustry = function (industryId) {
        if (!_this.template.restrictedToIndustries)
            return;
        _this.template.restrictedToIndustries.splice(_this.template.restrictedToIndustries.indexOf(industryId), 1);

    };

    _this.getIndustryName = function (industryId) {
        return _.find(_this.industryTypes, function (industry) {
            return industry.id == industryId;
        }).label;
    };


    _this.surveyAddRestrictedCompany = function (companyId) {
        if (!_this.template.restrictedToCompanies)
            _this.template.restrictedToCompanies = [];
        if (_this.template.restrictedToCompanies.indexOf(companyId) == -1)
            _this.template.restrictedToCompanies.push(companyId);
    };

    _this.surveyRemoveRestrictedCompany = function (companyId) {
        if (!_this.template.restrictedToCompanies)
            return;
        _this.template.restrictedToCompanies.splice(_this.template.restrictedToCompanies.indexOf(companyId), 1);

    };

    _this.getCompanyName = function (companyId) {
        /*CompanySvrc.getCompany(companyId, function (err, company)) {
            if (err) {

            } else {

            }
        }
        //return _.find($scope.companies, function (company) { return company.id == companyId; }).name;
        */
        return 'TODO';

    };


    SurveyAdvisors.listCategories(function (err, results) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else
            _this.categories = results;
    });


    _this.getLangKeys = function (langs) {
        if (langs)
            return Object.keys(langs);
        else
            return [];
    };


    _this.surveyTemplateAddLanguage = function (languageCode) {
        if (!_this.template.catalogTitle.langValues)
            _this.template.catalogTitle.langValues = {};
        else if (_this.template.catalogTitle.langValues[languageCode])
            return;
        _this.template.catalogTitle.langValues[languageCode] = '';

        if (!_this.template.catalogDescription.langValues)
            _this.template.catalogDescription.langValues = {};
        _this.template.catalogDescription.langValues[languageCode] = '';

    };

    _this.surveyTemplateRemoveLanguage = function (languageCode) {
        delete _this.template.catalogTitle.langValues[languageCode];
        delete _this.template.catalogDescription.langValues[languageCode];
    };


    _this.getLanguageName = function (code) {
        return _.find(_this.allLanguages, function (language) {
            return language.code == code;
        }).language;
    };

}






function AdvisorsSurveysEditView($stateProvider) {
    'ngInject'
    $stateProvider.state('advisors.survey-edit', {

        controller: AdvisorsSurveysEditCtrl,
        controllerAs: '$ctrl',
        bindToController: true,
        template: template,
        url: '/survey/edit/:templateId',
        data: { pageTitle: 'Surveys Edit', roles: ['ADVISOR'] }
    });
}

export default AdvisorsSurveysEditView;
