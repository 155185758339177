'use strict';

import {
    addCultureValue,
    getCultureValues,
    updateCultureValue
} from "../../../../../../shared/services/migrated/culture_values.service";
import async from 'async';
import CompanyCultureValuesConfigModal from './modals/company_culture_values.modal';

function CompanyCultureValuesConfigCtrl(FeedbackConfigurationService, ErrorSvrc, $translate, $scope, $uibModal, Languages, CompanyCultureValuesConfigModal) {
    'ngInject'

    var _this = this;


    _this.selectedLanguage = null;

    _this.selectedLanguageTranslation = null;

    _this.valuesLanguages = [];

    _this.cultureValues = [];


    function computeCurrentLanguages() {
        if (_this.cultureValues.length > 0) {
            const languages = new Set();

            // Get all the languages:
            _this.cultureValues.forEach(function (value) {
                if (value.name.langValues != null) {
                    Object.keys(value.name.langValues).forEach(function (x) {languages.add(x);});
                }
            });

            _this.valuesLanguages = [];
            languages.forEach(function (x) {_this.valuesLanguages.push(x);});
        } else {
            _this.valuesLanguages = [];
        }
    }

    _this.loadCultureValues = function () {
        getCultureValues(function (err, results) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            }
            else {
                _this.cultureValues = results;
            }
        });
    };

    _this.selectLanguage = function (language) {
        _this.selectedLanguage = language;
        _this.selectedLanguageTranslation = Languages.getNameByCode(language);
    };

    _this.addLanguage = function (language) {

        // For each value, add the new language
        _this.cultureValues.forEach(function (value) {
            Languages.setLangValue(value.name, null, language);
            Languages.setLangValue(value.description, null, language);
        });

        // Update the language list
        computeCurrentLanguages();


    };

    _this.editCultureValue = function (cultureValue) {
        CompanyCultureValuesConfigModal.editValue(angular.copy(cultureValue), _this.selectedLanguage, function (result) {
            _this.loadCultureValues();
        });

    };

    _this.addCultureValue = function () {
        CompanyCultureValuesConfigModal.addValue(_this.selectedLanguage,function (result) {
            _this.loadCultureValues();
        });
    }

    _this.removeLanguage = function (language) {

        var languageName = Languages.getNameByCode(language);
        swal({
            type: 'warning',
            title: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_TITLE', { language: _this.selectedLanguageTranslation }),
            text: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_DESCRIPTION', { language: _this.selectedLanguageTranslation }),
            allowEscapeKey: true,
            buttons: {
                cancel: {
                    visible: true,
                    text: $translate.instant('QUESTION_DELETE_LANGUAGE_CANCEL', { language: _this.selectedLanguageTranslation }),
                    value: false
                },
                confirm: {
                    text: $translate.instant('QUESTION_DELETE_LANGUAGE_CONFIRM', { language: _this.selectedLanguageTranslation }),
                    value: true,
                    className: 'swal-button--delete'
                }
            },
            confirmButtonColor: '#DD6B55',
            closeOnConfirm: true
        }).then(function (result) {
            if (result) {

                var streams = [];
                _this.cultureValues.forEach(function (value) {

                    streams.push(function (next) {
                        // Remove the language
                        Languages.removeLangValue(value.name, _this.selectedLanguage);
                        Languages.removeLangValue(value.description, _this.selectedLanguage);
                        updateCultureValue(value, function (err, result) {
                            if (err) {
                                next(err);
                            } else {
                                next();
                            }
                        });
                    });

                    async.parallel(streams, function (err, results) {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                        }
                        _this.loadCultureValues();
                    });
                });

            }
        });
    };




    _this.loadCultureValues();

    // Monitor language changes of the question
    ['cultureValues'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind(_this, function () {
            return _this[key];
        }), function (n, o) {
            if (n != o) {
                computeCurrentLanguages();
            };

        });
    });
}

const hfCompanyCultureValuesConfig = angular.module('happyForceApp')
    .directive('hfCompanyCultureValuesConfig', function () {
        return {
            scope: {
                editEnabled: '='
            },
            template: require('./company_culture_values.html'),
            controller: CompanyCultureValuesConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfCompanyCultureValuesConfig;
