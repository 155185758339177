'use strict';

import Style from "../../../../utilities/style.utils";
import Charts from "../../../../utilities/charts.utilities";

function SurveyDistributionChartCtrl($translate) {
    'ngInject';
    var _this = this;

    this.$onInit = function () {

        var points = {};
        var values = [];

        if (_this.distribution) {
            _this.distribution.forEach(function (item) {
                var point = {
                    name: item.classification,
                    y: 8,
                    detail: [item.count],
                    z: item.count
                };
                if (item.classification === 'NEGATIVE') {
                    point.color = Style.bad;
                } else if (item.classification === 'NEUTRAL') {
                    point.color = Style.neutral;
                } else if (item.classification === 'POSITIVE') {
                    point.color = Style.excellent;
                }

                points[item.classification] = point;

            });
        }

        if (points['POSITIVE'])
            values.push(points['POSITIVE']);
        if (points['NEUTRAL'])
            values.push(points['NEUTRAL']);
        if (points['NEGATIVE'])
            values.push(points['NEGATIVE']);



        var fontSize;
        var fontWeight;
        var fontFamily;
        var margin;
        var height;

        if (_this.size === 'small') {
            var chartFontConfig = Charts.getFontConfig('mini');
            height = 24;
            margin = 2;
            fontSize = chartFontConfig.fontSize;
            fontWeight = chartFontConfig.fontWeight;
            fontFamily = chartFontConfig.fontFamily;
        } else {
            var chartFontConfig = Charts.getFontConfig('big');
            height = 56;
            margin = 8;
            fontSize = chartFontConfig.fontSize;
            fontWeight = chartFontConfig.fontWeight;
            fontFamily = chartFontConfig.fontFamily;
        }


        _this.chartConfig = {
            options: {
                chart: {
                    type: 'variwide',
                    height: height,
                    margin: [margin, 0, margin, 0],
                    spacing: [0, 0, 0, 0],
                    backgroundColor: 'transparent',
                    fontFamily: fontFamily,
                    fontSize: fontSize,
                    fontWeight: fontWeight
                },

                title: null,
                yAxis: {
                    visible: false,
                    min: 1,
                    max: 8
                },

                xAxis: {
                    type: 'category',
                    visible: false,
                    tickWidth: 0,
                    labels: {
                        enabled: false,
                        align: 'center',
                        reserveSpace: false,
                        y: -35,
                        style: {
                            color: '#FFFFFF',
                            fontFamily: fontFamily,
                            fontSize: fontSize,
                            fontWeight: fontWeight
                        }
                    }
                },

                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        animation: true,
                        borderWidth: 0
                    },
                    variwide: {
                        stacking: 'normal',

                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.detail}</b>',
                            y: 0,
                            style: {
                                color: '#FFFFFF',
                                fontFamily: fontFamily,
                                fontSize: fontSize,
                                fontWeight: fontWeight,
                                textOutline: '0px none'
                            }

                        }
                    }

                }
            },
            series: [{
                data: values,
                tooltip: {
                    pointFormat: 'Number of Employees:{point.detail}'
                },
                colorByPoint: true
            }]

        };

    };

};

const hfSurveyDistributionChart = angular.module('happyForceApp')
    .directive('hfSurveyDistributionChart', function () {
        return {
            scope: {
                distribution: '=',
                hasResult: '=',
                size: '<'
            },
            template: require('./survey_distribution_chart.html'),
            controller: SurveyDistributionChartCtrl,
            controllerAs: 'si',
            bindToController: true
        };
    });
export default hfSurveyDistributionChart;
