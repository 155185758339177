import {Person} from '../../types';
import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@mui/material';
import {getService} from 'reactInAngular';

interface UserResendProps {
    person: Person | Person[];
    onExecuted: (person: Person | Person[]) => void;
    onClose: () => void;
}


const UserResubscribeMailDialog = ({person, onExecuted, onClose}: UserResendProps) => {
    const toaster = getService('toaster');

    const $translate = getService('$translate')
    const ErrorSvrc = getService('ErrorSvrc');
    const PeopleService = getService('People');
    const [executing, setExecuting] = React.useState(false);


    const handleMultipleProfileResets = async (persons: Person[]) => {
        const updatedPersons: Person[] = [];
        let errored = false;
        for (const person of persons) {
            try {
                await new Promise((resolve, reject) => {
                    PeopleService.reSubscribeForMail(person, (resendErr) => {
                        if (resendErr) {
                            reject(resendErr);
                        } else {
                            resolve(person);
                        }
                    });
                }).then((updatedPerson) => {
                    updatedPersons.push(updatedPerson as Person);
                });
            } catch (error) {
                ErrorSvrc.showError(error);
                errored = true;
                setExecuting(false);
                break; // Sale del bucle si hay un error
            }
        }
        if (!errored) {
            toaster.pop('success', null, $translate.instant('EMPLOYEE_RESUBSCRIBED'));
            onExecuted(updatedPersons);
            onClose();
        }
        setExecuting(false);

    }

    const handleSingleProfileReset = (person: Person) => {

        setExecuting(true);

        PeopleService.reSubscribeForMail(person, function (resendErr, resubscribedEmployee) {

            setExecuting(false);
            if (resendErr) {
                ErrorSvrc.showError(resendErr);
            } else {
                toaster.pop('success', null, $translate.instant('EMPLOYEE_RESUBSCRIBED'));
                onExecuted(resubscribedEmployee);
                onClose();
            }
        });
    };


    return (
        <Dialog
            open={!!person}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant={'h4'} >
                    {$translate.instant('EMPLOYEE_RESUBSCRIBE_TITLE')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant={'body2'}>
                        {Array.isArray(person) ?
                            $translate.instant('SURE_RESUBSCRIB_EMPLOYEE_BULK', { users: person.length })
                            : $translate.instant('SURE_RESUBSCRIB_EMPLOYEE', { email: person?.email })
                        }
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{$translate.instant('CANCEL')}</Button>
                <Button
                    variant="contained"
                    disabled={executing}
                    onClick={() => {Array.isArray(person) ? handleMultipleProfileResets(person): handleSingleProfileReset(person)}} autoFocus>
                    {$translate.instant('EMPLOYEE_RESUBSCRIB_BUTTON')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserResubscribeMailDialog;
