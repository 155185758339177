



function AnnouncementsListingController($scope, Announcements, AnnounncementsSearchUtils, $translate, AuthSrvc, ErrorSvrc) {
    var _this = this;

    _this.loadingAnnouncements = false;
    _this.announcementsPerPage = 5;
    _this.totalAnnouncements = 0;
    _this.currentAnnouncementsPage = 1;
    _this.announcements = [];
    _this.sectionData = null;


    _this.loadSectionData = function () {
        if (_this.searchParams && _this.sectionId) {
            Announcements.getSection(_this.sectionId, _this.searchParams, function (error, sectionData) {
                if (error) {
                    ErrorSvrc.showErrorModal(error);
                    return;
                }
                _this.sectionData = sectionData;
                _this.loadAnnouncements();
            });

        }
    };

    _this.loadAnnouncements = function () {

        _this.loadingAnnouncements = true;

        var finalSearchParams = angular.copy(_this.searchParams);

        finalSearchParams.fromAnnouncement = _this.currentAnnouncementsPage ? (_this.currentAnnouncementsPage - 1) * _this.announcementsPerPage : 0;
        finalSearchParams.toAnnouncement = finalSearchParams.fromAnnouncement + _this.announcementsPerPage;
        finalSearchParams.orderBy = _this.orderBy;


        _this.announcements = [];
        Announcements.getResume(_this.sectionId, finalSearchParams, function (err, result) {
            _this.loadingAnnouncements = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.totalAnnouncements = result.total;
                _this.announcements =  result.results;
                if (_this.announcements && _this.announcements.length > 0) {
                    _this.onClick(_this.announcements[0]);
                }
            }
        });
    };

    _this.onClick = function (announcement) {
        if (_this.onAnnouncementSelected) {
            _this.onAnnouncementSelected(announcement.id);
        }
    };

    _this.orderBy = 'RECENT';

    _this.orderTypes =  [
        { id: 'RECENT', label: $translate.instant('RECENT') },
        { id: 'OLDER', label: $translate.instant('OLDER') }
    ];

    _this.sortBy = function (orderType) {
        _this.orderBy = orderType;
    };




    _this.$onInit = function () {

        // Register to events


        ['selectedFilters', 'sectionId', 'orderBy', 'searchText'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                _this.searchParams =  AnnounncementsSearchUtils.compileParamsFromSearch(_this.selectedFilters, _this.searchText);
                _this.loadSectionData();
            }, true);
        });

        _this.searchParams =  AnnounncementsSearchUtils.compileParamsFromSearch(_this.selectedFilters, _this.searchText);
        _this.loadSectionData();
    };

    $scope.$on('announcementCreated', function (event, content) {
        _this.loadSectionData();
    });

    $scope.$on('announcementDeleted', function (event, announcementId) {
        _this.loadSectionData();
    });

    $scope.$on('announcementMoved', function (event, announcementId) {
        _this.loadSectionData();
    });

    $scope.$on('announcementUpdated', function (event, data) {
        if (data.statusChange)
            _this.loadSectionData();
    });

};

//Directive used to set metisMenu and minimalize button
const hfAnnouncementsListing = angular.module('happyForceApp')
    .directive('hfAnnouncementsListing', function () {
    return {
        scope: {
            sectionId: '<',
            selectedAnnouncementId: '<',
            selectedFilters: '<',
            onAnnouncementSelected: '<',
            searchText: '<'
        },
        transclude: true,
        replace: true,
        template: require('./announcements-listing.component.html'),
        controller: AnnouncementsListingController,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfAnnouncementsListing;
