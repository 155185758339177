'use strict';

const hfMinimezeSidebar = angular.module('happyForceApp')
    .directive('minimalizaSidebar', function ($timeout) {
        return {
            restrict: 'A',

            // jscs:disable maximumLineLength
            template: '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()"><i class="fa fa-bars"></i></a>',

            // jscs:enable maximumLineLength
            controller: function ($scope, $element) {
                $scope.minimalize = function () {
                    angular.element('#old_dashboard_link_box').toggleClass();
                    angular.element('body').toggleClass('mini-navbar');
                    if (!angular.element('body').hasClass('mini-navbar') || angular.element('body').hasClass('body-small')) {
                        // Hide menu in order to smoothly turn on when maximize menu
                        angular.element('#side-menu').hide();
                        angular.element('body').css('overflow', 'hidden', 'important');

                        // For smoothly turn on menu
                        $timeout(function () {
                            angular.element('#side-menu').fadeIn(500, function () {
                                var position = $('.nav.metismenu').position();
                                $('.nav.metismenu').height($(window).height() - position.top + 'px');
                            });
                        }, 100);
                    } else {
                        // Remove all inline style from jquery fadeIn function to reset menu state
                        angular.element('#side-menu').removeAttr('style');
                        angular.element('body').removeAttr('style');
                    }
                };
            }
        };
    });

export default hfMinimezeSidebar;
