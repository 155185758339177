'use strict';

import Numbers from '../../../../../utilities/number.utilities';
import {neutral600} from 'root/app/utilities/color.utilities';

function EngagementAnalyzeBoxChartCtrl($scope) {
    'ngInject';
    var _this = this;

    function renderChart() {

        var oneDayOffset = (3600 * 1000 * 24);

        var hasENPS = false;
        var hasHI = false;
        var hasScores = false;
        var series = [];

        var scoreSeriesStyles = [
            'ShortDot',
            'ShortDashDot',
            'ShortDashDotDot',
            'Dot', 'Dash', 'LongDash', 'DashDot', 'LongDashDot',
            'LongDashDotDot'];
        var addedTypeIds = [];


        /* DO a first pass to see if we have ENPS or HI and some scores
        * based on that, add some fake series to the chart, for the eNPS, HI and scores. Then, link them to the real series. Ex
        *   series.push({
            showInLegend: true,
            name: 'HI',
            color: 'black'
        });

        series.push({
            showInLegend: true,
            name: 'ENPS',
            color: 'black'
        });

        *
        *
        *
        * */

        /* Do a first pass to build some dummy series based on the types */




        _this.results.forEach(function (data) {
            var yAxisId = 'SCORES';
            var dashStyle =  'ShortDot';
            var groupId = data.group;

            if (data.id === 'HI') {
                yAxisId = 'HI';
                hasHI = true;
                dashStyle =  'Solid';

            } else if (data.id === 'ENPS') {
                yAxisId = 'ENPS';
                hasENPS = true;
                dashStyle =  'ShortDash';
            } else {
                hasScores = true;

                // Let's determine the dash style
                var dashStyleIndex = addedTypeIds.indexOf(data.id);
                dashStyleIndex = dashStyleIndex == -1 ? addedTypeIds.length : dashStyleIndex;
                dashStyle = scoreSeriesStyles[dashStyleIndex % scoreSeriesStyles.length];
            }

            // Add the added type id:
            var dummySerie = null;
            if (addedTypeIds.indexOf(data.id) == -1) {
                addedTypeIds.push(data.id);

                dummySerie = {
                    id: 'SERIE_' + data.id,
                    dashStyle: dashStyle,
                    color: 'black',
                    name: data.metricName,
                    yAxis: yAxisId,
                    type: 'line',
                    data: [],
                    marker: {
                        symbol: 'circle'
                    }
                };

                series.push(dummySerie);
            }

            var serieData = {
                id: groupId + '_' + data.id,
                dashStyle: dashStyle,
                color: data.color,
                name: data.groupName,
                metricName: data.metricName,
                yAxis: yAxisId,
                type: 'line',
                data: [],
                linkedTo:  'SERIE_' + data.id,

                marker: {
                    symbol: 'circle'
                }
            };

            // Let's parse the results
            data.results.forEach(function (result) {
                serieData.data.push({
                    x: Date.formatServerDateToTimestamp(result.date) + oneDayOffset,
                    y: (result.value ? Numbers.roundNumber(result.value, 1) : null)
                });
            });

            series.push(serieData);
        });

        /* Ok, for each added type, add some dummy series */




        _this.chartConfig   =  {
            options: {
                chart: {
                    marginTop: 30,
                    zoomType: 'xy',
                    fontFamily: 'Helvetica',
                    color: neutral600,
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal'
                },
                title: {
                    text: null
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%b \'%y',
                        second: '%b \'%y',
                        minute: '%b \'%y',
                        hour: '%b \'%y',
                        day: '%e %b',
                        week: '%b \'%y',
                        month: '%b \'%y',
                        year: '%b \'%y'
                    },
                    startOnTick: false,
                    title: {
                        enabled: false
                    }
                },
                yAxis: [
                    {
                        id: 'HI',
                        visible: hasHI,
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            y: -20,
                            x: -17,
                            text: 'HI'
                        },
                        min: 0,
                        max: 100,
                        tickAmount: 5
                    },
                    {
                        id: 'ENPS',
                        visible: hasENPS,
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            y: -20,
                            x: -10,
                            text: 'eNPS'
                        },
                        min: -100,
                        max: 100,
                        tickAmount: 5,
                        opposite: (hasHI && !hasScores)
                    },
                    {
                        id: 'SCORES',
                        visible: hasScores,
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            y: -20,
                            text: 'Scores'
                        },
                        opposite: (hasENPS || hasHI),
                        min: 0,
                        max: 10,
                        tickAmount: 5
                    }
                ],
                tooltip: {
                    shared: true,
                    stickyTracking: false,
                    snap: 5,
                    useHTML: true,
                    outside: true,

                    /*headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
                    pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
                        '<td style="text-align: right"><b>{point.y} {point.series.userOptions.metricName}</b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2*/
                    formatter: function () {

                        var groupByName = this.points.reduce(function (s, point) {
                            s[point.series.name] = s[point.series.name] || [];
                            s[point.series.name].push(point);
                            return s;
                        }, {});

                        var formatedDate = Highcharts.dateFormat('%e %b,%Y', this.x);


                        var header = '<div style="margin-bottom:4px">' + formatedDate + '</div><table>';

                        var body = '';
                        Object.keys(groupByName).forEach(function (key) {
                            const type = key;
                            const values = groupByName[key];
                            body += '<tr style=\'color:' + values[0].color + '\'><td colspan=2><b>' + type + '</b></td></tr>';

                            values.forEach(function (value) {
                                body += '<tr"><td><b>' + value.series.userOptions.metricName +
                                    ':&nbsp</b></td><td style="text-align: right">' + value.y + 'pts' + '</td></tr>';
                            });

                            body += '<tr><td colspan=2>&nbsp</td></tr>';





                        });


                        /*return this.points.reduce(function (s, point) {
                            return s + '<br/>' + point.series.name + ': ' +
                                point.y + 'm';
                        }, '<b>' + this.x + '</b>');*/

                        var footerFormat = '</table>';

                        return header  + body + footerFormat;
                    }

                },
                legend: {
                    symbolWidth: 40
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        connectNulls: true
                    }
                }
            },
            series: series
        };
    };

    _this.$onInit = function () {
        ['results'].forEach(function (prop) {
            $scope.$watch(angular.bind(_this, function () {
                return _this[prop];
            }), function (n, o) {

                if (!_.isEqual(n, o)) {
                    renderChart();
                };
            }, true);
        });

    };
};



const AnalyzeBoxChart = angular.module('happyForceApp').directive('hfEngagementAnalyzeBoxChart', function () {
    return {
        scope: {
            results: '='
        },
        restrict: 'E',
        template: require('./analyze_box_chart.html'),
        controller: EngagementAnalyzeBoxChartCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});


export default AnalyzeBoxChart;
