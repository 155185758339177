import QuestionsUtils from '../../utilities/question.utilities';
import {isMultiHierarchies} from '../../shared/services/migrated/hierarchy.service';
import EngagementReportModal
    from 'root/app/components/engagement/components/engagement_report_type_modal/engagement_report_type_modal.component';
import template
    from 'root/app/components/engagement/components/engagement_report_type_modal/engagement_report_type_modal.html';
import Style from 'root/app/utilities/style.utils';


const WizardTopicOperationsCtrl = function (AuthSrvc, $scope, Topics, Analytics, CompanySvrc, ErrorSvrc, $modalInstance, $translate, selectedTopic, addTopic, editTopic, deleteTopic, $compile, Settings, $uibModal, Characteristics, Questions, Groups) {
    'ngInject'
    var MAX_VALUES = 14;

    $scope.selectedTopic = selectedTopic;
    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';
    $scope.isDeleteTopic = deleteTopic;
    $scope.isEditTopic = editTopic;
    $scope.isAddTopic = addTopic;
    $scope.isActivateQuestion = false;
    $scope.hierarchyId = [];
    $scope.showSegments = false;
    $scope.hasHierarchies = false;
    $scope.showGroups = Groups.hasGroups();
    $scope.maxLength = 256;

    // Initialize default slider options
    $scope.minRangeValue = 2;
    $scope.maxRangeValue = 10;
    $scope.rangeMaxLabelLength = 30;
    $scope.activationRule = false;
    $scope.AuthSrvc = AuthSrvc;

    $scope.colors = Style.questionColors;

    // Enable the multiCheckbox if needed
    if ((selectedTopic.minSelection && selectedTopic.minSelection > 1) || (selectedTopic.maxSelection && selectedTopic.maxSelection > 1)) {
        selectedTopic.limitMinMaxAnswers = true;
    }

    CompanySvrc.getUserCompany(
        function (err, company) {
            if (err)
            {$scope.companyName = { companyName: company.name };}
            else
            {$scope.companyName = { companyName: $translate.instant('COMPANY') };}
        });

    if (AuthSrvc.isMANAGER()) {
        $scope.selectedTopic.sendAll = false;
    }

    $scope.slider = {
        value: $scope.minRangeValue,
        rangeFirstOption: '',
        rangeLastOption: '',
        options: {
            ceil: $scope.maxRangeValue,
            minLimit: $scope.minRangeValue,
            floor: 1,
            hidePointerLabels: true,
            hideLimitLabels: true,
            showSelectionBar: true,
            showTicks: true,
            showTicksValues: false
        }
    };

    if ($scope.isEditTopic && $scope.selectedTopic.questionType === 'RANGE') {
        // Hold on!, we are editting a RANGE topic, initialize values
        $scope.slider.rangeFirstOption = $scope.selectedTopic.values[0].label.defaultValue;
        $scope.slider.rangeLastOption = $scope.selectedTopic.values[$scope.selectedTopic.values.length - 1].label.defaultValue;
        $scope.slider.value =  $scope.selectedTopic.values.length;
    }

    var today = moment().toDate();
    var nextWeek = moment(today).add('days', 7).toDate();

    $scope.changeActivationRule = function () {
        if ($scope.activationRule) {
            selectedTopic.activationRule = {
                ruleType: 'SINGLE',
                from: today,
                to: nextWeek
            };
        } else {
            selectedTopic.activationRule = {
                ruleType: 'RECURRENT',
                startAt: today,
                cronExpression: '0 0 0 * * ?',
                duration: 1
            };
        }
    };

    if ($scope.isEditTopic) {
        $scope.blockRuleType = selectedTopic.activationRule.ruleType;
        if (selectedTopic.activationRule.ruleType === 'RECURRENT') {
            $scope.activationRule = true;
        }
    }

    $scope.yesterday = moment().subtract(1, 'd');

    Characteristics.getAll(function (err, data) {
        if (err) {
            console.error('Error loading Characteristics (topic.wizard.operation)', err);
        } else {
            $scope.showSegments = (data && data.length);
        }
    });

    isMultiHierarchies(function (err, hasHierarchies) {
        if (err) {
            console.error(err);
            $scope.hasHierarchies = false;
        } else {
            $scope.hasHierarchies = hasHierarchies;
        }
    });

    // WIZARD

    function findStep(classes) {
        if (!classes) {return;}
        var findStep = classes.match(/step-(\d)/);
        var actual = 1;
        if (findStep && findStep[1]) {
            actual = parseInt(findStep[1]);
        }

        return actual;
    }

    function validateStep() {
        var actualStep = findStep(angular.element('.modal-topics').attr('class'));
        var step = '.step-' + actualStep;

        angular.element(step + ' .error').removeClass('error');
        angular.forEach(angular.element(step + ' [required]'), function (item) {
            if (item.value === '') {
                var $item = angular.element(item);

                if ($item.parent().attr('class') === 'special-input-input') {
                    $item = $item.parent();
                }

                $item.addClass('error');
            }
        });

        if ($scope.selectedTopic.limitMinMaxAnswers) {
            var min = $scope.selectedTopic.minSelection;
            var max = $scope.selectedTopic.maxSelection;
            if (!min) {
                angular.element('#minSelection').addClass('error');
            }

            if (!max) {
                angular.element('#maxSelection').addClass('error');
            }
        }


        return (angular.element(step + ' .error').length === 0 && angular.element(step + ' .collission_error').length === 0);

    }

    $scope.wizardTitle = editTopic ? $translate.instant('EDIT_QUESTION') : $translate.instant('CREATE_QUESTION');
    $scope.wizardSubtitle = $translate.instant('QUESTION_SUBTITILE');

    $scope.wizardOptions = {
        nextButton: $translate.instant('QUESTION_NEXT'),
        prevButton: $translate.instant('QUESTION_PREV'),
        confirm: {
            text: editTopic ? $translate.instant('UPDATE_QUESTION') : $translate.instant('LAUNCH_QUESTION'),
            action: function () {
                if ($scope.executing) {return;}
                $scope.executing = true;
                if (editTopic)
                {$scope.updatedQuestion(function () {
                    $scope.executing = false;
                });}

                else
                {$scope.createQuestion(function () {
                    $scope.executing = false;
                });}
            }
        },
        return: {
            text: editTopic ? $translate.instant('DELETE') : $translate.instant('RETURN'),
            className: editTopic ? 'button-delete' : 'hf-secondary',
            action: function () {
                if (editTopic) {
                    if ($scope.executing) {return;}
                    $scope.executing = true;
                    $scope.deleteQuestion(function () {
                        $scope.executing = false;
                    });
                } else
                {$scope.goToQuestionSelector();}
            }
        },
        validate: validateStep,
        exit: function () {
            $scope.exit();
        }
    };

    function setStep(next) { // true --> +; false --> -
        var actualStep = findStep(angular.element('.modal-topics').attr('class'));
        $scope.stepActive = (next) ? actualStep + 1 : actualStep - 1;
    }

    $scope.intializeQuestion = function (questionType) {
        $scope.selectedTopic.questionType = questionType;
        if (typeof $scope.selectedTopic.sendAll === 'undefined')
        {$scope.selectedTopic.sendAll = true;}
        angular.element('.modal-topics').addClass('showWizard').addClass('step-1');
    };

    $scope.goToQuestionSelector = function () {
        angular.element('.modal-topics').removeClass('showWizard').removeClass('step-1');
    };

    //  / WIZARD
    function prepareTopic(originalTopic) {
        var rawTopic = angular.copy(originalTopic);

        var topic = {
            id: rawTopic.id,
            title: rawTopic.title,
            questionType: rawTopic.questionType,
            mandatory: rawTopic.mandatory,
            privated: rawTopic.privated,
            values: rawTopic.values,
            nonAnonymous: rawTopic.nonAnonymous,
            activationRule: rawTopic.activationRule
        };

        if (topic.activationRule.startAt)
        {topic.activationRule.startAt = moment(topic.activationRule.startAt).format(Settings.apiDateFormat);}

        if (topic.activationRule.from)
        {topic.activationRule.from = moment(topic.activationRule.from).format(Settings.apiDateFormat);}

        if (topic.activationRule.to)
        {topic.activationRule.to = moment(topic.activationRule.to).format(Settings.apiDateFormat);}

        if (topic.activationRule.duration === -1)
        {topic.activationRule.duration = 1;}

        // Hierarchies
        if (rawTopic.hierarchyIds) {
            topic.hierarchyIds = rawTopic.hierarchyIds;
        }

        topic.characteristics = rawTopic.characteristics;

        if (rawTopic.groupIds) {
            topic.groupIds = rawTopic.groupIds;
        }

        if (topic.questionType === 'OPEN') {
            topic.values = [];
            topic.requireNotEmpty = rawTopic.requireNotEmpty;
        } else if (topic.questionType === 'RANGE') {
            topic.values = [];
            topic.values.push({ id: 1, score: 1, label: { defaultValue: $scope.slider.rangeFirstOption }, color: Style.questionColors[0] });
            for (var i = 2; i < $scope.slider.value; i++) {
                topic.values.push({ id: i, score: i, label: { defaultValue: i }, color: Style.questionColors[i - 1] });
            }

            topic.feedbackEnabled = rawTopic.feedbackEnabled;

            topic.values.push({
                id: $scope.slider.value,
                score: $scope.slider.value,
                label: { defaultValue: $scope.slider.rangeLastOption },
                color: Style.questionColors[$scope.slider.value - 1]
            });
        } else if (topic.questionType === 'TOPIC') {
            topic.feedbackEnabled = rawTopic.feedbackEnabled;
            if (rawTopic.limitMinMaxAnswers) {
                topic.minSelection = rawTopic.minSelection;
                topic.maxSelection = rawTopic.maxSelection;
            } else {
                topic.minSelection = 1;
                topic.maxSelection = 1;
            }
        }

        if (topic.sendAll) {
            topic.hierarchyIds = [];
            topic.groupIds = [];
            topic.characteristics = [];
        }

        return topic;
    }

    $scope.getGroupName = function (groupId) {
        return Groups.getGroupName(groupId);
    };

    function prepareResumen(topic) {
        // Dates
        if (topic.activationRule.ruleType === 'SINGLE') {
            topic.from = topic.activationRule.from;
            topic.to = topic.activationRule.to;
        } else if (topic.activationRule.ruleType === 'RECURRENT') {
            topic.from = topic.activationRule.startAt;
            topic.to = topic.activationRule.endAt;
        }

        if (topic.groupIds && !(topic.groupIds instanceof Array)) {
            topic.groupIds = [topic.groupIds];
        }

        if (topic.hierarchyIds) {
            topic.resumeHierarchyIds = topic.hierarchyIds;
        } else {
            delete topic.resumeHierarchyIds;
        }

        topic.rangeLabel = $translate.instant('QUESTION_RANGE_RESUME_VALUES', { initialValue: '1',  endValue: $scope.slider.value });

        return topic;
    }

    // Watch de selectedTopic
    $scope.$watch('selectedTopic', function (n, o) {
        if (!n || n === o) {return;}

        var $toDate = $('input[view]').last();
        if (n.activationRule.from && n.activationRule.from.isAfter) {
            if (n.activationRule.from.isAfter(n.activationRule.to)) {
                $toDate.addClass('collission_error');
            } else {
                $toDate.removeClass('collission_error');
            }
        }

        //if (n.groupIds && n.groupIds.length)
        //    n.groupIds = n.groupIds[0];

        if (!n.mandatory) {
            n.mandatory = false;
        }

        $scope.resumen = prepareResumen(n);
    }, true);

    $scope.$watch('slider', function (n, o) {
        prepareResumen($scope.selectedTopic);
    }, true);

    $scope.resumen = prepareResumen($scope.selectedTopic);

    $scope.createQuestion = function (callback) {
        var preparedTopic = prepareTopic($scope.selectedTopic);

        Questions.create(preparedTopic, function (err, data) {
            callback();
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $modalInstance.close(data);
            }
        });
    };

    $scope.updatedQuestion = function (callback) {
        var preparedTopic = prepareTopic($scope.selectedTopic);

        Questions.update(preparedTopic, function (err, data) {
            callback();
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $modalInstance.close(data);
            }
        });
    };

    $scope.deleteQuestion = function (callback) {
        Questions.remove($scope.selectedTopic.id, function (err, data) {
            callback();
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $modalInstance.close('delete');
            }
        });
    };

    $scope.exit = function () {
        $modalInstance.dismiss('close');
    };

    $scope.cronConfig = {
        options: {
            allowMinute: false,
            allowHour: false
        },
        allowMultiple: true,
        quartz: true
    };

    //===========================//

    $scope.dateFormat = $translate.instant('DATE_FORMAT');
    if ($scope.dateFormat === 'DATE_FORMAT')
    {$scope.dateFormat = Settings.defaultDateFormat;}

    $scope.isActivateQuestion = function () {

        $scope.isActivateQuestion = true;
    };

    function processTopic(topic) {
        var startDate = moment($scope.selectedTopic.date.startDate);
        var endDate = moment($scope.selectedTopic.date.endDate);
        var offset = startDate.utcOffset();
        topic.fromDate = startDate.add(offset, 'm').toDate();
        topic.toDate = endDate.add(offset, 'm').toDate();

        return topic;
    }

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.deleteValue = function (value, index) {
        $scope.selectedTopic.values =  _.without($scope.selectedTopic.values, $scope.selectedTopic.values[index]);
        var counter = $scope.selectedTopic.values.length;
        _.forEach($scope.selectedTopic.values, function (value) {
            value.id = counter;
            counter = counter - 1;
        });

        if ($scope.selectedTopic.values.length > ($scope.selectedTopic.minSelection - 1)) {
            $scope.selectedTopic.minSelection = $scope.selectedTopic.values.length - 1;
        }

        if ($scope.selectedTopic.values.length > ($scope.selectedTopic.maxSelection - 1)) {
            $scope.selectedTopic.maxSelection = $scope.selectedTopic.values.length - 1;
        }

        $scope.colors.push(value.color);

        refreshColors();
    };

    $scope.addValue = function () {
        if (typeof $scope.selectedTopic.values === 'undefined') {
            $scope.selectedTopic.values = [];
        }

        if ($scope.selectedTopic.values.length > MAX_VALUES) {return;}

        $scope.selectedTopic.values.push({ id: 1, label: { defaultValue: '' } });
        refreshColors();
    };

    $scope.selectColor = function (value, color) {
        if (value.color)
        {$scope.colors.push(value.color);}

        value.color = color;
        $scope.colors = _.without($scope.colors, color);
    };

    function refreshColors() {
        if (!$scope.selectedTopic.values) {return;}

        $scope.selectedTopic.values.map(function (value, index) {
            value.id = index + 1;
            value.color = value.color || $scope.colors[0];
            $scope.colors = _.without($scope.colors, value.color);
        });
    }

    refreshColors();


    $scope.blockUnblockQuestion = function () {
        $modalInstance.dismiss('blockUnblock');
    };

    $scope.deleteTopic = function () {
        $modalInstance.dismiss('delete', $scope.selectedTopic);
    };

    $scope.getTopicLetter = function (index) {
        return QuestionsUtils.getTopicLetter(index);
    };

}


const TopicOperationsModal = angular.module('happyForceApp')
    .factory('TopicOperationsModal',
        function ($uibModal, $translate, Analytics, ErrorSvrc, toaster, Questions) {
            'ngInject';
            var methods = {};

            methods.showCreateQuestionWizard = function (callback) {

                var today = moment().toDate();
                var nextWeek = moment(today).add('days', 7).toDate();

                $uibModal.open({
                    backdrop: 'static',
                    template: require('./modals/wizard.html'),
                    controller: WizardTopicOperationsCtrl,
                    size: 'topics add',
                    resolve: {
                        selectedTopic: function () {

                            var preparedTopic = {
                                title: {
                                    defaultValue: ''
                                },
                                questionType: 'OPEN',
                                hierarchyIds: [],
                                characteristics: [],
                                groupIds: [],
                                mandatory: true,
                                minSelection: 1,
                                maxSelection: 1,
                                feedbackEnabled: true,
                                values: [
                                    { id: 2, value: '', color: QuestionsUtils.getTopicColor(1) },
                                    { id: 1, value: '', color: QuestionsUtils.getTopicColor(2) }
                                ]
                            };

                            preparedTopic.activationRule = {
                                ruleType: 'SINGLE',
                                from: today,
                                to: nextWeek
                            };

                            return preparedTopic;
                        },

                        addTopic: function () {
                            return true;
                        },

                        editTopic: function () {
                            return false;
                        },

                        deleteTopic: function () {
                            return false;
                        }
                    }
                }).result.then(function (data) {
                    toaster.pop('success', null, ($translate.instant('QUESTION_CREATED')));
                    if (callback) {
                        callback(null, data);
                    }
                });
            };



            methods.editQuestion = function (originalQuestion, callback) {
                Analytics.trackEvent('topics', 'edit');

                $uibModal.open({
                    backdrop: 'static',
                    template: require('./modals/wizard.html'),
                    controller: WizardTopicOperationsCtrl,
                    size: 'topics edit showWizard step-1',
                    resolve: {
                        selectedTopic: function () {

                            var topic = angular.copy(originalQuestion);

                            if (!topic.values || topic.questionType === 'OPEN')
                            {
                                topic.values = [];
                            }

                            if (topic.activationRule.ruleType === 'SINGLE') {
                                topic.activationRule = {
                                    ruleType: 'SINGLE',
                                    from: moment.utc(topic.activationRule.from, 'DDMMYYYY').toDate(),
                                    to: moment.utc(topic.activationRule.to, 'DDMMYYYY').toDate()
                                };
                            } else if (topic.activationRule.ruleType === 'RECURRENT') {
                                topic.activationRule.startAt = moment.utc(topic.activationRule.startAt, 'DDMMYYYY').toDate();
                            }

                            return topic;
                        },

                        addTopic: function () {
                            return false;
                        },

                        editTopic: function () {
                            return true;
                        },

                        deleteTopic: function () {
                            return false;
                        }
                    }
                }).result.then(function(editedTopic) {
                    if (!editedTopic) {return;}

                    if (editedTopic === 'delete') {
                        toaster.pop('success', null, ($translate.instant('QUESTION_DELETE_SUCCESS')));
                        Analytics.trackEvent('topics', 'edit', 'deleted');
                    } else {
                        toaster.pop('success', null, ($translate.instant('QUESTION_UPDATED')));
                        Analytics.trackEvent('topics', 'edit', 'success');
                    }

                    if (callback) {
                        callback(editedTopic);
                    }
                });
            };


            methods.archiveQuestion = function (questionId, callback) {

                swal({
                    title: $translate.instant('QUESTION_ARCHIVE_CONFIRM_TITLE'),
                    text: $translate.instant('QUESTION_ARCHIVE_CONFIRM_TEXT'),
                    icon: 'warning',
                    buttons: {
                        cancel: {
                            text: $translate.instant('QUESTION_ARCHIVE_CONFIRM_BUTTON_CANCEL'),
                            value: null,
                            visible: true,
                            className: '',
                            closeModal: true
                        },
                        confirm: {
                            text: $translate.instant('QUESTION_ARCHIVE_CONFIRM_BUTTON_OK'),
                            value: true,
                            visible: true,
                            className: '',
                            closeModal: true
                        }
                    }
                }).then(function (archived) {
                    if (archived) {
                        Questions.archive(questionId, function (err, data) {
                            if (err) {
                                Analytics.trackEvent('topics', 'archive', 'fail');
                                ErrorSvrc.showErrorModal(err);
                            } else {
                                Analytics.trackEvent('topics', 'archive', 'success');
                                toaster.pop('success', null, ($translate.instant('QUESTION_UPDATED')));
                                if (callback) {
                                    callback();
                                }
                            }
                        });
                    }
                });
            };

            methods.changeActiveState = function (question, callback) {

                Questions.CRUD.CHANGEACTIVE(question.id, (question.status === 'PAUSED'))
                    .then(function (updatedTopic) {
                        if (updatedTopic.status === 'PAUSED') {
                            toaster.pop('success', null, ($translate.instant('QUESTION_PAUSED')));
                        } else {
                            toaster.pop('success', null, ($translate.instant('QUESTION_RESUMED')));
                        }
                        Analytics.trackEvent('topics', 'change_state');
                        if (callback) {
                            callback();
                        }
                    }, function (err) {
                        ErrorSvrc.showErrorModal(err);
                    });
            };


            return methods;
        }
    );

export default TopicOperationsModal;
