

import Style from '../../../../utilities/style.utils';

function FeedbackTransparencyConfigCtrl($scope, $translate, Analytics) {
    'ngInject'

    var _this = this;

    Analytics.trackEvent('settings_feedback_transparency_config');


    $scope.updateFeedbackLevel = function () {
        if ($scope.feedbackLevel === 1) {
            $scope.feedbackLevelImg = '/images/hf_no_feedback.png';
            $scope.feedbackLevelLabel = $translate.instant('FEEDBACK_MATURITY_NO_FEEDBACK');
            $scope.feedbackLevelDescriptionLabel = $translate.instant('FEEDBACK_MATURITY_NO_FEEDBACK_DESCRIPTION');
            $scope.feedbackConfiguration.feedbackLevelMode = 'NO_FEEDBACK';
        } else if ($scope.feedbackLevel === 2) {
            $scope.feedbackLevelImg = '/images/hf_messages.png';
            $scope.feedbackLevelLabel = $translate.instant('FEEDBACK_MATURITY_CLOSED');
            $scope.feedbackLevelDescriptionLabel = $translate.instant('FEEDBACK_MATURITY_CLOSED_DESCRIPTION');
            $scope.feedbackConfiguration.feedbackLevelMode = 'ALL_CLOSED';
        } else if ($scope.feedbackLevel === 3) {
            $scope.feedbackLevelImg = '/images/hf_feedback.png';
            $scope.feedbackLevelLabel = $translate.instant('FEEDBACK_MATURITY_OPEN_ON_REPLY');
            $scope.feedbackLevelDescriptionLabel = $translate.instant('FEEDBACK_MATURITY_OPEN_ON_REPLY_DESCRIPTION');
            $scope.feedbackConfiguration.feedbackLevelMode = 'ANONYMOUS_ON_REPLY';
        } else {
            $scope.feedbackLevelImg = '/images/hf_people_working.png';
            $scope.feedbackLevelLabel = $translate.instant('FEEDBACK_MATURITY_OPEN');
            $scope.feedbackLevelDescriptionLabel = $translate.instant('FEEDBACK_MATURITY_OPEN_DESCRIPTION');
            $scope.feedbackConfiguration.feedbackLevelMode = 'ANONYMOUS_OPEN';
        }
    };

    $scope.$watch('feedbackLevel', function (n, o) {
        $scope.updateFeedbackLevel();
    }, true);


    $scope.slider = {
        value: $scope.feedbackLevel,
        rangeFirstOption: '',
        rangeLastOption: '',
        options: {
            ceil: 4,
            minLimit: 0,
            floor: 1,
            hidePointerLabels: true,
            hideLimitLabels: true,
            showSelectionBar: true,
            showTicks: true,
            showTicksValues: false,
            stepsArray: [
                { value: 1, legend: $translate.instant('COMPANY_FEEDBACK_NO_FEEDBACK') },
                { value: 2, legend: $translate.instant('COMPANY_FEEDBACK_CLOSED') },
                { value: 3, legend: $translate.instant('COMPANY_FEEDBACK_OPEN_ON_REPLY') },
                { value: 4, legend: $translate.instant('COMPANY_FEEDBACK_OPEN') }
            ],
            getPointerColor: function (value) {
                if (value === 1)
                {return Style.noData;}
                if (value === 2)
                {return Style.bad;}
                if (value === 3)
                {return Style.warning;}
                if (value === 4)
                {return Style.good;}
            }
        }
    };


    setTimeout(function () {
        $(window).trigger('resize');
    }, 500);
}

const hfFeedbackTransparencyConfig = angular.module('happyForceApp')
    .directive('hfFeedbackTransparencyConfig', function () {
        return {
            template: require('./feedback_transparency_config.html'),
            controller: FeedbackTransparencyConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackTransparencyConfig;
