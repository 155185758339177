'use strict';
const getSettingsSections = function ($translate) {
    return [
        {
            name: $translate.instant('SETTINGS_COMPANY'),
            statusCode: 'basicOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_COMPANY_BASIC_INFO'),
                    code: 1
                },
                {
                    name: $translate.instant('SETTINGS_COMPANY_LOCALIZATION_TIMEZONE'),
                    code: 2
                }
            ]
        },
        {
            name: $translate.instant('SETTINGS_SEGMENTATION_AND_PROFILE'),
            statusCode: 'segmentationOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_SEGMENTATION_AND_PROFILE_HIERARCHY'),
                    code: 3
                },
                {
                    name: $translate.instant('SETTINGS_SEGMENTATION_AND_PROFILE_SEGMENTS'),
                    code: 4
                },
                {
                    name: $translate.instant('SETTINGS_SEGMENTATION_AND_PROFILE_GROUPS'),
                    code: 5
                }
            ]
        },
        {
            name: $translate.instant('SETTINGS_MEASURE'),
            statusCode: 'measureOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_MEASURE_HI'),
                    code: 6
                },
                {
                    name: $translate.instant('SETTINGS_MEASURE_ENPS'),
                    code: 29
                },
                {
                    name: $translate.instant('SETTINGS_MEASURE_SCORES'),
                    code: 19
                },
                {
                    name: $translate.instant('SETTINGS_MEASURE_TRANSPARENCY'),
                    code: 7
                }
            ]
        },
        {
            name: $translate.instant('SETTINGS_FEEDBACK_TYPES'),
            statusCode: 'feedbackOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_RECOGNITION'),
                    code: 12
                },
                {
                    name: $translate.instant('FEEDBACK_TYPE_RESULT_CONGRATULATION'),
                    code: 26
                },
                {
                    name: $translate.instant('FEEDBACK_TYPE_RESULT_SUGGESTION'),
                    code: 25
                },
                {
                    name: $translate.instant('FEEDBACK_TYPE_RESULT_CRITICISM'),
                    code: 27
                },
                {
                    name: $translate.instant('FEEDBACK_TYPE_RESULT_INFORMATION'),
                    code: 28
                }
            ]
        },
        {
            name: $translate.instant('SETTINGS_FEEDBACK'),
            statusCode: 'feedbackOtherSettings',
            childs: [

                {
                    name: $translate.instant('SETTINGS_FEEDBACK_SIGNATURE_AND_REPLIES'),
                    code: 10
                }

            ]
        },
        {
            name: $translate.instant('SETTINGS_COMMUNICATE'),
            statusCode: 'communicateOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_COMMUNICATE_ANNOUNCEMENTS'),
                    code: 21
                }
            ]
        },
        {
            name: $translate.instant('SETTINGS_EMPLOYEE_PROVISIONING'),
            statusCode: 'employeeProvisioningOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_EMPLOYEE_AUTO_INVITE'),
                    code: 13
                }
            ]
        },

        {
            name: $translate.instant('SETTINGS_INTEGRATION'),
            statusCode: 'integrationOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_API_INTEGRATION'),
                    code: 14
                },

                {
                    name: $translate.instant('SETTINGS_WIDGET_INTEGRATION'),
                    code: 20
                }
            ]
        },
        {
            name: $translate.instant('SETTINGS_NOTIFICATIONS_AND_MAILS'),
            statusCode: 'notificationsOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_NOTIFICATIONS_AND_MAILS_CUSTOMIZATION'),
                    code: 24
                },
                {
                    name: $translate.instant('SETTINGS_NOTIFICATIONS_AND_MAILS_ALERT_AND_REMINDERS'),
                    code: 15
                },
                {
                    name: $translate.instant('SETTINGS_NOTIFICATIONS_AND_MAILS_ENGAGEMENT_MAILS'),
                    code: 17
                },
                {
                    name: $translate.instant('SETTINGS_QUESTIONS_AND_ANNOUNCEMENTS'),
                    code: 16
                }
            ]
        },

        {
            name: $translate.instant('SETTINGS_BILLING'),
            statusCode: 'billingOpen',
            childs: [
                {
                    name: $translate.instant('SETTINGS_BILLING_SUBSCRIPTION_AND_INFO'),
                    code: 22
                }
            ]
        },


        {
            name: 'Advisors',
            onlyAdvisors: true,
            statusCode: 'advisorsIsOpen',
            childs: [
                {
                    name: 'Advisors',
                    code: 18
                },
                {
                    name: $translate.instant('SETTINGS_FEEDBACK_TRANSPARENCY'),
                    code: 11
                },
                {
                    name: $translate.instant('SETTINGS_HRSI_INTEGRATION'),
                    code: 23
                }
            ]
        }
    ];
};

export default getSettingsSections;
