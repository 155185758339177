'use strict';

import Numbers from "../../../utilities/number.utilities";
import {getActivation} from "../../../shared/services/migrated/activation.service";

function PrelaunchCtrl($scope, CompanyStatus, CompanySvrc, $timeout) {
    'ngInject';
    var _this = this;

    this.$onInit = function () {

        // Monitor if the company has enough active employees if we are not in launched status
        _this.notEnoughActiveEmployeesPrelaunch = CompanyStatus.notEnoughActiveEmployeesPrelaunch;

        CompanySvrc.getUserCompany(
            function (err, company) {
                if (err)
                    _this.companyName = translate.instant('COMPANY');
                else
                    _this.companyName = company.name;
            }
        );


        function monitorActivatedEmployees() {

            getActivation({ to: new Date() }, function (err, result) {
                if (err) {
                    _this.activatedEmployees = 0;
                    _this.activatedPercentEmployees = 0;
                    $timeout(monitorActivatedEmployees, 5000);
                } else {
                    _this.activatedEmployees = result.activated;
                    _this.activatedPercentEmployees = Numbers.roundNumber
                    (Numbers.calculatePercent(_this.activatedEmployees, _this.minActiveEmployees), 0);
                    if (_this.activatedPercentEmployees < 100) {
                        $timeout(monitorActivatedEmployees, 5000);
                    }
                }
            });
        }

        $scope.$watch(
            function () {
                return CompanyStatus.notEnoughActiveEmployeesPrelaunch;
            },
            function (newVal, oldVal) {
                _this.notEnoughActiveEmployeesPrelaunch = newVal;
                if (_this.notEnoughActiveEmployeesPrelaunch) {
                    CompanySvrc.getConfigKey('enableAutoInvite', function (err, result) {
                        if (!err) {
                            _this.enableAutoInvite = result;
                        }
                    });
                    CompanySvrc.getConfigKey('autoInviteToken', function (err, result) {
                        if (!err) {
                            _this.autoInviteToken = result;
                        }
                    });
                    CompanySvrc.getConfigKey('minActiveEmployees', function (err, result) {
                        if (!err) {
                            _this.minActiveEmployees = result;
                            monitorActivatedEmployees();
                        }
                    });
                }

            }
        );
    };
}

// TODO: Hace módulo shared
const PrelaunchWarningComponent = angular.module('happyForceApp').directive('hfPrelaunchWarning', function () {
    return {
        scope: {
            level: '=',
            dates: '='
        },
        restrict: 'E',
        template: require('./pre-launch.html'),
        controller: PrelaunchCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default PrelaunchWarningComponent;
