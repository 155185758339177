'use strict';

import GroupAddModal from './components/group_add/group_add.controller';
import GroupEditModal from './components/group_edit/group_edit.controller';

function GroupsConfigCtrl(GroupAddModal, GroupEditModal, Groups, $translate, HFController, Analytics) {
    'ngInject'
    var _this = this;

    _this.addGroup = function () {
        GroupAddModal.showModal(function (newGroup) {
            if(newGroup){
                loadGroups();
            }
        });
    };

    _this.editGroup = function (group)
     {
        GroupEditModal.showModal(angular.copy(group), function (groupUpdated) {
            if(groupUpdated){
                loadGroups();
            }
        });
    };

    var loadGroups = function () {
        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            HFController.publish('hf-settings-groups', groups);
            _this.groups = groups.map(function (group) {

                group.exitPoliciesLabels = group.exitPolicies.map(function (exitPolicy) {
                    var icons = {
                        ELAPSED_DAYS: 'fa-calendar',
                        ALL_QUESTIONS: 'fa-tags',
                        NONE: null
                    };

                    var obj = {
                        label: $translate.instant(exitPolicy.policyType, { days: exitPolicy.days || null })
                    };

                    obj.ico = icons[exitPolicy.policyType];

                    if (exitPolicy.exitAction && exitPolicy.exitAction.actionType && exitPolicy.exitAction.actionType !== 'NONE') {
                        if (exitPolicy.exitAction.actionType === 'MOVE_TO_GROUP') {
                            obj.action = $translate.instant('GROUP_MOVE_TO_GROUP') + Groups.getGroupName(exitPolicy.exitAction.targetGroup);
                        } else {
                            obj.action = $translate.instant(exitPolicy.exitAction.actionType);
                        }
                    }

                    return obj;
                });

                return group;
            });
        });
    };

    loadGroups();
    Analytics.trackEvent('settings_company_groups');
}

const groupsConfig = angular.module('happyForceApp')
    .directive('groupsConfig', function () {
        return {
            template: require('./groups_config.html'),
            controller: GroupsConfigCtrl,
            controllerAs: 'ctrl',
            bindToController: true
        };
    });

export default groupsConfig;