'use strict'
import hfFeedbackIdeasRowItem from "./components/feedback-idea-row.controller";



function FeedbackIdeasListingCtrl($scope, SegmentsFilterService, ErrorSvrc, FeedbackStatusService, FeedbackIdeasService, $translate) {
    'ngInject';

    var _this = this;

    _this.currentIdeasPage = 1;
    _this.totalIdeas = 0;
    _this.exportingExcelReport = false;
    _this.order_by = 'RECENT';
    _this.dateFormat = $translate.instant('DATE_FORMAT');
    _this.hasStatuses = true;
    _this.hasEnoughActiveEmployees = true;
    _this.searchText = null;

    function prepareParams() {

        var params = {};

        if (_this.filters['from']) {
            params.from = _this.filters['from'];
        }
        if (_this.filters['to']) {
            params.to = _this.filters['to'];
        }

        if (_this.filters.groups && _this.filters.groups.length)
            params.groups = _this.filters.groups;

        if (_this.filters.hierarchyIds && _this.filters.hierarchyIds.length) {
            params.hierarchyId = _this.filters.hierarchyIds.join(',');
        }

        if (_this.filters.segments && _this.filters.segments.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.filters.segments);
        }

        if (_this.order_by != null) {
            params.sortOrder = _this.order_by;
        }

        if (_this.searchText != null) {
            params.commentText = _this.searchText;
        }

        if (_this.initiativeId) {
            params.containerId = _this.initiativeId;
        }

        // Set the pagination to the params
        params.fromComment = (_this.currentIdeasPage - 1) * 10;
        params.toComment = 10;

        return params;
    };



    // Main method loading calling to the API for load the ideas
    _this.loadIdeasFeedback = function () {
        var params = prepareParams();
        _this.loadingData = true;
        FeedbackIdeasService.list(params, function (err, results) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.ideas = results.comments;
                _this.totalIdeas = results.total;
            }
            _this.loadingData = false;
        });
    };

    _this.onIdeaUpdated = function () {
        _this.loadIdeasFeedback();
    };

    // Just a helper method to export to Excel and generate reports
    _this.exportToExcel = function () {
        // TODO:
    };




    this.$onInit = function () {

        // Also check if there are statuses defined for this type
        FeedbackStatusService.checkTypeHasStatuses('SUGGESTION', function (err, hasStatuses) {
            if (err)
                ErrorSvrc.showErrorModal(err);
            else {
                _this.hasStatuses = hasStatuses;
            }
        });

        var pageArgs = ['filters', 'searchText', 'initiativeId', 'currentIdeasPage', 'order_by'];
        var lastExecution;
        pageArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (newVal === oldValue) return;
                if (lastExecution) {
                    // Just delay a little bit the request, in case the user is typing.
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }
                lastExecution = moment();
                _this.loadIdeasFeedback();
            }, true);
        });

        _this.loadIdeasFeedback();
    };






}

const hfFeedbackIdeasListing = angular.module('happyForceApp')
    .directive('hfFeedbackIdeasListing', function () {
        return {
            template: require('./feedback-ideas-listing.html'),
            controller: FeedbackIdeasListingCtrl,
            scope: {
                filters: '<',
                initiativeId: '<'
            },
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeasListing;
