import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import Style from "../../../../../utilities/style.utils";

import {getENPSClassification} from "../../../../../shared/services/migrated/enps.service";
import ClassificationFlowChart from "../../../shared/classification_flow_chart/classification_flow_chart";
import {Alert, Typography} from "@mui/material";
import VotesChart from "../../../shared/votes_chart/votes_chart.component";
import EmptyState from "../../../../../shared/components/_migrated/empty_state/empty_state";
import Dates from "../../../../../utilities/date.utilities";
import Numbers from "../../../../../utilities/number.utilities";
import {HfBannerReact} from "../../../../../shared/new-components/hf-banner/hfBanner";
import HappyLoading from "../../../../../shared/components/_migrated/loader/loading.directive";

const categories = [
    {
        category: 'DETRACTORS',
        color: Style.bad,
        last: false
    },
    {
        category: 'PASSIVES',
        color: Style.warning,
        last: false
    },
    {
        category: 'PROMOTERS',
        color: Style.good,
        last: true
    }
];

const ENPSFunnelBox = ({ to, level, filters, groups, dateGrouping, currentClassification }) => {


    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);
    const [distributionValues, setDistributionValues] = useState(null);
    const [total, setTotal] = useState(null);
    const [flowValues, setFlowValues] = useState(null);
    const [valuation, setValuation] = useState(null);


    const ErrorSvrc = getService('ErrorSvrc'); // You'll need to provide a React context or another solution for this service

    // You should replace this with your actual translation function
    const $translate = getService('$translate');

    // Implement these functions based on their AngularJS counterparts
    const processDistribution = (results) => {
        if (!results) {
            return;
        }

        const { DETRACTORS=0, PASSIVES=0, PROMOTERS=0 } = results;
        setTotal(DETRACTORS + PASSIVES + PROMOTERS);
        const total = DETRACTORS + PASSIVES + PROMOTERS;

        const distributionValues = [
            {
                value: DETRACTORS,
                color: Style.bad
            },
            {
                value: PASSIVES,
                color: Style.warning
            },
            {
                value: PROMOTERS,
                color: Style.excellent
            }
        ];

        return distributionValues;
    };


    const buildArrowElement = (funnelItem) => {
        return {
            count: funnelItem.count,
            sourceLabel: $translate.instant(`ENPS_${funnelItem.source}`),
            targetLabel: $translate.instant(`ENPS_${funnelItem.target}`)
        };
    };

    const processCategory = (results, categoryInfo) => {
        const result = {
            category: {
                type: 'category',
                label: $translate.instant(`ENPS_${categoryInfo.category}`),
                number: results.classification[categoryInfo.category],
                color: categoryInfo.color
            },
            arrow: null
        };

        const flowCount = results.funnel
            .filter(f => f.source === categoryInfo.category || f.target === categoryInfo.category)
            .reduce((acc, f) => acc + f.count, 0);

        const flowItems = results.funnel
            .filter(f =>
                (f.source === categoryInfo.category && f.count !== 0) ||
                (f.target === categoryInfo.category && f.count !== 0))
            .map(buildArrowElement);

        if (!categoryInfo.last) {
            result.arrow = {
                type: 'arrow',
                flow: flowCount,
                flowItems: flowItems
            };
        }

        return result;
    };

    const loadResults = () => {
        if (!to) {
            return;
        }
        setLoading(true);
        const toFormatted = Dates.lastDayOfMonth(to);
        const params = {
            to: toFormatted,
            filters,
            groupId: groups,
            grouping: dateGrouping,
        };

        if (level) {
            params.hierarchyId = level.id;
        }

        getENPSClassification(params, function (err, response) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                console.error('ERROR getting ENPS Stats', err);
            } else if (response.quality === 'NO_DATA') {
                    setNoData(true);
                    setDistributionValues(null);
                    setFlowValues(null);
                    setValuation(null);
                    setHasEnoughActiveEmployees(false);
            } else {
                // Process the classification data
                const distributionValues = processDistribution(currentClassification);


                // Process the funnel data
                var results = [];
                categories.forEach(function (type) {
                    var result = processCategory(response, type);
                    results.push(result.category);
                    if (result.arrow) {
                        results.push(result.arrow);
                    }
                });

                setDistributionValues(distributionValues);
                setFlowValues(results);
                setValuation(response.valuation);
                setHasEnoughActiveEmployees(true);
                setNoData(false);
            }
            setLoading(false);
        });
    };


    useEffect(() => {
        loadResults();
    }, [to, level, filters, groups, dateGrouping, currentClassification]);



    return (

        <HappyLoading loading={loading}>
            {noData ? (
                <div className="row">
                    <div className="col-xs-12">
                        <EmptyState
                            image="images/empty_state_chart.png"
                            small={false}
                            message={$translate.instant('SCORE_NO_DATA')}
                            submessage={$translate.instant('SCORE_NO_DATA_DESCRIPTION')}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-xs-12">
                            {flowValues && <ClassificationFlowChart values={flowValues}/>}
                        </div>
                    </div>
                    <div className="row p-t">
                        <div className="col-xs-12">
                            <HfBannerReact type={'primary'}>
                                <Typography variant="h6" component="div">
                                    {$translate.instant('FLOW_DESCRIPTION_' + valuation)}
                                </Typography>

                            </HfBannerReact>

                        </div>
                    </div>
                    <div className="row p-t">
                        <div className="col-xs-12">
                            <div className="small-style">{$translate.instant('ENPS_DISTRIBUTION_OVERALL')}</div>
                            <div className="mini-style warm-grey m-t-xs m-b-xs">{$translate.instant('ENPS_DISTRIBUTION_OVERALL_DESCRIPTION')}</div>
                            {distributionValues && <VotesChart values={distributionValues}  totalVotes={total}  />}
                        </div>
                    </div>
                </>
            )}
        </HappyLoading>
    );
};

angularize(ENPSFunnelBox, 'enpsFunnelBox', angular.module('happyForceApp'), {
    to: '=',
    level: '=',
    filters: '=',
    groups: '=',
    dateGrouping: '<',
    currentClassification: '<'
});

export default ENPSFunnelBox;
