'use strict';

function CommentTypeHandlerCtrl() {
    'ngInject';
    var _this = this;


    _this.$onInit = function () {

    };
}

const hfCommentTypeHandler = angular.module('happyForceApp').directive('hfCommentTypeHandler', function () {
    return {
        scope: {
            comment: '<',
            showConversationLink: '<?',
            showModerateLink: '<?',
            showReplyWithAnnouncementLink: '<?',
            showRepliesAction: '<',
            showModerateAction: '<',
            size: '<'
        },
        restrict: 'E',
        template: require('./comment-type-handler.html'),
        controller: CommentTypeHandlerCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});


export default hfCommentTypeHandler;
