const ManagersRankingService = function (Restangular, $translate, Settings) {
    'ngInject';
    var ManagersRanking = Restangular.one('company').one('ranking');

    var methods = {};

    function prepareParams(params) {
        var finalParams = angular.copy(params);
        if (params.from)
            finalParams.from = moment(params.from).format(Settings.apiDateFormat);
        if (params.to)
            finalParams.to = moment(params.to).format(Settings.apiDateFormat);

        return finalParams;
    }

    methods.get = function (params, callback) {

        var finalParams = prepareParams(params);

        ManagersRanking.one('managers').get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.exportToExcel = function (params, callback) {
        var finalParams = prepareParams(params);

        ManagersRanking.one('managers').one('export').get(finalParams).then(function (results) {
            callback(null, results);
        }, function (err) {
            callback(err);
        });
    };

    methods.groupingTypes = [
        { id: 'WEEK', label: $translate.instant('DATE_GROUPING_WEEK') },
        { id: 'MONTH', label: $translate.instant('DATE_GROUPING_MONTH') },
        { id: 'QUARTER', label: $translate.instant('DATE_GROUPING_QUARTER') }
    ];

    return methods;

};
export default ManagersRankingService;
