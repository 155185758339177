import {dateToQueryParam} from 'root/app/utilities/navigation_state.utilities';
import template from './engagement_beta.html';
import EngagementBeta from 'root/app/components/engagement_beta/engagement_beta';

function EngagementBetaView($scope, Filters) {
    'ngInject';
    var _this = this;

    _this.to = Filters.endDate();

    _this.grouping = 'MONTH';

}



const Engagement2View = function($stateProvider) {
    'ngInject';
    $stateProvider.state('beta.screen', {
        url: '/',
        controller: EngagementBetaView,
        controllerAs: '$ctrl',
        template: template,
        data: { pageTitle: 'SIDEMENU_ENGAGEMENT', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
};

export default Engagement2View;
