import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Style from '../../../../utilities/style.utils';
import Numbers from '../../../../utilities/number.utilities';


const SurveyQuestionResultsDetailView = ({ survey, result, question, top }) => {
    const $translate = getService('$translate');
    const [chartConfig, setChartConfig] = useState(null);

    const Languages = getService('Languages');


    const prepareResults = (values) => {

        let maxScore = 0;
        values.forEach(value => {
            const comparison = value.score || value.id;
            if (comparison > maxScore) {
                maxScore = comparison;
            }
        });

        const items = top ? values.sort((a, b) => {
            const aVotes = result && result.ratings && result.ratings[a.id] ? result.ratings[a.id] : 0;
            const bVotes = result && result.ratings && result.ratings[b.id] ? result.ratings[b.id] : 0;
            return bVotes - aVotes;
        }).slice(0, 3) : values;

        return items.map(value => {
            const normalizedScore = value.score || value.id;
            if (!value.color) {
                value.color = Style.colorGradient(normalizedScore / maxScore, Style.bad, Style.warning, Style.excellent);
            }
            return {
                color: value.color,
                label: Languages.getTranslationFromUserLanguage(value.label),
                y: result && result.ratings && result.ratings[value.id] ? Numbers.roundNumber(Numbers.calculatePercent(result.ratings[value.id], result.votes), 1) : 0,
                votes: result && result.ratings && result.ratings[value.id] ? result.ratings[value.id] : 0
            };
        });
    };

    const buildChart = (items) => {
        const categories = items.map(item => `${item.label} (${item.votes} ${$translate.instant('VOTES')})`);
        const data = items.map(item => ({
            y: item.y,
            color: item.color
        }));

        const newChartConfig = {
            chart: {
                type: 'column',
                height: top ? '90px' : '180px'
            },
            title: { text: '' },
            xAxis: {
                categories: categories,
                type: 'categories',
                labels: { enabled: top ? false : true },
            },
            yAxis: {
                min: 0,
                max: 100,
                title: { text: '' },
                labels: { enabled: false }
            },
            series: [{
                showInLegend: false,
                data: data,
                dataLabels: {
                    enabled: true,
                    format: '{y} %'
                }
            }]
        };
        setChartConfig(newChartConfig);
    };



    useEffect(() => {

        let valueList;
        if (!survey || !result || !question) {
            return;
        }

        let items = [];
        if (question.questionType === 'TOPIC') {

            valueList = [];

            question.values.forEach(function (value) {
                valueList.push({
                    id: value.id,
                    score: value.score,
                    color: value.color,
                    label: value.label
                });
            });
            items = prepareResults(valueList);
        } else if (question.questionType === 'RANGE') {
            var numItems = question.lastValue.id;
            var scoreUnit = question.lastValue.score / numItems;
            scoreUnit = parseFloat(scoreUnit.toFixed(2));

            valueList = [question.firstValue];
            for (var i = question.firstValue.id + 1; i < question.lastValue.id; i++) {
                valueList.push({
                    id: i,
                    score: i * scoreUnit,
                    label: {
                        defaultValue: i
                    }
                });
            }

            valueList.push(question.lastValue);
            valueList.reverse();
            items = prepareResults(valueList);

        } else {

            valueList = [];

            [1, 2].forEach(function (value) {
                valueList.push({
                    id: value,
                    score: (result.ratings && result.ratings.hasOwnProperty(value)) ? result.ratings[value] : 0,
                    label: {
                        defaultValue: (value === 1 ? $translate.instant('COMMENTED') : $translate.instant('NOT_COMMENTED'))
                    }
                });
            });
            items = prepareResults(valueList);
        }

        buildChart(items);
    }, [survey, result, question, top, $translate]);

    return (
        <div className="row">
            <div className="col-xs-12">
                {chartConfig && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartConfig}
                    />
                )}
            </div>
        </div>
    );
};

angularize(SurveyQuestionResultsDetailView, 'hfSurveyQuestionResultsDetailView', angular.module('happyForceApp'), {
    survey: '=',
    result: '=',
    question: '=',
    top: '<'
});

export default SurveyQuestionResultsDetailView;
