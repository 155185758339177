import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import Dates from "../../../../../utilities/date.utilities";
import Numbers from "../../../../../utilities/number.utilities";
import Style from "../../../../../utilities/style.utils";
import ClassificationFlowChart from "../../../shared/classification_flow_chart/classification_flow_chart";
import HappyLoading from "../../../../../shared/components/_migrated/loader/loading.directive";
import VotesChart from "../../../shared/votes_chart/votes_chart.component";
import {HfBannerReact} from "../../../../../shared/new-components/hf-banner/hfBanner";
import {Typography} from '@mui/material';
import moment from 'moment'

const HIFunnelBox = ({ from, to, level, filters, groups, dateGrouping }) => {


    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);
    const [flowValues, setFlowValues] = useState(null);
    const [flowBanner, setFlowBanner] = useState('');
    const [totalVotes, setTotalVotes] = useState(0);
    const [distributionValues, setDistributionValues] = useState(null);
    const [loadingClassification, setLoadingClassification] = useState(false);
    const [loadingVotes, setLoadingVotes] = useState(true);
    const [noData, setNoData] = useState(false);

    const HIService = getService('HIService');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');


    const categories = [
        {
            category: 'ENCHANTED',
            color: Style.excellent,
            last: false
        },
        {
            category: 'HAPPY',
            color: Style.warning,
            last: false
        },
        {
            category: 'NEUTRAL',
            color: Style.neutral,
            last: false
        },
        {
            category: 'SAD',
            color: Style.faceBad,
            last: false
        },
        {
            category: 'DISENCHANTED',
            color: Style.faceSad,
            last: true
        }
    ];

    const buildArrowElement = (funnelItem) => {
        var result = {
            count: funnelItem.count,
            sourceLabel: $translate.instant('ATTITUDE_' + funnelItem.source),
            targetLabel: $translate.instant('ATTITUDE_' + funnelItem.target)
        };

        return result;
    };

    const buildVotesLabel = (total, votes) => {
        var percent = Numbers.roundNumber((votes / total) * 100, 1) + '%';
        var keys = { percent: percent, votes: votes };
        return $translate.instant('HI_DETAIL_DISTRIBUTION_VOTES', keys);
    };

    /*
           This function takes the API response, and builds a category item and the arrow of the funnel flow
           First, we look for all entries matching the source or the target category.
           For each matching entry, we accumulate the count of each entry; this will produce the arrow element.
           If the element is possitive, the arrow will point to the left; otherwise to the right.
           We also store some detail of the flow: from this category, which element are moving in-to it.
       */

    const processCategory = (results, type) => {
        // Let's process the PROMOTERS
        var result = {
            category: {
                type: 'category',
                label: $translate.instant('ATTITUDE_' + type.category),
                number: results.classification[type.category],
                color: type.color
            },
            arrow: null
        };

        var hiFlowCount = 0;
        var hiFlow = [];

        results.funnel.forEach(function (category) {
            if (category.source === type.category) {
                hiFlowCount += category.count;
                if (!type.last && category.count !== 0) {
                    hiFlow.push(buildArrowElement(category));
                }
            } else if (category.target === type.category) {
                hiFlowCount += category.count;
                if (!type.last && category.count !== 0) {
                    hiFlow.push(buildArrowElement(category));
                }
            }
        });

        if (!type.last) {
            result.arrow = {
                type: 'arrow',
                flow: hiFlowCount,
                flowItems: hiFlow
            };
        }

        return result;
    };


    const loadResults = () => {
        setLoadingClassification(true);
        setLoadingVotes(true);
        setHasEnoughActiveEmployees(true);

        const toFormatted = Dates.lastDayOfMonth(to);
        const fromFormatted = Dates.firstDayOfMonth(to);
        const params = {
            from: fromFormatted,
            to: toFormatted,
            filters: filters,
            groupId: groups,
            grouping: dateGrouping,
            hierarchyId: level ? level.id : undefined
        };

        HIService.stats.classification(params, (err, response) => {
            setLoadingClassification(false);
            if (response.quality === 'NO_DATA') {
                setNoData(true);
                setDistributionValues(null);
                setFlowValues(null);
                setFlowBanner('');
            } else {
                // Process the distribution values
                //processDistribution(response);

                // Now, process the funnel data
                var results = [];
                categories.forEach(function (type) {
                    var result = processCategory(response, type);
                    results.push(result.category);
                    if (result.arrow) {
                        results.push(result.arrow);
                    }
                });
                setFlowValues(results);
                setFlowBanner($translate.instant('FLOW_DESCRIPTION_' + response.valuation));
                setNoData(false);
            }

        });

        HIService.stats.get(params, (err, response) => {
            setLoadingClassification(false);
            if (response.results && response.results.length > 0) {
                var currentResult = response.results[0];
                setTotalVotes(currentResult.votesPeriod);
                var ratings = currentResult.ratings;
                var rattingsFaceHappy = ratings[4] ? ratings[4] : 0;
                var rattingsFaceGood = ratings[3] ? ratings[3] : 0;
                var rattingsFaceBad = ratings[2] ? ratings[2] : 0;
                var rattingsFaceSad = ratings[1] ? ratings[1] : 0;

                const newDistributionValues = [
                    {
                        value: rattingsFaceHappy,
                        color: Style.faceHappy
                    },
                    {
                        value: rattingsFaceGood,
                        color: Style.faceGood
                    },
                    {
                       value: rattingsFaceBad,
                        color: Style.faceBad
                    },
                    {
                        value: rattingsFaceSad,
                        color: Style.faceSad
                    }
                ];


                setDistributionValues(newDistributionValues);
            } else {
                setDistributionValues(null);
            }
        });
    };


    useEffect(() => {
        loadResults();

    }, [to, level, filters, groups, dateGrouping]);

    return (
        <HappyLoading loading={loadingClassification && loadingVotes}>
            {hasEnoughActiveEmployees && (
                <>
                    <div className="row">
                        <div className="col-xs-12">
                            {flowValues && <ClassificationFlowChart values={flowValues} />}
                        </div>
                    </div>

                    <div className="row p-t">
                        <div className="col-xs-12">
                            <HfBannerReact type={'primary'}>
                                <Typography variant="h6" component="div">
                                    {flowBanner}
                                </Typography>
                            </HfBannerReact>
                        </div>
                    </div>

                    <div className="row p-t">
                        <div className="col-xs-12">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="small-style m-t-xs">
                                        {$translate.instant('HI_DETAIL_NUM_VOTES', { votes: totalVotes, month: moment(to).format('MMM YYYY') })}
                                    </div>
                                </div>
                            </div>
                            <div className="row p-h-xs">
                                <div className="col-xs-12">
                                    {distributionValues && <VotesChart values={distributionValues}  totalVotes={totalVotes} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </HappyLoading>
    );
};

angularize(HIFunnelBox, "hiFunnelBox", angular.module("happyForceApp"), {
    from: "=",
    to: "=",
    level: "=",
    filters: "=",
    groups: "=",
    dateGrouping: "<"
});

export default HIFunnelBox;
