import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from "reactInAngular";
import Valuation from "../../../../../utilities/valuation.utils";
import Dates from "../../../../../utilities/date.utilities";
import {APISettings} from '../../../../../shared/services/migrated/axiosWrapper';
import ScaleChart from '../../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import HfImpactMark from '../../../components/impact_mark/impact_mark.component';

const ScoreEngagementDetailOverview = ({ data, hasEnoughActiveEmployees, enpsImpact, hiImpact, minValue, maxValue, status }) => {
    const $translate = getService("$translate");
    const [slices, setSlices] = useState([]);
    const [scoreColor, setScoreColor] = useState('');
    const [shouldDisplayData, setShouldDisplayData] = useState(false);
    const [measureDate, setMeasureDate] = useState(null);
    const [hasImpactChart, setHasImpactChart] = useState(false);

    useEffect(() => {
        setSlices([
            {
                color: Valuation.valuationColor('BAD', false),
                range: [0, 3],
                label: $translate.instant('HI_SLICE_FIRST')
            },
            {
                color: Valuation.valuationColor('WARNING', false),
                range: [3, 7],
                label: $translate.instant('HI_SLICE_SECOND')
            },
            {
                color: Valuation.valuationColor('EXCELLENT', false),
                range: [7, 10],
                label: $translate.instant('HI_SLICE_THIRD')
            }
        ]);

        processData();
    }, [data]);

    const processData = () => {
        setMeasureDate(Dates.fromAPIFormat(data.measureDate, APISettings.apiDateFormat));
        setShouldDisplayData(hasEnoughActiveEmployees && status === 'ACTIVATED' && data.quality !== 'NO_DATA');
        setScoreColor(Valuation.valuationColor(data.valuation, false));
        setHasImpactChart(hasEnoughActiveEmployees);
    };

    const getVariationText = () => {
        let key = 'SCORE_VARIATION_';
        if (data.variation > 0) {
            key += 'UP';
        } else if (data.variation < 0) {
            key += 'DOWN';
        } else {
            key += 'EQUAL';
        }

        return $translate.instant(key, { variation: data.variation });
    };

    return (
        <section className="hi_box engagement-score-detail-overview white-bg">
            <div className="row overview-row">
                <div className={hasImpactChart ? 'col-md-6 col-sm-12 col-xs-6' : 'col-md-10 col-sm-12 col-xs-10'}>
                    {shouldDisplayData && (
                        <div className="row same-height">
                            <div className="col-xs-12">
                                <div className="big-number-container" style={{ color: scoreColor }}>
                                    <span className="big-number">{data.value}</span>
                                    <span className="big-number-sub">pts</span>
                                </div>
                                <ScaleChart slices={slices} min={minValue} max={maxValue} value={data.value} />
                                <p className="small-style" dangerouslySetInnerHTML={{ __html: getVariationText() }}></p>
                            </div>
                        </div>
                    )}

                    {hasEnoughActiveEmployees && data.quality === 'NO_DATA' && (
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="big-number-container" style={{ color: scoreColor }}>
                                    <span className="big-number-no-data">{$translate.instant('NO_DATA')}</span>
                                </div>
                                <p className="small-style">
                                    {$translate.instant('SCORE_NO_DATA_SHORT_DESCRIPTION')}
                                </p>
                            </div>
                        </div>
                    )}

                    {status === 'DISABLED' && (
                        <div className="row">
                            <p>{$translate.instant('SCORE_DEACTIVATED')}</p>
                        </div>
                    )}

                    {!hasEnoughActiveEmployees && (
                        <EmptyState
                            image="'images/anonymous.png'"
                            message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                            submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                        />
                    )}
                </div>

                {hasImpactChart && (
                    <div className="col-md-6 col-sm-12 col-xs-6 impact-chart">
                        <div className="row">
                            <div className="mini-style text-center">{$translate.instant('ENGAGEMENT_IMPACT_HI')}</div>
                            <div className="impact-container">
                                {hiImpact && <HfImpactMark level={hiImpact} color={scoreColor} />}
                            </div>
                        </div>
                        <div className="row m-t-md">
                            <div className="mini-style text-center">{$translate.instant('ENGAGEMENT_IMPACT_ENPS')}</div>
                            <div className="impact-container">
                                {enpsImpact && <HfImpactMark level={enpsImpact} color={scoreColor} />}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

ScoreEngagementDetailOverview.propTypes = {
    data: PropTypes.object,
    hasEnoughActiveEmployees: PropTypes.bool,
    enpsImpact: PropTypes.number,
    hiImpact: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    status: PropTypes.string
};

angularize(ScoreEngagementDetailOverview, "hfScoreEngagementDetailOverview", angular.module("happyForceApp"), {
    data: "<",
    hasEnoughActiveEmployees: "<",
    enpsImpact: "<",
    hiImpact: "<",
    minValue: "<",
    maxValue: "<",
    status: "@"
});

export default ScoreEngagementDetailOverview;
