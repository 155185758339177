import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import { Typography } from '@mui/material';

const DaysLeft = ({ date, dateTo }) => {
    const [daysLeft, setDaysLeft] = useState(0);
    const $translate = getService('$translate');

    useEffect(() => {
        const computeDaysLeft = () => {
            const fromDate = date ? new Date(date) : new Date();
            const toDate = new Date(dateTo);
            const timeDiff = toDate - fromDate;
            setDaysLeft(Math.ceil(timeDiff / (1000 * 3600 * 24)));
        };

        computeDaysLeft();
    }, [date, dateTo]);

    return (
        <Typography variant="body2">
            {$translate.instant('DAYS_LEFT', { days: daysLeft })}
        </Typography>
    );
};

angularize(DaysLeft, 'hfDaysLeft', angular.module('happyForceApp'), {
    date: '=',
    dateTo: '='
});

export default DaysLeft;
