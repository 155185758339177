

'use strict';

function AnnouncementsDefaultSectionConfigCtrl($scope) {
    'ngInject';

    const _this = this;

    _this.updateSection = function () {
        _this.onUpdate(_this.sectionData.id, this.sectionData);
    };

    _this.$onInit = function () {
        $scope.$watch(angular.bind(_this, function () {
            return _this['sectionData'];
        }), function (n, o) {
            if (n == o) return;

            // We just validate here if the title is valid
            const isTitleValid = !!_this.sectionData.title && !!_this.sectionData.title.defaultValue
                && _this.sectionData.title.defaultValue.length > 0;
            _this.setIsValid(_this.sectionData.id, isTitleValid);
        }, true);
    };
}


const hfDefaultSectionConfig = angular.module('happyForceApp')
    .directive('hfDefaultSectionConfig', function () {
        return {
            template: require('./default_section_configuration.html'),
            controller: AnnouncementsDefaultSectionConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                sectionData: '<',
                onRemoveSection: '<',
                setIsValid: '<',
                onMoveUp: '<',
                onMoveDown: '<'
            }
        };
    });

export default hfDefaultSectionConfig;