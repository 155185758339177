
const MilestoneService = function (Restangular, $uibModal, Settings) {
    'ngInject';
    var Company = Restangular.one('company');

    var methods = {};

    methods.POST = function (milestone, callback, error) {
        milestone.date = moment(milestone.date).format(Settings.apiDateFormat);
        Company.one('milestone').customPOST(milestone).then(callback, error);
    };

    methods.PUT = function (milestone, callback, error) {
        milestone.date = moment(milestone.date).format(Settings.apiDateFormat);
        Company.one('milestone').one(milestone.id).customPUT(milestone).then(callback, error);
    };

    methods.GET = function (from, to, params, callback) {

        Company.one('milestone')
            .one('from', moment(from).format(Settings.apiDateFormat))
            .one('to', moment(to).format(Settings.apiDateFormat))
            .get(params).then(function (data) {
            callback(null, data);
        }, callback);
    };

    methods.DELETE = function (id, callback, error) {
        Company.one('milestone').one(id).remove().then(callback, error);
    };

    return methods;
};

export default MilestoneService;



