function LoggedUserProfile($scope, Restangular, AuthSrvc, ErrorSvrc, $modalInstance, selectedUser, $translate, $uibModal, $state, $localStorage, People) {
    'ngInject'
    $scope.AuthSrvc = AuthSrvc;

    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';

    $scope.isEditUser = false;
    $scope.isDeleteUser = false;
    $scope.isInviteUser = false;
    $scope.isResendUser = false;
    $scope.selectedUser = selectedUser;
    $scope.selectedUser.changed = false;

    $scope.pathData = [];
    $scope.rootData = [];

    $scope.newpassword = '';
    $scope.newpasswordbis = '';
    $scope.uploadProgress = 0;
    $scope.uploading = false;

    $scope.deleteLinkedEmployee = false;
    $scope.deleteFromAllCompanies = false;

    $scope.errorHierarchySelector = false;

    $scope.notificationTypes = People.notificationTypes;

    $scope.langs = ['es', 'en'];
    $scope.actualLang = AuthSrvc.getLanguage();

    $scope.hasUserImage = AuthSrvc.hasUserImage;

    $scope.setChanged = function () {
        $scope.selectedUser.changed = true;
    };

    $scope.deleteUser = function () {
        $modalInstance.dismiss('delete', $scope.selectedUser);
    };

    $scope.validNewPassword = function () {
        if (this.newpasswordbis === '' && this.newpassword === '') {
            return true;
        } else if (this.newpasswordbis !== this.newpassword) {
            return false;
        } else if (this.newpassword.length < 5) {
            return false;
        } else {
            return true;
        }
    };

    $scope.getUserImage = function () {
        return AuthSrvc.getUserImage();
    };



    $scope.setUserImage = function (file) {
        $scope.uploading = true;
        AuthSrvc.setUserImage(file, function (updatedUser) {
            $scope.uploading = false;
            $scope.selectedUser = updatedUser;
            swal($translate.instant('PROFILE_IMG_UPDATED'), '', 'success');
        }, function (loaded, total) {

            $scope.uploadProgress = 100 * loaded / total;
            console.log(loaded + ' of ' + total);
        }, function () {

            $scope.uploading = false;
        });
    };

    $scope.updateUser = function () {
        if (!$scope.selectedUser.changed) {
            $modalInstance.dismiss('updated');
            swal($translate.instant('PROFILE_UPDATED'), '', 'success');
            return;
        }

        if (this.newpassword !== '') {
            $scope.selectedUser.password = this.newpassword;
        }

        $scope.selectedUser.language = $scope.actualLang;

        AuthSrvc.updateUser($scope.selectedUser, function success(updatedUser) {
            $scope.selectedUser = Restangular.copy(updatedUser);
            $modalInstance.dismiss('updated');
            swal($translate.instant('PROFILE_UPDATED'), '', 'success');
            $state.reload();
        }, function error(response) {
            ErrorSvrc.showErrorModal(response);
        });
    };

    $scope.login = function () {
        AuthSrvc.login({
            email: $scope.selectedUser.email,
            password: $scope.selectedUser.password,
            rememberMe: true
        },
        function (res) {
            $scope.completed = true;
        },

        function (err) {
            $scope.completed = true;
            $scope.failed = true;
            $scope.failedReason = $translate.instant(ErrorSvrc.getErrorMessage(err));
            $state.go('onboarding');
        });
    };


    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}

const LoggedUserProfileCtrl = angular.module('happyForceApp')
.controller('LoggedUserProfileCtrl', LoggedUserProfile);

export default LoggedUserProfileCtrl;
