
import angular from 'angular';
import * as React from 'react';
import {angularize} from 'reactInAngular';


type HFSwitchProps = {
    $scope: any,
    firstLabel: string,
    firstValue: string | number | boolean,
    secondLabel: string,
    secondValue: string | number | boolean,
    selected: string | number | boolean,
    onSelect: Function
}

const HFSwitch = ({firstLabel, firstValue, secondLabel, secondValue, selected, onSelect} : HFSwitchProps) => {

    return (
        <span className="hf-switch p-b-new-xs p-t-new-xs p-l-new-xs p-r-new-xs">
            <div role="button"
                className={'p-b-new-xs p-t-new-xs p-l-new-sm p-r-new-sm m-r-new-xs small-style ' + ((selected == firstValue) && 'selected')}
                onClick={() => onSelect(firstValue)}>{firstLabel}</div>
            <div role="button"
                className={'p-b-new-xs p-t-new-xs p-l-new-sm p-r-new-sm m-r-new-xs small-style ' + ((selected == secondValue) && 'selected')}
                onClick={() => onSelect(secondValue)}>{secondLabel}</div>
        </span>
    )

}

/* This is our trick for expose these components on angular */
angularize(HFSwitch, 'hfSwitch', angular.module('happyForceApp'), {
    firstLabel: '<',
    firstValue: '<',
    secondLabel: '<',
    secondValue: '<',
    selected: '<',
    onSelect: '<'

});

export default HFSwitch;
