import React from 'react';
import { angularize, getService } from 'reactInAngular';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography, Divider, Link } from '@mui/material';
import SurveyIndexChart from '../../../surveys/components/survey_index_chart/surveyIndexChart';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import HfMultiLanguageText from '../../../../shared/new-components/hf-multilanguage-text/hfMultilanguageText';


const LifeCycleSurveysTable = ({ momentResults, loading, onShowDetailsModal }) => {
    const $translate = getService('$translate');

    if (!momentResults) {
        return null;
    }

    const showDetails = (survey) => {

        onShowDetailsModal(survey);
    }

    function renderSurveyRow(sIndex, survey, momentResult) {
        return (<div key={sIndex} className="row row-eq-height m-b-xs">
            <div className="col-xs-3">
                <>
                    <strong>
                        <HfMultiLanguageText multilanguage={survey.details.title}/>
                    </strong>
                    <div className="mini-style warm-grey m-t-xs">
                        {$translate.instant('SURVEY_VOTES', {votes: survey.votes})}
                    </div>
                </>

            </div>
            <div className="col-xs-2">
                <SurveyIndexChart index={survey.pain} size="small" hasResult={survey.pain}/>
            </div>
            <div className="col-xs-2">
                <SurveyIndexChart index={survey.gain} size="small" hasResult={survey.gain}/>
            </div>
            <div className="col-xs-2 col-full-center-content">
                {survey.comments}
            </div>
            <div className="col-xs-2 col-full-center-content">
                {survey.participationPercent ? survey.participationPercent : ' - '}%
            </div>
            <div className="col-xs-1 col-full-center-content">
                <Link onClick={() => showDetails(survey.details)}>Ver detalle</Link>
            </div>
            {!momentResult.surveys[sIndex + 1] ? null : (
                <Divider className="m-t-xs m-b-xs"/>
            )}
        </div>);
    }

    return (
        <HappyLoading loading={loading}>
            <div className="row">
                <div className="col-xs-12">
                    {momentResults.map((momentResult, index) => (
                        <div key={index} className="row">
                            <div className="col-xs-12">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <Typography variant="subtitle1" className="font-bold medium-style no-padding">
                                            {momentResult.name}
                                        </Typography>
                                        <div className="row m-t-xs">
                                            <div className="col-xs-3" />
                                            <div className="col-xs-2 mini-style warm-grey text-center">
                                                <strong>Importancia</strong>
                                            </div>
                                            <div className="col-xs-2 mini-style warm-grey text-center">
                                                <strong>Opinión</strong>
                                            </div>
                                            <div className="col-xs-2 mini-style warm-grey text-center">
                                                <strong>Comments</strong>
                                            </div>
                                            <div className="col-xs-2 mini-style warm-grey text-center">
                                                <strong>Participación</strong>
                                            </div>
                                            <div className="col-xs-1" />
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        {momentResult.surveys.map((survey, sIndex) => renderSurveyRow(sIndex, survey, momentResult))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </HappyLoading>
    );
};

angularize(LifeCycleSurveysTable, 'hfLifeCycleSurveysTable', angular.module('happyForceApp'), {
    momentResults: '<',
    loading: '<',
    onShowDetailsModal: '<'
});

export default LifeCycleSurveysTable;
