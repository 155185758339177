'use strict';

const FeedbackConfigurationService = function (Restangular, Settings, Rollbar) {
    'ngInject';
    var FeedbackConfiguration = Restangular.one('company').one('feedback').one('configuration');

    var methods = {};

    var FeedbackRecognitionValues = FeedbackConfiguration.one('recognition').one('values');

    methods.getConfiguration = function (callback) {
        FeedbackConfiguration
            .get({})
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                console.log(err);
                Rollbar.error('ERROR retrieving feedback Configuration ', err);
                callback(err);
            });
    };

    methods.updateConfiguration = function (configuration, callback) {
        FeedbackConfiguration
            .customPOST(configuration)
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                console.log(err);
                Rollbar.error('ERROR upating feedback Configuration ', err);
                callback(err);
            });
    };

    methods.checkTypeIsEnabled = function (type, callback) {
        methods.getConfiguration(function (err, configuration) {
            if (err) {
                callback(err, null);
            } else if (type === 'RECOGNITION') {
                callback(null, configuration.recognitionEnabled);
            } else if (configuration.feedbackConfiguration[type]) {
                callback(null, configuration.feedbackConfiguration[type].enabled);
            } else {
                callback(null, true);
            }
        });
    };



    return methods;
};

export default FeedbackConfigurationService;
