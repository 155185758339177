import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from "reactInAngular";
import ScoreFactorBox from './components/factor_box_row/score_factor_box';

const ScoreDetailFactorsList = ({ data, scoreId, dateGrouping }) => {
    const [factorResults, setFactorResults] = useState([]);
    const $translate = getService("$translate");

    useEffect(() => {
        const processFactorsData = () => {
            if (data) {
                let tmpFactors = [];
                data.forEach(scoreResult => {
                    if (scoreResult.factors) {
                        const { from, to } = scoreResult;
                        scoreResult.factors.forEach(factorResult => {
                            let factor = tmpFactors.find(f => f.id === factorResult.id);
                            if (!factor) {
                                factor = {
                                    id: factorResult.id,
                                    results: []
                                };
                                tmpFactors.push(factor);
                            }
                            factor.results.push({
                                from,
                                to,
                                quality: factorResult.quality,
                                valuation: factorResult.valuation,
                                result: factorResult.result,
                                questions: factorResult.questions,
                                variation: factorResult.variation
                            });
                        });
                    }
                });
                setFactorResults(tmpFactors);
            }
        };

        processFactorsData();
    }, [data]);

    return (
        <section id="factors_box">
            <div className="row">
                <div className="col-xs-12">
                    <h2>{$translate.instant('ENGAGEMENT_DETAIL_FACTORS')}</h2>
                </div>
            </div>
            <div className="row">
                {factorResults.map((result, index) => (
                    <div key={index} className="col-xs-12 p-t p-b">
                        <ScoreFactorBox
                            scoreId={scoreId}
                            dateGrouping={dateGrouping}
                            factor={result}
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

ScoreDetailFactorsList.propTypes = {
    data: PropTypes.array.isRequired,
    scoreId: PropTypes.string.isRequired,
    dateGrouping: PropTypes.string
};

angularize(ScoreDetailFactorsList, "hfScoreDetailFactorsList", angular.module("happyForceApp"), {
    data: "<",
    scoreId: "<",
    dateGrouping: "<"
});

export default ScoreDetailFactorsList;
