import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {InsightInt} from '../../types';
import Style from '../../../../../utilities/style.utils';
import Tooltip from '@mui/material/Tooltip';
import {Box, Grid, Typography} from '@mui/material';



const RiskBarContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '5px',
    backgroundColor: Style.chartBg,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
}));

const Segment = styled('div')({
    height: '100%',
});

const HighRiskSegment = styled(Segment)({
    backgroundColor: Style.bad,
});

const MediumRiskSegment = styled(Segment)({
    backgroundColor: Style.warning,
});

const LowRiskSegment = styled(Segment)({
    backgroundColor: Style.good,
});

interface InsightItemProps {
    insight: InsightInt,
}




const InsightRiskBar = ({ insight } : InsightItemProps) => {
    const totalCandidates = insight.overallCandidates || 0;

    const highRiskPercentage = (insight.highRisk / totalCandidates) * 100;
    const mediumRiskPercentage = (insight.mediumRisk / totalCandidates) * 100;
    const lowRiskPercentage = (insight.lowRisk / totalCandidates) * 100;


    const getTooltip = () => {
        return  <Grid container>
            <Grid item xs={12}>
                <Typography variant="caption" fontWeight={'bold'}>Analyzed: {insight.overallCandidates}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">High Risk: {insight.highRisk}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">Medium Risk: {insight.mediumRisk}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">Low Risk: {insight.lowRisk}</Typography>
            </Grid>
        </Grid>

    }

    return (
        <Tooltip title={getTooltip()}>
            <RiskBarContainer>
                <HighRiskSegment style={{ width: `${highRiskPercentage}%` }} />
                <MediumRiskSegment style={{ width: `${mediumRiskPercentage}%` }} />
                <LowRiskSegment style={{ width: `${lowRiskPercentage}%` }} />
            </RiskBarContainer>
        </Tooltip>
    );
};

export default InsightRiskBar;
