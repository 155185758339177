import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';
import CommentConversationAction
    from 'root/app/shared/new-components/hf-comments/components/comment-actions/comment-conversation-action/CommentConversationAction';
import CommentReplyWithAnnouncementAction
    from 'root/app/shared/new-components/hf-comments/components/comment-actions/comment-reply-with-announcement-action/CommentReplyWithAnnouncementAction';
import CommentInappropriateAction
    from 'root/app/shared/new-components/hf-comments/components/comment-actions/comment-inappropriate-action/CommentInappropriateAction';
import CommentChangeVisibilityAction
    from 'root/app/shared/new-components/hf-comments/components/comment-actions/comment-change-visibility-action/CommentChangeVisibilityAction';

const CommentActionsBar = ({
    comment,
    replyOf,
    showInappropriate,
    showConversation,
    showReplyWithAnnouncement,
    showSwapPrivacy,
    selfHandleInappropriate,
    commentUpdatedCallback
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ display: 'flex', gap: '12px'}}>
            {showConversation && (
                <CommentConversationAction
                    comment={comment}
                    commentUpdatedCallback={commentUpdatedCallback}
                />
            )}
            {showReplyWithAnnouncement && (
                <CommentReplyWithAnnouncementAction
                    comment={comment}
                    commentUpdatedCallback={commentUpdatedCallback}
                />
            )}
            {showInappropriate && (
                <CommentInappropriateAction
                    replyOf={replyOf}
                    comment={comment}
                    selfHandle={selfHandleInappropriate}
                    inappropriateCallback={commentUpdatedCallback}
                    showActionLabel={false}
                />
            )}
            {showSwapPrivacy && (
                <>
                    <a onClick={handleMenuOpen}>
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleMenuClose}>
                            <CommentChangeVisibilityAction
                                comment={comment}
                                commentUpdatedCallback={commentUpdatedCallback}
                                showActionLabel={true}
                            />
                        </MenuItem>
                    </Menu>
                </>
            )}
        </div>
    );
};

CommentActionsBar.propTypes = {
    comment: PropTypes.object.isRequired,
    replyOf: PropTypes.string,
    showInappropriate: PropTypes.bool,
    showConversation: PropTypes.bool,
    showReplyWithAnnouncement: PropTypes.bool,
    showSwapPrivacy: PropTypes.bool,
    selfHandleInappropriate: PropTypes.bool,
    commentUpdatedCallback: PropTypes.func
};

angularize(CommentActionsBar, "hfCommentActionsBar", angular.module("happyForceApp"), {
    comment: '<',
    replyOf: '<',
    showInappropriate: '<',
    showConversation: '<',
    showReplyWithAnnouncement: '<',
    showSwapPrivacy: '<',
    selfHandleInappropriate: '<',
    commentUpdatedCallback: '='
});

export default CommentActionsBar;
