import Style from "../../../../../../utilities/style.utils";
import Scope from "../../../../../../utilities/scope.utilities";

import async  from "async";
import {getActivation} from "../../../../../../shared/services/migrated/activation.service";


function ActivationSegmentChartCtrl($scope, $translate, $timeout, Settings, ErrorSvrc, Languages) {
    'ngInject';
    var _this = this;

    function loadData() {


        delete _this.activationSegmentChartConfig;

        var streams = [];
        var statsValues = [];
        if (_this.characteristic.questionType === 'LIST') {
            _this.characteristic.values.forEach(function (value) {
                statsValues.push({
                    id: value.id,
                    value: Languages.getTranslationFromUserLanguage(value.value)

                });
            });
        } else if (_this.characteristic.questionType === 'DATE') {
            _this.characteristic.ranges.forEach(function (range) {
                statsValues.push({
                    id: range.id,
                    value: range.name
                });
            });

        } else {
            statsValues.push({
                id: true,
                value: Languages.getTranslationFromUserLanguage(_this.characteristic.yesValue)

            });
            statsValues.push({
                id: false,
                value: Languages.getTranslationFromUserLanguage(_this.characteristic.noValue)
            });
        }

        _this.characteristic.statsValues = statsValues;

        _this.characteristic.statsValues.forEach(function (statsValue) {
            streams.push(function (next) {
                var params = {
                    from: _this.from,
                    to: _this.to
                };

                if (_this.level) {
                    params.hierarchyId = _this.level.id;
                }

                params.filters = _this.characteristic.id + ':' + statsValue.id;

                getActivation(params, function (err, results) {
                    if (err)
                        next(err);
                    else {
                        statsValue.employees = results.activated;
                        next(null, statsValue);
                    }

                });
            });
        });

        async.parallel(streams, function (err, results) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            }

            var data = [];

            _this.characteristic.statsValues.forEach(function (statsValue) {
                data.push({
                    name: statsValue.value,
                    y: statsValue.employees
                });
            });

            _this.activationSegmentChartConfig = activationSegmentChartConfig(_this.characteristic.description, data);
        });
    }


    function activationSegmentChartConfig(title, values) {
        return {
            options: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    style: {
                        fontFamily: Style.chartFontFamily,
                        fontWeight: Style.chartFontWeight
                    },
                    height: 250
                },
                title: {
                    text: title
                },
                tooltip: {
                    pointFormat: '{point.name}: <b>{point.y}</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        colors: Style.chartColors,
                        dataLabels: {

                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            style: {
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                                fontFamily: Style.chartFontFamily,
                                fontWeight: Style.chartFontWeight
                            }
                        }
                    }
                }
            },
            series: [{
                name: title,
                innerSize: '50%',
                data: values
            }]
        };
    };

    this.$onInit = function () {

        Scope.initialize(_this, ['activationSegmentChartConfig'], {
            title: $translate.instant('LOADING_CHART')
        });



        loadData();
    };

}


const activationSegmentChart = angular.module('happyForceApp')
    .directive('activationSegmentChart', function () {
        return {
            scope: {
                characteristic: '=',
                from: '=',
                to: '=',
                level: '=',
                groups: '='
            },
            template: require('./activation-segment-chart.html'),
            controller: ActivationSegmentChartCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });

export default activationSegmentChart;
