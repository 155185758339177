'use strict';

function AnnouncementFeedbackItemCtrl($scope, Announcements, $translate, toaster, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.commentUpdated = function (err, updatedComment) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            _this.comment = updatedComment;
        }
    };
}

const hfAnnouncementFeedbackItem = angular.module('happyForceApp').directive('hfAnnouncementFeedbackItem', function () {
    return {
        scope: {
            comment: '=',
            announcementId: '=',
            showConversationLink: '<?',
            showReplyWithAnnouncementLink: '<?',
            size: '<'
        },
        restrict: 'E',
        template: require('./announcement_feedback_item.html'),
        controller: AnnouncementFeedbackItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfAnnouncementFeedbackItem;

