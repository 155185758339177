'use strict';

import {getHierarchyAll} from "../../../../shared/services/migrated/hierarchy.service";
import HierarchyEditorModal from "./components/hierarchy_editor-modal/hierarchy_editor-modal.component";
import silosConfigComponent from "./components/silos_config/silos_config.controller";



function HierarchyConfigCtrl($rootScope, $translate, $scope, $location, AuthSrvc, CompanySvrc, ErrorSvrc, $stateParams, $state, $uibModal, Analytics, HierarchyEditorModal) {
    'ngInject';
    var _this = this;

    // _this.selectedItem = null;
    function countHierarchyLevels(hierarchy) {
        var count = 0;
        _.forEach(hierarchy, function (level) {
            count = count + 1;
            if (typeof level.children != 'undefined' && level.children != null && level.children.length > 0) {
                count = count + countHierarchyLevels(level.children);
            }
        });

        return count;
    };

    $scope.addLevel = function () {
        //TODO: I don't fucking now why, but the binded variable, is on this, and not on the scope!
        var newLevel = { name: '', parentName: '' };
        if (_this.selectedItem && _this.selectedItem.id != 'root') {
            newLevel.parentId = _this.selectedItem.id;
            newLevel.parentName = _this.selectedItem.name;
        };

        HierarchyEditorModal.showAdd(newLevel, function (updatedOrgData){
            $scope.orgdata = updatedOrgData;
        });

    };

    $scope.editLevel = function () {

        HierarchyEditorModal.showEdit(angular.copy(_this.selectedItem), function (updatedOrgData){
            $scope.orgdata = updatedOrgData;
        });

    };

    $scope.deleteLevel = function () {

        HierarchyEditorModal.showDelete(_this.selectedItem, function (updatedOrgData){
            $scope.orgdata = updatedOrgData;
        });

    };


    getHierarchyAll(function (err, orgdata) {

        if (err) {
            ErrorSvrc.showErrorModal(err);
            $scope.isError = true;
        } else {
            $scope.orgdata = orgdata;
            $scope.loading = false;

            if (typeof orgdata != 'undefined' && orgdata != null && orgdata.length > 0) {
                $scope.hierarchyLevelsCount = countHierarchyLevels(orgdata);
            } else {
                $scope.hierarchyLevelsCount = 0;
            }

        }
        $scope.loading = false;
        $scope.$apply();
    });
}

const hierarchyConfig=  angular.module('happyForceApp')
    .directive('hierarchyConfig', function () {
        return {
            template: require('./hierarchy_config.html'),
            controller: HierarchyConfigCtrl,
            controllerAs: 'hcc',
            bindToController: true
        };
    });

export default hierarchyConfig;
