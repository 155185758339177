import React, {useEffect, useState} from 'react';
import { angularize, getService } from 'reactInAngular';
import {Button, Menu, MenuItem, Typography, Avatar, Divider, Box} from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserUtils from 'root/app/utilities/user.utilities';
import HFAvatar from 'root/app/shared/new-components/hf-avatar/hf-avatar.component';
import UserProfileSettings from 'root/app/shared/components/_migrated/profile/modal/userProfileSettings';

const UserPofileMenu = () => {
    const AuthSrvc = getService('AuthSrvc');
    const ErrorSvrc = getService('ErrorSvrc');
    const $location = getService('$location');
    const $translate = getService('$translate');
    const $window = getService('$window');
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const [showProfileSettings, setShowProfileSettings] = useState(false);


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        localStorage.removeItem('HappyForce-happyForceUser');
        AuthSrvc.logout(
            () => {
                $location.path('/onboarding');
                setTimeout(() => {
                    $window.location.reload();
                }, 0);
            },
            (err) => {
                $location.path('/onboarding');
                ErrorSvrc.showErrorModal(err);
            }
        );
    };

    useEffect(() => {
        const loggedUser = AuthSrvc.user();
        setUser(loggedUser);
    }, []);


    useEffect(() => {
        setUserImage(AuthSrvc.getUserImage());
    }, [user]);


    useEffect(() => {
        const loggedUser = AuthSrvc.user();
        setUser(loggedUser);
    }, [showProfileSettings]);

    return (
        <div>
            <Box
                sx={theme => ({
                    padding: theme.spacing(1),
                    cursor: 'pointer'
                })}
                id="profile-dropdown-menu"
                aria-controls={open ? 'profile-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>

                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <HFAvatar
                        name={user?.name}
                        image={userImage}
                        showName={true}
                        size="small" />
                    <Box sx={{overflow: 'hidden'}}>
                        <Typography variant="h6" sx={theme => ({marginLeft: theme.spacing(1), whiteSpace: 'nowrap'})}>
                            {user?.name}
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <Typography variant="h6" className="hf-profile-name-title">
                                <div className="mini-style warm-grey hidden-xs capitalize">{$translate.instant(user?.role)}</div>
                            </Typography>
                        </Box>
                    </Box>

                </Box>



            </Box>

            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'profile-dropdown-menu',
                }}
            >
                <MenuItem onClick={() => {setShowProfileSettings(true)}}>{$translate.instant('MY_PROFILE')}</MenuItem>
                <Divider />
                <MenuItem onClick={logout} className="text-danger">{$translate.instant('LOG_OUT')}</MenuItem>
            </Menu>
            <UserProfileSettings
                show={showProfileSettings}
                onClose={() => {setShowProfileSettings(false)}} />
        </div>
    );
};

UserPofileMenu.propTypes = {

};

angularize(UserPofileMenu, 'userProfileMenu', angular.module('happyForceApp'), {
});

export default UserPofileMenu;
