'use strict';

import {getHierarchies} from "../../../../shared/services/migrated/hierarchy.service";

function FeedbackAreas($scope, $state, Comments) {
    'ngInject'

    var _this = this;

    this.$onInit = function () {

        function loadData(hierarchyId) {

            var stream = [];

            stream.push(function (next) {
                getHierarchies(function (err, hierarchies) {
                    if (!err) {
                        _this.selectedArea = null;
                        _this.selectionChilds = hierarchies;
                        _this.selectionPath = null;
                    }

                    next();
                });
            });

            stream.push(function (next) {

                function recursiveStatsGetter(hierarchy) {
                    if (hierarchy.children) {
                        hierarchy.children.forEach(function (hierarchy) {
                            recursiveStatsGetter(hierarchy);
                        });
                    }

                    var params = {
                        from: _this.dates.from,
                        to: _this.dates.to,
                        hierarchyId: hierarchy.id
                    };
                    Comments.stats.get(params, function (err, data) {
                        hierarchy.stats = data;
                        hierarchy.feedbackTypeCount = Object.keys(data.feedbackTypeCount).filter(function (key) {
                            return data.feedbackTypeCount[key] > 0 && data.feedbackTypeCount[key];
                        });
                    });
                }

                _this.selectionChilds && _this.selectionChilds.forEach(function (hierarchy) {
                    recursiveStatsGetter(hierarchy);
                });

                next();
            });

            async.waterfall(stream);
        }

        _this.selectArea = function (hierarchyId) {
            _this.level = hierarchyId;
            $(document).scrollTop();
        };

        var lastExecution;
        ['level', 'dates'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                loadData(n);
            }, true);
        });

        loadData(_this.level);
    };
}

// TODO: Hace módulo shared
const hfFeedbackAreas = angular.module('happyForceApp').directive('hfFeedbackAreas', function () {
    return {
        scope: {
            level: '=',
            dates: '='
        },
        restrict: 'E',
        template: require('./feedback-areas.html'),
        controller: FeedbackAreas,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfFeedbackAreas;
