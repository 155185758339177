'use strinct';

function GroupsBreadcumsCtrl(Groups, HFController, $scope, $uibModal, $translate) {
    'ngInject';

    var _this = this;

    _this.selectGroups = function () {
        $uibModal.open({
            template: require('../group-selector/group-selector-modal.html'),
            controller: 'GroupSelectorModalCtrl',
            controllerAs: '$ctrl',
            size: 'mini',
            resolve: {
                selected: function () {
                    return $scope.selectedGroups;
                }
            }
        }).result.then(function (hierarchy) {
            if (hierarchy && hierarchy.id) {
                $scope.selectedGroups.push({
                    id: hierarchy.id,
                    textValue: hierarchy.name
                });
            }
        }, function (err) {

            console.log('error', err);
        });
    };

    this.$onInit = function () {

        $scope.selectedGroups = [];

        if (_this.groups && _this.groups.length) {
            _this.groups.forEach(function (group) {
                $scope.selectedGroups.push({
                    id: group,
                    textValue: Groups.getGroupName(group)
                });
            });
        }



        $scope.$watch(
            'selectedGroups',
            function (n, o) {
                if (!n || n === o) return;

                _this.groups = [];
                if ($scope.selectedGroups) {
                    $scope.selectedGroups.forEach(function (group) {
                        _this.groups.push(group.id);
                    });
                }

            },

            true
        );

    };

}


const hfGroupsBreadcums = angular.module('happyForceApp')
    .directive('hfGroupsBreadcums', function () {
        return {
            scope: {
                groups: '=',
                addMoreLabel: '@',
                addFirstLabel: '@'
            },
            template: require('./groups-breadcums.html'),
            controller: GroupsBreadcumsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfGroupsBreadcums;
