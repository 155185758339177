

import template from './life_cycle.html';
import companiesMomentsConfig from './moments_by_company.json';
import Numbers from '../../utilities/number.utilities';
import async from 'async';

import LifeCycleDetailsModal from './components/life_cycle_evolution_modal/life-cycle-details-modal';
import hfLifeCycleEvolutionChart from './components/life_cycle_evolution_chart/lifeCycleEvolutionChart';
import LifeCycleSurveysTable from './components/life_cycle_surveys_table/lifeCycleSurveyTable';





function LifeCycleCtrl($scope, Settings,  CompanySvrc, Surveys, $state, $translate, SegmentsFilterService, ErrorSvrc, LifeCycleDetailsModal) {
    'ngInject';

    var _this = this;


    _this.dateFromEnabled = false;
    _this.dateToEnabled = false;

    _this.dateFormat = $translate.instant('DATE_FORMAT');

    if (_this.dateFormat === 'DATE_FORMAT')
    {_this.dateFormat = Settings.defaultDateFormat;}

    _this.dateTo = new Date();
    _this.dateFrom = new Date();
    _this.dateFrom.setFullYear(_this.dateTo.getFullYear() - 1);

    _this.selectedFilters = {};

    // Initialize the default tags
    _this.selectedTags = [];

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        /*if(_this.selectedFilters.from){
            _this.dateFrom = _this.selectedFilters.from;
        }
        if(_this.selectedFilters.to){
            _this.dateTo = _this.selectedFilters.to;
        }*/
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        show: true,
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        show: true,
        mode: 'single',
        title: $translate.instant('GROUPS'),
        nonRemovable: false
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        show: true,
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false
    }

    _this.dateFromFilter = {
        title: $translate.instant('FROM'),
        nonRemovable: false,
        id: 'from',
    };

    _this.dateToFilter = {
        title: $translate.instant('TO'),
        nonRemovable: false,
        id: 'to'
    };

    //TODO: use this on the filter
    _this.dateFiltersConfig = {
        show: false,
        filters: [_this.dateFromFilter, _this.dateToFilter]
    };

    // Initialize the default filters
    _this.selectedFilters = {
        hierarchyIds: [],
        segments: [],
        groups: []
    };

    _this.momentResults = [];
    _this.loading = false;

    _this.setMomentsConfig = function (companyId) {


        let companyMomentsConfig = companiesMomentsConfig.filter(function (config) {
            return config.companyId === companyId;
        });

        if (companyMomentsConfig.length) {
            this.mommentsConfig = companyMomentsConfig[0].config;
        }
    }

    _this.prepareParams = function () {
        var params = {};


        if (_this.selectedFilters.groups && _this.selectedFilters.groups.length)
        {params.groupId = _this.selectedFilters.groups;}

        if (_this.selectedFilters.hierarchyIds && _this.selectedFilters.hierarchyIds.length) {
            params.hierarchyId = _this.selectedFilters.hierarchyIds.join(',');
        }

        if (_this.selectedFilters.segments && _this.selectedFilters.segments.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.selectedFilters.segments);
        }

        if (_this.selectedFilters.questionFilters && _this.selectedFilters.questionFilters.length) {
            params.questionResults = _this.selectedFilters.questionFilters.join(',');
        }

        if (_this.dateFromEnabled) {
            params.from = _this.dateFrom;
        }

        if (_this.dateToEnabled) {
            params.to = _this.dateTo;
        }

        return params;

    };



    _this.loadResults = function () {

        delete _this.results;

        var params = _this.prepareParams();


        var streams = [];

        _this.loading = true;



        _this.mommentsConfig.forEach(function (momentConfig) {
            momentConfig.surveys.forEach(function (surveyConfig) {
                streams.push(function (nextMoment) {
                    Surveys.get(surveyConfig.surveyId, function (err, survey) {
                        if (err) {
                            nextMoment(err);
                        } else {
                            Surveys.stats.results(surveyConfig.surveyId, params, function (err, results) {
                                if (err) {
                                    nextMoment(err, null);
                                } else {

                                    if (results.result) {
                                        // Locate the pain question:
                                        var pain = null;

                                        results.result.blocks.forEach(function (block) {
                                            var painResults = _.find(block.questions, function (questionResult) {
                                                return questionResult.question.id === surveyConfig.painQuestionId;
                                            });
                                            if (painResults) {
                                                pain = painResults.questionIndex;
                                            }
                                        });

                                        survey.result = results.result;

                                        var momentSurveyResult = {
                                            surveyId: surveyConfig.surveyId,
                                            details: survey,
                                            votes: results.result.votes,
                                            comments: results.result.comments,
                                            participationPercent:
                                                Numbers.roundNumber(
                                                    Numbers.calculatePercent(results.result.participants, results.result.expectedParticipants), 1),
                                            gain: results.result.periodIndex ? Numbers.roundNumber(results.result.periodIndex, 1) : null,
                                            name: momentConfig.name,
                                            pain: pain ? Numbers.roundNumber(pain, 1) : null
                                        };
                                    } else {
                                        var momentSurveyResult = {
                                            surveyId: surveyConfig.surveyId,
                                            details: survey,
                                            votes: 0,
                                            comments: 0,
                                            participationPercent: null,
                                            gain: null,
                                            name: momentConfig.name,
                                            pain: null
                                        };
                                    }


                                    nextMoment(null, momentSurveyResult);
                                }
                            });
                        }
                    });

                });
            });


        });

        async.parallel(streams, function (err, results) {

            _this.loading = false;

            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }


            // For each momment, let's build the groups

            _this.momentResults = [];

            const newMomentResults = [];

            _this.mommentsConfig.forEach(function (momentConfig) {

                var momentSurveyResults = {
                    name: momentConfig.name,
                    surveys: results.filter(function (result) {
                        return result.name === momentConfig.name;
                    })
                }

                newMomentResults.push(momentSurveyResults);
            });

            _this.momentResults = newMomentResults;
        });
    };

    _this.showDetails = function (surveyDetail) {
        LifeCycleDetailsModal.showModal(surveyDetail,
            _this.selectedFilters.groups,
            _this.selectedFilters.segments,
            _this.selectedFilters.hierarchyIds,
            _this.dateFromEnabled,
            _this.dateFrom,
            _this.dateToEnabled,
            _this.dateTo);

    };


    this.$onInit = function () {
        CompanySvrc.getUserCompany(
            function (err, company) {
                if (!err) {
                    _this.setMomentsConfig(company.id);

                    ['selectedFilters', 'dateFrom', 'dateTo', 'dateFromEnabled', 'dateToEnabled'].forEach(function (key) {
                        // Listeners
                        $scope.$watch(angular.bind(_this, function () {
                            return _this[key];
                        }), function (newVal, oldValue) {
                            if (typeof newVal === 'undefined') {
                                return;
                            }
                            if (newVal !== oldValue || !_this.heatmapData) {
                                _this.loadResults();
                            }
                        }, true);
                    });

                    _this.loadResults();
                } else {
                    ErrorSvrc.showErrorModal(err);
                }
            });
    };


}



const LifeCycleView = function($stateProvider) {
    'ngInject';
    $stateProvider.state('custom.lc', {

        url: '/',
        controller: LifeCycleCtrl,
        controllerAs: '$ctrl',
        bindToController: true,
        template: template,
        data: { pageTitle: 'Life Cycle', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] },
    });
}

export default LifeCycleView;
