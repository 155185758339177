'use strict';

import ActivationComponent from "./activation/activation.controller";
import HappinesIndexComponent from "./happiness-index/happiness-index.controller";
import CommentsBoxComponent from "./comments/comments.controller";
import PrelaunchComponent from "./pre-launch/pre-launch.controller";
import CommentTagsComponent from "./tags/tags.controller";

import template from './dashboard.html';
import {checkAnonymity} from "../../shared/services/migrated/anonymity.service";
import WelcomeUserModalComponent from './change-password-modal/welcome-user.modal.component';


function DashboardCtrl($scope, Filters, AuthSrvc, Reports, Analytics, $translate, $uibModal, ErrorSvrc, $interval, Settings, CompanyStatus, CompanySvrc, Characteristics, $intercom, WelcomeUserModalComponent) {
    'ngInject';
    var _this = this;

    _this.loadingParent = false;
    _this.hasEnoughActiveEmployees = true;

    _this.dates = {
        from: Filters.startDate(),
        to: Filters.endDate()
    };
    _this.level = Filters.level() || null;
    _this.filters = Filters.segments() || null;
    _this.groups = Filters.groups() || [];

    _this.header = {
        title: $translate.instant('WELCOME_MESSAGE', { name: AuthSrvc.user().name }),
        subtitle: $translate.instant('WELCOME_SUBMESSAGE')
    };

    $scope.exportToExcel = function () {
        var params = {
            questionId: $scope.selectedTopic,
            from: _this.dates.from,
            to: _this.dates.to
        };

        // Get the data for the current hierarchy & filter
        params.filters = _this.filters;
        params.groupId = _this.groups;

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        if (typeof $scope.selectedTopic != 'undefined') {
            params.questionId = $scope.selectedTopic;
        }

        $scope.exportingExcelReport = true;
        Reports.participation(_this.dates.from, _this.dates.to, params, function () {
            // We are quering without question and for one hierarchy, so we expect only one node, with one hie, that is for the primary question
            $scope.loadingData = false;
            $scope.exportingExcelReport = false;
            swal($translate.instant('VOTES_EXPORT_SENT'), '', 'success');
            Analytics.trackEvent('votes', 'excel_report', 'success');
        }, function errorCallback(response) {

            ErrorSvrc.showErrorModal(response);
            $scope.loadingData = false;
            $scope.exportingExcelReport = false;
            Analytics.trackEvent('votes', 'excel_report', 'false');

        });
    };

    function requestFullScreen(element) {
        var docElm = document.documentElement;
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.msRequestFullscreen) {
            docElm = document.body; //overwrite the element (for IE)
            docElm.msRequestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
    }

    function cancelFullScreen(el) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }

    }

    function verifyAnonymity() {
        // Verify the filters
        var params = {
            from: _this.dates.from,
            to: _this.dates.to
        };

        // Get the data for the current hierarchy & filter
        params.filters = _this.filters;
        params.groupId = _this.groups;

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        checkAnonymity(params, function (err, response) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else if (!response.valid) {
                _this.hasEnoughActiveEmployees = false;
            } else {
                _this.hasEnoughActiveEmployees = true;
            }
        });
    }

    var interval;
    _this.isFullScreen = false;
    var elem = angular.element('body');

    function datesInterval() {
        _this.dates.updated = new Date();
    }

    _this.toFullScreen = function () {
        elem.addClass('fullscreen');
        requestFullScreen(elem.get(0));

        $(window).trigger('resize');

        _this.isFullScreen = true;

        interval = $interval(function () {
            datesInterval();
        }, 60000); // 1min
    };

    _this.toNormalView = function () {
        _this.isFullScreen = false;
        elem.removeClass('fullscreen');
        cancelFullScreen(elem.get(0));
        $(window).trigger('resize');
        $interval.cancel(interval);
    };

    if (document.addEventListener) {
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
    }

    var block = false;

    function exitHandler() {
        block = !block;
        if (block) return;
        if (_this.isFullScreen)
            _this.toNormalView();

    }


    this.$onInit = function () {


        $scope.$watch(function () {
            return AuthSrvc.user();
        }, function (n, o) {

            _this.header.title = $translate.instant('WELCOME_MESSAGE', { name: AuthSrvc.user().name });
        });

        $scope.$on('loading', function (event, data) {
            _this.loading = data;
        });

        //Ñapa size
        setTimeout(function () {
            $(window).trigger('resize');
        }, 500);

        CompanySvrc.getUserCompany(function (err, company) {

            // THIS IS A CRAP: It should be launched on a common place, and not here
            // Only display it if the company is not on CREATE status, since the user will setup is password on the setup process
            if (company.launchStatus != 'CREATED') {
                // Onboarding

                if (AuthSrvc.user() && AuthSrvc.user().requiresPasswordChange) {
                    setTimeout(WelcomeUserModalComponent.showRegisterModal(), 3000);
                }
            }

        });



        verifyAnonymity();

        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                verifyAnonymity();
            }, true);
        });

        // Monitor if the company has enough active employees if we are not in launched status
        _this.notEnoughActiveEmployeesPrelaunch = CompanyStatus.notEnoughActiveEmployeesPrelaunch;

        $scope.$watch(
            function () {
                return CompanyStatus.notEnoughActiveEmployeesPrelaunch;
            },
            function (newVal, oldVal) {
                _this.notEnoughActiveEmployeesPrelaunch = newVal;
                if (!_this.notEnoughActiveEmployeesPrelaunch) {
                    verifyAnonymity();
                }
            }
        );

    };

    $intercom.trackEvent('dashboard_visited_overview');

}


function DashboardView($stateProvider) {
    'ngInject';

    $stateProvider.state('private.dashboard', {
        url: '/dashboard?:showConversationFor&showModerateFor',
        template: template,
        controller: DashboardCtrl,
        controllerAs: '$ctr',
        data: { pageTitle: 'SIDEMENU_DASHBOARD', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
        /*,
        resolve: {
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                    {
                        name: 'easypiechart',
                        files: [
                            '/plugins/highcharts/highstock.js',
                            '/plugins/jquery.easy-pie-chart/dist/angular.easypiechart.js']
                    }
                ]);
            }
        }*/
    });
}

export default DashboardView;
