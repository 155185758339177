import Numbers from "../../../utilities/number.utilities";
import Style from '../../../utilities/style.utils';

var ManagersRankingCharts = ManagersRankingCharts || {};




function sortAndFilterData(data, inverted) {

    var toSortData = _.reject(data, function (item) { return item.sortPoints == 0; });
    var filteredData = _.reject(data, function (item) { return item.sortPoints != 0; });

    toSortData.sort(function (first, second) {
        if (first.sortPoints > second.sortPoints)
           return inverted ? 1 : -1;
        else if (first.sortPoints < second.sortPoints)
           return inverted ? -1 : 1;
        return 0;
    });

    return {
        filteredData: filteredData,
        sortedData: toSortData
    };

}

function resolveNameFromTypes(type, types) {
    var foundType = _.find(types, function (typeDesc) {return typeDesc.id == type;});

    return foundType != null ? foundType.desc : type;
}

function responseTimeToCategory(time) {
    // Time commes in seconds, so we need to convert it to an appropiate category
    var hours = time ? (time / 60 / 60) : 0;
    var category = 0;
    if (hours == 0) {
        category = 0;
    } else if ((hours > 0) && (hours < 1)) {
        // Less than an hour
        category = 1;
    } else if ((hours > 1) && (hours < 8)) {
        // Less than 8 hours
        category = 2;
    } else if ((hours > 8) && (hours < 24)) {
        // Less than 24 hours
        category = 3;
    } else if ((hours > 24) && (hours < 48)) {
        // Less than 2 days
        category = 4;
    } else if ((hours > 48) && (hours < 120)) {
        // Less than 5 days
        category = 5;
    } else if ((hours > 120) && (hours < 168)) {
        // Less than 7 days
        category = 6;
    } else if (hours > 168) {
        // More than 7 days
        category = 7;
    }

    return { y: category, z: Numbers.roundNumber(hours, 1) };

}

function responseChartDataParse(data, type, types,  $translate) {
    var serie = {
        data: [],
        color: '#FFC200',
        name: resolveNameFromTypes(type, types)
    };


    // Sort the data:
    data.forEach(function (entry) {
        var sortPoints = 0;
        if (type == 'RESPONSE_OVERALL' && entry.ranking.overallResponseTime) {
            sortPoints = entry.ranking.overallResponseTime;
        } else if (entry.ranking.firstResponseTime) {
            sortPoints = entry.ranking.firstResponseTime;
        }
        entry.sortPoints = sortPoints;
    });

    var sortedAndFiltered = sortAndFilterData(data, true);

    var categories = [];

    sortedAndFiltered.sortedData.forEach(function (entry) {
        // Build the category
        categories.push(entry.user.name);
        if (type == 'RESPONSE_OVERALL') {
            serie.data.push(responseTimeToCategory(entry.ranking.overallResponseTime));
        } else {
            serie.data.push(responseTimeToCategory(entry.ranking.firstResponseTime));
        }

    });

    return {
        series: [serie],
        categories: categories,
        filtered: sortedAndFiltered.filteredData
    };
}

function resolveFieldValueFromType(entry, type) {
    if (type == 'OVERALL_ENGAGEMENT') {
        return Numbers.roundNumber(entry.ranking['engagementScore'], 2);
    } else if (type == 'OVERALL_SCORE') {
        return Numbers.roundNumber(entry.ranking['score'], 2);
    } else if (type.startsWith('SCORE')) {
        var scoreId = type.split('_')[1];
        return Numbers.roundNumber(entry.ranking.engagementScores[scoreId], 2);
    }

}

function genericChartDataParse(data, type, types, $translate) {
    var serie = {
        data: [],
        color: '#FFC200',
        name: resolveNameFromTypes(type, types)
    };
    var categories = [];

    // Sort the data:
    data.forEach(function (entry) {
        entry.sortPoints = resolveFieldValueFromType(entry, type);
    });

    var sortedAndFiltered = sortAndFilterData(data, false);

    sortedAndFiltered.sortedData.forEach(function (entry) {
        // Build the category
        categories.push(entry.user.name);
        serie.data.push(resolveFieldValueFromType(entry, type));
    });

    return {
        series: [serie],
        categories: categories,
        filtered: sortedAndFiltered.filteredData
    };
}

function activityChartDataParse(data, type, name, $translate) {
    var series = [];
    var categories = [];


    var serieNames = [
    {
        name: 'QUESTION_PUBLISHED',
        color: Style.managerActivityColors[1]
    },
    {
        name: 'ANNOUNCEMENT_PUBLISHED',
        color: Style.managerActivityColors[2]
    },
    {
        name: 'ANNOUNCEMENT_REPLY',
        color: Style.managerActivityColors[3]
    },
    {
        name: 'CONVERSATION_PARTICIPATED',
        color: Style.managerActivityColors[4]
    },
    {
        name: 'COMMENT_SIGNED',
        color: Style.managerActivityColors[5]
    },
    {
        name: 'REPLY_SIGNED',
        color: Style.managerActivityColors[6]
    },
    {
        name: 'RECOGNITION_PERFORMED',
        color: Style.managerActivityColors[7]
    }
    ];

    // If we receive a type:
    if (type != 'ACTIVITY_ALL') {
        var typeFilter = type.replaceAll('ACTIVITY_', '');
        serieNames = _.filter(serieNames, function (serieType) { return serieType.name == typeFilter; });
    }



    // Sort the data:
    data.forEach(function (entry) {
        entry.sortPoints = 0;
        serieNames.forEach(function (serieType) {
            if (entry.ranking.activityTypes && entry.ranking.activityTypes[serieType.name]) {
                entry.sortPoints = entry.sortPoints + entry.ranking.activityTypes[serieType.name];
            }
        });
    });

    var sortedAndFiltered = sortAndFilterData(data, false);


    sortedAndFiltered.sortedData.forEach(function (entry) {

        // Build the category
        categories.push(entry.user.name);

        serieNames.forEach(function (serieType) {
            var serieName = 'ACTIVITY_' + serieType.name;
            var serie = _.find(series, function (item) {
                return item.name == serieName;
            });

            if (!serie) {
                var serie = {
                    name: serieName,
                    data: [],
                    color: serieType.color
                };
                series.push(serie);
            }
            if (entry.ranking.activityTypes && entry.ranking.activityTypes[serieType.name]) {
                serie.data.push(entry.ranking.activityTypes[serieType.name]);
            } else {
                serie.data.push(0);
            }
        });

    });

    // Compute final name of series
    series.forEach(function (entry) {
        entry.name = $translate.instant(entry.name);
    });

    return {
        series: series,
        categories: categories,
        filtered: sortedAndFiltered.filteredData
    };
}

ManagersRankingCharts.managersRankingChart = function (data, type, types, $translate) {

    var chartData;
    if (type.startsWith('ACTIVITY')) {
        chartData = activityChartDataParse(data, type, types, $translate);
    } else if (type.startsWith('RESPONSE_')) {
        chartData = responseChartDataParse(data, type, types, $translate);
    } else {
        chartData = genericChartDataParse(data, type, types,  $translate);
    }


    var barHeight;
    if (chartData.categories.length == 0) {
        barHeight = 40;
    } else if (chartData.categories.length < 5) {
        barHeight = 100 * chartData.categories.length;
    } else {
        barHeight = 30 * chartData.categories.length;
    }


    var chartConfig =  {
        options: {
            chart: {
                type: 'bar',
                height: barHeight
            },
            title: {
                text: ''
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'categories',
                lineWidth: 0,
                gridLineWidth: 0,
                tickWidth: 0,
                enabled: true,
                showLastLabel: true,
                showFirstLabel: true,
                categories: chartData.categories,
                labels: {
                    step: 1
                }
            },
            yAxis: {
                lineWidth: 0,
                gridLineWidth: 0,
                tickWidth: 0,
                labels: {
                    enabled: true
                },
                title: {
                    enabled: false
                }

            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    grouping: false,
                    pointPadding: 0

                }
            },
            tooltip: {
                shared: true
            },
            lang: {
                noData: 'No rankings on this category'
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: '#303030'
                }
            }
        },
        series: chartData.series
    };

    if (type.startsWith('RESPONSE_')) {
        chartConfig.options.yAxis.type = 'categories';
        chartConfig.options.yAxis.categories = ['', '< 1h', '< 8h', ' <1d', '<2d', '<5d', '<7d', '>7d'];
        chartConfig.options.tooltip.formatter = function () {
            return '<b>' + this.x +
                '</b> avg. reply time is <b>' +  this.points[0].point.z + ' hours</b>';
        };
    }

    return {
        chart: chartConfig,
        filtered: chartData.filtered
    };
};

export default ManagersRankingCharts;
