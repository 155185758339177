'use strict';
function PillButton($scope) {
    'ngInject';

    var _this = this;

    _this.launchAction = function () {
        $scope.selectPill($scope.index);
        _this.action();
    };

}


const hfPillButton = angular.module('happyForceApp').directive('hfPillButton', function () {

    return {
        scope: {
            text: '@',
            action: '&',
            size: '@',
            type: '@?',
            selected: '<'
        },
        restrict: 'E',
        template: require('./pill-button.html'),
        replace: true,
        transclude: true,
        controllerAs: '$ctrl',
        bindToController: true,
        controller: PillButton,
        require: '^hfPillPanel',
        link: function ($scope, $element, $attrs, hfPillPanel) {
            $scope.index = hfPillPanel.addPill($scope);

            $scope.$on('$destroy', function () {
                hfPillPanel.removePill($scope);
            });

            $scope.selectPill = hfPillPanel.selectPill;

        }
    };
});

export default hfPillButton;
