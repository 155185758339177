import {Box, styled, Theme} from '@mui/material';
import * as React from 'react';

const getSize = (theme: Theme, size: string) => {
    switch (size) {
        case 'small':
            // @ts-expect-error: theme.overrides is not visible
            return theme.iconSizes.small;
        case 'medium':
            // @ts-expect-error: theme.overrides is not visible
            return theme.iconSizes.medium;
        case 'large':
            // @ts-expect-error: theme.overrides is not visible
            return theme.iconSizes.large;
        default:
            // @ts-expect-error: theme.overrides is not visible
            return  theme.iconSizes.medium;
    }
}

type ImageIconProps = {
    size: string;
    alt?: string;
    src: string;
}


const ImageBox = React.forwardRef(function ImageBox(props, ref) {
    return <Box ref={ref} component="img" {...props} />;
});

const ImageIcon = styled(ImageBox)<ImageIconProps>(({ theme, size }) => ({
    width: getSize(theme,size),
}));

export {ImageIcon}
