const UserService = function (Restangular, Settings, Rollbar, $translate) {
    'ngInject';
    var Company = Restangular.one('company');

    var methods = {};
    var Users = Company.one('users');

    methods.get = function (userId, callback) {
        Users
            .one(userId)
            .get()
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR getUsers', err);
                callback(err);
            });
    };

    methods.invite = function (user, linkToEmployee, callback) {

        if (user.role != 'MANAGER') {
            user.hierarchyIds = null; //Only managers belong to a hierarchy
        }

        Users.customPOST(user, 'invite', { linkToEmployee: linkToEmployee }, {})
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR inviteUser', err);
                callback(err);
            });

    };

    methods.update = function (user, linkToEmployee, callback) {

        if (user.role != 'MANAGER') {
            user.hierarchyIds = null; //Only managers belong to a hierarchy
        }

        Users.customPUT(user, user.id, { linkToEmployee: linkToEmployee }, {})
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR updateUser', err);
                callback(err);
            });

    };

    methods.delete = function (userId, callback) {
        Users
            .one(userId)
            .remove()
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR deleteUser', err);
                callback(err);
            });
    };

    methods.resendInvite = function (userId, callback) {
        Users
            .one(userId)
            .one('resend')
            .customPUT({})
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR resendInviteUser', err);
                callback(err);
            });
    };

    methods.list = function (params, callback) {
        Users
            .get(params)
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR listUsers', err);
                callback(err);
            });
    };

    methods.validatePassword = function (password, repeatPassword) {
        if (!password || password.length < 6) {
            return { errorCode: 1, errorMessage: $translate.instant('ACTIVATION_PASSWORD_SMALL') };
        } else if (!repeatPassword || password != repeatPassword) {
            return { errorCode: 2, errorMessage: $translate.instant('EDIT_PASS_ERROR') };
        } else {
            return null;
        }
    };

    return methods;
};

export default UserService;
