

import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official'; // Ensure the appropriate React wrapper is installed for Highcharts
import Style from '../../../../../utilities/style.utils';
import {getENPSGrouping} from '../../../../../shared/services/migrated/enps.service';
import EngagementCharts from '../../../engagement.charts';
import {angularize, getService} from 'reactInAngular';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import {Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, Link, MenuItem, Select} from '@mui/material';
import {
    CharacteristicName,
    GroupName,
    HierarchyName
} from '../../../../../shared/new-components/hf-segmentation-name/hfSegmentationName';
import async from 'async';
import {getHierarchyName} from '../../../../../shared/services/migrated/hierarchy.service';
import {dateToQueryParam} from '../../../../../utilities/navigation_state.utilities';

const EnpsPositioningBox = ({ to, mode, navigateToDetail }) => {
    const [positioningBy, setPositioningBy] = useState('HIERARCHIES');
    const [loading, setLoading] = useState(false);
    const [noTypes, setNoTypes] = useState(false);
    const [data, setData] = useState([]);
    const [bubbleChart, setBubbleChart] = useState(null);
    const [allSelected, setAllSelected] = useState(false);

    const positioningByOptions = [
        { id: 'HIERARCHIES', label: 'Areas', idField: 'hierarchyId' },
        { id: 'GROUPS', label: 'Groups', idField: 'groupId' },
        { id: 'CHARACTERISTICS', label: 'Segments' }
    ];

    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');
    const Languages = getService('Languages');



    const computeColor = count => {
        const remainder = count % Style.colors.length;
        return Style.colors[remainder === 0 ? Style.colors.length - 1 : remainder - 1];
    };

    const selectAll = () => {
        const newSelection = !allSelected;
        const newData = data.map(item => ({ ...item, selected: newSelection }));
        setData(newData);
        setAllSelected(newSelection);
    };

    const handleSelect = (item) => {


        const newData = data.map(dataItem => {

            const match =
                (item.hierarchyId && item.hierarchyId === dataItem.hierarchyId) ||
                (item.groupId && item.groupId === dataItem.groupId) ||
                (item.characteristic && item.characteristic.characteristicId === dataItem.characteristic.characteristicId && item.characteristic.value === dataItem.characteristic.value);



            if (match) {
                return { ...dataItem, selected: !dataItem.selected };
            }

            return dataItem;
        });
        setData(newData);
    };

    const gotoItemDetail =(item) => {

        var linkParams = {};

        linkParams.to = dateToQueryParam(to);

        if (item.hierarchyId) {
            linkParams.hierarchyId = item.hierarchyId;
        } else if (item.groupId) {
            linkParams.groupId = item.groupId;
        } else if (item.characteristic) {
            linkParams.filters = item.characteristic.characteristicId + ':' + item.characteristic.value;
        }
        navigateToDetail('engagement.enpsDetail', linkParams);
    };


    const renderChart = () => {
        if (!data.length) {
            return;
        } // Ensure data is loaded
        setAllSelected(data.every(item => item.selected));
        const chart = EngagementCharts.scorePositioningChart(data, -100, 100, 'eNPS', 'ENPS');
        setBubbleChart(chart);
    };

    const getSegmentationName = (segment, callback) => {
        if (positioningBy === 'HIERARCHIES') {
            getHierarchyName(segment.hierarchyId, (err, name) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, name);
                }
            });
        } else if (positioningBy === 'GROUPS') {
            callback(null, Groups.getGroupName(segment.groupId));
        } else if (positioningBy === 'CHARACTERISTICS') {
            let name = '';
            const characteristic = Characteristics.getCharacteristicById(segment.characteristic.characteristicId);
            if (characteristic) {
                name = `${characteristic.description}-${Languages.getTranslationFromUserLanguage(
                    Characteristics.getCharacteristicValueName(segment.characteristic.characteristicId, segment.characteristic.value)
                )}`;
            }
            callback(null, name);
        }
    }

    const loadResults = () => {
        setLoading(true);
        getENPSGrouping(positioningBy, { from: to, to: to }, (err, response) => {
            setLoading(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            } else {
                const hasData = response && response.results;
                setNoTypes(response.noTypes);
                if (hasData && response.results.length) {


                    let count = 0;
                    const stream = [];
                    response.results
                        .filter(item => (item.hierarchyId || item.groupId || item.characteristic))
                        .forEach(item => {
                            stream.push(next => {
                                const finalItem = {
                                    ...item,
                                    color: computeColor(++count),
                                    selected: true  // Initially, set all items as selected
                                }
                                getSegmentationName(item, (err, name) => {
                                    if (err) {
                                        next(err, null);
                                    } else {
                                        finalItem.name = name;
                                        next(null, finalItem);
                                    }
                                });
                            });
                        });


                    async.parallel(stream, (err, results) => {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                            return;
                        } else {
                            setData(results);
                        }
                    })
                }
            }
        });
    };

    useEffect(() => {
        renderChart();
    }, [data, allSelected]);

    useEffect(() => {
        loadResults();
    }, [to, mode, positioningBy]);

    const renderItemForType = (item) => {
        if (item.hierarchyId) {
            return (<HierarchyName id={item.hierarchyId}/>);
        } else if (item.groupId) {
            return (<GroupName id={item.groupId}/>
            );
        } else if (item.characteristic) {
            return (<CharacteristicName id={item.characteristic.id}
                valueId={item.characteristic.value}/>);
        } else {
            // TODO: log error
            return undefined;
        }
    };

    return (
        <section className="enps-positioning-box">

            <div className="row">
                <div className="col-xs-12 col-md-10">
                    <h2>{$translate.instant('ENPS_POSITIONING')}</h2>
                </div>
                <div className="col-xs-12 col-md-2">

                    <FormControl fullWidth>
                        <InputLabel id="positioning-by-label">{$translate.instant('SHOW_BY')}</InputLabel>
                        <Select
                            labelId="positioning-by-label"
                            value={positioningBy}
                            label="Show By"
                            onChange={event => {
                                setPositioningBy(event.target.value);
                            }}
                        >
                            {positioningByOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>


            <HappyLoading loading={loading} message={'Loading data...'} >

                {!noTypes && (
                    <div className="row">
                        <div className="col-xs-3">
                            <div className="small-style m-t-sm">
                                {$translate.instant('ENGAGEMENT_POSITIONING_FILTER')}
                            </div>
                            <div className="small-style m-t-sm">
                                <Link onClick={selectAll}>
                                    {allSelected ? $translate.instant('UNSELECT_ALL') : $translate.instant('SELECT_ALL' ) }
                                </Link>
                            </div>

                            <div className="m-t-xs positioning_chart_selector">
                                <FormGroup>

                                    {data.map(item => (
                                        <FormControlLabel
                                            key={item}
                                            control={<Checkbox checked={item.selected} onClick={() =>{handleSelect(item)}} />}
                                            label={renderItemForType(item)}>
                                        </FormControlLabel>

                                    ))}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-xs-9">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={bubbleChart}
                            />
                        </div>
                    </div>
                )}
                {noTypes && !loading && (
                    <div className="row">
                        <div className="col-xs-12">
                            {/* You can include your 'hf-no-segments' component here, translated to React */}
                        </div>
                    </div>
                )}
            </HappyLoading>
        </section>
    );
};

// Finally, we will use `angularize` to make the React component available in AngularJS applications.
angularize(EnpsPositioningBox, 'hfEngagementEnpsPositioningBox', angular.module('happyForceApp'), {
    to: '=',
    mode: '<',
    navigateToDetail: '='
});

export default EnpsPositioningBox;

