import React from 'react';
import {IconButton, Link} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HFTag from '../../hf-tag/hf-tag';
import HierarchyName from '../../hf-hierarchy-name';
import AreaSelectorPopover from './AreaSelectorPopover';


interface AreaClickableTagProps {
    hierarchyId?: string;
    updateHierarchyId: (hierarchyId: string) => void;
    onRemove?: () => void ;
    isAddButton?: boolean;
    addLabel?: string;
}
/*
    A utility component that renders a clickable tag that can be used to select an area.
    It displays the name of the area and an optional remove button.
    Also it can act as an add button that opens a popover to select an area.
 */
const AreaClickableTag = ({hierarchyId, updateHierarchyId, onRemove, isAddButton, addLabel} : AreaClickableTagProps) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const renderAddButton = () => {

        if (isAddButton) {

            if (addLabel) {
                return (
                    <Link
                        color="primary"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        sx={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}
                    >
                        <AddIcon/> {addLabel}
                    </Link>

                )
            }

            return (
                <IconButton aria-label="add"
                    color="primary"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                    }}>
                    <AddIcon/>
                </IconButton>
            )
        }

    };


    return (
        <>
            {!isAddButton &&  <HFTag
                text={<HierarchyName hierarchyId={hierarchyId}/>}
                onRemove={() => {
                    onRemove();
                }}
                onPress={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
                status={'primary'}/>}

            {renderAddButton()}

            <AreaSelectorPopover
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                hierarchyId={hierarchyId}
                updateHierarchyId={updateHierarchyId}/>
        </>
    );
};

export default AreaClickableTag;
