'use strict';

import Dates from "../../../../../utilities/date.utilities";

var DateTaggingFilterCtrl = function ($rootScope, $compile, $scope, Settings) {
    'ngInject'

    var _this = this;

    _this.pickerId = 'date-picker-id-' + Math.random().toString(36).substr(2, 9);

    _this.date = null;

    _this.initialized = false;


    function removeFilter(id) {
        // First, remove the element from the groups list
        _this.date = null;
        _this.notifyFilterChanges();
    }

    function renderTags() {
        // Based on the mode, we need to render the tags differently

        var dateString =  Dates.toAPIFormat(_this.date, _this.format);
        _this.tagFilterMenu.insertFilterTag(_this.type, _this.type, dateString, _this.typeLabel, _this.nonRemovable, removeFilter);
    }

    _this.prevent = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };

    _this.notifyFilterChanges = function () {

        var filtersNotifyMsg = {
            type: _this.type,
            filters: _this.date ? Dates.removeTimeZone(_this.date) : null
        };

        $rootScope.$broadcast('filterChange-' + _this.tagFilterMenu.filterId, filtersNotifyMsg);
    };

    // Load all groups
    _this.$onInit = function () {



        // If there are filters, render them
        if (_this.tagFilterMenu.filters && _this.tagFilterMenu.filters[_this.type]) {
            _this.date = _this.tagFilterMenu.filters[_this.type];
            renderTags();
        }

        // Now, update the picker with the configuration:


        if (!_this.view) {
            _this.view = 'date';
        }

        if (!_this.maxView) {
            _this.maxView = _this.view;
        }

        if (!_this.minView) {
            _this.minView = _this.view;
        }

        if (!_this.minDate) {
            _this.minDate = null;
        }

        if (!_this.maxDate) {
            _this.maxDate = null;
        }


        if (!_this.format) {
            _this.format = Settings.defaultDateFormat;
        }

        // $scope.$broadcast('pickerUpdate', _this.pickerId, pickerConfiguration);

        _this.initialized = true;

        $scope.$watch(function () {
            return _this.date;
        }, function (n, o) {
            if (_this.date != null) {
                renderTags();
                if (n != o) {
                    _this.notifyFilterChanges();
                }
            }

        });

    };
};

/*
    Here is an example of how to use this component:
        <hf-date-tagging-filter
                        label="{{'FROM' | translate}}"
                        type="from"
                        view="'month'"
                        max-view="'month'"
                        min-view="'month'"
                        format="'YYYY-MM'"
                        non-removable="true"
                ></hf-date-tagging-filter>

 */

const hfDateTaggingFilter = angular.module('happyForceApp').directive('hfDateTaggingFilter', function () {
    return {
        restrict: 'E',
        scope: {
            mode: '<',
            label: '@',
            typeLabel: '@',
            type: '@',
            nonRemovable: '<',
            maxView: '<',
            minView: '<',
            minDate: '@',
            maxDate: '@',
            view: '<',
            format: '<'
        },
        require: {
            tagFilterMenu: '^hfTagFilterMenu'
        },
        template: require('./date-tagging-filter.html'),
        controllerAs: '$ctrl',
        bindToController: true,
        transclude: false,
        replace: true,
        controller: DateTaggingFilterCtrl
    };
});

export default hfDateTaggingFilter;
