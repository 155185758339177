'use strict';

import firebase from "firebase";

function AnnouncementCreateModalController(Languages, $modalInstance) {
    'ngInject';

    var _this = this;
    _this.templates = [];
    _this.languages = [];
    _this.language = Languages.currentLanguage;
    _this.category = {};
    _this.loading = false;

    function loadFireBaseTemplates() {

        _this.loading = true;

        // Firebase announcements templates
        _this.reference = firebase.database().ref().child('templates').child('announcements');
        _this.reference.on('value', function (snapshot) {
            const fireBaseTemplates = snapshot.val();

            // Iterate over the languages
            Object.keys(fireBaseTemplates).forEach(function (lang) {

                var templatesLang = {
                    lang: lang,
                    categories: []
                };


                // Iterate over the categories
                Object.keys(fireBaseTemplates[lang]).forEach(function (category) {
                    var categoriesInLang = {
                        lang: lang,
                        name: category,
                        templates: []
                    };

                    // Iterate over the templates
                    Object.keys(fireBaseTemplates[lang][category]).forEach(function (templateId) {
                        categoriesInLang.templates.push({
                            id: templateId,
                            template: fireBaseTemplates[lang][category][templateId]
                        });
                    });

                    templatesLang.categories.push(categoriesInLang);

                });

                _this.templates.push(templatesLang);
                _this.loading = false;


            });
        }, function (errorObject) {
            _this.loading = false;
        });

    }

    _this.onCategorySelected = function (category) {
        _this.selectedCategory = category;
    };

    _this.onTemplateSelect = function (template) {

        delete _this.templateSourceAnnouncement;

        // This is a hack to adapt temporally old templates to new ones with multilanguage support
        if (template) {

            _this.templateSourceAnnouncement = angular.copy(template.template);

            _this.templateSourceAnnouncement.sectionId = _this.sectionId;

            if (!_this.templateSourceAnnouncement.message.defaultValue) {
                const message = angular.copy(_this.templateSourceAnnouncement.message);
                _this.templateSourceAnnouncement.message = {
                    defaultValue: message
                };
            }
            if (!_this.templateSourceAnnouncement.title.defaultValue) {
                const title = angular.copy(_this.templateSourceAnnouncement.title);
                _this.templateSourceAnnouncement.title = {
                    defaultValue: title
                };
            }

            if (_this.templateSourceAnnouncement.resume && !_this.templateSourceAnnouncement.resume.defaultValue) {
                const resume = angular.copy(_this.templateSourceAnnouncement.resume);
                _this.templateSourceAnnouncement.resume = {
                    defaultValue: resume
                };
            }

            if (!_this.templateSourceAnnouncement.coverImageURL) {
                _this.templateSourceAnnouncement.coverImageURL = 'https://assets.myhappyforce.com/defaultImages/' +
                    'announcement_contents/announcements_cover_image1.png';
            }

            if (_this.templateSourceAnnouncement.categoryIds) {
                delete _this.templateSourceAnnouncement.categoryIds;
            }


        }


        _this.selectedTemplate = template;
    };

    _this.createAnnouncementFromSelectedTemplate = function () {
        $modalInstance.close(_this.templateSourceAnnouncement);
    };

    _this.createEmptyAnnouncement = function () {

        $modalInstance.close(
            {
                title: {
                    defaultValue: ''
                },
                message: {
                    defaultValue: ''
                },
                resume: {
                    defaultValue: ''
                },
                cover: '',
                commentsEnabled: true,
                reactionsEnabled: true,
                notify: true,
                attachments: []
            }
        );


    };

    loadFireBaseTemplates();
};


const AnnouncementCreateModal = angular.module('happyForceApp')
    .factory('AnnouncementCreateModal',
        function ($uibModal) {
            var methods = {};

            methods.showModal = function (callback) {

                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'lg',
                    template: require('./announcement-create-modal.component.html'),
                    controller: AnnouncementCreateModalController,
                    controllerAs: '$ctrl'
                }).result.then(function (announcementTemplate) {
                    if (callback) {
                        callback(announcementTemplate);
                        console.log('Modal closed with ok');
                    }
                }, function (params) {
                    // If there is a callback, reload allays the comment, so we have a fresh version of it
                    if (callback) {
                        console.log('Modal closed with dismiss');
                    }
                });

            };

            return methods;
        }
    );


export default AnnouncementCreateModal;
