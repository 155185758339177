const ScoresAdvisorsService = function (Restangular) {
  'ngInject';
    var Scores = Restangular.one('company').one('advisor');

    var Categories = Restangular.one('company').one('catalog').one('scores').one('categories');

    var Providers = Restangular.one('company').one('catalog').one('scores').one('providers');

    var methods = {};
    methods.create = function (score, callback) {
        Scores.customPOST(score, 'scores').then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.update = function (score, callback) {
        Scores.one('scores').one(score.id).customPUT(score).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.delete = function (scoreId, callback) {
        Scores.one('scores').remove(scoreId).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.list = function (callback) {
        Scores.one('scores').get().then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    methods.get = function (scoreId, callback) {
        Scores.one('scores').one(scoreId).get().then(function (score) {
            callback(null, score);
        }, function (err) {

            callback(err);
        });
    };

    methods.export = function (callback) {
        Scores.one('scores').one('export').one('file').get().then(function (score) {
            callback(null, score);
        }, function (err) {
            callback(err);
        });
    };

    methods.providers = {};

    methods.providers.list = function (callback) {
        Providers.get().then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    methods.providers.get = function (providerId, callback) {
        Providers.one(providerId).get().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.providers.create = function (provider, callback) {
        Providers.customPOST(provider, '').then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.providers.delete = function (providerId, callback) {
        Providers.customDELETE(providerId).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.providers.update = function (provider, callback) {
        Providers.one(provider.id).customPUT(provider).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.categories = {};

    methods.categories.list = function (callback) {
        Categories.get().then(function (results) {
            callback(null, results);
        }, function (err) {

            callback(err);
        });
    };

    methods.categories.get = function (categoryId, callback) {
        Categories.one(categoryId).get().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.categories.create = function (category, callback) {
        Categories.customPOST(category, '').then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.categories.delete = function (categoryId, callback) {
        Categories.customDELETE(categoryId).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.categories.update = function (category, callback) {
        Categories.one(category.id).customPUT(category).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    return methods;
};

export default ScoresAdvisorsService;

