const MonitoringAlertsService = function (Restangular, Settings, Rollbar) {
  'ngInject';
    var Company = Restangular.one('company');

    var methods = {};
    var Rules = Company.one('monitoring').one('alarms');

    methods.getAlarms = function (params, callback) {
        Rules
            .get(params)
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR retrieving rule ', err);
                callback(err);
            });
    };

    return methods;
};

export default MonitoringAlertsService;

