'use strict'



angular.module('angularjs-stripe-elements', [])
    .provider('StripeElements', function () {
        this.apiKey = null;

        this.setAPIKey = function (apiKey) {
            this.apiKey = apiKey;
        };

        this.$get = function () {
            return Stripe(this.apiKey);
        };
    })
    .directive('stripeElementDecorator', function () {
        return {
            restrict: 'A',
            link: link
        };

        function link($scope, $element, $attr) {
            var $ctrl = $scope.$ctrl;

            $scope.$on('$destroy', function () {
                $ctrl.instance.destroy();
            });

            $ctrl.instance.mount($element[0]);
        }
    })
    .component('stripeElement', function () {
        return {
            template: '<div stripe-element-decorator></div>',
            controller: function () {},

            bindings: {
                instance: '<'
            }
        };
    });
