'use strict';

import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import Numbers from "../../../../../utilities/number.utilities";
import EngagementRankingBox from "../../../shared/ranking_box/ranking_box.controller";

const EngagementDetailEnpsBenchmark = ({ data, noData, mode }) => {
    const [companyBenchmark, setCompanyBenchmark] = useState(null);
    const [globalBenchmark, setGlobalBenchmark] = useState(null);
    const [industryBenchmark, setIndustryBenchmark] = useState(null);
    const [ranking, setRanking] = useState(null);
    const [value, setValue] = useState(null);

    const processData = () => {
        if (!data) {
            return;
        }

        if (mode === 'ENPS_MODE') {
            setCompanyBenchmark(data.companyBenchmark);
            setGlobalBenchmark(data.globalBenchmark);
            setIndustryBenchmark(data.industryBenchmark);
            setRanking(data.ranking);
            setValue(data.value);
        } else {
            setCompanyBenchmark(data.companyAverageBenchmark);
            setGlobalBenchmark(data.globalAverageBenchmark);
            setIndustryBenchmark(data.industryAverageBenchmark);
            setRanking(data.ranking);
            setValue(data.averageValue);
        }
    };

    const textForBenchmark = (value, benchmark, key) => {
        const difference = value - benchmark;
        const formattedDiff = Numbers.roundNumber(difference, 1);

        const keys = { benchmark: benchmark, difference: Math.abs(formattedDiff) };
        const $translate = getService('$translate');

        if (difference === 0) {
            return $translate.instant(key + '_NEUTRAL', keys);
        } else if (difference > 0) {
            return $translate.instant(key + '_POSITIVE', keys);
        } else {
            return $translate.instant(key + '_NEGATIVE', keys);
        }
    };

    useEffect(() => {
        processData();
    }, [data, mode]);

    if (noData) {
        return null;
    }

    return (
        <div>
            <div className={'m-t'}>
                <EngagementRankingBox ranking={ranking} type="ENPS" />
            </div>
            {mode === 'FILTERED' && (
                <div className="small-style m-t" dangerouslySetInnerHTML={{ __html: textForBenchmark(value, companyBenchmark, 'BENCHMARK_COMPANY') }}/>
            )}
            <div className="small-style m-t" dangerouslySetInnerHTML={{ __html: textForBenchmark(value, globalBenchmark, 'BENCHMARK_GLOBAL') }}></div>
            <div className="small-style m-t" dangerouslySetInnerHTML={{ __html: textForBenchmark(value, industryBenchmark, 'BENCHMARK_INDUSTRY') }}></div>
        </div>
    );
};

angularize(EngagementDetailEnpsBenchmark, "hfEngagementEnpsBenchmark", angular.module("happyForceApp"), {
    data: '=',
    noData: '<',
    mode: '<'
});

export default EngagementDetailEnpsBenchmark;

