var FeedbackIdeasUtils = FeedbackIdeasUtils || {};

FeedbackIdeasUtils.getStatusColorAndLabel = function (status) {
    if (status === 'ARCHIVED') {
        return {
            statusLabel: 'INITIATIVE_ARCHIVED',
            statusStyle: 'badge-danger'
        };
    } else if (status === 'DRAFT') {
        return {
            statusLabel: 'INITIATIVE_DRAFT',
            statusStyle: 'badge-gray'
        };
    } else if (status === 'EXPIRED') {
        return {
            statusLabel: 'INITIATIVE_EXPIRED',
            statusStyle: 'badge-danger'
        };
    } else if (status === 'SCHEDULED') {
        return {
            statusLabel: 'INITIATIVE_SCHEDULED',
            statusStyle: 'badge-warning'
        };

    } else if (status === 'ONGOING') {
        return {
            statusLabel: 'INITIATIVE_ONGOING',
            statusStyle: 'badge-green'
        };
    }
};

export default FeedbackIdeasUtils;
