import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import { Box, Typography, Link } from '@mui/material';

const NoSegmentsEmptyState = ({ type }) => {
    const AuthSrvc = getService('AuthSrvc');
    const $translate = getService('$translate');
    const [hasAdminRoles, setHasAdminRoles] = useState(false);

    useEffect(() => {
        setHasAdminRoles(AuthSrvc.isADVISOR() || AuthSrvc.isADMIN());
    }, []);

    const titleKey = `NO_SEGMENTS_${type}_TITLE`;
    const descriptionKey = `NO_SEGMENTS_${type}_DESCRIPTION`;
    const ctaKey = `NO_SEGMENTS_${type}_CTA`;

    const sectionMapping = {
        'HIERARCHIES': 3,
        'CHARACTERISTICS': 4,
        'GROUPS': 5
    };

    return (
        <Box className="row">
            <Box className="col-xs-12 text-center">
                <img src="/images/no_segments.svg" alt="" className="src" />
                <Typography variant="h1" className="p-t">
                    {$translate.instant(titleKey)}
                </Typography>
                <Typography variant="body2" className="small-style p-t">
                    {$translate.instant(descriptionKey)}
                </Typography>
                {hasAdminRoles && sectionMapping[type] && (
                    <Typography component="p" className="p-t">
                        <Link href={`#/company/config/${sectionMapping[type]}`}>
                            {$translate.instant(ctaKey)}
                        </Link>
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

angularize(NoSegmentsEmptyState, 'hfNoSegments', angular.module('happyForceApp'), {
    type: '='
});

export default NoSegmentsEmptyState;
