'use strict';

function CommentInlineCtrl($scope, Comments, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.inappropiateCallback = function (err, updatedComment) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
        } else {
            _this.comment = updatedComment;
        }
    };
}




// This component is used just for draw comments on tables
const hfCommentInline = angular.module('happyForceApp')
    .directive('hfCommentInline', function () {
        return {
            scope: {
                comment: '=',
                maxLength: '='
            },
            template: require('./comment-inline.html'),
            controller: CommentInlineCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });


export default hfCommentInline;

