const CountryService = function ($timeout, $http) {
    var CountryFactory = {
        fetch: function () {
            return $timeout(function () {
                return $http.get('defaults/countries.json').then(function (response) {
                    return response.data;
                });
            }, 30);
        }
    };

    return CountryFactory;
};

export default CountryService;
