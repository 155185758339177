'use strict';

import Style from "../../../../../utilities/style.utils";

function SurveyFeedbackItemCtrl(Surveys, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.commentUpdated = function (err, updatedComment) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            _this.comment = updatedComment;
        }
    };

    var calculatePunctuationColor = function (value) {
        if (value > 6) {
            return Style.excellent;
        } else if (value > 5) {
            return Style.warning;
        } else {
            return Style.bad;
        }
    };


    this.$onInit = function () {

        // Conversation link modal, will be only displayed based on setting value
        if (typeof _this.showConversationLink != 'undefined') {
            _this.displayConversationLink = _this.showConversationLink;
        } else {
            _this.displayConversationLink = true;
        }


        if (_this.comment.surveyId) {
            Surveys.get(_this.comment.surveyId, function (err, survey) {
                _this.surveyTitle = survey.title;

                if (survey.schedulePlan.activationRule.ruleType != 'CONTINUOUS') {
                    // ITerate over the rounds:
                    var foundRound =  survey.rounds.find(function (round) {
                        return round.id = _this.comment.roundId;
                    });

                    if (foundRound) {
                        _this.roundTitle = foundRound.title;
                    }
                }
            });
        }

        _this.score = _this.comment.votes[0].score;
        _this.voteColor = calculatePunctuationColor(_this.score);

    };
}

const hfSurveyFeedbackItem = angular.module('happyForceApp').directive('hfSurveyFeedbackItem', function () {
    return {
        scope: {
            hideHeader: '<',
            comment: '=',
            showConversationLink: '<?',
            showReplyWithAnnouncementLink: '<?',
            size: '<'
        },
        restrict: 'E',
        template: require('./survey_feedback_item.html'),
        controller: SurveyFeedbackItemCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfSurveyFeedbackItem;
