'use strict';

import Style from "../../../../utilities/style.utils";
import Numbers from "../../../../utilities/number.utilities";


import template from './survey_top_selected_options.html';

function SurveyTopSelectedOptionsCtrl($translate, Languages) {
    var _this = this;


    function prepareResults(values) {

        var maxScore = 0;
        _this.topValues = [];

        values.sort(function (first, second) {
            var firstVotes = _this.result && _this.result.ratings && _this.result.ratings[first.id] ? _this.result.ratings[first.id] : 0;
            var secondVotes = _this.result && _this.result.ratings && _this.result.ratings[second.id] ? _this.result.ratings[second.id] : 0;
            return secondVotes - firstVotes;
        }).slice(0, 3).forEach(function (value) {

            var topValue = {
                textColor: Style.white
            };

            if (!value.color) {
                topValue.color = Style.colorGradient(((value.score ? value.score : value.id) / maxScore), Style.bad, Style.warning, Style.excellent);
            } else {
                topValue.color = value.color;
            }

            var valueTranslation = Languages.getTranslationFromUserLanguage(value.label);

            if (valueTranslation.length > 10) {
                topValue.showPopover = true;
                topValue.text =  valueTranslation.slice(0, 7) + '...';
            } else {
                topValue.text =  valueTranslation;
            }

            topValue.fullText = valueTranslation;





            if (_this.result && _this.result.ratings && _this.result.ratings.hasOwnProperty(value.id) && _this.result.ratings[value.id] > 0) {
                topValue.y = Numbers.roundNumber(Numbers.calculatePercent(_this.result.ratings[value.id], _this.result.votes), 1);
                topValue.votes = _this.result.ratings[value.id];
            } else {
                topValue.y = 0;
                topValue.votes = 0;

            }

            _this.topValues.push(topValue);

        });

    };



    this.$onInit = function () {

        if (_this.question.questionType === 'TOPIC') {
            var valueList = [];

            _this.question.values.forEach(function (value) {
                valueList.push({
                    id: value.id,
                    score: value.score,
                    color: value.color,
                    label: value.label
                });
            });

            prepareResults(valueList);
        } else if (_this.question.questionType === 'RANGE') {

            var numItems = _this.question.lastValue.id;
            var scoreUnit = _this.question.lastValue.score / numItems;
            scoreUnit = parseFloat(scoreUnit.toFixed(2));

            var valueList = [_this.question.firstValue];
            for (var i = _this.question.firstValue.id + 1; i < _this.question.lastValue.id; i++) {
                valueList.push({
                    id: i,
                    score: i * scoreUnit,
                    label: {
                        defaultValue: i
                    }
                });
            }

            valueList.push(_this.question.lastValue);
            valueList.reverse();
            prepareResults(valueList);
        } else {

            var valueList = [];

            [1, 2].forEach(function (value) {
                valueList.push({
                    id: value,
                    score: (_this.result.ratings && _this.result.ratings.hasOwnProperty(value)) ? _this.result.ratings[value] : 0,
                    label: {
                        defaultValue: (value == 1 ? $translate.instant('COMMENTED') : $translate.instant('NOT_COMMENTED'))
                    }
                });
            });


            prepareResults(valueList);


        }


    };



};

const hfSurveyTopSelectedOptions = angular.module('happyForceApp')
    .directive('hfSurveyTopSelectedOptions', function () {
        return {
            scope: {
                survey: '<',
                result: '<',
                question: '<'
            },
            template: template,
            controller: SurveyTopSelectedOptionsCtrl,
            controllerAs: 'tpvalues',
            bindToController: true
        };
    });

export default hfSurveyTopSelectedOptions;
