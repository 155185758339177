import Dates from '../../../../../utilities/date.utilities';
import {APISettings} from '../../../../../shared/services/migrated/axiosWrapper';
import Numbers from '../../../../../utilities/number.utilities';
import Style from '../../../../../utilities/style.utils';
import Charts from '../../../../../utilities/charts.utilities';
import {scoreZones} from '../../utils/chartZones';
import moment from 'moment/moment';
import {getService} from '../../../../../../migration_utils/react-in-angular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React, {useEffect} from 'react';
import {ISegmentInfo} from '../../../../../shared/new-components/hf-heatmap/heatmap-types';
import {ScoreName} from '../../../../../shared/new-components/hf-score-name/hfScoreName';
import {SegmentFilter} from '../../utils/filteringUtils';
import useScoreNames from '../../../../../shared/hooks/useScoreNames';
import useCompanyNames from '../../../../../shared/hooks/useCompanyNames';


interface ScoreEvolutionChartProps {
    scoreId: string;
    dateGrouping: string;
    scoreData: any | null;
    segmentationFilter: SegmentFilter | null;
    onSelectResult: (result: any) => void;
}

const ScoreEvolutionChart = ({ scoreId, dateGrouping, scoreData, onSelectResult } : ScoreEvolutionChartProps) => {

    const $translate = getService('$translate');

    const [showCompanyBenchmark, setShowCompanyBenchmark] = React.useState<boolean>(false);
    const [showIndustryBenchmark, setShowIndustryBenchmark] = React.useState<boolean>(false);
    const [showGlobalBenchmark, setShowGlobalBenchmark] = React.useState<boolean>(false);

    const [chartConfig, setChartConfig] = React.useState<any>(null);
    const [hasData, setHasData] = React.useState<boolean>(false);
    const [selectedResult, setSelectedResult] = React.useState<number | null>();
    const { scoreName, factorNames, scoreNamesLoading } = useScoreNames(scoreId);
    const {companyName, industryName, companyInfoLoading} = useCompanyNames();


    const generateChartConfig = () => {
        const scoreSeriesData = [];
        const globalBenchmarkSeriesData = [];
        const industryBenchmarkSeriesData = [];
        const companyBenchmarkSeriesData = [];
        const scoreRangesSeriesData = [];

        const factorsSeriesData = {};


        let dataMin = Number.MAX_VALUE;
        let dataMax = Number.MIN_VALUE;

        const offset12Hours = 12 * 3600 * 1000;
        const scoreMin = 0;
        const scoreMax = 10;

        scoreData.results.forEach((item, index) => {
            const date = Dates.fromAPIFormat(item.from, APISettings.apiDateFormat).getTime() + offset12Hours;
            const result = item.result ? Numbers.roundNumber(item.result, 1) : null;
            const benchmark = item.globalBenchmark ? Numbers.roundNumber(item.globalBenchmark, 1) : null;
            const industryBenchmark = item.industryBenchmark ? Numbers.roundNumber(item.industryBenchmark, 1) : null;
            const companyBenchmark = item.companyBenchmark ? Numbers.roundNumber(item.companyBenchmark, 1) : null;

            const minVal = Math.min(result, benchmark);
            dataMin = Math.min(dataMin, minVal);
            const maxVal = Math.max(result, benchmark);
            dataMax = Math.max(dataMax, maxVal);

            scoreSeriesData.push([date, result]);
            globalBenchmarkSeriesData.push([date, benchmark]);
            industryBenchmarkSeriesData.push([date, industryBenchmark]);
            companyBenchmarkSeriesData.push([date, companyBenchmark]);


            if (result) {


                // Función para calcular percentiles
                function percentile(arr, p) {
                    arr.sort((a, b) => a - b);
                    const index = (p / 100) * (arr.length - 1);
                    if (Math.floor(index) === index) {
                        return arr[index];
                    }
                    const lower = arr[Math.floor(index)];
                    const upper = arr[Math.ceil(index)];
                    return lower + (upper - lower) * (index - Math.floor(index));
                }


                const stdDeviation = Math.sqrt(Object.entries(item.distribution).reduce((acc, [value, votes]) => {
                    return acc + votes * Math.pow(value - result, 2);
                }, 0) / result);



                const lowRange = Math.max(0, result - stdDeviation);
                const highRange = Math.min(10, result + stdDeviation);

//                scoreRangesSeriesData.push([date, lowRange, highRange]);



                const valores = [];
                Object.entries(item.distribution).forEach(([valor, votos]) => {
                    for (let i = 0; i < votos; i++) {
                        valores.push(Number(valor));
                    }
                });
                const q1 = Math.min(result, percentile(valores, 25));
                const q3 =  Math.max(result, percentile(valores, 75));


                console.log('q1: ' + q1 + ' q2: ' + q3 + ' score: ' + result);


                scoreRangesSeriesData.push([date, q1, q3]);



            }


            // Let's add the factors
            if (item.factors) {
                item.factors.forEach((factor) => {
                    if (!factorsSeriesData[factor.id]) {
                        factorsSeriesData[factor.id] = {
                            name: factorNames[factor.id],
                            data: []
                        };
                    }

                    const factorResult = factor.result ? Numbers.roundNumber(factor.result, 1) : null;


                    factorsSeriesData[factor.id].data.push([date, factorResult]);
                });
            }
        });

        dataMin = Math.floor(dataMin - 0.1);
        dataMax = Math.ceil(dataMax + 0.1);
        dataMin = Math.max(scoreMin, dataMin);
        dataMax = Math.min(scoreMax, dataMax);

        dataMin = 0;
        dataMax = 10;

        Charts.initializeDateFormats();

        const series:[any] = [
            {
                type: 'spline',
                name: scoreName,
                data: scoreSeriesData,
                zones: scoreZones,
                color: Style.scoreLineColor,
                dashStyle: 'Solid',
                step: 'undefined',
                connectNulls: true,
                lineWidth: 2,
                showInLegend: false,
                marker: {
                    enabled: true,
                    radius: 1,
                    states: {
                        hover: {
                            enabled: false,
                        },
                        select: {
                            enabled: true,
                            radius: 6,
                            lineWidth: 0,
                            fillColor: Style.scoreLineColor,
                        },
                        normal: {

                        }
                    }
                },
                allowPointSelect: true,
                point: {
                    events: {
                        select: function (e) {
                            e.target.selected = true;
                            setSelectedResult(e.target.index);
                        }
                    }
                }
            }
        ];

        series.push({
            name: 'Range',
            data: scoreRangesSeriesData,
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            color: Highcharts.getOptions().colors[0],
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
                enabled: false
            }
        });

        Object.keys(factorsSeriesData).forEach((factorId) => {
            const factor = factorsSeriesData[factorId];
            series.push({
                type: 'spline',
                name: factorNames[factorId],
                data: factor.data,
                zones: scoreZones,
                color: Style.scoreLineColor,
                dashStyle: 'Dot',
                step: 'undefined',
                connectNulls: true,
                lineWidth: 2,
                marker: {
                    enabled: false
                },
                visible: false
            });
        });




        series.push({
            id: 'benchmark',
            type: 'line',
            name: 'Happyforce',
            data: globalBenchmarkSeriesData,
            connectNulls: true,
            color: Style.benchmarkLineColor,
            dashStyle: 'dash',
            step: 'right',
            lineWidth: 1,
            marker: {
                enabled: false
            },
            visible: false,
            showInLegend: true
        });

        series.push({
            linkedTo: 'benchmark',
            type: 'line',
            name: $translate.instant('ALL_COMPANY', {companyName: companyName}),
            data: companyBenchmarkSeriesData,
            connectNulls: true,
            color: '#9A9A9A',
            dashStyle: 'dash',
            step: 'right',
            lineWidth: 1,
            marker: {
                enabled: false
            },
            visible: false,
            showInLegend: false
        });

        series.push({
            linkedTo: 'benchmark',
            type: 'line',
            name: industryName,
            data: industryBenchmarkSeriesData,
            connectNulls: true,
            color: Style.industryBenchmarkLineColor,
            dashStyle: 'dash',
            step: 'right',
            lineWidth: 1,
            marker: {
                enabled: false
            },
            visible: false,
            showInLegend: false
        });

        const chartOptions =  {
            chart: {
                type: 'spline',
                height: 250,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                fontFamily: 'HelveticaNeue-Light'
            },
            title: null,
            plotOptions: {
                series: {
                    lineWidth: 4,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: Charts.getAxisDateFormatFromDateGrouping(dateGrouping),
                startOnTick: false,
                title: {
                    enabled: false
                }
            },
            yAxis: {
                tickColor: '#b5bbc3',
                title: {
                    enabled: false
                },
                gridLineWidth: 1,
                gridLineDashStyle: 'Dot',
                labels: {
                    style: {
                        color: '#b5bbc3'
                    }
                },
                min: dataMin,
                max: dataMax,
                tickInterval: scoreMax / 5
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: true,
                useHTML: true,
                itemDistance: 20,
                layout: 'horizontal',
                labelFormatter: function () {
                    if (this.index === 0) {
                        return this.name;
                    }

                    if (this.options?.id != 'benchmark') {
                        let icon = '';
                        if (this.visible) {
                            icon += '<img src="/images/icon-eye-visible.svg" alt="">';
                        } else {
                            icon += '<img class="legend-item-hidden" src="/images/icon-eye-hidden.svg" alt="">';
                        }

                        return this.name + ' ' + icon;
                    } else {
                        return $translate.instant('CHART_SHOW_BENCHMARKS');
                    }

                }
            },
            tooltip: {
                enabled: true,
                pointFormat: '<span style="font-size: 10px">{series.name}: <b>{point.y}</b></span>',
                headerFormat: '<b>{point.x:' + Charts.getDateFormatFromDateGrouping(dateGrouping) + '}</b>',
                split: true,
                crosshairs: true,
                shared: true
            },
            series: series
        };

        if (!selectedResult|| selectedResult > scoreData.results.length -1) {
            setSelectedResult(scoreData.results.length -1);
        }

        return chartOptions;
    };


    useEffect(() => {
        if (!scoreNamesLoading && !companyInfoLoading) {
            if (scoreData && scoreData?.results?.length > 0) {
                setHasData(true);
                setChartConfig(generateChartConfig());
            } else {
                setHasData(false);
                setChartConfig(null);
            }
        }
    }, [scoreData, scoreNamesLoading, companyInfoLoading]);

    useEffect(() => {
        if (selectedResult) {
            onSelectResult(scoreData.results[selectedResult]);
        } else {
            onSelectResult(null);
        }

    }, [selectedResult]);

    return (
        <>
            {!hasData && (
                <h2 className="ultra-style score-no-data p-t text-center">
                    {$translate.instant('SCORE_NO_DATA')}
                </h2>
            )}
            {hasData && (
                <HighchartsReact highcharts={Highcharts} options={chartConfig} />
            )}
        </>
    );


};

export default ScoreEvolutionChart;
