import { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, Box, ListItemText, Typography } from '@mui/material';

interface GroupNestedMenuItemProps {
    filters?: any;
    setFilters?: (filters: any) => void;
    open?: boolean;
    mode?: string;
    title?: string;
    nonRemovable: boolean;
}

const GroupNestedMenuItem = ({ filters, setFilters, open, mode, title, nonRemovable }: GroupNestedMenuItemProps) => {
    const Groups = getService('Groups');
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const ErrorSvrc = getService('ErrorSvrc');

    useEffect(() => {
        Groups.getAll({ active: true }, function (err, groupsData) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setGroups(groupsData);
            }
        });
    }, []);

    //Used to load selected groups from filters
    useEffect(() => {
        if (mode === 'multi') {
            const initialSelectedGroups = [];
            if (filters && filters.length > 0) {
                filters.forEach((filter) => {
                    initialSelectedGroups.push(filter);
                });
                setSelectedGroups(initialSelectedGroups);
            }
        } else if (mode === 'single') {
            if (filters && filters.length > 0) {
                setSelectedGroup(filters[0]);
            }
        }
    }, [filters]);

    //handle with remove/add
    const handleFilter = (item) => {
        item.option = title;
        item.nonRemovable = nonRemovable;

        if (mode === 'multi') {
            if (filters && filters.findIndex(e => e.id === item.id) > -1) {
                setFilters(filters => filters.filter(filterItem => filterItem.id !== item.id));
            } else {
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else if (mode === 'single') { //DONE
            //Check if exists one of the type
            if (filters.findIndex(e => e.type === item.type) > -1) {
                //If nonRemovable, check if do nothing or replace
                if (nonRemovable) {
                    //If id is the same
                    if (filters && filters.findIndex(e => e.id === item.id) > -1) { //Do nothing
                        return;
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                } else {
                    if (filters && filters.findIndex(e => e.id === item.id) > -1) { //Same id, remove it
                        setFilters(filters => filters.filter(filterItem => filterItem.type !== item.type));
                    } else { //Replace
                        const updatedFilters = filters.map(filter => filter.type === item.type ? item : filter);
                        setFilters(updatedFilters);
                    }
                }
            } else { //No same type, add it
                setFilters(optionFilters => [...optionFilters, item]);
            }
        } else {
            setFilters(optionFilters => [...optionFilters, item]);
        }
    }

    const handleCheckboxChange = (item) => {
        handleFilter(item);
        setSelectedGroups((prevSelected) => {
            const itemExists = prevSelected.some(e => e.id === item.id);

            if (itemExists) {
                return prevSelected.filter(e => e.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const handleRadioChange = (item) => {
        handleFilter(item);

        if (selectedGroup && nonRemovable) {
            return;
        } else {
            if(selectedGroup === item){
                setSelectedGroup(null);
            } else {
                setSelectedGroup(item);
            }
        }
    };

    const renderGroups = (items) => {
        if (!items) {
            return null;
        }

        if (mode === 'multi') {
            return items.map((item, index) => {
                const isChecked = selectedGroups.findIndex(e => e.id === item.id) > -1;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleCheckboxChange(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isChecked} />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderGroups(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleCheckboxChange(item)}>
                            <Checkbox
                                checked={isChecked} /><ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return items.map((item, index) => {
                const isSelected = selectedGroup ? (selectedGroup as { id: string }).id === item.id : false;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleRadioChange(item)}
                            renderLabel={() => <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Radio
                                    checked={isSelected}
                                />
                                <ListItemText primary={
                                    <Typography style={{ fontSize: '14px' }}>
                                        {item.name}
                                    </Typography>
                                } />
                            </Box>}
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderGroups(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            sx={{ paddingLeft: 1 }}
                            key={index}
                            onClick={() => handleRadioChange(item)}
                        >
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            //TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderGroups(groups)}
        </NestedMenuItem>
    );
};

export default GroupNestedMenuItem;
