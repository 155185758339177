'use strict';

function SurveyResultHeatmapDetailControler($scope, $modalInstance, survey, question, result) {

    this.survey = survey;
    this.question = question;
    this.result = result;

    $scope.ok = function () {
        $modalInstance.dismiss('close');
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };


}



const SurveyResultHeatmapDetailCtrl = angular.module('happyForceApp')
    .controller('SurveyResultHeatmapDetailCtrl', SurveyResultHeatmapDetailControler);

export default SurveyResultHeatmapDetailCtrl;
