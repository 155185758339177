'use strict';

function SettingsCompanyInfoCtrl($scope, AuthSrvc, CompanySvrc, $translate, $window, ErrorSvrc, Languages, Analytics, HIService, toaster) {


    $scope.setCompanyLogo = function (file) {
        $scope.uploading = true;
        $scope.companySvrc.setCompanyImage(file,
            function (err, updatedCompany) {
                $scope.uploading = false;
                $scope.uploadProgress = 0;
                if (err) {
                    $scope.uploading = false;
                    ErrorSvrc.showErrorModal(err);
                    console.error('Error uploading file');
                } else {
                    $scope.companyDetails = updatedCompany;
                    toaster.pop('success', null, ($translate.instant('COMPANY_LOGO_UPDATED')));
                }

            }, function (loaded, total) {

                $scope.uploadProgress = 100 * loaded / total;
            });
    };

    Analytics.trackEvent('settings_company_info');
}


const hfCompanyInfo =  angular.module('happyForceApp')
    .directive('hfCompanyInfo', function () {
        return {
            template: require('./settings_company_info.html'),
            controller: SettingsCompanyInfoCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfCompanyInfo;
