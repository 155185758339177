import React from 'react';

import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import {getService} from 'reactInAngular';

// Assuming utils or services used to translate, as there's no $translate in React


const ClassificationFlowChart = ({ values }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const $translate = getService('$translate');

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const gridColumns = (values || []).map(() => 'auto').join(' ');

    return (
        <section className="classification_flow_chart-box">
            <div style={{ gridTemplateColumns: gridColumns }} className="p-t-sm grid-container">
                {values.map((item, index) => (
                    <div key={index} className="grid-item">
                        {item.type === 'category' && (
                            <div className="grid-item-content">
                                <div className="classification-bullet">
                                    <i className="fa fa-circle" style={{ color: item.color }} />
                                </div>
                                <div className="classification-label">
                                    <div className="medium-style">{item.number}</div>
                                    <div className="small-style m-t-xs">{item.label}</div>
                                </div>
                            </div>
                        )}
                        {item.type === 'arrow' && (
                            <div
                                className={`medium-style classification-arrow ${item.flow >= 0
                                    ? 'classification-arrow-left'
                                    : 'classification-arrow-right'}`}
                            >
                                {item.flow}
                                <IconButton
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <InfoIcon />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <div className="popover-content">
                                        {item.flowItems.map((flowItem, index) => (
                                            <div key={index} className="m-b-xs">
                                                {$translate.instant('CLASSIFICATION_FLOW_CHART_ARROW_ITEMS', {
                                                    source: flowItem.sourceLabel,
                                                    target: flowItem.targetLabel,
                                                    count: flowItem.count,
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                </Popover>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

ClassificationFlowChart.propTypes = {
    values: PropTypes.array.isRequired,
};

export default ClassificationFlowChart;
