const UnsplashService = function ($http) {
    'ngInject';
    var methods = {};

    methods.searchImages = function (search, itemsPerPage, callback) {

        $http.get('https://api.unsplash.com/search/photos' +
            '?client_id=8Gx93oXHEGtDrnNQa9wt1EDRksH11Q2N8XMKA4g9z7E&orientation=landscape&page=1&query='
            + search + '&per_page=' + itemsPerPage).then(function (response) {
            //handle your response here
            if (response.status === 200) {
                var results = [];
                if (response.data.results) {
                    response.data.results.forEach(function (result) {
                        results.push({
                            name: result.user.name,
                            image: result.urls.full + '&q=100&fit=crop&w=1024&h=720&fm=jpg'
                        });
                    });
                    callback(null, results);
                }
            } else if (response.errors) {
                callback(response.errors[0]);
            } else {
                callback({ errorCode: 'FAILURE_LOADING_IMAGES' });
            }
        });
    };

    return methods;
};

export default UnsplashService;
