'use strict'

function FeedbackIdeasRowCtrl(AuthSrvc, Analytics, Comments, People, ErrorSvrc) {
    'ngInject';

    var _this = this;

    _this.isAdvisor = AuthSrvc.isADVISOR();

    _this.showReplies = function () {
        $('#intercom-container').hide();
        Comments.operations.showFeedback('replies', _this.feedbackIdea, function (err, data) {
            $('#intercom-container').show();
            Analytics.trackEvent('feedback_ideas', 'show_replies');
        });
    };


    _this.commentUpdatedCallback = function (err, updatedComment) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            _this.feedbackIdea = updatedComment;
        }
    };

    _this.containerUpdated = function () {
        if (_this.onChange)
            _this.onChange();
    };



    // Retrieve the attached conversation user, so we can render his/her name
    this.$onInit = function () {
        // Save the vote path, so we can pass it to the user-small directive
        if (_this.feedbackIdea.votes && _this.feedbackIdea.votes[0].imageUrl) {
            _this.voteImage = _this.feedbackIdea.votes[0].imageUrl;
        };

        if (_this.feedbackIdea.conversationOwnerId) {
            People.getProfile(_this.feedbackIdea.conversationOwnerId, function (err, user) {
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else
                    _this.conversationOwner = user.name;;
            });
        }
    };



}


const hfFeedbackIdeasRowItem = angular.module('happyForceApp')
    .directive('hfFeedbackIdeasRowItem', function () {
        return {
            scope: {
                feedbackIdea: '=',
                onChange: '='
            },
            template: require('./feedback-idea-row.html'),
            controller: FeedbackIdeasRowCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeasRowItem;
