
import Dates from "../../utilities/date.utilities";

export default function Conversation(Restangular, Settings, Rollbar) {
    'ngInject';
    var Company = Restangular.one('company');

    var methods = {};
    var Conversation = Company.one('conversations');

    methods.sendMessage = function (conversationId, message, callback) {
        Conversation
        .one('message')
        .customPOST({ message: message }, conversationId)
        .then(function (data) {
            callback(null, data);
        }, function (err) {

            Rollbar.error('ERROR replyConversation ', err);
            callback(err);
        });
    };

    methods.startConversationOnComment = function (commentId, message, callback) {
        Conversation
            .one('init')
            .customPOST({
                commentId: commentId,
                originType: 'COMMENT',
                message: message })
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR starting conversation ', err);
                callback(err);
            });
    };

    methods.startConversationOnPersonStatus = function (personKey, statusDate, message, callback) {
        Conversation
            .one('init')
            .customPOST({
                personKey: personKey,
                statusDate: statusDate,
                originType: 'ENGAGEMENT_STATUS',
                message: message })
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR starting conversation ', err);
                callback(err);
            });
    };

    methods.get = function (conversationId, callback) {
        Conversation
          .one(conversationId)
          .get()
          .then(function (data) {
            callback(null, data);
        }, function (err) {

            Rollbar.error('ERROR getConversation ', err);
            callback(err);
        });
    };

    methods.getAll = function (params, callback) {
        var _params = angular.copy(params);
        if (params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }
        if (params.to) {
            _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }
        Conversation
          .get(_params)
          .then(function (data) {
            callback(null, data);
        }, function (err) {

            Rollbar.error('ERROR getConversation ', err);
            callback(err);
        });
    };

    methods.getPreview = function (params, callback) {
        var _params = angular.copy(params);
        if (params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }
        if (params.to) {
            _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }
        Conversation
            .one('preview')
            .get(_params)
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR getConversation ', err);
                callback(err);
            });
    };

    methods.getPending = function (callback) {
        Conversation
           .one('pending')
          .get()
          .then(function (data) {
            callback(null, data);
        }, function (err) {
            console.log('ERROR getPending ', err);
            callback(err);
        });
    };

    methods.markAsSeen = function (conversationId, callback) {
        Conversation
           .one(conversationId)
          .put()
          .then(function (data) {
            callback(null, data);
        }, function (err) {

            Rollbar.error('ERROR markAsSeen ' + err);
            callback(err);
        });
    };

    return methods;
};
