import React, {useState, useEffect, FC, ReactNode} from 'react';
import Tooltip from '@mui/material/Tooltip';

import {getService} from 'reactInAngular';
import {IHeatmapColumn, IHeatmapRow} from './heatmap-types';
import { Box } from '@mui/material';


interface HeatmapRowProps  {
    row: IHeatmapRow;
    columns: IHeatmapColumn[];
    children: ReactNode;
    loading: boolean;
    onSwapRowDeployed?: (segmentId: string, isDeployed: boolean) => void;
    hasNonEnoughEmployees: boolean;
}

const HeatmapRow: FC<HeatmapRowProps & React.HTMLAttributes<HTMLElement>> = ({ row, columns, onSwapRowDeployed, hasNonEnoughEmployees, children }) => {

    const numPaddingColumns = 2;
    const [numVisibleColumns, setNumVisibleColumns] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState('#fff');

    const [marginLeftLevelArrow, setMarginLeftLevelArrow] = useState('0px');

    const $translate = getService('$translate');


    useEffect(() => {
        if (row) {
            setBackgroundColor(row.parentId === null ? '#EDF4FC' : '#fff');

            switch (row.level) {
                case 1:
                    setMarginLeftLevelArrow('24px');
                    break;
                case 2:
                    setMarginLeftLevelArrow('48px');
                    break;
                default:
                    if (row.level > 2) {
                        setMarginLeftLevelArrow('48px');
                    }
                    break;
            }

            if (row.name.length > 40) {
                row.name = row.name.trim().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 40) + '...';
            }
        }
    }, [row]);

    useEffect(() => {
        const numVisibleColumns = columns.filter(c => c.visible).length + numPaddingColumns;
        setNumVisibleColumns(numVisibleColumns);
    },[columns]);


    function renderCells() {

        if (hasNonEnoughEmployees) {
            return null;
        } else {
            return (children)
        }
    }

    function renderAnonymousRow() {

        if (hasNonEnoughEmployees  && (row.type !== 'CHARACTERISTIC' || (row.type === 'CHARACTERISTIC' && row.parentId !== null))) {
            return (
                <div
                    className="row-anonymous"
                    style={{gridColumn: `3/${numVisibleColumns + 1}`}}>
                    <div className="content">
                        <Tooltip title={$translate.instant('SCORE_ANONYMOUS_DESCRIPTION')} placement="bottom">
                            <img className="img-sm" src="/images/anonymous.png"/>
                        </Tooltip>
                    </div>

                </div>
            );
        } else {
            return null;
        }
    }

    function renderRowHeader() {
        return <Box
            key={row.segmentId}
            className="row_header"
            style={{gridColumn: '1/3'}}
            onClick={() => onSwapRowDeployed(row.segmentId, !row.isDeployed)}
            role={row.hasChildren ? 'button' : ''}
        >
            <Box className="content p-t-new-xs p-r-new-md p-b-new-xs p-l-new-md m-b-new-xs"  style={{backgroundColor: backgroundColor}}>
                <Box className="arrow_container mini-style" style={{marginLeft: marginLeftLevelArrow}}>
                    {row.hasChildren && (
                        <>
                            {row.isDeployed ? <i className="fa text-success fa-chevron-up"/> :
                                <i className="fa text-success fa-chevron-down"/>}
                        </>
                    )}
                    <Box className="name_container m-l-new">
                        <Box className={`small-style ${row.parentId ? '' : 'font-bold'}`}>{row.name}</Box>
                        <Box className="users-count-container mini-style warm-grey"
                            style={{display: row.activeUsers !== undefined ? 'flex' : 'none'}}>
                            <i className="fa fa-users m-t-new-xs "></i>
                            <div className="m-l-new-xs">{row.activeUsers}</div>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>;
    }

    return (
        <>
            {renderRowHeader()}
            {renderCells()}
            {renderAnonymousRow()}
        </>
    )
};

export default HeatmapRow;
