'use strict';

function SettingsCustomizationCtrl(CompanyCustomization, PreviewService, $translate, ErrorSvrc, Languages, Analytics, toaster) {
    'ngInject';

    var _this = this;

    _this.selectedLanguage = null;

    _this.customizationLanguages = [];

    _this.appLanguages = Languages.appLangCodes;


    _this.updateLangs = function () {
        _this.customizationLanguages = Languages.getLangs(_this.companyContentCutomization.employeeInvitation);
    };

    _this.updateEditorContent = function () {
        let content = _this.selectedLanguage ?
            _this.companyContentCutomization.employeeInvitation.langValues[_this.selectedLanguage]
            : _this.companyContentCutomization.employeeInvitation.defaultValue;

        $('#contentEditor').summernote('code', content);
    };


    _this.saveCompanyCustomizationSettings = function () {
        CompanyCustomization.save(_this.companyContentCutomization, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop('success', null, ($translate.instant('SETTINGS_CUSTOMIZATION_SETTINGS_UPDATED')));
            }
        });
    };


    _this.sendSample = function () {
        let content = _this.selectedLanguage ?
            _this.companyContentCutomization.employeeInvitation.langValues[_this.selectedLanguage]
            : _this.companyContentCutomization.employeeInvitation.defaultValue;
        PreviewService.sendInvitationPreview(content, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop('success', null, ($translate.instant('SETTINGS_CUSTOMIZATION_PREVIEW_SENT')));
            }
        });
    };

    _this.addLanguage = function (language) {
        Languages.setLangValue(_this.companyContentCutomization.employeeInvitation, null, language);
        _this.updateLangs();
    };

    _this.removeLanguage = function (language) {
        Languages.removeLangValue(_this.companyContentCutomization.employeeInvitation, language);
        _this.updateLangs();
    };

    _this.selectLanguage = function (language) {
        _this.selectedLanguage = language;
        _this.updateEditorContent();
    };

    _this.$onInit = function () {
        CompanyCustomization.get(function (err, companyContentCutomization) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {

                if (companyContentCutomization) {
                    _this.companyContentCutomization = companyContentCutomization;
                } else {
                    _this.companyContentCutomization =  {
                        employeeInvitation: Languages.createMultiLanguage()
                    };
                }


                // Initialize the editor
                $('#contentEditor').summernote({
                    dialogsInBody: true,
                    dialogsFade: false,
                    placeholder: $translate.instant('SETTINGS_CUSTOMIZATION_INVITATION_MAIL_PLACEHOLDER'),
                    height: 300,
                    toolbar: [['style', ['bold', 'italic', 'underline', 'ul', 'link']]],
                    onCreateLink: function (link) {
                        setTimeout(function () {
                            $('body').addClass('modal-open');
                        }, 500);
                        return link;
                    },
                    callbacks: {
                        onChange: function (contents, $editable) {
                            // This will update the model
                            var content = $('#contentEditor').summernote(
                                'code'
                            );

                            if ($('#contentEditor').summernote('isEmpty')) {
                                content = null;
                            }

                            if (!_this.selectedLanguage) {
                                _this.companyContentCutomization.employeeInvitation.defaultValue = content;
                            } else {
                                _this.companyContentCutomization.employeeInvitation.langValues[_this.selectedLanguage] = content;
                            }
                        }
                    }
                });

                _this.updateLangs();
                _this.updateEditorContent();
            }
        });
    };






    Analytics.trackEvent('settings_customization');
}

// Takes care about login & activation
const hfSettingsCustomization = angular.module('happyForceApp')
    .directive('hfSettingsCustomization', function () {
        return {
            template: require('./settings_customization.html'),
            controller: SettingsCustomizationCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSettingsCustomization;
