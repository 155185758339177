import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Numbers from '../../../../../utilities/number.utilities';
import Valuation from '../../../../../utilities/valuation.utils';
import EngagementDetailCharts from '../../../engagement_detail.charts';
import { getENPSConfiguration } from '../../../../../shared/services/migrated/enps.service';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import HFTag from 'root/app/shared/new-components/hf-tag/hf-tag';

const ENPSVotesDistribution = ({ data, noData }) => {
    const [quality, setQuality] = useState('NO_DATA');
    const [questionData, setQuestionData] = useState(null);
    const [result, setResult] = useState(null);
    const [averageResult, setAverageResult] = useState(null);
    const [tagStatus, setTagStatus] = useState(null);
    const [questionResultsChart, setQuestionResultsChart] = useState(null);

    const ErrorSvrc = getService('ErrorSvrc');
    const Languages = getService('Languages'); // Assuming Languages is a service
    const $translate = getService('$translate'); // Assuming $translate is a service

    const processData = () => {
        if (data && questionData) {
            const lastResult = data.slice(-1)[0];
            if (lastResult.quality !== 'NO_DATA_') {
                setQuality(lastResult.quality);
                setResult(Numbers.roundNumber(lastResult.result, 1));
                setAverageResult(Numbers.roundNumber(lastResult.averageResult, 1));
                setTagStatus(Valuation.enpsTagType(lastResult.result, lastResult.averageResult, lastResult.valuation, true));
                const questionResults = {
                    distribution: lastResult.distribution,
                    participants: lastResult.participants
                };

                const chartConfig = EngagementDetailCharts.scoreQuestionChart(questionData, questionResults);

                setQuestionResultsChart(chartConfig);
            } else {
                setQuestionResultsChart(null);
                setResult(null);
            }
        } else {
            setQuestionResultsChart(null);
            setResult(null);
        }
    };

    useEffect(() => {
        processData();
    }, [data, noData, questionData]);

    useEffect(() => {
        getENPSConfiguration((err, enpsConfiguration) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setQuestionData({
                    title: enpsConfiguration.title,
                    first: enpsConfiguration.first,
                    last: enpsConfiguration.last,
                    type: 'RANGE'
                });
            }
        });
    }, []);

    return (
        <section className="enps-votes-distribution">


            <HappyLoading message={$translate.instant('LOADING_DATA_SENTENCE_1')}>
                <div className="row p-t p-b">
                    <div className="col-sm-5 col-xs-12">
                        <div className="medium-style p-h-lg">
                            {questionData && Languages.getTranslationFromUserLanguage(questionData.title)}
                        </div>

                        {quality !== 'NO_DATA' && !noData && (
                            <HFTag
                                status={tagStatus}
                                text={$translate.instant('ENPS_AVERAGE_RESULT', { result:averageResult })}
                            />
                        )}

                        {(quality === 'NO_DATA' || noData) && (

                            <HFTag
                                status={'neutral'}
                                text={$translate.instant('NO_DATA')}
                            />
                        )}
                    </div>
                    {quality !== 'NO_DATA' && !noData && (
                        <div className="col-sm-7 col-xs-12 text-center">
                            {questionResultsChart && <HighchartsReact className="m-t-xs score_chart"
                                highcharts={Highcharts}
                                options={questionResultsChart}/>}
                        </div>

                    )}
                </div>
            </HappyLoading>

        </section>
    );
};

angularize(ENPSVotesDistribution, 'hfEngagementEnpsVotesDistribution', angular.module('happyForceApp'), {
    data: '=',
    noData: '<'
});

export default ENPSVotesDistribution;
