import Dates from "../../utilities/date.utilities";

const InsightsService = function (Restangular, Settings, Rollbar) {
    'ngInject';
    var Company = Restangular.one('company').one('insights');

    var methods = {};

    methods.getScoreInsights = function (params, callback) {

        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }
        if (_params.to) {
            _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }


        Company.one('scores').get(_params).then(function (results) {
            callback(null, results);
        }, function (err) {
            Rollbar.error('Error getting Insights', err);
            callback(err);
        });
    };


    return methods;

};

export default InsightsService;
