'use strict';

import template from './hf-sorting-selector.html';

var HFSortingSelectorComponentCtrl = function ($scope) {
    'ngInject';
    var _this = this;

    _this.selectedItem == null;


    _this.setOrderBy = function (orderBy) {
        _this.onOptionSelected(orderBy);
    };

    _this.updateSelectedItem = function () {
        _this.selectedItem = _this.options.find(function (option) {
            return option.id === _this.selectedOption;
        });
    };

    _this.$onInit = function () {
        ['selectedOption', 'options'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                _this.updateSelectedItem();
            });
        });
        _this.updateSelectedItem();
    };
};

const hfSortingSelector = angular.module('happyForceApp')
    .directive('hfSortingSelector', function () {
        return {
            scope: {
                options: '<',
                selectedOption: '<',
                onOptionSelected: '<'
            },
            template: template,
            controller: HFSortingSelectorComponentCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });

export default hfSortingSelector;
