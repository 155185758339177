const LoadingService = function () {
    var _this = this;
    var methods = {};
    var isLoading = false;
    _this.components = [];

    methods.start = function (name) {
        isLoading = true;
        if (_this.components.indexOf(name) === -1) {
            _this.components.push(name);
        }
    };

    methods.stop = function (name) {
        var index = _this.components.indexOf(name);
        if (index !== -1) {
            _this.components.splice(index, 1);
        }
        isLoading = _this.components.length > 0;
    };

    methods.isLoading = function () {
        return isLoading;
    };

    return methods;
};

export default LoadingService;
