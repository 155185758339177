'use strict';
import ArrowIndicator from '../../../../../shared/components/_migrated/arrow-indicator-directive/arrow-indicator';

function participationTableCtrl() {

}


const participationTable = angular.module('happyForceApp')
    .directive('participationTable', function () {
        return {
            scope: {
                results: '='
            },
            template: require('./participation-table.html'),
            controller: participationTableCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });

export default participationTable;
