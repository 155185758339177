

const MultiLanguageFilter = function (Languages) {
    'ngInject';
    return function (input, options) {
        if (input && (typeof (input.defaultValue) != 'undefined') && input.defaultValue != null) {
            // It seems like a multilanguage
            return Languages.getTranslationFromUserLanguage(input, options);
        } else {
            // return as it comes
            return input;
        }

    };

};
export default MultiLanguageFilter
