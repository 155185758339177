'use strict';

var RankingImageCtrl = function ($scope) {
    'ngInject'
    var _this = this;
    _this.position = $scope.position;
};



const hfRankingImage = angular.module('happyForceApp')
    .directive('hfRankingImage', function () {
        return {
            scope: {
                position: '='
            },
            template: './ranking-image.html',
            controller: RankingImageCtrl,
            controllerAs: '$ctrl',
            transclude: true
        };
    });

export default hfRankingImage;
