'use strict';

var TopNavBarCtrl = function ($rootScope, $uibModal, CompanySvrc, ErrorSvrc, AuthSrvc, LaunchService) {
    'ngInject'
    var _this = this;

    this.$onInit = function () {

        _this.companyDetails = null;
        _this.user = AuthSrvc.user();

        _this.showLaunchBadge = false;


        // Ge the company
        CompanySvrc.getUserCompany(function (err, company) {
            if (err) {
                _this.errorMsg = ErrorSvrc.getErrorMessage(err);
            } else {
                _this.companyDetails = angular.copy(company);
                if (_this.user.role != 'MANAGER') {
                    CompanySvrc.getUserCompanyConfig(function (err, companyConfig) {
                        if (err)
                            console.log('Error updating company config' + err);
                        else
                            _this.companyConfig = companyConfig;
                    });
                }
            }
        });



        this.toggleBtn = function () {
            $rootScope.$broadcast('showOldDashboardIcon');
        };

        this.isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;

        _this.getCompanyImage = function () {
            if (!_this.companyDetails || typeof _this.companyDetails.imageUrl === 'undefined') {
                return 'images/placeholder_enterprise.png';
            } else {
                return _this.companyDetails.imageUrl;
            }
        };


        // jscs:disable
        beamer_config.top = -10;
        beamer_config.icon = 'flame';
        beamer_config.right = -10;
        beamer_config.selector = 'getbeamer';
        beamer_config.user_firstname = _this.user.name;
        beamer_config.user_lastname = '';
        beamer_config.user_email = _this.user.email;
        beamer_config.user_id = _this.user.id;
        // jscs:enable

        // Init Beamer here
        Beamer.init();
    };
};

const hfTopnavbar = angular.module('happyForceApp')
    .directive('hfTopnavbar', function () {
        return {
            template: require('./topnavbar.html'),
            controller: TopNavBarCtrl,
            controllerAs: 'tp',
            bindToController: true
        };
    });

export default hfTopnavbar;

