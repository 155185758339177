'use strict';
import hfDateTaggingFilter from "./filters/date-tagging-filter/date-tagging-filter.component";
//import HFDateTaggingFilter from "./filters/date-tagging-filter/HFDateTaggingFilter";
import hfGroupsTaggingFilter from "./filters/groups-tagging-filter/groups-tagging-filter.component";
//import HFGroupsTaggingFilter from "./filters/groups-tagging-filter/HFGroupsTaggingFilter";
import hfHierarchyTaggingFilter from "./filters/hierarchy-tagging-filter/hierarchy-tagging-filter.component";
//import HFHierarchyTaggingFilter from "./filters/hierarchy-tagging-filter/HFHierarchyTaggingFilter";
import hfOptionsTaggingFilter from "./filters/options-tagging-filter/options-tagging-filter.component";
//import HFOptionsTaggingFilter from "./filters/options-tagging-filter/HFOptionsTaggingFilter";
import hfSegmentsTaggingFilter from "./filters/segments-tagging-filter/segments-tagging-filter.component";
//import HFSegmentsTaggingFilter from "./filters/segments-tagging-filter/HFSegmentsTaggingFilter";


var TaggingFilterMenuCtrl = function ($scope) {
    'ngInject'

    var _this = this;

    _this.elements = [];

    this.filterId = 'filter-id-' + Math.random().toString(36).substr(2, 9);

    /*
    * Inserts a new filter tag on the HTML nodes;
    */
    _this.insertFilterTag = function (id, type, text, typeLabel, nonRemovable, removalCallback) {
        var formatedText;
        if (typeLabel) {
            formatedText = '<strong>' + typeLabel + ': </strong>' + text;
        } else {
            formatedText = text;
        }
        var element = _.find(_this.elements, function (element) {
            return element.id == id && element.type == type;
        });
        if (element) {
            element.type = type;
            element.text = formatedText;
            element.nonRemovable = nonRemovable;
            element.removalCallback = removalCallback;
        } else {
            _this.elements.push({
                id: id,
                type: type,
                text: formatedText,
                nonRemovable: nonRemovable,
                removalCallback: removalCallback
            });
        }
    };

    /*
    * Removes a filter tag from the HTML nodes and call's the removal callback function;
     */

    _this.removeTag = function (id, type) {
        var element = _.find(_this.elements, function (element) {
            return element.id == id && element.type == type;
        });
        if (element) {
            _this.elements = _.filter(_this.elements, function (inserted) { return inserted.id != id || inserted.type != type; });
            if (element.removalCallback) {
                element.removalCallback(id);
            }
        }
    };


    /* Checks if there is a tag matching this id */
    _this.hasTag = function (id, type) {
        var element = _.find(_this.elements, function (elem) { return elem.id == id && elem.type == type; });
        return element != undefined;
    };

    _this.prevent = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };

    _this.$onInit = function () {
        if (!_this.filters)
            _this.filters = {};

        if (!_this.tagsColor) {
            _this.tagsColor = 'neutral';
        }

        $scope.$on('filterChange-' + this.filterId, function (event, content) {
            _this.filters[content.type] = content.filters;
        });

    };
};




const hfTagFilterMenu = angular.module('happyForceApp').directive('hfTagFilterMenu', function () {
    return {
        template: require('./hf-tag-filter-menu.html'),
        restrict: 'E',
        transclude: true,
        scope: {
            filters: '=',
            label: '@',
            iconClass: '@',
            tagsColor: '@',
            tagsOnLeft: '<'
        },
        bindToController: true,
        controller: TaggingFilterMenuCtrl,
        controllerAs: '$ctrl'
    };
});

export default hfTagFilterMenu;
