const MonitoringRulesService = function (Restangular, Settings, Rollbar) {

    var Company = Restangular.one('company');

    var methods = {};
    var Rules = Company.one('monitoring').one('rules');

    methods.registerRule = function (rule, callback) {
        Rules
            .customPOST(rule)
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR registering rule ', err);
                callback(err);
            });
    };

    methods.deleteRule = function (ruleId, callback) {
        Rules.one(ruleId).remove().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.disableRule = function (ruleId, callback) {
        Rules.one(ruleId).one('disable').put().then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    methods.getRules = function (callback) {
        Rules
            .get()
            .then(function (data) {
                callback(null, data);
            }, function (err) {

                Rollbar.error('ERROR retrieving rule ', err);
                callback(err);
            });
    };

    return methods;
};

export default MonitoringRulesService;
