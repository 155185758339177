'use strict';

var GroupsSelectorCtrl = function ($scope, $translate, Groups, $log) {
    var _this = this;

    this.$onInit = function () {

        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            _this.groups = groups;
        });

        _this.hideAll = $scope.hideAll;

    };
};

angular.module('happyForceApp')
  .controller('GroupsSelectorCtrl', GroupsSelectorCtrl);


const hfGroupsSelector = angular.module('happyForceApp')
    .directive('hfGroupsSelector', function () {
        return {
            scope: {
                selected: '=?',
                showAllOption: '=?'
            },
            template: require('./groups_selector.html'),
            controller: 'GroupsSelectorCtrl',
            controllerAs: 'ctrl',
            bindToController: true
        };
    });

export default hfGroupsSelector;

