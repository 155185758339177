const ScoreDetailsModal = angular.module('happyForceApp')
    .factory('ScoreDetailsModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.showModal = function (score) {
                $uibModal.open({
                    backdrop: 'static',
                    template: require('./score_details.html'),
                    controller: function ($scope, $modalInstance) {
                        'ngInject'
                        $scope.score = score;
        
                        $scope.close = function () {
                            $modalInstance.dismiss('cancel');
                        };
                    },
                    size: ' lg'
                }).result.then(function () {
                }, function (closeAction) {
                });
        
            };

            return methods;
        }
    );

export default ScoreDetailsModal;