// NOTE: This has been migrated to React on hfSegmentationName.tsx
const CharacteristicValueNameFilter = function (Characteristics, Languages) {
    'ngInject';
    return function (valueId, characteristicId) {
        if ((typeof valueId !== 'undefined') && characteristicId)
            return Languages.getTranslationFromUserLanguage(Characteristics.getCharacteristicValueName(characteristicId, valueId));
        else
            return '';

    };
};

export default CharacteristicValueNameFilter;
