'use strict';

function SurveyBlockEdit($scope, $modalInstance, $translate, Languages, block, isEdit, selectedLanguage) {

    var _this = this;

    _this.isEdit = isEdit;
    _this.selectedLanguage = selectedLanguage;


    if (_this.selectedLanguage) {
        _this.modalSubtitle = $translate.instant('SURVEY_BLOCK_SETTINGS_TRANSLATION', { language: Languages.getNameByCode(_this.selectedLanguage) });
    } else {
        _this.modalSubtitle = $translate.instant('SURVEY_BLOCK_SETTINGS');
    }


    if (block) {
        _this.block = angular.copy(block);
    } else {
        _this.block = {
            title: {
                defaultValue: '',
                langValues: {}
            },
            description: {
                defaultValue: '',
                langValues: {}
            }
        };
        _this.isEdit = false;
    }

    _this.ok = function () {
        $modalInstance.close(_this.block);
    };

    _this.delete = function () {
        $modalInstance.close(null);
    };

    _this.cancel = function () {
        $modalInstance.dismiss('close');
    };

}

const SurveyBlockEditCtrl= angular.module('happyForceApp').controller('SurveyBlockEditCtrl', SurveyBlockEdit);

export default SurveyBlockEditCtrl;
