/**
 * Created by dcastro on 31/03/14.
 */

const HFController = function ($log, PubSub, Rollbar) {
    'ngInject';

    var PREFIX = 'hf-';

    var Utils = PubSub;

    Utils.notifyParent = function (parent, data) {
        PubSub.publish(PREFIX + parent, data);
    };

    Utils.loading = function (parent, name, status) {
        var loading = {};
        loading[name] = status;
        $log.debug('[' + name + '] Loading published on ' + parent + ' (' + status + ')');
        Utils.notifyParent(parent, { loading: loading });
    };

    Utils.notifyError = function (parent, message, error) {
        Utils.notifyParent(parent, { error: {
                message: message,
                detail: error
            } });
    };

    Utils.registerParent = function (name, callback) {
        $log.debug('Registered ' + PREFIX + name + ' parent');
        PubSub.subscribe(PREFIX + name, function (data) {
            callback(null, data);
        });
    };

    var status = {};
    Utils.getLoadingStatus = function (loading) {
        if (!loading) return false;
        $log.debug('Loading updated', loading);

        Object.keys(loading).forEach(function (key) {
            status[key] = loading[key];
        });

        $log.debug('Global Loading Status', status);
        if (JSON.stringify(status).indexOf('true') === -1) {
            return false;
        } else {
            return true;
        }
    };

    Utils.registerError = function (error, callback) {
        if (!error) return;
        $log.error(error.message, error.detail);
        Rollbar.error(error.message, error.detail);
        if (callback)
            callback(error);
    };

    Utils.cleanSubscriptions = function (name) {
        Object.keys(PubSub.topics).forEach(function (key) {
            if (key.indexOf(name) === -1) {
                return;
            }

            PubSub.unsubscribe(key);
        });

        PubSub.unsubscribe('hf-segments');
    };

    return Utils;
};

export default HFController;

