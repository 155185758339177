'use strict';

function ReceivedFeedbackPriorityCtrl($scope, $stateParams, SegmentsFilterService, HIService, Comments, ManagersActivity, ErrorSvrc) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {

        _this.loading = true;

        _this.loadPriorizedComments = function () {
            _this.loading = true;
            var params = {
                from: _this.dates.from,
                to: _this.dates.to,
                privated: false,
                hasConversation: false,
                hasCompanyReply: false,
                sortOrder: 'AGREE',
                fromComment: 0,
                toComment: 5,
                filters: _this.filters,
                hierarchyId: _this.level && _this.level.id,
                groupId: _this.groups
            };

            Comments.get(params, function (employeeComments) {
                _this.loading = false;
                _this.priorityComments = employeeComments.comments;
            }, function (err) {
                _this.loading = false;
                ErrorSvrc.showErrorModal(err);
            });

        };

        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                // Now compute the stats
                _this.loadPriorizedComments();
            }, true);
        });

        _this.loadPriorizedComments();

    };

}

const hfReceivedFeedbackPriority = angular.module('happyForceApp').directive('hfReceivedFeedbackPriority', function () {
    return {
        scope: {
            dates: '=',
            filters: '=',
            groups: '=',
            level: '='
        },
        restrict: 'E',
        template: require('./received-feedback-priority.html'),
        controller: ReceivedFeedbackPriorityCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfReceivedFeedbackPriority;
