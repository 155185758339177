'use strict';

import hfAnnouncementsSectionsConfig from './components/sections_configuration/announcements_sections_config.controller';
import AnnouncementCategoryEditModal from './modals/announcement_category_edit.controller';

function AnnouncementsConfigCtrl($scope, AnnouncementCategoryEditModal, $translate, ErrorSvrc, Analytics, Announcements) {
    'ngInject';

    $scope.loadCategories = function (message) {
        Announcements.getCategories(function (err, categories) {
            if (err)
                ErrorSvrc.showErrorModal(err);
            else {
                $scope.categories = categories;
                if (message) {
                    swal(message, '', 'success');
                }
            }
        });
    };

    $scope.editAnnouncementCategory = function (category) {
        AnnouncementCategoryEditModal.showModal(category, function (result, action) {
            if (action === 'add') {
                $scope.loadCategories($translate.instant('ANNOUNCEMENTS_CATEGORY_ADDED'));
            } else if (action === 'update') {
                $scope.loadCategories($translate.instant('ANNOUNCEMENTS_CATEGORY_UPDATED'));
            } else {
                $scope.loadCategories($translate.instant('ANNOUNCEMENTS_CATEGORY_DELETED'));
            }
        });
    };

    Announcements.getCategories(function (err, categories) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            $scope.categories = categories;
        }

    });

    Analytics.trackEvent('settings_announcements_config');
}

const hfAnnouncementsConfig = angular.module('happyForceApp')
    .directive('hfAnnouncementsConfig', function () {
        return {
            template: require('./announcements_config.html'),
            controller: AnnouncementsConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAnnouncementsConfig;
