

import Scope from '../../../utilities/scope.utilities';
import async from 'async';
import Dates from '../../../utilities/date.utilities';

import template from './topics_detail.html';
import RangeTopic from 'root/app/components/topics/topics_detail/components/rangeTopic';
import OptionsTopic from 'root/app/components/topics/topics_detail/components/optionsTopic';
import OpenTopic from 'root/app/components/topics/topics_detail/components/openTopic';


const TopicsDetailCtrl = function ($scope, $anchorScroll, CompanySvrc, Topics, Stats, ErrorSvrc, $stateParams, $state, $translate, Analytics, Comments, Questions, Settings, Filters, Reports) {

    var _this = this;

    var watching;

    Scope.initialize($scope, ['loadingData', 'loadingGlobal', 'loadingComments'], true);
    Scope.initialize($scope, ['comments'], []);

    $scope.hasComments = false;
    $scope.filters = { boolean: {}, list: {}, date: {} };
    $scope.currentCommentPage = 1;
    $scope.totalComments = 0;
    $scope.commentsOrder = 'RECENT';
    $scope.hasEnoughActiveEmployees = true;
    $scope.order_by = $translate.instant('RECENT');
    $scope.questionId = $stateParams.selectedTopic;
    _this.searchText = '';

    _this.level = Filters.level() || null;
    _this.filters = Filters.segments() || null;
    _this.groups = Filters.groups() || [];



    function processComment(comment, responseValues) {
        var pos = 0;
        _.find(responseValues, function (option) {
            if (option.id === comment.rating) {
                comment.rating = {
                    value: comment.rating,
                    color: option.color,
                    label: option.label.defaultValue
                };
                return comment;
            }

            pos++;
        });

        return comment;
    }

    function prepareParams() {
        var params = {
            questionId: $scope.questionId
        };
        params.filters = _this.filters;
        params.groupId = _this.groups;
        params.hierarchyId = _this.level && _this.level.id;

        // Compute the dates:
        params.from = _this.dates.from ? _this.dates.from : Dates.fromAPIFormat($scope.question.activations[0].from, Settings.apiDateFormat);
        params.to = _this.dates.to ? _this.dates.to : Dates.fromAPIFormat($scope.question.activations[$scope.question.activations.length - 1].to, Settings.apiDateFormat);
        return params;
    }

    $scope.header = {
        title: $translate.instant('QUESTION_RESULTS'),
        arrow: {
            action: function () {
                $state.go('topics.browse');
            }
        }
    };

    CompanySvrc.getUserCompany(
        function (err, company) {
            if (err)
            {$scope.companyName = $translate.instant('COMPANY');}
            else
            {$scope.companyName = company.name;}
        }
    );

    $scope.loadData = function () {
        $scope.loadingData = true;

        var streams = [];

        // Obtenemos la question deseada
        streams.push(function (next) {
            Questions.find({ questionId: $scope.questionId }, next);
        });

        streams.push(function (questions, next) {

            $scope.question = questions && questions.result && questions.result[0];
            if (!$scope.question) {return next();}

            if (!_this.dates || !_this.dates.from || !_this.dates.to) {
                _this.dates = {
                    from: Dates.fromAPIFormat($scope.question.activations[0].from, Settings.apiDateFormat),
                    to: Dates.fromAPIFormat($scope.question.activations[$scope.question.activations.length - 1].to, Settings.apiDateFormat)
                };
            }

            // Set the filters:

            var params = prepareParams();

            // Set the dates for variable replacement:
            $scope.dateLabels = {
                from: moment(Dates.fromAPIFormat($scope.question.activations[0].from, Settings.apiDateFormat)).format('DD-MMM-YYYY'),
                to: moment(Dates.fromAPIFormat($scope.question.activations[$scope.question.activations.length - 1].to,
                    Settings.apiDateFormat)).format('DD-MMM-YYYY')
            };
            if ($scope.question.activationRule.ruleType == 'RECURRENT') {
                $scope.dateLabels.times = $scope.question.activations.length;
            }

            Stats.questions.get(params, function (err, results) {
                if (err) {
                    return next(err);
                }

                $scope.questionResults = results;

                //if (!$scope.questionResults) return next();
                next();
            });

            // loadComments
            $scope.loadComments();
        });

        async.waterfall(streams, function (err) {
            if (err) {
                $scope.loadingData = false;

                if (err) {
                    if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {

                        $scope.hasEnoughActiveEmployees = false;
                        return console.error(err);
                    } else {
                        ErrorSvrc.showErrorModal(err);
                    }

                }
            } else {
                $scope.hasEnoughActiveEmployees = true;
            }

            $scope.loadingData = false;

            if (!watching) {
                watchVars();
            }

            $anchorScroll('page-wrapper');
        });
    };

    $scope.exportToExcel = function () {
        // Get the data for the current hierarchy & filter
        var params = prepareParams();
        $scope.exportingExcelReport = true;
        Reports.question(params, function () {
            // We are quering without question and for one hierarchy, so we expect only one node, with one hie, that is for the primary question
            $scope.loadingData = false;
            $scope.exportingExcelReport = false;
            swal($translate.instant('TOPICS_EXPORT_SENT'), '', 'success');
            Analytics.trackEvent('topics', 'excel_report', 'success');
        }, function errorCallback(response) {

            ErrorSvrc.showErrorModal(response);
            $scope.loadingData = false;
            $scope.exportingExcelReport = false;
            Analytics.trackEvent('topics', 'excel_report', 'false');

        });
    };

    $scope.loadComments = function (callback) {

        $scope.loadingComments = true;
        var params = prepareParams();
        var fromDate = new Date(params.from);
        var toDate = new Date(params.to);
        var extras = {};

        params.filters = _this.filters;

        if ($scope.commentsOrder != null) {
            params.sortOrder = $scope.commentsOrder;
        }

        //Set the topic id
        params.questionId = $scope.questionId;
        params.groupId = _this.groups;
        params.hierarchyId = _this.level && _this.level.id;

        // Set the pagination
        params.fromComment = (this.currentCommentPage - 1) * 10;
        params.toComment = 10;

        // Set he current hierarchy for filter the comments
        //params.hierarchyId = $stateParams.hierarchyLevel;

        if (_this.searchText !== '' && typeof _this.searchText !== 'undefined') {
            params.commentText = _this.searchText;
        }

        var streams = [];

        // Obtenemos la question deseada
        streams.push(function (next) {
            Questions.find({ questionId: $scope.questionId }, next);
        });

        streams.push(function (question, next) {
            var paralellelStream = [];
            paralellelStream.push(function (next) {
                // And get the comments
                Comments.get(params, function (employeeComments) {
                    $scope.hasComments = true;
                    $scope.comments.length = 0;
                    $scope.comments.total = employeeComments.total;

                    _.forEach(employeeComments.comments, function (comment) {
                        comment = processComment(comment, question.result[0].values);
                        $scope.comments.push(comment);
                    });

                    $scope.totalComments = employeeComments.total;
                    $scope.loadingComments = false;

                    next();
                }, function (err) {

                    if (err) {
                        if (!ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                            ErrorSvrc.showErrorModal(err);
                            return console.error(err);
                        }

                    }

                    $scope.loadingComments = false;
                    next();
                });
            });

            async.parallel(paralellelStream, function (err) {
                if (callback) {
                    callback();
                }
            });
        });

        async.waterfall(streams, function (err) {

            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    $scope.hasEnoughActiveEmployees = false;
                } else {
                    ErrorSvrc.showErrorModal(err);
                    return console.error(err);
                }

            }

            $scope.loadingComments = false;

        });

    };

    $scope.orderComments = function (orderBy) {
        $scope.commentsOrder = orderBy;

        var ORDER_BY = orderBy || 'RECENT';
        if (ORDER_BY === 'INAPPROPRIATE')
        {ORDER_BY = 'REPORTED_COMMENT';}

        $scope.order_by = $translate.instant(ORDER_BY);
        $scope.loadComments();

    };



    function watchVars() {
        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind($scope, function () {
                return _this[key];
            }), function (newVal, oldVal) {

                if (newVal === oldVal) {return;}
                $scope.loadData();
            }, true);
        });

        watching = true;
    }


    this.$onInit = function () {



        $scope.$watch(angular.bind($scope, function () {
            return _this.searchText;
        }), function (newVal, oldVal) {

            console.log('searchText');
            if (newVal === oldVal) {return;}
            $scope.loadComments();
        }, true);

        $scope.loadData();

        Analytics.trackEvent('topics', 'detail');

        //Ñapa size
        setTimeout(function () {
            $(window).trigger('resize');
        }, 500);

    };
};


function TopicsDetailView($stateProvider) {
    'ngInject';
    $stateProvider.state('topics.detail', {
        url: '/detail/:selectedTopic',
        controller: TopicsDetailCtrl,
        controllerAs: 'td',
        template: template,
        data: { pageTitle: 'TOPICS_DETAIL', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
    });
}

export default TopicsDetailView;


