import {ISegmentInfo} from '../../../../../shared/new-components/hf-heatmap/heatmap-types';
import {Box, Button, Grid, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {getScoreAIResume} from '../../../../../shared/services/migrated/scoresAi';
import {getService} from '../../../../../../migration_utils/react-in-angular';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import BodyOne from 'mui-markdown/src/components/BodyOne';
import {SegmentFilter} from '../../utils/filteringUtils';


interface ScoreAIBoxProps {
    scoreId: string;
    to: Date;
    dateGrouping: string;
    segmentationFilter: SegmentFilter | null;
}

const ReportTypography = (({children}) => {
    return (
        <Typography variant="body1">
            {children}
        </Typography>
    );
});


interface ReportAction {
    title: string;
    description: string;

}
interface Report {
    text: string;
    actions: ReportAction[];
}

const ScoreAIBox = (({scoreId, to,  dateGrouping, segmentationFilter}:ScoreAIBoxProps) => {


    const ErrorService = getService('ErrorSvrc');


    const [report, setReport] = React.useState<Report | undefined>();
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {



        if (!to) {
            return;
        }


        setLoading(true);


        /*setReport({
            'text': 'The recent pulse survey indicates a mixed bag of results for Intrinsic Motivation. **Autonomy** has seen an improvement, suggesting employees feel more empowered in their roles, although comments about micromanagement and decision validations hint at areas needing further attention. **Mastery** also shows progress, with employees feeling more supported in their development, yet some still perceive limited growth opportunities. Conversely, **Purpose** has slightly declined, with employees feeling less connected to the company\'s mission. This could be due to a lack of visible impact of their work, as indicated in the comments.\n\nTo address these issues, focus on enhancing decision-making autonomy and clarifying career growth paths. Additionally, reinforcing the company\'s mission and demonstrating how individual roles contribute to it can help reignite a sense of purpose.',
            'actions': [
                {
                    'title': 'Increase Decision-Making Autonomy',
                    'description': 'Empower employees by reducing micromanagement and streamlining validation processes. This will help them feel more in control and valued in their roles.'
                },
                {
                    'title': 'Clarify Career Growth Paths',
                    'description': 'Provide clear and achievable career advancement opportunities. Regularly discuss individual development plans to ensure employees see a future within the company.'
                }
            ]
        })

        setLoading(false);
*/


        getScoreAIResume(
            scoreId,
            to,
            segmentationFilter?.hierarchyId,
            null,
            segmentationFilter?.groupId,
            dateGrouping,
            function (error, response) {
                if (error) {
                    ErrorService.showErrorModal(error);
                } else {
                    setReport(response);
                }
                setLoading(false);

            });



    }, [scoreId, dateGrouping, segmentationFilter, to]);

    const renderActions = (actions: ReportAction[]) => {
        if (!actions) {
            return <Box>No actions have been found</Box>;
        } else {
            return actions.map((action, index) => {
                return (

                    <Box key={index}
                        sx={ theme => ({
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                            marginBottom: theme.spacing(2),
                            borderRight: '1px solid #e2e7e9',
                            borderLeft: '2px solid #e2e7e9\'}',
                        })}>
                        <Typography
                            variant="h6"
                            component="div"
                        >
                            <b> {action.title}</b>
                        </Typography>
                        <Typography
                            sx={(theme) => ({
                                marginTop: theme.spacing(1),
                            })}
                            color="text.secondary"
                            variant="caption"
                        >
                            {action.description}
                        </Typography>
                    </Box>
                );
            });
        }
    };

    return (
        <Box>
            <HappyLoading loading={loading}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>

                        <Typography variant="h6">
                            What's going on?
                        </Typography>

                        <Box   sx={(theme) => ({
                            marginTop: theme.spacing(2),
                        })}>
                            <MuiMarkdown

                                overrides={{
                                    ...getOverrides({}), // This will keep the other default overrides.
                                    p: {
                                        component: 'p'
                                    },
                                }}
                            >
                                {report?.text}
                            </MuiMarkdown>
                        </Box>
                        <Box   sx={(theme) => ({
                            marginTop: theme.spacing(2),
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between',
                        })}>
                            <Button size={'small'}
                                variant="outlined"
                                color="primary">Chat about this</Button>
                            <Button size={'small'}
                                variant="text">... or talk with your Advisor</Button>
                        </Box>

                    </Grid>
                    <Grid item xs={6}>

                        <Typography variant="h6">
                            Recommended Actions
                        </Typography>

                        <Box   sx={(theme) => ({
                            marginTop: theme.spacing(2),
                        })}>
                            {renderActions(report?.actions)}
                        </Box>


                    </Grid>
                </Grid>

            </HappyLoading>
        </Box>
    );

});


export default ScoreAIBox
