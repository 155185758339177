'use strict';

import Numbers from "../../../utilities/number.utilities";
import async from "async";
import {getActivationResume} from "../../../shared/services/migrated/activation.service";


/**
 * Servicio encargado de obtener los datos necesarios para el módulo de activaciones
 * @param {Service} Questions [description]
 */

export default function DashboardActivationService(ParticipationService, ErrorSvrc, Settings, $q, $log) {

    /**
     * Obtenemos datos de participación entre unas fechas
     * @param  {[type]} from [description]
     * @param  {[type]} to   [description]
     */
    function getParticipation(params, callback) {
        if (!params.from)
            params.from = moment(params.to).subtract(params.days, 'd')._d;

        var options = {};

        if (params.level && params.level.id) {
            options.hierarchyId = params.level.id;
        }

        options.filters = params.filters;

        if (params.groupId) {
            options.groupId = params.groupId;
        }

        ParticipationService.get(params.from, params.to, options,  function (err, stats) {

            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err))
                   callback(null, {
                    hasEnoughActiveEmployees: false
                });
                else
                   return callback(err);
            } else {
                callback(null, {
                    variationPercent: Numbers.roundNumber(stats.participantsVariationPercent, 1),
                    total: stats.activated || 0,
                    actived: stats.participants || 0,
                    percent: Numbers.roundNumber(((stats.participants * 100) / stats.activated) || 0),
                    hasEnoughActiveEmployees: true,
                    valuation: stats.valuation
                });
            }
        });
    }

    /**
     * Obtenemos los datos de activación
     * @param  {Date} from Desde
     * @param  {Date} to   Hasta
     */
    function getActivation(params, callback) {
        var options = {
            from: params.from,
            to: params.to
        };

        if (params.level && params.level.id)
         options.hierarchyId = params.level.id;

        options.filters = params.filters;

        if (params.groupId) {
            options.groupId = params.groupId;
        }

        getActivationResume(options, function (err, stats) {
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err))
                    callback(null, {
                        hasEnoughActiveEmployees: false
                    });
                else
                    return callback(err);
            } else {
                callback(null, {
                    variationPercent: Numbers.roundNumber(stats.activatedVariationPercent, 1),
                    total: stats.totalInvited || 0,
                    actived: stats.totalActivated || 0,
                    percent: Numbers.roundNumber(((stats.totalActivated * 100) / stats.totalInvited) || 0),
                    hasEnoughActiveEmployees: true,
                    valuation: stats.valuation
                });

            }

        });
    }

    function treatData(data) {
        if (data.percent > 100) {
            data.percent = 100;
            data.actived = data.total;
        }

        return data;
    }

    return {

        loadData: function (data, callback) {

            var response = {};
            var streams = [];

            streams.push(function (next) {
                var asyncStream = [];

                asyncStream.push(function (nextParallel) {
                    var params = {
                        from: data.from,
                        to: data.to,
                        level: data.level,
                        filters: data.filters,
                        groupId: data.groupsId
                    };

                    getActivation(params, function (err, activation) {
                        if (err) {
                            $log.error('Ha ocurrido un error', err);
                            return nextParallel(err);
                        }

                        response.activation = activation;
                        nextParallel(null);
                    });
                });

                asyncStream.push(function (nextParallel) {
                    var params = {
                        from: data.from,
                        to: data.to,
                        level: data.level,
                        groupId: data.groupsId,
                        filters: data.filters
                    };
                    getParticipation(params, function (err, particionation) {
                        if (err) {
                            $log.error('Ha ocurrido un error 1d', err);
                            return nextParallel(err);
                        }

                        response.particionation1d = treatData(particionation);
                        response.particionation1d.dateRangeString = 1;

                        nextParallel(null);
                    });
                });

                var today = new Date();
                today.setHours(0, 0, 0, 0);

                // Days
                [30, 90].forEach(function (x) {
                    asyncStream.push(function (nextParallel) {
                        var params = {
                            to: today,
                            days: x,
                            level: data.level,
                            groupId: data.groupsId,
                            filters: data.filters
                        };
                        getParticipation(params, function (err, particionation) {
                            if (err) {
                                $log.error('Ha ocurrido un error ' + x + 'd', err);
                                return nextParallel(err);
                            }

                            response['particionation' + x + 'd'] = treatData(particionation);
                            response['particionation' + x + 'd'].dateRangeString = x;
                            nextParallel(null);
                        });
                    });
                });

                async.parallel(asyncStream, next);
            });

            async.waterfall(streams, function (err) {
                if (err) {
                    callback(err);
                    $log.error(err);
                }

                callback(null, response);
            });
        }
    };
};

angular.module('happyForceApp')
.service('DashboardActivationService', DashboardActivationService);
