'use strict';

import hfFeedbackAmountSettings from '../components/feedback_amount_settings/feedback_amount_settings.controller';
import hfFeedbackTransparencySettings from '../components/feedback_transparency_settings/feedback_transparency_settings.controller';
import hfCompanyCultureValuesConfig from './components/company_culture_values/company_culture_values.controller';

function FeedbackRecognitionConfigCtrl($scope, Analytics) {
    'ngInject'

    var _this = this;

    ['enabled', 'employeeSignatureMode', 'managerSignatureMode', 'privacyMode', 'sharedAmmountComments', 'maxCommentsPerDay'].forEach(function (key) {
        // Listeners
        if ($scope.feedbackConfiguration) {
            $scope.$watch(
                angular.bind(_this, function () {
                    return $scope.feedbackConfiguration.feedbackConfiguration['RECOGNITION'][key];
                }),
                function (n, o) {
                    if (n === o) {
                        return;
                    }
                    $scope.saveFeedbackSettings();
                }
            );
        }

    });


    Analytics.trackEvent('settings_recognition_feedback_config');
}

const recognitionConfig = angular.module('happyForceApp')
    .directive('recognitionConfig', function () {
        return {
            template: require('./recognition_config.html'),
            controller: FeedbackRecognitionConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default recognitionConfig;
