    const GroupNamesFilter = function (Groups) {
        'ngInject';
        var filterFunction =  function (input, options) {
            var groupNames = [];

            if (input) {

                angular.forEach(input, function (groupId) {
                    groupNames.push(Groups.getGroupName(groupId));
                });
            }

            groupNames.sort();

            return groupNames.length ? groupNames.join(', ') : null;

        };

        filterFunction.$stateful = true;

        return filterFunction;
    };
    
    export default GroupNamesFilter;