const RankingService = function () {
    'ngInject';
    var rankingImages = {
        TOP_10: '/images/ranking_1.svg',
        TOP_20: '/images/ranking_2.svg',
        TOP_30: '/images/ranking_3.svg',
        TOP_40: '/images/ranking_4.svg',
        TOP_50: '/images/ranking_5.svg',
        TOP_75: '/images/ranking_6.svg',
        NO_TOP: '/images/ranking_none.svg',
        UNKNOWN: '/images/ranking_none.svg'
    };

    var rankingLabels = {
        TOP_10: 'RANKING_GOD',
        TOP_20: 'RANKING_HERO',
        TOP_30: 'RANKING_MASTER',
        TOP_40: 'RANKING_ADVANCED',
        TOP_50: 'RANKING_SKILLED',
        TOP_75: 'RANKING_LEARNER',
        NO_TOP: 'RANKING_STARTER',
        UNKNOWN: 'RANKING_UNKNOWN'
    };

    var methods = {};

    methods.getRankingImage = function (ranking) {

        var img = rankingImages[ranking];

        if (img) {
            return img;
        } else {
            return rankingImages['UNKNOWN'];
        }
    };

    methods.getRankingLabel = function (ranking) {

        var label = rankingLabels[ranking];

        if (label) {
            return label;
        } else {
            return rankingLabels['UNKNOWN'];
        }
    };

    return methods;

};

export default RankingService;
