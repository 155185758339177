const CompanyCustomizationService = function (Restangular, $translate, Settings) {
    'ngInject';
    var CompanyCustomization = Restangular.one('company').one('config').one('customization');
    var methods = {};
    var stats = {};


    methods.get = function (callback) {
        CompanyCustomization.get().then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.save = function (content, callback) {
        CompanyCustomization.customPOST(content).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    return methods;

};

export default CompanyCustomizationService;
