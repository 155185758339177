import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getService, angularize } from 'reactInAngular';
import Numbers from '../../../../../../utilities/number.utilities';
import Valuation from '../../../../../../utilities/valuation.utils';
import Style from '../../../../../../utilities/style.utils';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HappyLoading from '../../../../../../shared/components/_migrated/loader/loading.directive';
import {Box} from '@mui/material';
import {
    getBenchmarkDescription,
    getQualityDescription, getVariationDescription, renderPopover
} from 'root/app/components/engagement/components/engagement_heatmap/components/cellTypes/cell_utils';

const HICell = ({ cellData, loading, segment, column, onCellClick, selectedInsight}) => {
    const [value, setValue] = useState(null);
    const [hasData, setHasData] = useState(true);
    const [color, setColor] = useState('');

    const $translate = getService('$translate');


    useEffect(() => {

        //  console.log(`there is a change in ${dataId}, ${loading}, ${to}, ${dateGrouping}`);


        if (cellData) {

            const isRepresentative = cellData.quality === 'NORMAL' || cellData.quality === 'GOOD';

            setValue(Numbers.roundNumber(cellData.result, 0));
            let calculatedColor = Style.noData;
            if (cellData.result === null || cellData.result === undefined) {
                setHasData(false);
                calculatedColor = Style.noData;
                if (!isRepresentative) {
                    calculatedColor = Style.neutralNotRelevant;
                }
            } else {
                setHasData(true);
                calculatedColor = Valuation.valuationColor(cellData.valuation, true);
                if (!isRepresentative) {
                    calculatedColor = Valuation.valuationColorWithLowQuality(cellData.valuation, true);
                }
            }
            setColor(calculatedColor);
        }

    }, [cellData]);


    const handleOnCellClick = () => {
        {onCellClick(segment, column, selectedInsight);}
    };




    return (
        <div className="cell_heatmap" style={{ backgroundColor: color}}>
            <div className="cell_container p-new-sm">

                {!loading && (
                    <>
                        <i style={{ visibility: 'hidden' }} className="fa fa fa-info-circle text-white"></i>
                        <div className="result-container text-center small-style">
                            {hasData ? (
                                <div className="small-style" role="button" onClick={handleOnCellClick}>{value}</div>
                            ) : (
                                <div className="small-style">{$translate.instant('NO_DATA')}</div>
                            )}
                        </div>

                        {renderPopover(hasData, cellData, value, $translate)}


                    </>
                )}

                {loading && (
                    <Box className={'result-container'} sx={{width:'100%'}}>
                        <HappyLoading loading={loading} skeleton={2} noMessage={true}/>
                    </Box>
                )}
            </div>
        </div>
    );
};

HICell.propTypes = {
    cellData: PropTypes.object,
    segment: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onCellClick: PropTypes.func.isRequired,
    selectedInsight: PropTypes.object,
};

export default HICell;
