'use strict';

import template from './access.html';
function AccessCtrl($stateParams, AuthSrvc, ErrorSvrc, $state, $translate, CompanySvrc) {
    const _this = this;


    function access(token) {
        AuthSrvc.login({
                activationToken: token,
                rememberMe: true
            },
            function (res) {
                _this.completed = true;
                CompanySvrc.resetUserCompanyCache();
                $state.go('private.dashboard');

                /*CompanySvrc.getConfig('launchStatus')
                .then(function (launchStatus) {
                    $state.go(launchStatus === 'CREATED' ? 'setup.initial' : 'private.dashboard');
                });*/
            },

            function (err) {
                console.error(err);
                _this.completed = true;
                _this.failed = true;
                var swalParams = {
                    type: 'error',
                    allowEscapeKey: false,
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55',
                    button: $translate.instant('OK'),
                    closeOnConfirm: true
                };
                var errorCode = ErrorSvrc.getErrorCodeFromStatus(err);
                if (err.status === 400) {
                    swalParams.text = $translate.instant(ErrorSvrc.getErrorMessage(err));
                    if (errorCode == 'USER_EXPIRED_ACTIVATION_TOKEN') {
                        swalParams.title = $translate.instant('USER_EXPIRED_ACTIVATION_TOKEN_TITLE');
                        swalParams.text = $translate.instant('USER_EXPIRED_ACTIVATION_TOKEN');
                        delete swalParams.button;
                        swalParams.buttons = [$translate.instant('CANCEL'), $translate.instant('OK')];
                    } else {
                        swalParams.title = $translate.instant('USER_INVALID_ACTIVATION_TOKEN_TITLE');
                    }
                } else {
                    swalParams.title = $translate.instant('UPS_ERROR');
                    swalParams.text = $translate.instant('CONNECTION_ERROR');
                }

                swal(swalParams)
                    .then(function (shouldResendLink) {
                        if (shouldResendLink && errorCode == 'USER_EXPIRED_ACTIVATION_TOKEN') {
                            AuthSrvc.sendAccessLink({ expiredToken: token }, function (res) {
                                    $state.go('onboarding/sent');
                                },
                                function (err) {
                                    swal($translate.instant(err.errorCode), '', 'error');
                                    $state.go('onboarding');
                                });
                        } else {
                            $state.go(err.noLogout ? 'private.dashboard' : 'onboarding');
                        }
                    });

            });
    }


    this.$onInit = function () {
        _this.user = AuthSrvc.user;
        var token = $stateParams.activationToken;

        if (typeof token !== 'undefined') {
            access(token);
        }
    };

}
const AccessView = function AccessConfig($stateProvider) {
    'ngInject';
    $stateProvider
        .state('access', {
            url: '/access/*activationToken',
            template: template,
            controllerAs: 'ac',
            controller: AccessCtrl,
            data: { pageTitle: 'Accessing', bodyClasses: 'login gray-bg' }
        });
};

export default AccessView;
