'use strict';
import hfWizardIntro from "./wizard-intro.directive";
import hfWizardStep from "./wizard-step.directive";
import hfSteps from "./steps.directive";

function HFWizardCtrl($scope) {
    var _this = this;

    this.$onInit = function () {

        _this.steps = [];
        _this.stepActive = 0;

        // On stepChange
        ['stepActive'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal) {

                if (!_this.steps.length) return;
                _this.steps.forEach(function (step) {
                    step.selected = false;
                });

                _this.steps[newVal].selected = true;
            });
        });

        // Add Step
        _this.addStep = function (step) {
            if (step.hidden == false) {
                var stepOrder = _this.steps.length;
                step.number = _this.steps.length + 1;
                if (!_this.steps.length) step.selected = true;
                _this.steps[stepOrder] = step;
            }

        };

        // Remove Step
        _this.removeStep = function (step) {
            var index = _this.steps.indexOf(step);
            if (index > 0) {
                _this.steps.splice(index, 1);
            }
        };

        // Navigate
        _this.goToPrev = function () {
            _this.stepActive--;
        };

        _this.goToNext = function () {
            if (_this.options && _this.options.validate) {
                if (validateStep()) {
                    _this.stepActive++;
                }
            } else {
                _this.stepActive++;
            }
        };

        function validateStep() {
            var step = _this.steps[_this.stepActive];
            if (_this.options.validate) {
                // Number function version
                if (_this.options.validate[step.number]) {
                    return _this.options.validate[step.number](step);

                    // Global function
                } else if (typeof _this.options.validate === 'function') {
                    return _this.options.validate(step);
                }

            }

            return true; // Always continue

        }
    };

}


const hfWizard = angular.module('happyForceApp')
    .directive('hfWizard', function () {
        return {
            scope: {
                hfTitle: '@',
                hfSubtitle: '@',
                className: '@',
                options: '=?',
                clickable: '=?'
            },
            template: require('./wizard.html'),
            controller: HFWizardCtrl,
            transclude: true,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfWizard;
