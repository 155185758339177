import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Pagination from '@mui/material/Pagination'; // Replace with your preferred pagination component

// Import custom hooks or context for fetching ENPS data and handling errors
import {getENPSIndividualStats} from "../../../../../shared/services/migrated/enps.service";
import {angularize, getService} from 'reactInAngular';
import Charts from "../../../../../utilities/charts.utilities";
import Dates from "../../../../../utilities/date.utilities";
import Style from "../../../../../utilities/style.utils";
import Valuation from "../../../../../utilities/valuation.utils";
import InfoIcon from "@mui/icons-material/Info";
import {Box, FormControl, InputLabel, MenuItem, Select, Tooltip} from '@mui/material';
import HappyLoading from "../../../../../shared/components/_migrated/loader/loading.directive";


const DisplayByOptions = [
    { id: 'CALENDAR', labelKey: 'INDIVIDUAL_VOTES_CALENDAR' },
    { id: 'SCATTER', labelKey: 'INDIVIDUAL_VOTES_SCATTER' }
];

const ValueByOptions = [
    { id: 'LAST_VOTE', labelKey: 'LAST_VOTE' },
    { id: 'AVERAGE_VOTE', labelKey: 'AVERAGE_VOTE' }
];

const EngagementEnpsIndividualHeatmapBox = ({ to, level, filters, groups }) => {

    const [itemsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [displayBy, setDisplayBy] = useState('SCATTER');
    const [valueBy, setValueBy] = useState('LAST_VOTE');
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const PersonEngagementStatusContextModal = getService('PersonEngagementStatusContextModal');
    // Chart configuration state
    const [individualChartConfig, setIndividualChartConfig] = useState(null);
    const [calendarChartConfig, setCalendarChartConfig] = useState(null);



     const displayByOptions = DisplayByOptions.map(option => ({
        id: option.id,
        label: $translate.instant(option.labelKey),
    }));

    const valueByOptions = ValueByOptions.map(option => ({
        id: option.id,
        label:  $translate.instant(option.labelKey),
    }));

    // Event handlers for select change
    const handleDisplayByChange = (event) => {
        setDisplayBy(event.target.value);
    };

    const handleValueByChange = (event) => {
        setValueBy(event.target.value);
    };


    // Methods to create individual charts

    const buildIndividual = (members) => {

        var serieData = [];

        const markerPulsing = {
            radius: 5,
            symbol: 'circle',
            states: {
                hover: {
                    enabled: true
                },
                normal: {
                    enabled: true,
                    lineColor: Style.chartColors[0],
                    lineWidthPlus: 3
                }
            }
        };

        const markerNotPulsing = {
            radius: 5,
            symbol: 'circle',
            states: {
                hover: {
                    enabled: true
                }
            }
        };


        members.forEach(function (member) {

            var vote =
                (member.votes && valueBy === 'LAST_VOTE')  ?
                    member.votes[member.votes.length - 1].rating : member.result;

            var valuation =  (member.votes && valueBy === 'LAST_VOTE') ?
                member.votes[member.votes.length - 1].valuation : member.valuation;


            var memberData = {
                x: member.votes.length,
                y: vote,
                lastVoteDate: member.votes[member.votes.length - 1].date,
                color: Valuation.valuationColor(valuation),
                encryptedId: member.encryptedId

            };
            if (member.hasConversations) {
                memberData.marker = markerPulsing;
            } else {
                memberData.marker = markerNotPulsing;
            }

            serieData.push(memberData);
        });

        return {
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                plotBorderWidth: 0
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: {
                title: {
                    text: 'Votes'
                },
                gridLineWidth: 0,
                tickInterval: 1
            },
            yAxis: {  // Primary yAxis
                gridLineWidth: 0,
                min: 0,
                max: 10,
                title: {
                    text: 'eNPS'
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    point: {
                        events: {
                            click: function (event) {
                                if (!event.point.isCluster)
                                    PersonEngagementStatusContextModal.showEngagementContextDetails(
                                        Dates.firstDayOfMonth(to),
                                        Dates.lastDayOfMonth(to),
                                        event.point.options.encryptedId);
                            }
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        pointFormat: ''
                    },
                    jitter: {
                        x: 0.75, // This will add some randomless to the chart
                        y: 0.25
                    }
                }
            },
            series:  [{
                type: 'scatter',
                data: serieData,

                turboThreshold: 0,

                cursor: 'pointer',

                tooltip: {
                    followPointer: false,
                    pointFormat: (
                        valueBy === 'AVERAGE_VOTE'
                            ? 'Num. votes: {point.x} : eNPS: {point.y}'
                            : 'Last vote: {point.options.lastVoteDate:%b-%Y} : eNPS: {point.y}')
                }
            }]
        };


    };

    const buildCalendarChart = (members) => {

        // ... create calendar chart
        Charts.initializeDateFormats();

        const series = [];
        const enpsCategories = [];
        const conversationCategories = [];


        members.forEach(function (member) {


            var periodEnps =
                (member.votes && valueBy === 'LAST_VOTE')  ?
                    member.votes[member.votes.length - 1].rating : member.result;

            enpsCategories.push(periodEnps);

            conversationCategories.push(false);
            var memberSerie = {
                data: []
            };
            member.votes.forEach(function (vote) {

                memberSerie.data.push([Date.formatServerDateToTimestamp(vote.date), series.length, vote.rating]);
            });
            series.push(memberSerie);
        });

        const numSeries = series.length;


        return {
            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                height: ((35 * numSeries) + 100) + 'px'
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: [{
                type: 'datetime',
                gridLineWidth: 0,
                tickInterval:  90 * 24 * 3600 * 1000,
                labels: {
                    format: '{value:Q%q\' %Y}',
                    rotation: -30
                }
            },
                {
                    type: 'datetime',
                    gridLineWidth: 0,
                    opposite: true,
                    linkedTo: 0,
                    tickInterval:  90 * 24 * 3600 * 1000,
                    labels: {
                        format: '{value:Q%q\' %Y}',
                        rotation: -30
                    }
                }],
            yAxis: [
                {  // Primary yAxis
                    type: 'category',
                    categories: enpsCategories,
                    title: null,
                    gridLineWidth: 0,
                    dashStyle: 'Dot',
                    labels: {
                        useHTML: true,
                        formatter: function () {

                            const member = members[this.pos];

                            var lastVote = member.votes ?
                                member.votes[member.votes.length - 1].rating : member.result;

                            var img;
                            if (lastVote >= 9) {
                                img = '/images/enps_promoter.svg';
                            } else if (lastVote >= 7) {
                                img = '/images/enps_neutral.svg';
                            } else {
                                img = '/images/enps_detractor.svg';
                            }


                            var hasConversationClass = members[this.pos].hasConversations ? 'fa-comments' : 'fa-comments-o';

                            return '<span role="button" class="medium-style text-success" style="display: flex;align-items: center;">'
                                + '<img src="' + img + '" class="img-xsm" />'
                                + '<i  class="fa m-l-xs '  + hasConversationClass + '"></i></span>';
                        },
                        events: {
                            click: function () {
                                if (this.pos >= 0) {
                                    PersonEngagementStatusContextModal.showEngagementContextDetails(
                                        Dates.firstDayOfMonth(to),
                                        Dates.lastDayOfMonth(to),
                                        members[this.pos].encryptedId);
                                }
                            }
                        }
                    }
                }
            ],
            colorAxis: {
                dataClasses: [{
                    from: 1,
                    to: 6,
                    color: Style.bad
                }, {
                    from: 7,
                    to: 8,
                    color: Style.warning
                }, {
                    from: 9,
                    to: 10,
                    color: Style.good
                }]
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    colsize: 90 * 24 * 36e5,
                    rowsize: 0.50,
                    marker: {
                        symbol: 'circle'
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return '<b>' + Highcharts.dateFormat('%e-%b-%Y', new Date(this.point.x)) + '</b>: ' + this.point.value;
                }
            },
            series: series
        };
    };


    useEffect(() => {

        // Currently, we only display the last 12 months:
        const toFormatted = new Date(to).toISOString().split('T')[0];
        const from = new Date(to);
        from.setFullYear(from.getFullYear() - 1);
        const fromFormatted = from.toISOString().split('T')[0];

        const params = {
            filters,
            groupId: groups,
        };

        if (level) {
            params.hierarchyId = level.id;
        }


        getENPSIndividualStats(fromFormatted, toFormatted, params, function (err, result) {
            setLoading(false);
            if (err) {

                if (!ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    ErrorSvrc.showErrorModal(err);
                }
                setLoading(false);
                setResults([]);
            } else {
                setResults(result.members);
            }
        });


    }, [to, level, filters, groups, displayBy, valueBy]);

    useEffect(() => {
        // When loading state changes or error occurs, should handle actions
        if (results && results.length) {
            const paginatedMembers = results.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
            );
            setCalendarChartConfig(buildCalendarChart(paginatedMembers));
            setIndividualChartConfig(buildIndividual(results));
        }
    }, [results, currentPage]);



    return (
        <section className="positioning-box">


            <div className="row">
                <div className="col-xs-8">
                    <h2>{$translate.instant('HI_INDIVIDUALS_MAP')}</h2>
                    <p className="mini-style warm-grey m-t-xs">
                        {$translate.instant(displayBy === 'SCATTER'
                            ? 'INDIVIDUAL_HEATMAP_BY_SCATTER_DESCRIPTION'
                            : 'INDIVIDUAL_HEATMAP_BY_CALENDAR_DESCRIPTION', {metric: 'eNPS'})}
                    </p>
                </div>
                <div className="col-xs-2">
                    <FormControl fullWidth>
                        <InputLabel>{$translate.instant('DISPLAY_MODE')}</InputLabel>
                        <Select
                            value={displayBy}
                            onChange={handleDisplayByChange}
                        >
                            {displayByOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-xs-2">
                    <FormControl fullWidth>
                        <InputLabel>
                            {$translate.instant('VALUE_MODE')}
                            <Tooltip title={$translate.instant('VALUE_MODE_DESCRIPTION_POPUP')}>
                                <InfoIcon className="text-success m-l-xs" />
                            </Tooltip>
                        </InputLabel>
                        <Select
                            value={valueBy}
                            onChange={handleValueByChange}
                        >
                            {valueByOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>


            <HappyLoading message={$translate.instant('LOADING_DATA_SENTENCE_1')} loading={loading}>
                <div className="row p-t p-b">
                    <div className="col-xs-12">


                        {displayBy === 'SCATTER' && (
                            <HighchartsReact highcharts={Highcharts} options={individualChartConfig} />
                        )}

                        {displayBy === 'CALENDAR' && (
                            <HighchartsReact highcharts={Highcharts} options={calendarChartConfig} />
                        )}
                    </div>
                </div>
                {displayBy === 'CALENDAR' && (
                    <div className="row p-t p-b">
                        <div className="col-xs-12">
                            <Box display="flex" justifyContent="center">
                                <Pagination
                                    count={Math.ceil(results.length / itemsPerPage)}
                                    page={currentPage}
                                    shape="rounded"
                                    variant={'outlined'}
                                    size="large"
                                    onChange={(_, page) => setCurrentPage(page)}
                                />
                            </Box>

                        </div>
                    </div>
                )}
            </HappyLoading>
        </section>
    );
};

angularize(EngagementEnpsIndividualHeatmapBox, 'hfEngagementEnpsIndividualHeatmapBox', angular.module('happyForceApp'), {
    to: '=',
    level: '=',
    filters: '=',
    groups: '='
});

export default EngagementEnpsIndividualHeatmapBox;
