'use strict';


import template from './managers_ranking.html';

import async from "async";
import hfManagersRankingTable from "./components/managers_ranking_table/managers_ranking_table.controller";
import hfManagersRankingChart from "./components/managers_ranking_chart/managers_ranking_chart.controller";
import hfManagersRanking from "./managers_ranking.controller";




function ManagersRankingCtrl($scope, AuthSrvc, Settings, ErrorSvrc, People, $intercom, Analytics, ManagersRanking, $stateParams, Filters, $translate, Scores, toaster) {
    'ngInject';

    var _this = this;

    _this.dateFormat = Settings.defaultMonthDateFormat;
    _this.from = $stateParams.to && moment(moment($stateParams.from).format('YYYY-MM-DD HH:mm:ss')).toDate() || Filters.startDate();
    _this.to = $stateParams.to && moment(moment($stateParams.to).format('YYYY-MM-DD HH:mm:ss')).toDate() || Filters.endDate();
    _this.grouping = 'MONTH';
    _this.groupingTypes = ManagersRanking.groupingTypes;


    // Let's build the filtering menu:
    _this.rankingBy = 'OVERALL_SCORE';


    _this.rankingByTypes = [
        { id: 'OVERALL_SCORE', desc: $translate.instant('RANKING_OVERALL_SCORE') },
        { id: 'ACTIVITY_ALL', type: $translate.instant('RANKING_ACTIVITY'), desc: $translate.instant('RANKING_ALL_ACTIVITIES') },
        {
            id: 'ACTIVITY_QUESTION_PUBLISHED',
            type: $translate.instant('RANKING_ACTIVITY'),
            desc: $translate.instant('ACTIVITY_QUESTION_PUBLISHED')
        },
        {
            id: 'ACTIVITY_ANNOUNCEMENT_PUBLISHED', type: $translate.instant('RANKING_ACTIVITY'),
            desc: $translate.instant('ACTIVITY_ANNOUNCEMENT_PUBLISHED')
        },
        {
            id: 'ACTIVITY_ANNOUNCEMENT_REPLY',
            type: $translate.instant('RANKING_ACTIVITY'),
            desc: $translate.instant('ACTIVITY_ANNOUNCEMENT_REPLY')
        },
        { id: 'ACTIVITY_COMMENT_SIGNED', type: $translate.instant('RANKING_ACTIVITY'), desc: $translate.instant('ACTIVITY_COMMENT_SIGNED') },
        { id: 'ACTIVITY_REPLY_SIGNED', type: $translate.instant('RANKING_ACTIVITY'), desc: $translate.instant('ACTIVITY_REPLY_SIGNED') },
        {
            id: 'ACTIVITY_RECOGNITION_PERFORMED', type: $translate.instant('RANKING_ACTIVITY'),
            desc: $translate.instant('ACTIVITY_RECOGNITION_PERFORMED')
        },
        { id: 'RESPONSE_OVERALL', type: $translate.instant('RANKING_RESPONSE_TIMES'), desc: $translate.instant('RANKING_AVERAGE_REPLY_TIMES') },
        { id: 'RESPONSE_FIRST_TIME', type: $translate.instant('RANKING_RESPONSE_TIMES'), desc: $translate.instant('RANKING_FIRST_REPLY_TIMES') },
        { id: 'OVERALL_ENGAGEMENT', type: 'Engagement', desc: $translate.instant('RANKING_OVERALL_ENGAGEMENT') }
    ];

    Scores.list({}, function (err, results) {
        if (!err)
            results.forEach(function (result) {
                if (result.core && result.type != 'ENPS') {
                    _this.rankingByTypes.push(
                        {
                            id: 'SCORE_' + result.id,
                            type: 'Engagement',
                            desc: result.name.defaultValue
                        }
                    );
                }
            });
        else {
            console.error('Error retrieving score names', err);
        }
    });


    _this.loadData = function () {

        _this.loading = true;

        var params = {};
        params.from = _this.from;
        params.to = _this.to;
        params.grouping = _this.grouping;


        if (_this.grouping == 'WEEK') {
            _this.groupingName = $translate.instant('DATE_GROUPING_WEEK');
        } else if (_this.grouping == 'MONTH') {
            _this.groupingName = $translate.instant('DATE_GROUPING_MONTH');
        } else if (_this.grouping == 'QUARTER') {
            _this.groupingName = $translate.instant('DATE_GROUPING_QUARTER');
        }

        var typeId = _.find(_this.rankingByTypes, function (type) {
            return type.id === _this.rankingBy;
        });
        _this.rankingTypeDesc = typeId ? typeId.desc : '';

        ManagersRanking.get(params, function (err, results) {
            _this.loading = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }

            // Let's parse all the data:
            var streams = [];

            _this.dateResults = results.results;

            results.periodResults.forEach(function (ranking) {
                streams.push(function (nextRanking) {
                    People.getProfile(ranking.userId, function (err, user) {
                        if (err) {
                            nextRanking(err, null);
                        } else {
                            var result = {
                                user: user,
                                ranking: ranking
                            };
                            nextRanking(null, result);
                        }
                    });

                });

            });

            async.parallel(streams, function (err, data) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                    return;
                } else {
                    _this.periodData = data;
                }
            });

        });


    };

    _this.exportToExcel = function () {


        var params = {};
        params.from = _this.from;
        params.to = _this.to;
        params.grouping = _this.grouping;
        ManagersRanking.exportToExcel(params, function (err, results) {
            _this.loading = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            } else {
                toaster.pop('success', null, ($translate.instant('MANAGER_REPORT_SENT')));
            }
        });
    };


    this.$onInit = function () {



        ['from', 'to', 'grouping', 'rankingBy'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }

                _this.loadData();
            }, true);
        });

        _this.loadData();


        $intercom.trackEvent('dashboard_visited_managers_ranking');
    };
}



const ManagersRankingView = function($stateProvider) {
    'ngInject';

    $stateProvider.state('reports.ranking', {
        url: '/ranking',
        controller: ManagersRankingCtrl,
        controllerAs: '$mng_ranking',

        template: template,
        data: { pageTitle: 'SIDEMENU_MANAGERS_RANKING', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
};

export default ManagersRankingView;

