const ClassificiationService = function (Restangular, Rollbar, Settings, ErrorSvrc, $log) {
    'ngInject';
    var Company = Restangular.one('company');
    var Analytics = Company.one('classification');

    var Feedback = Restangular.one('company').one('feedback').one('classification');

    var analytics = {};

    // Attitude Analytic method calls

    var classification = {};

    classification.get = function (from, to, attitude, params, callback) {
        Analytics
            .one(attitude)
            .one(moment(from).format(Settings.apiDateFormat))
            .one(moment(to).format(Settings.apiDateFormat))
            .get(params || null)
            .then(function (results) {
                callback(null, results);
            }, function (err) {

                if (!ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    $log.error('Error loading Analytics.Attitude', err, params);
                    Rollbar.error('Error loading Analytics.Attitude', err, params);
                }

                callback(err);
            });
    };

    classification.funnel = function (from, to, params, fromAttitude, toAttitude, callback) {
        Analytics
            .one('funnel')
            .one(fromAttitude)
            .one(toAttitude)
            .one(moment(from).format(Settings.apiDateFormat))
            .one(moment(to).format(Settings.apiDateFormat))
            .get(params || null)
            .then(function (results) {
                callback(null, results);
            }, function (err) {

                if (!ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    $log.error('Error loading Analytics.Attitude', err, params);
                    Rollbar.error('Error loading Analytics.Attitude', err, params);
                }
                callback(err);
            });
    };

    var feedback = {};

    feedback.get = function (params, callback) {

        params.from =  (moment(params.from).format(Settings.apiDateFormat));
        params.to =  (moment(params.to).format(Settings.apiDateFormat));

        Feedback.get(params).then(function (result) {
            callback(null, result);
        }, function (err) {

            callback(err);
        });
    };

    analytics.classification = classification;
    analytics.feedback = feedback;

    analytics.colors = [];
    analytics.colors['ENCHANTED'] = '#ffc200';
    analytics.colors['HAPPY'] = '#37c098';
    analytics.colors['NEUTRAL'] = '#7B955D';
    analytics.colors['SAD'] = '#6c70dc';
    analytics.colors['DISENCHANTED'] = '#df356b';
    analytics.colors['DISENGAGED'] = '#dddddd';
    analytics.colors['SLEEPING'] = '#275980';

    return analytics;
};

export default ClassificiationService;
