'use strict';

import Numbers from "../../../../utilities/number.utilities";
import template from './votes-distribution-chart.html';

/**
 * Controller de activación
 * @param {Service} _service [description]
 */
var VotesDistributionChartCtrl = function ($scope) {
    'ngInject';
    var _this = this;

    _this.votes = [];

    var colors = {
        1: '#df356b',
        2: '#6c70dc',
        3: '#37c098',
        4: '#FFBA18'
    };

    function loadData() {

        if (_this.question && _this.results) {

            _this.votes = [];
            _this.question.values.forEach(function (vote) {
                _this.votes.push({
                    votes: _this.results.ratings[vote.id] ? _this.results.ratings[vote.id] : 0,
                    percent: Numbers.roundNumber((_this.results.ratingPercents[vote.id] ? _this.results.ratingPercents[vote.id] : 0), 0),
                    color:  vote.color || colors[vote.id],
                    imageUrl: vote.imageUrl,
                    label: vote.label
                });
            });
        }
    };

    ['results', 'question'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind(_this, function () {
            return _this[key];
        }), function (n, o) {

            if (!n || n == o) return;
            loadData();
        });
    });
};

const VotesDistributionChartComponent =  angular.module('happyForceApp')
    .directive('votesDistributionChart', function () {
        return {
            scope: {
                results: '=',
                question: '=',
                additionalStyles: '@'
            },
            restrict: 'E',
            template: template,
            controller: VotesDistributionChartCtrl,
            controllerAs: 'votes',
            bindToController: true
        };
    });

export default VotesDistributionChartComponent;
