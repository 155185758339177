'use strict';

import {hasHierarchies} from "../../../services/migrated/hierarchy.service";
import Dates from "../../../../utilities/date.utilities";

function ReplyWithAnnouncementModalCtrl($scope, Announcements, Groups, Analytics, Characteristics, comment, announcementId, noapi, toaster, ErrorSvrc, $translate, $modalInstance, Settings) {
    'ngInject';

    var _this = this;

    _this.comment = comment;
    _this.renotify = false;

    _this.dateFormat = Settings.defaultDateFormat;

    hasHierarchies(function (result) {
        _this.hasHierarchies = result;
    });

    Characteristics.hasCharacteristics(function (result) {
        _this.hasCharacteristics = result;
    });

    _this.hasGroups = Groups.hasGroups();


    _this.getGroupName = function (groupId) {
        return Groups.getGroupName(groupId);
    };

    _this.goToWizard = function () {
        angular.element('.modal-announcements').removeClass('showIntro');
    };

    _this.goToSelector = function () {
        angular.element('.modal-announcements').addClass('showIntro');
    };

    _this.updateExpirationDate = function () {
        _this.expirationDate = Dates.addDoDate(_this.effectiveDate, 'day', _this.duration);

    };



    function findStep(classes) {
        if (!classes) return;
        var findStep = classes.match(/step-(\d)/);
        var actual = 1;
        if (findStep && findStep[1]) {
            actual = parseInt(findStep[1]);
        }

        return actual;
    }

    function validateStep() {
        var actualStep = findStep(
            angular.element('.modal-announcements').attr('class')
        );
        var step = '.step-' + actualStep;

        angular.element(step + ' .error').removeClass('error');
        angular.forEach(angular.element(step + ' [required]'), function (item) {
            if (item.value === '') {
                var $item = angular.element(item);
                if ($item.hasClass('trix-input')) {
                    return;
                }

                if ($item.parent().attr('class') === 'special-input-input') {
                    $item = $item.parent();
                }

                $item.addClass('error');
            }
        });

        if (actualStep === 1) {
            $editor.moveCursorInDirection('backward');
        }

        return angular.element(step + ' .error').length === 0;
    }

    _this.interval = setInterval(fixModalOpen, 500);

    function fixModalOpen() {
        if (!$('body').hasClass('modal-open')) {
            $('body').addClass('modal-open');
        }

        var actualStep = findStep(
            $('.inmodal.announcements').attr('class')
        );

        if (!isIE && actualStep === 0) {
            angular.element('#simulator').show();
        } else {
            angular.element('#simulator').hide();
        }
    }


    function configureWizard() {

        _this.wizardTitle = _this.isEdit
            ? $translate.instant('UPDATE_ANNOUNCEMENT')
            : $translate.instant('ADD_ANNOUNCEMENT');
        _this.wizardSubtitle = null;

        if (_this.comment) {
            _this.wizardTitle = $translate.instant('REPLY_TO_COMMENT');
        }

        _this.wizardOptions = {
            nextButton: $translate.instant('QUESTION_NEXT'),
            prevButton: $translate.instant('QUESTION_PREV'),
            confirm: {
                text: _this.isEdit
                    ? $translate.instant('UPDATE')
                    : $translate.instant('PUBLISH'),
                className: 'btn ok-button pull-right uppercase',
                action: function () {
                    // if (_this.executing) return;


                    _this.announcement.effectiveDate = Dates.toAPIFormat(_this.effectiveDate, Settings.apiDateFormat);
                    _this.announcement.expirationDate = Dates.toAPIFormat(_this.expirationDate, Settings.apiDateFormat);


                    _this.executing = true;
                    if (_this.isEdit)
                        Announcements.PUT(_this.announcement.id, _this.announcement, _this.renotify, function (result) {
                            toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_UPDATED')));
                            _this.executing = false;
                            $modalInstance.close(result.id);
                        }, function (err) {
                            ErrorSvrc.showErrorModal(err);
                            _this.executing = false;
                        });
                    else

                        Announcements.POST(_this.announcement, function (result) {
                            toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_CREATED')));
                            _this.executing = false;
                            $modalInstance.close(result.id);
                        }, function (err) {
                            ErrorSvrc.showErrorModal(err);
                            _this.executing = false;
                        });
                }
            },
            validate: validateStep,
            exit: function () {
                $modalInstance.dismiss();
            }
        };

        if (_this.isEdit || noapi) {
            _this.wizardOptions.return = {
                text: _this.isEdit ? $translate.instant('DELETE') : $translate.instant('RETURN'),
                className: $scope.isEdit
                    ? 'button-delete btn-danger'
                    : 'hf-secondary',
                action: function () {
                    if (_this.isEdit) {
                        if (_this.executing) return;
                        _this.executing = true;
                        swal({
                            type: 'warning',
                            text: $translate.instant('ANNOUNCEMENT_DELETE_MESSAGE'),
                            title: $translate.instant('ANNOUNCEMENT_DELETE'),
                            allowEscapeKey: true,
                            buttons: {
                                cancel: true,
                                confirm: { text: $translate.instant('OK'), className: 'btn btn-danger' }
                            },
                            confirmButtonColor: '#DD6B55',
                            closeOnConfirm: true
                        }).then(function (result) {

                            Announcements.remove(_this.announcement.id, function (result) {
                                toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_DELETED')));
                                _this.executing = false;
                                $modalInstance.close(result.id);
                            }, function (err) {
                                ErrorSvrc.showErrorModal(err);
                                _this.executing = false;
                            });
                        });
                    } else {
                        _this.goToSelector();
                    }
                }
            };
        }

        _this.wizardIntroOptions = {
            name: 'announcements',
            image: 'images/announcement.png',
            title: _this.wizardTitle,
            subtitle: _this.wizardSubtitle,
            explication: $translate.instant('ANNOUNCEMENT_EXPLICATION'),
            cancel: {
                text: $translate.instant('EXIT'),
                action: function () {
                    $modalInstance.dismiss('cancel');
                }
            },
            success: {
                text: $translate.instant('ADD_ANNOUNCEMENT_BUTTON')
            }
        };
    }


    this.$onDestroy = function () {
        clearInterval(_this.interval);
    };

    if (announcementId) {

        _this.isEdit = true;

        Announcements.getOne(announcementId, function (err, announcement) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }
            _this.announcement = announcement;

            // We need to format the dates of the announcement to API date
            _this.effectiveDate = Dates.fromAPIFormat(_this.announcement.effectiveDate, Settings.apiDateFormat);
            _this.expirationDate = Dates.fromAPIFormat(_this.announcement.expirationDate, Settings.apiDateFormat);
            _this.duration = Dates.xDaysTo(_this.effectiveDate, _this.expirationDate);

            // Check if the announcement is segmented
            if (_.isEmpty(_this.announcement.hierarchyIds)
                && _.isEmpty(_this.announcement.groupIds)
                && _.isEmpty(_this.announcement.characteristics)) {
                _this.sendAll = true;
            } else {
                _this.sendAll = false;
            }

            configureWizard();
        });
    } else {

        var duration = 7;
        var today = moment();
        var endDate = moment().add(duration, 'd');

        _this.effectiveDate = today;
        _this.expirationDate = endDate;

        _this.sendAll = true;
        _this.isEdit = false;
        _this.duration = 7;
        _this.announcement = {
            hierarchyIds: null,
            groupIds: [],
            message: '',
            notified: false,
            notify: true,
            title: '',
            repliesToComments: [],
            attachments: [],
            media: [],
            sendAll: true
        };

        if (_this.comment) {
            _this.announcement.repliesToComments.push(_this.comment.id);
        }

        configureWizard();

    }

    //$timeout(_this.onInit, 500);

}


const ReplyWithAnnouncementModal = angular.module('happyForceApp')
    .factory('ReplyWithAnnouncementModal',
        function ($uibModal) {
            var methods = {};
            methods.showModal = function (comment, announcementId, noapi, callback) {

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'lg',
                    template: require('./reply-with-announcement-modal.html'),
                    controller: ReplyWithAnnouncementModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        comment: comment,
                        announcementId: function () {
                            return announcementId;
                        },
                        noapi: function () {
                            return noapi;
                        }
                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );

export default ReplyWithAnnouncementModal;
