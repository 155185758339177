import dotFilter from './migrated/dot2underscore.filter';
import multilanguageFilter from './migrated/multilanguage.filter';
import DateGroupingFormat from "./dateGrouping.filter";
import ScoreNameFilter from "./migrated/scoreName.filter";
import HierarchyNameFilter from "./migrated/hierarchyName.filter";
import trustedFilter from "./trustHtml.filter"
import FactorNameFilter from "./migrated/factorName.filter";
import GroupNamesFilter from "./migrated/groupNames.filter";
import EmployeeCharacteristicFilter from "./migrated/characteristics.filter";
import CharacteristicNameFilter from "./migrated/characteristicName.filter";
import CharacteristicValueNameFilter from "./migrated/characteristicValueName.filter";
import hftokenreplace from "./migrated/hfTokenReplace.filter";
import SanitizeFilter from "./migrated/sanitize.filter";
import ScoreIconFilter from "./migrated/scoreIcon.filter";
import languageNameFilter from "./migrated/languageName.filter";
import userNameFilter from "./migrated/userName.filter";
import stripeHTMLFilter from "./migrated/stripeHTML.filter";






angular
    .module('happyForceApp')
    .filter('dot2underscore', dotFilter)
    .filter('multilanguage', multilanguageFilter)
    .filter('dateGroupingFormat', DateGroupingFormat)
    .filter('scoreName', ScoreNameFilter)
    .filter('hierarchyname', HierarchyNameFilter)
    .filter('trusted', trustedFilter)
    .filter('factorName', FactorNameFilter)
    .filter('groupnames', GroupNamesFilter)
    .filter('employeeCharacteristic', EmployeeCharacteristicFilter)
    .filter('characteristicName', CharacteristicNameFilter)
    .filter('characteristicValueName', CharacteristicValueNameFilter)
    .filter('hftokenreplace', hftokenreplace)
    .filter('sanitize',SanitizeFilter)
    .filter('scoreIcon', ScoreIconFilter)
    .filter('languageName', languageNameFilter)
    .filter('username', userNameFilter)
    .filter('stripeHTML', stripeHTMLFilter);

