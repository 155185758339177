'use strict';

import SurveyIndexChart from '../../components/survey_index_chart/surveyIndexChart';


import hfSurveyDistributionChart from "../../components/survey_distribution_chart/survey_distribution_chart.controller";
import hfSurveyTopSelectedOptions
    from '../../components/survey_top_selected_options/survey_top_selected_options.controller';

function SurveyResultsListViewCtrl($scope, $translate, $intercom, $uibModal) {

    var _this = this;

    _this.visibleQuestions = {};

    _this.visibleBlocks = {};
    _this.initialized = false;


    _this.toggleQuestionDetails = function (questionResults) {
        _this.visibleQuestions[questionResults.question.id] = !_this.visibleQuestions[questionResults.question.id];
    };


    _this.toggleBlockDetails = function (block) {
        _this.visibleBlocks[block.blockId] = !_this.visibleBlocks[block.blockId];
    };

    _this.showComments = function (question, callback) {


        var filters = {
            hierarchies: _this.hierarchies,
            groups: _this.groups,
            segments: _this.segments,
            dateTo: _this.toDate,
            dateFromEnabled: _this.fromDateEnabled,
            dateFrom: _this.fromDate,
            dateToEnabled: _this.toDateEnabled,
            questionFilters: _this.questionFilters
        };


        $intercom.update({ hide_default_launcher: true });
        $uibModal.open({
            backdrop: false,
            animation: true,
            backdropClass: 'right',
            template: require('./../../components/survey_comments/modal.html'),
            controller: 'hfSurveyCommentsCtrl',
            controllerAs: '$ctrl',
            size: 'right survey',
            resolve: {
                survey: _this.survey,
                question: question,
                roundId: function () {
                    return _this.roundId;
                },
                filters: filters
            }
        }).result.then(function (data) {
            $intercom.update({ hide_default_launcher: false });
            callback(null, data);
        }, callback);
    };


    this.$onInit = function () {

        var filterArgs = ['survey'];

        filterArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {
                if (typeof newVal === 'undefined' || _this.initialized) return;
                if (newVal != oldValue && _this.survey.result) {

                    // Set the first block as open
                    if (_this.survey.result.blocks.length > 0) {
                        _this.survey.result.blocks.forEach(function (block) {
                            _this.visibleBlocks[block.blockId] = true;
                        });

                        _this.initialized = true;
                    }
                }
            }, true);
        });


    };


};

const hfSurveyResultsListView = angular.module('happyForceApp')
    .directive('hfSurveyResultsListView', function () {
        return {
            scope: {
                survey: '=',
                roundId: '=',
                hierarchies: '=',
                segments: '=',
                groups: '=',
                questionFilters: '=',
                fromDateEnabled: '=',
                fromDate: '=',
                toDateEnabled: '=',
                toDate: '='
            },
            template: require('./survey_results_list_view.html'),
            controller: SurveyResultsListViewCtrl,
            controllerAs: 'srl',
            bindToController: true
        };
    });

export default hfSurveyResultsListView;
