import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItemIcon, Button } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import HfMultiLanguageText from 'root/app/shared/new-components/hf-multilanguage-text/hfMultilanguageText';
import {darkDropdown} from 'root/app/utilities/color.utilities';
import Tooltip from '@mui/material/Tooltip';

const CommentStatusSelector = ({ comment, onlyActive }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const $translate = getService('$translate');
    const toaster = getService('toaster');
    const FeedbackStatusService = getService('FeedbackStatusService');
    const ErrorSvrc = getService('ErrorSvrc');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeFeedbackStatus = (newStatusId) => {
        FeedbackStatusService.setStatus(comment.id, newStatusId, (error, updatedComment) => {
            if (error) {
                ErrorSvrc.showErrorModal(error);
            } else {
                comment.statuses = updatedComment.statuses;
                getLastStatus();
                toaster.pop('success', null, ($translate.instant('FEEDBACK_STATUS_CHANGED')));
            }
        });
        handleClose();
    };

    const removeStatus = () => {
        FeedbackStatusService.removeStatus(comment.id, (error, updatedComment) => {
            if (error) {
                ErrorSvrc.showErrorModal(error);
            } else {
                comment.statuses = updatedComment.statuses;
                getLastStatus();
            }
        });
        handleClose();
    };

    const getLastStatus = () => {
        if (comment.statuses) {
            const currentCommentStatus = comment.statuses[comment.statuses.length - 1];
            setCurrentStatus(statuses.find(item => item.id === currentCommentStatus.statusTypeId));
        }
    };

    useEffect(() => {
        const listStatuses = () => {
            const archivedStatus = onlyActive ? 'ACTIVE' : 'ANY';
            FeedbackStatusService.list(comment.feedbackType, archivedStatus, (err, fetchedStatuses) => {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    setStatuses(fetchedStatuses.map(status => ({
                        ...status,
                        color: status.phase === 'DISCARDED' ? 'red' : status.phase === 'ACCEPTED' ? 'green' : 'yellow'
                    })));
                }
            });
        };

        listStatuses();
    }, [comment.feedbackType, onlyActive]);

    useEffect(() => {
        getLastStatus();
    }, [statuses, comment.statuses]);

    return (
        <div>
            <Tooltip title={currentStatus ? <HfMultiLanguageText
                multilanguage={currentStatus.label}
                replaceTokens={false}
            /> : $translate.instant('FEEDBACK_NO_STATUS')}
            >
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    startIcon={<ArrowDropDown />}
                    sx={{
                        color: 'gray',
                        justifyContent: 'left',
                        width: '100%',
                        backgroundColor: '#f2f2f2',
                        border: '0px',
                        fontSize: '12px',
                        display: 'inline-flex',
                        maxHeight: '27px',
                        textTransform: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    <>
                        <span className={'m-r-xs'} style={{color: currentStatus?.color}}>■</span>
                        {currentStatus ? <HfMultiLanguageText
                            multilanguage={currentStatus.label}
                            replaceTokens={false}
                        /> : $translate.instant('FEEDBACK_NO_STATUS')}
                    </>

                </Button>
            </Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiMenu-paper':
                        {
                            backgroundColor: darkDropdown,
                            boxShadow: '0 0 3px rgba(86, 96, 117, 0.7)',
                            border: 'medium none',
                            borderRadius: '3px',
                            fontSize: '12px',
                            padding: '0',
                        },
                }}
            >
                {statuses.map((status) => (
                    <MenuItem key={status.id} onClick={() => changeFeedbackStatus(status.id)}>
                        <ListItemIcon>
                            <span style={{color: status?.color}}>■</span>
                        </ListItemIcon>
                        <div className="small-style dark-dropdown-menu-item">
                            <HfMultiLanguageText
                                multilanguage={status.label}
                                replaceTokens={false}
                            />
                        </div>
                    </MenuItem>
                ))}
                <hr/>
                <MenuItem onClick={removeStatus}>
                    <ListItemIcon>
                        <span style={{ color: 'gray' }}>■</span>
                    </ListItemIcon>
                    <div className="small-style dark-dropdown-menu-item">{$translate.instant('FEEDBACK_REMOVE_STATUS')}</div>
                </MenuItem>
            </Menu>
        </div>
    );
};

CommentStatusSelector.propTypes = {
    comment: PropTypes.object.isRequired,
    onlyActive: PropTypes.bool
};

angularize(CommentStatusSelector, 'hfCommentStatusSelector', angular.module('happyForceApp'), {
    comment: '<',
    onlyActive: '<'
});

export default CommentStatusSelector;
