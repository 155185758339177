'use strict';

import ManagersRankingCharts from "../../managers_ranking.charts";

function ManagersRankingChartCtrl($scope, ErrorSvrc, $translate) {
    'ngInject'
    var _this = this;

    this.$onInit = function () {

        _this.quality = 'NO_DATA';

        function renderChart() {
            if (_this.data && _this.type) {
                var result = ManagersRankingCharts.managersRankingChart(_this.data, _this.type, _this.types, $translate);
                _this.managerRankingsChart = result.chart;
                _this.filteredNames = _.map(result.filtered, function (result) {
                    return result.user.name;
                }).join(',');

            }
        }

        ['data', 'type'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;
                renderChart();
            });
        });

        renderChart();

    };
};

const hfManagersRankingChart = angular.module('happyForceApp').directive('hfManagersRankingChart', function () {
    return {
        scope: {
            data: '=',
            type: '=',
            types: '='
        },
        restrict: 'E',
        template: require('./managers_ranking_chart.html'),
        controller: ManagersRankingChartCtrl,
        controllerAs: '$managersrankingchartctrl',
        bindToController: true
    };
});


export default hfManagersRankingChart;
