import Dates from "../../utilities/date.utilities";

export default function ParticipationService(Restangular, Settings, Rollbar, $translate) {
    'ngInject';
    var Company = Restangular.one('company').one('stats').one('participation');

    var methods = {};

    methods.get = function (from, to, params, callback) {

        Company
            .one('from', moment(from).format(Settings.apiDateFormat))
            .one('to', moment(to).format(Settings.apiDateFormat))
            .get(params).then(function (question) {
            callback(null, question);
        }, function (err) {

            Rollbar.error('Error getting Participation stats', err);
            callback(err);
        });
    };

    methods.getDistribution = function (from, to, params, callback) {

        Company
            .one('distribution')
            .one('from', moment(from).format(Settings.apiDateFormat))
            .one('to', moment(to).format(Settings.apiDateFormat))
            .get(params).then(function (question) {
            callback(null, question);
        }, function (err) {

            Rollbar.error('Error getting Participation stats', err);
            callback(err);
        });
    };

    methods.heatmap = function (groupBy, params, callback) {
        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }
        if (_params.to) {
            _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }
        Company.one('grouping').one(groupBy).get(_params).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.getValuationLabel = function (valuation) {
        if (!valuation) return $translate.instant('PARTICIPATION_NO_DATA');

        if (valuation === 'BAD') {
            return $translate.instant('PARTICIPATION_BAD');
        } else if (valuation === 'WARNING') {
            return $translate.instant('PARTICIPATION_CAUTION');
        } else if (valuation === 'GOOD') {
            return $translate.instant('PARTICIPATION_GOOD');
        } else {
            return $translate.instant('PARTICIPATION_EXCELLENT');
        }
    };



    methods.dateGroupingTypes = [
        { id: 'DAY', label: $translate.instant('DATE_GROUPING_DAY') },
        { id: 'WEEK', label: $translate.instant('DATE_GROUPING_WEEK') },
        { id: 'MONTH', label: $translate.instant('DATE_GROUPING_MONTH') },
        { id: 'QUARTER', label: $translate.instant('DATE_GROUPING_QUARTER') }
    ];

    return methods;

}


