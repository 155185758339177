import {IHeatmapColumn, IHeatmapRow, ISegmentInfo} from './heatmap-types';

const createColumnDefinition = (
    id: string,
    name: string,
    visible: boolean,
    type: string,
    hasChildren: boolean,
    isDeployed: boolean,
    icon: string,
    parentId: string | null
): IHeatmapColumn => ({
    id,
    name,
    visible,
    type,
    hasChildren,
    isDeployed,
    icon,
    parentId
});



const buildSegmentInfoFromInfoRowElement = (
    type: string,
    segmentId: string,
    parentId: string | null
): ISegmentInfo => {
    const segmentInfo: ISegmentInfo = { type };

    if (type === 'HIERARCHY') {
        segmentInfo.hierarchyId = segmentId;
    } else if (type === 'GROUP') {
        segmentInfo.groupId = segmentId;
    } else if (type === 'CHARACTERISTIC') {
        segmentInfo.characteristic = {
            characteristicId: parentId as string,
            value: segmentId
        };
    }

    return segmentInfo;
};


const createRowDefinition = (
    segmentId: string,
    name: string,
    type: string,
    activeUsers: number, // adjust based on actual data type
    visible: boolean,
    parentId: string | null,
    hasChildren: boolean,
    level: number,
    isDeployed: boolean
): IHeatmapRow => ({
    segmentId: segmentId,
    name,
    type,
    activeUsers,
    visible,
    parentId,
    hasChildren,
    level,
    isDeployed,
    segmentInfo: buildSegmentInfoFromInfoRowElement(type, segmentId, parentId)
});

const getSegmentId = (result: ISegmentInfo): string => {
    if (result.hierarchyId) {
        return result.hierarchyId;
    } else if (result.groupId) {
        return result.groupId;
    } else if (result.characteristic) {

        return result.characteristic.characteristicId + ':' + result.characteristic.value;
    } else {
        return 'Company';
    }
};

const buildHeatMapKey = (
    type: string,
    id: string,
    segmentId: string,
    keyDate: string,
    dateGrouping: string,
    groupBy: string
): string => `${type}_${id}_${segmentId}_${keyDate}_${dateGrouping}_${groupBy}`;


const buildHighLightKey = (
    type: string,
    id: string,
    segmentId: string,

): string => `highlight`;

const buildLoadedSegmentKey = (
    segmentId: string,
    keyDate: string,
    dateGrouping: string,
    groupBy: string
): string => `${segmentId}_${keyDate}_${dateGrouping}_${groupBy}`;

export {
    createColumnDefinition,
    createRowDefinition,
    getSegmentId,
    buildHeatMapKey,
    buildLoadedSegmentKey
};
