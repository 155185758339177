'use strict';

function AnnouncementEmulatorCtrl(Announcements, $scope) {
    'ngInject';
    var _this = this;


    _this.$onInit = function () {
        if (_this.simulatorHeight) {
            _this.height = _this.simulatorHeight;
        } else {
            _this.height = '550px';
        }
        ['announcement'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }


            }, true);


        });
    };
};

//Directive used to set metisMenu and minimalize button
const hfAnnouncementEmulator = angular.module('happyForceApp').directive('hfAnnouncementEmulator', function () {
    return {
        scope: {
            announcement: '<',
            simulatorHeight: '<'
        },
        replace: true,
        template: require('./announcement-emulator.html'),
        controller: AnnouncementEmulatorCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfAnnouncementEmulator;
