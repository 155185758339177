import { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import { NestedMenuItem } from 'mui-nested-menu';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dates from '../../../../utilities/date.utilities';
import { ListItemText, Typography } from '@mui/material';

interface DateNestedMenuItemProps {
    filters?: any;
    setFilters?: (filters: any) => void;
    open?: boolean;
    mode?: string;
    title?: string;
    id?: string;
    nonRemovable: boolean;
}

const DateNestedMenuItem = ({ filters, setFilters, open, title, id, nonRemovable }: DateNestedMenuItemProps) => {
    const Settings = getService('Settings');
    const [date, setDate] = useState(null);

    //Used to load selected groups from filters
    useEffect(() => {
        if (filters && filters.length > 0) {
            filters.forEach((filter) => {
                if(filter.id === id){
                    setDate(filter.date);
                }
            });
        }
    }, [filters]);

    useEffect(() => {
        if (date && date.isValid() && date.year() > 1900) {
            const item: { date: any, option: string, name: string, id: string, nonRemovable: boolean } = {
                date: null,
                option: '',
                name: '',
                id: '',
                nonRemovable: false
            };

            item.date = date;
            item.option = title;
            item.name = Dates.toAPIFormat(date, Settings.defaultDateFormat);
            item.id = id;
            item.nonRemovable = nonRemovable

            if (filters && filters.findIndex(e => e.id === item.id) > -1) {
                setFilters(filters => filters.filter(filterItem => filterItem.id !== item.id));
            }
            setFilters(dateFilters => [...dateFilters, item]);
        }
    }, [date]);

    const renderDate = () => {
        return (
            <DatePicker
                value={date}
                onChange={setDate}
            />
        );
    }

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderDate()}
        </NestedMenuItem>
    );
};

export default DateNestedMenuItem;
