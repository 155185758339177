'use strict';

import React, { useState, useEffect } from 'react';
import {angularize, getService} from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import Dates from "../../../../utilities/date.utilities";
import EngagementDetailCharts from "../../engagement_detail.charts";
import Numbers from "../../../../utilities/number.utilities";
import Valuation from "../../../../utilities/valuation.utils";
import HappyLoading from "../../../../shared/components/_migrated/loader/loading.directive";
import Style from "../../../../utilities/style.utils";

const ScoresImpactChart = ({ from, to, level, filters, groups, type, dateGrouping }) => {
    const [loading, setLoading] = useState(false);
    const [correlations, setCorrelations] = useState([]);
    const [chartConfig, setChartConfig] = useState(null);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);

    // Assuming Scores, ScoresV2Srvc, ErrorSvrc are provided from AngularJS context
    const Scores = getService('Scores');
    const ScoresV2Srvc = getService('ScoresV2Srvc');
    const ErrorSvrc = getService('ErrorSvrc');
    const Languages = getService('Languages');
    const $translate = getService('$translate');

    const navigateToScore = (scoreId) => {
        // Implement navigation logic
    };


    const processResults = async (error, results) => {
        if (error) {
            setLoading(false);
            setCorrelations([]);
            setChartConfig(null);
            if (ErrorSvrc.isNotEnoughActiveEmployees(error)) {
                setHasEnoughActiveEmployees(false);
            } else {
                ErrorSvrc.showErrorModal(error);
            }
        } else {
            const namePromises = results.map(item =>
                new Promise(resolve => {
                    ScoresV2Srvc.getScore(item.scoreId, (err, score) => {
                        if (err) {
                            // TODO: Handle error
                            resolve('ND');
                        } else {
                            resolve(score.name);
                        }
                    });
                })
            );

            const names = await Promise.all(namePromises);

            const newCorrelations = results.map((item, index) => ({
                ...item,
                name: names[index],
                value: Math.abs(item.correlation),
                result: Numbers.roundNumber(item.result, 2),
                color: Valuation.fillColorForValuation(item.valuation),
                borderColor: Valuation.valuationColor(item.valuation)
            }));

            setCorrelations(newCorrelations);
            setLoading(false);
        }
    };

    const loadData = () => {
        if (!to && !from) return;

        setLoading(true);
        setCorrelations([]);

        const params = {
            from: Dates.firstDayOfMonth(from),
            to: Dates.lastDayOfMonth(to),
            grouping: (dateGrouping ?  null : dateGrouping), // Define dateGrouping
            filters,
            groupId: groups
        };

        if (level) {
            params.hierarchyId = level.id;
        }

        if (type === 'HI') {
            ScoresV2Srvc.stats.hiImpact(params, processResults);
        } else if (type === 'ENPS') {
            ScoresV2Srvc.stats.enpsImpact(params, processResults);
        }
    };

    useEffect(() => {
        loadData();
    }, [from, to, level, filters, groups, type, dateGrouping]);

    useEffect(() => {
        if (correlations) {

            var names = [];
            var values = [];
            correlations.forEach(function (item) {
                var result = item.result ? '' + item.result : 'ND';
                names.push(Languages.getTranslationFromUserLanguage(item.name) + ' (' +  result + ')');
                values.push({ y: item.value, color: item.color, result: item.result, borderWidth: 1, borderColor: item.borderColor });
            });

            const newChartConfig = {
                chart: {
                    type: 'bar',
                    style: {
                        fontFamily: Style.charts.fontFamily,
                        fontSize: Style.charts.fontSmall.size,
                        fontWeight: Style.charts.fontSmall.weight,
                        color: Style.charts.textColor
                    },
                    height: 40 + (40 * values.length),
                    events: {
                        render: function () {
                            var axis = this.xAxis[0];
                            if (!axis.ticks) {
                                return;
                            }

                            var ticks = Object.values(axis.ticks);
                            ticks.forEach(function (tick) {
                                var index = tick.pos;
                                if (index == -1) {
                                    return;
                                }

                                var scoreId = correlations[index].scoreId;
                                if (tick.label.element.onclick) {
                                    return;
                                }

                                tick.label.element.onclick = function () {
                                    navigateToScore(scoreId);
                                };
                            });
                        }
                    }
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: names,
                    gridLineWidth: 0,
                    labels: {
                        useHTML: 'true',
                        formatter: function () {
                            return '<a>' + this.value + '</a>';
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    max: 1,
                    title: null,
                    gridLineWidth: 0,
                    labels: {
                        step: 10,
                        useHTML: true,
                        align: 'left',
                        formatter: function () {
                            if (this.isFirst) {
                                return $translate.instant('LOW_IMPACT');
                            } else if (this.isLast) {
                                return '<span style="margin-left: -80px">' + $translate.instant('HIGH_IMPACT') + '</span>';
                            } else {
                                return '';
                            }
                        }
                    }
                },
                tooltip: {
                    enabled: false
                },
                series: [{
                    data: values,
                    name: $translate.instant('IMPACT'),
                    showInLegend: false
                }]
            };

            setChartConfig(newChartConfig);
        }
    }, [correlations]);

    return (
        <section className="impact-box">
            <HappyLoading loading={loading}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartConfig}
                />
            </HappyLoading>
        </section>
    );
};

angularize(ScoresImpactChart, "hfEngagementScoresImpactChart", angular.module("happyForceApp"), {
    from: "<",
    to: "<",
    level: "<",
    filters: "<",
    groups: "<",
    type: "@"
});

export default ScoresImpactChart;
