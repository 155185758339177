import Style from "./style.utils";

var QuestionsUtils =  {};

var letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZ'.split('');

QuestionsUtils.getTopicLetter = function (optionValue) {
    return letters[optionValue];
};

QuestionsUtils.getTopicColor = function (optionValue) {
    if (optionValue > 0 && optionValue < 16) {
        return Style.questionColors[optionValue - 1];
    } else {
        return '#000000';
    }
};


export default QuestionsUtils;
