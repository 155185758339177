'use strict';

import {getHierarchies, getHierarchyName} from "../../../services/migrated/hierarchy.service";

const AreasFilterCtrl = function ($scope, Filters, AuthSrvc, $translate, Analytics) {
  'ngInject';
    var _this = this;

    this.$onInit = function () {

        var all = $translate.instant('ALL');

        _this.trackEvent = function (action) {
            Analytics.trackEvent('areas_filter', action);
        };

        _this.user = AuthSrvc.user();

        _this.openFilter = function () {
            if (!_this.selectedDepartments) return;

            _this.trackEvent('open_filter');
            _this.openedFilter = true;
            _this.selectedDepartmentBackup = _this.selectedDepartment;
            _this.selectedDepartmentsBackup = _this.selectedDepartments.slice(0);
        };

        _this.isSelected = function (name) {
            if (_this.selectedDepartments[_this.selectedDepartments.length - 1].name == name) {
                return true;
            } else {
                return false;
            }
        };

        _this.setSelectedDepartment = function (selected) {
            if (!_this.selectedDepartments) return;
            _this.selectedDepartment = selected;
            if (_this.selectedDepartments.length == 0 && _this.user.role != 'MANAGER') {
                _this.selectedDepartments.push({ name: all, id: null });
            }

            _this.selectedDepartments.push(selected);
            _this.hierarchies = selected.children;

            if (_this.useFilter)
                Filters.save({ level: selected });

            _this.trackEvent('navigate');
        };

        _this.setSuperiorDepartment = function (selected) {
            if (!selected || !_this.selectedDepartments) return;
            _this.selectedDepartment = selected;
            var index = _this.selectedDepartments.indexOf(selected);
            _this.selectedDepartments = _this.selectedDepartments.slice(0, index + 1);

            if (selected.name == all) {
                getHierarchies(function (err, hierarchies) {
                    if (err) {
                        return console.error('Error Loading Hierarchies', err);
                    }

                    _this.hierarchies = hierarchies;
                });
            } else {
                _this.hierarchies = selected.children;
            }
        };

        _this.resetSelection = function () {
            if (!_this.selectedDepartments) return;
            _this.openedFilter = false;
            _this.selectedDepartments = _this.selectedDepartmentsBackup;
            _this.selectedDepartment = _this.selectedDepartmentBackup;
            if (_this.user.role != 'MANAGER') {
                getHierarchies(function (err, hierarchies) {
                    if (err) {
                        return console.error('Error Loading Hierarchies', err);
                    }

                    _this.hierarchies = hierarchies;
                });
            } else {
                _this.hierarchies = _this.selectedDepartment.children;
            }
        };

        _this.filter = function () {
            _this.openedFilter = false;
            _this.level = {
                id: _this.selectedDepartment.id,
                name: _this.selectedDepartment.name
            };
            _this.trackEvent('apply_filter');
        };

        getHierarchies(function (err, hierarchies) {
            if (err) {
                return console.error('Error Loading Hierarchies', err);
            }

            _this.hierarchies = hierarchies;

            _this.selectedDepartments = [];

            if (_this.user.role != 'MANAGER') {
                _this.selectedDepartment = { name: all, id: null };
                _this.selectedDepartments = [{ name: all }];
            } else {
                if (_this.hierarchies.length > 1) {
                    _this.selectedDepartment = { name: all, id: null };
                    _this.selectedDepartments = [{ name: all }];
                } else {
                    _this.setSelectedDepartment(_this.hierarchies[0]);
                    _this.filter();
                }

            }

            if (_this.useFilter && Filters.level()) {
                var fromFilter = Filters.level();
                if (fromFilter.name === '') {
                    getHierarchyName(fromFilter.id, function (err, name) {
                        fromFilter.name = name;
                    });
                }

                _this.setSelectedDepartment(fromFilter);
                _this.setSuperiorDepartment(fromFilter);
            } else if (_this.level) {
                var fromFilter = _this.level;
                if (fromFilter.name === '') {
                    getHierarchyName(fromFilter.id, function (err, name) {
                        fromFilter.name = name;
                    });
                }

                _this.setSelectedDepartment(fromFilter);
                _this.setSuperiorDepartment(fromFilter);
            }
        });

        ['level'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind($scope, function () {
                return _this[key];
            }), function (newVal, oldVal) {

                if (newVal === oldVal) return;
                _this.setSelectedDepartment(newVal);
                _this.setSuperiorDepartment(newVal);
            });
        });
    };
};

const AreasFilterComponent = angular.module('happyForceApp')
    .directive('hfAreasFilter', function () {
    return {
        scope: {
            level: '=',
            labelSuccess: '@',
            hideLabel: '@',
            useFilter: '@',
            openedFilter: '@',
            additionalStyles: '@'
        },
        template: require('./areas.html'),
        controller: AreasFilterCtrl,
        controllerAs: 'afc',
        transclude: true,
        bindToController: true
    };
});


export default AreasFilterComponent;
