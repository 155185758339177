'use strict';

function SettingsSubscriptionInfoCtrl(Billing, $window, ErrorSvrc) {
    'ngInject';

    var _this = this;

    _this.activeStatuses = ['ACTIVE', 'TRIALING'];

    _this.warningStatuses = ['INCOMPLETE', 'INCOMPLETE_EXPIRED', 'PAST_DUE'];

    _this.dangerStatuses = ['UNPAID', 'CANCELED'];

    _this.unknownStatuses = ['UNKNOWN', 'NONE'];

    _this.paymentFailure = ['INCOMPLETE', 'INCOMPLETE_EXPIRED', 'PAST_DUE', 'UNPAID'];

    _this.isLoading = true;

    function parseSubscriptionData(response)
    {
        _this.subscriptionInfo = _.pick(response, ['amount', 'cancelledOn', 'currency',
            'endsOn', 'interval', 'intervalCount', 'periodEnds', 'quantity', 'status', 'trialEnd',
            'trialStart', 'type', 'managedExternal']);

        _this.badgeStyle = 'badge-disable';

        _this.paymentFailed = false;

        if (_this.activeStatuses.indexOf(_this.subscriptionInfo.status) != -1) {
            _this.badgeStyle = 'badge-success';
        } else if (_this.warningStatuses.indexOf(_this.subscriptionInfo.status)  != -1) {
            _this.badgeStyle = 'badge-warning';
        } else if (_this.dangerStatuses.indexOf(_this.subscriptionInfo.status)  != -1) {
            _this.badgeStyle = 'badge-danger';
        }

        if (_this.paymentFailure.indexOf(_this.subscriptionInfo.status)  != -1) {
            _this.paymentFailed = true;
        }
    }

    Billing.getSubscription(function (err, response) {

        if (err) {
            ErrorSvrc.showErrorModal(err);
        } else {
            parseSubscriptionData(response);
        }
        _this.isLoading = false;
    });


    this.goToBillingPortal = function () {
        _this.isLoading = true;
        Billing.getSubscriptionManageSession(function (err, response) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $window.open(response.url, '_top');
            }
            _this.isLoading = false;
        });
    };

    this.reSubscribe = function () {
        _this.isLoading = true;
        Billing.reSubscribe(function (err, response) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                parseSubscriptionData(response);
            }
            _this.isLoading = false;
        });
    };

}

const hfSettingsSubscriptionInfo = angular.module('happyForceApp')
    .directive('hfSettingsSubscriptionInfo', function () {
        return {
            scope: {
                isBillingInfoValid: '='
            },
            template: require('./subscription_info.html'),
            controller: SettingsSubscriptionInfoCtrl,
            controllerAs: '$sc_ctrl',
            bindToController: true
        };
    });

export default hfSettingsSubscriptionInfo;