import {Avatar, Box, Skeleton} from '@mui/material';
import UserUtils from '../../../../../../utilities/user.utilities';
import React from 'react';
import {CellProps, Person} from '../../types';




const AvatarCell = ({person}: CellProps) => {


    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
        }}>

        <Avatar
            src={person.profileImagePath}
        >{UserUtils.getUserNameAcronym(person?.name, '', 2)}</Avatar>


    </Box>);
}

export default AvatarCell;
