import Valuation from './valuation.utils';
import Numbers from './number.utilities';
import Highcharts from 'highcharts';

var Charts =  {};



// We have to investigate this: for some reason we need to add one day at least in order to draw proper start period date on scores charts.
Charts.oneDayOffset = (3600 * 1000 * 24);


Charts.initializeDateFormats = function () {
    if (!Charts.formatsInitialized) {
        Highcharts.dateFormats = {
            q: function (timestamp) {
                var date = new Date(timestamp),
                    quarter = (Math.floor(date.getUTCMonth() / 3) + 1);
                return quarter;
            }
        };

        Charts.formatsInitialized = true;
    }
};


Charts.initializeDateFormats();


Charts.getDateFormatFromDateGrouping =  function (grouping) {

    var format;
    switch (grouping) {
        case 'MONTH':
            format = '%b \'%y';
            break;
        case 'QUARTER':
            format = 'Q%q\' %Y';
            break;
        case 'YEAR':
            format = '%Y';
            break;
        default:
            format = '%d-%m-%Y';
            break;
    }

    return format;
};


Charts.getAxisDateFormatFromDateGrouping =  function (grouping) {

    var format;
    switch (grouping) {
        case 'MONTH':
            format = '%b \'%y';
            break;
        case 'QUARTER':
            format = 'Q%q\' %Y';
            break;
        case 'YEAR':
            format = '%Y';
            break;
        default:
            format = '%d-%m-%Y';
            break;
    }

    return {
        millisecond: format,
        second: format,
        minute: format,
        hour: format,
        day: format,
        week: format,
        month: format,
        year: format
    };

};

Charts.topicConfig = function (labels, values, colors, showTooltip) {
    var formatedData = [];
    for (var x in labels) {
        formatedData.push([labels[x], values[x]]);
    }

    var total = 0;
    $.each(values, function (x, el) {
        total += el;
    });

    if (total === 0) {return null;}

    /*
     Amarillo: #ffc200,
     Verde: #37c098,
     Violeta: #6c70dc,
     Rojo: #df356b
    */

    return {
        options: {
            colors: colors,
            chart: {
                type: 'pie',
                height: 120
            },
            tooltip: {
                enabled: showTooltip
            }
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false
        },
        title: {
            text: '',
            style: {
                color: '#971a31',
                fontWeight: 'bold',
                fontSize: '15px'
            },
            verticalAlign: 'middle',
            y: 20,
            x: -24
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {},
        series: [
            {
                type: 'pie',
                name: '%',
                innerSize: '80%', // Grosor
                dataLabels: {
                    enabled: false
                },
                data: formatedData
            }
        ],
        loading: false
    };
};


Charts.rangeTopicConfig = function (labels, values, colors, showTooltip, big) {

    var series = [];

    $.each(values, function (x, value) {
        series.push({ name: labels[x], data:  [Numbers.roundNumber(value, 1)] });
    });

    return {
        options: {
            colors: colors,
            chart: {
                type: 'bar',
                height: big ? 90 : 20,
                width: 220,
                spacingBottom: 0,
                marginTop: 0,
                marginLeft: 20,
                marginRight: 20

            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },

            xAxis: {
                visible: false
            },
            yAxis: {
                visible: false
            },
            legend: {
                reversed: true,
                enabled: false
            },
            tooltip: {
                enabled: showTooltip,

                formatter: function () {
                    return this.series.name + ': <b>' + this.y + '%</b>';
                }
            }
        },

        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false
        },
        title: {
            text: ''
        },
        series: series
    };
};


Charts.styles = {

};
Charts.getFontConfig = function (style) {
    if (!Charts.styles[style]) {
        if (style === 'ultra') {
            Charts.styles[style] = {
                fontFamily: 'Helvetica',
                fontSize: '24px',
                fontWeight: 700
            };

        } else if (style === 'big') {
            Charts.styles[style] = {
                fontFamily: 'Helvetica',
                fontSize: '20px',
                fontWeight: 700
            };
        } else if (style === 'medium') {
            Charts.styles[style] = {
                fontFamily: 'Helvetica',
                fontSize: '16px',
                fontWeight: 400
            };
        } else if (style === 'small') {
            Charts.styles[style] = {
                fontFamily: 'Helvetica',
                fontSize: '14px',
                fontWeight: 400
            };
        } else if (style === 'mini') {
            Charts.styles[style] = {
                fontFamily: 'Helvetica',
                fontSize: '12px',
                fontWeight: 400
            };
        } else {
            Charts.styles[style] = Charts.getFontConfig('small');
        }
    }
    return Charts.styles[style];
};


Charts.setFontStyle = function (chartConfig, style) {



    var fontConfig = Charts.getFontConfig(style);
    if (chartConfig.chart) {
        if (!chartConfig.chart.style)
        {chartConfig.chart.style = {};}
        chartConfig.chart.style = _.extend(chartConfig.chart.style, fontConfig);
    }
    if (chartConfig.legend) {
        if (!chartConfig.legend.style)
        {chartConfig.legend.style = {};}
        chartConfig.legend.style = _.extend(chartConfig.legend.style, fontConfig);
    }

    if (chartConfig.xAxis) {
        if (Array.isArray(chartConfig.xAxis)) {
            _.each(chartConfig.xAxis, function (xAxis) {
                if (!xAxis.labels)
                {xAxis.labels = {};}
                if (!xAxis.labels.style)
                {xAxis.labels.style = {};}
                xAxis.labels.style = _.extend(xAxis.labels.style, fontConfig);
            });
        } else {
            if (!chartConfig.xAxis.labels) {
                chartConfig.xAxis.labels = {};
            }
            if (!chartConfig.xAxis.labels.style)
            {chartConfig.xAxis.labels.style = {};}

            chartConfig.xAxis.labels.style = _.extend(chartConfig.xAxis.labels.style, fontConfig);
        }

    }
    if (chartConfig.yAxis) {
        if (Array.isArray(chartConfig.yAxis)) {
            _.each(chartConfig.yAxis, function (yAxis) {
                if (!yAxis.labels)
                {yAxis.labels = {};}
                if (!yAxis.labels.style)
                {yAxis.labels.style = {};}
                yAxis.labels.style = _.extend(yAxis.labels.style, fontConfig);
            });
        } else {
            if (!chartConfig.yAxis.labels)
            {chartConfig.yAxis.labels = {};}
            if (!chartConfig.yAxis.labels.style)
            {chartConfig.yAxis.labels.style = {};}
            chartConfig.yAxis.labels.style = _.extend(chartConfig.yAxis.labels.style, fontConfig);
        }
    }
    if (chartConfig.tooltip) {
        if (!chartConfig.tooltip.style) {
            chartConfig.tooltip.style = {};
        }
        chartConfig.tooltip.style = _.extend(chartConfig.tooltip.style, fontConfig);
    }
    if (chartConfig.plotOptions && chartConfig.plotOptions.series && chartConfig.plotOptions.series.dataLabels) {
        if (!chartConfig.plotOptions.series.dataLabels.style)
        {chartConfig.plotOptions.series.dataLabels.style = {};}
        chartConfig.plotOptions.series.dataLabels.style = _.extend(chartConfig.plotOptions.series.dataLabels.style, fontConfig);
    }



    // For series, we shoudl perform individually
    if (chartConfig.series)
    {_.each(chartConfig.series, function (serie) {
        if (serie.dataLabels) {
            if (!serie.dataLabels.style)
            {serie.dataLabels.style = {};}
            serie.dataLabels.style = _.extend(serie.dataLabels.style, fontConfig);
        }
    });}

    return chartConfig;
};

export default Charts;
