import {ISegmentInfo} from '../../../../shared/new-components/hf-heatmap/heatmap-types';




type SegmentFilter = {
    hierarchyId?: string;
    groupId?: string;
    characteristic?: {
        characteristicId: string;
        value: string;
    }
}

const heatmapSegmentToFilter = (segmentInfo: ISegmentInfo): SegmentFilter => {

    const filter = {
        hierarchyId: segmentInfo.type === 'HIERARCHY' ? (segmentInfo.hierarchyId !== 'Company' ? segmentInfo.hierarchyId : undefined): undefined,
        groupId: segmentInfo.type === 'GROUP' ? segmentInfo.groupId: undefined,
        characteristic:  segmentInfo.type === 'CHARACTERISTIC' ? {
            characteristicId: segmentInfo.characteristic.characteristicId,
            value: segmentInfo.characteristic.value,
        }: undefined,
    }

    return filter;
}

export {heatmapSegmentToFilter, SegmentFilter};
