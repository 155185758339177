
function LanguageSelectorCtrl(Languages, $modalInstance, excludedLanguages, includedLanguages) {
    'ngInject';
    var _this = this;

    _this.availableLanguages = Languages.list.filter(function (lang) {
        let notExcluded = excludedLanguages.indexOf(lang.code) === -1;
        let included = (includedLanguages == null || includedLanguages.length === 0)
            ? true : includedLanguages.indexOf(lang.code) !== -1;

        return notExcluded && included;
    });

    _this.addLanguage = function (lang) {
        $modalInstance.close(lang);
    };

    _this.cancel = function () {
        $modalInstance.dismiss('cancel');
    };


    this.$onInit = function () {
    };



}

const LanguageSelectorComponent = angular.module('happyForceApp')
    .factory('LanguageSelectorService',  function (CompanySvrc, AuthSrvc, $uibModal, $translate) {
        'ngInject';
    var methods = {};

    methods.showSelectLanguageModal = function (excludedLanguages, includedLanguages, callback) {
        $uibModal.open({
            backdrop: 'static',
            template: require('./language_selector.html'),
            controller: LanguageSelectorCtrl,
            controllerAs: '$ctrl',
            size: 'language-selector',
            resolve: {
                excludedLanguages: function () {
                    return excludedLanguages;
                },
                includedLanguages: function () {
                    return includedLanguages;
                }
            }
        }).result.then(function (item) {
            if (callback) {
                callback(item.code);
            }

        }, function (res) {
            console.log('Modal ' + res);
        });
    };

    return methods;
});


export default LanguageSelectorComponent;
