/**
 * INSPINIA - Responsive Admin Theme
 * 2.3
 *
 * Custom scripts
 */
var isMobile;

$(document).ready(function () {
    $('.navbar-default.hide').removeClass('hide');

    // Block all date-time input
    $(document.body).on('keypress', 'input[date-time]', function (e) {
        e.preventDefault();
    });

    // Prevens dropdown menu from going of sceen
    $('> .dropdown-menu', this).parent().hover(function () {
        console.log('JAJAJAJJAJA');
        /*var menu = $(this).find('ul');
        var menupos = $(menu).offset();

        if (menupos.left + menu.width() > $(window).width()) {
            var newpos = -$(menu).width();
            menu.css({ left: newpos });
        }*/
    });

    // Full height
    function fixHeight() {
        var heightWithoutNavbar = $('body > #wrapper').height() - 61;
        $('.sidebard-panel').css('min-height', heightWithoutNavbar + 'px');

        var navbarHeigh = $('nav.navbar-default').height();
        var wrapperHeigh = $('#page-wrapper').height();

        if (navbarHeigh > wrapperHeigh) {
            $('#page-wrapper').css('min-height', navbarHeigh + 'px');
        }

        if (navbarHeigh < wrapperHeigh) {
            $('#page-wrapper').css('min-height', $(window).height() + 'px');
        }

        if ($('body').hasClass('fixed-nav')) {
            $('#page-wrapper').css('min-height', $(window).height() - 60 + 'px');
        }

        var styles = {
            overflow: 'auto',
            height: $('#page-wrapper').height() - 167 + 'px'
        };
        $('#comment-list').css(styles);

        // Topics
        if ($(document.body).hasClass('topics-detail')) {
            var $dashboardHeader = $('#dashboard-header');
            $('.truncate', $dashboardHeader).css({
                maxWidth: $dashboardHeader.width() - 100 + 'px'
            });
        }

        isMobile = (window.matchMedia('only screen and (max-width: 760px)').matches);

        $('.full-height').height($(window).innerHeight()
         - $('.topnavbar').outerHeight()
         - $('#dashboard-header').outerHeight()
         - $('.footer').outerHeight());
    }

    function sameHeight() {
        //$('#secundary').css('height', $('#primary').height() + 20 + 'px');
    }

    $(window).bind('load resize scroll', function () {
        sameHeight();

        $('.fullheight').height($('body').height() + 'px');

        if (!$('body').hasClass('body-small')) {
            fixHeight();
        } else {
            // Hack menu flotante
            if ($('body').hasClass('mini-navbar')) {
                $('nav.navbar-default').css(
                  'min-height',
                  $('#page-wrapper').height() + 'px'
                );
            } else {
                $('nav.navbar-default').css(
                  'min-height',
                  $('#primary').height() + 'px'
                );
            }
        }
    });

    $(window).bind('load resize', function () {
        if ($(this).width() < 1024) {
            $('body').addClass('body-small');
        } else {
            $('body').removeClass('body-small');
        }
    });
});

// TABS
$(document.body).on('click', '.nav-tabs li a', function (e) {
    e.preventDefault();
    $(this).tab('show');
});

$(document.body).on('click', 'a.closeNavigation', function (e) {
    e.preventDefault();
    $('body').removeClass('mini-navbar');
});

$(document.body).on('click', 'nav.navbar-static-side a', function (e) {
    e.preventDefault();
    setTimeout(function () {
        $('body').removeClass('mini-navbar');
    }, 500);
});




// Polyfill
if (typeof Object.assign != 'function') {
    Object.assign = function (target, varArgs) { // .length of function is 2
        'use strict';
        if (target == null) { // TypeError if undefined or null
            throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];

            if (nextSource != null) { // pasamos si es undefined o null
                for (var nextKey in nextSource) {
                    // Evita un error cuando 'hasOwnProperty' ha sido sobrescrito
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }

        return to;
    };
}


(function () {
    // hold onto the drop down menu
    var dropdownMenu;

    // and when you show it, move it to the body
    $(window).on('show.bs.dropdown', function (e) {
        // grab the menu
        dropdownMenu = $(e.target).find('.dropdown-menu');

        // detach it and append it to the body
        $('body').append(dropdownMenu.detach());

        // grab the new offset position
        var eOffset = $(e.target).offset();

        // make sure to place it where it would normally go (this could be improved)
        dropdownMenu.css({
            display: 'block',
            top: eOffset.top + $(e.target).outerHeight(),
            left: eOffset.left
        });
    });

    // and when you hide it, reattach the drop down, and hide it normally
    $(window).on('hide.bs.dropdown', function (e) {
        $(e.target).append(dropdownMenu.detach());
        dropdownMenu.hide();
    });
})();

