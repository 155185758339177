import React from 'react';
import { angularize } from 'reactInAngular';
import PropTypes from 'prop-types';
import CommentShowRepliesAction
    from 'root/app/shared/new-components/hf-comments/components/comment-actions/comment-show-replies-action/CommentShowReplieaAction';


const CommentReactionsBar = ({ comment, showReplies, commentUpdatedCallback }) => {
    return (
        <div style={{ display: 'flex', gap: '12px'}}>

            <span>
                <i className="fa fa-thumbs-o-up"></i>
                <span className="m-l-xs">{comment.likes}</span>
            </span>


            <span>
                <i className="fa fa-thumbs-o-down"></i>
                <span className="m-l-xs">{comment.dislikes}</span>
            </span>


            {showReplies && (
                <CommentShowRepliesAction
                    comment={comment}
                    commentUpdatedCallback={commentUpdatedCallback}
                />
            )}
        </div>
    );
};

CommentReactionsBar.propTypes = {
    comment: PropTypes.object.isRequired,
    replyOf: PropTypes.string,
    showReplies: PropTypes.bool,
    commentUpdatedCallback: PropTypes.func
};

angularize(CommentReactionsBar, 'hfCommentReactionsBar', angular.module('happyForceApp'), {
    comment: '<',
    replyOf: '<',
    showReplies: '<',
    commentUpdatedCallback: '='
});

export default CommentReactionsBar;
