'use strict';

import hfFeedbackAmountSettings from '../components/feedback_amount_settings/feedback_amount_settings.controller';
import hfFeedbackTransparencySettings from '../components/feedback_transparency_settings/feedback_transparency_settings.controller';

function SomethingIsWrongFeedbackConfigCtrl($scope, Analytics) {
    'ngInject';

    var _this = this;

    ['enabled', 'employeeSignatureMode', 'managerSignatureMode', 'privacyMode', 'sharedAmmountComments', 'maxCommentsPerDay'].forEach(function (key) {
        // Listeners
        $scope.$watch(
            angular.bind(_this, function () {
                return $scope.feedbackConfiguration.feedbackConfiguration['CRITICISM'][key];
            }),
            function (n, o) {
                if (n === o) {
                    return;
                }
                $scope.saveFeedbackSettings();
            }
        );
    });


    Analytics.trackEvent('settings_somethingiswrong_feedback_config');
}

const hfSomethingIsWrongFeedbackConfig = angular.module('happyForceApp')
    .directive('hfSomethingIsWrongFeedbackConfig', function () {
        return {
            template: require('./somethingiswrong_feedback_config.html'),
            controller: SomethingIsWrongFeedbackConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfSomethingIsWrongFeedbackConfig;
