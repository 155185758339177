'use strict';

function SurveyReminderSendCtrl($scope, $modalInstance, survey, Languages) {
    'ngInject';

    var _this = this;

    _this.header = Languages.createMultiLanguage();
    _this.message = Languages.createMultiLanguage();
    _this.appReminder = true;
    _this.mailReminder = true;
    _this.valid = false;


    // Get current survey languages, based on the title of the survey
    _this.surveyLanguages = Languages.getLangs(survey.title);
    _this.currentReminderLanguages = [];

    _this.addReminderLanguage = function (language) {
        Languages.setLangValue(_this.header, null, language);
        Languages.setLangValue(_this.message, null, language);
        _this.currentReminderLanguages = Languages.getLangs(_this.header);
    };

    _this.removeReminderLanguage = function (language) {
        Languages.removeLangValue(_this.header, language);
        Languages.removeLangValue(_this.message, language);
        _this.currentReminderLanguages = Languages.getLangs(_this.header);
    };

    _this.setReminderLanguage = function (language) {
        _this.selectedLanguage = language;
    };

    _this.ok = function () {
        $modalInstance.close(
            {
                header: _this.header,
                message: _this.message,
                appReminder: _this.appReminder,
                mailReminder: _this.mailReminder

            }
        );
    };

    _this.close = function () {
        $modalInstance.dismiss(null);
    };

    ['header', 'message', 'appReminder', 'mailReminder'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind(_this, function () {
            return _this[key];
        }), function (n, o) {
            if (n == o) {
                return;
            }
            const validMessageAndTitle = _this.header.defaultValue && _this.message.defaultValue;
            const validReminderTypes = _this.appReminder || _this.mailReminder;
            _this.valid = validMessageAndTitle && validReminderTypes;
        }, true);
    });


}



const SurveySendReminderModal = angular.module('happyForceApp')
    .factory('SurveySendReminderModal',
        function ($uibModal, Surveys) {
            var methods = {};

            methods.showModal = function (survey, callback) {

                $uibModal.open({
                    backdrop: 'static',
                    template: require('./survey_reminder_send.html'),
                    controller: SurveyReminderSendCtrl,
                    resolve: {
                        survey: survey
                    },
                    controllerAs: '$ctrl'
                }).result.then(function (reminderContent) {
                    Surveys.sendReminder(survey.id, reminderContent, function (err, result) {
                        callback(err, result);
                    });
                }, function (res) {
                    console.log('Modal ' + res);
                });

            };

            return methods;
        }
    );


export default SurveySendReminderModal;
