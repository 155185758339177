
import React, {useState} from 'react';
import {BulkActionType, UserListingTable} from '../../../../components/user_listing/components/users_table';
import {BulkActionPerson, Person} from '../../../../components/user_listing/types';
import {
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_Row,
    MRT_SortingState,
    MRT_TableOptions
} from 'material-react-table';


type UsersInviteTableProps = {
    persons: BulkActionPerson[];
    currentAction: BulkActionType | undefined;
    onDeleteUser: (person: BulkActionPerson) => void;
    onSaveUser: (index: number, person: BulkActionPerson) => void;
}

const BulkActionReviewAndProcessPanel = ({persons, currentAction, onDeleteUser, onSaveUser}: UsersInviteTableProps) => {

    const [table, setTable] = useState<MRT_TableOptions<BulkActionPerson>['table'] | null>(null);


    const [isSaving, setIsSaving] = useState(false);

    const [isValid, setIsValid] = useState(true);

    const [currentEditingRow, setCurrentEditingRow] = useState<MRT_Row<BulkActionPerson> | null>(null);
    const [currentEditingPerson, setCurrentEditingPerson] = useState<BulkActionPerson | null>(null);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const handleSaveUser: MRT_TableOptions<BulkActionPerson>['onEditingRowSave'] = async ({
        values,
        table,
    }) => {

        if (!isValid) {
            return;
        }
        onSaveUser(currentEditingRow.index,  currentEditingPerson as BulkActionPerson);
        table.setEditingRow(null); //exit editing mode
        table.setCreatingRow(null); //exit cr
        currentEditingRow.toggleExpanded(false);
        setCurrentEditingRow(null);
        setCurrentEditingPerson(null);

    };

    const handleDeleteUser = (row: MRT_Row<BulkActionPerson> | MRT_Row<BulkActionPerson>[]) => {

        if (Array.isArray(row)) {
            // @ts-ignore
            row.values().forEach((r) => {
                onDeleteUser(r.original);
            });
        } else {
            onDeleteUser(row.original as BulkActionPerson);
        }
    }



    return (
        <UserListingTable
            totalUsers={persons.length}
            data={persons}
            isError={false}
            isRefetching={false}
            isLoading={false}
            isSaving={isSaving}
            currentEditingRow={currentEditingRow}
            currentEditingPerson={currentEditingPerson}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            setSorting={setSorting}
            setCurrentEditingRow={setCurrentEditingRow}
            setCurrentEditingPerson={setCurrentEditingPerson}
            sorting={sorting}
            globalFilter={globalFilter}
            columnFilters={columnFilters}
            handleSaveUser={handleSaveUser}
            onTableCreated={(table) => {setTable(table)}}
            onValidationChange={(isValid) => {setIsValid(isValid)}}
            pagination={pagination}
            setPagination={setPagination}
            handleCreateUser={() =>{}}
            handleDeleteUser={handleDeleteUser}
            handleResendInvitation={() =>{}}
            handleResetUserProfile={() =>{}}
            handleResubscribeUser={() =>{}}
            bulkActionType={currentAction}
        />
    );

}

export default BulkActionReviewAndProcessPanel;
