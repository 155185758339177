import Scope from "../../../../utilities/scope.utilities";
import Numbers from "../../../../utilities/number.utilities";
import hiEvolutionChart from "./happiness-evolution-chart.render";

import template from './happiness-evolution-chart.html';
import {getHierarchyName} from "../../../../shared/services/migrated/hierarchy.service";

function happinessEvolutionChartCtrl($scope, $translate, Milestone, Settings, $timeout) {
    'ngInject';
    var _this = this;

    Scope.initialize(_this, ['hiEvolutionChartConfig'], {
        title: $translate.instant('LOADING_CHART')
    });

    var getMaxForValues = function (values) {
        var participationValues = [];

        Object.keys(values).forEach(function (key) {
            participationValues.push(values[key].totalParticipants);

        });

        return Math.max.apply(null, participationValues);
    };

    function loadData() {

        if (!_this.seriesData) {
            return;
        }
        var participationMax = getMaxForValues(_this.seriesData.votes);
        var tickInterval = 25;
        if (participationMax < 25) {
            tickInterval = 5;
        }

        var dateFormat = $translate.instant('DATE_FORMAT');
        if (dateFormat === 'DATE_FORMAT')
            dateFormat = Settings.defaultDateFormat;

        var periodData = [];
        var participationData = [];
        Object.keys(_this.seriesData.votes).forEach(function (date) {
            var timestamp = parseInt(moment(date).format('x'));
            var vote = _this.seriesData.votes[date];
            var roundedIndex = Numbers.roundNumber(vote.indexRating);
            periodData.push([timestamp, roundedIndex > 0 ? roundedIndex : null]);
            participationData.push([timestamp, vote.totalVotes > 0 ? vote.totalVotes : null]);
        });

        var previousPeriodData = [];
        var previousParticipationData = [];

        if (_this.previousSeriesData) {
            Object.keys(_this.previousSeriesData.votes).forEach(function (date) {
                var timestamp = parseInt(moment(date).format('x'));
                var vote = _this.previousSeriesData.votes[date];
                var roundedIndex = Numbers.roundNumber(vote.indexRating);
                previousPeriodData.push([timestamp, roundedIndex > 0 ? roundedIndex : null]);
                previousParticipationData.push([timestamp, vote.totalVotes > 0 ? vote.totalVotes : null]);
            });
        }

        var milestonesData = [];
        _.each(_this.milestonesData, function (milestone) {
            milestone.date = moment(milestone.date, Settings.apiDateFormat).toDate().getTime();
            if (milestone.hierarchyId) {
                getHierarchyName(milestone.hierarchyId, function (err, name) {
                    if (err) {
                        milestone.hierarchyName = '?';
                    } else {
                        milestone.hierarchyName = name;
                    }

                });
            }
            else {
                milestone.hierarchyName = $translate.instant('MILESTONE_ALL_COMPANY');
            }
            milestonesData.push(hiEvolutionChart.hiEvolutionMilestone(milestone.date, milestone.title,
            function () {
                _this.editMilestone(milestone);
            }, function () {

                _this.hoverMilestone(milestone);
            }));
        });

        var options = {
            index: {
                label: $translate.instant('HI'),
                serieName: $translate.instant('HI_EVOLUTION_CHART_CURRENT_PERIOD'),
                data: periodData
            },

            participation: {
                label: $translate.instant('VOTES'),
                serieName: $translate.instant('HI_EVOLUTION_CHART_CURRENT_PERIOD'),
                data: participationData
            },
            milestones: milestonesData,
            tickInterval: tickInterval,
            dateFormat: dateFormat,
            showLegend: _this.previousSeriesData ? true :  false,
            events: {
                load: function () {
                    $(window).trigger('resize');
                }
            }
        };

        if (_this.previousSeriesData) {
            options.previousIndex =  {
                label: $translate.instant('HI'),
                serieName: $translate.instant('HI_EVOLUTION_CHART_PREVIOUS_PERIOD'),
                data: previousPeriodData
            };

            options.previousParticipation = {
                label: $translate.instant('VOTES'),
                serieName: $translate.instant('HI_EVOLUTION_CHART_PREVIOUS_PERIOD'),
                data: previousParticipationData
            };
        }

        if (_this.addMilestone) {
            options.events.click = function (date) {
                _this.addMilestone(new Date(date));
            };
        }

        _this.hiEvolutionChartConfig = hiEvolutionChart.hiEvolutionConfig(options, (_this.addMilestone ? true : false));

        // Repaint fix (launch error in console)
        $timeout(function () {
            $scope.$apply && $scope.$apply();
        });

        var chart = $('#happinessEvolutionChart').highcharts();
        if (chart) {
            if (chart.yAxis.length == 2) {
                chart.yAxis[1].update({
                    tickInterval: tickInterval
                });
            }
        }
    };

    ['seriesData', 'previousSeriesData'].forEach(function (key) {
        // Listeners
        $scope.$watch(angular.bind(_this, function () {
            return _this[key];
        }), function (n, o) {

            if (!n || n == o) return;
            loadData();
        });
    });

}


const HappinessIndexEvolutionComponent = angular.module('happyForceApp')
    .directive('happinessEvolutionChart', function () {
        return {
            scope: {
                seriesData: '=',
                previousSeriesData: '=',
                milestonesData: '=',
                addMilestone: '=',
                editMilestone: '=',
                hoverMilestone: '='
            },
            template: template,
            controller: happinessEvolutionChartCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });


export default HappinessIndexEvolutionComponent;
