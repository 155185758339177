import React, {useEffect} from 'react';
import {Box, Button, LinearProgress, Typography} from '@mui/material';

import {getService} from 'reactInAngular';
import {BulkActionPerson, BulkActionType} from '../../../../components/user_listing/types';


type BulkActionReviewAndProcessPanelProps = {
    currentAction: BulkActionType | null;
    isProcessing: boolean;
    totalProcessedSuccess: number;
    totalProcessedFailure: number;
    totalToProcess: number;
    onProceed: () => void;
    onRestart: () => void;
    uploadedPersons: BulkActionPerson[];
}

const BulkActionReviewAndProcessPanelStep=({currentAction, uploadedPersons, onProceed, isProcessing, totalProcessedSuccess, totalProcessedFailure, totalToProcess, onRestart } : BulkActionReviewAndProcessPanelProps) => {

    const $translate = getService('$translate');

    const [successProgress, setSuccessProgress] = React.useState(0);
    const [failureProgress, setFailureProgress] = React.useState(0);

    const [hasPendingIssues, setHasPendingIssues] = React.useState<boolean>(false);


    useEffect(() => {

        // Update if there are issues pending based on the uploaded persons
        setHasPendingIssues(uploadedPersons.some(person => person.recordFailures?.length > 0));

    }, [uploadedPersons]);


    const renderProcessingStart = () => {

        if (isProcessing) {
            return null;
        }

        return (
            <>
                {hasPendingIssues && (
                    <Typography
                        sx={(theme) => ({color: theme.palette.error.main})}
                        variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_PROCESS_ISSUES_PENDING')}</Typography>

                )}

                {!hasPendingIssues && (
                    <Typography
                        variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_PROCESS_ALL_READY')}</Typography>
                )}

                <Button onClick={onProceed}
                    disabled={hasPendingIssues || isProcessing}
                    variant={'contained'}>{isProcessing ? $translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_PROCESSING') : $translate.instant('BULK_EMPLOYEE_PROCESSING_REVIEWED_PROCEED')}</Button>

            </>
        )
    }


    const renderProcessingProgress = () => {

        if (!isProcessing) {
            return null;
        }
        return (


            <Box sx={(theme) => ({
                display: 'flex',
                width: '100%',
                gap: theme.spacing(1),
                flexDirection: 'column',
                alignItems: 'baseline',
                textAlign: 'left'
            })}>

                <LinearProgress sx={{width: '100%', height: '10px'}} variant="determinate" value={((totalProcessedSuccess + totalProcessedFailure) / totalToProcess) * 100} />


                <Typography variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_PROCESSING_SUCCESS_PROGRESS',{
                    total: totalToProcess,
                    current: totalProcessedSuccess
                })}</Typography>

                {totalProcessedFailure > 0 && (

                    <Typography variant={'caption'} sx={(theme) => ({color: theme.palette.error.main})}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_PROCESSING_ERROR_PROGRESS',{
                        total: totalToProcess,
                        current: totalProcessedFailure
                    })}</Typography>
                )}


            </Box>

        )

    };

    const renderCompletedMessage = () => {


        if (!isProcessing || (totalProcessedSuccess + totalProcessedFailure) < totalToProcess) {
            return null;
        }


        return (
            <Box sx={(theme) => ({
                display: 'flex',
                width: '100%',
                gap: theme.spacing(1),
                flexDirection: 'column',
                alignItems: 'baseline',
                textAlign: 'left'
            })}>

                <Typography variant={'caption'}>
                    {$translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_COMPLETED')}
                </Typography>


                <Button onClick={onRestart}
                    variant={'contained'}>{isProcessing ? $translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_COMPLETED_RESTART') : $translate.instant('BULK_EMPLOYEE_PROCESSING_REVIEWED_PROCEED')}</Button>


            </Box>
        )

    };

    return (
        <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column'})}>
            <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column', alignItems: 'baseline', textAlign: 'left'})}>

                <Box sx={(theme) => ({
                    display: 'flex',
                    width: '100%',
                    gap: theme.spacing(1),
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    textAlign: 'left'
                })}>

                    {renderProcessingStart()}

                    {renderProcessingProgress()}

                    {renderCompletedMessage()}

                </Box>

            </Box>
        </Box>
    );
}

export default BulkActionReviewAndProcessPanelStep;
