const StatsService = function (Restangular, Settings, Rollbar, $log) {
  'ngInject';
    var Company = Restangular.one('company');
    var Stats = Company.one('stats');

    var stats = {};

    stats.questions = {
        get: function (params, callback) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = moment(params.from).format(Settings.apiDateFormat);
            }

            if (_params.to) {
                _params.to = moment(params.to).format(Settings.apiDateFormat);
            }

            $log.debug('Stats.Questions.get', _params);
            Stats.one('questions')
                .get(_params || null)
                .then(function (stats) {
                    $log.debug('Stats.Questions.get (response)', stats);
                    callback(null, stats);
                }, function (err) {

                    $log.error('Error loading questions stats', err, _params);
                    Rollbar.error('Error loading questions stats', err, _params);
                    callback(err);
                });
        }
    };

    stats.hierarchy = {
        get: function (params, callback) {
            var _params = angular.copy(params);
            if (_params.from) {
                _params.from = moment(params.from).format(Settings.apiDateFormat);
            }

            if (_params.to) {
                _params.to = moment(params.to).format(Settings.apiDateFormat);
            }

            $log.debug('Stats.Hierarchy.get', params);

            Stats.one('hierarchy')
                .get(params || null)
                .then(function (stats) {
                    $log.debug('Stats.Hierarchy.get (response)', stats);
                    callback(null, stats[0]);
                }, function (err) {

                    $log.error('Error loading hierarchy stats', err, params);
                    Rollbar.error('Error loading hierarchy stats', err, params);
                    callback(err);
                });
        }
    };

    return stats;
};

export default StatsService;
