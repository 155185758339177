'use strict';

function SettingsBillingInfoCtrl($scope, Billing, $translate, ErrorSvrc, Analytics, toaster) {
    'ngInject'

    Analytics.trackEvent('settings_billing_info');

    var _this = this;


    _this.billingForm = {};

    _this.billing = {
        country: null
    };

    _this.taxId = null;
    _this.taxIdRequired = false;
    _this.isLoading = true;
    _this.isBillingInfoValid = true;
    _this.isBillingEditing = false;


    var EUCountries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES',
        'FI', 'FR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL',
        'PT', 'RO', 'SE', 'SI', 'SK', 'UK'
    ];

    _this.getTaxType = function (country) {
        if (country) {
            var countryCode = country.toLowerCase();
            if (countryCode == 'es') {
                return 'es_cif';
            }

            if (EUCountries.indexOf(countryCode) > -1) {
                return 'eu_vat';
            }
        }

        return null;
    };

    $scope.taxIdRequiredFn = function () {
        console.log('check');
    };

    $scope.prepareTaxes = function () {
        if (_this.taxId) {
            var taxType = _this.getTaxType(_this.billing.country);
            if (taxType != null) {
                var taxIds = {};
                taxIds[taxType] = _this.taxId;
                _this.billing.taxIds = taxIds;
            }
        }
    };


    $scope.$watch(angular.bind(_this, function () {
        return _this['taxId'];
    }), function (n, o) {

        if (n == o) return;
        $scope.prepareTaxes();
    }, true);


    $scope.$watch(angular.bind(_this, function () {
        return _this['billingForm'].$valid;
    }), function (n, o) {

        if (n == o) return;

        // Update the status and verify: if is not fine, we enter in edit mode
        _this.isBillingInfoValid = _this.billingForm.$valid;

    }, true);



    $scope.$watch(angular.bind(_this, function () {
        return _this['billing'];
    }), function (n, o) {

        if (n == o) return;

        if (_this.billing) {
            var taxType = _this.getTaxType(_this.billing.country);
            _this.taxIdRequired = (taxType != null);
            $scope.taxIdRequired =  _this.taxIdRequired;

            if (_this.billing.taxIds) {
                var values = _.values(_this.billing.taxIds);
                _this.taxId = values.shift();
            }
            $scope.prepareTaxes();

            // Mark fields als pristine, so errored is displayed
            var field;
            for (field in _this.billingForm) {
                if (field[0] == '$') {
                    continue;
                }

                var item = _this.billingForm[field];
                if (!item.$pristine) {
                    continue;
                }

                _this.billingForm[field].$setDirty();
            }

        }


    }, true);


    function parseBillingData(billingResponse)
    {
        _this.billing = _.pick(billingResponse, ['addressLine1',
            'city', 'country', 'email', 'name', 'phone', 'postalCode', 'state', 'taxIds', 'type', 'valid']);

        _this.isBillingInfoValid = _this.billing.valid;
        if (_this.isBillingEditing) {
            _this.isBillingEditing = !_this.isBillingInfoValid;
        }

    }

    $scope.saveCompanyBilling =  function () {
        _this.isLoading = true;
        Billing.saveBillingInfo(_this.billing, function (err, response) {

            if (err) {
                ErrorSvrc.showErrorModal(err);
                _this.isBillingInfoValid = false;
                _this.isBillingEditing = false;
            } else {
                parseBillingData(response);
                toaster.pop('success', null, ($translate.instant('BILLING_INFO_UPDATED')));
            }
            _this.isLoading = false;
        });
    };

    Billing.getBillingInfo(function (err, response) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
        } else {
            parseBillingData(response);
        }
        _this.isLoading = false;
    });
}

const hfSettingsBillingInfo = angular.module('happyForceApp')
    .directive('hfSettingsBillingInfo', function () {
        return {
            scope: {
                isBillingInfoValid: '='
            },
            template: require('./billing_info.html'),
            controller: SettingsBillingInfoCtrl,
            controllerAs: '$bi_ctrl',
            bindToController: true
        };
    });

export default hfSettingsBillingInfo;