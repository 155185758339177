'use strict';

import ScoreDetailsModal from './components/score_details/score_details.controller';

function SettingsScoresCtrl($scope, ScoreDetailsModal, $translate, ErrorSvrc, Languages, Analytics, Scores, toaster) {

    var _this = this;

    $scope.customScores = 0;

    Scores.list({}, function (err, results) {
        if (err)
            ErrorSvrc.showErrorModal(err);
        else {
            $scope.scores =  _.filter(results, function (score) { return score.status != 'RETIRED'; });
            $scope.scores.forEach(function (score) {
                score.enabled = (score.status === 'ACTIVATED');
                $scope.customScores = $scope.customScores + 1;
            });
        }
    });

    $scope.saveScoreStatus = function () {
        $scope.scores.forEach(function (score) {
            if (!score.enabled && score.status === 'ACTIVATED') {
                Scores.deactivate(score.id, function (err, result) {
                    if (err)
                        ErrorSvrc.showErrorModal(err);
                });
            } else if (score.enabled && score.status != 'ACTIVATED') {
                Scores.activate(score.id, function (err, result) {
                    if (err)
                       ErrorSvrc.showErrorModal(err);
                });
            }
        });
        toaster.pop('success', null, ($translate.instant('COMPANY_UPDATED')));
    };

    $scope.scoreViewDetails = function (score)
    {
        ScoreDetailsModal.showModal(score);
    };

    Analytics.trackEvent('settings_scores');
}

// Takes care about login & activation
angular.module('happyForceApp').controller('SettingsScoresCtrl', SettingsScoresCtrl);


const hfSettingsScores = angular.module('happyForceApp')
.directive('hfSettingsScores', function () {
    return {
        template: require('./settings_scores.html'),
        controller: SettingsScoresCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfSettingsScores;