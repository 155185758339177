import React, { JSX } from "react";
import {Alert} from "@mui/material";


type HfBannerProps = {
    children: JSX.Element
    type: 'primary' | 'success' | 'warning' | 'danger' | 'error' | 'neutral' | 'complementary01' | 'complementary02' | 'complementary03' | 'complementary04'
}


/*
*  We need to use this component while we are migrating to React
* since we can't pass to React components Angular components as children
*
 */
export const HfBannerReact = ({children, type}: HfBannerProps) => {


    const getSeverity = () => {
        switch (type) {
            case 'primary':
                return 'info';
            case 'success':
                return 'success';
            case 'warning':
                return 'warning';
            case 'danger':
                return 'error';
            case 'error':
                return 'error';
            case 'neutral':
                return 'info';
            case 'complementary01':
                return 'info';
            case 'complementary02':
                return 'info';
            case 'complementary03':
                return 'info';
            case 'complementary04':
                return 'info';
            default:
                return 'info';
        }
    }


    return (
        <Alert
            sx={{width: '100%', flex: 1, justifyContent: 'center'}}
            severity={getSeverity()}>
            {children}
        </Alert>
    )
};
