'use strict';

var SegmentsTaggingFilterCtrl = function ($rootScope, $compile, $scope, $element, Characteristics, Languages) {
    'ngInject';
    var _this = this;

    _this.segmentFilters = [];

    function removeFilter(id) {
        // First, remove the element from the groups list
        _this.segmentFilters = _.filter(_this.segmentFilters, function (filter) { return filter.id != id; });
        _this.notifyFilterChanges();
    }


    function compileSegmentName(segmentFilter, callback) {

        var segment = Characteristics.getCharacteristicById(segmentFilter.id);
        if (!segment)
            callback(null, '?');
        else {
            // Get the segment name
            var segmentName = segment.description;

            // Now the value names
            var segmentValueNames = [];
            segmentFilter.values.forEach(function (valueId) {
                var valueName = Languages.getTranslationFromUserLanguage(Characteristics.getCharacteristicValueName(segmentFilter.id, valueId));
                segmentValueNames.push(valueName);
            });

            // Finally, compose a segment with value names:
            var segmentNameWithValues = segmentName + ' (' + segmentValueNames.join(', ') + ')';

            callback(null, segmentNameWithValues);
        }


        /*Characteristics.getCharacteristicById(segmentFilter.id, function (err, segment) {
            insertFilterTag(segment.id, group.name);
        });*/
    }

    function renderTags() {

        // Based on the mode, we need to render the tags differently
        if (_this.mode == 'multi') {
            // Multi mode:
            if (_this.segmentFilters.length > 0) {
                // Look for the already inserted elements
                // Let's retrieve all the group names; we expect only one on multi and single modes.
                _this.segmentFilters.forEach(function (segmentFilter) {
                    // Compose the filter name
                    compileSegmentName(segmentFilter, function (err, name) {
                        _this.tagFilterMenu.insertFilterTag(segmentFilter.id, _this.type, name, _this.typeLabel,  _this.nonRemovable, removeFilter);
                    });
                });

            }
        } else if (_this.mode == 'single') {
            // Single mode:
            if (_this.segmentFilters.length > 0) {
                compileSegmentName(_this.segmentFilters[0], function (err, name) {
                    _this.tagFilterMenu.insertFilterTag(_this.segmentFilters[0].id, _this.type, name, _this.typeLabel,
                        _this.nonRemovable, removeFilter);
                });
            }
        } else {
            // Grouped mode:
            if (_this.segmentFilters.length > 0) {
                var streams = [];
                _this.segmentFilters.forEach(function (segmentFilter) {
                    // Only add the names already not added:
                    streams.push(function (next) {
                        compileSegmentName(segmentFilter, function (err, name) {
                            if (err) {
                                next(err, '?');
                            } else {
                                next(null, name);
                            }
                        });
                    });
                });

                async.parallel(streams, function (err, names) {
                    if (err) {
                        console.log(err);
                    }
                    const name = names.join(', ');
                    _this.tagFilterMenu.insertFilterTag('dummy', _this.type, name, _this.typeLabel,  _this.nonRemovable, removeFilter);
                });
            }
        }
    }

    _this.selectSegment = function (segmentId, valueId) {

        // In single mode, we remove all elements not matching this segmentId or the valueId
        if (_this.mode == 'single') {
            _this.segmentFilters = _.filter(_this.segmentFilters,
                function (segment) {
                    var valueMatch = _.find(segment.values, function (value) {
                        return value == valueId;
                    });
                    return segment.id == segmentId && valueMatch;
                });
        }

        // If the segment and value is already added, this is a removal
        var found = _.find(_this.segmentFilters, function (segment) { return segment.id == segmentId; });
        if (found && found.values.indexOf(valueId) >= 0) {
            found.values = _.without(found.values, valueId);

            // If the segment is empty, we remove it
            if (found.values.length == 0) {
                _this.tagFilterMenu.removeTag(segmentId, _this.type);
                _this.segmentFilters = _.filter(_this.segmentFilters, function (segment) { return segment.id != segmentId; });
            }

        } else if (found) {
            found.values.push(valueId);
        } else {
            _this.segmentFilters.push({
                id: segmentId,
                values: [valueId]
            });
        }

        // Render tags
        renderTags();

        // Notify filter changes:
        _this.notifyFilterChanges();
    };

    _this.isSegmentValueSelected = function (segmentId, valueId) {
        var found = _.find(_this.segmentFilters, function (segment) { return segment.id == segmentId; });
        if (found && found.values.indexOf(valueId) >= 0) {
            return true;
        } else {
            return false;
        }
    };

    _this.notifyFilterChanges = function () {
        var filtersNotifyMsg = {
            type: _this.type,
            filters: _this.segmentFilters
        };

        $rootScope.$broadcast('filterChange-' + _this.tagFilterMenu.filterId, filtersNotifyMsg);
    };

    _this.prevent = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };

    // Load all groups
    _this.$onInit = function () {
        Characteristics.getAll(function (err, segments) {
            _this.segments = angular.copy(segments);
        });

        // If there are filters, render them
        if (_this.tagFilterMenu.filters && _this.tagFilterMenu.filters[_this.type]) {
            _this.segmentFilters = _this.tagFilterMenu.filters[_this.type];
            renderTags();
        }
    };
};



const hfSegmentsTaggingFilter = angular.module('happyForceApp').directive('hfSegmentsTaggingFilter', function () {
    return {
        restrict: 'E',
        scope: {
            mode: '<',
            label: '@',
            typeLabel: '@',
            type: '@',
            nonRemovable: '<'
        },
        require: {
            tagFilterMenu: '^hfTagFilterMenu'
        },
        template: require('./segments-tagging-filter.html'),
        controllerAs: '$ctrl',
        bindToController: true,
        transclude: false,
        replace: true,
        controller: SegmentsTaggingFilterCtrl
    };
});

export default hfSegmentsTaggingFilter;
