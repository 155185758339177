import React, {useEffect, useState} from 'react';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';
import { Button, Switch, Typography, TextField, FormControlLabel, Autocomplete, Chip } from '@mui/material';

import QRCode from 'react-qr-code';

const SettingsAutoInvite = ({ companyConfiguration, onSave }) => {
    const $translate = getService('$translate');
    const toaster = getService('toaster');

    const [validDomains, setValidDomains] = useState([]);
    const [domainPatternExclusions, setDomainPatternExclusions] = useState([]);
    const [enableAutoInvite, setEnableAutoInvite] = useState(companyConfiguration.enableAutoInvite);

    const share = () => {
        navigator.clipboard.writeText(`https://join.myhappyforce.com/#${companyConfiguration.autoInviteToken}`)
            .then(() => {
                toaster.pop('success', null, ($translate.instant('SETTINGS_LINK_COPIED')));
            });
    };


    useEffect(() => {
        setValidDomains(companyConfiguration?.validDomains ? companyConfiguration?.validDomains : []);
        setDomainPatternExclusions(companyConfiguration?.domainPatternExclusions ? companyConfiguration?.domainPatternExclusions : []);
        setEnableAutoInvite(companyConfiguration?.enableAutoInvite);
    }, [companyConfiguration]);

    const onDomainPatternExclusionsChange = newValue => {
        companyConfiguration.domainPatternExclusions = newValue;
        setDomainPatternExclusions(companyConfiguration?.domainPatternExclusions);
    };

    const onValidDomainsChange = newValue => {
        companyConfiguration.validDomains = newValue;
        setValidDomains(companyConfiguration?.validDomains);
    };

    function onEnableAutoInviteChange(newValue) {
        setEnableAutoInvite(newValue);
        companyConfiguration.enableAutoInvite = newValue;

    }

    return (
        <div className="settings_auto_invite_section">

            <div className="row row-eq-height">
                <div className="col-xs-10">
                    <h1 className="big-style font-bold">{$translate.instant('AUTO_INVITE')}</h1>
                    <div
                        className="small-style m-b-xs m-t-xs warm-grey">{$translate.instant('AUTO_INVITE_DESCRIPTION')}</div>
                </div>
                <div className="col-xs-2 title-save-button-container">
                    <Button variant="contained" color="primary" onClick={onSave}>
                        {$translate.instant('SAVE')}
                    </Button>

                </div>
            </div>

            <div className="row p-t">
                <div className="col-xs-12">
                    <FormControlLabel
                        control={<Switch checked={companyConfiguration.enableAutoInvite} onChange={(_, checked) => {
                            onEnableAutoInviteChange(checked);
                        }}/>}
                        label={$translate.instant('AUTO_INVITE_JOIN_DOMAIN_ENABLED')}
                    />
                </div>
            </div>

            <div className="row p-t row-eq-height">

                <div className="col-xs-10">

                    <div id="joinLink" className="big-style text-center share-link">
                        https://join.myhappyforce.com/#{companyConfiguration.autoInviteToken}
                    </div>
                </div>
                <div className="col-xs-2 title-save-button-container">
                    <Button onClick={share}>{$translate.instant('SETUP_DOMAIN_COPY_LINK')}</Button>
                </div>
            </div>

            <div className="row p-t">
                <div className="col-xs-12">
                    <h2 className="medium-style">{$translate.instant('AUTO_INVITE_JOIN_DOMAIN_QR')}</h2>
                    <div
                        className="small-style warm-grey m-b-xs m-t-xs">{$translate.instant('AUTO_INVITE_JOIN_DOMAIN_QR_DESCRIPTION')}</div>
                </div>
            </div>

            <div className="row p-t">
                <div className="col-xs-12 text-center">
                    <QRCode value={`https://join.myhappyforce.com/#${companyConfiguration.autoInviteToken}`}
                        size={150}/>
                </div>
            </div>


            <div className="row p-t">
                <div className="col-xs-12">
                    <hr/>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12">
                    <h2 className="medium-style font-bold">{$translate.instant('VALID_DOMAINS')}</h2>
                    <div
                        className="small-style warm-grey m-b-xs m-t-xs">{$translate.instant('VALID_DOMAINS_DESCRIPTION')}</div>

                    <Autocomplete
                        className="p-t"
                        multiple
                        options={validDomains}
                        freeSolo
                        onChange={(event, newValue) => {
                            onValidDomainsChange(newValue);

                        }}
                        value={validDomains}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({index})} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={$translate.instant('DOMAIN_PLACE_HOLDER')}
                                placeholder="Add domains"
                            />
                        )}
                    />
                </div>
            </div>


            <div className="row p-t">
                <div className="col-xs-12">
                    <h2 className="medium-style font-bold">{$translate.instant('DOMAINS_EXCLUSIONS')}</h2>
                    <div
                        className="small-style warm-grey m-b-xs m-t-xs">{$translate.instant('DOMAIN_EXCLUSION_DESCRIPTION')}</div>

                    <Autocomplete
                        className="p-t"
                        multiple
                        options={domainPatternExclusions}
                        freeSolo
                        onChange={(event, newValue) => {
                            onDomainPatternExclusionsChange(newValue);
                        }}
                        value={domainPatternExclusions}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({index})} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={$translate.instant('DOMAIN_EXCLUSION_PLACE_HOLDER')}
                                placeholder="Add domain exclusions"
                            />
                        )}
                    />

                </div>
            </div>




        </div>
    );
};

SettingsAutoInvite.propTypes = {
    companyConfiguration: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired
};

angularize(SettingsAutoInvite, 'hfSettingsAutoInvite', angular.module('happyForceApp'), {
    companyConfiguration: '<',
    onSave: '&'
});

export default SettingsAutoInvite;
