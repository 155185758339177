'use strict';

const SegmentsFilterService = function () {
    var getAnswerId = function (question, answerString) {
        for (var i = 0; i < question.values.length; i++) {
            var answer = question.values[i];
            if (answerString == answer.value) {
                return answer.id;
            }
        }
    };

    var getRangeId = function (question, answerString) {
        for (var i = 0; i < question.ranges.length; i++) {
            var answer = question.values[i];
            if (answerString == answer.name) {
                return answer.id;
            }
        }
    };

    return {

        compileFilterStringOfSegment: function (segment) {
            return segment.characteristicId + ':' + segment.value;
        },

        compileFilterStringFromSegments: function (segments) {

            var filterArray = [];

            _.forEach(segments, function (segment) {
                filterArray.push(segment.characteristicId + ':' + segment.value);
            });

            var filterString = filterArray.join();

            return filterString;

        },


        compileFilterStringFromTagSegments: function (segments) {

            var filterArray = [];

            _.forEach(segments, function (segment) {
                segment.values.forEach(function (value) {
                    filterArray.push(segment.id + ':' + value);
                });

            });

            var filterString = filterArray.join();

            return filterString;

        },

        compileFilterString: function (segments, segmentedQuestions) {
            var filterArray = [];
            for (var i = 0; i < segments.length; i++) {
                var segment = segments[i];
                var description = segment.split(':')[0];
                for (var j = 0; j < segmentedQuestions.length; j++) {
                    var segmentQuestion = segmentedQuestions[j];
                    if (description == segmentQuestion.description) {
                        if (segmentQuestion.questionType == 'BOOLEAN') {
                            var answer = segment.split(':')[1].substring(1);
                            if (answer == segmentQuestion.yesValue) {
                                filterArray.push(segmentQuestion.id + ':' + true);
                            } else if (answer == segmentQuestion.noValue) {
                                filterArray.push(segmentQuestion.id + ':' + false);
                            }
                        } else if (segmentQuestion.questionType == 'LIST') {
                            var alreadyInserted = false;
                            var answerString = segment.split(':')[1].substring(1);
                            var answerId = getAnswerId(segmentQuestion, answerString);
                            if (answerId) {
                                for (var k = 0; k < filterArray.length; k++) {
                                    var filterId = filterArray[k].split(':')[0];
                                    if (filterId == segmentQuestion.id) {
                                        var values = filterArray[k].split(':')[1] + '&' + answerId;
                                        filterArray.splice(k, 1);
                                        filterArray.push(filterId + ':' + values);
                                        alreadyInserted = true;
                                    }
                                }

                                if (!alreadyInserted) {
                                    filterArray.push(segmentQuestion.id + ':' + answerId);
                                }
                            } else {
                                Console.error('[SEGMENT_FILTER] Answer id not found');
                            }
                        } else if (segmentQuestion.questionType == 'DATE') {
                            var alreadyInserted = false;
                            var answerString = segment.split(':')[1].substring(1);
                            var answerId = getRangeId(segmentQuestion, answerString);
                            if (answerId) {
                                for (var k = 0; k < filterArray.length; k++) {
                                    var filterId = filterArray[k].split(':')[0];
                                    if (filterId == segmentQuestion.id) {
                                        var values = filterArray[k].split(':')[1] + '&' + answerId;
                                        filterArray.splice(k, 1);
                                        filterArray.push(filterId + ':' + values);
                                        alreadyInserted = true;
                                    }
                                }

                                if (!alreadyInserted) {
                                    filterArray.push(segmentQuestion.id + ':' + answerId);
                                }
                            } else {
                                Console.error('[SEGMENT_FILTER] Answer id not found');
                            }
                        }
                    }
                }
            }

            var filterString = filterArray.join();

            return filterString;
        }
    };
};

export default SegmentsFilterService;
