'use strict';

function ImageSearchModalController($scope, $modalInstance, defaultImage, currentImage, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.currentImage = currentImage;
    _this.defaultImage = defaultImage;

    _this.onSelectImage = function (image) {
        _this.selectedImage = image;
    };

    _this.close = function () {
        $modalInstance.close(_this.selectedImage);
    };

    _this.exit = function () {
        $modalInstance.dismiss();
    };
};


const hfImageSearchModal = angular.module('happyForceApp')
    .factory('hfImageSearchModal',
        function ($uibModal, $intercom) {
            var methods = {};

            methods.showImageSelector = function (currentImage, defaultImage, callback)  {

                $intercom.update({ hide_default_launcher: true });

                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'right',
                    template: require('./hf-image-search-modal.html'),
                    controller: ImageSearchModalController,
                    controllerAs: '$ctrl',
                    resolve: {
                        currentImage: function () {
                            return currentImage;
                        },
                        defaultImage: function () {
                            return defaultImage;
                        }
                    }
                }).result.then(function (image) {
                    $intercom.update({ hide_default_launcher: false });

                    if (callback) {
                        callback(image);
                        console.log('Modal closed with ok');
                    }
                }, function (params) {
                    $intercom.update({ hide_default_launcher: false });

                    // If there is a callback, reload allays the comment, so we have a fresh version of it
                    if (callback) {
                        console.log('Modal closed with dismiss');
                    }
                });

            };

            return methods;
        }
    );

export default hfImageSearchModal;
