import React from 'react';
import PropTypes from 'prop-types';
import {angularize} from 'reactInAngular';
import OptionsTopic from 'root/app/components/topics/topics_detail/components/optionsTopic';

const HFDotLegend = ({ items }) => {

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <ul className="hf-dot-leyend">
            {items.map((item, index) => (
                <li key={index}>
                    <i className="fa fa-circle" style={{ color: item.color }}></i> {item.label}
                </li>
            ))}
        </ul>
    );
};

HFDotLegend.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired
};


angularize(HFDotLegend, 'hfDotLeyend', angular.module('happyForceApp'), {
    items: '<',
});

export default HFDotLegend;
