'use strict';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import { angularize, getService } from 'reactInAngular';
import Highcharts from 'highcharts';
import {Select, MenuItem, FormControl, InputLabel, Box} from '@mui/material';
import Dates from "../../../../../utilities/date.utilities";
import Style from "../../../../../utilities/style.utils";
import Valuation from "../../../../../utilities/valuation.utils";
import Pagination from "@mui/material/Pagination";
import Charts from "../../../../../utilities/charts.utilities";



const EngagementHiIndividualHeatmapBox = ({ to, level, filters, groups }) => {
    const [displayBy, setDisplayBy] = useState('CALENDAR');
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    // Chart configuration state
    const [individualChartConfig, setIndividualChartConfig] = useState(null);
    const [calendarChartConfig, setCalendarChartConfig] = useState(null);


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    const totalResults = results.length;

    const PersonEngagementStatusContextModal = getService('PersonEngagementStatusContextModal');
    const HIService = getService('HIService');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    const loadIndividualVotes = () => {
        setLoading(true);
        const from = Dates.firstDayOfMonth(to);
        const toDay = Dates.lastDayOfMonth(to);
        let params = { filters, groupId: groups };
        if (level) {
            params.hierarchyId = level.id;
        }

        HIService.stats.individual(from, toDay, params, (err, result) => {
            setLoading(false);
            if (err) {
                if (!ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    setResults([]);
                    setCalendarChartConfig(null);
                    setIndividualChartConfig(null);
                }
            } else {
                setResults(result.members);
            }
        });
    };

    const buildCalendarChart = (members) => {

        // ... create calendar chart
        Charts.initializeDateFormats();

        var series = [];
        var hiCategories = [];
        var conversationCategories = [];


        members.forEach(function (member) {
            hiCategories.push(member.hi);
            conversationCategories.push(false);
            var memberSerie = {
                data: []
            };
            member.votes.forEach(function (vote) {
                memberSerie.data.push([Date.formatServerDateToTimestamp(vote.date), series.length, vote.rating]);
            });
            series.push(memberSerie);
        });

        const numSeries = series.length;


        return {
            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                height: ((35 * numSeries) + 100) + 'px'
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: [{
                type: 'datetime',
                gridLineWidth: 0,
                tickInterval: 24 * 3600 * 1000
            },
                {
                    type: 'datetime',
                    gridLineWidth: 0,
                    opposite: true,
                    linkedTo: 0,
                    tickInterval: 24 * 3600 * 1000
                }],
            yAxis: [
                {  // Primary yAxis
                    type: 'category',
                    categories: hiCategories,
                    title: null,
                    gridLineWidth: 0,
                    dashStyle: 'Dot',
                    labels: {
                        useHTML: true,
                        formatter: function () {
                            var img;
                            if (this.value > 75) {
                                img = '/images/vote_1_btn.png';
                            } else if (this.value > 50) {
                                img = '/images/vote_2_btn.png';
                            } else if (this.value > 25) {
                                img = '/images/vote_3_btn.png';
                            } else {
                                img = '/images/vote_4_btn.png';
                            }

                            var hasConversationClass =  members[this.pos] && members[this.pos].hasConversations ? 'fa-comments' : 'fa-comments-o';

                            return '<span role="button" class="medium-style text-success" style="display: flex;align-items: center;">'
                                + '<img src="' + img + '" class="img-xsm" />'
                                + '<i  class="fa m-l-xs '  + hasConversationClass + '"></i></span>';

                        },
                        events: {
                            click: function () {
                                if (this.pos >= 0) {
                                    PersonEngagementStatusContextModal.showEngagementContextDetails(
                                        Dates.firstDayOfMonth(to),
                                        Dates.lastDayOfMonth(to),
                                        members[this.pos].encryptedId);
                                }
                            }
                        }
                    }
                }
            ],
            colorAxis: {
                dataClasses: [{
                    from: 1,
                    to: 1,
                    color: Style.faceSad
                }, {
                    from: 2,
                    to: 2,
                    color: Style.faceBad
                }, {
                    from: 3,
                    to: 3,
                    color: Style.faceGood
                },
                    {
                        from: 4,
                        to: 4,
                        color: Style.faceHappy
                    }]
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    colsize: 24 * 36e5,
                    rowsize: 0.50,
                    marker: {
                        symbol: 'circle'
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return '<b>' + Highcharts.dateFormat('%e-%b-%Y', new Date(this.point.x)) + '</b>';
                }
            },
            series: series
        };
    };

    const buildIndividualVotesChart = (members) => {
        var serieData = [];
        var hiCategories = [];


        const markerPulsing = {
            radius: 5,
            symbol: 'circle',
            states: {
                hover: {
                    enabled: true
                },
                normal: {
                    enabled: true,
                    lineColor: Style.chartColors[0],
                    lineWidthPlus: 3
                }
            }
        };

        const markerNotPulsing = {
            radius: 5,
            symbol: 'circle',
            states: {
                hover: {
                    enabled: true
                }
            }
        };


        members.forEach(function (member) {
            hiCategories.push(member.hi);
            var memberData = {
                x: member.votes.length,
                y: member.hi,
                color: Valuation.valuationColor(member.valuation),
                encryptedId: member.encryptedId

            };
            if (member.hasConversations) {
                memberData.marker = markerPulsing;
            } else {
                memberData.marker = markerNotPulsing;
            }

            serieData.push(memberData);
        });

        return {
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                plotBorderWidth: 0
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: {
                title: {
                    text: 'Votes'
                },
                gridLineWidth: 0,
                tickInterval: 1
            },
            yAxis: {  // Primary yAxis
                gridLineWidth: 0,
                min: 0,
                max: 100,
                title: {
                    text: 'HI'
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    point: {
                        events: {
                            click: function (event) {
                                if (!event.point.isCluster) {
                                    PersonEngagementStatusContextModal.showEngagementContextDetails(
                                        Dates.firstDayOfMonth(to),
                                        Dates.lastDayOfMonth(to),
                                        event.point.options.encryptedId);
                                }

                            }
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        pointFormat: ''
                    },
                    jitter: {
                        x: 0.25, // This will add some randomless to the chart
                        y: 0.75
                    }
                }
            },
            series:  [{
                type: 'scatter',
                data: serieData,

                turboThreshold: 0,

                cursor: 'pointer',



                tooltip: {
                    followPointer: false,
                    pointFormat: 'Votos: {point.x} HI: {point.y:.1f}'
                }
            }]
        };
    };


    useEffect(() => {
        loadIndividualVotes();
    }, [to, level, filters, groups, displayBy]);

    useEffect(() => {
        if (results && results.length) {
            const paginatedMembers = results.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
            );
            setIndividualChartConfig(buildIndividualVotesChart(results));
            setCalendarChartConfig(buildCalendarChart(paginatedMembers));
        }

    }, [results, currentPage]);

    return (
        <section className="positioning-box">
            <div className="row">
                <div className="col-xs-8">
                    <h2>{$translate.instant('HI_INDIVIDUALS_MAP')}</h2>

                </div>
                <FormControl variant="outlined" className="col-xs-4">
                    <InputLabel id="display-mode-label">{$translate.instant('DISPLAY_MODE')}</InputLabel>
                    <Select
                        labelId="display-mode-label"
                        id="display-mode-select"
                        value={displayBy}
                        onChange={(e) => setDisplayBy(e.target.value)}
                        label="Display Mode"
                    >
                        <MenuItem value="CALENDAR">{$translate.instant('INDIVIDUAL_VOTES_CALENDAR')}</MenuItem>
                        <MenuItem value="SCATTER">{$translate.instant('INDIVIDUAL_VOTES_SCATTER')}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="row p-t p-b">
                <div className="col-xs-12">
                    <div>
                        {displayBy === 'SCATTER' ? $translate.instant('INDIVIDUAL_HEATMAP_BY_SCATTER_HINT') : $translate.instant('INDIVIDUAL_HEATMAP_BY_CALENDAR_HINT')}
                    </div>
                </div>
            </div>
            <div className="row p-h-xs">
                <div className="col-xs-12">
                    {displayBy === 'SCATTER' && (
                        <HighchartsReact highcharts={Highcharts} options={individualChartConfig} />
                    )}

                    {displayBy === 'CALENDAR' && (
                        <HighchartsReact highcharts={Highcharts} options={calendarChartConfig} />
                    )}
                </div>
            </div>
            {displayBy === 'CALENDAR' && (
                <div className="row p-t p-b">
                    <div className="col-xs-12">
                        <Box display="flex" justifyContent="center">
                            <Pagination
                                count={Math.ceil(results.length / itemsPerPage)}
                                page={currentPage}
                                shape="rounded"
                                variant={'outlined'}
                                size="large"
                                onChange={(_, page) => setCurrentPage(page)}
                                // ... other props
                            />
                        </Box>
                    </div>
                </div>
            )}
        </section>
    );
};

EngagementHiIndividualHeatmapBox.propTypes = {
    to: PropTypes.any,
    level: PropTypes.object,
    filters: PropTypes.object,
    groups: PropTypes.any,
};

angularize(EngagementHiIndividualHeatmapBox, 'engagementHiIndividualHeatmapBox', angular.module('happyForceApp'), {
    to: '<',
    level: '<',
    filters: '<',
    groups: '<'
});

export default EngagementHiIndividualHeatmapBox;
