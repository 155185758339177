'use strict';

import Dates from '../../../../utilities/date.utilities';


import template from './engagement_report_type_modal.html';


const EngagementReportModalController = function ($scope, $uibModalInstance, groupingType, groupByOptions, from, to, ErrorSvrc, Settings, Reports) {
    var _this = this;
    _this.groupingType = groupingType;
    _this.groupByOptions = groupByOptions;
    _this.from = from;
    _this.to = to;
    _this.dateFormat = Settings.defaultMonthDateFormat;
    _this.ok = function () {
        _this.exporting = true;

        const from =  Dates.firstDayOfMonth(_this.from);
        const to = Dates.lastDayOfMonth(_this.to);
        Reports.engagement(from, to, _this.groupingType, function (err, result) {
            _this.exporting = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
                Analytics.trackEvent('engagement', 'excel_report', 'false');
            } else {
                $uibModalInstance.close();
            }
        });
    };

    _this.changeFrom = function (from) {
        debugger;
    };

    _this.cancel = function () {
        $uibModalInstance.dismiss();
    };
};




const EngagementReportModal = angular.module('happyForceApp')
    .factory('EngagementReportModal',
        function ($uibModal, $translate) {
            'ngInject';
            var methods = {};
            methods.showModal = function (to, callback) {
                $uibModal.open({
                    backdrop: 'static',
                    resolve: {
                        groupingType: function () {
                            return 'HIERARCHIES';
                        },
                        groupByOptions: function () {
                            return [
                                {id: 'HIERARCHIES', label: $translate.instant('AREAS')},
                                {id: 'GROUPS', label: $translate.instant('GROUPS')},
                                {id: 'CHARACTERISTICS', label: $translate.instant('SEGMENTS')}
                            ];
                        },
                        from: function () {
                            return moment(to).subtract(12, 'month').toDate();
                        },
                        to: function () {
                            return angular.copy(to);
                        }
                    },
                    controller: EngagementReportModalController,
                    controllerAs: '$ctrl',
                    template: template
                }).result.then(function () {
                    callback();
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            }


            return methods;

        }
    );


export default EngagementReportModal;
