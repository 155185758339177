'use strict';

import Dates from "../../../../utilities/date.utilities";
import {getENPSFeedback} from '../../../../shared/services/migrated/enps.service';

function EngagementScoresFeedbackBoxCtrl($scope, Scores, LoadingService, Comments) {
    var _this = this;


    _this.currentPage = 1;
    _this.totalComments = 0;
    _this.itemsperpage = 5;

    _this.loadComments = function () {

        if (_this.to) {
            _this.loading = true;
            LoadingService.start('feedback');

            var params = {};

            params.filters = _this.filters;
            params.groupId = _this.groups;


            if (_this.level) {
                params.hierarchyId = _this.level.id;
            }

            if (_this.to) {
                var to = Dates.lastDayOfMonth(_this.to);
                params.to = to;
            }

            if (_this.from) {
                params.from = _this.from;
            } else if (_this.to) {
                params.from = Dates.firstDayOfMonth(_this.to);
            }



            params.fromComment = (_this.currentPage - 1) * _this.itemsperpage;
            params.toComment = _this.itemsperpage;

            if (_this.scoreId) {
                params.scoreId = _this.scoreId;
                Scores.feedback.get(params, function (err, scoreComments) {
                    if (!err) {
                        _this.totalComments = scoreComments.total;
                        _this.comments = scoreComments.result;
                    }

                    LoadingService.stop('feedback');
                    _this.loading = false;
                });
            } else if (_this.isEnps) {
                getENPSFeedback(params, function (err, enpsComments) {
                    if (!err) {
                        _this.totalComments = enpsComments.total;
                        _this.comments = enpsComments.result;
                    }

                    LoadingService.stop('feedback');
                    _this.loading = false;
                });
            } else {
                params.feedbackTypes = [
                    'SCORE'
                ];

                Comments.get(params, function (feedbackComments) {
                    _this.totalComments = feedbackComments.total;
                    _this.comments = feedbackComments.comments;
                    _this.loading = false;
                });
            }

            _this.comments = [];
        }
    };

    this.$onInit = function () {


        ['from', 'to', 'level', 'filters', 'groups', 'scoreId'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;
                _this.loadComments();
            }, true);
        });

        _this.loadComments();
    };
}

const hfEngagementScoresFeedbackBox = angular.module('happyForceApp').directive('hfEngagementScoresFeedbackBox', function () {
    return {
        scope: {
            from: '<',
            to: '<',
            level: '<',
            filters: '<',
            groups: '<',
            scoreId: '<',
            isEnps: '<'
        },
        restrict: 'E',
        template: require('./feedback_box.html'),
        controller: EngagementScoresFeedbackBoxCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfEngagementScoresFeedbackBox;
