'use strict';

import hfFeedbackAmountSettings from '../components/feedback_amount_settings/feedback_amount_settings.controller';
import hfFeedbackTransparencySettings from '../components/feedback_transparency_settings/feedback_transparency_settings.controller';
import hfFeedbackStatusTypesSettings from '../components/feedback_status_types_setting/feedback_status_types_setting.controller';

function IdeasFeedbackConfigCtrl($scope, $translate, Analytics) {
    'ngInject'

    var _this = this;

    ['enabled', 'employeeSignatureMode', 'managerSignatureMode', 'privacyMode', 'sharedAmmountComments', 'maxCommentsPerDay'].forEach(function (key) {
        // Listeners
        $scope.$watch(
            angular.bind(_this, function () {
                return $scope.feedbackConfiguration.feedbackConfiguration['SUGGESTION'][key];
            }),
            function (n, o) {
                if (n === o) {
                    return;
                }
                $scope.saveFeedbackSettings();
            }
        );
    });


    // TODO: This should come from the service
    $scope.ideasPhaseTypes = [
        {
            id: 'ONGOING',
            label: $translate.instant('FEEDBACK_STATUS_PHASE_ONGOING'),
            description: $translate.instant('FEEDBACK_STATUS_PHASE_ONGOING_DESCRIPTION'),
            status: 'warning'
        },
        {
            id: 'ACCEPTED',
            label: $translate.instant('FEEDBACK_STATUS_PHASE_ACCEPTED'),
            description: $translate.instant('FEEDBACK_STATUS_PHASE_DESCRIPTION'),
            status: 'success'
        },
        {
            id: 'DISCARDED',
            label: $translate.instant('FEEDBACK_STATUS_PHASE_DISCARTED'),
            description: $translate.instant('FEEDBACK_STATUS_PHASE_DISCARTED_DESCRIPTION'),
            status: 'error'
        }

    ];


    Analytics.trackEvent('settings_ideas_feedback_config');
}

const hfIdeasFeedbackConfig = angular.module('happyForceApp')
    .directive('hfIdeasFeedbackConfig', function () {
        return {
            template: require('./ideas_feedback_config.html'),
            controller: IdeasFeedbackConfigCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfIdeasFeedbackConfig;
