
import * as React from 'react';
import {angularize} from 'reactInAngular';
import angular from 'angular';



interface Props {
    attachments: any; // <-- TODO: Define somewhere the type of this object
}


const HfCommentImage = (({attachments}:Props) => {





    if (!attachments || !attachments.length) {
        return null;
    }

    // Current implementation only supports one image per comment
    const imageURL = attachments[0]?.fileURL;


    return (
        <div className={'m-t-xs m-b-xs'}>
            <img style={{width: '50%', maxWidth: '150px'}}
                src={imageURL}/>
        </div>
    );

});



/* This is our trick for expose these components on angular */
angularize(HfCommentImage, 'hfCommentImage', angular.module('happyForceApp'), {
    attachments: '<'

});

export default HfCommentImage;
