
import angular from 'angular';
import template from './feedback_report_type_modal.html';


const FeedbackReportTypeModal = angular.module('happyForceApp')
    .factory('FeedbackReportTypeModal',
        function ($uibModal, Reports, ErrorSvrc, toaster, $translate, Analytics) {
            'ngInject'

            var methods = {};


            methods.showCommentsReportModal = function (params, callback) {
                // Before continue, let the user select the report type;
                $uibModal.open({
                    backdrop: 'static',
                    resolve: {
                        type: function () {
                            return 'LIST';
                        }
                    },
                    controller: function ($scope, $uibModalInstance, type) {
                        $scope.type = type;
                        $scope.ok = function () {
                            $uibModalInstance.close($scope.type);
                        };

                        $scope.cancel = function () {
                            $uibModalInstance.dismiss();
                        };
                    },
                    template: template
                }).result.then(function (type) {

                    const finalParams = angular.copy(params);
                    finalParams.reportType = type;
                    Reports.comments(finalParams, function () {
                        toaster.pop('success', null, ($translate.instant('SURVEY_EXPORT_SENT')));
                        callback();
                        Analytics.trackEvent('comments', 'excel_report', 'success');
                    }, function errorCallback(response) {
                        ErrorSvrc.showErrorModal(response);
                        Analytics.trackEvent('comments', 'excel_report', 'false');
                    });
                }, function (closeAction) {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };


            return methods;

        }
    );

export default FeedbackReportTypeModal;
