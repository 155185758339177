'use strict';

import {getHierarchies, getHierarchyById, getHierarchyPath} from "../../../services/migrated/hierarchy.service";

function AreaSingleSelectorCtrl($scope) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {

        _this.selectArea = function (hierarchyId) {
            if (hierarchyId) {
                getHierarchyById(hierarchyId, function (err, hierarchy) {
                    $scope.selectedArea = hierarchy;
                    if ($scope.selectedArea) {
                        $scope.selectionChilds = $scope.selectedArea.children;
                        getHierarchyPath(hierarchyId, function (err, path) {
                            $scope.selectionPath = path;
                        });
                    }
                });
            } else {
                getHierarchies(function (err, hierarchies) {
                    if (!err) {
                        $scope.selectedArea = null;
                        $scope.selectionChilds = hierarchies;
                        $scope.selectionPath = null;
                    }
                });
            }
        };

        _this.ok = function () {
            if ($scope.selectedArea) {
                _this.area = $scope.selectedArea.id;
                $scope.currentArea = $scope.selectedArea;
                _this.popoverIsOpen = false;
                if (_this.onSelect) {
                    setTimeout(function () {
                        _this.onSelect({ id: $scope.selectedArea.id });
                    }, 500);
                }
            }
        };

        _this.clear = function () {
            $scope.currentArea = null;
            _this.popoverIsOpen = false;
            _this.area = null;

            if (_this.onSelect) {
                setTimeout(function () {
                    _this.onSelect(null);
                }, 500);
            }
        };

        _this.cancel = function () {
            _this.popoverIsOpen = false;
        };

        $scope.$watch(function () {
                return _this.area;
            },

            function (n, o) {
                if (_this.area) {
                    getHierarchyById(_this.area, function (err, hierarchy) {
                        $scope.currentArea = hierarchy;
                        _this.selectArea(_this.area);
                    });
                } else {
                    _this.selectArea();
                }
            });
    };

}


const hfAreaSingleSelector = angular.module('happyForceApp')
    .directive('hfAreaSingleSelector', function () {
        return {
            scope: {
                area: '=',
                onSelect: '&',
                allowSelectNonLeaf: '=',
                selectAreaLabel: '@',
                disabled: '='
            },
            template: require('./area_single_selector.html'),
            controller: AreaSingleSelectorCtrl,
            controllerAs: '$ctrl',
            transclude: true,
            bindToController: true
        };
    });


export default hfAreaSingleSelector;
