'use strict';

import ParticipationReportExportModal
    from "./components/participation-report-type-modal/participation_report_type_modal.component";
import Dates from "../../../utilities/date.utilities";

import hfAnalyticsParticipation from "./analytics_participation.controller";
import participationHeatmap from "./components/participation-heatmap/participation-heatmap.controller";
import participationNumber from "./components/participation-number/participation-number.controller";
import participationTable from "./components/participation-table/participation-table.controller";
import participationVotesDistribution
    from "./components/participation-vote-distribution/participation-vote-distribution.controller";
import participationEvolutionChart
    from "./components/participation-evolution-chart/participation-evolution-chart.controller";
import template from './analytics_participation.html';
import {getHierarchyName} from "../../../shared/services/migrated/hierarchy.service";
import NoSegmentsEmptyState from "../../../shared/components/_migrated/no_segments_empty_state/no_segments_empty_state";
import CompanyName from "../../../shared/components/_migrated/company-name/company-name";




function AnalyticsParticipationCtrl($scope, ErrorSvrc, Filters, Analytics, Characteristics, ParticipationService, ParticipationReportExportModal, $stateParams, CompanySvrc, $translate, Reports, $intercom, Settings) {
    'ngInject'
    var _this = this;

    _this.maxDate = new Date();


    function loadData() {

        _this.loading = true;
        var params = {
            grouping: _this.grouping
        };

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        params.filters = _this.filters;
        params.groupId = _this.groups;

        if (_this.to <= Dates.firstDayOfMonth(_this.from)) {
            _this.to =  Dates.lastDayOfMonth(Dates.addDoDate(_this.from, 1, 'months'));
        }


        var from =  Dates.firstDayOfMonth(_this.from);
        var to = Dates.lastDayOfMonth(_this.to);



        ParticipationService.get(from, to, params, function (err, stats) {
            _this.loading = false;
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    _this.hasEnoughActiveEmployees = false;
                } else {
                    ErrorSvrc.showErrorModal(err);
                }
            } else {
                _this.stats = stats;
                _this.results = stats.results;
                _this.hasEnoughActiveEmployees = true;
                _this.loading = false;
                _this.resultDates = {
                    from: stats.from,
                    to: stats.to
                };
            }
        });

    }


    _this.exportToExcel = function () {
        ParticipationReportExportModal.showExportModal(_this.from, _this.to, _this.filters, _this.groups);
    };

    this.$onInit = function () {

        _this.loading = false;

        _this.dateFormat = Settings.defaultMonthDateFormat;

        _this.hasEnoughActiveEmployees = true;

        _this.exportingExcelReport = false;

        // Get the last 3 months by default

        _this.to = $stateParams.to && moment(moment($stateParams.to).format('YYYY-MM-DD HH:mm:ss')).toDate() || Filters.endDate();

        var from = new Date(Filters.endDate().getTime());

        from.setMonth(from.getMonth() - 2);
        from = new Date(from.getFullYear(), from.getMonth());

        _this.from = $stateParams.from && moment(moment($stateParams.from).format('YYYY-MM-DD HH:mm:ss')).toDate() || from;


        if ($stateParams.filters) {
            _this.filters = $stateParams.filters;
        } else {
            _this.filters = Filters.segments() || null;
        }

        if ($stateParams.groupId) {
            _this.groups = [$stateParams.groupId];
        } else {
            _this.groups = Filters.groups() || [];
        }


        _this.title = {};
        _this.grouping = 'MONTH';
        _this.dateGroupingTypes = ParticipationService.dateGroupingTypes;

        _this.summaryView = 1;

        if ($stateParams.hierarchyId && $stateParams.hierarchyId != 'top') {
            _this.level = {
                id: $stateParams.hierarchyId
            };

            getHierarchyName($stateParams.hierarchyId, function (err, name) {
                _this.level.name = name;
            });

        }



        ['from', 'to', 'level', 'groups', 'grouping', 'filters'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                // Now compute the stats
                loadData();
            }, true);
        });

        loadData();

        Analytics.trackEvent('analytics_paticipation');

        $intercom.trackEvent('dashboard_visited_participation');
    };
}

const AnalyticsParticipationView = function ($stateProvider) {
    'ngInject';

    $stateProvider.state('reports.participation', {
        url: '/participation/:hierarchyId',
        params: {
            hierarchyId: { squash: true, value: null }
        },
        controllerAs: '$ctrl',
        controller: AnalyticsParticipationCtrl,
        template: template,
        data: { pageTitle: 'SIDEMENU_ANALYTICS_PARTICIPATION', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
    });
};

export default AnalyticsParticipationView;

