import {Box, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, Typography} from '@mui/material';
import HfAreaTagSelector from '../../../../../../../shared/new-components/hf-area-tag-selector';
import React, {useEffect} from 'react';
import {getService} from 'reactInAngular';
import {BulkActionType, Person} from '../../../types';


interface UserBasicDetailsProps {
    person: Person;
    onRowChange: (updatedPerson: Person) => void;
    onValidationChange: (isValid: boolean) => void;
    isInvite?: boolean;
}


const UserBasicDetails = ({person, onRowChange, onValidationChange, isInvite=false}: UserBasicDetailsProps) => {

    const $translate = getService('$translate');
    const Languages = getService('Languages');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [isValidMail, setIsValidMail] = React.useState<boolean>(true);

    const availableLanguages = [{id: 'default', label: $translate.instant('USER_INVITE_LANGUAGE_DEFAULT')}, ... Languages.appLanguages];



    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>  {

        const isValidMail = e.target.value.length > 0 && emailRegex.test(e.target.value);
        setIsValidMail(isValidMail);
        onValidationChange(isValidMail);
        onRowChange({...person, email: e.target.value});

    };

    return (


        <>
            <FormControl variant="standard" fullWidth sx={theme => ({marginBottom: theme.spacing(2)})}>
                <InputLabel
                    htmlFor="userName">{$translate.instant('EMPLOYEE_NAME')}</InputLabel>
                <Input
                    fullWidth
                    id="userName"
                    value={person.name}
                    placeholder={$translate.instant('EMPLOYEE_NAME_PLACEHOLDER')}
                    onChange={(e) => {
                        onRowChange({...person, name: e.target.value});
                    }}
                    aria-describedby="userName-text"/>
                <FormHelperText id="userName-text">
                    {$translate.instant('EMPLOYEE_NAME_DESCRIPTION')}
                </FormHelperText>
            </FormControl>

            <FormControl variant="standard"  error={!isValidMail} fullWidth sx={theme => ({marginBottom: theme.spacing(2)})}>
                <InputLabel
                    htmlFor="userEmail">{$translate.instant('EMPLOYEE_EMAIL')}</InputLabel>
                <Input
                    fullWidth
                    id="userEmail"

                    value={person.email}
                    placeholder={$translate.instant('EMPLOYEE_EMAIL_PLACEHOLDER')}
                    type="email"
                    onChange={(e) => {
                        handleEmailChange(e);
                    }}
                    aria-describedby="userEmail-text"/>
                <FormHelperText id="userEmail-text">
                    {isValidMail ? $translate.instant('EMPLOYEE_EMAIL_DESCRIPTION'): $translate.instant('EMPLOYEE_EMAIL_INVALID')}
                </FormHelperText>
            </FormControl>
            <FormControl variant="standard" fullWidth sx={theme => ({marginBottom: theme.spacing(2)})}>
                <InputLabel
                    htmlFor="userRole">{$translate.instant('USER_ROLE')}</InputLabel>
                <Select
                    fullWidth
                    id="userRole"
                    value={person.role}
                    placeholder={$translate.instant('USER_ROLE_DESCRIPTION')}
                    onChange={(e) => {
                        onRowChange({...person, role: e.target.value});
                    }}
                    aria-describedby="userEmail-text">
                    <MenuItem value={'EMPLOYEE'}>
                        {$translate.instant('EMPLOYEE')}

                    </MenuItem>
                    <MenuItem value={'MANAGER'}>
                        {$translate.instant('MANAGER')}
                    </MenuItem>
                    <MenuItem value={'ADMIN'}>
                        {$translate.instant('ADMIN')}
                    </MenuItem>
                </Select>
                <FormHelperText id="userRole-text">
                    {person.role === 'ADMIN' && $translate.instant('USER_ADMIN_EXPLAIN_DESCRIPTION')}
                    {person.role === 'MANAGER' && $translate.instant('USER_MANAGER_EXPLAIN_DESCRIPTION')}
                    {person.role === 'EMPLOYEE' && $translate.instant('USER_EMPLOYEE_EXPLAIN_DESCRIPTION')}

                </FormHelperText>

                {person.role === 'MANAGER' && (
                    <Box sx={theme => ({display: 'flex', flexDirection: 'column', marginTop: theme.spacing(2)})}>
                        <Typography variant={'caption'}
                            fontWeight={'bold'}>{$translate.instant('MANAGER_AREAS')}</Typography>
                        <Typography
                            variant={'caption'}

                            sx={theme => ({
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: theme.spacing(1),
                                color: theme.palette.text.secondary
                            })}>
                            {$translate.instant('USER_MANAGER_SELECT_AREAS_DESCRIPTION')}
                        </Typography>

                        <HfAreaTagSelector
                            hierarchyId={person.managedHierarchyIds}
                            addLabel={$translate.instant('ADD_AREA')}
                            onRemove={(hierarchyId) => {
                                onRowChange({
                                    ...person,
                                    managedHierarchyIds: person.managedHierarchyIds.filter(id => id !== hierarchyId)
                                });

                            }}
                            updateHierarchyId={(newManagedHierarchies) => {
                                onRowChange({
                                    ...person,
                                    managedHierarchyIds: Array.isArray(newManagedHierarchies) ? newManagedHierarchies : [newManagedHierarchies]
                                });
                            }}></HfAreaTagSelector>
                        {!person.managedHierarchyIds || person.managedHierarchyIds.length === 0 &&
                        <Typography sx={theme => ({
                            marginTop: theme.spacing(2),
                            color: theme.palette.warning.main
                        })} variant={'caption'}

                        dangerouslySetInnerHTML={{__html: $translate.instant('MANAGER_NEEDS_HIERARCHIES')}}/>}

                    </Box>)}
            </FormControl>


            {isInvite && (

                <FormControl variant="standard" fullWidth sx={theme => ({marginBottom: theme.spacing(2)})}>
                    <InputLabel
                        htmlFor="userEmail">{$translate.instant('INVITATION_LANGUAGE')}</InputLabel>
                    <Select
                        fullWidth
                        id="userInvitationLanguage"
                        value={person.language ? person.language : 'default'}
                        placeholder={$translate.instant('USER_INVITATION_LANGUAGE')}
                        onChange={(e) => {

                            onRowChange({...person, language: (e.target.value === 'default'  ? null : e.target.value)});


                        }}
                        aria-describedby="userInvitationLanguage-text">
                        {availableLanguages.map((language, index) => (
                            <MenuItem key={index} value={language.id}>
                                {language.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText id="userRole-text">
                        {$translate.instant('USER_INVITATION_LANGUAGE_DESCRIPTION')}

                    </FormHelperText>

                </FormControl>

            )}
        </>
    );

};

export default UserBasicDetails;
