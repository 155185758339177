'use strict';

import template from './country_selector.html';

function CountrySelectorCtrl($scope, Countries, $localStorage, AuthSrvc) {
    'ngInject';
    var _this = this;

    this.$onInit = function () {

        var currentLanguage = AuthSrvc.getLanguage();
        if (!currentLanguage) {
            currentLanguage = 'en';
        }

        Countries.fetch().then(function success(allCountries) {
            _this.countries = allCountries.map(function (country) {
                var name = country.translations[currentLanguage.toLowerCase()];
                country.name = name;
                return country;
            });
        });

    };
}

const hfCountrySelector = angular.module('happyForceApp')
    .directive('hfCountrySelector', function () {
        return {
            scope: {
                selected: '=',
                name: '@',
                required: '@',
                classname: '@'
            },
            template: template,
            controller: CountrySelectorCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });


export default hfCountrySelector;
