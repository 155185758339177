import React, { useState, useEffect } from 'react';
import {angularize, getService} from 'reactInAngular';

import Style from '../../../../../utilities/style.utils';
import Dates from '../../../../../utilities/date.utilities';
import Valuation from '../../../../../utilities/valuation.utils';
import {APISettings} from '../../../../../shared/services/migrated/axiosWrapper';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import ScaleChart from '../../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import HFTag from 'root/app/shared/new-components/hf-tag/hf-tag';
// Assuming it has been migrated to React. HappyLoading doesn't seem to affect layout and only shows a message when loading.


const EnpsDetailOverview = ({ data, noData, hasEnoughActiveEmployees, mode }) => {
    const [shouldDisplayData, setShouldDisplayData] = useState(false);
    const [valutationStatus, setValutationStatus] = useState('');
    const [measureDate, setMeasureDate] = useState('');
    const [color, setColor] = useState('');
    const [variationText, setVariationText] = useState('');

    const $translate = getService('$translate');

    const enpsSlices = [{
        color: Style.bad,
        range: [-100, -10],
        label: $translate.instant('ENPS_SLICE_FIRST')
    }, {
        color: Style.neutral,
        range: [-10, 20],
        label: $translate.instant('ENPS_SLICE_SECOND')
    }, {
        color: Style.warning,
        range: [20, 40],
        label: $translate.instant('ENPS_SLICE_THIRD')
    }, {
        color: Style.excellent,
        range: [40, 100],
        label: $translate.instant('ENPS_SLICE_FOURTH')
    }];

    const averageSlices = [{
        color: Style.bad,
        range: [0, 6],
        label: $translate.instant('ENPS_SLICE_FIRST')
    }, {
        color: Style.warning,
        range: [7, 8],
        label: $translate.instant('ENPS_SLICE_THIRD')
    }, {
        color: Style.excellent,
        range: [9, 10],
        label: $translate.instant('ENPS_SLICE_FOURTH')
    }];

    // ... similar setup for averageSlices

    const updateVariationText = () => {
        var variation = 0;
        if (data) {
            if (mode === 'ENPS_MODE') {
                variation = data.variation;
            } else {
                variation = data.averageVariation;
            }
            var key = 'SCORE_VARIATION_';
            if (variation > 0) {
                key += 'UP';
            } else if (variation < 0) {
                key += 'DOWN';
            } else {
                key += 'EQUAL';
            }
            setVariationText($translate.instant(key, { variation: Math.abs(variation) }));
        }

    };


    useEffect(() => {
        if (data) {

            setMeasureDate(Dates.fromAPIFormat(data.measureDate, APISettings.apiDateFormat));
            setShouldDisplayData(hasEnoughActiveEmployees && data.quality !== 'NO_DATA');
            if (mode === 'ENPS_MODE') {
                // If we are in eNPSMode, the tag type should be inverted
                setColor(Valuation.enpsValuationColor(data.value, data.averageValue, data.valuation, false));
                setValutationStatus(Valuation.enpsTagType(data.value, data.averageValue, data.valuation, true));
            } else {
                setColor(Valuation.enpsValuationColor(data.value, data.averageValue, data.valuation, true));
                setValutationStatus(Valuation.enpsTagType(data.value, data.averageValue, data.valuation, false));
            }
            updateVariationText();

        } else {
            setShouldDisplayData(false);
        }
    }, [data, mode, noData]); // Re-run processData when any of these change.

    return (
        <section className="engagement-enps-detail-overview white-bg">

            {shouldDisplayData && (
                <>
                    {(mode === 'ENPS_MODE' && <HFTag
                        status={valutationStatus}
                        text={$translate.instant('ENPS_AVERAGE_RESULT', { result: data.averageValue })}
                    />)}

                    {(mode !== 'ENPS_MODE' && <HFTag
                        status={valutationStatus}
                        text={$translate.instant('ENPS_STANDARD_RESULT', { result: data.value })}
                    />)}


                    <div className="big-number-container" style={{'color': color}}>
                        <span className="big-number">{mode === 'ENPS_MODE' ? data.value : data.averageValue }</span>
                        <span className="big-number-sub">pts</span>
                    </div>

                    <ScaleChart slices={mode === 'ENPS_MODE' ? enpsSlices : averageSlices}
                        value={mode === 'ENPS_MODE' ? data.value : data.averageValue}>

                    </ScaleChart>
                    <p className="small-style m-t-xs" dangerouslySetInnerHTML={{__html: variationText}} ></p>
                </>
            )}


            {noData && !hasEnoughActiveEmployees && (
                <EmptyState
                    image="images/anonymous.png"
                    message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                    submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                />
            )}

            {/* You will need to replicate the rest of the HTML into JSX here, potentially creating new React components for things like 'hf-banner' */}

        </section>
    );

};

angularize(EnpsDetailOverview, 'hfEnpsDetailOverview', angular.module('happyForceApp'), {
    data: '=',
    noData: '<',
    hasEnoughActiveEmployees: '<',
    mode: '<'
});

export default EnpsDetailOverview;

