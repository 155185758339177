

import React, { useState, useEffect } from 'react';
import UserUtils from '../../../utilities/user.utilities';
import PropTypes from 'prop-types';
import angular from 'angular';
import {angularize} from 'reactInAngular';




const HFAvatar = ({ name, image, badgeImage, showName, size, showPopover }) => {
    const [shortName, setShortName] = useState('');

    useEffect(() => {
        const newSize = size || 'small';
        if (name) {
            setShortName(UserUtils.getUserNameAcronym(name, 'HF', 2));
        }
    }, [name, size]);

    const renderAvatarLetter = () => (
        !image || showName ? (
            <span className={`avatar-letter ${size}`}>
                <strong className="mini-style font-bold">{shortName.toUpperCase()}</strong>
            </span>
        ) : null
    );

    const renderAvatarImage = () => (
        image && !showName ? (
            <img className={`img-circle avatar-image ${size}`} alt="image" src={image} />
        ) : null
    );

    const renderBadgeImage = () => (
        badgeImage ? (
            <img className={`img-circle avatar-badge ${size}`} alt="image" src={badgeImage} />
        ) : null
    );

    return (
        <span className="hf-avatar"
            data-toggle="popover"
            title={name}
            data-trigger="mouseenter"
            data-placement="top"
            data-content={showPopover ? name : ''}
        >
            {renderAvatarLetter()}
            {renderAvatarImage()}
            {renderBadgeImage()}
        </span>
    );
};

HFAvatar.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
    badgeImage: PropTypes.string,
    showName: PropTypes.bool,
    size: PropTypes.string,
    showPopover: PropTypes.bool
};




angularize(HFAvatar, 'hfAvatar', angular.module('happyForceApp'), {
    name: '<',
    image: '<',
    badgeImage: '<',
    showName: '<',
    size: '<',
    showPopover: '<'
});

export default HFAvatar;
