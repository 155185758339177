'use strict';

import Style from "../../../../../utilities/style.utils";
import Dates from "../../../../../utilities/date.utilities";

function FeedbackIdeasStatsDetailCtrl($modalInstance, FeedbackIdeasService, FeedbackStatusService, Languages, $translate, $scope, from, to, level, filters, groups) {
    'ngInject';

    var _this = this;
    _this.from = from;
    _this.to = to;
    if (_this.level && _this.level.length) {
        _this.hierarchyId = _this.level.join(',');
    } else {
        _this.hierarchyId = level;
    }
    _this.filters = filters;
    _this.groups = groups;

    _this.phaseColors = [];

    // Build an array of phase colors, so we can access faster to it
    FeedbackIdeasService.stats.phaseconfig.forEach(function (phaseConfig) {
        _this.phaseColors[phaseConfig.id] = {
            color: phaseConfig.color,
            linkedToInitiativeColor: phaseConfig.linkedToInitiativeColor
        };
    });

    _this.renderChart = function (entries) {

        var categories = [];
        var ideaSeries = [];
        var ideaLinkedtoInitiativeSeries = [];

        entries.forEach(function (entry) {


            categories.push(entry.name);
            ideaSeries.push({
                y: entry.numIdeas,
                color: _this.phaseColors[entry.phase].color
            });

            ideaLinkedtoInitiativeSeries.push({
                y: entry.numIdeas,
                color: _this.phaseColors[entry.phase].linkedToInitiativeColor
            });

        });

        var series = [{
            name: $translate.instant('FEEDBACK_IDEAS_INNOVATION_IDEAS_WITHOUT_INITIATIVE'),
            data: ideaSeries
        }, {
            name: $translate.instant('FEEDBACK_IDEAS_INNOVATION_IDEAS_WITH_INITIATIVE'),
            data: ideaLinkedtoInitiativeSeries
        }];


        _this.chartConfig = {
            options: {
                chart: {

                    type: 'bar',
                    height: 200,
                    style: {
                        fontFamily: Style.charts.fontFamily,
                        fontSize: Style.charts.fontSmall.size
                    }
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    min: 0,
                    labels: {
                        enabled: false,
                        overflow: 'justify'
                    },
                    title: {
                        text: null
                    }
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    bar: {
                        pointWidth: 12,
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontWeight: Style.charts.fontSmall.weight,
                                fontSize: Style.charts.fontSmall.size
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                }
            },
            series: series
        };

    };

    _this.loadStats = function () {
        _this.loading = true;

        delete _this.ranking;

        var params = {};

        if (_this.from)
            params.from = Dates.firstDayOfMonth(_this.from);

        if (_this.to)
            params.to = Dates.lastDayOfMonth(_this.to);

        params.hierarchyId = _this.hierarchyId;
        params.groupId = _this.groups;

        params.filters = _this.filters;
        params.type = 'HIERARCHY';

        FeedbackIdeasService.stats.ranking(params, function (err, result) {
            if (result.ranking) {
                _this.ranking = [];
                result.ranking.forEach(function (item) {

                    _this.ranking.push({
                        type: result.type,
                        id: item.id,
                        totalIdeas: item.totalIdeas,
                        totalAcceptedIdeas: item.totalAcceptedIdeas
                    });
                });
                _this.loading = false;
            }
        });

        FeedbackIdeasService.stats.get(params, function (err, result) {
            _this.byPersonRatio = result.byPersonRatio;
            _this.totalIdeasNoInitiative = result.totalIdeas - result.ideasInInitiative;
            _this.totalIdeasInInitiative = result.ideasInInitiative;

            FeedbackStatusService.list('SUGGESTION', null, function (err, statusTypes) {
                var chartEntries = [];
                Object.keys(result.ideasByStatus).forEach(function (statusId) {
                    const statusData = result.ideasByStatus[statusId];

                    var statusTypeDataEntry = {
                        id: statusId,
                        numIdeas: statusData.numIdeas,
                        numIdeasInitiatives: statusData.numIdeasInitiatives
                    };

                    var statusIndex = statusTypes.findIndex(function (_statusType) {
                        return _statusType.id == statusId;
                    });


                    if (statusIndex >= 0) {
                        statusTypeDataEntry.name = Languages.getTranslationFromUserLanguage(statusTypes[statusIndex].label);
                        statusTypeDataEntry.phase = statusTypes[statusIndex].phase;
                    } else {
                        statusTypeDataEntry.name = '?';
                        statusTypeDataEntry.phase = 'NO_PHASE';

                    }
                    chartEntries.push(statusTypeDataEntry);

                });
                _this.loading = false;
                _this.renderChart(chartEntries);
            });
        });


    };

    _this.close = function () {
        $modalInstance.dismiss();
    };

    _this.loadStats();
}

const FeedbackIdeasStatsDetailModal = angular.module('happyForceApp')
    .factory('FeedbackIdeasStatsDetailModal',
        function ($uibModal, $intercom) {
            'ngInject';
            var methods = {};
            methods.showStatDetails = function (from, to, level, filters, groups) {
                $intercom.update({ hide_default_launcher: true });
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    backdropClass: 'right',
                    template: require('./feedback-ideas-stats-detail.html') ,
                    controller: FeedbackIdeasStatsDetailCtrl,
                    controllerAs: '$ctrl',
                    size: 'right survey',
                    resolve: {
                        from: function () { return from;},
                        to: function () { return to;},
                        level: function () { return level;},
                        filters: function () { return filters;},
                        groups: function () { return groups;}
                    }
                }).result.then(function () {
                    $intercom.update({ hide_default_launcher: false });
                });
            };

            return methods;

        }
    );

export default FeedbackIdeasStatsDetailModal;
