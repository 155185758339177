import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';

const CommentConversationAction = ({ comment }) => {
    const CommentConversationModal = getService('CommentConversationModal');
    const People = getService('People');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const [conversationOwner, setConversationOwner] = useState('');

    useEffect(() => {
        if (comment.conversationOwnerId) {
            People.getProfile(comment.conversationOwnerId, (err, user) => {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    setConversationOwner(user.name);
                }
            });
        }
    }, [comment.conversationOwnerId]);

    const showConversation = (event) => {
        event.stopPropagation();
        CommentConversationModal.showModal(comment, () => {
            document.getElementById('intercom-container').style.display = 'block';
        });
    };

    return (
        <span>
            {comment.allowStartConversation ? (
                <a onClick={showConversation} color={comment.conversationId ? 'success' : 'default'} style={{display:'flex'}}>
                    <i className="fa fa-comments-o"></i>
                    {comment.conversationId && <span className={'m-l-xs'}>(1)</span>}
                </a>
            ) : (
                <Tooltip title={$translate.instant('CONVERSATION_STARTED_BY_OTHER_DESCRIPTION', { conversationOwner: conversationOwner })} placement="left">
                    <span className={'warm-grey'} style={{display:'flex'}}>
                        <i className="fa fa-comments-o"></i>
                        {comment.conversationId && <span className={'m-l-xs'}>(1)</span>}
                    </span>
                </Tooltip>
            )}
        </span>
    );
};

CommentConversationAction.propTypes = {
    comment: PropTypes.object.isRequired
};

angularize(CommentConversationAction, 'hfCommentConversationAction', angular.module('happyForceApp'), {
    comment: '='
});

export default CommentConversationAction;
