import {addCultureValue, updateCultureValue} from '../../../../../../../shared/services/migrated/culture_values.service';

function CompanyCultureValuesConfigOperationsCtrl(Languages, $scope, HIService, AuthSrvc, ErrorSvrc, $modalInstance, cultureValue, language, isEdit, Analytics, $uibModal) {
    $scope.cultureValue = cultureValue;
    $scope.isEdit = isEdit;
    $scope.executing = false;
    $scope.errored = false;
    $scope.errorMsg = '';

    $scope.iconRange = _.range(1, 71);
    $scope.language = language;


    $scope.name = Languages.initializeOrGetLangValue(cultureValue.name, language);
    $scope.description = Languages.initializeOrGetLangValue(cultureValue.description, language);


    $scope.ok = function () {
        Languages.setLangValue(cultureValue.name, $scope.name, language);
        Languages.setLangValue(cultureValue.description, $scope.description, language);
        $scope.executing = true;

        if (!$scope.isEdit) {
            addCultureValue(cultureValue, function (err, result) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    $modalInstance.close(result);
                }
                $scope.executing = false;
            });
        } else {
            updateCultureValue(cultureValue, function (err, result) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                }
                else {
                    $modalInstance.close(result);
                }
                $scope.executing = false;

            });
        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.changeIcon = function () {
        $uibModal.open({
            backdrop: 'static',
            template: require('./icon_selector.html'),
            controller: CompanyCultureValuesConfigOperationsCtrl,
            resolve: {
                cultureValue: function () {
                    return $scope.cultureValue;
                },
                language: function () {
                    return $scope.selectedLanguage;
                },
                isEdit: $scope.isEdit
            },
            size: ' hi_question'
        }).result.then(function (iconId) {
            $scope.cultureValue.iconId = iconId;

        }, function (closeAction) {
            console.info('Modal dismissed at: ' + new Date());
        });
    };

    $scope.cancelIconSelect = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.selectIcon = function (iconNumber) {
        $modalInstance.close(iconNumber);
    };

    $scope.handleErrorResponse = function (response) {
        $scope.executing = false;
        $scope.errored = true;
        $scope.errorMsg = ErrorSvrc.getErrorMessage(response);
    };
}


const CompanyCultureValuesConfigModal = angular.module('happyForceApp')
    .factory('CompanyCultureValuesConfigModal',
        function ($uibModal) {
            'ngInject'

            var methods = {};

            methods.addValue = function (selectedLanguage, callback) {

                const value = {
                    name: {
                        defaultValue: ''
                    },
                    description: {
                        defaultValue: ''
                    },
                    archived: false,
                    iconId: 33
                };

                $uibModal.open({
                    backdrop: 'static',
                    template: require('./add_value.html'),
                    controller: CompanyCultureValuesConfigOperationsCtrl,
                    resolve: {
                        cultureValue: function () {
                            return value;
                        },
                        language: function () {
                            return selectedLanguage;
                        },
                        isEdit: false
                    },
                    size: ' hi_question'
                }).result.then(function (result) {
                    callback(result);
                }, function (closeAction) {

                });
            };

            methods.editValue = function (value,selectedLanguage, callback) {
                $uibModal.open({
                    backdrop: 'static',
                    template: require('./add_value.html'),
                    controller: CompanyCultureValuesConfigOperationsCtrl,
                    resolve: {
                        cultureValue: function () {
                            return value;
                        },
                        language: function () {
                            return selectedLanguage;
                        },
                        isEdit: true
                    },
                    size: ' hi_question'
                }).result.then(function (result) {
                    callback(result);
                }, function (closeAction) {

                });
            }

            return methods;
        }
    );

export default CompanyCultureValuesConfigModal;
