'use strict'

import hfFeedbackIdeasInitiativeEditor from "./feedback-ideas-initiative-editor.controller";


function FeedbackIdeasInitiativeEditor($stateProvider) {
    'ngInject';
    $stateProvider
        .state('feedback.initiativeEditor', {
            url: '/ideas/initiative/editor?initiativeId',
            template: '<hf-feedback-ideas-initiative-editor></hf-feedback-ideas-initiative-editor>',
            data: { pageTitle: 'SIDEMENU_IDEAS', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
        });
}

const FeedbackIdeasInitiativeEditorConfig = angular.module('happyForceApp')
    .config(FeedbackIdeasInitiativeEditor);

export default FeedbackIdeasInitiativeEditorConfig;
