function CommentInappropriateModalCtrl($scope, Comments, $modalInstance, AuthSrvc, $translate, comment, isReplyOf, CommentInappropriateReportsModal) {
    'ngInject';
    var _this = this;

    _this.AuthSrvc = AuthSrvc;
    _this.originalComment = comment;
    _this.comment = angular.copy(comment);
    _this.isReplyOf = isReplyOf;

    _this.reason = comment.inappropriateReason;
    _this.reasonType = comment.inappropriateReasonType;
    _this.inappropriate = comment.inappropriate;
    _this.referee = comment.refereed != null ? true : false;

    // Compute the comment inappropriate
    _this.inappropriateOffensive = [];
    _this.inappropriateDisrespectful = [];
    _this.inappropriateOther = [];

    _.forEach(_this.comment.employeeInnappropiateFeedback, function (inappropriateFeedback) {
        if (inappropriateFeedback.type == 'OFFENSIVE') {
            _this.inappropriateOffensive.push(inappropriateFeedback);
        } else if (inappropriateFeedback.type == 'DISRESPECTFUL') {
            _this.inappropriateDisrespectful.push(inappropriateFeedback);
        } else if (inappropriateFeedback.type == 'OTHER') {
            _this.inappropriateOther.push(inappropriateFeedback);
        }
    });


    _this.openReportedMessagesModal = function () {
        CommentInappropriateReportsModal.showModal(_this.inappropriateOther);
    };

    _this.setDefaultReasonIfNull = function () {
        if (_this.reasonType == null) {
            _this.reasonType = 'OFFENSIVE';
        } else if (_this.reasonType == 'THRESHOLD' && _this.inappropriate == false) {
            _this.inappropriate = !_this.comment.inappropriate;
        }
    };

    _this.setInappropriateIfReferee = function () {
        if (_this.referee && !_this.inappropriate) {
            _this.inappropriate = true;
            _this.setDefaultReasonIfNull();
        }
    };

    _this.ok = function () {

        var hasInappropriateChange = _this.comment.inappropriate != _this.inappropriate;
        var hasRefereeChange = _this.comment.referee != _this.referee;
        var hasTypeChange = _this.comment.inappropriateReasonType != _this.reasonType;
        var hasReasonChange = _this.comment.inappropriateReason != _this.reason;

        var params = {
            reason: _this.reason,
            reasonType: _this.reasonType,
            inappropriate: _this.inappropriate,
            referee: _this.referee,
            commentId: _this.comment.id,
            isReplyOf: _this.isReplyOf,
            hasChange: (hasInappropriateChange || hasRefereeChange || hasTypeChange || hasReasonChange)
        };

        $modalInstance.close(params);
    };

    _this.exit = function () {
        $modalInstance.dismiss();
    };

}




const CommentInappropriateModal = angular.module('happyForceApp')
    .factory('CommentInappropriateModal',
        function ($uibModal) {
            var methods = {};
            methods.showModal = function (comment, isReplyOf, callback) {
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'md',
                    template: require('./comment-inappropriate-modal.html'),
                    controller: CommentInappropriateModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        comment: comment,
                        isReplyOf: function () {
                            return isReplyOf;
                        }

                    }
                }).result.then(function (params) {
                    if (callback) {
                        callback(params);
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            };

            return methods;

        }
    );
export default CommentInappropriateModal;
