import {getService} from '../../../../../../../migration_utils/react-in-angular';
import {Box, Skeleton, Tooltip, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {BulkActionPerson, BulkActionType, CellProps, Person} from '../../types';
import {useGroupNames} from '../../../../../../shared/hooks/useCharacteristicValueName';
import HFInfoIconWithTooltip from '../../../../../../shared/new-components/hf-info-icon-with-tooltip';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {isPerson} from '../../utils';


const GroupsCell = ({person, bulkActionType}: CellProps) => {

    const $translate = getService('$translate');
    const [originalPerson, setOriginalPerson] = React.useState<any>();
    const [hasDifference, setHasDifference] = React.useState<boolean>(false);

    const groupNames = useGroupNames(person?.groups);

    const originalGroupNames = useGroupNames(originalPerson?.groups);



    useEffect(() => {
        setOriginalPerson((person as BulkActionPerson).originalPerson);
    }, [person]);

    useEffect(() => {

        const originalPersonGroups = originalPerson?.groups?.slice().sort() || [];
        const personGroups = person?.groups?.slice().sort() || [];

        if (originalPersonGroups.length !== personGroups.length) {
            setHasDifference(true);
            return
        }


        const hasGroupsDifference = !originalPersonGroups.every((value, index) => value === personGroups[index]);


        setHasDifference(hasGroupsDifference);


    }, [originalPerson, person]);

    if (isPerson(person) && !person.id  && bulkActionType === undefined) {
        return (
            <Skeleton animation={false} variant="rounded" width={'80%'} height={20} />
        )
    }


    const renderOriginalPersonGroupsTooltip = () => {

        if (originalPerson
            && bulkActionType === BulkActionType.UPDATE
            && hasDifference
        ) {

            const originalGroupNamesString = originalGroupNames?.map((group) => {
                return group.groupName
            }).join(', ') || $translate.instant('NO_GROUPS');

            return (
                <HFInfoIconWithTooltip
                    tooltipContent={<Typography variant={'caption'}>{
                        $translate.instant('BULK_ACTION_ORIGINAL_RECORD', {value: originalGroupNamesString })}</Typography>}
                    color={'primary'}
                    iconOverride={<SwapHorizIcon/>}/>
            );
        } else {
            return null;
        }

    };




    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
                width: '100%'
            }}>
            <Tooltip title={  groupNames?.map((group) => {
                return  group.groupName
            }).join(', ') || $translate.instant('NO_GROUPS')}>
                <Typography
                    variant={'caption'}
                    dangerouslySetInnerHTML={{ __html: $translate.instant('USER_BELONG_TO_N_GROUPS',
                        {number: person.groups ? person.groups?.length: 0 })}} />
            </Tooltip>

            {renderOriginalPersonGroupsTooltip()}
        </Box>
    )

}

export default GroupsCell;
