'use strict';

import hfQuestionBox from "./components/question-box.component";
import QuestionsUtils from "../../utilities/question.utilities";
import template from "./topics.html";
import Dates from "../../utilities/date.utilities";
import TopicOperationsModal from 'root/app/components/topics/topics.wizard.operations';




function TopicsCtrl($scope, AuthSrvc, Analytics, ErrorSvrc, $translate, TopicOperationsModal, Questions, Groups, toaster, $intercom, Reports, Settings) {
    'ngInject'

    var _this = this;

    _this.questionTypes = [];
    _this.questionTypes['OPEN'] = true;
    _this.questionTypes['TOPIC'] = true;
    _this.questionTypes['RANGE'] = true;


    _this.states = [];
    _this.states['ACTIVE'] = true;
    _this.states['PAUSED'] = false;
    _this.states['PENDING'] = false;
    _this.states['EXPIRED'] = false;
    _this.states['ARCHIVED'] = false;

    _this.searchText = '';

    _this.questionActivationTypes = [];
    _this.questionActivationTypes['SINGLE'] = true;
    _this.questionActivationTypes['RECURRENT'] = true;


    _this.AuthSrvc = AuthSrvc;

    _this.header = {
        title: $translate.instant('SIDEMENU_CHECKINS')/*,
        subtitle: $translate.instant('QUESTIONS')*/
    };

    _this.topics = {};
    _this.total = 0;
    _this.from = 0;
    _this.to = 0;
    _this.currentPage = 1;

    var timeout = null;
    _this.search = {
        text: '',
        open: true,
        topic: true,
        range: true
    };

    _this.searchTypes = function () {
        var types = [];
        if (_this.search.open) types.push($translate.instant('QUESTION_SEARCH_TYPE_OPEN'));
        if (_this.search.topic) types.push($translate.instant('QUESTION_SEARCH_TYPE_TOPIC'));
        if (_this.search.range) types.push($translate.instant('QUESTION_SEARCH_TYPE_RANGE'));
        return types.join(', ');
    };

    _this.groups = [];

    _this.getGroupName = function (groupId) {
        var group = _this.groups.filter(function (group) {
            return group.id === groupId;
        });

        return (group && group[0] && group[0].name) || '';
    };

    _this.loadTopics = function (callback) {

        if (_this.loading) return;

        _this.topics = {};
        _this.from = 0;
        _this.to = 0;
        _this.loading = true;

        var startQuestion = (_this.currentPage - 1) * 10;

        var questionSearchStatus = [];
        _.forEach(Object.keys(_this.states), function (state) {
            if (_this.states[state])
                questionSearchStatus.push(state);
        });

        var questionSearchTypes = [];
        _.forEach(Object.keys(_this.questionTypes), function (type) {
            if (_this.questionTypes[type])
                questionSearchTypes.push(type);
        });

        if (questionSearchTypes.length === 0)
            questionSearchTypes = ['OPEN', 'RANGE', 'TOPIC'];

        var questionActivationTypes = [];
        _.forEach(Object.keys(_this.questionActivationTypes), function (type) {
            if (_this.questionActivationTypes[type])
                questionActivationTypes.push(type);
        });


        var params = {
            type: questionSearchTypes,
            status: questionSearchStatus,
            questionText: this.searchText,
            activationType: questionActivationTypes,
            fromQuestion: startQuestion,
            toQuestion: startQuestion + 10
        };

        params.toQuestion = params.fromQuestion + 10;

        /*if (state === 'ARCHIVED') {
            if (_this.search.text !== '') {
                params.questionText = _this.search.text;
            }
        }*/

        Questions.find(params, function (err, topics) {
            if (err) {
                if (callback) callback(err);
                ErrorSvrc.showErrorModal(err);
                _this.isError = true;
                _this.loading = false;
                return;
            };

            _this.topics = topics.result;
            _this.total = topics.total;
            _this.from = topics.from;
            _this.to = topics.to;
            _this.loading = false;
        });
    };

    _this.createTopicQuestion = function () {
        TopicOperationsModal.showCreateQuestionWizard(function (err, question) {
            _this.loadTopics();
        });
    };

    _this.exportToExcel = function () {

        _this.topics.forEach(function (topic) {
            var params = {
                questionId: topic.id
            };

            if (topic.activations && topic.activations.length) {
                topic.activations = topic.activations.sort(function (a, b) {
                    return a.from > b.from;
                });
            }

            // Compute the dates:
            params.from = Dates.fromAPIFormat(topic.activations[0].from, Settings.apiDateFormat);
            params.to = Dates.fromAPIFormat(topic.activations[topic.activations.length - 1].to, Settings.apiDateFormat);

            Reports.question(params, function () {
                // We are quering without question and for one hierarchy,
                // so we expect only one node, with one hie, that is for the primary question
                swal($translate.instant('TOPICS_EXPORT_SENT'), '', 'success');
                Analytics.trackEvent('topics', 'excel_report', 'success');
            }, function errorCallback(response) {

                ErrorSvrc.showErrorModal(response);
                Analytics.trackEvent('topics', 'excel_report', 'false');

            });
        });

    };

    this.$onInit = function () {



        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            _this.groups = groups;
        });


        ['searchText'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) return;
                _this.loadTopics();
            });
        });

        _this.loadTopics();

        Analytics.trackEvent('topics');

        $intercom.trackEvent('dashboard_visited_checkins');
    };
}


const CheckinsView = function ($stateProvider) {
    'ngInject';
    $stateProvider.state('topics.browse', {
        controller: TopicsCtrl,
        controllerAs: '$ctrl',
        bindToController: true,
        scope: {
            activationType: '@'
        },
        url: '/browse',
        template: template,
        data: { pageTitle: 'SIDEMENU_TOPICS_QUESTIONS', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
    });
};

export default CheckinsView;
