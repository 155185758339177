'use strict';

var SearchInputCtrl = function ($timeout) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {
        var _timeout;
        _this.searchString = '';
        _this.realTime = (typeof _this.realTime == 'undefined') ? false : _this.realTime;

        _this.clearInput = function () {
            if (typeof _this.searchString != 'undefined' && _this.searchString != '') {
                _this.searchString = '';
                _this.search(null);
            }
        };

        _this.focusSearch = function () {
            angular.element('#clear-button').addClass('primary-border');
        };

        _this.blurSearch = function () {
            angular.element('#clear-button').removeClass('primary-border');
        };

        _this.search = function (searchString) {
            _this.searchText = searchString;
        };

        _this.realTimeSearch = function (searchString) {
            if (_this.realTime) {
                if (_timeout) { // if there is already a timeout in process cancel it
                    $timeout.cancel(_timeout);
                }

                _timeout = $timeout(function () {
                    _this.searchText = searchString;
                    _timeout = null;
                }, 500);
            }
        };
    };

};

const hfSearchInput = angular.module('happyForceApp')
    .directive('hfSearchInput', function () {
        return {
            scope: {
                searchText: '=',
                realTime: '=?',
                hideButtons: '@',
                searchPlaceholder: '@'
            },
            template: require('./search-input.html'),
            controller: SearchInputCtrl,
            controllerAs: 'si',
            bindToController: true
        };
    });

export default hfSearchInput;
