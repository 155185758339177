'use strict';

var GroupSelectorModalCtrl = function (AuthSrvc, Groups, Filters, HFController, Analytics, $modalInstance, selected) {
    'ngInject';
    var _this = this;

    _this.isopen = false;

    _this.selected = selected;

    Groups.getAll({ active: true }, function (err, groups) {
        if (err) {
            console.log('Error', err);
            return;
        }

        _this.groups = groups;
    });

    _this.groups_applied = _this.selected && _this.selected.length || 0;

    _this.addGroup = function (group) {
        if (!group) {
            _this.selected = [];
        } else {
            _this.selected = {
                id: group.id,
                name: group.name
            };
        }

        _this.isopen = false;
        HFController.publish('hf-groups', _this.selected);
        _this.groups_applied = _this.selected && _this.selected.length || 0;

        _this.trackEvent('group_select');
    };

    _this.isSelected = function (group) {
        if (!group) {
            if (!_this.selected || _this.selected.length === 0) {
                return true; // ALL
            }

            return false;
        } else {
            if (_this.selected && _this.selected.id === group.id) {
                return true;
            }

            return false;
        }
    };

    _this.trackEvent = function (action) {
        Analytics.trackEvent('groups_filter', action);
    };

    _this.applyFilters = function () {
        $modalInstance.close(_this.selected);
    };

    _this.cancelFilter = function () {
        $modalInstance.close([]);
    };

    this.$onInit = function () {



    };
};

angular.module('happyForceApp')
    .controller('GroupSelectorModalCtrl', GroupSelectorModalCtrl);


const GroupSelectorModalComponent = angular.module('happyForceApp')
    .directive('hfGroupSelectorModal', function () {
        return {
            scope: {
                selected: '=',
                useFilter: '='
            },
            template: require('./group-selector-modal.html'),
            controller: GroupSelectorModalCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default GroupSelectorModalComponent;
