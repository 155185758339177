'use strict';

/**
 * Controller de activación
 * @param {Service} _service [description]
 */

import DashboardActivationService from "./activation.service";
import Valuation from "../../../utilities/valuation.utils";
import Style from "../../../utilities/style.utils";
import template from './activation.html';


var ActivationCtrl = function ($scope, DashboardActivationService, ErrorSvrc, $translate) {
    'ngInject';
    this.$onInit = function () {
        var _this = this;
        _this.dateString = '';
        _this.leyendItems = [];

        _this.leyendItems.push({ color: Style.good, label: $translate.instant('VERYGOOD') });
        _this.leyendItems.push({ color: Style.warning, label: $translate.instant('IMPROVABLE') });
        _this.leyendItems.push({ color: Style.bad, label: $translate.instant('BAD') });

        _this.getVariationLabel = function (percent) {
            if (!percent) return;
            percent = Math.round(percent);
            if (percent === 0) {
                return $translate.instant('SAME_AS_PREV_PERIOD');
            } else if (percent < 0) {
                return $translate.instant('BELOW_AS_PREV_PERIOD', { percent: percent });
            } else {
                return $translate.instant('ABOVE_AS_PREV_PERIOD', { percent: percent });
            }
        };

        var params;

        var data = _this.questionData;

        function loadData() {
            var groupsIds = [];

            params = {
                from: _this.dates.from,
                to: _this.dates.to,
                level: _this.level || null,
                filters: _this.filters,
                groupsId: _this.groups
            };

            $scope.$emit('loading', true);
            DashboardActivationService.loadData(params, function (err, response) {
                $scope.$emit('loading', false);
                if (err) {
                    ErrorSvrc.showErrorModal(response);
                } else {
                    Object.keys(response).forEach(function (key) {
                        _this[key] = response[key];
                        _this[key].color = Valuation.valuationColor(_this[key].valuation);

                    });
                }

            });

        }

        loadData();

        var lastExecution;
        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                loadData();
            }, true);
        });
    };
};

/*angular.module('happyForceApp')
.controller('ActivationCtrl', ActivationCtrl);*/


const ActivationComponent = angular.module('happyForceApp')
    .directive('hfActivationBox', function (ParticipationService) {
        return {
            scope: {
                dates: '=',
                level: '=',
                filters: '=',
                groups: '='
            },
            template: template,
            controller: ActivationCtrl,
            controllerAs: 'ac',
            bindToController: true/*,
            link: function(scope, element, attr, controller) {
                scope.$on('updateValues',function(event, data){
                    controller.loadData({
                        from: scope.startDate,
                        to: scope.endDate,
                        level: scope.level,
                        filterString: scope.filterString
                    });
                });
            }*/
        };
    });

export default ActivationComponent;
