'use strict';

import hfFeedbackComments from "./feedback-comments.controller";



function FeedbackCommentsConfig($stateProvider) {
    'ngInject';

    $stateProvider
    .state('feedback.comments', {
        url: '/comments/:hierarchyLevel?' +
            'hasComments&hasConversation&commentsOrder&classifiedStatus&' +
            'hasEmployeeReplies&hasCompanyReply&selectedFeebackTypes&searchText&activedTags&activedRatings&hasReplies&privated',
        params: {
            hierarchyLevel: { squash: true, value: null },
            hasComments: null,
            hasConversation: null,
            commentsOrder: null,
            classifiedStatus: null,
            hasEmployeeReplies: null,
            hasCompanyReply: null,
            selectedFeebackTypes: null,
            searchText: null,
            activedTags: null,
            activedRatings: null,
            hasReplies: null,
            privated: null,
            hiddenParam: 'YES'
        },
        template: '<hf-feedback-comments></hf-feedback-comments>',
        data: { pageTitle: 'COMMENTS_DETAIL', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
        /*
        resolve: {
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                   {
                    serie: true,
                    files: [
                        '/plugins/highcharts/highstock.js',
                        '/plugins/highcharts/highcharts-more.js']
                }
               ]);
            }
        }*/
    })
    .state('feedback.comment', {
        url: '/comment/:commentId/:replyId?' +
            'hasComments&hasConversation&commentsOrder&classifiedStatus&' +
            'hasEmployeeReplies&hasCompanyReply&selectedFeebackTypes&searchText&activedTags&activedRatings&hasReplies&privated',
        params: {
            hasComments: null,
            hasConversation: null,
            commentsOrder: null,
            classifiedStatus: null,
            hasEmployeeReplies: null,
            hasCompanyReply: null,
            selectedFeebackTypes: null,
            searchText: null,
            activedTags: null,
            activedRatings: null,
            hasReplies: null,
            privated: null,
            hiddenParam: 'YES'
        },
        template: '<hf-feedback-comments></hf-feedback-comments>',
        data: { pageTitle: 'COMMENTS_DETAIL', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
        /*,
        resolve: {
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                   {
                    serie: true,
                    files: [
                        '/plugins/highcharts/highstock.js',
                        '/plugins/highcharts/highcharts-more.js']
                }
               ]);
            }
        }*/
    })
    .state('feedback.comments.detail.withQuestion', {
        url: '/:selectedQuestion',
        template: '<hf-comments-detail></hf-comments-detail>',
        data: { pageTitle: 'COMMENTS_DETAIL', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
        /*,
        resolve: {
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                   {
                    serie: true,
                    files: [
                        '/plugins/highcharts/highstock.js',
                        '/plugins/highcharts/highcharts-more.js']
                }
               ]);
            }
        }*/
    });
}

const FeedbackComments = angular.module('happyForceApp')
.config(FeedbackCommentsConfig);

export default FeedbackComments;
