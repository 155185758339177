'use strict'

function FeedbackInitiativeDatesCtrl($scope, $translate) {

    var _this = this;

}


const hfFeedbackInitiativeDates = angular.module('happyForceApp')
    .directive('hfFeedbackInitiativeDates', function () {
        return {
            template: require('./feedback-initiative-dates.html'),
            controller: FeedbackInitiativeDatesCtrl,
            scope: {
                initiative: '=',
                onlyStartDate: '='
            },
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackInitiativeDates;
