import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getService } from 'reactInAngular';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HappyLoading from '../../../../../../shared/components/_migrated/loader/loading.directive';
import {Box} from '@mui/material';
import Numbers from 'root/app/utilities/number.utilities';
import Style from 'root/app/utilities/style.utils';
import Valuation from 'root/app/utilities/valuation.utils';
import {
    renderPopover
} from 'root/app/components/engagement/components/engagement_heatmap/components/cellTypes/cell_utils';
import { alpha } from "@mui/material";

const ScoreFactorCell = ({ cellData, type, id, loading, segment, column, selectedInsight, onCellClick}) => {
    const [value, setValue] = useState(null);
    const [hasData, setHasData] = useState(true);
    const [showImpacts, setShowImpacts] = useState(false);
    const [impactHI, setImpactHI] = useState(false);
    const [impactENPS, setImpactENPS] = useState(false);
    const [color, setColor] = useState('');
    const [hiOpacity, setHiOpacity] = useState(1);
    const [enpsOpacity, setEnpsOpacity] = useState(1);
    const [highlightLevel, setHighlightLevel] = useState(0);
    const $translate = getService('$translate');


    useEffect(() => {

        if (cellData) {

            if (type === 'SCORE') {
                setShowImpacts(true);
                setValue(Numbers.roundNumber(cellData.result, 1));

                const isHighHIImpact = cellData.impactHI === 'HIGH' || cellData.impactHI === 'TOTAL';
                const isHighEnpsImpact = cellData.impactENPS === 'HIGH' || cellData.impactENPS === 'TOTAL';
                setImpactHI(isHighHIImpact);
                setImpactENPS(isHighEnpsImpact);
                setHiOpacity(isHighHIImpact ? 1 : 0.3);
                setEnpsOpacity(isHighEnpsImpact ? 1 : 0.3);

            } else if (type === 'FACTOR') {
                setValue(Numbers.roundNumber(cellData.result, 1));
            }

            const isRepresentative = cellData.quality === 'NORMAL' || cellData.quality === 'GOOD';
            let calculatedColor = Style.noData;
            if (cellData.result === null || cellData.result === undefined) {
                setHasData(false);
                calculatedColor = Style.noData;
                if (!isRepresentative) {
                    calculatedColor = Style.neutralNotRelevant;
                }
            } else {
                setHasData(true);
                calculatedColor = Valuation.valuationColor(cellData.valuation, false);
                if (!isRepresentative) {
                    calculatedColor = Valuation.valuationColorWithLowQuality(cellData.valuation, false);
                }
            }
            setColor(calculatedColor);
        }

    }, [cellData]);


    useEffect(() => {

        if (selectedInsight) {
            setHighlightLevel(selectedInsight.signalStrength ? selectedInsight.signalStrength : 0);
        } else {
            setHighlightLevel(0);
        }

    }, [selectedInsight]);


    const infoImpactPopover = (impactType) => {

        if (impactType === 'HI') {
            return impactHI ? $translate.instant('SCORE_IMPACTS_HI') : $translate.instant('SCORE_NO_IMPACTS_HI');
        } else if (impactType === 'ENPS') {
            return impactENPS ? $translate.instant('SCORE_IMPACTS_ENPS') : $translate.instant('SCORE_NO_IMPACTS_ENPS');
        }
        return element;
    };

    const handleOnCellClick = () => {
        if (onCellClick)
        {onCellClick(segment, column, selectedInsight);}
    };




    return (
        <Box id={id} sx={theme => ({
            backgroundColor: color,
            border: `${color ? '2' : 0}px solid ${highlightLevel ? alpha(theme.palette.primary.main, highlightLevel / 100) : color}`,
        })}
        className="cell_heatmap">

            <div  className="cell_container p-new-sm">

                {!loading && (
                    <>

                        <span className={'mini-style'}>
                            <i style={{visibility: 'hidden'}} className="fa fa fa-info-circle text-white"></i>
                        </span>


                        <div className="result-container text-center">
                            {hasData ? (
                                <div className="small-style" role="button" onClick={handleOnCellClick}>{value}</div>
                            ) : (
                                <div className="small-style">{$translate.instant('NO_DATA')}</div>
                            )}

                            {showImpacts && (
                                <div className="impact_container m-t-new-sm">
                                    <Tooltip title={
                                        <Typography className="small-style"
                                            dangerouslySetInnerHTML={{__html: infoImpactPopover('HI')}}></Typography>
                                    }
                                    placement="bottom">
                                        <img src="/images/hi_cell_hm.png" className="img-xs"
                                            style={{opacity: hiOpacity}}/>
                                    </Tooltip>
                                    <Tooltip title={
                                        <Typography className="small-style"
                                            dangerouslySetInnerHTML={{__html: infoImpactPopover('ENPS')}}></Typography>
                                    }
                                    placement="bottom">
                                        <img src="/images/enps_cell_hm.png" className="img-xs"
                                            style={{opacity: enpsOpacity}}/>
                                    </Tooltip>
                                </div>
                            )}
                        </div>


                        {renderPopover(hasData, cellData, value, $translate)}


                    </>
                )}

                {loading && (
                    <Box className={'result-container'} sx={{width:'100%'}}>
                        <HappyLoading loading={loading} skeleton={2} noMessage={true}/>
                    </Box>
                )}
            </div>
        </Box>
    );
};

ScoreFactorCell.propTypes = {
    cellData: PropTypes.object,
    segment: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    onCellClick: PropTypes.func.isRequired,
    selectedInsight: PropTypes.object,
};

export default ScoreFactorCell;
