'use strict'

import Dates from "../../../../utilities/date.utilities";
import {getHierarchyName} from "../../../services/migrated/hierarchy.service";

function ConversationCtrl($translate, Comments, AuthSrvc, Conversation, People, $scope, Analytics, Settings) {
    'ngInject';
    var _this = this;

    _this.loading = false;
    _this.reply = false;

    function getConversation(conversationId) {
        if (conversationId) {
            _this.loading = true;
            Conversation.get(conversationId, function (err, conversation) {
                _this.conversation = conversation;
                _this.message = '';
                _this.reply = false;
                _this.loading = false;
                _this.canMessage = conversation.canMessage;
                _this.notAnonymous = conversation.notAnonymous;
                _this.createdOn = Dates.fromAPIFormat(conversation.createdOn, Settings.apiDateFormat);
                Conversation.markAsSeen(conversationId, function (err, data) {
                    if (err)
                        console.log(err);
                });

                if (conversation.type === 'COMMENT') {
                    _this.commentId = conversation.commentId;
                } else if (conversation.type === 'ENGAGEMENT_STATUS') {
                    _this.personalTokenId = conversation.encryptedId;
                }

                // Get the initiator profile:
                People.getProfile(conversation.assignedTo, function (err, user) {
                    if (err) {
                        _this.assignedToName = '?';
                        _this.assignedToImage = '';
                        _this.assignedToDescription = '';
                    } else {
                        _this.assignedToName = user.name;
                        _this.assignedToImage = user.profileImagePath;
                        if (conversation.assignedTo === AuthSrvc.user().id) {
                            _this.assignedToDescription = $translate.instant('CONVERSATION_STARTED_BY_YOU');
                        } else {
                            _this.assignedToDescription = _this.assignedToName;
                        }
                    }
                });

                // Get the hierarchy name:
                getHierarchyName(_this.conversation.hierarchyId, function (err, name) {
                    if (err) {
                        name = '?';
                    }
                    _this.hierarchyName = name;
                });
            });

        } else {
            _this.canMessage = true;
        }

        return [];
    };

    function handleConversationMessageCallback(err, updatedConversation) {
        _this.loading = false;
        if (err) {
            Analytics.trackEvent('conversations', 'send_reply', 'fail');
            return swal($translate.instant('ERROR_REPLY_CONVERSATION'), err);

        } else {
            Analytics.trackEvent('conversations', 'send_reply', 'success');

            _this.conversation = updatedConversation;
            _this.message = null;

            // Is this the first message?
            if (!_this.conversationId) {
                _this.conversationId = updatedConversation.id;
                if (_this.onConversationStarted) {
                    _this.onConversationStarted(err, _this.conversationId);
                }
            }
        }



    }

    _this.showDate = function (date, prev) {

        if (!prev)
            return true;

        var mActual = moment(date).format('DDMMYYYY');
        var mPrev = moment(prev).format('DDMMYYYY');
        return (mPrev !== mActual);
    };

    _this.sendMessage = function () {

        if (!_this.loading && _this.message && _this.message.length > 0) {
            _this.loading = true;
            if (!_this.conversationId) {
                // There are two cases here if the conversation has not yet been started:
                if (_this.commentId) {
                    Conversation.startConversationOnComment(_this.commentId, _this.message, handleConversationMessageCallback);
                } else {
                    Conversation.startConversationOnPersonStatus(_this.personalTokenId, new Date(), _this.message, handleConversationMessageCallback);
                }
            } else {
                Conversation.sendMessage(_this.conversationId, _this.message, handleConversationMessageCallback);
            }
        }

    };

    this.$onInit = function () {



        ['conversationId', 'commentId', 'personalTokenId'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                getConversation(_this.conversationId);
            }, true);
        });
        getConversation(_this.conversationId);

    };
}

const hfConversation = angular.module('happyForceApp')
    .directive('hfConversation', function () {
        return {
            scope: {
                commentId: '<',
                conversationId: '<',
                personalTokenId: '<',
                onConversationStarted: '='
            },
            restrict: 'E',
            template: require('./conversation.html'),
            controller: ConversationCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfConversation;
