'use strict';

import hfAnnouncementAttachments from "./components/announcement-attachments/announcement-attachments.component";
import hfAnnouncementCommentReplyTypeEditor
    from "./types/announcement-comment-reply-type-editor/announcement-comment-reply-type-editor.component";
import hfAnnouncementLifeEventTypeEditor
    from "./types/announcement-lifeevent-type-editor/announcement-liveevent-type-editor.component";
import hfAnnouncementStandardTypeEditor
    from "./types/announcement-standard-type-editor/announcement-standard-type-editor.component";
import hfAnnouncementTemplateTypeEditor
    from "./types/announcement-template-type-editor/announcement-template-type-editor.component";
import Style from "../../../../utilities/style.utils";



function AnnouncementsEditorController($scope, Announcements, $translate, AuthSrvc, Settings, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.genericExitEdition = function () {
        swal({
            type: 'warning',
            title: $translate.instant('ANNOUNCEMENT_CHANGES_WILL_BE_LOST'),
            text: $translate.instant('ANNOUNCEMENT_CHANGES_WILL_BE_LOST_DESCRIPTION'),
            allowEscapeKey: true,
            buttons: {
                cancel: { visible: true, text: $translate.instant('ANNOUNCEMENT_CHANGES_WILL_BE_LOST_CONFIRM'),
                    value: true, className: 'swal-button--delete' },
                confirm: { text: $translate.instant('ANNOUNCEMENT_CHANGES_STAY_CANCEL'), value: false }
            },
            confirmButtonColor: Style.bad,
            closeOnConfirm: true
        }).then(function (result) {
            if (result) {
                _this.onExitEdition();
                $scope.$digest();
            };
        });
    };

    _this.$onInit = function () {
        if (_this.sectionId ===  Settings.announcementSectionsConfig.celebrationSectionId) {
            _this.editorType = 'lifeevent';
        } else if (_this.sectionId ===  Settings.announcementSectionsConfig.commentReplySectionId) {
            _this.editorType = 'comment_reply';
        } else {
            _this.editorType = 'standard';
        }
    };


};

const hfAnnouncementEditor = angular.module('happyForceApp')
    .directive('hfAnnouncementEditor', function () {
        return {
            scope: {
                announcement: '<',
                showHeader: '<',
                sectionId: '<',
                onAnnouncementEdited: '<',
                onExitEdition: '<'
            },
            replace: true,
            template: require('./announcement.editor.component.html'),
            controller: AnnouncementsEditorController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAnnouncementEditor;
