'use strict';

function SurveyAudienceBadgeCtrl($translate, CompanySvrc) {
    var _this = this;

    this.$onInit = function () {

        _this.allCompany = false;

        CompanySvrc.getUserCompany(
            function (err, company) {
                _this.companyName = company.name;
            }
        );

        if (!_this.survey.result.hierarchyIds && !_this.survey.result.characteristics && !_this.survey.result.groupIds) {
            _this.allCompany = true;
        }

    };


};

const hfSurveyAudienceBadge = angular.module('happyForceApp')
    .directive('hfSurveyAudienceBadge', function () {
        return {
            scope: {
                survey: '='
            },
            template: require('./survey_audience_badge.html'),
            controller: SurveyAudienceBadgeCtrl,
            controllerAs: 'sa',
            bindToController: true
        };
    });

export default hfSurveyAudienceBadge;
