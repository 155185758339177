'use strict';


import Valuation from "../../../utilities/valuation.utils";
import Dates from "../../../utilities/date.utilities";
import {getENPSPersonalStats} from "../../services/migrated/enps.service";

var HFPersonEngagementContextCtrl = function ($scope, People, ErrorSvrc, HIService, ScoresV2Srvc) {
    'ngInject';
    var _this = this;

    _this.currentScoresPage = 1;


    _this.loadPersonHI = function () {
        return new Promise(function (resolve, reject) {
            HIService.stats.personal(Dates.firstDayOfMonth(_this.to), Dates.lastDayOfMonth(_this.to), _this.personToken, function (err, individualHI) {
                if (err) {
                    reject(err);
                } else {
                    _this.hi = individualHI.hi;
                    _this.hiValuation = individualHI.valuation;
                    _this.hiValuationColor =  Valuation.valuationColor(individualHI.valuation, false);
                    _this.numVotes = individualHI.votes ? individualHI.votes.length : 0;
                    resolve(null);
                }
            });
        });
    };

    _this.loadPersonENPS = function () {
        return new Promise(function (resolve, reject) {

            getENPSPersonalStats(Dates.lastDayOfMonth(_this.to), _this.personToken, function (err, individualENPS) {
                if (err) {
                    reject(err);
                } else {
                    _this.eNPS = individualENPS.result;
                    _this.eNPSValuation = individualENPS.valuation;
                    _this.eNPSValuationColor =  Valuation.valuationColor(individualENPS.valuation, false);

                    resolve(null);
                }
            });
        });
    };

    _this.loadPersonScores = function () {

        console.log('CARGA DE SCORES')
        _this.currentScoresPage = 1;
        _this.scores = [];
        return new Promise(function (resolve, reject) {
            ScoresV2Srvc.stats.personal(Dates.lastDayOfMonth(_this.to), _this.personToken, function (err, individualScores) {
                if (err) {
                    reject(err);
                } else {
                    var scores = [];
                    individualScores.forEach(function (result) {
                        var score = {
                            id: result.scoreId,
                            value: result.score,
                            valuation: result.valuation,
                            valuationColor: Valuation.valuationColor(result.valuation, false),
                            complete: result.valuation === 'NO_DATA' ? false : true,
                            factors: []
                        };

                        Object.keys(result.factors).forEach(function (factorId) {
                            score.factors.push({
                                id: factorId,
                                value: result.factors[factorId].score,
                                valuation: result.factors[factorId].valuation,
                                valuationColor: Valuation.valuationColor(result.factors[factorId].valuation, false)
                            });

                            if (result.factors[factorId].valuation === 'NO_DATA') {
                                score.complete === false;
                            }
                        });
                        scores.push(score);
                    });

                    // Now sort the scores with ones with most negative values first
                    // If a score is not completed, give a higher value (starting at 10), so it will be added to the end.
                    _this.scores = _.sortBy(scores, function (score) { return score.complete ? score.value : score.value + 10; });
                    _this.paginate(1);
                    resolve(null);
                }
            });
        });
    };

    /* For some reason I'm unable to make work the angular sorBy & limit together, so we need o split the scores manually */
    _this.paginate = function (currentScoresPage) {

        var index = (currentScoresPage - 1) * _this.maxScoreRows;
        var to = (index + _this.maxScoreRows);

        // Restrict the index
        var to = to > _this.scores.length ? _this.scores.length - to : to;

        _this.visibleScores = _this.scores.slice(index, to);
    };


    _this.loadPersonStatus = function () {
        _this.loadingData = true;
        Promise.all([
            _this.loadPersonHI(),
            _this.loadPersonENPS(),
            _this.loadPersonScores()]
        ).then(function (res) {
            _this.loadingData = false;
        }).catch(function (err) {
            _this.loadingData = false;
            ErrorSvrc.showErrorModal(err);
        });
    };


    this.$onInit = function () {
        ['personToken', 'to'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n && n != o) {
                    _this.loadPersonStatus();
                }
            });
        });
        _this.loadPersonStatus();
    };
};

const hfPersonEngagementContext = angular.module('happyForceApp')
    .directive('hfPersonEngagementContext', function () {
        return {
            scope: {
                personToken: '<',
                maxScoreRows: '<',
                to: '<'
            },
            template: require('./hf-person-engagement-context.html'),
            controller: HFPersonEngagementContextCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });

export default hfPersonEngagementContext;
