'use strict';

import DaysLeft from "../../../shared/components/_migrated/days-left/days-left";
import Dates from "../../../utilities/date.utilities";



function FeedbackIdeasCtrl($scope, Filters, HIService, Stats, $stateParams, Settings, FeedbackIdeasService, $translate, $state, Analytics, ErrorSvrc, Reports, CompanySvrc, FeedbackConfigurationService, anchorSmoothScroll, SegmentsFilterService, $location, $intercom) {
    'ngInject';

    var _this = this;



    _this.dateFormat = Settings.defaultDateFormat;

    _this.typeView = 1;

    _this.ideasListingFilter = {

    };

    _this.ideasInitiativesFilter = {

    };

    _this.selectedTags = [];

    _this.updateFilters = function (newSelectedFilters) {
        _this.ideasListingFilter = newSelectedFilters;
        _this.ideasInitiativesFilter = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        mode: 'single',
        title: $translate.instant('GROUPS'),
        nonRemovable: false
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false
    }

    _this.dateFrom = {
        title: $translate.instant('FROM'),
        nonRemovable: false,
        id: 'from',
    };

    _this.dateTo = {
        title: $translate.instant('TO'),
        nonRemovable: false,
        id: 'to'
    };

    _this.dateFiltersConfig = {
        show: true,
        filters: [_this.dateFrom, _this.dateTo]
    };

    $intercom.trackEvent('dashboard_visited_feedback_ideas');


    this.$onInit = function () {
        // Let's check if the ideas feedback is disabled or enabled
        FeedbackConfigurationService.checkTypeIsEnabled('SUGGESTION', function (err, enabled) {
            if (err)
                ErrorSvrc.showErrorModal(err);
            else {
                _this.ideasEnabled = enabled;
            }
        });
    };


}

const hfFeedbackIdeas = angular.module('happyForceApp')
    .directive('hfFeedbackIdeas', function () {
        return {
            template: require('./feedback-ideas.html'),
            controller: FeedbackIdeasCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeas;
