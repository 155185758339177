/**
 * i18n.config
 *
 * Fichero destinado a gestionar la configuración de la idiomatización del dashboard
 *
 */

import es from './../../../i18n/es.json';
import en from './../../../i18n/en.json';


import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/**
 * Configuración del translateProvider
 * @param  {Object} $translateProvider Provider translate (angular-translate)
 */
function configureLanguage($translateProvider) {
    'ngInject';
    // available langs
    var availableLangs = ['es', 'en'];
    var availableAliases = {
        'en_*': 'en',
        'es_*': 'es',
        ca: 'es',
        gl: 'es'
    };


    const langs = {
        es: es,
        en: en
    };


    for (var x in availableLangs) {
        var lang = availableLangs[x];
        /*if (langs[lang] && langs[lang].config && langs[lang].config.dir && langs[lang].config.dir === 'rtl') {
            window.rtlLanguages = window.rtlLanguages || [];
            window.rtlLanguages.push(lang);
        }*/


        $translateProvider.translations(lang, langs[lang]);
        $translateProvider.useSanitizeValueStrategy('sanitize');
    }

    $translateProvider.registerAvailableLanguageKeys(availableLangs, availableAliases);

    $translateProvider.fallbackLanguage('en');
    $translateProvider.determinePreferredLanguage();

    $translateProvider.useLocalStorage();

    // Enable escaping of HTML
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
}


export default configureLanguage;

