'use strict';

import async  from "async";
import Style from "../../../utilities/style.utils";

function FeedbackRecognitionCtrl($scope, Filters, HIService, AuthSrvc, Stats, $stateParams, Settings, Comments, $translate, $state, Analytics, ErrorSvrc, Reports, CompanySvrc, anchorSmoothScroll, SegmentsFilterService, $location, RecognitionService, $intercom) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {

        _this.AuthSrvc = AuthSrvc;

        _this.dateFrom = Filters.startDate();
        _this.dateFromEnabled = true;

        _this.dateTo = Filters.endDate();
        _this.dateToEnabled = true;

        _this.exportingExcelReport = false;

        _this.loadingData = true;
        _this.currentCommentPage = 1;
        _this.totalComments = 0;
        _this.exportingExcelReport = false;
        _this.classifiedStatus = null;
        _this.searchString = null;
        _this.order_by = $translate.instant('RECENT');
        _this.filters = null;
        _this.groups = Filters.groups() || [];
        _this.leyendRatings = [];
        _this.activedTags = [];
        _this.recognitionTags = [];
        _this.activedRatings = [];
        _this.hasComments = false;
        _this.hasCompanyReply = false;
        _this.hasConversation = false;
        _this.selectedFeebackTypes = ['RECOGNITION'];
        _this.hasEnoughActiveEmployees = true;
        _this.filterByComment;
        _this.recognitionValue = null;
        _this.commentsOrder = $stateParams.commentsOrder || null;
        _this.order_by = $translate.instant('RECENT');

        _this.hierarchyId = [];

        _this.popover_stats = {
            totalOther: 0
        };

        _this.dateFormat = $translate.instant('DATE_FORMAT');
        if (_this.dateFormat === 'DATE_FORMAT')
            _this.dateFormat = Settings.defaultDateFormat;

        if ($stateParams.hierarchyLevel && $stateParams.hierarchyLevel != 'top') {
            _this.hierarchyId.push($stateParams.hierarchyLevel);
        }

        _this.polarOptions = {
            with: '100px',
            height: '100px'
        };

        _this.header = {
            title: $translate.instant('RECOGNITION_SECTION_TITLE'),
            subtitle: $translate.instant('RECOGNITION_SECTION_SUBTITLE')
        };

        var params;

        // Monitorize variables;
        var pageArgs = ['dateFrom', 'dateFromEnabled', 'dateTo', 'dateToEnabled', 'hierarchyId', 'filters', 'groups', 'filterByComment'];
        var lastExecution;
        pageArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (newVal === oldValue) return;
                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                _this.loadComments();
            }, true);
        });


        // Monitorize filters
        var filterArgs = ['searchText',
            'classifiedStatus',
            'hasCompanyReply',
            'hasConversation',
            'selectedFeebackTypes',
            'commentsOrder',
            'recognitionTags', 'dateFrom', 'dateFromEnabled', 'dateTo', 'dateToEnabled', 'hasSignature'];

        filterArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (typeof newVal === 'undefined' || newVal === oldValue) return;
                Analytics.trackEvent('recognition', 'filter_by_others');
                _this.loadComments();
            });
        });


        // Monitorize order by
        $scope.$watch(angular.bind(_this, function () {
            return _this.commentsOrder;
        }), function (newVal, oldValue) {

            if (typeof newVal === 'undefined' || newVal === oldValue) return;
            var ORDER_BY = newVal || 'RECENT';
            if (ORDER_BY === 'INAPPROPRIATE')
                ORDER_BY = 'REPORTED_COMMENT';

            _this.order_by = $translate.instant(ORDER_BY);
        });


        _this.initialize = function () {
            var stream = [];
            _this.loadingData = true;

            stream.push(function (next) {
                HIService.get(function (err, hiQuestion) {
                    if (err) {
                        console.error('Error loading question', err);
                        next(err);
                    }

                    _this.question = hiQuestion;
                    _this.leyendRatings = hiQuestion.values;

                    next(null);
                });
            });

            async.parallel(stream, function (err, data) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    _this.loadComments();
                }
            });
        };

        _this.loadComments = function (callback) {//filterString, textToSearch, order, classifiedStatus) {

            var params = prepareParams();

            // Set the pagination to the params
            params.fromComment = (_this.currentCommentPage - 1) * 10;
            params.toComment = 10;
            var commentStream = [];

            // Get comments
            commentStream.push(function (next) {
                _this.question.comments = [];

                // And get the comments
                _this.loadingComments = true;
                RecognitionService.list(params, function (err, recognitions) {

                    if (err) {
                        next(err);
                    } else {
                        _this.hasEnoughActiveEmployees = true;
                        _this.hasComments = true;

                        //_this.comments.length = 0;
                        _.forEach(recognitions.comments, function (comment) {
                            _this.question.comments.push(comment);
                        });

                        _this.totalComments = recognitions.total;
                        _this.loadingComments = false;
                        _this.values = recognitions.question && recognitions.question.values;

                        next();
                    }


                }, function errorCallback(response) {

                    _this.loadingComments = false;
                    _this.hasEnoughActiveEmployees = false;
                    next(response);
                });
            });

            // Get BlOCKED
            commentStream.push(function (next) {

                var totalBlockedParams = angular.copy(params);
                delete totalBlockedParams.from;
                delete totalBlockedParams.to;
                totalBlockedParams.statTypes = ['REFEREED'];
                totalBlockedParams.feedbackTypes ['RECOGNITION'];


                Comments.stats.get(totalBlockedParams, function (err, commentStats) {
                    _this.loadCommentsStats = false;
                    if (err) {
                        return next(err);
                    }

                    _this.totalBlockedComments = commentStats.refereed;

                    next();
                });
            });

            // Get recognitions stats
            commentStream.push(function (next) {
                var recognitionParams = angular.copy(params);
                _this.loadCommentsStats = true;
                RecognitionService.stats.get(recognitionParams, function (err, stats) {
                    _this.loadCommentsStats = false;
                    if (err) {
                        next(err);
                    } else {
                        _this.recognitionValues = stats.results.map(function (item, i) {
                            item.value.color = Style.recognitionColors[i];
                            item.value.count = item.count;
                            return item.value;
                        });
                        _this.recognitionStats = stats.results;
                        next(null);
                    }
                });
            });

            commentStream.push(function (next) {
                var recognitionParams = angular.copy(params);
                RecognitionService.stats.rankingSource(recognitionParams, function (err, result) {
                    if (err) {
                        next(err);
                    } else {
                        _this.recognitionBySourceRanking = result.ranking.splice(0, 5);
                        next(null);
                    }
                });

            });

            commentStream.push(function (next) {
                var recognitionParams = angular.copy(params);
                RecognitionService.stats.rankingTarget(recognitionParams, function (err, result) {
                    if (err) {
                        next(err);
                    } else {
                        _this.recognitionByTargetRanking = result.ranking.splice(0, 5);
                        next(null);
                    }
                });
            });

            async.parallel(commentStream, function (err, data) {
                if (callback)
                    callback(err, data);
            });

        };


        // Helper functions
        _this.exportToExcel = function () {
            var params = prepareParams();

            _this.exportingExcelReport = true;
            _this.loadingData = true;
            Reports.recognitions(params, function () {
                // We are quering without question and for one hierarchy, so we expect only one node, with one hie, that is for the primary question
                _this.loadingData = false;
                _this.exportingExcelReport = false;
                swal($translate.instant('RECOGNITIONS_EXPORT_SENT'), '', 'success');
                Analytics.trackEvent('recognitions', 'excel_report', 'success');

            }, function errorCallback(response) {

                ErrorSvrc.showErrorModal(response);
                _this.loadingData = false;
                _this.exportingExcelReport = false;
                Analytics.trackEvent('recognitions', 'excel_report', 'failure');
            });
        };

        function prepareParams() {

            var params = {};

            if (_this.dateFromEnabled) {
                params.from = _this.dateFrom;
            }

            if (_this.dateToEnabled) {
                params.to = _this.dateTo;
            }

            params.questionId = _this.question.id;
            params.groupId = _this.groups;

            // Set he current hierarchy for filter the comments
            if (_this.hierarchyId && _this.hierarchyId.length) {
                params.hierarchyId = _this.hierarchyId.join(',');
            }

            if (_this.searchText != null) {
                params.commentText = _this.searchText;
            }

            if (_this.activedTags) {
                params.tags = _this.activedTags;
            }

            if (_this.activedRatings.length) {
                params.ratings = _this.activedRatings;
            }

            params.filters = SegmentsFilterService.compileFilterStringFromSegments(_this.filters);

            // if (_this.selectedFeebackTypes.length) {
            params.feedbackTypes = 'RECOGNITION';

            // }

            if (_this.filterByComment) {
                params.sameAuthorAs = _this.filterByComment;
            }

            if (_this.commentsOrder != null) {
                params.sortOrder = _this.commentsOrder;
            }

            if (_this.hasCompanyReply)
                params.hasCompanyReply = true;
            if (_this.hasConversation)
                params.hasConversation = true;

            if (_this.hasSignature) {
                params.hasSignature = _this.hasSignature;
            }

            if (_this.classifiedStatus != null) {
                params.classifiedStatus = _this.classifiedStatus;
            };

            if (_this.recognitionTags) {
                params.valueIds = [];
                _this.recognitionTags.forEach(function (tag) {
                    params.valueIds.push(tag.id);
                });

            }

            return params;
        };


        _this.toogleRecognitionValueTag = function (tag) {
            var index = _.findIndex(_this.recognitionTags, function (item) {
                return item.id == tag.id;
            });

            if (index == -1) {
                tag.active = true;
                _this.recognitionTags.push(tag);
            } else {
                tag.active = false;
                _this.recognitionTags.splice(index, 1);
            }
            Analytics.trackEvent('recognition', 'filter_by_recognition');
            _this.loadComments();
        };

        _this.isActiveRecognitionTag = function (tag) {
            var filtered = _this.recognitionTags.filter(function (t) {
                return t.id === tag;
            });
            return filtered && filtered.length > 0;
        };


        _this.toogleFeedbackType = function (feedbackType) {
            var index = _this.selectedFeebackTypes.indexOf(feedbackType);
            if (index == -1)
                _this.selectedFeebackTypes.push(feedbackType);
            else
                _this.selectedFeebackTypes.splice(index, 1);
            Analytics.trackEvent('recognition', 'filter_by_feedback_type');
            _this.loadComments();
        };

        _this.feedbackTypeActived = function (feedbackType) {
            var index = _this.selectedFeebackTypes.indexOf(feedbackType);
            return (index > -1);
        };

        _this.tagActived = function (tag) {
            var index = _this.activedTags.indexOf(tag);
            return (index > -1);
        };

        // TAGS
        _this.toogleRating = function (rating) {
            var index = _this.activedRatings.indexOf(rating);
            if (index == -1)
                _this.activedRatings.push(rating);
            else
                _this.activedRatings.splice(index, 1);
            Analytics.trackEvent('recognition', 'filter_by_rating');
            _this.loadComments();
        };


        _this.resetTags = function () {
            _this.activedTags = [];
            _this.loadComments();
        };

        _this.resetRecognitionTags = function () {
            _this.recognitionTags = [];
            _this.loadComments();
        };

        _this.getColorFromRating = function (rating) {
            var leyend = _.find(_this.leyendRatings, function (leyend) {
                return leyend.id === rating;
            });

            return leyend.color;
        };

        _this.setComment = function (selected) {
            _this.selected_comment = selected;
            _this.temp_comment = JSON.parse(JSON.stringify(selected)); // Cerdada (cambiar con angular.copy)
        };

        _this.changePage = function () {
            $location.hash('pagination');
            _this.loadComments(function (err, data) {
                $location.hash('comments');
                anchorSmoothScroll.scrollTo('comments');
            });
        };

        _this.initialize();

        //loadData();
        Analytics.trackEvent('recognition', 'detail', (!_this.level) && 'top');

        $intercom.trackEvent('dashboard_visited_recognitions');
    };
}


const hfRecognitionSection = angular.module('happyForceApp')
    .directive('hfRecognitionSection', function () {
        return {
            template: require('./feedback-recognition.html'),
            controller: FeedbackRecognitionCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });


export default hfRecognitionSection;
