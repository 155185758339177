const FeedbackStatusService = function (Restangular, Settings, Rollbar) {
    'ngInject';
    var FeedbackStatusConfiguration = Restangular
        .one('company')
        .one('feedback')
        .one('configuration')
        .one('status');

    var FeedbackStatus = Restangular.one('company').one('comments').one('status');

    var methods = {};

    methods.list = function (feedbackType, archivedStatus, callback) {

        var params = {};

        if (archivedStatus)
            params.archivedStatus = archivedStatus;

        FeedbackStatusConfiguration.one(feedbackType).get(params).then(function (statuses) {
            callback(null, statuses);
        }, function (err) {
            Rollbar.error('Error getting Feedback status', err);
            callback(err);
        });
    };

    methods.update = function (id, statusType, callback) {
        FeedbackStatusConfiguration.customPUT(statusType, id).then(function (status) {
            callback(null, status);
        }, function (err) {
            Rollbar.error('Error updating Feedback status', err);
            callback(err);
        });
    };

    methods.create = function (statusType, callback) {
        FeedbackStatusConfiguration.customPOST(statusType).then(function (status) {
            callback(null, status);
        }, function (err) {
            Rollbar.error('Error creating Feedback status', err);
            callback(err);
        });
    };

    methods.setStatus = function (commentId, newStatusId, callback) {
        FeedbackStatus.one(commentId).one(newStatusId).customPUT().then(function (status) {
            callback(null, status);
        }, function (err) {
            callback(err);
        });
    };

    methods.removeStatus = function (commentId, callback) {
        FeedbackStatus.one(commentId).remove().then(function (status) {
            callback(null, status);
        }, function (err) {
            callback(err);
        });
    };

    methods.checkTypeHasStatuses = function (feedbackType, callback) {
        methods.list(feedbackType, 'ACTIVE', function (err, result) {
            if (err)
                callback(err, null);
            else
                callback(null, result.length > 1);
        });
    };

    return methods;
};

export default FeedbackStatusService;
