const hftokenreplace = function (CompanySvrc, Languages, $translate) {
    'ngInject'
    return function (input, options) {
        return Languages.replaceTokens(input);

    }
}

export default hftokenreplace

