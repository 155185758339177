'use strict';


import {isMultiHierarchies} from "../../../../shared/services/migrated/hierarchy.service";

function SurveyScheduleEditController($scope, $modalInstance, $translate, Groups, Characteristics, schedulePlan, Surveys, survey, Settings, Languages,
                                      isEdit) {
    'ngInject';

    var _this = this;

    var today = moment().toDate();
    var nextMonth = moment(today).add('days', 31).toDate();

    // Store this on the scope, since angular-datepicker doesn't works very well with the scope
    $scope.pickerMin = moment().subtract(1, 'd');


    // The survey for which we want to launch the plan
    _this.survey = survey;


    if (schedulePlan) {
        _this.plan = angular.copy(schedulePlan);

        // Parse dates
        if (_this.plan.activationRule.ruleType == 'SINGLE') {
            _this.plan.activationRule.from = new Date(_this.plan.activationRule.from);
            _this.plan.activationRule.to = new Date(_this.plan.activationRule.to);
        }

    } else {
        _this.plan = {
            roundTitle: {
                defaultValue: ''
            },
            activationRule: {
                ruleType: 'SINGLE',
                from: today,
                to: nextMonth
            },
            appSurvey: true,
            mailSurvey: true,
            communicationTitle: Languages.createMultiLanguage(),
            communicationDescription: Languages.createMultiLanguage()
        };
        _this.isEdit = false;
    }





    // Get current survey languages, based on the title of the survey
    _this.surveyLanguages = Languages.getLangs(_this.survey.title);

    // Get current comunication languages, based on the title of the communicationTitle
    _this.currentComunicationPlanLanguages = Languages.getLangs(_this.plan.communicationTitle);

    _this.isEdit = isEdit;


    _this.showGroups = Groups.hasGroups();

    isMultiHierarchies(function (err, hasHierarchies) {
        if (err) {
            console.error(err);
            _this.showHierarchies = false;
        } else {
            _this.showHierarchies = hasHierarchies;
        }

    });

    Characteristics.hasCharacteristics(function (result) {
        _this.showSegments = result;
    });

    _this.dateFormat = $translate.instant('DATE_FORMAT');
    if (_this.dateFormat === 'DATE_FORMAT')
        _this.dateFormat = Settings.defaultDateFormat;


    _this.wizardOptions = {
        nextButton: $translate.instant('SURVEY_SCHEDULE_NEXT'),
        prevButton: $translate.instant('SURVEY_SCHEDULE_PREV'),
        confirm: {
            text: _this.isEdit ? $translate.instant('SURVEY_SCHEDULE_UPDATE') : $translate.instant('SURVEY_SCHEDULE_CREATE'),
            action: function () {

            }
        },
        validate: function (step) {
            delete _this.error;
            if (step.number == 1 && _.isEmpty(_this.plan.roundTitle.defaultValue)) {
                _this.error = $translate.instant('SURVEY_ROUND_REQUIRES_TITLE');
                return false;
            } else if (step.number === 2) {
                // If we are launching a new round, verify that the new one doesn't colides
                if (!_this.isEdit && _this.plan.activationRule.ruleType == 'SINGLE' && _this.survey.rounds) {
                    _this.survey.rounds.forEach(function (round) {
                        if (Dates.isBetween(_this.plan.activationRule.from, round.from, round.to, true)) {
                            _this.error =
                                $translate.instant('SURVEY_ROUND_OVERLAPS',
                                    { from: Date.formatToLocaleDate(round.from), to: Date.formatToLocaleDate(round.to) });
                        }
                    });
                    if (_this.error) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                delete _this.error;
                return true;
            }
        },
        exit: function () {
            $modalInstance.dismiss('close');
        }
    };

    _this.changeActivationRule = function (type) {
        if (type === 'CONTINUOUS') {
            _this.plan.activationRule = {
                ruleType: 'CONTINUOUS'
            };
        } else {
            _this.plan.activationRule = {
                ruleType: 'SINGLE',
                from: today,
                to: nextMonth
            };

        }
    };


    if (_this.plan.hierarchyIds || _this.plan.characteristics || _this.plan.groupIds) {
        _this.sendAll = false;
    } else {
        _this.sendAll = true;
    }

    $scope.ok = function () {
        $modalInstance.close(_this.survey);
    };

    _this.validateDates = function () {
        if (_this.plan.activationRule.ruleType == 'SINGLE') {

            return _this.plan.activationRule.from < _this.plan.activationRule.to;
        } else {
            return true;
        }
    };

    _this.validateCommunication = function () {
        // At least one channel must be selected
        const communicationPlansEnabled =  _this.plan.appSurvey || _this.plan.mailSurvey;
        const communicationContentOk =  !_.isEmpty(_this.plan.communicationTitle.defaultValue)
            && !_.isEmpty(_this.plan.communicationDescription.defaultValue);

        return communicationPlansEnabled && communicationContentOk;
    };


    _this.addCommunicationPlanLanguage = function (language) {
        Languages.setLangValue(_this.plan.communicationTitle, null, language);
        Languages.setLangValue(_this.plan.communicationDescription, null, language);
        _this.currentComunicationPlanLanguages = Languages.getLangs(_this.plan.communicationTitle);
    };

    _this.removeComunicationPlanLanguage = function (language) {
        Languages.removeLangValue(_this.plan.communicationTitle, language);
        Languages.removeLangValue(_this.plan.communicationDescription, language);
        _this.currentComunicationPlanLanguages = Languages.getLangs(_this.plan.communicationTitle);
    };

    _this.selectCommunicationPlanLanguage = function (language) {
        _this.selectedLanguage = language;

        //_this.updateEditorContent();
    };

    _this.save = function () {
        if (_this.sendAll) {
            delete _this.plan.hierarchyIds;
            delete _this.plan.characteristics;
            delete _this.plan.groupIds;
        }
        $modalInstance.close(_this.plan);
    };
}


const SurveyScheduleModal = angular.module('happyForceApp')
    .factory('SurveyScheduleModal',
        function ($uibModal, Surveys) {
            var methods = {};

            methods.showModal = function (survey, schedulePlan, relaunch, callback) {

                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);

                $uibModal.open({
                    backdrop: 'static',
                    template: require('./survey_schedule_edit.html'),
                    controller: SurveyScheduleEditController,
                    controllerAs: '$ctrl',
                    resolve: {
                        survey: survey,
                        schedulePlan: schedulePlan,
                        isEdit: (!relaunch && schedulePlan ? true : false)
                    }
                }).result.then(function (plan) {
                    Surveys.setPlan(survey.id, plan, function (err, result) {
                        callback(err, result);
                    });
                }, function (res) {
                    console.log('Modal ' + res);
                });

            };

            return methods;
        }
    );

export default SurveyScheduleModal;
