'use strict'

import Dates from "../../../../../utilities/date.utilities";
import Valuation from "../../../../../utilities/valuation.utils";
import Style from "../../../../../utilities/style.utils";

function FeedbackIdeasStatsCtrl($scope, $translate, Languages, FeedbackIdeasService, FeedbackIdeasStatsDetailModal) {
    'ngInject';

    var _this = this;

    _this.slices = [{
        color: Valuation.valuationColor('BAD'),
        range: [0, 33],
        label: $translate.instant('FEEDBACK_IDEAS_INNOVATION_SLICE_FIRST')
    }, {
        color: Valuation.valuationColor('WARNING'),
        range: [33, 66],
        label: $translate.instant('FEEDBACK_IDEAS_INNOVATION_SLICE_SECOND')
    }, {
        color: Valuation.valuationColor('EXCELLENT'),
        range: [66, 100],
        label: $translate.instant('FEEDBACK_IDEAS_INNOVATION_SLICE_THIRD')
    }];



    _this.loadStats = function () {


        var params = {};

        if (_this.filters['from']) {
            params.from = _this.filters['from'];
        }
        if (_this.filters['to']) {
            params.to = _this.filters['to'];
        }

        if (_this.filters.groups && _this.filters.groups.length)
            params.groups = _this.filters.groups;

        if (_this.filters.hierarchyIds && _this.filters.hierarchyIds.length) {
            params.hierarchyId = _this.filters.hierarchyIds.join(',');
        }

        if (_this.filters.segments && _this.filters.segments.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.filters.segments);
        }


        FeedbackIdeasService.stats.get(params, function (err, stats) {
            _this.stats = stats;
            _this.value = stats.innovationScore;
            _this.totalIdeas = stats.totalIdeas;
            _this.previousTotalIdeas = stats.previousTotalIdeas;
            _this.ideasDifference = stats.totalIdeas - stats.previousTotalIdeas;


            _this.valuation = stats.valuation;
            _this.ideasValuationColor = Valuation.valuationColor(stats.valuation);

            if (_this.ideasDifference > 0) {
                _this.ideasDifferenceColor = Valuation.valuationColor('EXCELLENT');
            } else if (_this.ideasDifference < 0) {
                _this.ideasDifferenceColor = Valuation.valuationColor('BAD');
            }

            _this.finishedInitiatives = stats.finishedInitiatives;
            _this.activeInitiatives = stats.activeInitiatives;
            _this.previousActiveInitiatives = stats.previousActiveInitiatives;

            _this.initiativesDifference = _this.activeInitiatives - _this.previousActiveInitiatives;

            if (_this.initiativesDifference > 0) {
                _this.initiativesDifferenceColor = Valuation.valuationColor('EXCELLENT');
            } else if (_this.initiativesDifference < 0) {
                _this.initiativesDifferenceColor = Valuation.valuationColor('BAD');
            }

            _this.renderChart();
        });


    };

    _this.showDetails = function ($event) {
        $event.stopPropagation();

        var hierarchyIds;
        var filters;
        var groups;
        if (_this.filters.groups && _this.filters.groups.length)
            groups = _this.filters.groups;

        if (_this.filters.hierarchyIds && _this.filters.hierarchyIds.length) {
            hierarchyIds = _this.filters.hierarchyIds.join(',');
        }

        if (_this.filters.segments && _this.filters.segments.length) {
            filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.filters.segments);
        }

        FeedbackIdeasStatsDetailModal.showStatDetails
            (
                _this.filters['from'],
                _this.filters['to'],
                hierarchyIds,
                filters,
                groups
            );
    };


    _this.renderChart = function () {


        var categories = [];
        var ideaSeries = [];
        var ideaLinkedtoInitiativeSeries = [];

        FeedbackIdeasService.stats.phaseconfig.forEach(function (phaseConfig) {

            var notLinkedToInitiative =
                (_this.stats && _this.stats.ideasDistribution[phaseConfig.id]) ? _this.stats.ideasDistribution[phaseConfig.id].numIdeas : 0;
            const linkedToInitiative =
                (_this.stats && _this.stats.ideasDistribution[phaseConfig.id]) ?
                    _this.stats.ideasDistribution[phaseConfig.id].numIdeasInitiatives : 0;

            categories.push($translate.instant(phaseConfig.label));
            ideaSeries.push({
                y: notLinkedToInitiative,
                color: phaseConfig.color
            });

            ideaLinkedtoInitiativeSeries.push({
                y: linkedToInitiative,
                color: phaseConfig.linkedToInitiativeColor
            });

        });

        var series = [{
                name: $translate.instant('FEEDBACK_IDEAS_INNOVATION_IDEAS_WITHOUT_INITIATIVE'),
                data: ideaSeries
            }, {
                name: $translate.instant('FEEDBACK_IDEAS_INNOVATION_IDEAS_WITH_INITIATIVE'),
                data: ideaLinkedtoInitiativeSeries
            }];


        _this.chartConfig = {
            options: {
                chart: {

                    type: 'bar',
                    height: 200,
                    style: {
                        fontFamily: Style.charts.fontFamily,
                        fontSize: Style.charts.fontSmall.size
                    }
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    min: 0,
                    labels: {
                        enabled: false,
                        overflow: 'justify'
                    },
                    title: {
                        text: null
                    }
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    bar: {
                        pointWidth: 12,
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontWeight: Style.charts.fontSmall.weight,
                                fontSize: Style.charts.fontSmall.size
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                }
            },
            series: series
        };
    };


    this.$onInit = function () {
        var pageArgs = ['filters'];
        pageArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (newVal === oldValue) return;
                _this.loadStats();
            }, true);
        });
        _this.loadStats();
    };


}

const hfFeedbackIdeasStats = angular.module('happyForceApp')
    .directive('hfFeedbackIdeasStats', function () {
        return {
            template: require('./feedback-ideas-stats.html'),
            controller: FeedbackIdeasStatsCtrl,
            scope: {
                filters: '<'
            },
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeasStats;
