import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';

const CompanyName = () => {
    const [companyName, setCompanyName] = useState('');
    const CompanySvrc = getService('CompanySvrc');

    useEffect(() => {
        CompanySvrc.getUserCompany((err, company) => {
            if (!err && company) {
                setCompanyName(company.name);
            }
        });
    }, []);

    return <span>{companyName}</span>;
};

angularize(CompanyName, 'hfCompanyName', angular.module('happyForceApp'));

export default CompanyName;
