import React from 'react';
import PropTypes from 'prop-types';
import { angularize } from "reactInAngular";
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import {Box} from '@mui/material';

const HfImpactMark = ({ level, color, loading }) => {
    const getGrade = (level) => {
        switch(level) {
            case 'LOW': return 1;
            case 'MODERATE': return 2;
            case 'HIGH': return 3;
            case 'TOTAL': return 4;
            default: return 0;
        }
    };

    const grade = getGrade(level);

    return (
        <div className="impact_mark">

            {loading && (
                <Box sx={{minWidth:'120px'}}>
                    <HappyLoading loading={loading} noMessage={true} skeleton={1}/>
                </Box>

            )}

            {!loading && (
                <div className="impact-line">
                    {[1, 2, 3, 4].map((i) => (
                        <div key={i} className="impact-item">
                            <div className="complete" style={i <= grade ? { backgroundColor: color } : null}></div>
                        </div>
                    ))}
                </div>
            )}




        </div>
    );
};

HfImpactMark.propTypes = {
    level: PropTypes.number,
    color: PropTypes.string,
    loading: PropTypes.bool,
};

angularize(HfImpactMark, "hfImpactMark", angular.module("happyForceApp"), {
    level: "<",
    color: "<"
});

export default HfImpactMark;
