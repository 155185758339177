'use strict';

import Dates from "../../../../utilities/date.utilities";
import hfPersonEngagementContext
    from "../../../new-components/hf-person-engagement-context/hf-person-engagement-context.component";

function PersonEngagementStatusContextModalCtrl($modalInstance, $translate, Conversation, ErrorSvrc, $scope, from, to, employeeToken) {
    'ngInject';

    var _this = this;
    _this.to = Dates.lastDayOfMonth(to);
    _this.statusTo = Dates.lastDayOfMonth(to);
    _this.from = Dates.firstDayOfMonth(from);
    _this.employeeToken = employeeToken;
    _this.totalConversations = 0;
    _this.currentConversationsPage = 1;


    _this.loadPersonConversations = function () {
        _this.loadingConversations = true;
        new Promise(function (resolve, reject) {
            var params = {
                personEncryptedId: _this.employeeToken,
                fromConversation: (_this.currentConversationsPage - 1) * 5,
                toConversation: ((_this.currentConversationsPage - 1) * 5) + 5
            };

            Conversation.getPreview(params, function (err, conversations) {
                if (err) {
                    reject(err);
                } else {
                    _this.totalConversations = conversations.total;
                    _this.conversations = conversations && conversations.results;
                    resolve(null);
                }
            });
        }).then(function () {
            _this.loadingConversations = false;
        }).catch(function (err) {
            _this.loadingConversations = false;
            ErrorSvrc.showErrorModal(err);
        });
    };


    _this.close = function () {
        $modalInstance.dismiss();
    };

    _this.newConversation = function () {
        _this.displayConversation = true;
    };

    _this.closeConversation = function () {
        _this.displayConversation = false;
        _this.conversationId = null;
        _this.conversationDate = null;
        _this.loadPersonConversations();
        _this.statusTo = Dates.lastDayOfMonth(_this.to);
    };

    _this.handleConversationStarted = function (err, conversationId) {
        _this.conversationId = conversationId;
    };

    _this.viewConversation = function (conversationId, conversationDate) {
        _this.displayConversation = true;
        _this.conversationId = conversationId;
        _this.conversationDate = conversationDate;
        _this.statusTo = Dates.lastDayOfMonth(new Date(conversationDate));
    };


    _this.loadPersonConversations();
}

const PersonEngagementStatusContextModal = angular.module('happyForceApp')
    .factory('PersonEngagementStatusContextModal',
        function ($uibModal, $intercom) {
            var methods = {};
            methods.showEngagementContextDetails = function (from, to, employeeToken) {
                $intercom.update({ hide_default_launcher: true });
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    backdropClass: 'right',
                    template: require('./engagement-context-modal.html'),
                    controller: PersonEngagementStatusContextModalCtrl,
                    controllerAs: '$ctrl',
                    size: 'right survey',
                    resolve: {
                        from:  function () {return from;},
                        to: function () { return to;},
                        employeeToken: function () { return employeeToken;}
                    }
                }).result.then(function () {
                    $intercom.update({ hide_default_launcher: false });
                });
            };

            return methods;

        }
    );

export default PersonEngagementStatusContextModal;
