'use strict';

function FeedbackTransparencySettingsCtrl($scope, AuthSrvc, $translate) {
    'ngInject'

    var _this = this;

    _this.showNamesBanner = false;

    _this.privacyModes = [
        { id: 'OPEN', label: $translate.instant('COMPANY_FEEDBACK_PRIVACY_MODE_OPEN') },
        { id: 'OPTIONAL', label: $translate.instant('COMPANY_FEEDBACK_PRIVACY_MODE_OPTIONAL') },
        { id: 'MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_PRIVACY_MODE_MANDATORY') }
    ];

    _this.signatureModes = [
        { id: 'ANONYMOUS', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_ANONYMOUS') },
        { id: 'OPTIONAL', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_OPTIONAL') },
        { id: 'MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_MANDATORY') }
    ];

    _this.employeeSignatureModes = [
        { id: 'ANONYMOUS', label: $translate.instant('COMPANY_FEEDBACK_EMPLOYEE_SIGNATURE_MODE_ANONYMOUS') },
        { id: 'OPTIONAL', label: $translate.instant('COMPANY_FEEDBACK_EMPLOYEE_SIGNATURE_MODE_OPTIONAL') }

    ];

    if (AuthSrvc.isADVISOR()) {
        _this.employeeSignatureModes.push(
            { id: 'PUBLIC_MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_PUBLIC_MANDATORY') }
        );

        _this.signatureModes.push(
            { id: 'PUBLIC_MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_PUBLIC_MANDATORY') }
        );
    };

    this.$onInit = function () {
        $scope.$watch(angular.bind($scope, function () {
            return _this.type;
        }), function (newVal, oldVal) {

            if (newVal != oldVal) {
                // Enable or disable the show names banner based on the signature modes
                _this.showNamesBanner = _this.type.managerSignatureMode === 'OPTIONAL' ||
                                        _this.type.managerSignatureMode === 'MANDATORY' ||
                                        _this.type.managerSignatureMode === 'PUBLIC_MANDATORY' ||
                                        _this.type.employeeSignatureMode === 'OPTIONAL' ||
                                        _this.type.employeeSignatureMode === 'MANDATORY' ||
                                        _this.type.managerSignatureMode === 'PUBLIC_MANDATORY';
            }
        }, true);
    };
}

const hfFeedbackTransparencySettings = angular.module('happyForceApp')
    .directive('hfFeedbackTransparencySettings', function () {
        return {
            scope: {
                type: '='
            },
            template: require('./feedback_transparency_settings.html'),
            controller: FeedbackTransparencySettingsCtrl,
            controllerAs: '$feedbackTransparency',
            bindToController: true
        };
    });

export default hfFeedbackTransparencySettings;