const hiEvolutionChart = {};

hiEvolutionChart.hiEvolutionMilestone = function (date, title, clickFunction, hoverFunction) {
    return {
        color: 'rgba(255, 235, 191, .5)',
        width: 8,
        value: date,
        label: null,
        events: {
            click: function (e) {
                clickFunction();
                e.stopPropagation();
            },

            mouseover: function () {
                this.options.original_color = this.options.color;
                this.svgElem.attr({ stroke: 'rgba(255, 191, 191, .5)' });
                hoverFunction();
            },

            mouseout: function () {
                this.svgElem.attr({ stroke: this.options.original_color });
                hoverFunction();

            }
        }
    };
};

function buildHiCharDashedZones(data) {
    var zones = [],
        i = -1, len = data.length, current, previous, dashStyle, value, fillColor;

    while (data[++i] && data[i][1] === null) {
        zones.push({
            value: data[i][0]
        });
    }

    while (++i < len) {
        previous = data[i - 1][1];
        current = data[i][1];
        dashStyle = '';

        if (previous !== null && current === null) {
            dashStyle = 'solid';
            value = data[i - 1][0];
        } else if (previous === null && current !== null) {
            dashStyle = 'ShortDot';
            value = data[i][0];
        }

        if (dashStyle) {
            zones.push({
                dashStyle: dashStyle,
                value: value
            });
        }
    }

    return zones;
}

/**
 * Dashboard chart options
 * @type {Object}
 */
hiEvolutionChart.hiEvolutionConfig = function (params, enableAddMilestones) {
    // index, participation, milestones, tickInterval, dateFormat
    //

    if (!params) return {};

    var index = params.index;
    var previousIndex = params.previousIndex;
    var participation = params.participation;
    var previousParticipation = params.previousParticipation;
    var milestones = params.milestones;
    var tickInterval = params.tickInterval;
    var dateFormat = params.dateFormat;
    var clickEvent = params.events && params.events.click;

    var series = [];

    if (previousIndex && previousParticipation) {

        series.push({
                // id: 'previousParticipation',
                visible: false,
                linkedTo: 'previous',

                name: previousParticipation.label,
                serieName: previousParticipation.serieName,
                data: previousParticipation.data,
                type: 'column',
                xAxis: 1,
                yAxis: 0,
                color: '#e0e0e0',
                borderColor: '#e0e0e0'
            });
        series.push({
                id: 'previous',
                visible: false,
                name: previousIndex.label,
                serieName: previousIndex.serieName,
                data: previousIndex.data,
                zones: buildHiCharDashedZones(previousIndex.data),
                zoneAxis: 'x',
                connectNulls: true,
                xAxis: 1,
                yAxis: 1,
                color: '#e0e0e0',
                lineWidth: 3
            });
    }

    series.push({
        //     id: 'currentParticipation',
        linkedTo: 'current',
        name: participation.label,
        serieName: participation.serieName,
        data: participation.data,
        type: 'column',
        yAxis: 0,
        xAxis: 0,
        color: '#3ebfde',
        borderColor: 'rgba(116,167,188,0.1)'
    });
    series.push({
        id: 'current',
        name: index.label,
        serieName: index.serieName,
        data: index.data,
        zones: buildHiCharDashedZones(index.data),
        zoneAxis: 'x',
        connectNulls: true,
        xAxis: 0,
        yAxis: 1,
        color: '#ff5f3d',
        lineWidth: 3
    });

    return {
        options: {
            chart: {
                type: 'spline',
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                fontFamily: 'HelveticaNeue-Light',
                alignTicks: false,
                zoomType: 'x',
                height: 250
            },
            plotOptions: {
                series: {
                    lineWidth: 4,
                    marker: {
                        enabled: false,
                        fillColor: 'red',
                        states: {
                            hover: {
                                enabled: enableAddMilestones
                            }
                        },
                        radius: -2,
                        symbol: 'url(images/add_milestone.png)'
                    },
                    events: {
                        click: function (event) {
                            if (!clickEvent) return;
                            clickEvent(event && event.point && event.point.x);
                        }
                    }
                }
            },
            legend: {
                enabled: params.showLegend,
                labelFormatter: function () {
                    return this.userOptions.serieName;
                }
            },
            tooltip: {
                shared: true,
                formatter: function () {
                    var tooltipMarkup =
                        '<span class="tooltip-date">' +
                        moment(this.x)
                            .format(dateFormat) +
                        '</span><br />';
                    this.points.forEach(function (item, index) {
                        tooltipMarkup +=
                            '<span style="color:' +
                            item.series.color +
                            '">\u25CF</span> ' +
                            item.series.name +
                            ': <b>' +
                            item.y +
                            ' </b><br/>';
                    });

                    return tooltipMarkup;
                }
            },
            xAxis: [{
                type: 'datetime',
                labels: {
                    style: {
                        color: '#b5bbc3',
                        height: '50px'
                    }
                },
                maxZoom: 48 * 3600 * 1000,
                pointInterval: 24 * 3600 * 1000 * 7,
                plotLines: milestones
            },
                {
                    type: 'datetime',
                    labels: {
                        style: {
                            color: '#b5bbc3',
                            height: '50px'
                        }
                    },
                    maxZoom: 48 * 3600 * 1000,
                    pointInterval: 24 * 3600 * 1000 * 7,
                    opposite: true,
                    visible: true
                }],
            yAxis: [
                {
                    id: 'participation',
                    min: 0,
                    allowDecimals: false,
                    opposite: true,
                    title: {
                        text: null
                    },
                    tickColor: '#FFFFFF',
                    gridLineColor: '#6991ab',
                    tickInterval: tickInterval,
                    endOnTick: false,
                    labels: {
                        align: 'left',
                        x: -8,
                        y: -2,
                        style: {
                            color: '#3ebfde'
                        }
                    }
                },
                {
                    id: 'hi',
                    min: 0,
                    max: 100,
                    title: {
                        text: null
                    },
                    tickColor: '#FFFFFF',
                    gridLineWidth: 0,
                    allowDecimals: false,
                    tickInterval: 25,
                    endOnTick: false,
                    labels: {
                        align: 'right',
                        x: 0,
                        y: -2,
                        style: {
                            color: '#ff5f3d'
                        }
                    }
                }
            ],
            title: {
                text: '',
                enabled: false
            },
            credits: {
                enabled: false
            },
            loading: false,
            size: {
                height: 210
            }

        },
        series: series
    };
};

export default hiEvolutionChart;
