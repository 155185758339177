import React from 'react';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import SurveyChartRender from '../survey_chart_render_utils';
import Numbers from '../../../../utilities/number.utilities';
import Charts from '../../../../utilities/charts.utilities';



const SurveyIndexChart = ({ index, hasResult, size }) => {
    const $translate = getService('$translate');

    const roundedIndex = Numbers.roundNumber(index, 1);

    const indexValuation = SurveyChartRender.indexEvaluation(roundedIndex);

    let y;
    let fontSize;
    let fontWeight;
    let fontFamily;
    let height;

    if (size === 'small') {
        const chartFontConfig = Charts.getFontConfig('mini');
        height = 36;
        fontSize = chartFontConfig.fontSize;
        fontWeight = chartFontConfig.fontWeight;
        fontFamily = chartFontConfig.fontFamily;
        y = -6;
    } else if (size === 'medium') {
        const chartFontConfig = Charts.getFontConfig('medium');
        height = 56;
        fontSize = chartFontConfig.fontSize;
        fontWeight = chartFontConfig.fontWeight;
        fontFamily = chartFontConfig.fontFamily;
        y = -8;
    } else {
        const chartFontConfig = Charts.getFontConfig('big');
        height = 80;
        fontSize = chartFontConfig.fontSize;
        fontWeight = chartFontConfig.fontWeight;
        fontFamily = chartFontConfig.fontFamily;
        y = -10;
    }

    const chartConfig = {
        chart: {
            type: 'solidgauge',
            height: height,
            margin: [0, 0, 0, 0],
            spacing: [0, 0, 0, 0],
            backgroundColor: 'transparent'
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    borderWidth: 0,
                    useHTML: true,
                    y: y
                }
            }
        },
        title: null,
        pane: {
            center: ['50%', '50%'],
            size: '90%',
            startAngle: 0,
            endAngle: 360,

            background: {
                backgroundColor: '#EEE',
                innerRadius: '80%',
                outerRadius: '100%',
                shape: 'arc',
                borderWidth: 0
            }
        },
        yAxis: {
            min: 0,
            max: 10,
            lineWidth: 0,
            minorTickInterval: null,
            tickPixelInterval: 400,
            tickWidth: 0,
            title: {
                y: -70
            },
            labels: {
                enabled: false
            }
        },
        series: [{
            name: $translate.instant('POINTS'),
            data: [{
                y: roundedIndex,
                color: indexValuation.color
            }],
            innerRadius: '80%',
            outerRadius: '70%',
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                useHTML: true,
                y: y,
                padding: 0,
                format: `<div style="text-align: center; font-size: ${fontSize}; font-family: ${fontFamily}; font-weight: ${fontWeight}; color: ${indexValuation.color};">${indexValuation.indexLabel}</div>`
            }
        }]
    };


    Charts.setFontStyle(chartConfig, size);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartConfig}
            allowChartUpdate={true}
        />
    );
};

angularize(SurveyIndexChart, 'hfSurveyIndexChart', angular.module('happyForceApp'), {
    index: '=',
    hasResult: '=',
    size: '<'
});

export default SurveyIndexChart;
