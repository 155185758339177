'use strict';

import hfFeedbackIdeasInitiativeDetail from "./feedback-ideas-initiative-detail.controller";



function FeedbackIdeasInitiativeDetail($stateProvider) {
    'ngInject';

    $stateProvider
        .state('feedback.initiativeDetail', {
            url: '/ideas/initiative/detail/:initiativeId',
            template: '<hf-feedback-ideas-initiative-detail></hf-feedback-ideas-initiative-detail>',
            data: { pageTitle: 'SIDEMENU_IDEAS', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
            /*,
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            files: [
                                '/plugins/highcharts/highstock.js',
                                '/plugins/highcharts/highcharts-more.js']
                        }
                    ]);
                }
            }*/
        });
}

const FeedbackIdeasInitiativeDetailConfig = angular.module('happyForceApp')
    .config(FeedbackIdeasInitiativeDetail);

export default FeedbackIdeasInitiativeDetailConfig;
