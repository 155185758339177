'use strict';

import Valuation from "../../../../../utilities/valuation.utils";
import Numbers from "../../../../../utilities/number.utilities";
import Dates from "../../../../../utilities/date.utilities";
import {getActivationHeatmap} from "../../../../../shared/services/migrated/activation.service";
import {getHierarchies} from "../../../../../shared/services/migrated/hierarchy.service";


function activationHeatmapCtrl($scope, UserSendReminderModal, ErrorSvrc, $translate, Characteristics, Groups, $state, Rollbar) {
    'ngInject'
    var _this = this;

    _this.heatmapGroupBy = 'HIERARCHIES';

    _this.columnHeaders =  [
        {
            name:  $translate.instant('ANALYTICS_ACTIVATION_PERCENTAGE')
        },
        {
            name:  $translate.instant('ANALYTICS_ACTIVATION_TOTAL_ACTIVATED')
        },
        {
            name:  $translate.instant('ANALYTICS_ACTIVATION_TOTAL_INVITED'),
            description:  $translate.instant('ANALYTICS_ACTIVATION_TOTAL_INVITED_DESCRIPTION')
        },
        {
            name: $translate.instant('ANALYTICS_ACTIVATION_ACTION'),
            description:  $translate.instant('ANALYTICS_ACTIVATION_ACTION_DESCRIPTION')
        }
    ];

    _this.groupByOptions = [
        { id: 'HIERARCHIES', label: $translate.instant('AREAS') },
        { id: 'GROUPS', label: $translate.instant('GROUPS') },
        { id: 'CHARACTERISTICS', label: $translate.instant('SEGMENTS') }
    ];

    _this.togglePopoverForItem = function (item) {
        _this.currentItem = item;
        _this.currentItem.variationDescription = _this.getVariationDescription(item);
        _this.currentItem.qualityDescription = _this.getQualityDescription(item);
        _this.currentItem.benchmarkDescription = _this.getBenchmarkDescription(item);
    };

    _this.getQualityDescription = function (item) {
        var quality = item.quality;
        if (quality == 'LOW') {
            return $translate.instant('SCORE_LOW_QUALITY_DESCRIPTION');
        } else if (quality == 'NO_DATA') {
            return $translate.instant('SCORE_NODATA_QUALITY_DESCRIPTION');
        } else {
            return $translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION');
        }
    };

    _this.getVariationDescription = function (item) {
        var variation = item.variation || 0;
        var textKey = 'SCORE_VARIATION_EQUAL';
        if (variation > 0) {
            textKey = 'SCORE_VARIATION_UP';
        } else if (variation < 0) {
            textKey = 'SCORE_VARIATION_DOWN';
        }

        variation = Math.abs(variation).toFixed(2);
        return $translate.instant(textKey, { variation: variation });
    };

    _this.styleForCell = function (item) {
        return { 'background-color': item.color };
    };

    _this.loadResults = function () {
        _this.loading = true;
        _this.results = [];

        var params = {
            from: Dates.firstDayOfMonth(_this.from),
            to: Dates.lastDayOfMonth(_this.to)
        };

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        params.filters = _this.filters;
        params.groupId = _this.groups;

        getActivationHeatmap(_this.heatmapGroupBy, params, function (err, results) {
            _this.loading = false;
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    _this.hasEnoughActiveEmployees = false;
                } else {
                    Rollbar.error('Error getting heatmap data', err);
                }
            } else {
                _this.processHeatmapData(results);
            }
        });
    };

    _this.processHeatmapData = function (data) {
        data.results.forEach(function (result) {

            // Let's build the metadata for the row
            var characteristic = result.characteristic || {};

            var type;
            if (result.hierarchyId) {
                type = 'SEGMENT';
            } else if (result.groupId) {
                type = 'SEGMENT';
            } else if (result.characteristic) {
                type = 'SEGMENT';
            } else {
                type = 'COMPANY';
            }


            var entry = {
                type: type,
                hierarchy: result.hierarchyId,
                group: result.groupId,
                characteristic: characteristic.id,
                characteristicValue: characteristic.value
            };

            // Now add each column value:
            entry.nonEnoughEmployees = result.nonEnoughEmployees;
            entry.totalActivated = result.totalActivated;
            entry.activatedVariationPercent = Numbers.roundNumber(result.activatedVariationPercent, 1);
            entry.totalInvited = result.totalInvited;
            entry.invitedVariationPercent = Numbers.roundNumber(result.invitedVariationPercent, 1);
            entry.activationPercent = Numbers.roundNumber(
                Numbers.calculatePercent(result.totalActivated, result.totalInvited), 1);
            entry.valuationColor = Valuation.valuationColor(result.valuation);
            entry.linkParams =  buildLinkParams(entry);

            _this.results.push(entry);
        });
    };

    _this.sendReminder = function (linkParams) {
        UserSendReminderModal.showSendReminderModal(
            'NON_ACTIVATED',
            linkParams.hierarchyId,
            linkParams.filters,
            linkParams.groupId,
            function (err) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                }
            });
    };

    function buildLinkParams(entry) {
        var linkParams = {};

        linkParams.from = _this.from;
        linkParams.to = _this.to;

        if (entry.hierarchy) {
            linkParams.hierarchyId = entry.hierarchy;
        } else if (entry.group) {
            linkParams.groupId = entry.group;
        } else if (entry.characteristic) {
            linkParams.filters = entry.characteristic + ':' + entry.characteristicValue;
        }

        return linkParams;
    }



    getHierarchies(function (err, hierarchies) {
        _this.hasHierarchies = hierarchies && hierarchies.length > 0;
    });

    Characteristics.hasCharacteristics(function (result) {
        _this.hasCharacteristics = result;
    });

    _this.hasGroups = Groups.hasGroups();

    _this.shouldDisplayEmptyState = function () {
        if (_this.heatmapGroupBy === 'HIERARCHIES' && !_this.hasHierarchies) {
            return true;
        } else if (_this.heatmapGroupBy === 'GROUPS' && !_this.hasGroups) {
            return true;
        } else if (_this.heatmapGroupBy === 'CHARACTERISTICS' && !_this.hasCharacteristics) {
            return true;
        } else {
            return false;
        }
    };


    _this.goToDetails = function (params) {
        if (params) {
            $state.go('reports.activation', params);
        }
    };

    this.$onInit = function () {
        ['from', 'to', 'level', 'filters', 'groups'].forEach(function (key) {
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) return;
                _this.loadResults();
            }, true);
        });

        _this.loadResults();
    };

}



const activationHeatmap = angular.module('happyForceApp')
    .directive('activationHeatmap', function () {
        return {
            scope: {
                from: '=',
                to: '=',
                level: '=',
                filters: '=',
                groups: '='
            },
            template: require('./activation-heatmap.html'),
            controller: activationHeatmapCtrl,
            bindToController: true,
            controllerAs: '$ctrl'
        };
    });

export default activationHeatmap;
