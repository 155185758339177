'use strict';

//Directive used to set metisMenu and minimalize button
const schrollBottom =angular.module('happyForceApp')
    .directive('schrollBottom', function () {
        return {
            scope: {
                schrollBottom: '='
            },
            link: function (scope, element) {
                scope.$watchCollection('schrollBottom', function (newValue) {
                    if (newValue)
                    {
                        $(element).scrollTop($(element)[0].scrollHeight);
                    }
                });
            }
        };
    });

export default schrollBottom;

