'use strict';

function ManagerActivity($scope, ManagersActivity, SegmentsFilterService, ErrorSvrc) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {
        _this.loading = true;
        _this.activities = [];
        var from = 0;
        var to = 5;

        var TYPES = {
            feedback: 'ANNOUNCEMENT_REPLY,CONVERSATION_PARTICIPATED,COMMENT_SIGNED,REPLY_SIGNED,RECOGNITION_PERFORMED'
        };

        function loadData() {
            _this.loading = true;

            var params = {};
            params.from = _this.dates.from;
            params.to = _this.dates.to;
            params.groupId = _this.groups;
            params.fromActivity = from;
            params.toActivity = to;
            params.types = TYPES[_this.type];

            // Set he current hierarchy for filter the comments
            if (_this.level) {
                params.hierarchyId = _this.level.id || _this.level;
            }

            params.filters = _this.filters; // SegmentsFilterService.compileFilterStringFromSegments(_this.filters);

            ManagersActivity.list(params, function (err, results) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    _this.activities = results.result;
                    _this.totalActivities = results.total;
                }

                _this.loading = false;
            });
        }

        var lastExecution;
        ['dates', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                loadData();
            }, true);
        });

        loadData();
    };
}



const hfManagerActivity = angular.module('happyForceApp').directive('hfManagerActivity', function () {
    return {
        scope: {
            type: '@',
            dates: '=',
            filters: '=',
            groups: '=',
            level: '='
        },
        restrict: 'E',
        template: require('./manager-activity.html'),
        controller: ManagerActivity,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfManagerActivity;
