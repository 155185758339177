const FactorNameFilter = function (ScoresV2Srvc) {
    'ngInject';
    var hash = [];

    function asyncLoad(factorId, scoreId) {
        if (hash[factorId]) {
            return hash[factorId];
        } else {
            ScoresV2Srvc.getScore(scoreId, function (err, score) {
                if (err) {
                    hash[factorId] = '?';
                } else {

                    var foundFactor = _.find(score.factors, function (factor) { return factor.id == factorId; });

                    if (foundFactor) {
                        hash[factorId] = foundFactor.name;
                    } else {
                        hash[factorId] = '?';
                    }
                }


                //$rootScope.$apply();
            });
        }
        return factorId;
    };
    asyncLoad.$stateful = true;
    return asyncLoad;
};

export default FactorNameFilter;
