'use strict';

function SurveyDeleteController($scope, $modalInstance, $translate, survey) {

    var _this = this;
    $scope.ok = function () {
        $modalInstance.close(survey);
    };

    $scope.close = function () {
        $modalInstance.dismiss('close');
    };
}


const SurveyDeleteModal = angular.module('happyForceApp')
    .factory('SurveyDeleteModal',
        function ($uibModal, Surveys, ErrorSvrc, $translate) {
            'ngInject'

            var methods = {};

            methods.showDeleteModal = function (survey, callback) {
                $uibModal.open({
                    backdrop: 'static',
                    template: require('./survey_delete.html'),
                    controller: SurveyDeleteController,
                    controllerAs: '$ctrl',
                    resolve: {
                        survey: survey
                    }
                }).result.then(function (survey) {
                    Surveys.delete(survey.id, function (err, result) {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                        } else {
                            swal($translate.instant('SURVEY_DELETED'), '', 'success');
                            callback();
                        }
                    });
                });
            };

            return methods;

        }
    );

export default SurveyDeleteModal;
