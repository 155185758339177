

function SettingsCompanyLocalizationCtrl($scope, ErrorSvrc, Languages, Analytics, HIService, toaster) {

    $scope.allLanguages = Languages.list;

    var _this = this;

    _this.selectedTimeZone = $scope?.companyConfig?.timezone;

    Languages.getSupportedLanguages(function (err, supportedLanguages) {
        if (err) {
            ErrorSvrc.getErrorMessage(err);
            return;
        }

        $scope.supportedLanguages = supportedLanguages;
    });


    _this.onTimeZoneChange = function (timeZone) {
        $scope.companyConfig.timezone = timeZone;
    };


    /* We have to do this in order to make the timezone selector (in react) to work with AngularJs */
    _this.$onInit = function () {

        $scope.$watch('companyConfig',function(newValue,oldValue) {

            if (newValue===oldValue) {
                return;
            }

            _this.selectedTimeZone = newValue.timezone;
        }, true);

    }

    Analytics.trackEvent('settings_company_localization');
}



const hfCompanyLocalization = angular.module('happyForceApp')
    .directive('hfCompanyLocalization', function () {
        return {
            template: require('./settings_company_localization.html'),
            controller: SettingsCompanyLocalizationCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfCompanyLocalization;
