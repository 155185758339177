import Dates from "../../utilities/date.utilities";

export default function HIService(Restangular, Settings, Rollbar, $log) {
    'ngInject';

    const Company = Restangular.one('company');

    var methods = {};

    var _general = null;

    methods.get = function (callback) {
        if (_general) return callback(null, _general);

        Company.one('hi').get().then(function (question) {
            _general = question;
            callback(null, question);
        }, function (err) {

            Rollbar.error('Error getting HI question', err);
            callback(err);
        });
    };

    methods.update = function (question, callback) {

        Company.one('hi').customPUT(question).then(function (result) {
            _general = result;
            callback(null, result);
        }, function (err) {

            Rollbar.error('Error getting HI question', err);
            callback(err);
        });

    };

    var Stats = Company.one('stats').one('hi');
    var stats = {};

    stats.get = function (params, callback, error) {

        var reqParams = angular.copy(params);

        if (params.from)
            reqParams.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        if (params.to)
            reqParams.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);

        Stats
            .get(reqParams || null).then(function (question) {
            callback(null, question);
        }, function (err) {

            Rollbar.error('Error getting HI stats', err);
            callback(err);
        });
    };

    stats.correlations = function (from, to, params, callback) {
        Stats
            .one('correlation')
            .one(moment(from).format(Settings.apiDateFormat))
            .one(moment(to).format(Settings.apiDateFormat))
            .get(params || null)
            .then(function (results) {
                $log.debug('Analytics.correlation.get (response)', results);
                callback(null, results);
            }, function (err) {

                $log.error('Error loading Analytics.correlation', err, params);
                Rollbar.error('Error loading Analytics.correlation', err, params);
                callback(err);
            });
    };

    stats.correlation = function (scoreId, from, to, params, callback) {
        Stats
            .one('correlation')
            .one(scoreId)
            .one(moment(from).format(Settings.apiDateFormat))
            .one(moment(to).format(Settings.apiDateFormat))
            .get(params || null)
            .then(function (results) {
                $log.debug('Analytics.correlation.get (response)', results);
                callback(null, results);
            }, function (err) {

                $log.error('Error loading Analytics.correlation', err, params);
                Rollbar.error('Error loading Analytics.correlation', err, params);
                callback(err);
            });
    };

    stats.individual = function (from, to, params, callback) {
        Stats
            .one('individual')
            .one(moment(from).format(Settings.apiDateFormat))
            .one(moment(to).format(Settings.apiDateFormat))
            .get(params || null)
            .then(function (results) {
                $log.debug('Analytics.individual.get (response)', results);
                callback(null, results);
            }, function (err) {

                if (!err.status || err.status != 412) {
                    $log.error('Error loading Analytics.individual', err, params);
                    Rollbar.error('Error loading Analytics.individual', err, params);
                }

                callback(err);
            });
    };

    stats.personal = function (from, to, personalToken, callback) {
        Stats
            .one('personal')
            .one(moment(from).format(Settings.apiDateFormat))
            .one(moment(to).format(Settings.apiDateFormat))
            .one(personalToken)
            .get()
            .then(function (results) {
                $log.debug('Analytics.personal.get (response)', results);
                callback(null, results);
            }, function (err) {
                if (!err.status || err.status != 412) {
                    $log.error('Error loading Analytics.individual', err);
                    Rollbar.error('Error loading Analytics.individual', err);
                }

                callback(err);
            });
    };

    stats.benchmarks = function (params, callback) {
        var reqParams = angular.copy(params);

        reqParams.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        reqParams.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);

        Stats.one('benchmark')
            .get(reqParams || null).then(function (question) {
            callback(null, question);
        }, function (err) {

            Rollbar.error('Error getting HI benchmarks', err);
            callback(err);
        });
    };

    stats.grouping = function (groupBy, params, callback) {
        var reqParams = angular.copy(params) || {};
        if (params.from)
            reqParams.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        if (params.to)
            reqParams.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        Stats
            .one('grouping')
            .one(groupBy)
            .get(reqParams || null)
            .then(function (results) {
                callback(null, results);
            }, function (error) {
                Rollbar.error('Error getting grouped HI', error);
                callback(error);
            });
    };

    stats.classification = function (params, callback) {
        var _params = angular.copy(params);
        if (_params.from) {
            _params.from = Dates.toAPIFormat(params.from, Settings.apiDateFormat);
        }
        if (_params.to) {
            _params.to = Dates.toAPIFormat(params.to, Settings.apiDateFormat);
        }
        Stats.one('classification').get(_params).then(function (result) {
            callback(null, result);
        }, function (err) {
            callback(err);
        });
    };

    methods.stats = stats;

    return methods;
};
