'use strict';

import Numbers from "../../../utilities/number.utilities";

import hfSurveyFeedbackView from "./survey_feedback_view/survey_feedback_view.controller";

import hfSurveyResultsHeatmapView from "./survey_results_heatmap_view/survey_results_heatmap_view.controller";
import hfSurveyResultsListView from "./survey_results_list_view/survey_results_list_view.controller";

import template from './survey_results.html';
import SurveyQuestionResultsDetailView from './survey_question_result_detail_view/surveyQuestionResultDetailView';
import SurveyContinuousEvolutionView from './survey_continuous_evolution_view/surveyContinuousEvolutionView';

function SurveysResultsCtrl($scope, AuthSrvc, Settings, Languages, SegmentsFilterService, ErrorSvrc, $translate, $stateParams, Surveys, $uibModal, toaster, $state, $intercom, Reports, Analytics) {
    'ngInject';

    var _this = this;

    // Initialize the default filters
    _this.selectedFilters = {
       
    };

    // Initialize the default tags
    _this.selectedTags = [];

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        mode: 'single',
        title: $translate.instant('GROUPS'),
        nonRemovable: false
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false
    }

    _this.optionFiltersConfig = {
        filters: []
    };

    _this.mode = 'LIST';

    _this.selectedRound = null;

    _this.dateFromEnabled = false;
    _this.dateToEnabled = false;

    _this.dateFormat = $translate.instant('DATE_FORMAT');

    if (_this.dateFormat === 'DATE_FORMAT')
        _this.dateFormat = Settings.defaultDateFormat;

    _this.dateTo = new Date();
    _this.dateFrom = new Date();
    _this.dateFrom.setMonth(_this.dateTo.getMonth() - 1);

    _this.arrow = {
        action: function () {
            $state.go('surveys.list');
        }
    };

    _this.loadResults = function () {


        if (_this.survey) {
            delete _this.survey.result;
            var params = _this.prepareParams();

            Surveys.stats.results(_this.survey.id, params, function (err, results) {
                var survey = _this.survey;
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    survey.result = results.result;
                    if (survey.result) {
                        _this.selectedRound = survey.result.roundId;

                        survey.result.periodIndex = Numbers.roundNumber(survey.result.periodIndex, 1);
                        survey.result.participationPercent =
                            Numbers.roundNumber(Numbers.calculatePercent(survey.result.participants, survey.result.expectedParticipants), 1);

                        survey.result.blocks.forEach(function (block) {
                            block.blockIndex = Numbers.roundNumber(block.blockIndex, 1);
                            block.participationPercent =
                                Numbers.roundNumber(Numbers.calculatePercent(block.participants, survey.result.expectedParticipants), 1);
                            block.questions.forEach(function (question) {
                                question.participationPercent =
                                    Numbers.roundNumber(Numbers.calculatePercent(question.participants, survey.result.expectedParticipants), 1);
                            });
                        });
                    }
                }
            });
        }
    };

    _this.exportToExcel = function () {
        // Get the data for the current hierarchy & filter
        if (_this.survey) {
            const nonAnonymous = _this.survey.nonAnonymous;

            // Before continue, let the user select the report type;
            $uibModal.open({
                backdrop: 'static',
                resolve: {
                    type: function () {
                        return 'SUMMARY';
                    },
                    notAnonymous: function () {
                        return _this.survey.notAnonymous;
                    }
                },
                controller: function ($scope, $uibModalInstance, type, notAnonymous) {
                    $scope.type = type;
                    $scope.notAnonymous = notAnonymous;
                    $scope.ok = function () {
                        $uibModalInstance.close($scope.type);
                    };

                    $scope.cancel = function () {
                        $uibModalInstance.dismiss();
                    };
                },
                template: require('./../components/survey_report_type_modal/survey_report_type_modal.html')
            }).result.then(function (type) {

                var params = _this.prepareParams();

                _this.exportingExcelReport = true;
                Reports.survey(_this.survey.id, type, params, function () {
                    _this.exportingExcelReport = false;
                    toaster.pop('success', null, ($translate.instant('SURVEY_EXPORT_SENT')));
                    Analytics.trackEvent('surveys', 'excel_report', 'success');
                }, function errorCallback(response) {
                    ErrorSvrc.showErrorModal(response);
                    _this.exportingExcelReport = false;
                    Analytics.trackEvent('surveys', 'excel_report', 'false');
                });
            }, function (closeAction) {
                console.info('Modal dismissed at: ' + new Date());
            });


        }
    };


    _this.prepareParams = function () {
        var params = {};

        _this.continuous = _this.survey.schedulePlan.activationRule.ruleType === 'CONTINUOUS';

        if (_this.selectedRound) {
            params.roundId = _this.selectedRound;
        }

        if (_this.selectedFilters.groups && _this.selectedFilters.groups.length)
            params.groupId = _this.selectedFilters.groups;

        if (_this.selectedFilters.hierarchyIds && _this.selectedFilters.hierarchyIds.length) {
            params.hierarchyId = _this.selectedFilters.hierarchyIds.join(',');
        }

        if (_this.selectedFilters.segments && _this.selectedFilters.segments.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.selectedFilters.segments);
        }

        if (_this.selectedFilters.questionFilters && _this.selectedFilters.questionFilters.length) {
            params.questionResults = _this.selectedFilters.questionFilters.join(',');
        }

        if (_this.dateFromEnabled && _this.continuous)
            params.from = _this.dateFrom;

        if (_this.dateToEnabled && _this.continuous)
            params.to = _this.dateTo;


        return params;

    };



    this.$onInit = function () {


        if ($stateParams.surveyId) {
            // Load the survey
            Surveys.get($stateParams.surveyId, function (err, survey) {
                if (err)
                    ErrorSvrc.showErrorModal(err);
                else {
                    _this.survey = survey;

                    if (_this.survey.schedulePlan) {
                        _this.loadResults();
                    } else {
                        $state.go('surveys.list');
                    }

                    _this.resultFilter =  [];

                    // Compile the result filters:
                    _this.survey.blocks.forEach(function (block) {
                        var blockFilters = {
                            id: block.blockId,
                            title: Languages.getTranslationFromUserLanguage(block.title),
                            selectable: false,
                            exclusive: false,
                            children: []
                        };
                        var blockQuestions = [];

                        block.questions.forEach(function (question) {
                            const questionTitle = Languages.getTranslationFromUserLanguage(question.title);
                            if (question.questionType != 'OPEN') {
                                var blockQuestion = {
                                    id: question.id,
                                    title: questionTitle,
                                    type: 'questionFilters',
                                    selectable: false,
                                    exclusive: false,
                                    children: []
                                };

                                if (question.questionType == 'TOPIC') {
                                    question.values.forEach(function (value) {
                                        var blockQuestionValue = {
                                            id: question.id + ':' + value.id,
                                            title: Languages.getTranslationFromUserLanguage(value.label),
                                            type: 'questionFilters',
                                            selectable: true,
                                            exclusive: false,
                                            overrideTypeLabel: questionTitle
                                        };
                                        blockQuestion.children.push(blockQuestionValue);
                                    });

                                } else if (question.questionType == 'RANGE') {
                                    for (var i = question.firstValue.id; i <= question.lastValue.id; i++) {
                                        var blockQuestionValue = {
                                            id: question.id + ':' + i,
                                            type: 'questionFilters',
                                            title: i,
                                            selectable: true,
                                            exclusive: false,
                                            overrideTypeLabel: questionTitle
                                        };
                                        blockQuestion.children.push(blockQuestionValue);
                                    }
                                }


                                blockQuestions.push(blockQuestion);
                            }
                        });

                        if (blockQuestions.length > 0) {
                            blockFilters.children = blockQuestions;
                            _this.resultFilter.push(blockFilters);
                        }
                    });

                    if(_this.resultFilter.length > 0){
                        _this.optionFilters = {
                            title: $translate.instant('SURVEY_FILTER_BY_VOTE'),
                            nonRemovable: false,
                            options: _this.resultFilter
                        }
                        _this.optionFiltersConfig.filters.push(_this.optionFilters);
                    }


                    // Finally, add the watch variables

                    var filterArgs = [
                        'selectedFilters',
                        'selectedRound',
                        'dateFromEnabled',
                        'dateToEnabled',
                        'dateFrom',
                        'dateTo'];

                    filterArgs.forEach(function (key) {
                        // Listeners
                        $scope.$watch(angular.bind(_this, function () {
                            return _this[key];
                        }), function (newVal, oldValue) {


                            if (typeof newVal === 'undefined') return;



                            if (newVal != oldValue) {

                                if (_this.survey.rounds) {
                                    if (_this.survey.schedulePlan.activationRule.ruleType != 'CONTINUOUS') {
                                        // Set dates from first round
                                        _this.dateFrom = _this.survey.rounds[0].from;
                                        _this.dateTo = _this.survey.rounds[0].to;

                                    }
                                }
                                _this.loadResults();
                            }
                        }, true);
                    });
                }
            });

        } else {
            $state.go('surveys.list');
        }
    };

}


function SurveyResultsView($stateProvider) {
    'ngInject'
    $stateProvider.state('surveys.results', {
        url: '/results/:surveyId',
        controller: SurveysResultsCtrl,
        controllerAs: '$ctrl',
        bindToController: true,
        template: template,
        data: { pageTitle: 'Surveys Results', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
}

export default SurveyResultsView;
