import Numbers from "./number.utilities";

var Participation = {};

Participation.calculateParticipation = function (expectedValue, currentValue) {
    if (expectedValue === 0) {
        return 0;
    }

    return Numbers.roundNumber((currentValue * 100) / expectedValue);
};


export default Participation;
