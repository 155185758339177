'use strict';

function AnnouncementsSectionsBrowserController($scope, Announcements, AnnounncementsSearchUtils, AuthSrvc, ErrorSvrc) {
    'ngInject';

    var _this = this;

    _this.loadingSections = false;

    _this.loadSections = function () {
        _this.loadingSections = true;

        const searchParams = AnnounncementsSearchUtils.compileParamsFromSearch(_this.selectedFilters, _this.searchText);

        Announcements.getSections(searchParams, function (error, response) {
            _this.loadingSections = false;
            if (error) {
                ErrorSvrc.showErrorModal(error);
                return;
            }

            _this.sections = [];
            var toSelectSectionId;
            response.forEach(function (section) {
                _this.sections.push({
                    id: section.id,
                    title: section.title,
                    count: section.announcementsCount,
                    publishingCapable: section.publishingCapable
                });

                if (section.publishingCapable && !_this.selectedSectionId && _this.sections.length > 0) {
                    toSelectSectionId = section.id;
                }
            });

            if (toSelectSectionId) {
                _this.onClickedSection(toSelectSectionId);
            }
        });
    };

    _this.updateSectionData = function (sectionId) {
        const searchParams = AnnounncementsSearchUtils.compileParamsFromSearch(_this.selectedFilters);
        Announcements.getSection(sectionId, searchParams, function (error, sectionData) {
            if (!error) {
                _this.sections.forEach(function (section) {
                    if (section.id === sectionData.id) {
                        section.count = sectionData.announcementsCount;
                    }
                });
            }
        });
    };

    _this.onClickedSection = function (sectionId) {
        if (_this.onSectionSelected) {
            _this.onSectionSelected(sectionId);
        }
    };

    _this.$onInit = function () {
        ['selectedFilters', 'searchText'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                _this.loadSections();
            }, true);
        });

        _this.loadSections();
    };

    $scope.$on('announcementCreated', function (event, data) {
        _this.updateSectionData(data.sectionId);
    });

    $scope.$on('announcementDeleted', function (event, data) {
        _this.updateSectionData(data.sectionId);
    });

    $scope.$on('announcementMoved', function (event, data) {
        _this.updateSectionData(data.sectionId);
        _this.updateSectionData(data.previousSectionId);
        _this.onClickedSection(data.sectionId);
    });

    $scope.$on('announcementUpdated', function (event, data) {
        _this.updateSectionData(data.sectionId);
        _this.onClickedSection(data.sectionId);
        if (data.statusChange)
            _this.onClickedSection(data.sectionId);
    });
}



//Directive used to set metisMenu and minimalize button
const hfAnnouncementsSectionsBrowser = angular.module('happyForceApp')
    .directive('hfAnnouncementsSectionsBrowser', function () {
        return {
            scope: {
                selectedSectionId: '<',
                selectedFilters: '<',
                onSectionSelected: '<',
                searchText: '<'
            },
            transclude: true,
            replace: true,
            template: require('./announcements-sections-browser.component.html'),
            controller: AnnouncementsSectionsBrowserController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });


export default hfAnnouncementsSectionsBrowser;
