import React, { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import {getService} from '../../../../../migration_utils/react-in-angular';
import {APISettings} from '../../../../shared/services/migrated/axiosWrapper';

const Chat = ( ) => {
    const [messages, setMessages] = useState([]);
    const [client, setClient] = useState(null);

    useEffect(() => {
        const AuthSrvc = getService('AuthSrvc');
        const socket = new SockJS(`${APISettings.wsURL}/company`);
        const stompClient = new Client({
            webSocketFactory: () => socket,
            debug: (str) => {
                console.log('STOMP debug: ' + str);
            },
            connectHeaders: {
                SessionId: AuthSrvc.sessionId(),
                Type: 'company',
            },
            reconnectDelay: 5000, // Reintenta la conexión cada 5 segundos
            onConnect: (frame) => {
                console.log('Connected: ' + frame);

                console.log('Subscribing to /user/queue/messages');
                stompClient.subscribe('/user/queue/messages', (message) => {
                    setMessages((prevMessages) => [...prevMessages, message.body]);
                });
            },
            onChangeState: (state) => {
                console.log('State changed to: ' + state);
            },
            onStompError: (frame) => {
                console.error('Broker reported error: ' + frame.headers['message']);
                console.error('Additional details: ' + frame.body);
            },
            onWebSocketClose: (evt) => {
                console.log(`onWebSocketClose: ${evt.code} - ${evt.reason}`);
            },
            onWebSocketError: (error) => {
                console.error('onWebSocketError:', error);
            },
            onDisconnect: (frame) => {
                console.log('onDisconnect: ' + frame);
            },
            onUnhandledMessage: (message) => {
                console.log('Unhandled message: ' + message);
            },onUnhandledFrame: (frame) => {
                console.log('Unhandled frame: ' + frame);
            }
        });

        stompClient.activate();
        setClient(stompClient);

        return () => {
             stompClient.deactivate();
        };
    }, []);

    const sendMessage = (message) => {
        if (message.trim() === '') {
            console.error('Cannot send empty message');
            return;
        }


        if (client && client.connected) {
            client.publish({
                destination: '/app/company/chat',
                body: message,
            });
        } else {
            console.error('STOMP client is not connected');
        }
    };

    return (
        <div>
            <div>
                {messages.map((msg, index) => (
                    <div key={index}>{msg}</div>
                ))}
            </div>
            <input type="text" onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    sendMessage(e.target.value);
                    e.target.value = '';
                }
            }} />
        </div>
    );
};

export default Chat;
