
'use strict';

import Style from '../../utilities/style.utils';
import Numbers from '../../utilities/number.utilities';
import Dates from '../../utilities/date.utilities';
import Charts from '../../utilities/charts.utilities';
import {getService} from "reactInAngular";
import {APISettings} from '../../shared/services/migrated/axiosWrapper';

var EngagementDetailCharts = EngagementDetailCharts || {};



function computeSeriesData(i, distribution, participants, seriesEmptyData, seriesVotesData) {
    var votes = 0;
    if (distribution[i]) {
        votes = distribution[i];
    }
    seriesEmptyData.push({
        x: seriesEmptyData.length,
        y: (participants - votes)
    });
    seriesVotesData.push({
        x: seriesVotesData.length,
        y: votes
    });

    return votes;
}

function addCategory(categories, label) {
    categories.push({
        label: label
    });
}


EngagementDetailCharts.scoreZones = [
    {
        value: 0,
        color: '#BBBBBB'
    },
    {
        value: 5,
        color: '#FE2847'
    },
    {
        value: 7,
        color: '#FF9300'
    }, {
        color: '#0CAD00'
    }];

EngagementDetailCharts.scoreQuestionChart = function (questionData, questionResults) {


    const Languages = getService('Languages'); // Assuming Languages is a service


    var votes = 0;
    var categories = [];
    var seriesEmptyData = [];
    var seriesVotesData = [];

    if (questionData.type == 'RANGE') {
        // OK, we have to generate 10 labels:
        addCategory(categories, Languages.getTranslationFromUserLanguage(questionData.first.label));
        votes += computeSeriesData(questionData.first.id, questionResults.distribution,
            questionResults.participants, seriesEmptyData, seriesVotesData);
        for (var i = questionData.first.id + 1; i < questionData.last.id; i++) {
            addCategory(categories, i);
            votes += computeSeriesData(i, questionResults.distribution, questionResults.participants, seriesEmptyData, seriesVotesData);
        }
        addCategory(categories, Languages.getTranslationFromUserLanguage(questionData.last.label));
        votes += computeSeriesData(questionData.last.id, questionResults.distribution, questionResults.participants,
            seriesEmptyData, seriesVotesData);
    } else {
        questionData.values.forEach(function (value) {
            addCategory(categories, Languages.getTranslationFromUserLanguage(value.label));
            votes += computeSeriesData(value.id, questionResults.distribution, questionResults.participants, seriesEmptyData, seriesVotesData);
        });
    }

    for (var i = 0; i < seriesVotesData.length; i++) {
        var data = seriesVotesData[i];
        if (data.y == 0) {
            categories[i].percent = 0;
        } else {
            categories[i].percent = Numbers.roundNumber(Numbers.calculatePercent(data.y, votes), 1);
        }
    }

    return {
        chart: {
            type: 'column',
            height: '180px'
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'categories',
            lineWidth: 0,
            gridLineWidth: 0,
            tickWidth: 0,
            enabled: true,
            showLastLabel: true,
            showFirstLabel: true,
            categories: categories,
            labels: {
                step: 1,
                formatter: function () {
                    return this.value.percent + '%<br/><br/><strong>' + this.value.label + '</strong>';
                }
            }
        },
        yAxis: {
            lineWidth: 0,
            gridLineWidth: 0,
            tickWidth: 0,
            labels: {
                enabled: false
            },
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                stacking: 'percent',
                groupPadding: 0,
                borderWidth: 0
            }
        },
        tooltip: {
            shared: true,
            formatter: function () {
                return this.points[1].y == 0 ? 'No votes' : 'Votes:' + this.points[1].y;
            }
        },
        series: [{
            data: seriesEmptyData,
            color: '#EAEAEA'
        }, {
            data: seriesVotesData,
            color: '#FFC200'
        }]
    };
};

var offset12Hours = 12 * 3600 * 1000;


EngagementDetailCharts.factorSparkLineEvolution = function (data, dateGrouping, height) {
    var dataMin;
    var dataMax;

    var seriesData = [];
    var tickPositions = [];

    data.forEach(function (point) {
        if (point[1]) {
            if (!dataMin || dataMin > point[1]) {
                dataMin = point[1];
            }

            if (!dataMax || dataMax < point[1]) {
                dataMax = point[1];
            }

            var date = (Dates.fromAPIFormat(point[0],   APISettings.apiDateFormat)).getTime() + Charts.oneDayOffset;

            seriesData.push(
                {
                    x: date,
                    y: Numbers.roundNumber(point[1], 1)
                });
            tickPositions.push(date);
        }
    });


    if (seriesData.length == 0) {
        return null;
    }

    var min = dataMin - 0.1;
    var max = dataMax + 0.1;

    // If we have less than 3 points, we enable the marker
    const markerEnabled = seriesData.length <= 3;


    return {
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        exporting: {
            enabled: false
        },
        chart: {
            type: 'line',
            style: {
                fontFamily: Style.charts.fontFamily,
                color:  Style.charts.textColor,
                fontSize: Style.charts.fontSmall.size,
                fontWeight: Style.charts.fontSmall.weight,
                fontStyle: 'normal'
            },
            height: height,
            marginLeft: 2,
            marginRight: 2,
            marginTop: 2,
            marginBottom: 2
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        yAxis: {
            visible: true,
            max: max,
            min: min,
            gridLineWidth: 0,
            gridLineDashStyle: 'ShortDot',
            gridLineColor: '#EEEEEE'
        },
        xAxis: {
            labels: {
                enabled: false,
                formatter: function() {
                    return this.chart.time.dateFormat(Charts.getDateFormatFromDateGrouping(dateGrouping), this.value)
                }
            },
            type: 'datetime',
            alignTicks: true,
            endOnTick: false,
            showFirstLabel: true,
            showEmpty: true,
            visible: true,
            lineWidth: 1,
            lineColor: '#E2E7E9',
            gridLineWidth: 1,
            gridLineColor: '#EEEEEE',
            gridLineDashStyle: 'ShortDot',
            tickWidth: 0,
            tickPositions: tickPositions
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        colors: [
            '#7cb5ec'
        ],
        pane: {
            background: []
        },
        responsive: {
            rules: []
        },
        tooltip: {
            enabled: true,
            padding: 4,
            backgroundColor: '#ffffff',
            borderColor: Style.charts.primaryColor,
            useHTML: false,
            xDateFormat: Charts.getDateFormatFromDateGrouping(dateGrouping),
            headerFormat: '<span style=\"font-size: 10px\">{point.key}: <b>{point.y}</b>' +
                '</span>',
            pointFormat: ''
        },
        series: [
            {
                name: 'spark',
                marker: {
                    enabled: markerEnabled,
                },
                dashStyle: 'Solid',
                lineWidth: 2,
                zones: EngagementDetailCharts.scoreZones,
                data: seriesData
            }
        ]
    };
};


EngagementDetailCharts.individualVotesChart = function (members) {

    var series = [];
    var categories = [];

    members.forEach(function (member) {
        categories.push(member.hi);
        var memberSerie = {
            data: []
        };
        member.votes.forEach(function (vote) {
            memberSerie.data.push([Date.formatServerDateToTimestamp(vote.date), series.length, vote.rating]);
        });
        series.push(memberSerie);
    });

    const numSeries = series.length;


    return {
        options: {
            chart: {
                type: 'heatmap',
                plotBorderWidth: 0,
                height: ((35 * numSeries) + 100) + 'px'
            },
            boost: {
                useGPUTranslations: true
            },
            title: null,
            xAxis: [{
                type: 'datetime',
                gridLineWidth: 0,
                tickInterval: 24 * 3600 * 1000
            },
            {
                type: 'datetime',
                gridLineWidth: 0,
                opposite: true,
                linkedTo: 0,
                tickInterval: 24 * 3600 * 1000
            }],
            yAxis: {
                type: 'category',
                categories: categories,
                title: null,
                gridLineWidth: 0,
                dashStyle: 'Dot',
                labels: {
                    useHTML: true,
                    formatter: function () {
                        var img;
                        if (this.value > 75) {
                            img = '/images/vote_1_btn.png';
                        } else if (this.value > 50) {
                            img = '/images/vote_2_btn.png';
                        } else if (this.value > 25) {
                            img = '/images/vote_3_btn.png';
                        } else {
                            img = '/images/vote_4_btn.png';
                        }

                        return '<img src="' + img + '" style="width:22px; height:22px" />';
                    }
                }
            },
            colorAxis: {
                dataClasses: [{
                    from: 1,
                    to: 1,
                    color: Style.faceSad
                }, {
                    from: 2,
                    to: 2,
                    color: Style.faceBad
                }, {
                    from: 3,
                    to: 3,
                    color: Style.faceGood
                },
                    {
                        from: 4,
                        to: 4,
                        color: Style.faceHappy
                    }]
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    colsize: 24 * 36e5,
                    rowsize: 0.50,
                    marker: {
                        symbol: 'circle'
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return '<b>' + Highcharts.dateFormat('%e-%b-%Y', new Date(this.point.x)) + '</b>';
                }
            }
        },
        series: series
    };
};


export default EngagementDetailCharts;
