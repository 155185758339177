

import template from './manage.bulk.html';
import async from 'async';
import StringUtils from '../../../utilities/string.utilities';


import * as XLSX from 'xlsx';
import UsersBulkStepper from 'root/app/components/users/bulk/components';




function ManageUsersBulkCtrl() {
    'ngInject';

}


function ManageUsersBulkView($stateProvider) {
    'ngInject';

    $stateProvider.state('manage.users_bulk', {
        url: '/users/bulk',
        controller: ManageUsersBulkCtrl,
        controllerAs: '$ctrl',

        template: template,
        data: { pageTitle: 'Bulk Manage Users', roles: ['ADMIN', 'ADVISOR'] }
    });
}

export default ManageUsersBulkView;


