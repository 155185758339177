'use strict';


import hfFeedbackAreas from "./components/feedback-areas/feedback-areas.controller";
import hfManagerActivity from "./components/manager-activity/manager-activy.controller";
import hfReceivedFeedbackPriority from "./components/received-feedback-priority/received-feedback-priority.controller";
import hfReceivedFeedbackStats from "./components/received-feedback-stats/received-feedback-stats.controller";
import hfRecognition from "./components/recognition/recognition.controller";
import recognitionPolarChart
    from "./components/recognition/components/recognition-polar-chart/recognition-polar-chart.controller";
import hfConversationRow from "./components/your-conversations/conversation-row/conversation-row.component";
import hfYourConversations from "./components/your-conversations/your-conversations.controller";
import FeedbackComments from "./feedback-comments/feedback-comments";
import hfConversationsSection from "./feedback-conversations/feedback-conversations.controller";
import ConversationsStateConfig from "./feedback-conversations/feedback-conversations";
import FeedbackIdeasCnf from "./feedback-ideas/feedback-ideas";
import hfFeedbackIdeas from "./feedback-ideas/feedback-ideas.controller";
import hfFeedbackIdeasStats from "./feedback-ideas/components/feedback-ideas-stats/feedback-ideas-stats.controller";
import FeedbackIdeasInitiativeEditorConfig
    from "./feedback-ideas/feedback-ideas-initiative-editor/feedback-ideas-initiative-editor";
import hfFeedbackIdeasInitiativeEditor
    from "./feedback-ideas/feedback-ideas-initiative-editor/feedback-ideas-initiative-editor.controller";
import FeedbackIdeasInitiativeDetailConfig
    from "./feedback-ideas/feedback-ideas-initiative-detail/feedback-ideas-initiative-detail";
import hfFeedbackIdeasInitiativeDetail
    from "./feedback-ideas/feedback-ideas-initiative-detail/feedback-ideas-initiative-detail.controller";
import hfFeedbackIdeasListing
    from "./feedback-ideas/components/feedback-ideas-listing/feedback-ideas-listing.controller";
import FeedbackIdeasStatsDetailModal
    from "./feedback-ideas/components/feedback-ideas-stats-detail/feedback-ideas-stats-detail.controller";
import hfFeedbackInitiativeBox
    from "./feedback-ideas/components/feedback-initiative-box/feedback-initiative-box.controller";
import hfFeedbackInitiativeDropdown
    from "./feedback-ideas/components/feedback-initiative-dropdown/feedback-initiative-dropdown.controller";
import hfFeedbackInitiativesListing
    from "./feedback-ideas/components/feedback-initiatives-listing/feedback-initiatives-listing.controller";
import hfRecognitionSection from "./feedback-recognition/feedback-recognition.controller";
import hfRecognitionConfig from "./feedback-recognition/feedback-recognition";
import template from './feedback.html';




const FeedbackCtrl = function FeedbackCtrl($scope, $stateParams, SegmentsFilterService, HIService, Comments, ManagersActivity, ErrorSvrc, CompanySvrc, Filters, $translate, $state, Analytics, $intercom) {
    'ngInject';
    var _this = this;

    this.$onInit = function () {

        _this.dates = {
            from: Filters.startDate(),
            to: Filters.endDate()
        };

        _this.level = Filters.level() || null;
        _this.filters = Filters.segments() || null;
        _this.groups = Filters.groups() || [];

        Analytics.trackEvent('feedback');
        $intercom.trackEvent('dashboard_visited_feedback_overview');
    };
}

angular.module('happyForceApp')
    .controller('FeedbackCtrl', FeedbackCtrl);


function FeedbackView($stateProvider) {
    'ngInject';
    $stateProvider.state('feedback.detail', {
        url: '/:hierarchyId',
        controller: FeedbackCtrl,
        controllerAs: '$ctrl',
        template: template,
        params: {
            hierarchyId: { squash: true, value: null }
        },
        data: { pageTitle: 'SIDEMENU_FEEDBACK', roles: ['MANAGER', 'ADMIN', 'ADVISOR'] }
    });
}

export default FeedbackView;
