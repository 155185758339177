/**
 * Created by dcastro on 31/03/14.
 */

'use strict';

import * as Sentry from "@sentry/browser";


const ErrorModalCtrl =  function($scope, errorMsg, $modalInstance) {
    $scope.errorMsg = errorMsg;

    $scope.close = function () {
        $modalInstance.dismiss('close');
    };

}


export default function ErrorService($log, $uibModal, $translate, toaster) {
    'ngInject';
    var currentModal = null;

    var methods = [];

    methods.getErrorMessage = function (status) {
        if (!status) {
            return $translate.instant('UPS_INTERNAL_ERROR');
        } else if (status.data && status.data.customMessage) {
            return status.data.customMessage;
        } else if (typeof status.errorMessage != 'undefined') {
            return methods.getErrorStringFromCode(status.errorMessage);
        } else if (status.status === 0 || status.status === -1) {
            return $translate.instant('CONNECTION_ERROR');
        } else if (status.status === 401) {
            return $translate.instant('EXPIRE_ERROR');
        } else if (status.data && status.data.errorMessage) {
            return methods.getErrorStringFromCode(status.data.errorMessage);
        } else if (status.status === 500) {
            return $translate.instant('UPS_INTERNAL_ERROR');
        } else {
            return status.status;
        }
    };

    methods.getErrorStringFromCode = function (errorCode) {
        if (errorCode === 'USER_DOSNT_EXISTS') {
            return $translate.instant('CREDENTIALS_ERROR');
        } else {
            return $translate.instant(errorCode);
        }
    };

    methods.getErrorCodeFromStatus = function (status) {
        if (status.data && status.data.errorCode) {
            return status.data.errorCode;
        }
    };

    methods.isNotEnoughActiveEmployees = function (status) {
        return (status && status.status == 412 && status.data.errorCode === 'NOT_ENOUGH_ACTIVE_EMPLOYEES');
    };

    methods.showErrorModal = function (status) {

        const isNotEnough = status.status === 412 && status.data.errorCode === 'NOT_ENOUGH_ACTIVE_EMPLOYEES';

        // Only capture error if it's not a not enough active employees error
        if (!isNotEnough) {
            Sentry.captureException(JSON.stringify(status));
        }


        var msg = this.getErrorMessage(status);
        if (currentModal === null) {
            currentModal = $uibModal.open({
                backdrop: 'static',
                template: require('./../../partials/modals/error.html'),
                controller: ErrorModalCtrl,
                resolve: {
                    errorMsg: function () {
                        return msg;
                    }
                }
            }).result.then(function () {
                currentModal = null;
            }, function () {

                currentModal = null;
                $log.info('Modal dismissed at: ' + new Date());
            });
        }
    };

    methods.showErrorToast = function (status) {
        const isNotEnough = status.status === 412 && status.data.errorCode === 'NOT_ENOUGH_ACTIVE_EMPLOYEES';
        if (!isNotEnough) {
            Sentry.captureException(JSON.stringify(status));
        }
        var msg = this.getErrorMessage(status);
        toaster.pop('error', null, msg);

    };

    return methods;

}
