import {APISettings, doGet, doPost} from 'root/app/shared/services/migrated/axiosWrapper';
import Dates from 'root/app/utilities/date.utilities';
import {getService} from 'reactInAngular';



const baseUrl = '/company/scores/ai';
import { fetchEventSource } from '@microsoft/fetch-event-source';



export const getScoreAIResume = function (scoreId,
    to,
    hierarchyId,
    filters,
    groupIds,
    grouping,
    callback) {
    const url = `${baseUrl}/${scoreId}`;

    const params = {
        to: Dates.toAPIFormat(to, APISettings.apiDateFormat),
        hierarchyId: hierarchyId,
        filters: filters,
        groupIds: groupIds ? groupIds.join(',') : undefined,
        grouping: grouping,
    };

    doGet(url, params, callback);
};


export const sendAIChatMessage = function (message,  callback) {
    const url = `${baseUrl}/chat/send`;

    doPost(url, {
        message: message
    }, callback);
};

export const buildChatEventSource = function (controller, onMessage, onError, onOpen, onClose) {

    const AuthSrvc = getService('AuthSrvc');

    const eventSource = fetchEventSource(`${APISettings.apiUrl}${baseUrl}/chat/stream`,
        {
            headers: {
                'Content-Type': 'text/plain',
                'SessionId': AuthSrvc.sessionId()
            }
        });
    eventSource.signal = controller.signal;
    eventSource.onmessage = onMessage;
    eventSource.onerror = onError;
    eventSource.onopen = onOpen;
    eventSource.onclose = onClose;

    return eventSource;
}
