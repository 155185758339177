export default function CompanyStatusService(Restangular, CompanySvrc, AuthSrvc, $timeout) {
    'ngInject';
    var _this = this;
    var companyStatus = Restangular.one('company').one('config').one('status');

    _this.notEnoughActiveEmployees = false;
    _this.notEnoughActiveEmployeesPrelaunch = false;
    _this.noEmployees = false;
    _this.noNames = false;
    _this.hierarchiesNoMinimum = false;
    _this.statusResults = [];

    function evaluateResults(results) {
        if (!results || results.length === 0) {
            _this.notEnoughActiveEmployees = false;
            _this.notEnoughActiveEmployeesPrelaunch = false;
            _this.noEmployees = false;
            _this.noNames = false;
            _this.hierarchiesNoMinimum = false;

            _this.noAnnouncementsPrelaunch = false;
            _this.noHierarchiesPrelaunch = false;

            _this.statusResults = [];
            return false;
        } else {
            _this.notEnoughActiveEmployees = results.indexOf('NO_ENOUGH_ACTIVE_EMPLOYEES') != -1;
            _this.notEnoughActiveEmployeesPrelaunch = results.indexOf('NO_ENOUGH_ACTIVE_EMPLOYEES_PRELAUNCH') != -1;
            _this.noEmployees = results.indexOf('NO_EMPLOYEES') != -1;
            _this.noNames = results.indexOf('NO_ALL_EMPLOYEES_HAVE_NAMES') != -1;
            _this.hierarchiesNoMinimum = results.indexOf('HIERARCHIES_NO_MINIMUM') != -1;

            _this.noAnnouncementsPrelaunch = results.indexOf('NO_ANNOUNCEMENTS_PRELAUNCH') != -1;
            _this.noHierarchiesPrelaunch = results.indexOf('NO_HIERARCHIES_PRELAUNCH') != -1;

            _this.statusResults = results;
            return true;
        }
    }

    _this.retrieveStatus = function () {
        if (AuthSrvc.isLoggedIn()) {
            companyStatus.get().then(function (results) {
                if (evaluateResults(results)) {
                    $timeout(_this.retrieveStatus, 30000);
                } else {
                    $timeout(_this.retrieveStatus, 60000);
                }
            }, function (err) {
                console.log(err);
                evaluateResults(null);
                $timeout(_this.retrieveStatus, 30000);
            });
        } else {
            $timeout(_this.retrieveStatus, 30000);
        }


    };

    return _this;
};
