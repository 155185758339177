
import {getService} from '../../../../../../migration_utils/react-in-angular';
import moment from 'moment';
import {APISettings} from '../../../../../shared/services/migrated/axiosWrapper';
import {BulkActionPerson, Person, RecordStatusErrorType} from '../../../components/user_listing/types';
import StringUtils from '../../../../../utilities/string.utilities';
import * as XLSX from 'xlsx';

const processGroups = (employee: BulkActionPerson, field: string, employeeRow: any): boolean => {
    const Groups = getService('Groups');
    let found = false;

    if (employeeRow[field]?.toLocaleString().toLowerCase() === 'x') {
        const group = Groups.getGroupByName(field);
        if (group && group !== undefined ) {
            employee.groups.push(group.id);
            found = true;
        } else if (employee.recordFailures.indexOf(RecordStatusErrorType.GROUPS_FAILURES) === -1) {
            employee.recordFailures.push(RecordStatusErrorType.GROUPS_FAILURES);
        }
    }
    return found;
};



const processCharacteristics = function (employee: BulkActionPerson, field: string, employeeRow: any, characteristics: any[]) {


    const characteristic: any = characteristics.find(function (characteristic: any) {
        return characteristic.description.toLowerCase() === field.toLowerCase();
    });

    const fieldValue = employeeRow[field];


    if (characteristic && fieldValue) {

        console.log(`Looking for ${field} type of ${fieldValue} is  ${typeof fieldValue} and matches string: ${typeof fieldValue === 'string'} for characteristic ${characteristic.description}`);




        let value: any | null = null;
        let date: any | null = null;
        let unmatchedValue = false;
        if (characteristic.questionType === 'LIST' && typeof fieldValue === 'string') {
            value = characteristic.values.find(function (value: any) {

                console.log(`Comparing ${value.value.defaultValue.toLowerCase()} against ${fieldValue.toLowerCase()}`);

                if (value.value.defaultValue.toLowerCase() === fieldValue.toLowerCase()) {
                    return value;
                }
            });
            if (!value) {
                unmatchedValue = true;
            }
        } else if (characteristic.questionType === 'DATE') {

            date  = prepareSheetDate(fieldValue);
            if (!date) {
                unmatchedValue = true;
            }

        }

        if (value || date) {

            console.log('characteristic', characteristic);
            console.log('fieldValue', fieldValue);
            console.log('type', typeof fieldValue);
            console.log('MATCHED');

            const newCharacteristic: any = {
                characteristicId: characteristic.id,
                questionType: characteristic.questionType
            };

            if (value) {
                const Characteristics = getService('Characteristics');
                newCharacteristic.value = value.id;
                newCharacteristic.label = Characteristics.getCharacteristicValueName(characteristic.id, value.id);
            }

            if (date) {
                newCharacteristic.date = date;
            }

            employee.characteristics.push(newCharacteristic);
        } else if (unmatchedValue &&  employee.recordFailures.indexOf(RecordStatusErrorType.CHARACTERISTICS_FAILURES) === -1) {
            employee.recordFailures.push(RecordStatusErrorType.CHARACTERISTICS_FAILURES);
        }
    }
};


const prepareSheetDate = inputDate => {

    let momentDate = null;
    if (inputDate && (inputDate instanceof Date)) {
        momentDate = moment(inputDate);
    } else if (inputDate && (typeof inputDate === 'string' || inputDate instanceof String)) {
        momentDate = moment(inputDate, 'DD/MM/YYYY');
    }

    if (momentDate && momentDate.isValid()) {
        return momentDate.format(APISettings.apiDateFormat);
    } else {
        return null;
    }
};


const prepareTurnoverType = (employee: BulkActionPerson, turnoverType: string) => {

    if (turnoverType && turnoverType.length > 0 || (turnoverType === 'VOLUNTARY' || turnoverType === 'INVOLUNTARY' || turnoverType === 'OTHER')) {
        employee.turnoverType = turnoverType;
    } else {
        employee.recordFailures.push(RecordStatusErrorType.TURNOVER_FAILURES);
    }

};


const processPersonRow = (employeeRow: any, characteristics: any[]): BulkActionPerson => {

    const employee: BulkActionPerson = {
        turnoverDate: undefined,
        turnoverType: undefined,
        language: null,
        name: undefined,
        email: '',
        role: '',
        managedHierarchyIds: [],
        hierarchyId: undefined,
        groups: [],
        characteristics: [],
        recordFailures: [],
        blocked: false,
        debug: false,
        processingFailures: []

    };

    Object.keys(employeeRow).forEach(function (field: string) {
        if (field.toLowerCase() === 'email') {
            employee.email = employeeRow[field];

            if (!StringUtils.validateEmail(employee.email)) {
                employee.recordFailures.push(RecordStatusErrorType.EMAIL_FAILURES);
            }

        } else if (field.toLowerCase() === 'name') {
            employee.name = employeeRow[field];
        } else if (field.toLowerCase() === 'language') {

            let languageCode = null;
            employeeRow[field]?.replace(/\[(.*?)\]/g, function (g0: any, g1: any) {
                languageCode = g1;
            });
            if (languageCode) {
                employee.language = languageCode;
            }


        } else if (field.toLowerCase() === 'role') {
            employee.role = employeeRow[field] || 'EMPLOYEE';
        } else if (field.toLowerCase() === 'turnover type') {
            prepareTurnoverType(employee, employeeRow[field]);
        } else if (field.toLowerCase() === 'turnover date') {
            employee.turnoverDate = prepareSheetDate(employeeRow[field]);
        } else if (field.toLowerCase() === 'managed area') {
            const detectedHierarchy = [];
            employeeRow[field]?.replace(/\[(.*?)\]/g, function (g0: any, g1: any) {
                detectedHierarchy.push(g1);
            });

            if (detectedHierarchy && detectedHierarchy.length > 0) {
                employee.managedHierarchyIds = detectedHierarchy;
            }
        } else if (field.toLowerCase() === 'area') {
            const detectedHierarchy = [];
            employeeRow[field]?.replace(/\[(.*?)\]/g, function (g0: any, g1: any) {
                detectedHierarchy.push(g1);
            });

            if (detectedHierarchy && detectedHierarchy.length > 0) {
                employee.hierarchyId = detectedHierarchy[0];
            }

        } else {
            const found = processGroups(employee, field, employeeRow);
            if (!found) {
                processCharacteristics(employee, field, employeeRow, characteristics);
            }
        }
    });

    return employee;
};


const processEmployeeXLSFile = async (file: File,
    setProgress: (progress: number) => void,
    characteristics: any[]) : Promise<BulkActionPerson[]> => {


    return new Promise((resolve, reject) => {
        const employees = [];

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onprogress = (e) => {
            if (e.lengthComputable) {
                const progress = (e.loaded / e.total) * 100;
                setProgress(progress);
            }
        };

        fileReader.onload = function () {
            const data = new Uint8Array(fileReader.result as ArrayBuffer);

            const length = data.byteLength;
            let binary = '';
            for (let i = 0; i < length; i++) {
                binary += String.fromCharCode(data[i]);
            }


            const workbook = XLSX.read(binary, {
                type: 'binary',
                cellDates: true,
                cellStyles: true,
                cellText: false,
                dateNF: 'DD/MM/YYYY'
            });

            const employeeRows = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                blankrows: false,
                range: 1,
            });

            employeeRows.forEach(function (employeeRow: any) {
                const employee = processPersonRow(employeeRow, characteristics);
                // only add employees with data in the email column
                if (employee.email) {
                    employees.push(employee);
                }
            });

            resolve(employees);
        };

        fileReader.onerror = function (error: any) {
            reject(error);
        };
    });

};


export {processEmployeeXLSFile};
