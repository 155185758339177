

var ColorUtilities = {};

//
export const neutral600 = '#1A2231';

// Charts primary colors
export const blue400 = '#4A90E2';
export const green400 = '#71CA00';
export const neutral100 = '#EBECEF';
export const neutral200 = '#CCD1D8';
export const neutral400 = '#67748A';
export const orange400 = '#FF9300';
export const pink400 = '#DF356B';
export const purple400 = '#6C70DC';
export const red400 = '#FF1C1C';
export const turquoise400 = '#37C098';
export const yellow400 = '#FFC200';

// Charts complementary colors
export const blue200 = '#D2E3F8';
export const green200 = '#DBF2BF';
export const orange200 = '#FFE4BF';
export const pink200 = '#F7CDDA';
export const purple200 = '#DADBF6';
export const red200 = '#FFC6C6';
export const turquoise200 = '#CDEFE5';
export const yellow200 = '#FFF0BF';


export const warmGray = '#A0A0A0';

export const darkGrey = '#2d3133';

export const whiteGrey =  '#f2f2f2';


export const darkDropdown = '#626366';


// These are the names of the colors;
ColorUtilities.names = [
    'primary',
    'success',
    'warning',
    'error',
    'neutral',
    'complementary01',
    'complementary02',
    'complementary03',
    'complementary04'
];


ColorUtilities.palettes = {};

// Build the charts palette. Order maters here
ColorUtilities.palettes.charts = [
    neutral400,
    purple400,
    blue400,
    turquoise400,
    green400,
    yellow400,
    orange400,
    red400,
    pink400,
    neutral200,
    purple200,
    blue200,
    turquoise200,
    green200,
    yellow200,
    orange200,
    red200,
    pink200
];

/* This is the default palette for the charts based on names:
    primary, success, warning, error, neutral, complementary01, complementary02, complementary03, complementary04
 */
ColorUtilities.palettes.chartsNamed = [blue400, turquoise400, orange400, red400, neutral400, purple400, green400, yellow400, pink400];

export default ColorUtilities;
