'use strict';

import hfSettingsBillingInfo from './components/billing_info/billing_info.controller';
import hfSettingsSubscriptionInfo from './components/subscription_info/subscription_info.controller';

function SettingsBillingCtrl(CompanySvrc, Analytics) {
    'ngInject';

    var _this = this;

    _this.loading = true;
    _this.externalBilling = false;

    CompanySvrc.getUserCompany(
        function (err, company) {
            if (!err)
                _this.externalBilling = company.hasExternalBilling;

        }
    );

    Analytics.trackEvent('settings_billing');
}

const hfSettingsBilling = angular.module('happyForceApp')
    .directive('hfSettingsBilling', function () {
        return {
            template: require('./settings_billing.html'),
            controller: SettingsBillingCtrl,
            controllerAs: '$bcctrl',
            bindToController: true
        };
    });

export default hfSettingsBilling;