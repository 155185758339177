const TopicsService = function (Restangular, Settings) {
  'ngInject';
    var Company = Restangular.one('company');

    var methods = {};

    methods.remove = function (id, callback, error) {
        Company.one('topics').one(id).remove().then(callback, error);
    };

    methods.PUT = function (id, topic, callback, error) {
        Company.one('topics').one(id).customPUT(topic).then(callback, error);
    };

    methods.POST = function (topic, callback, error) {
        Company.customPOST(topic, 'topics').then(callback, error);
    };

    methods.COMMENTS = function (params, callback, error) {
        Company.one('comments')
            .one('from', moment(params.from).format(Settings.apiDateFormat))
            .one('to', moment(params.to).format(Settings.apiDateFormat))
            .get(params.extras || null).then(callback, error);
    };

    var Stats = Company.one('stats');
    var stats = {};

    stats.ACTIVE = function (callback, error) {
        Stats.one('topics').one('ACTIVE').get().then(callback, error);
    };

    stats.EXPIRED = function (callback, error) {
        Stats.one('topics').one('EXPIRED').get().then(callback, error);
    };

    stats.GET = function (params, callback, error) {
        var requestParams = {};
        if (typeof params.fromDate !== 'undefined') {
            requestParams.fromDate = moment(params.fromDate.getTime()).format(Settings.apiDateFormat);
        }

        if (typeof params.toDate !== 'undefined') {
            requestParams.toDate =  moment(params.toDate.getTime()).format(Settings.apiDateFormat);
        }

        if (typeof params.id !== 'undefined') {
            requestParams.questionId = params.id;
        }

        if (typeof params.fromTopic !== 'undefined') {
            requestParams.fromTopic = params.fromTopic;
        }

        if (typeof params.toTopic !== 'undefined') {
            requestParams.toTopic = params.toTopic;
        }

        if (typeof params.filters !== 'undefined') {
            requestParams.filters = params.filters;
        }

        if (typeof params.hierarchyId !== 'undefined') {
            requestParams.hierarchyId = params.hierarchyId;
        }

        Stats.one('topics').customGET(params.type, requestParams).then(callback, error);
    };

    methods.stats = stats;

    return methods;

};

export default TopicsService;
